(function () {
	'use strict';

	angular.module('dcApp').directive('columnPermissions', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'PermissionService',
				'toaster',
				'gettextCatalog',
				'$rootScope',
				'$timeout',
				'ProjectService',
				function (
					$scope,
					PermissionService,
					toaster,
					gettextCatalog,
					$rootScope,
					$timeout,
					ProjectService
				) {
					let translatePermissionActionLibelles = {};
					translatePermissionActionLibelles.data =
						gettextCatalog.getString('Visible');
					translatePermissionActionLibelles.colInfo =
						gettextCatalog.getString('Info colonne');
					translatePermissionActionLibelles.colCaption =
						gettextCatalog.getString('Colonnes');
					translatePermissionActionLibelles.Chopped =
						gettextCatalog.getString('Hachée');

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopSavePermission = gettextCatalog.getString(
						'Enregistrement des permissions effectué'
					);
					let isPublicProject = true;

					var vm = this;
					var gridInstance;

					PermissionService.getElementPermissionRules().then(function (
						response
					) {
						$scope.hdhElementPermissions = response.data;
					});

					$scope.permissionActionLibelles = {
						data: translatePermissionActionLibelles.data,
						info: translatePermissionActionLibelles.colInfo,
						hashed: translatePermissionActionLibelles.Chopped,
					};

					vm.init = function () {
						ProjectService.isPublicProject(vm.projectId).then(function (
							response
						) {
							isPublicProject = response.data;
							$scope.initPermissions();
						});
					};

					$scope.initPermissions = function () {
						$scope.selectedOption = { data: false, hashed: false };
						var options = $scope.hdhElementPermissions['column'];
						var columns = [
							{
								dataField: 'lib',
								caption: translatePermissionActionLibelles.colCaption,
								allowFiltering: true,
								allowSorting: true,
								witdh: '250px;',
							},
						];
						for (var o in options) {
							if (
								options[o] == 'create' ||
								options[o] == 'list' ||
								options[o] == 'info' ||
								(options[o] === 'data' && isPublicProject)
							) {
								continue;
							}
							var col = {
								permission: options[o],
								dataField: options[o],
								caption: '',
								allowFiltering: false,
								allowSorting: false,
							};
							col.cellTemplate = 'permissionActionsEl';
							col.headerCellTemplate =
								"<div style='display: flex; width: 57.6%; justify-content: space-between; height:20%; margin-right: 12px;'>" +
								"<div style='display: flex; align-items: center;'>" +
								$scope.permissionActionLibelles[options[o]] +
								'</div>';
							col.headerCellTemplate =
								col.headerCellTemplate +
								"<div ng-if='selectedElements[0]' class='top-space-5'><md-switch ng-model=\"selectedOption['" +
								options[o] +
								'\']" ng-change="changePermissionManyElements(\'' +
								options[o] +
								'\')"></md-switch>' +
								'</div>' +
								'</div>';
							columns.push(col);
						}

						$scope.gridOptions = {
							scrolling: { mode: 'infinite' },
							headerFilter: { visible: true, applyFilter: 'auto' },
							filterRow: { visible: true, applyFilter: 'auto' },
							columnAutoWidth: true,
							showBorders: true,
							showColumnLines: true,
							showRowLines: true,
							columns: columns,
							dataSource: [],
						};
						$scope.gridOptions.selection = {
							mode: 'multiple',
							showCheckBoxesMode: 'always',
						};
						$scope.gridOptions.onSelectionChanged = function (selectedItems) {
							$scope.selectedElements = selectedItems.selectedRowsData;
							let selectAllData = _.find($scope.selectedElements, function (e) {
								return e.permissions && e.permissions.data;
							});
							let selectAllHashed = _.find(
								$scope.selectedElements,
								function (e) {
									return e.permissions && e.permissions.hashed;
								}
							);
							$scope.selectedOption = {
								data: selectAllData ? true : false,
								hashed: selectAllHashed ? true : false,
							};
						};
						$scope.gridOptions.onInitialized = function (e) {
							gridInstance = e.component;
						};
						PermissionService.getUserColumnPermissions(
							vm.data.userId,
							vm.data.isGroup,
							vm.data.metadataId
						).then(function (response) {
							$scope.gridOptions.dataSource = response.data;
							if (gridInstance && gridInstance.option) {
								$timeout(function () {
									gridInstance.option(
										'dataSource',
										$scope.gridOptions.dataSource
									);
									gridInstance.refresh();
								}, 150);
							}
						});
					};

					$scope.changePermissionManyElements = function (option) {
						let firstRequest;
						let secondRequest;
						let value = $scope.selectedOption[option];
						if (option === 'hashed') {
							let hashedCols = _.filter($scope.selectedElements, function (e) {
								return e.data_type === 'string';
							});
							let hashedColsId = _.map(hashedCols, function (e) {
								return e.metadata_id;
							});
							firstRequest = {
								user_id: vm.data.userId,
								is_group: vm.data.isGroup,
								action: 'hashed',
								metadata_ids: hashedColsId,
								permitted: value,
							};
							if (value) {
								let dataColsToUpdate = _.filter(
									$scope.selectedElements,
									function (e) {
										return (
											hashedColsId.indexOf(e.metadata_id) > -1 &&
											e.permissions &&
											!e.permissions.data
										);
									}
								);
								if (dataColsToUpdate && dataColsToUpdate.length > 0) {
									let dataColsToUpdateIds = _.map(
										dataColsToUpdate,
										function (e) {
											return e.metadata_id;
										}
									);
									secondRequest = {
										user_id: vm.data.userId,
										is_group: vm.data.isGroup,
										action: 'data',
										metadata_ids: dataColsToUpdateIds,
										permitted: true,
									};
								}
							}
						} else if (option === 'data') {
							let dataColsId = _.map($scope.selectedElements, function (e) {
								return e.metadata_id;
							});
							firstRequest = {
								user_id: vm.data.userId,
								is_group: vm.data.isGroup,
								action: 'data',
								metadata_ids: dataColsId,
								permitted: value,
							};
							if (!value) {
								let hashedColsToUpdate = _.filter(
									$scope.selectedElements,
									function (e) {
										return (
											dataColsId.indexOf(e.metadata_id) > -1 &&
											e.permissions &&
											e.permissions.hashed
										);
									}
								);
								if (hashedColsToUpdate && hashedColsToUpdate.length > 0) {
									let hashedColsToUpdateIds = _.map(
										hashedColsToUpdate,
										function (e) {
											return e.metadata_id;
										}
									);
									secondRequest = {
										user_id: vm.data.userId,
										is_group: vm.data.isGroup,
										action: 'hashed',
										metadata_ids: hashedColsToUpdateIds,
										permitted: false,
									};
								}
							}
						}

						if (firstRequest && option) {
							PermissionService.updateUserPermissionManyElements(
								vm.projectId,
								firstRequest
							).then(function () {
								if (
									secondRequest &&
									secondRequest.metadata_ids &&
									secondRequest.metadata_ids.length > 0
								) {
									PermissionService.updateUserPermissionManyElements(
										vm.projectId,
										secondRequest
									).then(function () {
										for (let i in $scope.gridOptions.dataSource) {
											if (
												firstRequest.metadata_ids.indexOf(
													$scope.gridOptions.dataSource[i].metadata_id
												) > -1
											) {
												let secondOption =
													option === 'data' ? 'hashed' : 'data';
												$scope.gridOptions.dataSource[i].permissions[option] =
													value;
												$scope.gridOptions.dataSource[i].permissions[
													secondOption
												] = value;
											}
										}
										if (gridInstance && gridInstance.option) {
											gridInstance.option(
												'dataSource',
												$scope.gridOptions.dataSource
											);
										}
										toaster.pop(
											'success',
											toasterPopSuccess,
											toasterPopSavePermission
										);
									});
								} else {
									for (let i in $scope.gridOptions.dataSource) {
										if (
											firstRequest.metadata_ids.indexOf(
												$scope.gridOptions.dataSource[i].metadata_id
											) > -1
										) {
											$scope.gridOptions.dataSource[i].permissions[option] =
												value;
										}
									}
									if (gridInstance && gridInstance.option) {
										gridInstance.option(
											'dataSource',
											$scope.gridOptions.dataSource
										);
									}
									toaster.pop(
										'success',
										toasterPopSuccess,
										toasterPopSavePermission
									);
								}
							});
						}
					};

					$scope.changePermission = function (item, code, byElement) {
						if (!byElement) {
							return;
						}
						var permission = {
							user_id: vm.data.userId,
							is_group: vm.data.isGroup,
							action: code,
							metadata_id: item.metadata_id,
							permitted: item.permissions[code],
						};
						PermissionService.updateUserPermission(
							vm.projectId,
							permission
						).then(function () {
							if (
								code == 'hashed' &&
								item.permissions[code] &&
								!item.permissions['data']
							) {
								PermissionService.updateUserPermission(vm.projectId, {
									user_id: vm.data.userId,
									is_group: vm.data.isGroup,
									action: 'data',
									metadata_id: item.metadata_id,
									permitted: true,
								}).then(function () {
									item.permissions['data'] = true;
									toaster.pop(
										'success',
										toasterPopSuccess,
										toasterPopSavePermission
									);
								});
							} else if (
								code == 'data' &&
								!item.permissions[code] &&
								item.permissions['hashed']
							) {
								PermissionService.updateUserPermission(vm.projectId, {
									user_id: vm.data.userId,
									is_group: vm.data.isGroup,
									action: 'hashed',
									metadata_id: item.metadata_id,
									permitted: false,
								}).then(function () {
									item.permissions['hashed'] = false;
									toaster.pop(
										'success',
										toasterPopSuccess,
										toasterPopSavePermission
									);
								});
							} else {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopSavePermission
								);
							}
						});
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						delete $scope.gridOptions;
						$(vm.element).modal('hide');
						vm.data.showColumnPermissions = false;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=', projectId: '=' },
				controller,
				'./src/components/directives/columnPermissions/columnPermissions.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						delete scope.gridOptions;
						scope.vm.data.showColumnPermissions = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showColumnPermissions;
						},
						function () {
							scope.vm.init();
						}
					);
				}
			);
		},
	]);
})();
