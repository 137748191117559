(function () {
	'use strict';

	angular.module('dcApp').factory('DeleteProjectAdapter', [
		'$rootScope',
		'gettextCatalog',
		function ($rootScope, gettextCatalog) {
			const DeleteProjectAdapter = {};
			DeleteProjectAdapter.getProjectElements = adaptProjectElements;
		// TO DO handle correctly export template translations
			function adaptProjectElements(projectElements) {
				projectElements = {
					publications: {
						elementType: gettextCatalog.getString('Publications'),
						elements: projectElements.publications
							? projectElements.publications
							: [],
						count: projectElements.publications
							? projectElements.publications.length
							: 0,
					},
					dashboards: {
						elementType: gettextCatalog.getString('Tableaux de bords'),
						elements: projectElements.dashboards
							? projectElements.dashboards
							: [],
						count: projectElements.dashboards
							? projectElements.dashboards.length
							: 0,
					},
					handleElements: {
						elementType: gettextCatalog.getString('Presentation Handle'),
						count: getTotalHandleElementsCount(projectElements),
					},
					datablockExpositions: {
						elementType: gettextCatalog.getString('Datablocks exposés'),
						elements: projectElements.data_block_expositions
							? projectElements.data_block_expositions
							: [],
						count: projectElements.data_block_expositions
							? projectElements.data_block_expositions.length
							: 0,
					},
					datablocks: {
						elementType: gettextCatalog.getString('Datablocks'),
						elements: projectElements.data_blocks
							? projectElements.data_blocks
							: [],
						count: projectElements.data_blocks
							? projectElements.data_blocks.length
							: 0,
					},
					entities: {
						elementType: gettextCatalog.getString('Entités métiers'),
						elements: projectElements.entities ? projectElements.entities : [],
						count: projectElements.entities
							? projectElements.entities.length
							: 0,
					},
					repositories: {
						elementType: gettextCatalog.getString('Dépots'),
						elements: projectElements.depots ? projectElements.depots : [],
						count: projectElements.depots ? projectElements.depots.length : 0,
					},
					exportTemplates: {
						elementType: gettextCatalog.getString('export.template'),
						elements: projectElements.export_template ? projectElements.export_template : [],
						count: projectElements.export_template ? projectElements.export_template.length : 0,
					},
					connectors: {
						elementType: gettextCatalog.getString('Connecteurs'),
						elements: projectElements.connectors
							? projectElements.connectors
							: [],
						count: projectElements.connectors
							? projectElements.connectors.length
							: 0,
					},
					formulaCatalogItems: {
						elementType: gettextCatalog.getString('Catalogue des formules'),
						elements: projectElements.formula_catalog_items
							? projectElements.formula_catalog_items
							: [],
						count: projectElements.formula_catalog_items
							? projectElements.formula_catalog_items.length
							: 0,
					},
					classifications: {
						elementType: gettextCatalog.getString('Classements'),
						elements: projectElements.classifications
							? projectElements.classifications
							: [],
						count: projectElements.classifications
							? projectElements.classifications.length
							: 0,
					},
					formattedData: formatDataAsTree(projectElements),
				};
				return projectElements;
			}

			function getTotalHandleElementsCount(projectElements) {
				var totalElements = 0;
				var handleElements = [
					'grids',
					'medias',
					'maps',
					'charts',
					'time_lines',
				];
				for (const handleElement of handleElements) {
					totalElements += projectElements[handleElement]
						? projectElements[handleElement].length
						: 0;
				}
				return totalElements;
			}

			function formatDataAsTree(projectElements) {
				var items = Object.entries(projectElements).map(
					([mainKey, mainValue], mainIndex) => {
						return {
							id: mainIndex + 1,
							text: adaptTextValue(mainKey, mainValue),
							expanded: false,
							items: Object.entries(mainValue).map(([key, value], index) => {
								return {
									id: mainIndex + 1 + '_' + (index + 1),
									text: value.label,
									expanded: false,
								};
							}),
						};
					}
				);
				return items.reverse();
			}

			function adaptTextValue(text, value) {
				switch (text.toLowerCase()) {
					case 'connectors':
						return (
							gettextCatalog.getString('Connecteurs') +
							' (' +
							value.length +
							')'
						);
					case 'dashboards':
						return (
							gettextCatalog.getString('Tablaux de bords') +
							' (' +
							value.length +
							')'
						);
					case 'data_blocks':
						return (
							gettextCatalog.getString('DataBlocks') + ' (' + value.length + ')'
						);
					case 'data_block_expositions':
						return (
							gettextCatalog.getString('Expositions') +
							' (' +
							value.length +
							')'
						);
						n;
					case 'medias':
						return (
							gettextCatalog.getString('Médias') + ' (' + value.length + ')'
						);
					case 'depots':
						return (
							gettextCatalog.getString('Dépôts') + ' (' + value.length + ')'
						);
					case 'entities':
						return (
							gettextCatalog.getString('Entités métier') +
							' (' +
							value.length +
							')'
						);
					case 'grids':
						return (
							gettextCatalog.getString('Tableaux') + ' (' + value.length + ')'
						);
					case 'charts':
						return (
							gettextCatalog.getString('Graphiques') + ' (' + value.length + ')'
						);
					case 'time_lines':
						return (
							gettextCatalog.getString('Timelines') + ' (' + value.length + ')'
						);
					case 'maps':
						return (
							gettextCatalog.getString('Cartes') + ' (' + value.length + ')'
						);
					case 'dashboards':
						return (
							gettextCatalog.getString('Tableaux de bord') +
							' (' +
							value.length +
							')'
						);
					case 'publications':
						return (
							gettextCatalog.getString('Publications') +
							' (' +
							value.length +
							')'
						);
					case 'formula_catalog_items':
						return (
							gettextCatalog.getString('Catalogue des formules') +
							' (' +
							value.length +
							')'
						);
					case 'classifications':
						return (
							gettextCatalog.getString('Classements') +
							' (' +
							value.length +
							')'
						);
					case 'export_templates':
						return (
							gettextCatalog.getString('export.template') +
							' (' +
							value.length +
							')'
						);
					default:
						return text.toLowerCase();
				}
			}

			return DeleteProjectAdapter;
		},
	]);
})();
