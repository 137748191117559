(function () {
	'use strict';

	angular.module('dcApp').directive('datapreview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'TypeEntiteService',
				function ($scope, TypeEntiteService) {
					const vm = this;

					vm.showData = showData;
					vm.closeWidget = closeWidget;
					vm.widgets = [];
					vm.widgetData = [];

					function showData(id, label, modal) {
						TypeEntiteService.getEntiteTypeGrammar(id).then(function (
							response
						) {
							if (response.data) {
								let grammar = response.data;
								for (var c in grammar.columns) {
									grammar.columns[c].uuid = generateUuid('_');
								}
								let wid = buildDataBlock({ grammar: grammar }, label, 1);
								vm.widgets = [];
								vm.widgets.push(wid);
								$(modal).modal('show');
							}
						});
					}

					function closeWidget() {
						vm.showDataPreview = false;
					}
				},
			];
			return buildDirectiveReturnObject(
				{ showDataPreview: '=', entityId: '=', entityLabel: '=' },
				controller,
				'./src/components/directives/dataPreview/dataPreview.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showDataPreview = false;
						$(element).on('hidden.bs.modal', function () {
							scope.$apply(function () {
								scope.vm.showDataPreview = false;
							});
						});
					});
					scope.$watch(
						function () {
							return scope.vm.showDataPreview;
						},
						function (value) {
							if (value == true) {
								scope.vm.showData(
									scope.vm.entityId,
									scope.vm.entityLabel,
									element
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				}
			);
		},
	]);
})();
