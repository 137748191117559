(function () {
	'use strict';

	angular.module('dcApp').directive('delay', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$window',
				'$interval',
				'toaster',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					$window,
					$interval,
					toaster,
					gettextCatalog
				) {
					var vm = this;
					/* FIXME: Add better interval management, they keep running all the time even when leaving page */
					$scope.init = function () {
						if (!vm.refresh) {
							updateDelay();
						} else {
							if (vm.refresh < 5000) {
								vm.refresh = 5000;
							}
							updateDelay();
							// max update at 10000
							$interval(updateDelay, vm.refresh, 10000);
						}
					};
					var parseDuration = function (str) {
						let int_d = parseFloat(str);
						let unit = str.replace(int_d, '');

						return moment.duration(int_d, unit) || undefined;
					};

					var updateDelay = function () {
						var alert_class = '';
						if (vm.dateformat) {
							var expire_date = moment(vm.originaldate, vm.dateformat);
						} else {
							var expire_date = moment(vm.originaldate);
						}
						let diff = expire_date - Date.now();
						let duration = moment.duration(diff);
						if (vm.alertThres && duration < parseDuration(vm.alertThres)) {
							alert_class = 'exp-alert';
						} else if (
							vm.warningThres &&
							duration < parseDuration(vm.warningThres)
						) {
							alert_class = 'exp-warning';
						}
						vm.formatDelay = duration.humanize(true);
						vm.thres_alert = alert_class;
					};
				},
			];
			return {
				restrict: 'E',

				scope: {
					originaldate: '=',
					prefixmsg: '@?',
					refresh: '@?',
					dateformat: '@?',
					warningThres: '@?',
					alertThres: '@?',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/delay/delay.html',
				transclude: true,
				replace: true,
				link: function (scope, elem, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
