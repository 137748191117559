(function () {
	'use strict';

	angular.module('dcApp').directive('textfilter', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'gettextCatalog',
				function ($scope, $rootScope, gettextCatalog) {
					$scope.texteSearchModes = [
						{
							code: 'sentence',
							label: gettextCatalog.getString('Dans la même phrase'),
						},
						{
							code: 'paragraph',
							label: gettextCatalog.getString('Dans le même paragraphe'),
						},
					];
					//	$scope.operatorTypes = ['contains', 'not_contains', 'contains_at_least_one', 'contains_all', 'contains_all', 'not_contains_any'] ;
					$scope.operatorTypes = [
						'contains',
						'not_contains',
						'equal',
						'not_equal',
					];

					$scope.init = function (rule) {
						if (rule) {
							$scope.rule = rule;
						} else {
							$scope.rule = $scope.vm.rule;
						}

						$scope.operators = _.filter(qbOperators, function (op) {
							return $scope.operatorTypes.indexOf(op.type) > -1;
						});
						_.each($scope.operators, function (op) {
							op.label = $rootScope.currentLangCode === 'fr' ? rules_operators_fr[op.type] : rules_operators_en[op.type];
						});

						if (!$scope.rule.operator) {
							$scope.rule.operator = $scope.operators[0].type;
						}
						$scope.searchInterval = {};
						$scope.searchInterval.value =
							$scope.rule.search_interval === true
								? 1
								: $scope.rule.search_in === true
								? 0
								: 1;
					};

					$scope.multiple = function () {
						var op = _.filter($scope.operators, function (op) {
							return op.type == $scope.rule.operator;
						})[0];
						return op.multiple;
					};

					$scope.withValue = function () {
						var op = _.filter($scope.operators, function (op) {
							return op.type == $scope.rule.operator;
						})[0];
						return op.nb_inputs == 1 && !op.multiple;
					};

					$scope.searchInterval = {};
					$scope.searchInterval.value = 1;
					$scope.trueValue = true;

					$scope.changeSearchModeRadio = function () {
						if ($scope.searchInterval.value == 1) {
							$scope.changeIntervalMode();
						} else {
							$scope.changeSearchInMode();
						}
					};

					$scope.changeSearchInMode = function () {
						$scope.rule.search_interval = false;
						$scope.rule.search_in = true;
						$scope.searchInterval.value = 0;
						delete $scope.rule.search_interval_nb;
					};

					$scope.changeIntervalMode = function () {
						$scope.rule.search_in = false;
						$scope.rule.search_interval = true;
						$scope.searchInterval.value = 1;
						delete $scope.rule.search_in_mode;
					};

					$scope.openSelectTopValue = function () {
						vm.widgetData.defautValue = $scope.rule.value;

						vm.widgetData.caracId = $scope.rule.carac_id;
						vm.widgetData.updateaction_l2 = $scope.saveTopCaracValue;

						vm.widgetData.showTopCaracValue = true;
					};

					$scope.openValueList = function () {
						$scope.listValue = angular.copy($scope.rule.value_list);

						//		  vm.widgetData.columns = $scope.selectedWidget.grammar.columns ;
						vm.widgetData.listValue = $scope.listValue;
						vm.widgetData.selectedRule = $scope.rule;
						vm.widgetData.updateaction_l2 = $scope.saveListValue;
						vm.widgetData.showListValueFilter = true;
					};

					$scope.openSelectColumn = function () {
						//  		  vm.widgetData.columns = $scope.selectedWidget.grammar.columns ;
						vm.widgetData.valueColumn = $scope.rule.valueColumn;
						vm.widgetData.selectedRule = $scope.rule;
						vm.widgetData.updateaction_l2 = $scope.saveSelectColumn;
						vm.widgetData.showValueColumnFilter = true;
					};

					$scope.deleteSelectedColumn = function () {
						delete $scope.rule.valueColumn;
					};

					$scope.saveSelectColumn = function () {
						$scope.rule.valueColumn = vm.widgetData.valueColumn.column;
						$scope.valueColumnAlias = _.filter(
							vm.widgetData.columns,
							function (col) {
								return col.id == $scope.rule.valueColumn;
							}
						)[0].field;
					};

					$scope.saveListValue = function () {
						$scope.rule.value_list = $scope.vm.widgetData.listValue;
					};

					$scope.saveTopCaracValue = function (value) {
						$scope.rule.value = value;
					};

					var vm = this;
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					rule: '=',
					enableSearchMode: '=',
					showLabels: '=',
				},

				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/textFilter/textFilter.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.$watch(
						function () {
							return scope.vm.rule;
						},
						function () {
							if (scope.vm.rule) {
								scope.init();
							} else {
								scope.vm.widgetData.initFilter = scope.init;
							}
						}
					);
				},
			};
		},
	]);
})();
