(function () {
	'use strict';

	angular.module('dcApp').factory('FormulaCatalogService', [
		'$rootScope',
		'$http',
		'API_BASE_URL_BACKEND',
		'UrlService',
		function ($rootScope, $http, API_BASE_URL_BACKEND, UrlService) {
			let FormulaCatalogService = {};

			FormulaCatalogService.createItem = function (item) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('FORMULA_CATALOG');
				return $http.post(url, item).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.updateItem = function (itemId, item) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('FORMULA_CATALOG_ONE');
				url = url.replace('{itemId}', itemId);
				return $http.put(url, item).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.findAll = function (shared, excludeInactive) {
				const url = UrlService.generateUrl('FORMULA_CATALOG', {},
					{
						shared: typeof shared === 'boolean' ? shared : true,
						excludeInactive: typeof excludeInactive === 'boolean' ? excludeInactive : false,
					}
				);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.deleteItem = function (itemId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('FORMULA_CATALOG_ONE');
				url = url.replace('{itemId}', itemId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.deleteSelectedItems = function (selectedItems) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('FORMULA_CATALOG');
				if (Array.isArray(selectedItems)) {
					url = url + '?itemsIds=' + selectedItems[0];
					for (let i = 1; i < selectedItems.length; i++) {
						url = url + '&itemsIds=' + selectedItems[i];
					}
				} else {
					url = url + '?itemsIds=' + selectedItems;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.updateSharedStatus = function (itemId, shared) {
				shared = shared !== undefined ? shared : false;
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('FORMULA_CATALOG_ONE_STATUS');
				url = url.replace('{itemId}', itemId) + '?shared=' + shared;
				return $http.put(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.countAll = function () {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('FORMULA_CATALOG_COUNT');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.integrateImportedElements = function (importId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('FORMULA_CATALOG_IMPORT_INTEGRATE');
				url = url + '?importId=' + importId;
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			FormulaCatalogService.duplicateFormula = function (elmId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('FORMULA_CATALOG_ONE_DUPLICATE');
				url = url.replace('{itemId}', elmId);
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return FormulaCatalogService;
		},
	]);
})();
