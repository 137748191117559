(function () {
	'use strict';

	angular.module('dcApp').directive('treemapchartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'$window',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					$window,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;

					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'treemap',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope($scope, $state, vm);

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					var loadGroupDataInChart = function (data) {
						$scope.config.global.onLegendClick = function (e) {
							var arg = e.target;
							toggleVisibility(this.getAllSeries()[0].getPointsByArg(arg)[0]);
						};
						initPopover();
						if (
							$scope.config.global.tooltipColumns &&
							$scope.config.global.tooltipColumns[0]
						) {
							$scope.config.global.tooltip = {
								enabled: true,
								format: 'fixedPoint',
								customizeTooltip: function (arg) {
									return {
										html: arg.node.data.data
											? ChartService.getTooltip(
													arg.node.data.data,
													$scope.config.global.tooltipColumns,
													$scope.group.columns,
													$scope.config.global.argumentAxis
											  )
											: '',
									};
								},
							};
						}

						$scope.dxChartOptions = $scope.config.global;
						$scope.dxChartOptions.dataSource = formatData(
							data,
							$scope.config.global.value,
							$scope.config.global.group1,
							$scope.config.global.group2,
							$scope.config.global.group3
						);

						$scope.dxChartOptions.colorizer = {
							palette: 'harmony light',
						};
						let paletteColors;
						if (
							$scope.config.global.colorizerType &&
							($scope.config.global.startColor || $scope.config.global.endColor)
						) {
							paletteColors = [];
							if ($scope.config.global.startColor) {
								paletteColors.push($scope.config.global.startColor);
							}
							if ($scope.config.global.endColor) {
								paletteColors.push($scope.config.global.endColor);
							}
						} else {
							paletteColors = $scope.config.global.palette;
							$scope.dxChartOptions.colorizer.palette = paletteColors
								? paletteColors
								: $scope.dxChartOptions.colorizer.palette;
						}
						if ($scope.config.global.colorizerType == 'discrete') {
							$scope.dxChartOptions.colorizer = {
								type: 'discrete',
								colorizeGroups: false,
								palette: 'harmony light',
							};
						}

						if ($scope.config.global.colorizerType == 'grouped') {
							$scope.dxChartOptions.colorizer = {
								type: 'discrete',
								colorizeGroups: true,
								palette: paletteColors,
							};
						}

						if ($scope.config.global.colorizerType == 'range') {
							$scope.dxChartOptions.colorizer = {
								type: 'range',
								colorizeGroups: false,
								palette: paletteColors,
								range: [$scope.config.global.rangeValues],
							};
						}

						if ($scope.config.global.colorizerType == 'gradient') {
							$scope.dxChartOptions.colorizer = {
								type: 'gradient',
								colorizeGroups: false,
								palette: paletteColors,
								range: $scope.config.global.rangeValues,
							};
						}

						if ($scope.dxChartOptions.interactWithGroup) {
							$scope.dxChartOptions.maxDepth = 2;

							$scope.dxChartOptions.onClick = function (e) {
								e.node.drillDown();
							};

							$scope.dxChartOptions.onDrill = function (e) {
								var markup = $('#drill-down-title').empty(),
									node;
								for (node = e.node.getParent(); node; node = node.getParent()) {
									markup.prepend(' > ').prepend(
										$('<span />')
											.addClass('link')
											.text(node.label() || 'Tout')
											.data('node', node)
											.on('dxclick', onLinkClick)
									);
								}
								if (markup.children().length) {
									markup.append(e.node.label());
								}
							};
						}

						delete $scope.dxChartOptions.group1;
						delete $scope.dxChartOptions.group2;
						delete $scope.dxChartOptions.group3;
						delete $scope.dxChartOptions.value;
						delete $scope.dxChartOptions.colorizerType;
						delete $scope.dxChartOptions.startColor;
						delete $scope.dxChartOptions.endColor;
						delete $scope.dxChartOptions.rangeValues;

						$('#chart' + $scope.uuid).dxTreeMap($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxTreeMap('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxTreeMap('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var formatData = function (items, value, group1, group2, group3) {
						if (!group2) {
							return _.map(items, function (item) {
								return { name: item[group1], value: item[value], data: item };
							});
						}
						let grouped = _.groupBy(items, group1);
						let result = [];
						for (var g in grouped) {
							result.push({
								name: g,
								items: formatData(grouped[g], value, group2, group3),
							});
						}
						return result;
					};

					function onLinkClick(e) {
						$(e.target).data('node').drillDown();
					}
					$scope.onLinkClick = function () {
						this.label.node.drillDown();
					};
					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/treemap/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
