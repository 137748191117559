var getScaleLabel = function (scale) {
	switch (scale) {
		case 'millisecond':
			return 'ms';
		case 'second':
			return 's';
		case 'minute':
			return 'mn';
		case 'hour':
			return 'h';
		case 'day':
			return 'jour';
		case 'weekday':
			return 'sem';
		case 'month':
			return 'mois';
		case 'year':
			return 'années';
		default:
			return new Date(date);
	}
};

var getDiffMS = function (date1, date2) {
	if (!date1 || !date2) {
		return 0;
	} else {
		return date2 - date1;
	}
};

var getDiff = function (date1, date2) {
	var now = moment(date2).format('DD/MM/YYYY HH:mm:ss');
	var then = moment(date1).format('DD/MM/YYYY HH:mm:ss');

	moment.tz.add('Etc/UTC|UTC|0|0|');
	var ms = moment
		.tz(now, 'DD/MM/YYYY HH:mm:ss', 'Etc/UTC')
		.diff(moment.tz(then, 'DD/MM/YYYY HH:mm:ss', 'Etc/UTC'));
	var d = moment.duration(ms);
	var diff = '';

	if (Math.trunc(d.asDays()) > 0) {
		diff = diff + Math.trunc(d.asDays()) + ' Jours ';
	}
	if (d.hours() > 0) {
		diff = diff + d.hours() + ' Heures ';
	}
	if (d.minutes() > 0) {
		diff = diff + d.minutes() + ' Min ';
	}
	if (d.seconds() > 0) {
		diff = diff + d.seconds() + ' Sec ';
	}
	return diff;
};

var getDiffEnSec = function (diff) {
	return diff + ' Sec';
};

var getDiffEnMin = function (diff) {
	if (diff < 60) {
		return getDiffEnSec(diff);
	}
	var diffS = diff % 60;
	var diffM = (diff - diffM) / 60;
	return diffM + ' Min ' + (diffS > 0 ? getDiffEnSec(diffS) : '');
};

var getDiffEnHour = function (diff) {
	if (diff < 3600) {
		return getDiffEnMin(diff);
	}
	var diffM = diff % 3600;
	var diffH = (diff - diffM) / 3600;
	return diffH + ' Heures ' + (diffM > 0 ? getDiffEnMin(diffM) : '');
};

var getDiffEnJour = function (diff) {
	if (diff < 86400) {
		return getDiffEnHour(diff);
	}
	var diffH = diff % 86400;
	var diffJ = (diff - diffH) / 86400;
	return diffJ + ' Jours ' + (diffH > 0 ? getDiffEnJour(diffH) : '');
};

var getFirstAndLastDate = function (data) {
	var dates = {};
	for (var d in data) {
		if (!dates.first) {
			dates.first = data[d].start;
			dates.last = data[d].start;
		}
		if (data[d].start < dates.first) {
			dates.first = data[d].start;
		}
		if (data[d].end && data[d].end > dates.last) {
			dates.last = data[d].end;
		} else if (data[d].start > dates.last) {
			dates.last = data[d].start;
		}
	}
	return dates;
};

//sub timeline functions
var getDefaultTimelineDates = function (tlContainer) {
	var timeline = tlContainer.parentTimeline;
	var intervalDates = getLiveIntervalDates(timeline);
	var indicatorDate = getLiveIndicatorDates(timeline);

	return {
		min: intervalDates.start,
		max: intervalDates.end,
		start: indicatorDate.start,
		end: indicatorDate.end,
	};
};

var setTimeLineDateFromLiveInterval = function (tlContainer) {
	tlContainer.timeline.setOptions(getDefaultTimelineDates(tlContainer));
};

var draw = function (tlContainer) {
	tlContainer.timeline = new vis.Timeline(
		tlContainer.el,
		tlContainer.items,
		tlContainer.groups,
		tlContainer.options
	);
};

var zoom = function (dir, tlContainer) {
	var range = tlContainer.timeline.getWindow();
	var interval = range.end - range.start;

	tlContainer.timeline.setWindow({
		start: range.start.valueOf() - interval * dir * 0.2,
		end: range.end.valueOf() + interval * dir * 0.2,
	});
};

var getTimeByX = function (x, tlContainer) {
	return tlContainer.timeline.body.util.toTime(x);
};

var getXByTime = function (time, tlContainer) {
	return tlContainer.timeline.body.util.toGlobalScreen(time);
};

var setGroupedItems = function (tlContainer) {
	var g = getClusters(tlContainer);
	tlContainer.items = new vis.DataSet(g);
	tlContainer.timeline.setItems(tlContainer.items);
};

var getDiffDate = function (date1, date2) {
	//Get 1 second in milliseconds
	var second = 1000;
	var minute = second * 60;
	var hour = minute * 60;
	var day = hour * 24;
	var month = day * 31;
	var year = month * 12;

	// Convert both dates to milliseconds
	var date1_ms = date1.getTime();
	var date2_ms = date2.getTime();

	// Calculate the difference in milliseconds
	var difference_ms = date2_ms - date1_ms;

	// Convert back to days and return
	return {
		years: Math.round(difference_ms / year),
		months: Math.round(difference_ms / month),
		days: Math.round(difference_ms / day),
		hours: Math.round(difference_ms / hour),
		minutes: Math.round(difference_ms / minute),
	};
};

var getScale = function (tlContainer) {
	var start = tlContainer.timeline.getWindow().start;
	var end = tlContainer.timeline.getWindow().end;
	var diff = getDiffDate(start, end);

	if (diff.months > 12 * 2) {
		return 'year';
	} else if (diff.days > 31) {
		return 'month';
	} else if (diff.hours > 24) {
		return 'day';
	} else if (diff.minutes > 60) {
		return 'hour';
	} else {
		return 'second';
	}
};

var getClusters = function (tlContainer) {
	var scale = getScale(tlContainer);

	var group = tlContainer.groupedItems;
	var result = {};

	var formatByScale = {
		year: 'Y',
		month: 'Y-M',
		weekday: 'Y-M-week-W',
		day: 'Y-M-day-D',
		hour: 'Y-M-D-H',
		second: 'Y-M-D-H-m',
	};

	group.forEach(function (items, i) {
		items.forEach(function (item, j) {
			var index =
				moment(item.start).format(formatByScale[scale]) + '-group' + item.group;

			if (!result[index]) {
				result[index] = {
					firstid: item.id,
					count: 1,
					items: [],
					group: item.group,
					start: item.start,
				};
			}

			result[index].items.push(item);
			result[index].end = item.end ? item.end : item.start;
			result[index].className = item.end
				? 'vis-event-item'
				: 'vis-default-item';
			result[index].count++;
		});
	});

	return _.toArray(result);
};

var initEvents = function (tlContainer) {
	tlContainer.timeline.on('rangechange', function (event) {
		var indicator = tlContainer.parentTimeline.indicator;
		indicator.start = event.start;
		indicator.end = event.end;
		tlContainer.parentTimeline.items.update(indicator);
	});

	tlContainer.timeline.on('rangechanged', function () {
		setGroupedItems(tlContainer);
	});
};

var setOptions = function (options, tlContainer) {
	tlContainer.timeline.setOptions(options);
	tlContainer.options = _.extend(tlContainer.options, options);
};

var updateIndicatorPosition = function (tlContainer) {
	var width = $(
		'#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid
	).width();
	if (
		width >=
		$('#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid).width()
	) {
		width =
			$('#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid).width() -
			20;
	}

	var left =
		$('#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid).position()
			.left +
		$('#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid).width() / 2 -
		width / 2;
	$('#tp' + tlContainer.uuid + ' .indicator' + tlContainer.uuid).css({
		left: left,
		width: width,
	});
};

var updateInterval = function (tlContainer) {
	var interval = tlContainer.interval;
	var date = getLiveIntervalDates(tlContainer);
	interval.start = date.start;
	interval.end = date.end;
	var indicator = tlContainer.indicator;
	date = getLiveIndicatorDates(tlContainer);

	indicator.start = date.start;
	indicator.end = date.end;

	tlContainer.items.update(interval);
	tlContainer.items.update(indicator);
};

var updateIntervalWithDate = function (start, end, tlContainer) {
	var interval = tlContainer.interval;
	interval.start = start;
	interval.end = end;

	var indicator = tlContainer.indicator;
	let date = getLiveIndicatorDates(tlContainer);

	indicator.start = date.start;
	indicator.end = date.end;

	tlContainer.items.update(interval);
	tlContainer.items.update(indicator);
};

/**
 * computed Live interval date
 * from left and width
 */
var getLiveIntervalDates = function (tlContainer) {
	var bounds = getLiveIntervalBoundaries(tlContainer);

	return {
		start: getTimeByX(bounds.start, tlContainer),
		end: getTimeByX(bounds.end, tlContainer),
	};
};

/**
 * Live indicator date computed
 * from left and width
 */
var getLiveIndicatorDates = function (tlContainer) {
	var bounds = getLiveIndicatorBoundaries(tlContainer);

	return {
		start: getTimeByX(bounds.start, tlContainer),
		end: getTimeByX(bounds.end, tlContainer),
	};
};

var getLiveIntervalBoundaries = function (tlContainer) {
	var el = $('#tp' + tlContainer.uuid + ' .interval' + tlContainer.uuid);
	var offset = el.position();

	return {
		start: offset.left,
		end: offset.left + el.width(),
	};
};

var getLiveIndicatorBoundaries = function (tlContainer) {
	var el = $('#tp' + tlContainer.uuid + ' .indicator' + tlContainer.uuid);
	var offset = el.position();

	return {
		start: offset.left,
		end: offset.left + el.width(),
	};
};

var getDummyDates = function (coef, tlContainer) {
	var range = tlContainer.options;
	var step = Math.floor(range.end.valueOf() - range.valueOf().start) / coef;
	return {
		start: range.start.valueOf() + step,
		end: range.end.valueOf() - step,
	};
};

var initInterval = function (tlContainer) {
	var dates = {
		start: tlContainer.options.start.valueOf(),
		end: tlContainer.options.end.valueOf(),
	};
	tlContainer.interval = _.extend(
		{
			id: tlContainer.uuid + 'interval',
			type: 'background',
			className: 'interval' + tlContainer.uuid,
		},
		dates
	);
	tlContainer.indicator = _.extend(
		{
			id: tlContainer.uuid + 'indicator',
			type: 'background',
			className: 'indicator' + tlContainer.uuid,
		},
		getDummyDates(8, tlContainer)
	);

	tlContainer.items.update(tlContainer.interval);
	tlContainer.items.update(tlContainer.indicator);
};

var initEvents_Main = function (tlContainer) {
	tlContainer.timeline.on('rangechange', function (event) {});

	tlContainer.timeline.on('rangechanged', function () {
		setGroupedItems(tlContainer);
	});
};
