(function () {
	'use strict';
	angular.module('dcApp').factory('RequestInterceptor', RequestInterceptor);

	RequestInterceptor.$inject = [
		'$q',
		'$rootScope',
		'AppSelectorService',
		'API_BASE_URL_BACKEND',
	];

	function RequestInterceptor(
		$q,
		$rootScope,
		AppSelectorService,
		API_BASE_URL_BACKEND
	) {
		let numLoadings = 0;
		$rootScope.showSpinner = false;
		return {
			request: requestHandler,
			response: function (response) {
				if (--numLoadings === 0) {
					$rootScope.$broadcast('loader_hide');
					$rootScope.showSpinner = false;
				}

				return response || $q.when(response);
			},
			responseError: function (response) {
				if (!--numLoadings) {
					$rootScope.$broadcast('loader_hide');
					$rootScope.showSpinner = false;
				}

				return $q.reject(response);
			},
		};

		function requestHandler(config) {
			numLoadings++;
			$rootScope.$broadcast('loader_show');
			$rootScope.showSpinner = true;
			config.params = config.params || {};
			if (
				config.url.startsWith(API_BASE_URL_BACKEND) &&
				AppSelectorService.getSelectedCtxId() !== null
			) {
				config.params.ctx = AppSelectorService.getSelectedCtxId();
			}
			if (
				config.url.startsWith(API_BASE_URL_BACKEND) &&
				AppSelectorService.getSelectedProjectId() !== null &&
				config.params
			) {
				config.params.project = AppSelectorService.getSelectedProjectId();
			}

			// encode URL
			// Replace + by  "%2B"
			let urlAsArray = config.url.replace('+', '%2B').split('?');
			// get url without params
			let encodedURL = encodeURI(urlAsArray[0]);
			// remove url without params from url
			urlAsArray.shift();
			// Encode Params
			if (urlAsArray.length > 0) {
				let urlSearchStr = urlAsArray.join('?');
				let searchParams = new URLSearchParams(urlSearchStr);
				// create new URL
				encodedURL =
					encodedURL +
					'?' +
					Array.from(searchParams.entries())
						.map((p) => p[0] + '=' + encodeURIComponent(p[1]))
						.join('&');
			}
			config.url = encodedURL;

			return config || $q.when(config);
		}
	}
})();
