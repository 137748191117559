'use strict';

angular
	.module('dcApp')
	.filter('secondsToDateTime', [
		function () {
			/**
			 * This code returns a date string formatted manually.
			 * Code "new Date(1970, 0, 1).setSeconds(seconds)" returns malformed output on days.
			 * Eg. 4 days, magically becomes 5, 15 becomes 16 and so on...;
			 * */
			return function (seconds) {
				var days = Math.floor(seconds / 86400);
				var hours = Math.floor((seconds % 86400) / 3600);
				var mins = Math.floor(((seconds % 86400) % 3600) / 60);
				var secs = ((seconds % 86400) % 3600) % 60;
				return (
					(days > 0 ? days + 'd ' : '') +
					('00' + hours).slice(-2) +
					':' +
					('00' + mins).slice(-2) +
					':' +
					('00' + secs).slice(-2)
				);
			};
		},
	])
	.filter('fileSize', function () {
		return function (size) {
			if (size === '-') return '-';

			if (isNaN(size)) size = 0;

			if (size < 1024) return size + ' Bytes';

			size /= 1024;

			if (size < 1024) return size.toFixed(2) + ' Kb';

			size /= 1024;

			if (size < 1024) return size.toFixed(2) + ' Mb';

			size /= 1024;

			if (size < 1024) return size.toFixed(2) + ' Gb';

			size /= 1024;

			return size.toFixed(2) + ' Tb';
		};
	})
	.filter('connectorTranslator', [
		'gettextCatalog',
		function (gettextCatalog) {
			return function (item) {
				let connectorTranslatorFile = gettextCatalog.getString('fichier');
				let connectorTranslatorDb = gettextCatalog.getString('bdd');
				item = item != undefined ? item.toUpperCase() : item;
				switch (item) {
					case 'SQL':
					case 'MONGODB':
					case 'NEO4J':
						return connectorTranslatorDb;
					case 'HTTP':
						return 'HTTP';
					case 'fichier':
						return connectorTranslatorFile;
					case 'S3':
						return 'S3 / MinIO';
					case 'SFTP':
						return 'SFTP';
					case 'HDFS':
						return 'HDFS';
					case 'LOCAL':
					case undefined:
						return '';
					default:
						return '-';
				}
			};
		},
	]);
