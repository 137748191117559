(function () {
	'use strict';

	angular.module('dcApp').directive('basicErrorDialog', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					$scope.popupOptions = {
						width: 600,
						height: 160,
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'vm.data.visiblePopup',
							title: 'vm.data.popupTitle',
						},
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dialog/basicErrorDialog/basicErrorDialog.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {},
			};
		},
	]);
})();
