(function () {
	'use strict';

	angular.module('dcApp').directive('sendMessageToOwner', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'DataCatalogService',
				'toaster',
				'gettextCatalog',
				function ($scope, DataCatalogService, toaster, gettextCatalog) {
					var vm = this;

					vm.message = {};
					vm.closeModal = closeModal;
					vm.sendMessage = sendMessage;

					function sendMessage() {
						DataCatalogService.sendMailToElementAdmin(
							vm.data.elementId,
							vm.message
						).then(function (response) {
							let toasterPopSuccess = gettextCatalog.getString('Succès');
							let toasterPopSaveSuccess = gettextCatalog.getString(
								'mail.send.notification'
							);
							toaster.pop('success', toasterPopSuccess, toasterPopSaveSuccess);
							vm.data.sendMessageDialog = false;
						});
					}

					function closeModal() {
						$(vm.element).modal('hide');
						vm.data.sendMessageDialog = false;
					}
				},
			];
			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dataCatalog/sendMessageDialog/sendMessageDialog.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.sendMessageDialog = false;
						scope.vm.message = {};
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data ? scope.vm.data.sendMessageDialog : false;
						},
						function () {}
					);
				}
			);
		},
	]);
})();
