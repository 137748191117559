'use strict';

angular
	.module('dcApp')
	.controller('MediaListController', [
		'$location',
		'$scope',
		'$http',
		'$state',
		'$stateParams',
		'$rootScope',
		'MediaService',
		'toaster',
		'$q',
		'API_BASE_URL_BACKEND',
		'DEFAULT_CELL_TEMPLATE',
		'$timeout',
		'PermissionService',
		'gettextCatalog',
		'DcElementListService',
		function (
			$location,
			$scope,
			$http,
			$state,
			$stateParams,
			$rootScope,
			MediaService,
			toaster,
			$q,
			API_BASE_URL_BACKEND,
			DEFAULT_CELL_TEMPLATE,
			$timeout,
			PermissionService,
			gettextCatalog,
			DcElementListService
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteMediasSuccess = gettextCatalog.getString(
				'Suppression des Médias effectuée'
			);
			let toasterPopDeleteMediaSuccess = gettextCatalog.getString(
				'Suppression du Média effectuée'
			);
			let toasterPopDuplicateMediaSuccess = gettextCatalog.getString(
				'Duplication Média effectuée'
			);
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.checkPermissionData = {};

			PermissionService.haveGlobalPermission('media-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'media';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteBulkMediasModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkMedias = function () {
				let mediaIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				MediaService.deleteMedias(mediaIds)
					.then(function () {
						if ($scope.media_authorised) {
							initMedias();
						}
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteMediasSuccess
						);
					})
					.catch(function (e) {
						if (e && e.data && e.data.code == '40' && $scope.media_authorised) {
							initMedias();
						}
					});
			};

			$scope.editMedia = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'media';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.duplicateMedia = function (obj) {
				MediaService.duplicateMedia(obj.type, obj.id).then(function (response) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopDuplicateMediaSuccess
					);
					$state.go('medias-edit', { id: response.data, type: obj.type });
				});
			};

			$scope.createMedia = function () {
				$state.go('medias-select', {});
			};

			$scope.goToEdit = function () {
				$state.go('medias-edit', {
					id: $scope.objToEdit.id,
					type: $scope.objToEdit.type,
				});
			};

			$scope.viewMedia = function (obj) {
				$state.go('medias-view', {
					id: obj.id,
					type: obj.type,
					mode: 'visualisation',
				});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					alert: gettextCatalog.getString('media.delete.all-files.deleted'),
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkMedias
						: $scope.deleteMedia,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.deleteMedia = function () {
				MediaService.deleteMedia(
					$scope.objToDelete.type,
					$scope.objToDelete.id
				).then(function (response) {
					afterDeleteMedia();
				});
			};

			var afterDeleteMedia = function () {
				toaster.pop('success', toasterPopSuccess, toasterPopDeleteMediaSuccess);
				initMedias();
			};

			function getListMedias() {
				var deferred = $q.defer();
				MediaService.getAllMedias($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);

				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListMedias().then(function (content) {
					if (content !== undefined) {
						$scope.medias = [];
						$scope.tags = [];
						var tags = [];
						for (var r in content) {
							$scope.medias.push({
								id: content[r].id,
								code: content[r].metadata.code,
								type: content[r].type,
								sub_type: content[r].sub_type,
								lc: content[r].metadata.label,
								desc: content[r].metadata.description,
								tags: content[r].metadata.tags,
								icon_id: content[r].metadata.icon_id,
								tagAsArray: $rootScope.mapTags(content[r].metadata.tags),
								creationDate: $rootScope.getDateTimeWithPattern(
									content[r].metadata.creation_date
								),
								updateDate: $rootScope.getDateTimeWithPattern(
									content[r].metadata.update_date
								),
								createdBy: content[r].metadata.created_by,
								updatedBy: content[r].metadata.updated_by,
								project: content[r].metadata.project,
							});
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.showGrid = true;
						$scope.gridData = angular.copy($scope.medias);
						$scope.dataGridOptions.dataSource = $scope.gridData;
					}
				});
			};

			function initMedias() {
				PermissionService.haveGlobalPermission('media').then(function (
					response
				) {
					$scope.media_authorised = response.data;
					if ($scope.media_authorised) {
						$scope.getGridData();
						$scope.disableAllSelectedElems = true;
					}
				});
			}

			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'media', false);
			initMedias();

			$scope.moveOrCopyData = {};
			$scope.moveOrCopyElementToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyData = {
					elements: connectorIds,
					type: 'media',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneElementToProject = function (elementId) {
				$scope.moveOrCopyData = {
					elements: [elementId],
					type: 'media',
					showMoveCopy: true,
				};
			};
		},
	])
	.controller('MediaEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'MediaService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'hdSourceService',
		'ChartService',
		'gettextCatalog',
		'PermissionService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			MediaService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			hdSourceService,
			ChartService,
			gettextCatalog,
			PermissionService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Médias');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteMediaSuccess = gettextCatalog.getString(
				'Suppression du Média effectuée'
			);
			let toasterPopSaveParamsSuccess = gettextCatalog.getString(
				'Enregistrement des paramètres effectué'
			);
			let toasterPopCreateMediaSuccess = gettextCatalog.getString(
				'Création du Média effectuée'
			);
			let toasterPopUpdateMediaSuccess = gettextCatalog.getString(
				'Modification du Média effectuée'
			);
			let toasterPopDeleteMediaItemSuccess = gettextCatalog.getString(
				"Suppression de l'élément Média effectuée"
			);

			$scope.accessToken = window._keycloak.token;
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};

			$scope.mediaView = {};

			$scope.getHelpCode = function () {
				let code = 'MEDIA_';
				switch ($scope.type) {
					case 'image':
						code = code + 'IMAGE_';
						break;
					case 'pdf':
						code = code + 'PDF_';
						break;
					default:
						code = code + '_';
				}

				return code + ($scope.isNew ? 'CREATE' : 'EDIT');
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('medias');
				}, 300);
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.media && $scope.media.metadata
							? $scope.media.metadata.label
							: undefined,
					alert: gettextCatalog.getString('media.delete.all-files.deleted'),
					doDelete: $scope.deleteMediaElement,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.media.id;
				$scope.checkPermissionData.type = 'media';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteMediaElement = function () {
				MediaService.deleteMedia($scope.media.type, $scope.media.id).then(
					function (response) {
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteMediaSuccess
						);
						$timeout(function () {
							$state.go('medias');
						}, 300);
					}
				);
			};

			$scope.toggleShowTabs = function () {
				$scope.hideTabs = !$scope.hideTabs;
			};
			//handle data source directive
			$scope.dataSource = { onOpenSource: $scope.toggleShowTabs };

			$scope.showDataBlockViews = function (saveSelectedDataBlockViews) {
				$scope.widgetData.saveSelectedDataBlockViews =
					saveSelectedDataBlockViews;
				$scope.widgetData.showDataBlockViews = true;
			};

			$scope.dataSource.showDataBlockViews = $scope.showDataBlockViews;

			$scope.showSources = function () {
				if (!$scope.configSaved && $scope.config) {
					$scope.saveConfig(showSources);
					return;
				}
				showSources();
			};
			//end

			var showSources = function () {
				delete $scope.mediaView.mediaId;
				$scope.dataSource.reinit();
				$scope.configSaved = true;
			};

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.media.metadata.label;
				$scope.elementGraphData.centeredElementType = 'MEDIA';
				$scope.elementGraphData.centeredElementId = $scope.media.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.media.id;
				$scope.checkPermissionData.type = 'media';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.media.metadata.id;
				$scope.permissionData.type = 'media';
				$scope.permissionData.elementId = $scope.media.id;
				$scope.permissionData.lib = $scope.media.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showConfig = function () {
				delete $scope.mediaView.mediaId;
				$scope.configSaved = false;

				MediaService.getMediaConfig($stateParams.type, $stateParams.id).then(
					function (response) {
						$scope.config = response.data;
						loadMediaItems();

						ChartService.getChartOptions().then(function (
							responseChartOptions
						) {
							initConstants(responseChartOptions.data);
							if (
								$scope.config.global_config.title.text == undefined &&
								$scope.config.groups[0]
							) {
								$scope.config.global_config.title.text =
									$scope.config.groups[0].lib;
								$scope.config.global_config.title.subtitle.text =
									$scope.config.groups[0].desc;
							}
						});
					}
				);
			};

			var loadMediaItems = function () {
				delete $scope.itemsGridOptions;
				MediaService.getAllMediaItems($stateParams.id).then(function (
					response
				) {
					$scope.itemsGridOptions = angular.copy(itemsGridOptions);
					$scope.itemsGridOptions.dataSource = {
						store: new DevExpress.data.ArrayStore({
							key: 'id',
							data: response.data,
						}),
						reshapeOnPush: true,
					};
				});
			};

			var initConstants = function (data) {
				$scope.mediaOptions = data;
			};

			$scope.saveConfig = function (afterSave) {
				if (!checkConfig()) {
					return;
				}
				var config = angular.copy($scope.config);
				delete config.items;
				if (config.groups[0]) {
					config.groups = [{ id: config.groups[0].id, config: {} }];
				} else {
					config.groups = [];
				}

				MediaService.editMediaConfig(
					$stateParams.type,
					$stateParams.id,
					config
				).then(function (response) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopSaveParamsSuccess
					);
					checkMediaConfig();
					if (afterSave) {
						afterSave();
					}
				});
			};

			function checkConfig() {
				return true;
			}

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.config) {
					PermissionService.havePermission(
						$stateParams.id,
						'edit',
						'media'
					).then(function (response) {
						if (response.data) {
							$scope.saveConfig(showVisualization);
						} else {
							showVisualization();
						}
					});
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				$scope.configSaved = true;
				if ($scope.configIsValid) {
					if (!$scope.mediaView.mediaId) {
						$scope.mediaView.mediaId = $stateParams.id;
					} else {
						$scope.mediaView.reinit();
					}
				}
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.type = $stateParams.type;
				$scope.savingInProgress = false;

				if ($stateParams.id) {
					$scope.isNew = false;
					$scope.metadata.isNew = false;
					var mediaId = $stateParams.id;
					$scope.dataSource.hdId = mediaId;
					$scope.dataSource.hdType = 'MEDIA';

					MediaService.getMedia($stateParams.type, mediaId).then(function (
						response
					) {
						$scope.media = response.data;
						$scope.metadata = $scope.media.metadata;
						$scope.metadataLoaded = true;
						MediaService.checkMediaConfig(
							$stateParams.type,
							$stateParams.id
						).then(function (responseCheck) {
							$scope.configIsValid = responseCheck.data;
							if ($stateParams.mode == 'visualisation') {
								$('.nav-tabs a[data-target="#visualization"]').tab('show');
								$scope.showVisualization();
							} else {
								$('.nav-tabs a[data-target="#config"]').tab('show');
								$scope.disabledVisualisation = !$scope.configIsValid;
								$scope.showConfig();
							}
						});
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveMedia(true);
			};

			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchMediaSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveMedia = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var media = {};

				media.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					media.metadata.code = $scope.metadata.code;

					MediaService.createMedia($stateParams.type, media)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopCreateMediaSuccess
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('medias');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('medias-edit', {
										id: response.data,
										type: $stateParams.type,
									});
								}, 300);
							}
						})
						.catch(catchMediaSaveErrors);
				} else {
					MediaService.editMedia($stateParams.type, $scope.media.id, media)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopUpdateMediaSuccess
							);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('medias');
								}, 300);
							}
						})
						.catch(catchMediaSaveErrors);
				}
			};

			$scope.mediaItemData = {};

			$scope.addMedia = function (byUrl) {
				$scope.mediaItemData.save = function (item) {
					loadMediaItems();
					checkMediaConfig();
				};
				$scope.mediaItemData.media = { binary: !byUrl };
				$scope.mediaItemData.mediaId = $scope.media.id;
				$scope.mediaItemData.type = $stateParams.type;
				$scope.mediaItemData.showMediaItem = true;
			};

			$scope.deleteMediaItem = function (item) {
				$scope.itemToDelete = item;
				$scope.deleteElementData = {
					label: $scope.itemToDelete.label,
					doDelete: $scope.doDeleteMediaItem,
					showConfirmDeleteElement: true,
				};
			};

			$scope.doDeleteMediaItem = function () {
				MediaService.deleteMediaItem(
					$scope.media.id,
					$scope.itemToDelete.id
				).then(function () {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopDeleteMediaItemSuccess
					);
					loadMediaItems();
					checkMediaConfig();
				});
			};

			var checkMediaConfig = function () {
				MediaService.checkMediaConfig($stateParams.type, $stateParams.id).then(
					function (responseCheck) {
						$scope.disabledVisualisation = !responseCheck.data;
						$scope.configIsValid = responseCheck.data;
					}
				);
			};

			$scope.editMedia = function (item) {
				$scope.mediaItemData.type = $stateParams.type;
				$scope.mediaItemData.media = angular.copy(item);
				$scope.mediaItemData.mediaId = $scope.media.id;

				$scope.mediaItemData.save = function (mediaItem) {
					loadMediaItems();
				};
				$scope.mediaItemData.showMediaItem = true;
			};

			var itemsGridOptions = getDefaultDxGridConfig();
			itemsGridOptions.showColumnHeaders = true;
			itemsGridOptions.columns = [
				{
					caption: gettextCatalog.getString('Libellé'),
					dataField: 'label',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Description'),
					dataField: 'description',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Création'),
					dataField: 'creation_date',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
					dataType: 'date',
					format: 'yyyy-MM-dd HH:mm:ss',
				},
				{
					caption: gettextCatalog.getString('Créé par'),
					cellTemplate: 'createdBy',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Date de mise à jour'),
					dataField: 'update_date',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
					dataType: 'date',
					format: 'yyyy-MM-dd HH:mm:ss',
				},
				{
					caption: gettextCatalog.getString('Dernier modificateur'),
					cellTemplate: 'updatedBy',
					width: '20%',
					allowReordering: false,
					allowHeaderFiltering: false,
					allowFiltering: false,
				},
				{
					cellTemplate: 'optionTemplate',
					allowHeaderFiltering: false,
					allowFiltering: false,
					enableCellEdit: false,
					width: '10%',
				},
			];
		},
	]);
