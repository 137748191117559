(function () {
	angular.module('dcApp').factory('permissionChecker', [
		'$rootScope',
		'$http',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, API_BASE_URL_BACKEND) {
			var permissionChecker = {};

			permissionChecker.checkElementPermission = function (
				elementId,
				action,
				type,
				actionWhenOK,
				actionWhenKO
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/check/' +
					type +
					'/' +
					elementId +
					'/' +
					action;
				return $http.get(url).then(function (response) {
					handleResponse(response.data, actionWhenOK, actionWhenKO);
				});
			};

			permissionChecker.checkGlobalPermission = function (
				action,
				actionWhenOK,
				actionWhenKO
			) {
				var url = API_BASE_URL_BACKEND + '/permissions/check/' + action;
				return $http.get(url).then(function (response) {
					handleResponse(response, actionWhenOK, actionWhenKO);
				});
			};

			var handleResponse = function (response, actionWhenOK, actionWhenKO) {
				if (response.data) {
					actionWhenOK();
				} else if (actionWhenKO) {
					actionWhenKO();
				} else {
					$('#permissionErrorModal').modal('show');
				}
			};

			return permissionChecker;
		},
	]);
})();
