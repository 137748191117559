(function () {
	angular.module('dcApp').factory('DateService', [
		'$rootScope',
		'CommonServices',
		function ($rootScope, CommonServices) {
			const DEFAULT_FORMAT = "DD/MM/YYYY HH:mm";
			const DEFAULT_FORMAT_TIME = "DD/MM/YYYY HH:mm:ss";
			const DEFAULT_WRITING_FORMAT = "YYYY-MM-DD HH:mm:ss";
			const REVOCATION_DURATION_MONTHS = 6;

			var DateService = {};

			DateService.getDateInIsoFormat = function (dateString) {
				return moment(dateString, DEFAULT_FORMAT).toISOString(false);
			};

			DateService.getDateInIsoFormatInDefaultTz = function (
				dateString,
				pattern
			) {
				if (CommonServices.isEmpty(dateString) || dateString.includes("T")) {
					return dateString;
				}

				return moment.tz(dateString, DEFAULT_FORMAT_TIME, $rootScope.getDefaultTimezone()).toISOString(false);
			};

			DateService.dateToString = function (date) {
				return moment(date).format(DEFAULT_FORMAT);
			};

			DateService.getRevocationDate = function (date) {
				return moment(date)
					.add(REVOCATION_DURATION_MONTHS, 'M')
					.format(DEFAULT_FORMAT);
			};

			DateService.getRevocationDateWithPattern = function (date, pattern) {
				if (CommonServices.isEmpty(pattern)) {
					return DateService.getRevocationDate(date);
				}

				return moment(date)
					.add(REVOCATION_DURATION_MONTHS, 'M')
					.format(pattern);
			};

			DateService.dateToStringWithPattern = function (date, pattern) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				let patternToUse = pattern ? pattern : DEFAULT_WRITING_FORMAT;
				return moment.utc(date).format(patternToUse);
			};

			DateService.dateToISOString = function (date) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				return moment(date).toISOString(false);
			};

			DateService.dateStringToISO = function (dateStr) {
				if (CommonServices.isEmpty(dateStr)) {
					return dateStr;
				}

				let momentDate;
				if (dateStr.includes("T")) {
					momentDate = moment(dateStr);
				} else {
					momentDate = moment.utc(dateStr, "DD/MM/YYYY HH:mm:ss")
				}
				if (!momentDate.isValid()) {
					// Try with pattern
					momentDate = moment.utc(dateStr, "DD-MM-YYYY HH:mm:ss");
				}
				// check if is valid*
				if (!momentDate.isValid()) {
					// Try with pattern
					return dateStr;
				}

				return moment(momentDate).toISOString(false);
			};

			DateService.dateToStringWithPatternLocal = function (date, pattern) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				const patternToUse = pattern ? pattern : DEFAULT_WRITING_FORMAT;
				const localTimeZone = moment.tz.guess();

				return moment.tz(moment(date), localTimeZone).format(patternToUse);
			};

			DateService.dcCalendarDateToString = function (date, pattern) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				const patternToUse = pattern ? pattern : DEFAULT_WRITING_FORMAT;

				return moment.tz(moment(date).format(patternToUse), patternToUse, $rootScope.getDefaultTimezone()).format(patternToUse);
			};

			DateService.dateOnlyToStringWithPatternLocal = function (date, pattern) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}

				let momentDate;
				if (date.includes("T")) {
					momentDate = moment.utc(date);
				} else {
					momentDate = moment.utc(date, DEFAULT_WRITING_FORMAT)
				}
				if (!momentDate.isValid()) {
					// Try with pattern
					momentDate = moment.utc(date, "DD-MM-YYYY HH:mm:ss");
				}
				// check if is valid*
				if (!momentDate.isValid()) {
					// Try with pattern
					return date;
				}

				momentDate = momentDate.toDate();

				const patternToUse = pattern ? pattern : DEFAULT_WRITING_FORMAT;
				const localTimeZone = moment.tz.guess();

				return moment.tz(moment(Date.UTC(momentDate.getFullYear(), momentDate.getMonth(), momentDate.getDate())), localTimeZone).format(patternToUse);
			};

			DateService.datesListToISOString = function (dates) {
				if (CommonServices.isEmpty(dates)) {
					return dates;
				}
				return dates.map((date)=> DateService.dateToISOString(date));
			};

			DateService.dateToStringWithPatternAndTZ = function (date, pattern, tz) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				if (!tz) {
					return DateService.dateToStringWithPattern(date, pattern);
				}

				let patternToUse = pattern ? pattern : DEFAULT_WRITING_FORMAT;

				return  moment.tz(moment(date), tz).format(patternToUse);
			};

			DateService.dateToISOStringTZ = function (date, tz) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}
				if (!tz) {
					return DateService.dateToISOString(date);
				}
				return moment.tz(moment(date), tz).toISOString(false);
			};

			DateService.datesListToStringWithPatternAndTZ = function (dates, pattern, tz) {
				if (CommonServices.isEmpty(dates)) {
					return dates;
				}
				return dates.map((date)=> DateService.dateToStringWithPatternAndTZ(date, pattern, tz));
			};

			DateService.getJsDateWithPatternLocal = function (date, pattern) {
				if (CommonServices.isEmpty(date)) {
					return date;
				}

				const localTimeZone = moment.tz.guess();

				let momentDate = moment(date);
				if (!momentDate.isValid()) {
					momentDate = moment(date, pattern);
				}

				return moment.tz(moment(date), localTimeZone).toDate();
			};

			DateService.getJSDateForDcCalendarWithPattern = function (
				dateString,
				pattern,
				useDefaultTz
			) {
				if (CommonServices.isEmpty(dateString)) {
					return dateString;
				}
				const usedPattern = dateString.includes("T") ?  moment.ISO_8601 : pattern;

				return useDefaultTz ? moment(moment.tz(dateString, pattern, $rootScope.getDefaultTimezone()).format()).toDate() : moment(dateString, pattern).toDate();
			};

			DateService.getMomentTZList = function() {
				let tz = moment.tz.names();
				tz.splice(tz.indexOf("UTC"), 1)
				tz.splice(tz.indexOf("Europe/Paris"), 1)
				tz.splice(0, 0, "UTC");
				tz.splice(1, 0, "Europe/Paris");

				return tz;
			}

			return DateService;
		},
	]);
})();
