(function () {
	'use strict';

	angular.module('dcApp').controller('LogoutController', [
		'$scope',
		'$rootScope',
		function ($scope, $rootScope) {
			$scope.logout = function () {
				$rootScope.logout();
			};
		},
	]);
})();
