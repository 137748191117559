(function () {
	'use strict';

	angular.module('dcApp').directive('persistanceParams', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'TypeEntiteService',
				'toaster',
				'$sce',
				function ($scope, TypeEntiteService, toaster, $sce) {
					var vm = this;

					$scope.getHtmlIconByType = function (type) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type));
						}
					};

					$scope.getPersistanceParams = function () {
						$scope.axes = [];
						TypeEntiteService.getPersistanceParams(vm.params.id).then(function (
							response
						) {
							$scope.persistanceParams = response.data;
							if (!$scope.persistanceParams) {
								$scope.persistanceParams = { repartition_axes: [] };
							}
							TypeEntiteService.getColumnsFromCaracs(
								vm.params.id,
								vm.params.excludeLinks
							).then(function (response1) {
								$scope.columns = response1.data;
								for (var a in $scope.persistanceParams.repartition_axes) {
									var col = _.filter($scope.columns, function (item) {
										return (
											item.alias == $scope.persistanceParams.repartition_axes[a]
										);
									})[0];
									if (col) {
										$scope.axes.push(col);
									}
								}
							});
						});
					};

					$scope.updatePersistanceParams = function () {
						$scope.persistanceParams.repartition_axes = [];
						for (var a in $scope.axes) {
							$scope.persistanceParams.repartition_axes.push(
								$scope.axes[a].alias
							);
						}
						TypeEntiteService.updatePersistanceParams(
							$scope.persistanceParams,
							vm.params.id
						).then(function (response) {
							// refresh element
							$scope.getPersistanceParams();
						});
					};

					$scope.dropCallbackColumns = function (event, index, item) {
						return item;
					};

					$scope.moveCallbackColumns = function (index) {
						$scope.axes.splice(index, 1);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					params: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/persistanceParams/persistanceParams.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.getPersistanceParams();
					scope.vm.params.updatePersistanceParams =
						scope.updatePersistanceParams;
					scope.vm.params.init = scope.getPersistanceParams;
				},
			};
		},
	]);
})();
