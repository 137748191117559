(function () {
	'use strict';

	angular.module('dcApp').factory('TimeLineService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'httpService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, httpService) {
			var TimeLineService = {};

			TimeLineService.getTimeLines = function (excludePublic, excludeInactive) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_GET_ALL');
				url = excludeInactive ? url + '?excludeInactive=true' : url +  '?excludeInactive=false';
				url = excludePublic ? url + '&excludePublic=true' : url +  '&excludePublic=false';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TimeLineService.getTimeLine = function (timeLineId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_GET');
				url = url.replace('{id}', timeLineId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TimeLineService.createTimeLine = function (tl) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_CREATE');
				return httpService.postRequest(url, tl);
			};

			TimeLineService.createFromDataBlock = function (dbId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_CREATE_FROM_DB');
				return httpService.postRequest(url, dbId);
			};

			TimeLineService.editTimeLine = function (timeLineId, timeLine) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_EDIT');
				url = url.replace('{id}', timeLineId);
				$http.put(url, timeLine).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (error) {
						deferred.reject(error);
					}
				);
				return deferred.promise;
			};

			TimeLineService.deleteTimeLine = function (timeLineId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_DELETE');
				url = url.replace('{id}', timeLineId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			TimeLineService.duplicateTimeline = function (timeLineId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_DUPLICATE');
				url = url.replace('{id}', timeLineId);
				return duplicateTimeline(url);
			};

			TimeLineService.deleteBulkTimeLines = function (timeLinesId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_DELETE_BULK');
				if (Array.isArray(timeLinesId)) {
					url = url + '?timeLinesId=' + timeLinesId[0];
					for (let i = 1; i < timeLinesId.length; i++) {
						url = url + '&timeLinesId=' + timeLinesId[i];
					}
				} else {
					url = url + '?timeLinesId=' + timeLinesId;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			TimeLineService.editTimelineConfig = function (tlId, config) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_EDIT_CONFIG');
				url = url.replace('{id}', tlId);
				$http.put(url, config).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			TimeLineService.getTimeLineConfig = function (tlId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_GET_CONFIG');
				url = url.replace('{id}', tlId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TimeLineService.checkTimeLineConfig = function (tlId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('TIMELINES_CHECK_CONFIG');
				url = url.replace('{id}', tlId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var duplicateTimeline = function (url) {
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return TimeLineService;
		},
	]);
})();
