(function () {
	'use strict';

	angular.module('dcApp').controller('FormulasCatalogController', [
		'$sce',
		'$scope',
		'$rootScope',
		'toaster',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'FormulaCatalogService',
		'gettextCatalog',
		'$timeout',
		'permissionChecker',
		function (
			$sce,
			$scope,
			$rootScope,
			toaster,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			FormulaCatalogService,
			gettextCatalog,
			$timeout,
			permissionChecker
		) {
			$scope.activeElementsOnly = false;

			let formulaCatalogElementsRaw = [];
			let lookupTagDataSourceCat = {
				store: new DevExpress.data.CustomStore({
					loadMode: 'raw',
					load: function () {
						return getTags().then(function (response) {
							return response;
						});
					},
				}),
			};
			let translateMapCat = {
				Description: gettextCatalog.getString('Description'),
				Utilisateur: gettextCatalog.getString('Utilisateur'),
				Actions: gettextCatalog.getString('Actions'),
				Partagé: gettextCatalog.getString('Partagé'),
				Tag: gettextCatalog.getString('Tag'),
				'Crée le': gettextCatalog.getString('Crée le'),
				Libellé: gettextCatalog.getString('Libellé'),
				Type: gettextCatalog.getString('Type'),
			};
			let ruleTra = gettextCatalog.getString('Règle');
			let formulaTra = gettextCatalog.getString('Formule: ');
			let descTra = gettextCatalog.getString('Description: ');
			let selectedElms;
			let formulaToEdit;
			let formulaToDelete;
			let formulaToDuplicate;

			init();

			function init() {
				$scope.rules = [];
				$scope.tagsList = [];
				$scope.currentUserId = $rootScope.account.userId;
				$scope.showGrid = false;
				$scope.atLeastOneSelected = false;
				$scope.dataGridOptions = {
					paging: {
						enabled: true,
						pageSize: PAGINATIONS_SIZE,
					},
					pager: {
						showPageSizeSelector: true,
						allowedPageSizes: PAGINATIONS_SIZES,
						showInfo: true,
						visible: true,
					},
					filterRow: {
						visible: true,
						applyFilter: 'auto',
					},
					searchPanel: {
						visible: true,
						width: 240,
					},
					rowAlternationEnabled: true,
					headerFilter: {
						visible: true,
						applyFilter: 'auto',
					},
					selection: {
						mode: 'multiple',
						showCheckBoxesMode: 'always',
					},
					columnAutoWidth: true,
					showBorders: true,
					allowColumnResizing: true,
					showColumnLines: true,
					allowColumnReordering: true,
					showRowLines: true,
					columnFixing: {
						enabled: true,
					},
					columns: formulaCatalogListCols(
						translateMapCat,
						lookupTagDataSourceCat
					),
					onInitialized: function (e) {
						$scope.gridFormulaCatInstance = e.component;
					},
					onSelectionChanged: function (selectedItems) {
						let selectedRowsElms = _.uniq(selectedItems.selectedRowsData);
						selectedElms = _.map(selectedRowsElms, function (elm) {
							return elm.id;
						});
						$scope.atLeastOneSelected = selectedElms.length > 0;
					},
					onToolbarPreparing: function (e) {
						e.toolbarOptions.items.unshift(
							{
								location: 'after',
								widget: 'dxButton',
								options: {
									icon: 'fas fa-trash fa-lg',
									hint: gettextCatalog.getString(
										'Supprimer toutes les Formules séléctionnées'
									),
									onClick: function () {
										$scope.doShowDeleteModal(null, true);
									},
									bindingOptions: {
										visible: 'atLeastOneSelected',
									},
								},
							},
							{
								location: 'after',
								widget: 'dxButton',

								options: {
									icon: 'far fa-upload fa-lg',
									hint: gettextCatalog.getString('Exporter'),
									onClick: function () {
										$scope.showExportFormulaPopupMultiple();
									},
									bindingOptions: {
										visible: 'atLeastOneSelected',
									},
								},
							}
						);
					},
				};
				$scope.exportFormula = { data: {} };
				$scope.widgetData = {};
				$scope.dbBuilder = {};
				$scope.modes = {};
				$scope.deleteElementData = {};
				$scope.duplicateElmConf = {};
				$scope.getTypeHtmlDisp = function (type, isList) {
					if (type !== undefined) {
						type = type.toLowerCase();
						return $sce.trustAsHtml(getHtmlIconByType(type, isList));
					} else {
						return '';
					}
				};
				$scope.getFormulaCatalogItemToolTip = function (formulaCatItem) {
					let tooltipText =
						'<p class="formula-tooltip">' +
						buildFormulaDisplayed(formulaCatItem.formula, false, ruleTra, [], [], true) +
						'</p>';
					return {
						target: '#ViewFormulaCat-' + formulaCatItem.id,
						showEvent: 'mouseenter',
						hideEvent: 'mouseleave',
						maxWidth: '50%',
						closeOnOutsideClick: false,
						contentTemplate: function (data) {
							data.html(tooltipText);

						},
					};
				};

				permissionChecker.checkGlobalPermission(
					'formula_catalog',
					function () {
						$scope.getGridData();
					},
					function () {
						$scope.formula_catalog_unauthorised = true;
					}
				);
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				$scope.tags = [];
				let tags = [];
				$scope.selectedFormulaCat = undefined;
				$scope.formulaCatalogElements = [];
				formulaCatalogElementsRaw = [];
				const includeShared = true;
				FormulaCatalogService.findAll(includeShared, $scope.activeElementsOnly).then(function (response) {
					prepareFormulaCatalogDataFromRespH(response.data, tags);
				});
			};

			$scope.showExportFormulaPopup = function (formulaId) {
				$scope.exportFormula.data = {
					selectedElems: [formulaId],
					elements: formulaCatalogElementsRaw,
				};
				// FIXME: overloaded from child <export-forumla/> directive
				$scope.showExportPopup($scope.exportFormula.data, false);
			};

			$scope.showExportFormulaPopupMultiple = function () {
				$scope.exportFormula.data = {
					selectedElems: selectedElms,
					elements: formulaCatalogElementsRaw,
				};
				$scope.showExportPopup($scope.exportFormula.data, true);
			};

			$scope.refreshListAfterImport = function (allFormulas) {
				$scope.tags = [];
				let tags = [];
				$scope.selectedFormulaCat = undefined;
				let data = formulaCatalogElementsRaw.concat(allFormulas);
				prepareFormulaCatalogDataFromRespH(data, tags);
				$scope.gridFormulaCatInstance.option(
					'dataSource',
					$scope.formulaCatalogElements
				);
				$scope.gridFormulaCatInstance.refresh();
			};

			$scope.showAddModal = function () {
				var widget = {};
				widget.grammar = {};
				widget.grammar.columns = [];
				$scope.widgetData.widget = widget;
				$scope.dbBuilder.columns = widget.grammar.columns;
				$scope.modes.caracMode = false;
				$scope.widgetData.onlyOneFormula = true;
				$scope.widgetData.updateaction = saveNewFormula;
				$scope.widgetData.showFormule = true;
				$scope.widgetData.widgetMenuOpenAction = {};
				$scope.widgetData.widgetMenuData = {};
				$scope.widgetData.hideWidgetMenu = true;
			};

			$scope.showEditFormula = function (formula) {
				formulaToEdit = formula;
				let formulaObj = angular.copy(formula.formula);
				prepareCatalogFormula(formulaObj, '');
				var widget = {};
				widget.grammar = {};
				widget.grammar.columns = [
					{
						column_alias: formula.label,
						lib: formula.label,
						type: formula.formula.data_type,
						field: formula.label,
						value: formula.code,
						alias: formula.code,
						formula: formulaObj,
						uuid: formula.uuid,
						is_formule: true,
					},
				];
				$scope.widgetData.widget = widget;
				$scope.widgetData.widget.grammar.columnsTemp = angular.copy(
					$scope.widgetData.widget.grammar.columns
				);
				$scope.dbBuilder.columns = widget.grammar.columns;
				$scope.modes.caracMode = false;
				$scope.widgetData.onlyOneFormula = true;
				$scope.widgetData.updateaction = saveOldFormula;
				$scope.widgetData.showFormule = true;
				$scope.widgetData.widgetMenuOpenAction = {};
				$scope.widgetData.widgetMenuData = {};
				$scope.widgetData.hideWidgetMenu = true;
			};

			$scope.deleteCatalogFormula = function () {
				FormulaCatalogService.deleteItem(formulaToDelete.id).then(function () {
					$scope.getGridData();
					formulaToDelete = undefined;
					toaster.pop(
						'success',
						gettextCatalog.getString('Succès'),
						gettextCatalog.getString('Suppression effectuée')
					);
				});
			};

			$scope.deleteSelectedCatalogFormulas = function () {
				FormulaCatalogService.deleteSelectedItems(selectedElms).then(
					function () {
						$scope.getGridData();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Suppression effectuée')
						);
					}
				);
			};

			$scope.doShowDeleteModal = function (elm, bulk) {
				formulaToDelete = elm;
				$scope.deleteElementData = {
					label: elm ? elm.label : undefined,
					doDelete: bulk
						? $scope.deleteSelectedCatalogFormulas
						: $scope.deleteCatalogFormula,
					showConfirmDeleteElement: true,
					bulk: bulk,
					count: selectedElms ? selectedElms.length : 0,
				};
			};

			$scope.updateSharedFormulaStatus = function (elm) {
				FormulaCatalogService.updateSharedStatus(elm.id, elm.shared)
					.then(function (response) {
						elm = response.data;
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					})
					.catch(function (e) {
						elm.shared = !elm.shared;
					});
			};

			$scope.showDuplicateModal = function (elmId) {
				formulaToDuplicate = elmId;
				$scope.duplicateElmConf.showConfirmationDialog = true;
				$scope.duplicateElmConf.confirmationDialogTitle =
					gettextCatalog.getString('Confirmer la duplication');
				$scope.duplicateElmConf.confirmationDialogMessage =
					gettextCatalog.getString('Voulez-vous dupliquer cette Formule ?');
				$scope.duplicateElmConf.confirmationDialogActionName =
					gettextCatalog.getString('Oui');
				$scope.duplicateElmConf.enableConfirmDialogAction = true;
				$scope.duplicateElmConf.confirmationGreenAction = true;
				$scope.duplicateElmConf.confirmDialogAction = duplicateFormula;
			};

			//*** Private functions

			function duplicateFormula() {
				FormulaCatalogService.duplicateFormula(formulaToDuplicate).then(
					function (response) {
						$scope.getGridData();
						formulaToDuplicate = undefined;
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Duplication de la Formule effectuée')
						);
					}
				);
			}

			function prepareFormulaCatalogDataFromRespH(data, tags) {
				let elements = data;
				formulaCatalogElementsRaw = [];
				$scope.formulaCatalogElements = [];
				for (let i in elements) {
					formulaCatalogElementsRaw.push(elements[i]);
					$scope.formulaCatalogElements[i] = {
						id: elements[i].id,
						type: elements[i].formula.data_type,
						label: elements[i].metadata.label,
						description: elements[i].metadata.description,
						code: elements[i].metadata.code,
						tags: elements[i].metadata.tags,
						created: elements[i].metadata.creation_date,
						created_by: elements[i].metadata.created_by,
						formula: elements[i].formula,
						shared: elements[i].shared,
						metadata: elements[i].metadata,
						tagAsArray: $rootScope.mapTags(elements[i].metadata.tags),
					};
					tags = _.union(tags, elements[i].metadata.tags);
				}
				for (let i in tags) {
					if (_.find($scope.tags, { id: tags[i].id }) == null) {
						$scope.tags.push({ id: tags[i].id, label: tags[i].code });
					}
				}
				$scope.dataGridOptions.dataSource = $scope.formulaCatalogElements;
				$scope.showGrid = true;
			}

			function getTags() {
				return new Promise(function (resolve) {
					$timeout(function () {
						resolve(
							_.map(
								_.sortBy($scope.tags, function (i) {
									return i.label.toLowerCase();
								}),
								function (el) {
									return el.label;
								}
							)
						);
					}, 50);
				});
			}

			function saveNewFormula(data) {
				let col = data.grammar.columns[0];
				if (!col) {
					return;
				}
				let formula = col.formula;
				if (!formula) {
					return;
				}
				let formulaCat = angular.copy(formula);
				prepareCatalogFormula(formulaCat, '/?/');
				let catFormula = {
					metadata: {
						label: col.column_alias,
						description: col.description,
						tags: [],
					},
					formula: formula,
				};
				FormulaCatalogService.createItem(catFormula).then(function (response) {
					$scope.widgetData.showFormule = false;
					toaster.pop(
						'success',
						gettextCatalog.getString('Succès'),
						gettextCatalog.getString('Enregistrement effectué')
					);
					$scope.refreshListAfterImport(response.data);
				});
			}

			function saveOldFormula(data) {
				let col = data.grammar.columns[0];
				if (!col) {
					return;
				}
				let formula = col.formula;
				if (!formula) {
					return;
				}
				let formulaCat = angular.copy(formula);
				prepareCatalogFormula(formulaCat, '/?/');
				let catFormula = {
					metadata: formulaToEdit.metadata,
					formula: formula,
				};
				catFormula.metadata.label = col.column_alias;
				catFormula.metadata.description = col.description;
				let formulaToEditId = formulaToEdit.id;
				FormulaCatalogService.updateItem(formulaToEditId, catFormula).then(
					function (response) {
						$scope.widgetData.showFormule = false;
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
						$scope.getGridData();
						formulaToEdit = undefined;
					}
				);
			}
		},
	]);
})();
