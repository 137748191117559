(function () {
	angular.module('dcApp').directive('datablockList', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'$filter',
				'TypeEntiteService',
				'PermissionService',
				'API_BASE_URL_BACKEND',
				'DataBlocksService',
				'ExportService',
				'frameService',
				'ConnectorService',
				'$q',
				'$http',
				'toaster',
				'gettextCatalog',
				'UPDATE_EVENT',
				'NgExportTemplate',
				'DcElementListService',
				function (
					$scope,
					$rootScope,
					$state,
					$filter,
					TypeEntiteService,
					PermissionService,
					API_BASE_URL_BACKEND,
					DataBlocksService,
					ExportService,
					frameService,
					ConnectorService,
					$q,
					$http,
					toaster,
					gettextCatalog,
					UPDATE_EVENT,
					NgExportTemplate,
					DcElementListService
				) {
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.tags = gettextCatalog.getString('Tags');
					dataGirdColumnName.type = gettextCatalog.getString('Type');
					dataGirdColumnName.dataSource = gettextCatalog.getString(
						'Sources des données'
					);
					dataGirdColumnName.updateDate = gettextCatalog.getString(
						'Date de mise à jour'
					);
					dataGirdColumnName.actions = gettextCatalog.getString('Actions');
					dataGirdColumnName.code = gettextCatalog.getString('Code');
					dataGirdColumnName.cacheAndPersistence = gettextCatalog.getString(
						'Cache et persistance'
					);
					dataGirdColumnName.creationDate =
						gettextCatalog.getString('Date de création');
					dataGirdColumnName.structure = gettextCatalog.getString('Structure');
					dataGirdColumnName.description =
						gettextCatalog.getString('Description');

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopDeleteDatablock = gettextCatalog.getString(
						'Suppression du DataBlock effectuée'
					);

					let unpersistAllConfirmationDialogTitle =
						gettextCatalog.getString('Dépersister tout');
					let unpersistAllconfirmationDialogMessage = gettextCatalog.getString(
						'Êtes-vous sûr de vouloir dépersister tout les DataBlocks ?'
					);
					let unpersistAllconfirmationDialogActionName =
						gettextCatalog.getString('Confirmer');

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.disableAllSelectedElems = true;
					$scope.widgetData = {};
					$scope.frameDataConfig = {};

					$scope.currentProjectId = $rootScope.currentProjectId;
					$scope.showCurrentProjectOnly = true;
					$scope.activeElementsOnly = false;
					let yesTra = gettextCatalog.getString('Oui');
					let noTra = gettextCatalog.getString('Non');
					$scope.$on(UPDATE_EVENT['DATABLOCK'], function (event) {
						$scope.refreshBlockList();
					});

					function fillData(content) {
						if (content !== undefined) {
							$scope.datablocks = [];
							$scope.tags = [];
							$scope.showGrid = false;
							var tags = [];
							for (var r in content) {
								$scope.datablocks.push({
									id: content[r].id,
									code: content[r].metadata.code,
									lc: content[r].metadata.label,
									desc: content[r].metadata.description,
									active: content[r].metadata.actif ? yesTra : noTra,
									icon_id: content[r].metadata.icon_id,
									tags: content[r].metadata.tags,
									is_tmp: content[r].tmp_grammar !== undefined,
									tagAsArray: $rootScope.mapTags(content[r].metadata.tags),
									creationDate: $rootScope.getDateTimeWithPattern(
										content[r].metadata.creation_date
									),
									updateDate: $rootScope.getDateTimeWithPattern(
										content[r].metadata.update_date
									),
									createdBy: content[r].metadata.created_by,
									updatedBy: content[r].metadata.updated_by,
									project: content[r].metadata.project,
								});
								tags = _.union(tags, content[r].metadata.tags);
							}

							for (var i in tags) {
								if (_.find($scope.tags, { id: tags[i].id }) == null) {
									$scope.tags.push({ id: tags[i].id, label: tags[i].code });
								}
							}

							$scope.showGrid = true;
							$scope.loadData = $scope.datablocks;
							$scope.gridData = angular.copy($scope.datablocks);
							$scope.dataGridOptions.dataSource = $scope.gridData;
							$scope.vm.elementCount = $scope.datablocks.length;
							if ($scope.gridInstance && $scope.gridInstance.option) {
								$scope.gridInstance.option('dataSource', $scope.gridData);
							}
						}
					}

					$scope.getDataBlocksUsing = function () {
						$scope.isValueTab = false;
						TypeEntiteService.getDataBlocksUsing($scope.entiteId).then(
							function (response) {
								fillData(response.data);
							}
						);
					};

					$scope.getAllDataBlocks = function () {
						DataBlocksService.getDatablocks(
							$scope.showCurrentProjectOnly,
							$scope.activeElementsOnly
						).then(function (content) {
							if (content.data !== undefined) {
								fillData(content.data);
							}
						});
					};

					$scope.lookupTags = [];
					// Get DxGrid dataGridOptions
					DcElementListService.getDcElementDxList($scope, 'datablock', false);

					/* action button*/
					$scope.checkPermissionData = {};
					/*edit*/
					$scope.editDatablock = function (obj) {
						$scope.objToEdit = obj;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = 'datablock';
						$scope.checkPermissionData.action = 'edit';
						$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.goToEdit = function () {
						$state.go('datablocks-edit', { id: $scope.objToEdit.id });
					};
					/*edit*/

					/*export*/
					$scope.showExportModal = function (obj) {
						$state.go('from-datablock-list', {
							datablockId: obj.id,
							datablockLabel: obj.lc,
						});
					};
					/*export*/

					/*delete*/
					$scope.bulkDelete = false;
					$scope.showDeleteModal = function (row) {
						$scope.datablock = row;
						$scope.bulkDelete = false;
						$scope.checkPermissionData.elementId = $scope.datablock.id;
						$scope.checkPermissionData.type = 'datablock';
						$scope.checkPermissionData.action = 'delete';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteModal;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.deleteElementData = {};
					$scope.doShowDeleteModal = function (count) {
						$scope.deleteElementData = {
							label: $scope.datablock ? $scope.datablock.lc : undefined,
							doDelete: $scope.bulkDelete
								? $scope.deleteSelectedElements
								: $scope.deleteDataBlock,
							showConfirmDeleteElement: true,
							bulk: $scope.bulkDelete,
							count: count,
						};
					};

					$scope.deleteDataBlock = function () {
						DataBlocksService.deleteDataBlock($scope.datablock.id)
							.then(function (response) {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopDeleteDatablock
								);
								$scope.initDataBlocks();
							})
							.catch(function (e) {
								if (e && e.data && e.data.code == '40') {
									$scope.gridInstance.clearSelection();
								}
							});
					};
					/*delete*/

					PermissionService.haveGlobalPermission('datablock-create').then(
						function (response) {
							$scope.create_authorised = response.data;
						}
					);

					$scope.unpersistAll = function () {
						$scope.widgetData.confirmationDialogTitle =
							unpersistAllConfirmationDialogTitle;
						$scope.widgetData.confirmationDialogMessage =
							unpersistAllconfirmationDialogMessage;
						$scope.widgetData.confirmationDialogActionName =
							unpersistAllconfirmationDialogActionName;
						$scope.widgetData.enableConfirmDialogAction = true;
						$scope.widgetData.confirmDialogAction = $scope.confirmUnpersistAll;
						$scope.widgetData.showConfirmationDialog = true;
					};

					$scope.confirmUnpersistAll = function () {
						let selectedEntities = _.map($scope.selectedElems, function (elm) {
							return elm.code;
						});
						frameService
							.unpersistMany(selectedEntities)
							.then(function (response) {
								frameService.broadcastOperationResponse(response.data);
							});
					};

					// cache or persistent seleted item
					$scope.showLoadAllModal = function (mode) {
						$scope.loadAllMode = mode;
						$('#loadAllModal').modal('show');
					};
					$scope.loadAllData = function () {
						let selectedEntities = _.map($scope.selectedElems, function (elm) {
							return elm.code;
						});
						for (let et in selectedEntities) {
							if ($scope.loadAllMode == 'PERSIST') {
								frameService
									.persist(selectedEntities[et])
									.then(function (response) {
										let data = [];
										data[selectedEntities[et]] = response.data;
										frameService.broadcastOperationResponse(data);
									});
							} else if ($scope.loadAllMode == 'CACHE') {
								frameService
									.cache(selectedEntities[et])
									.then(function (response) {
										let data = [];
										data[selectedEntities[et]] = response.data;
										frameService.broadcastOperationResponse(data);
									});
							}
						}
						$scope.gridInstance.clearSelection();
						$('#loadAllModal').modal('hide');
					};

					// delete seleted item
					$scope.showDeleteSelectedElements = function () {
						$scope.bulkDelete = true;
						$scope.doShowDeleteModal($scope.selectedElems.length);
					};

					$scope.deleteSelectedElements = function () {
						let selectedEntities = _.map($scope.selectedElems, function (elm) {
							return elm.id;
						});
						DataBlocksService.deleteManyDataBlocks(selectedEntities)
							.then(function () {
								$scope.initDataBlocks();
								$scope.gridInstance.clearSelection();
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopDeleteDatablock
								);
							})
							.catch(function (e) {
								if (e && e.data && e.data.code == '40') {
									$scope.initDataBlocks();
									$scope.gridInstance.clearSelection();
								}
							});
					};

					/*refresh List*/
					$scope.refreshBlockList = function () {
						if ($scope.isEntityType && $scope.entiteId != null) {
							$scope.getDataBlocksUsing();
						} else {
							$scope.getAllDataBlocks();
						}
					};

					$scope.initDataBlocks = function () {
						PermissionService.haveGlobalPermission('datablock').then(function (
							response
						) {
							$scope.datablock_authorised = response.data;
							if ($scope.datablock_authorised) {
								if ($scope.isEntityType && $scope.entiteId != null) {
									$scope.getDataBlocksUsing();
								} else {
									$scope.getAllDataBlocks();
								}
							}
						});
					};

					$scope.moveOrCopyData = {};
					$scope.moveOrCopyElementToProject = function () {
						let datablockIds = _.map($scope.selectedElems, function (con) {
							return con.id;
						});
						$scope.moveOrCopyData = {
							elements: datablockIds,
							type: 'datablock',
							showMoveCopy: true,
						};
					};

					$scope.moveOrCopyOneElementToProject = function (elementId) {
						$scope.moveOrCopyData = {
							elements: [elementId],
							type: 'datablock',
							showMoveCopy: true,
						};
					};

					let exposeDataApi = function () {
						$scope.showExposeDataBlock = true;
					};

					$scope.showDataExposeParamInList = function (data) {
						$scope.datablockToExposeID = data.id;
						$scope.datablockToExposeLabel = data.lc;
						$scope.checkPermissionData.elementId = data.id;
						$scope.checkPermissionData.type = 'datablock';
						$scope.checkPermissionData.action = 'view';
						$scope.checkPermissionData.actionIfPermitted = exposeDataApi;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.getExposeStatus = function (data) {
						if (data && data.id) {
							DataBlocksService.isExposed(data.id).then(function (exposeResp) {
								data.exposed = exposeResp.data;
							});
						}
					};

					$scope.updateExposeStatus = function () {
						DataBlocksService.isExposed($scope.datablockToExposeID).then(
							function (exposeResp) {
								for (let i in $scope.gridData) {
									if ($scope.gridData[i].id === $scope.datablockToExposeID) {
										$scope.gridData[i].exposed = exposeResp.data;
										break;
									}
								}
							}
						);
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					entiteId: '=',
					isEntityType: '=',
					elementCount: '<',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl:
					'./src/components/directives/dataBlockList/dataBlockList.html',
				link: function (scope, element, attrs) {
					scope.entiteId = scope.vm.entiteId;
					scope.isEntityType = scope.vm.isEntityType;
					scope.initDataBlocks();
				},
			};
		},
	]);
})();
