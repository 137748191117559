(function () {
	'use strict';

	ExportModelListController.$inject = [
		'$log',
		'$state',
		'$timeout',
		'$rootScope',
		'$scope',
		'NgExportTemplate',
		'PermissionService',
		'DcElementListService',
	];

	function ExportModelListController(
		$log,
		$state,
		$timeout,
		$rootScope,
		$scope,
		NgExportTemplate,
		PermissionService,
		DcElementListService
	) {
		const vm = this;
		const listeners = [];
		vm.elems = [];
		vm.selectedElems = [];
		vm.showCurrentProjectOnly = true;
		vm.showActiveElementOnly = false;
		vm.disableAllSelectedElems = true;
		vm.createAuthorised = false;
		vm.exportTemplateAuthorised = false;
		vm.checkPermissionData = {};
		vm.permissionData = {};

		vm.$onInit = onInit;
		vm.$onDestroy = onDestroy;
		vm.createTemplate = create;
		vm.goToExportTemplate = goToExportTemplate;
		vm.deleteTemplate = deleteTemplate;
		vm.refreshGridData = refreshGridData;
		vm.deleteBulkExportTemplates = deleteBulkExportTemplates;
		vm.showPermissions = showPermissions;

		function create() {
			$state.go('export-template-edit', { exportConfigId: -1, type: 'new' });
		}

		function refreshGridData() {
			NgExportTemplate.refreshAvailableExportTemplateList(
				vm.showCurrentProjectOnly,
				vm.showActiveElementOnly
			);
		}

		function deleteTemplate(templateIds) {
			NgExportTemplate.deleteTemplatesById(
				templateIds,
				vm.showCurrentProjectOnly,
				vm.showActiveElementOnly
			);
		}

		function getGridData() {
			NgExportTemplate.getAvailableExportTemplateList().then((list) => {
				const content = list.toArray();
				vm.tags = [];
				vm.elems = content.map(([id, exportTemplateDetails]) => {
					return {
						id: exportTemplateDetails.item.id,
						metadataId: exportTemplateDetails.metadata.id,
						type: exportTemplateDetails.item.outputType,
						code: exportTemplateDetails.metadata.code,
						lc: exportTemplateDetails.metadata.title,
						desc: exportTemplateDetails.metadata.description,
						icon_id: exportTemplateDetails.metadata.iconId,
						creationDate: $rootScope.getDateTimeWithPattern(
							exportTemplateDetails.metadata.creationDate
						),
						updateDate: $rootScope.getDateTimeWithPattern(
							exportTemplateDetails.metadata.updateDate
						),
						createdBy: exportTemplateDetails.metadata.createdBy,
						updatedBy: exportTemplateDetails.metadata.updatedBy,
						project: exportTemplateDetails.metadata.project
							? exportTemplateDetails.metadata.project
							: {},
					};
				});

				if (vm.gridInstance && vm.gridInstance.option) {
					vm.gridInstance.option('dataSource', vm.elems);
					vm.gridInstance.refresh();
				}
			});
		}

		function goToExportTemplate(templateId, type) {
			$state.go('export-template-edit', {
				exportConfigId: templateId,
				type: type,
			});
		}

		function deleteBulkExportTemplates() {
			let ids = vm.selectedElems.map((el) => el.id);
			NgExportTemplate.deleteTemplatesById(
				ids,
				vm.showCurrentProjectOnly,
				vm.showActiveElementOnly
			);
		}

		function checkPermissionsAndLoadList() {
			PermissionService.haveGlobalPermission('export_template-create').then(
				function (response) {
					vm.createAuthorised = response.data;
				}
			);
			// if have permission to load list do it
			PermissionService.haveGlobalPermission('export_template').then(function (
				response
			) {
				vm.exportTemplateAuthorised = response.data;
				if (vm.exportTemplateAuthorised) {
					refreshGridData();
				}
			});
		}

		function onInit() {
			$log.debug('init export models list');
			// Get DxGrid dataGridOptions
			vm.disableAllSelectedElems = true;
			DcElementListService.getDcElementDxList(vm, 'exportTemplate', false);
			// INIT
			checkPermissionsAndLoadList();
			initEvents();
		}

		function onDestroy() {
			$log.debug('destroying component');
			listeners.forEach((cb) => cb());
		}

		function initEvents() {
			const ref = $scope.$on('export-template:list:refresh', function () {
				getGridData();
			});
			listeners.push(ref);
		}

		function showPermissions(element) {
			vm.checkPermissionData.elementId = element.id;
			vm.checkPermissionData.metadataId = element.metadataId;
			vm.checkPermissionData.label = element.lc;
			vm.checkPermissionData.type = 'export_template';
			vm.checkPermissionData.action = 'permissions';
			vm.checkPermissionData.actionIfPermitted = doShowPermissions;
			vm.checkPermissionData.showPermissionCheck = true;
		}

		function doShowPermissions() {
			vm.permissionData.metadataId = vm.checkPermissionData.metadataId;
			vm.permissionData.type = 'export_template';
			vm.permissionData.elementId = vm.checkPermissionData.elementId;
			vm.permissionData.lib = vm.checkPermissionData.label;
			vm.permissionData.showElementPermissions = true;
		}
	}

	const exportTemplateListCmp = {
		restrict: 'E',
		controller: ExportModelListController,
		templateUrl:
			'./src/components/directives/exportTemplate/exportTemplateList.component.html',
		controllerAs: 'vm',
		bindings: {
			isList: '<',
		},
	};

	angular
		.module('MiscellaneousSettingsModule')
		.component('exportTemplateListCmp', exportTemplateListCmp);
})();
