(function () {
	'use strict';

	angular.module('dcApp').directive('emptygridmessage', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				function ($rootScope, $scope) {
					var vm = this;
				},
			];

			return {
				restrict: 'E',

				scope: {
					widget: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/emptyGridMessage/emptyGridMessage.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {},
			};
		},
	]);
})();
