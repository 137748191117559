(function () {
	'use strict';

	angular.module('dcApp').directive('listvalue', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					$scope.deleteListValueItem = function (index) {
						$scope.listValue.splice(index, 1);
					};

					$scope.addListValueItem = function (index) {
						$scope.listValue.push('');
					};

					var vm = this;

					$scope.getMatchingColumns = function (columns, type) {
						var cols = [];
						for (var c in columns) {
							if (columns[c].type == type) {
								cols.push(columns[c]);
							}
						}
						return cols;
					};

					$scope.showValueSelectColumn = function (index) {
						$scope.listValue[index] = {};
						$scope.listValue[index].isColumn = true;
					};

					$scope.hideValueSelectColumn = function (index) {
						$scope.listValue[index] = undefined;
					};
					$scope.save = function () {
						$scope.vm.widgetData.listValue = $scope.listValue;
						vm.widgetData.updateaction_l2();
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showListValueFilter = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/listValueFilter/listValueFilter.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showListValueFilter = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showListValueFilter;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.listValue = scope.vm.widgetData.listValue;
								scope.selectedRule = scope.vm.widgetData.selectedRule;
								scope.columns = scope.vm.widgetData.columns;
								if (!scope.listValue) {
									scope.listValue = [];
									scope.listValue.push('');
								}
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
