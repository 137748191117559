(function () {
	'use strict';

	angular.module('dcApp').filter('truncateText', function () {
		return function (description, maxCarac) {
			if (
				description === null ||
				description === undefined ||
				description === ''
			) {
				return;
			}
			return description.length < maxCarac
				? description
				: truncate(description, maxCarac);
		};

		function truncate(text, max) {
			return text.slice(0, max) + '...';
		}
	});
})();
