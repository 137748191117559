(function () {
	'use strict';

	angular.module('dcApp').factory('MediaService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'$window',
		'UrlService',
		function (
			$http,
			$rootScope,
			API_BASE_URL_BACKEND,
			$q,
			$window,
			UrlService
		) {
			var MediaService = {};

			var getUrls = { image: 'MEDIAS_IMAGE_GET', pdf: 'MEDIAS_PDF_GET' };
			var createUrls = {
				image: 'MEDIAS_IMAGE_CREATE',
				pdf: 'MEDIAS_PDF_CREATE',
			};
			var createFromDbUrls = {
				image: 'MEDIAS_IMAGE_CREATE_FROM_DB',
				pdf: 'MEDIAS_PDF_CREATE_FROM_DB',
			};
			var editUrls = { image: 'MEDIAS_IMAGE_EDIT', pdf: 'MEDIAS_PDF_EDIT' };
			var deleteUrls = {
				image: 'MEDIAS_IMAGE_DELETE',
				pdf: 'MEDIAS_PDF_DELETE',
			};
			var getConfigUrls = {
				image: 'MEDIAS_IMAGE_GET_CONFIG',
				pdf: 'MEDIAS_PDF_GET_CONFIG',
			};
			var editConfigUrls = {
				image: 'MEDIAS_IMAGE_EDIT_CONFIG',
				pdf: 'MEDIAS_PDF_EDIT_CONFIG',
			};
			var duplicateUrls = {
				image: 'MEDIAS_IMAGE_DUPLICATE',
				pdf: 'MEDIAS_PDF_DUPLICATE',
			};
			var checkConfigUrls = {
				image: 'MEDIAS_IMAGE_CHECK_CONFIG',
				pdf: 'MEDIAS_PDF_CHECK_CONFIG',
			};

			MediaService.deleteMediaItem = function (mediaId, mediaItemId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MEDIAS_ITEMS_DELETE');
				url = url.replace('{id}', mediaId);
				url = url.replace('{itemId}', mediaItemId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			MediaService.createMediaItem = function (mediaItem, mediaId) {
				var deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MEDIAS_ITEMS_CREATE');
				url = url.replace('{id}', mediaId);
				$http.post(url, mediaItem).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			MediaService.editMediaItem = function (mediaItem, mediaId, mediaItemId) {
				var deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('MEDIAS_ITEMS_EDIT');
				url = url.replace('{id}', mediaId);
				url = url.replace('{itemId}', mediaItemId);
				$http.put(url, mediaItem).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			MediaService.createUploadedMediaItem = function (mediaItem, mediaId) {
				var deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('MEDIAS_ITEMS_CREATE_UPLOADED');
				url = url.replace('{id}', mediaId);
				var fd = new FormData();
				fd.append('file', mediaItem.file);
				fd.append('label', mediaItem.label);
				if (mediaItem.description) {
					fd.append('description', mediaItem.description);
				}
				$http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							var results = {};
							results.data = response.data;
							results.headers = response.headers();
							results.status = response.status;
							results.config = response.config;
							deferred.resolve(results);
						},
						function (response) {
							deferred.reject(response);
						}
					);
				return deferred.promise;
			};

			MediaService.editUploadedMediaItem = function (
				mediaItem,
				mediaId,
				mediaItemId
			) {
				var deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('MEDIAS_ITEMS_EDIT_UPLOADED');
				url = url.replace('{id}', mediaId);
				url = url.replace('{itemId}', mediaItemId);
				var fd = new FormData();
				fd.append('file', mediaItem.file);
				fd.append('label', mediaItem.label);
				if (mediaItem.description) {
					fd.append('description', mediaItem.description);
				}
				$http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							var results = {};
							results.data = response.data;
							results.headers = response.headers();
							results.status = response.status;
							results.config = response.config;
							deferred.resolve(results);
						},
						function (response) {
							deferred.reject(response);
						}
					);
				return deferred.promise;
			};

			MediaService.getMedia = function (type, mediaId) {
				var urlCode = getUrls[type];
				return getMedia(mediaId, $rootScope.getUrl(urlCode));
			};

			MediaService.createMedia = function (type, media) {
				var urlCode = createUrls[type];
				return createMedia(media, $rootScope.getUrl(urlCode));
			};

			MediaService.createMediaFromDataBlock = function (type, media) {
				var urlCode = createFromDbUrls[type];
				return createMedia(media, $rootScope.getUrl(urlCode));
			};

			MediaService.editMedia = function (type, mediaId, media) {
				var urlCode = editUrls[type];
				return editMedia(mediaId, media, $rootScope.getUrl(urlCode));
			};

			MediaService.deleteMedia = function (type, mediaId) {
				var urlCode = deleteUrls[type];
				return deleteMedia(mediaId, $rootScope.getUrl(urlCode));
			};

			MediaService.deleteMedias = function (mediasId) {
				return deleteMedias(mediasId, $rootScope.getUrl('MEDIAS_DELETE_BULK'));
			};

			MediaService.editMediaConfig = function (type, mediaId, config) {
				var urlCode = editConfigUrls[type];
				return editMediaConfig(mediaId, config, $rootScope.getUrl(urlCode));
			};

			MediaService.getMediaConfig = function (type, mediaId) {
				var urlCode = getConfigUrls[type];
				return getMediaConfig(mediaId, $rootScope.getUrl(urlCode));
			};

			MediaService.duplicateMedia = function (type, mediaId) {
				var urlCode = duplicateUrls[type];
				return duplicateMedia(mediaId, $rootScope.getUrl(urlCode));
			};

			MediaService.getAllMediaItems = function (mediaId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('MEDIAS_ITEMS');
				url = url.replace('{id}', mediaId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MediaService.checkMediaConfig = function (type, mediaId) {
				var urlCode = checkConfigUrls[type];
				return checkMediaConfig(mediaId, $rootScope.getUrl(urlCode));
			};

			MediaService.getAllMedias = function (excludePublic, excludeInactive) {
				return getMedias(excludePublic, excludeInactive, $rootScope.getUrl('MEDIAS_GET_ALL'));
			};

			MediaService.getDownLoadToken = function (mediaItemId, mediasId) {
				const deferred = $q.defer();
				const url = UrlService.generateUrl(
					'MEDIAS_GET_DOWNLOAD_TOKEN',
					{},
					{
						mediasId: mediasId,
						mediaItemId: mediaItemId,
					}
				);
				$http
					.get(url, {
						transformResponse: [
							function (data) {
								return data;
							},
						],
					})
					.then(
						function (response) {
							const downloadUrl = UrlService.generateUrl(
								'MEDIAS_DOWNLOAD_FILE',
								{},
								{ token: encodeURIComponent(response.data) }
							);
							deferred.resolve(downloadUrl);
						},
						function (err) {
							console.error(err);
						}
					);
				return deferred.promise;
			};

			var checkMediaConfig = function (mediaId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var getMedias = function (excludePublic, excludeInactive, url) {
				url = API_BASE_URL_BACKEND + url;
				url = excludePublic ? url + '?excludePublic=true' : url + '?excludePublic=false';
				url = excludeInactive ? url + '&excludeInactive=true' : url + '&excludeInactive=false';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var getMedia = function (mediaId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var createMedia = function (job, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				$http.post(url, job).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			var editMedia = function (mediaId, media, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				$http.put(url, media).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			var deleteMedia = function (mediaId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var deleteMedias = function (mediasId, url) {
				url = API_BASE_URL_BACKEND + url;
				if (Array.isArray(mediasId)) {
					url = url + '?mediasId=' + mediasId[0];
					for (let i = 1; i < mediasId.length; i++) {
						url = url + '&mediasId=' + mediasId[i];
					}
				} else {
					url = url + '?mediasId=' + mediasId;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var editMediaConfig = function (mediaId, mediaConfig, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				$http.put(url, mediaConfig).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			var getMediaConfig = function (mediaId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var duplicateMedia = function (mediaId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', mediaId);
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return MediaService;
		},
	]);
})();
