(function () {
	'use strict';

	angular
		.module('ProjectModule')
		.controller('ProjectController', ProjectController);

	ProjectController.$inject = [
		'$scope',
		'$rootScope',
		'ProjectService',
		'$stateParams',
		'toaster',
		'gettextCatalog',
		'$timeout',
		'$state',
		'UserService',
		'ContextServices',
		'DateService',
		'PermissionService',
		'PROJECT_VAR_NAME',
		'GLOBAL_PERMISSION_CODES',
	];

	function ProjectController(
		$scope,
		$rootScope,
		ProjectService,
		$stateParams,
		toaster,
		gettextCatalog,
		$timeout,
		$state,
		UserService,
		ContextServices,
		DateService,
		PermissionService,
		PROJECT_VAR_NAME,
		GLOBAL_PERMISSION_CODES
	) {
		$scope.dataModel = {};
		$scope.metadata = {};
		$scope.metadata.actif = true;
		$scope.metadata.tags = [];
		$scope.savingInProgress = false;
		$scope.datePattern = 'DD/MM/YYYY HH:mm';
		$scope.permissionData = {};
		$scope.selectedItem = null;
		$scope.searchText = null;
		$scope.projectId = $stateParams.projectId;
		$scope.selectedUsers = [];
		$scope.autocompleteDemoRequireMatch = false;
		$scope.querySearch = querySearch;
		$scope.queryMemberSearch = queryMemberSearch;
		$scope.queryCurrentMemberSearch = queryCurrentMemberSearch;
		$scope.transformChip = transformChip;
		$scope.project = {
			members: [],
			contexts: [],
			creator_permission_template: {},
			member_permission_template: {},
			selectedTab: 0,
			navigation_links: [{ label: 'Pydc', url: '' }],
		};
		$scope.dataModel.save = $scope.saveAndReturnToList;
        $scope.exposuresCodePattern = /^(\/)?([a-zA-Z0-9\-\.\/\_~\:\@\!\$\&\'\(\)\*\+\,\;\=]*)$/;

		let toasterPopSuccess = gettextCatalog.getString('Succès');
		let toasterPopCreationProject = gettextCatalog.getString(
			'Création du Projet effectuée'
		);

		init();

		$scope.saveAndReturnToList = function () {
			$scope.saveProject(true);
		};

		$scope.changeMember = function (item) {
			if ($scope.permissionData.reload) {
				$scope.permissionData.reload();
			}
		};

		$scope.selectUser = function (item) {
			if (item === null || item === undefined) {
				return;
			}
			$scope.selectedUsers.push({
				id: item.id,
				name: item.name,
				active: true,
				has_project_role: item.has_project_role,
				has_member_role: item.has_member_role,
				is_group: item.is_group,
				admin: false,
			});
		};

		$scope.deleteMember = function (item) {
			$scope.selectedUsers.splice($scope.selectedUsers.indexOf(item), 1);
		};

		$scope.validateTemplate = function (open) {
			$scope.project.selectedTab = open;
		};

		$scope.validateMembers = function (open) {
			$scope.project.selectedTab = open;
		};

		$scope.validateStatuses = function (open) {
			$scope.project.selectedTab = open;
		};

		$scope.validateContexts = function (open) {
			$scope.project.selectedTab = open;
		};

		$scope.linkChange = function (newVal) {
			$scope.justTheOnePyDcLink = newVal;
		};

        $scope.validateAdvancedParams = function (open) {
            $scope.project.selectedTab = open;
        };


        $scope.saveAll = function () {
			$scope.saveProject();
		};

		$scope.saveProject = function () {
			if (!validateProject($scope.project) || $scope.projectForm.$invalid) {
				return;
			}

			let projectUpdate = angular.copy($scope.project);
			projectUpdate.effect_date = DateService.getDateInIsoFormat(
				projectUpdate.effect_date
			);
			projectUpdate.expiration_date = DateService.getDateInIsoFormat(
				projectUpdate.expiration_date
			);

			projectUpdate.members = [];
			projectUpdate.contexts = [];
			const users = $scope.selectedUsers;
			for (let u in users) {
				projectUpdate.members.push({
					member_id: users[u].id,
					admin: users[u].admin !== undefined && users[u].admin,
					active: users[u].active !== undefined && users[u].active,
					is_group: users[u].is_group,
				});
			}
			for (let c in $scope.contexts) {
				if ($scope.contexts[c].persistence || $scope.contexts[c].jobs) {
					projectUpdate.contexts.push({
						context_id: $scope.contexts[c].id,
						persistence: $scope.contexts[c].persistence,
						jobs: $scope.contexts[c].jobs,
					});
				}
			}
			projectUpdate.is_public =
				projectUpdate.is_public !== undefined && projectUpdate.is_public;
			// set metadata
			for (var t in $scope.metadata.tags) {
				$scope.metadata.tags[t].color =
					$scope.metadata.tags[t].color !== undefined
						? $scope.metadata.tags[t].color
						: '#dbf5d1';
			}

			projectUpdate.metadata = {
				active: $scope.metadata.actif,
				tags: $scope.metadata.tags,
				licence_type: $scope.metadata.licence_type,
				icon_id: $scope.metadata.icon_id,
				short_label: $scope.metadata.label,
				description: $scope.metadata.description,
			};

			projectUpdate.navigation_links = [
				{ label: 'Pydc', url: $scope.justTheOnePyDcLink },
			];

			projectUpdate.advanced_params = {
				exposures_root_code: projectUpdate.advanced_params && projectUpdate.advanced_params.exposures_root_code
					? projectUpdate.advanced_params.exposures_root_code : undefined
			}

			$scope.savingInProgress = true;
			projectUpdate.metadata.code = $scope.metadata.code;
			let servicePromise = $scope.isNew
				? ProjectService.createProject(projectUpdate)
				: ProjectService.editProject($stateParams.projectId, projectUpdate);
			servicePromise
				.then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopCreationProject);
					$timeout(function () {
						$scope.savingInProgress = false;
						$state.go('projects');
					}, 300);
				})
				.catch(catchProjectSaveErrors);

			if ($stateParams.projectId === sessionStorage.getItem(PROJECT_VAR_NAME)) {
				$rootScope.$broadcast('update:project:selection');
			}
		};

		$scope.showDuplicateModal = function () {
			checkIfNoOperationIsRunningAndOpen(true);
		};

		$scope.showExportModal = function () {
			checkIfNoOperationIsRunningAndOpen(false);
		};

		function checkIfNoOperationIsRunningAndOpen(isDuplication) {
			ProjectService.isAnyExecutionRunning().then(function (response) {
				if (!response.data) {
					if (isDuplication) {
						$scope.elementData = true;
					} else {
						$scope.datas = true;
					}
				} else {
					toaster.pop(
						'error',
						gettextCatalog.getString('project.bulk.execution.in.progress')
					);
				}
			});
		}

		function init() {
			$scope.isNew = true;
			$scope.savingInProgress = false;
			$scope.justTheOnePyDcLink = '';
			if ($stateParams.projectId) {
				$scope.isNew = false;
				$scope.projectId = $stateParams.projectId;
				$scope.metadata.isNew = false;
				ProjectService.getProject($scope.projectId).then(function (response) {
					$scope.project = response.data;
					let projectLink =
						$scope.project.project_links && $scope.project.project_links[0]
							? $scope.project.project_links[0].link_url
							: '';
					$scope.justTheOnePyDcLink = projectLink;

					$timeout(function () {
						$rootScope.$broadcast('project:link-update', projectLink);
					}, 2000);

					$scope.project.effect_date = DateService.dateToStringWithPatternLocal(
						$scope.project.effect_date,
						$scope.datePattern
					);
					$scope.project.expiration_date =
						DateService.dateToStringWithPatternLocal(
							$scope.project.expiration_date,
							$scope.datePattern
						);
					$scope.metadata = $scope.project.metadata;
					$scope.projectLib = $scope.metadata.label;
					$scope.metadataLoaded = true;

					getAllUsers(function () {
						const members = $scope.project.members;
						for (let m in members) {
							let user = _.find($scope.users, function (item) {
								return item.id === members[m].member_id;
							});
							if (user) {
								$scope.selectedUsers.push({
									id: user.id,
									name: user.name,
									admin: members[m].admin,
									active: members[m].active,
									is_group: members[m].is_group,
									has_project_role: user.has_project_role,
									has_member_role: user.has_member_role,
								});
							}
						}
						$scope.currentMembers = angular.copy($scope.selectedUsers);
					});

					getAllContexts(function () {
						for (let c in $scope.project.contexts) {
							let ctx = _.find($scope.contexts, function (item) {
								return item.id === $scope.project.contexts[c].context_id;
							});
							if (ctx) {
								ctx.jobs = $scope.project.contexts[c].jobs;
								ctx.persistence = $scope.project.contexts[c].persistence;
							}
						}
					});

					ProjectService.getProjectLastAccessDates($scope.projectId).then(
						function (responseL) {
							$scope.lastAccessDates = responseL.data;
							for (let u in $scope.lastAccessDates) {
								$scope.lastAccessDates[u] = $rootScope.getDateTimeWithPatternMn(
									$scope.lastAccessDates[u]
								);
							}
						}
					);

					// check if the user has public project creation permission
					const ignoreMemberRole = true;
					PermissionService.haveGlobalPermission(
						GLOBAL_PERMISSION_CODES.public_project_create,
						ignoreMemberRole
					).then(function (response) {
						if (!response.data && !$scope.project.is_public) {
							$scope.disablePublicProjectCreation = true;
						}
					});
				});
			} else {
				$scope.opened = 'A';
				$scope.metadataLoaded = true;
				$scope.disablePublicProjectCreation = false;
				// default dates
				let nowDate = new Date();
				$scope.project.effect_date = DateService.dateToStringWithPatternLocal(
					nowDate,
					$scope.datePattern
				);
				$scope.project.expiration_date =
					DateService.getRevocationDateWithPattern(
						$scope.project.expiration_date,
						$scope.datePattern
					);
				getAllUsers();
				getAllContexts();

				// check if the user has public project creation permission
				const ignoreMemberRole = true;
				PermissionService.haveGlobalPermission(
					GLOBAL_PERMISSION_CODES.public_project_create,
					ignoreMemberRole
				).then(function (response) {
					if (!response.data) {
						$scope.project.is_public = false;
						$scope.disablePublicProjectCreation = true;
					}
				});
			}
		}

		function getAllUsers(action) {
			UserService.getProjectMembers().then(function (members) {
				$scope.users = _.filter(members, function (member) {
					return member.has_member_role || member.has_project_role;
				});
				if (action) {
					action();
				}
			});
		}

		function getAllContexts(action) {
			ContextServices.getContextList(true).then(function (response) {
				$scope.contexts = response.data;
				if (action) {
					action();
				}
			});
		}

		function validateProject(project) {
			if (
				!_.filter($scope.contexts, function (item) {
					return item.persistence;
				})[0]
			) {
				toaster.pop(
					'error',
					gettextCatalog.getString(
						'Un contexte Persistance/Extraction/Export est obligatoire'
					)
				);
				return false;
			}
			if (
				!_.filter($scope.contexts, function (item) {
					return item.jobs;
				})[0]
			) {
				toaster.pop(
					'error',
					gettextCatalog.getString(
						'Un contexte Autres activités est obligatoire'
					)
				);
				return false;
			}
			if (
				!_.filter($scope.selectedUsers, function (item) {
					return (
						item.admin && item.has_project_role && ($scope.isNew || item.active)
					);
				})[0]
			) {
				toaster.pop(
					'error',
					gettextCatalog.getString('Un administrateur est obligatoire')
				);
				return false;
			}
			if (
				project.expiration_date &&
				project.effect_date &&
				moment(project.effect_date).isAfter(moment(project.expiration_date))
			) {
				toaster.pop(
					'error',
					gettextCatalog.getString(
						'La date d’expiration d’un projet doit être strictement inférieur à sa date d’effet. La modifier pour respecter cette règle avant de poursuivre.'
					)
				);
				return false;
			}
			return true;
		}

		function catchProjectSaveErrors(error) {
			if (error.data.code === '52') {
				$scope.project.is_public = true;
			}
			$scope.savingInProgress = false;
		}

		/**
		 * Return the proper object when the append is called.
		 */
		function transformChip(chip) {
			// If it is an object, it's already a known chip
			if (angular.isObject(chip)) {
				return chip;
			}

			// Otherwise, create a new one
			return {
				name: chip,
				type: 'new',
			};
		}

		/**
		 * Search for users.
		 */
		function querySearch(query) {
			return query ? $scope.users.filter(createFilterFor(query)) : [];
		}

		/**
		 * Search for users.
		 */
		function queryMemberSearch(query) {
			return query
				? $scope.selectedUsers.filter(
						createMembersFilterFor(query, $scope.selectedUsers)
				  )
				: [];
		}

		/**
		 * Search for current members.
		 */
		function queryCurrentMemberSearch(query) {
			return query
				? $scope.currentMembers.filter(
						createMembersFilterFor(query, $scope.currentMembers)
				  )
				: [];
		}

		/**
		 * Create filter function for a query string
		 */
		function createFilterFor(query) {
			return function filterFn(user) {
				return (
					!_.find($scope.selectedUsers, function (item) {
						return item.id == user.id;
					}) && user.name.toLowerCase().indexOf(query.toLowerCase()) > -1
				);
			};
		}

		/**
		 * Create filter function for a query string
		 */
		function createMembersFilterFor(query, users) {
			return function filterFn(user) {
				return (
					_.find($scope.selectedUsers, function (item) {
						return item.id == user.id;
					}) && user.name.toLowerCase().indexOf(query.toLowerCase()) > -1
				);
			};
		}
	}
})();
