(function () {
	angular.module('dcApp').directive('entiteList', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'$filter',
				'API_BASE_URL_BACKEND',
				'toaster',
				'$q',
				'EntiteDepotService',
				'DataBlocksService',
				'TypeEntiteService',
				'$timeout',
				'PermissionService',
				'frameService',
				'ExportDcService',
				'DownloadFileService',
				'gettextCatalog',
				'UPDATE_EVENT',
				'DcElementListService',
				function (
					$scope,
					$rootScope,
					$state,
					$filter,
					API_BASE_URL_BACKEND,
					toaster,
					$q,
					EntiteDepotService,
					DataBlocksService,
					TypeEntiteService,
					$timeout,
					PermissionService,
					frameService,
					ExportDcService,
					DownloadFileService,
					gettextCatalog,
					UPDATE_EVENT,
					DcElementListService
				) {
					$scope.editEntityTra = gettextCatalog.getString(
						"Editer l'Entité métier"
					);
					$scope.editDBTra = gettextCatalog.getString('Editer le DataBlock');
					$scope.deleteEntityTra = gettextCatalog.getString(
						"Supprimer l'Entité métier"
					);
					$scope.deleteDBTra = gettextCatalog.getString(
						'Supprimer le DataBlock'
					);
					$scope.currentProjectId = $rootScope.currentProjectId;
					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterCreationDatablock = gettextCatalog.getString(
						'Création du Datablock effectuée'
					);
					let toasterDeleteEntity = gettextCatalog.getString(
						"Suppression de l'Entité métier effectuée"
					);
					let toasterDeleteEntities = gettextCatalog.getString(
						'Suppression des Entités Métier effectuée'
					);

					let unpersistAllConfirmationDialogTitle =
						gettextCatalog.getString('Dépersister tout');
					let unpersistAllconfirmationDialogMessage = gettextCatalog.getString(
						'Êtes-vous sûr de vouloir dépersister toutes les Entités Métier ?'
					);
					let unpersistAllconfirmationDialogActionName =
						gettextCatalog.getString('Confirmer');

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.showCurrentProjectOnly = true;
					$scope.activeElementsOnly = false;

					var vm = this;
					$scope.importedOnly = false;
					$scope.widgetData = {};
					$scope.frameDataConfig = {};
					$scope.disableAllSelectedElems = true;
					$scope.$on(UPDATE_EVENT['ENTITETYPE'], function (event) {
						$scope.initEntites();
					});
					/* entityList for depot*/
					function getLinkedEntiteTypesDepot() {
						EntiteDepotService.getUetByDepotId($scope.elementId, true).then(
							function (response) {
								if (response.data) {
									fillDataGrid(response.data);
								}
							}
						);
					}

					/* entityList for entity*/
					function getAllEntiteType() {
						getListNomencs().then(function (content) {
							if (content !== undefined) {
								fillDataGrid(content);
							}
						});
					}

					function getReferencedElementsResponse(response) {
						if (response.data) {
							let referencedElements = response.data;
							fillDataGrid(
								_.union(
									_.map(referencedElements.entities, function (elm) {
										elm.type = 'ENTITE';
										return elm;
									}),
									_.map(referencedElements.data_blocks, function (elm) {
										elm.type = 'DATABLOCK';
										delete elm.metadata.id;
										angular.extend(elm, elm.metadata);
										delete elm.metadata;
										return elm;
									})
								)
							);
						}
					}

					function getLinkedEntiteTypesDataBlock() {
						if ($scope.referencedElementsIds.length === 0) {
							DataBlocksService.getUsedEntitesAndDataBlocks(
								$scope.elementId
							).then(function (response) {
								getReferencedElementsResponse(response);
							});
						} else {
							DataBlocksService.findUsedEntitiesAndDataBlocksListByIds(
								$scope.elementId,
								$scope.referencedElementsIds
							).then(function (response) {
								getReferencedElementsResponse(response);
							});
						}
					}

					function getListNomencs() {
						var deferred = $q.defer();
						TypeEntiteService.getTypes(
							undefined,
							$scope.showCurrentProjectOnly,
							$scope.activeElementsOnly
						).then(
							function (response) {
								deferred.resolve(response.data);
							},
							function (errorMessage) {
								deferred.reject();
							}
						);
						return deferred.promise;
					}

					let yesTra = gettextCatalog.getString('Oui');
					let noTra = gettextCatalog.getString('Non');
					function fillDataGrid(content) {
						$scope.showGrid = false;
						$scope.nomencs = content;
						$scope.tags = [];
						var tags = [];
						for (var a in $scope.nomencs) {
							if ($scope.nomencs[a].switch_off) {
								$scope.nomencs[a].switch_off = yesTra;
							} else {
								$scope.nomencs[a].switch_off = noTra;
							}

							$scope.nomencs[a].active = $scope.nomencs[a].active
								? yesTra
								: noTra;
							$scope.nomencs[a].loaded_files =
								$scope.nomencs[a].loaded_files != undefined
									? $scope.nomencs[a].loaded_files
									: 0;
							$scope.nomencs[a].lib = $scope.nomencs[a].label;
							$scope.nomencs[a].desc = $scope.nomencs[a].description;
							$scope.nomencs[a].tagAsArray = $rootScope.mapTags(
								$scope.nomencs[a].tags
							);
							$scope.nomencs[a].creationDate =
								$rootScope.getDateTimeWithPattern(
									$scope.nomencs[a].creation_date
								);
							$scope.nomencs[a].createdBy = $scope.nomencs[a].created_by;
							$scope.nomencs[a].updateDate = $rootScope.getDateTimeWithPattern(
								$scope.nomencs[a].update_date
							);
							$scope.nomencs[a].updatedBy = $scope.nomencs[a].updated_by;
							$scope.nomencs[a].isDatablock =
								$scope.nomencs[a].type === 'DATABLOCK';
							$scope.nomencs[a].storageMode =
								$scope.nomencs[a].storage_mode && $scope.nomencs[a].isDatablock
									? $scope.nomencs[a].storage_mode.text
									: '';
							$scope.nomencs[a].isDatablockMode = !$scope.isDatablock;
							tags = _.union(tags, $scope.nomencs[a].tags);
						}
						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.loadData = $scope.nomencs;
						$scope.initNomencs = $scope.nomencs;
						$scope.gridData = angular.copy($scope.nomencs);
						$scope.dataGridOptions.dataSource = $scope.gridData;
						$scope.showGrid = true;
						if ($scope.gridInstance && $scope.gridInstance.option) {
							$scope.gridInstance.option('dataSource', $scope.gridData);
						}

						// Refresh element count
						$scope.vm.elementCount =
							$scope.nomencs != undefined
								? $scope.nomencs.length
								: $scope.vm.elementCount;
					}

					$scope.reloadDataConfirmation = {};
					$scope.entiteFromDepot = {};
					$scope.entiteFromDepot.show = false;
					$scope.showMapping = function () {
						let depot = $scope.depot;
						$scope.entiteFromDepot.depotType = depot.depot_type;
						$scope.entiteFromDepot.depotId = depot.id;
						$scope.entiteFromDepot.lib = depot.metadata.label;
						$scope.entiteFromDepot.updateAction = $scope.afterCreateFromDepot;
						$scope.entiteFromDepot.show = true;
					};

					$scope.afterCreateFromDepot = function () {
						$scope.refreshLInkedEntite();
					};

					$scope.gridInstance = {};
					vm.init = function () {
						// Filter on imported property
						// Binded to imported checkbox
						$scope.filterImported = function () {
							if ($scope.importedOnly) {
								$scope.gridInstance.filter(['imported', '=', true]);
							} else {
								$scope.gridInstance.clearFilter('dataSource');
							}
						};
						$scope.gridData = [];
						$scope.lookupTags = [];
						// Get DxGrid dataGridOptions
						DcElementListService.getDcElementDxList(
							$scope,
							'entity',
							$scope.isLinkedElem
						);
						$scope.initEntites();
					};

					$scope.checkPermissionData = {};
					/* edit depot*/
					$scope.editEntity = function (obj) {
						$scope.objToEdit = obj;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = obj.isDatablock
							? 'datablock'
							: 'entity';
						$scope.checkPermissionData.action = 'edit';
						$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.goToEdit = function () {
						if ($scope.objToEdit.isDatablock) {
							$state.go('datablocks-edit', { id: $scope.objToEdit.id });
						} else {
							$state.go('entites-edit', { entiteId: $scope.objToEdit.id });
						}
					};

					/* edit depot*/

					/* export Hdh*/
					$scope.exportHdh = function (obj) {
						$scope.objToExport = obj;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'export_dc';
						$scope.checkPermissionData.actionIfPermitted = $scope.doExportHdh;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.doExportHdh = function () {
						$scope.exportData = {};
						$scope.exportData.entiteTypeId = $scope.objToExport.id;
						$scope.exportData.entiteTypeLib = $scope.objToExport.lib;
						$scope.exportData.entiteTypeCode = $scope.objToExport.code;
						$scope.exportData.showExportHdh = true;
					};
					/* export Hdh*/

					/* delete single item*/
					$scope.entiteTypeLinekdSources = {};
					$scope.bulkDelete = false;
					$scope.showDeleteModal = function (obj) {
						$scope.elementToDelete = obj;
						$scope.bulkDelete = false;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'delete';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteModal;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.deleteElementData = {};
					$scope.doShowDeleteModal = function (count) {
						$scope.deleteElementData = {
							label: $scope.elementToDelete
								? $scope.elementToDelete.lib
								: undefined,
							doDelete: $scope.bulkDelete
								? $scope.deleteSelectedElements
								: $scope.deleteType,
							showConfirmDeleteElement: true,
							bulk: $scope.bulkDelete,
							count: count,
						};
					};

					$scope.deleteType = function (modalId) {
						TypeEntiteService.deleteEntiteType($scope.elementToDelete.id, true)
							.then(function (response) {
								$('#' + modalId).modal('hide');
								toaster.pop('success', toasterPopSuccess, toasterDeleteEntity);
								$scope.refreshLInkedEntite();
							})
							.catch(function (e) {
								if (e && e.data && e.data.code == '40') {
									$scope.gridInstance.clearSelection();
								}
							});
					};
					/* delete single item*/

					PermissionService.haveGlobalPermission('entity-create').then(
						function (response) {
							$scope.create_authorised = response.data;
						}
					);
					$scope.initEntites = function () {
						PermissionService.haveGlobalPermission('entity').then(function (
							response
						) {
							$scope.entite_authorised = response.data;
							if ($scope.entite_authorised) {
								if ($scope.isDepotType && $scope.elementId != undefined) {
									getLinkedEntiteTypesDepot();
								} else if (
									$scope.isDatablock &&
									$scope.elementId != undefined
								) {
									getLinkedEntiteTypesDataBlock();
								} else if ($scope.isEntity) {
									getAllEntiteType();
								}
							}
						});
					};

					/* BG- unpersistAll*/
					$scope.unpersistAll = function () {
						if (!$scope.widgetData) {
							$scope.widgetData = {};
						}
						$scope.widgetData.confirmationDialogTitle =
							unpersistAllConfirmationDialogTitle;
						$scope.widgetData.confirmationDialogMessage =
							unpersistAllconfirmationDialogMessage;
						$scope.widgetData.confirmationDialogActionName =
							unpersistAllconfirmationDialogActionName;
						$scope.widgetData.enableConfirmDialogAction = true;
						$scope.widgetData.confirmDialogAction = $scope.confirmUnpersistAll;
						$scope.widgetData.showConfirmationDialog = true;
					};

					$scope.confirmUnpersistAll = function () {
						var selectedElems = _.map($scope.selectedElems, function (elm) {
							return elm.code;
						});
						frameService.unpersistMany(selectedElems).then(function (response) {
							frameService.broadcastOperationResponse(response.data);
						});
					};
					/* BG- unpersistAll*/

					/* BG-create datablock*/
					$scope.showCreateDataBlockSelectedElements = function (modalId) {
						$('#' + modalId).modal('show');
					};

					$scope.CreateDataBlockSelectedElements = function (modalId) {
						var selectedElems = _.map($scope.selectedElems, function (elm) {
							return elm.id;
						});
						DataBlocksService.createDataBlockByUets(selectedElems).then(
							function () {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterCreationDatablock
								);
								$scope.gridInstance.clearSelection();
								$('#' + modalId).modal('hide');
							}
						);
					};
					/* BG-create datablock*/

					/*BG- EXPORT */
					$scope.showExportSelectedElements = function (modalId) {
						$('#' + modalId).modal('show');
					};

					$scope.exportSelectedData = function (includeSources, modalId) {
						var selectedElems = _.map($scope.selectedElems, function (elm) {
							return elm.id;
						});
						ExportDcService.exportSelectedUets(
							includeSources,
							selectedElems
						).then(function (urlData) {
							DownloadFileService.download(urlData.data.file_path, 'ZIP');
							$('#' + modalId).modal('hide');
							$scope.gridInstance.clearSelection();
						});
					};
					/*BG- EXPORT */

					/*BG- delete item*/
					$scope.showDeleteSelectedElements = function () {
						$scope.bulkDelete = true;
						$scope.doShowDeleteModal($scope.selectedElems.length);
					};

					$scope.deleteSelectedElements = function () {
						var selectedElems = _.map($scope.selectedElems, function (elm) {
							return elm.id;
						});
						TypeEntiteService.deleteSelectedUets(selectedElems)
							.then(function () {
								$scope.initEntites();
								$scope.gridInstance.clearSelection();
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterDeleteEntities
								);
							})
							.catch(function (e) {
								if (e && e.data && e.data.code == '40') {
									$scope.initEntites();
									$scope.gridInstance.clearSelection();
								}
							});
					};
					/*BG- delete item*/

					/*BG- cache */
					$scope.showLoadAllModal = function (mode) {
						$scope.loadAllMode = mode;
						$('#loadAllModal').modal('show');
					};

					$scope.loadAllData = function () {
						var selectedElems = _.map($scope.selectedElems, function (elm) {
							return elm.code;
						});
						for (var et in selectedElems) {
							if ($scope.loadAllMode == 'PERSIST') {
								frameService
									.persist(selectedElems[et])
									.then(function (response) {
										let data = [];
										data[selectedElems[et]] = response.data;
										frameService.broadcastOperationResponse(data);
									});
							} else if ($scope.loadAllMode == 'CACHE') {
								frameService.cache(selectedElems[et]).then(function (response) {
									let data = [];
									data[selectedElems[et]] = response.data;
									frameService.broadcastOperationResponse(data);
								});
							}
						}
						$scope.gridInstance.clearSelection();
						$('#loadAllModal').modal('hide');
					};
					/*BG- cache */

					/* BG-refresh */
					$scope.refreshLInkedEntite = function () {
						$scope.initEntites();
					};

					$scope.moveOrCopyData = {};
					$scope.moveOrCopyElementToProject = function () {
						let entitesIds = _.map($scope.selectedElems, function (con) {
							return con.id;
						});
						$scope.moveOrCopyData = {
							elements: entitesIds,
							type: 'entiteType',
							showMoveCopy: true,
						};
					};
					$scope.moveOrCopyOneElementToProject = function (elementId) {
						$scope.moveOrCopyData = {
							elements: [elementId],
							type: 'entiteType',
							showMoveCopy: true,
						};
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					depot: '=',
					isDepotType: '=',
					isDatablock: '=',
					isEntity: '=',
					elementId: '=',
					elementCount: '<',
					referencedElementsIds: '=',
					linkedElem: '<',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl: './src/components/directives/entityList/entityList.html',
				link: function (scope, element, attrs, $ctrl) {
					scope.isDepotType = scope.vm.isDepotType;
					scope.isDatablock = scope.vm.isDatablock;
					scope.isEntity = scope.vm.isEntity;
					scope.elementId = scope.vm.elementId;
					scope.isLinkedElem = $ctrl.linkedElem;
					scope.referencedElementsIds = scope.vm.referencedElementsIds;
					scope.depot = scope.vm.depot;
					if (scope.isDepotType) {
						scope.showMappingButton = true;
						scope.showNewButton = false;
						scope.isDatablock = false;
					} else if (scope.isEntity) {
						scope.showMappingButton = false;
						scope.showNewButton = true;
						scope.isDatablock = false;
					}
					scope.vm.init();
				},
			};
		},
	]);
})();
