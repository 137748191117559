(function () {
	'use strict';

	angular.module('dcApp').directive('userLogout', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'UserService',
				function ($scope, $rootScope, UserService) {
					var vm = this;

					$scope.$on('user-logout', function (event, userId) {
						if (vm.userId == userId) {
							$scope.isConnected = false;
							vm.data.isConnected = false;
						}
					});

					UserService.userIsConnected(vm.userId).then(function (response) {
						$scope.isConnected = response.data;
						vm.data.isConnected = response.data;
					});

					$scope.lougout = function () {
						vm.data.confirmLogout(vm.userId);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					userId: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/userLogout/userLogout.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {},
			};
		},
	]);
})();
