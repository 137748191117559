(function () {
	'use strict';

	angular.module('dcApp').controller('AppController', AppController);

	AppController.$inject = [
		'$scope',
		'$http',
		'$rootScope',
		'$state',
		'$window',
		'UserService',
		'AppSelectorService',
		'ParametersServices',
		'LoggerFactory',
		'APP_VERSION',
		'APP_BUILD',
		'APP_BUILD_DATE',
		'DOC_URL',
		'gettextCatalog',
		'ProjectService',
		'PROJECT_VAR_NAME',
		'PROJECT_LABEL_VAR_NAME',
		'BACKEND_CLIENT_ID',
		'ADMIN_ROLE_NAME',
		'MEMBER_ROLE_NAME',
		'USER_ROLE_NAME',
		'PROJECT_ROLE_NAME',
		'SELECTED_MENU',
	];

	function AppController(
		$scope,
		$http,
		$rootScope,
		$state,
		$window,
		UserService,
		AppSelectorService,
		ParametersServices,
		LoggerFactory,
		APP_VERSION,
		APP_BUILD,
		APP_BUILD_DATE,
		DOC_URL,
		gettextCatalog,
		ProjectService,
		PROJECT_VAR_NAME,
		PROJECT_LABEL_VAR_NAME,
		BACKEND_CLIENT_ID,
		ADMIN_ROLE_NAME,
		MEMBER_ROLE_NAME,
		USER_ROLE_NAME,
		PROJECT_ROLE_NAME,
		SELECTED_MENU
	) {
		const logger = LoggerFactory.getLogger('AppController');

		let lastConnection = gettextCatalog.getString('Dernière connexion');

		$scope.appVersion = APP_VERSION;
		$scope.$state = $state;
		$scope.appBuild = APP_BUILD;
		$scope.appBuildDate = APP_BUILD_DATE;
		$scope.year = new Date().getFullYear();
		$scope.docUrl = DOC_URL;
		$scope.currentProject = $rootScope.currentProject;
		$scope.currentProjectId = $rootScope.currentProjectId;
		$scope.hasAdminRole =
			$rootScope.keycloak &&
			$rootScope.keycloak.hasResourceRole(ADMIN_ROLE_NAME, BACKEND_CLIENT_ID);
		$scope.hasProjectRole =
			$rootScope.keycloak &&
			$rootScope.keycloak.hasResourceRole(PROJECT_ROLE_NAME, BACKEND_CLIENT_ID);
		$scope.hasMemberRole =
			$rootScope.keycloak &&
			$rootScope.keycloak.hasResourceRole(MEMBER_ROLE_NAME, BACKEND_CLIENT_ID);
		$scope.hasUserRole =
			$rootScope.keycloak &&
			$rootScope.keycloak.hasResourceRole(USER_ROLE_NAME, BACKEND_CLIENT_ID);
		$scope.isGenericDataMode = $state.current.data.admin; // FIXME: on suppose que si on est admin on est focement en mode Generics, pas de rapport clair
		$scope.noProjectSelected = gettextCatalog.getString(
			'project.none.selected'
		);

		$scope.colapse = {};
		$rootScope.avatarUrl = './src/img/menu_icons/BL_ICONE_CHGT_USER_BLANC.svg';
		$rootScope.avatarDefaultAvatar = true;

		init();

		$scope.showDiversHandleDataMenu = function () {
			if ($scope.hasAdminRole) {
				return true;
			}

			return (
				($scope.isGenericDataMode &&
					($rootScope.havePermission('icons') ||
						$rootScope.havePermission('icons-create') ||
						$rootScope.havePermission('tags') ||
						$rootScope.havePermission('tags-create') ||
						$rootScope.havePermission('metadatas') ||
						$rootScope.havePermission('metadatas-create'))) ||
				(!$scope.isGenericDataMode &&
					($rootScope.havePermission('classement') ||
						$rootScope.havePermission('classement-create') ||
						$rootScope.havePermission('semantic_context') ||
						$rootScope.havePermission('semantic_context-create')))
			);
		};

		// TODO: to remove, not used anymore
		$scope.goToUserManagement = function () {
			window.open(
				$rootScope.keycloak.authServerUrl +
					'/admin/' +
					$rootScope.keycloak.realm +
					'/console/#/realms/' +
					$rootScope.keycloak.realm +
					'/users',
				'_blank'
			);
		};

		$scope.openMonitor = function (selectedMenu) {
			$rootScope.selectedMenu = selectedMenu;
			$window.sessionStorage.setItem(SELECTED_MENU, selectedMenu);
			$rootScope.$broadcast('update:menu:selection', $scope.isGenericDataMode);
			var url = $state.href('monitoring');
			window.open(url, '_blank');
		};

		$scope.navigateTo = function () {
			$scope.isGenericDataMode = !$scope.isGenericDataMode;
			if ($scope.isGenericDataMode) {
				$rootScope.goTo('gd-home');
			} else {
				$rootScope.goTo('home');
			}
			$rootScope.$broadcast('isGenericDataMode', $scope.isGenericDataMode);
			$scope.collapseOutAll();
		};

		$scope.toggleSubMenu = function (id, e) {
			$('#' + id).toggle();
			e.stopPropagation();
			e.preventDefault();
		};

		$scope.hideToggledSubMenu = function () {
			$('.dropdown-submenu>ul.dropdown-menu').hide();
		};

		$scope.collapseOutAll = function () {
			angular.forEach($scope.collapse, function (value, key) {
				$scope.collapse[key] = false;
			});
			$('#main-left-menu .collapse').collapse('hide');
		};

		$scope.getAvatar = function () {
			$rootScope.loadingAvatar = false;
			if (
				$rootScope.account !== undefined &&
				!$rootScope.account.isLoggedOut &&
				!$rootScope.loadingAvatar &&
				$rootScope.avatarDefaultAvatar &&
				$rootScope.avatarUrl ===
					'./src/img/menu_icons/BL_ICONE_CHGT_USER_BLANC.svg'
			) {
				$rootScope.loadingAvatar = true;
				UserService.getUserAvatar()
					.then(function (response) {
						var data = response.data;
						if (
							data.avatar == undefined ||
							data.avatar.length <= 0 ||
							data.avatar_type == undefined ||
							data.avatar_type.length <= 0
						) {
							return;
						}
						$rootScope.avatarUrl =
							'data:' + data.avatar_type + ';base64,' + data.avatar;
						$rootScope.avatarDefaultAvatar = false;
						$rootScope.loadingAvatar = false;
					})
					.catch(function (e) {
						$rootScope.avatarUrl =
							'./src/img/menu_icons/BL_ICONE_CHGT_USER_BLANC.svg';
						$rootScope.avatarDefaultAvatar = true;
						$rootScope.loadingAvatar = false;
					});

				ParametersServices.getEstablishmentData().then(function (response) {
					var data = response.data;
					$rootScope.establishmentName = data['dc.app.establishment.name'];
					if (
						data['dc.app.establishment.logo.type'] &&
						data['dc.app.establishment.logo.type'].length > 0 &&
						data['dc.app.establishment.logo'] &&
						data['dc.app.establishment.logo'].length > 0
					) {
						$rootScope.establishmentLogo =
							'data:' +
							data['dc.app.establishment.logo.type'] +
							';base64,' +
							data['dc.app.establishment.logo'];
					} else {
						$rootScope.establishmentLogo = '';
					}
				});

				ParametersServices.getHandleDataLogo().then(function (response) {
					var data = response.data;
					if (
						data['dc.app.handledata.logo.type'] &&
						data['dc.app.handledata.logo.type'].length > 0 &&
						data['dc.app.handledata.logo'] &&
						data['dc.app.handledata.logo'].length > 0
					) {
						$rootScope.handledataLogo =
							'data:' +
							data['dc.app.handledata.logo.type'] +
							';base64,' +
							data['dc.app.handledata.logo'];
					} else {
						$rootScope.handledataLogo = '';
					}
				});

				ParametersServices.getGenericsDataLogo().then(function (response) {
					var data = response.data;
					if (
						data['dc.app.genericsdata.logo.type'] &&
						data['dc.app.genericsdata.logo.type'].length > 0 &&
						data['dc.app.genericsdata.logo'] &&
						data['dc.app.genericsdata.logo'].length > 0
					) {
						$rootScope.genericsdataLogo =
							'data:' +
							data['dc.app.genericsdata.logo.type'] +
							';base64,' +
							data['dc.app.genericsdata.logo'];
					} else {
						$rootScope.genericsdataLogo = '';
					}
				});
			}
		};

		/**
		 * private functions
		 */

		function init() {
			if ($scope.isGenericDataMode !== undefined) {
				$scope.isGenericDataMode = !!(
					$scope.isGenericDataMode === 'true' || $scope.isGenericDataMode
				);
				$window.sessionStorage.setItem(SELECTED_MENU, $rootScope.selectedMenu);
				$rootScope.$broadcast('isGenericDataMode', $scope.isGenericDataMode);
			} else {
				$scope.isGenericDataMode = false;
				$rootScope.$broadcast('isGenericDataMode', $scope.isGenericDataMode);
			}

			if ($rootScope.selectedMenu === undefined) {
				$scope.isGenericDataMode
					? $rootScope.goTo('gd-home')
					: $rootScope.goTo('home');
			}
			$scope.lastConnection = getLastConnectionData();
			initEvents();
			initWatchers();
		}

		function getLastConnectionData() {
			let lastConnectionDate = $rootScope.getLastLoginDate();
			return lastConnectionDate
				? '|' + lastConnection + ' : ' + lastConnectionDate
				: '';
		}

		function initEvents() {
			$scope.$on('update:menu:selection', function (event, val) {
				$scope.isGenericDataMode = val;
				$rootScope.selectedMenu = $window.sessionStorage.getItem(SELECTED_MENU);
			});

			$scope.$on('isGenericDataMode', function (event, val) {
				$scope.isGenericDataMode = val;
			});
		}

		function initWatchers() {
			$scope.$watch(
				function () {
					return $rootScope.currentProject;
				},
				function (value) {
					$scope.currentProject = $rootScope.currentProject;
				}
			);
		}
	}
})();
