(function () {
	'use strict';

	angular.module('dcApp').directive('dashboardview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$rootScope',
				'$scope',
				'$http',
				'API_BASE_URL_BACKEND',
				'toaster',
				'GRIDSTER_OPTIONS',
				'DashboardService',
				'$timeout',
				'gettextCatalog',
				function (
					$log,
					$rootScope,
					$scope,
					$http,
					API_BASE_URL_BACKEND,
					toaster,
					GRIDSTER_OPTIONS,
					DashboardService,
					$timeout,
					gettextCatalog
				) {
					var vm = this;

					vm.dictionaryOperators = {
						is_null:  gettextCatalog.getString("est null"),
						is_not_null:  gettextCatalog.getString("n’est pas null"),
						equal:  gettextCatalog.getString("égale"),
						not_equaL:  gettextCatalog.getString("différent"),
						is_empty:  gettextCatalog.getString("est vide"),
						is_not_empty:  gettextCatalog.getString("n’est pas vide"),
						contains:  gettextCatalog.getString("contient"),
						not_contains:  gettextCatalog.getString("ne contient pas"),
						begins_with:  gettextCatalog.getString("commence par"),
						not_begins_with:  gettextCatalog.getString("ne commence par"),
						ends_with:  gettextCatalog.getString("termine par"),
						not_ends_with:  gettextCatalog.getString("ne termine pas par"),
						between:  gettextCatalog.getString("dans l’interval"),
						not_between:  gettextCatalog.getString("n’est pas dans l’interval"),
						less:  gettextCatalog.getString("inférieur"),
						less_or_equal:  gettextCatalog.getString("inférieur ou égale"),
						greater:  gettextCatalog.getString("supérieur"),
						greater_or_equal:  gettextCatalog.getString("supérieure ou égale"),
						not_in:  gettextCatalog.getString("n’est pas dans la liste"),
					}

					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.activateZone = gettextCatalog.getString('Activer la zone');
					$scope.deactivateZone =
						gettextCatalog.getString('Désactiver la zone');

					var gridsterOptions = angular.copy(GRIDSTER_OPTIONS);
					gridsterOptions.resizable.enabled = false;

					$scope.getElementCenterings = function (centering) {
						return getAllCenteringMap(
							$scope.dashboardConfig.elements,
							centering.uuid
						);
					};

					$scope.reinitValues = function (centering) {
						if (centering.initValue) {
							centering.initValue(centering);
						}
					};

					$scope.updateCentering = function (
						centeringUuid,
						operator,
						values,
						updated_by,
						updated_by_color
					) {
						let updatedCentering = _.find(
							$scope.dashboardConfig.global.centerings,
							function (item) {
								return item.uuid == centeringUuid;
							}
						);
						if (!centeringUuid) {
							$log.error(centeringUuid + ' is not found');
							return;
						}
						updatedCentering.value_selection = true;
						updatedCentering.updated_by = updated_by;
						updatedCentering.updated_by_color = updated_by_color;
						updatedCentering.value.list_string_value = _.map(
							values,
							function (item) {
								return { text: item };
							}
						);
						updatedCentering.selection_values = values;
						if (operator != 'in' && operator != 'not_in') {
							updatedCentering.selection_values = values;
						}
						updatedCentering.operator = operator;
						updatedCentering.value_selection = true;
					};

					$scope.getOperators = function (centering) {
						if (!$scope.centeringOptions) {
							return;
						}
						if (centering.type == 'string') {
							return $scope.centeringOptions.string_operators;
						} else if (centering.type == 'numeric') {
							return $scope.centeringOptions.numeric_operators;
						} else if (centering.type == 'date') {
							return $scope.centeringOptions.date_operators;
						}
					};

					$scope.operatorRequireValues = function (operator) {
						return (
							operator !== 'is_null' &&
							operator !== 'is_not_null' &&
							operator !== 'is_empty' &&
							operator !== 'is_not_empty'
						);
					};

					$scope.reload = function () {
						var centerings = [];
						$scope.dataMessage = false;
						delete $scope.gridsterOptions;
						$timeout(function () {
							loadDashboard(true);
						});
					};

					//only charts devextreme
					$scope.exportAllCharts = function () {
						let instances = [];
						var elements = document.getElementsByClassName('dc_chart');

						for (var e in elements) {
							let ele = $('#' + elements[e].id);
							let inst;
							if (ele.hasClass('dc_pie')) {
								inst = ele.dxPieChart('instance');
							} else if (ele.hasClass('dc_barline')) {
								inst = ele.dxChart('instance');
							} else if (ele.hasClass('dc_funnel')) {
								inst = ele.dxFunnel('instance');
							} else if (ele.hasClass('dc_gauge_linear')) {
								inst = ele.dxLinearGauge('instance');
							} else if (ele.hasClass('dc_gauge_bar')) {
								inst = ele.dxBarGauge('instance');
							} else if (ele.hasClass('dc_gauge_circular')) {
								inst = ele.dxCircularGauge('instance');
							}

							if (inst) {
								instances.push([inst]);
							}
						}
						DevExpress.viz.exportWidgets(instances, {
							fileName: 'Graphiques',
							format: 'PNG',
						});
					};

					vm.init = function () {
						delete $scope.gridsterOptions;
						$scope.dataMessage = false;
						$scope.uuid = generateUuid('_');
						DashboardService.getCenteringOptions().then(function (
							optionsResponse
						) {
							$scope.centeringOptions = optionsResponse.data;
							if (vm.data.dashboardId) {
								DashboardService.getDashboardConfig(vm.data.dashboardId).then(
									function (response) {
										$scope.dashboardConfig = response.data;
										initDashboard();
									}
								);
							} else if (vm.data.full_data) {
								$scope.dashboardConfig = vm.data.full_data.config.config;
								$scope.publicationLink = vm.data.full_data.link;
								initDashboard();
							}
						});
					};

					var initDashboard = function () {
						if ($scope.dashboardConfig.global.centerings.length == 1) {
							$scope.numberOfCol = 12;
						} else if (
							$scope.dashboardConfig.global.centerings.length == 2 ||
							$scope.dashboardConfig.global.centerings.length == 4
						) {
							$scope.numberOfCol = 6;
						} else {
							$scope.numberOfCol = 4;
						}
						if ($scope.dashboardConfig.global.centerings.length > 9) {
							$scope.numberOfRow = 4;
						} else if ($scope.dashboardConfig.global.centerings.length > 6) {
							$scope.numberOfRow = 3;
						} else if ($scope.dashboardConfig.global.centerings.length > 3) {
							$scope.numberOfRow = 2;
						} else {
							$scope.numberOfRow = 1;
						}
						loadDashboard(false);
						$scope.showExportCharts =
							!vm.data.full_data &&
							_.filter($scope.dashboardConfig.elements, function (item) {
								return item.type == 'CHART';
							})[0];

					};

					var loadDashboard = function (reloadWithCentering) {
						if (!$scope.dashboardConfig.elements[0]) {
							$scope.dataMessage = gettextCatalog.getString(
								'Aucun élément disponible'
							);
							return;
						}
						if (!checkConfig($scope.dashboardConfig.config)) {
							$scope.dataMessage = gettextCatalog.getString(
								'Le Tableau de bord ne peut pas être visualisé. Certains paramètres ne sont pas valides.'
							);
							return;
						}
						// centering
						let centerings = angular.copy($scope.dashboardConfig.global.centerings);
						centerings.forEach(centering => DashboardService.centeringDateToISO(centering));
						for (var e in $scope.dashboardConfig.elements) {
							$scope.dashboardConfig.elements[e].row =
								$scope.dashboardConfig.elements[e].config.row;
							$scope.dashboardConfig.elements[e].col =
								$scope.dashboardConfig.elements[e].config.col;
							$scope.dashboardConfig.elements[e].label_visible =
								$scope.dashboardConfig.elements[e].config.label_visible;
							$scope.dashboardConfig.elements[e].sizeY =
								$scope.dashboardConfig.elements[e].config.size_y;
							$scope.dashboardConfig.elements[e].centering =
								$scope.dashboardConfig.elements[e].config.centering;
							$scope.dashboardConfig.elements[e].centering_modes =
								$scope.dashboardConfig.elements[e].config.centering_modes;
							$scope.dashboardConfig.elements[e]._centerings = centerings;
							delete $scope.dashboardConfig.elements[e].size_y;
							$scope.dashboardConfig.elements[e].sizeX =
								$scope.dashboardConfig.elements[e].config.size_x;
							delete $scope.dashboardConfig.elements[e].size_x;
							if (!$scope.dashboardConfig.elements[e].uuid) {
								$scope.dashboardConfig.elements[e].uuid = generateUuid('_');
							}
						}

						if (vm.data.full_data) {
							if (!reloadWithCentering) {
								loadFullData();
								$scope.gridsterOptions = angular.copy(gridsterOptions);
							} else {
								vm.data.reloadFullData(
									getCenterings(),
									function (updatedFullData) {
										let link = vm.data.full_data.link;
										vm.data.full_data = updatedFullData;
										vm.data.full_data.link = link;
										loadFullData();
										$scope.gridsterOptions = angular.copy(gridsterOptions);
									}
								);
							}
						} else {
							$scope.gridsterOptions = angular.copy(gridsterOptions);
						}
					};

					var getCenterings = function () {
						let centering = angular.copy(getCenteringsFromConfig($scope.dashboardConfig));
						DashboardService.centeringDateToISO(centering);
						return centering;
					};

					var loadFullData = function () {
						setSourceFullData(vm.data.full_data.config.timelines);
						setSourceFullData(vm.data.full_data.config.maps);
						setSourceFullData(vm.data.full_data.config.charts);
						setSourceFullData(vm.data.full_data.config.grids);
						setSourceFullData(vm.data.full_data.config.medias);
						setElementFullConfig(
							vm.data.full_data.config.timelines,
							'TIMELINE'
						);
						setElementFullConfig(vm.data.full_data.config.maps, 'MAP');
						setElementFullConfig(vm.data.full_data.config.charts, 'CHART');
						setElementFullConfig(vm.data.full_data.config.grids, 'GRID');
						setElementFullConfig(vm.data.full_data.config.medias, 'MEDIA');
					};

					var setSourceFullData = function (elements) {
						for (let e in elements) {
							for (let s in elements[e].sources) {
								elements[e].sources[s].data =
									vm.data.full_data.data.data[
										elements[e].sources[s].source_uuid
									];
							}
						}
					};

					var setElementFullConfig = function (elements, type) {
						for (let c in elements) {
							let ele = _.find(
								$scope.dashboardConfig.elements,
								function (item) {
									return item.uuid == elements[c].dash_element_id;
								}
							);
							ele.full_data = elements[c];
							ele.full_data.link = vm.data.full_data.link;
						}
					};

					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/dashboardView/dashboardView.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.vm.data.reinit = scope.vm.init;
					scope.vm.init();
				},
			};
		},
	]);
})();
