(function () {
	'use strict';

	angular.module('dcApp').directive('centeringValue', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'hdSourceService',
				'$timeout',
				'$rootScope',
				'DateService',
				function ($scope, hdSourceService, $timeout, $rootScope, DateService) {
					var vm = this;
					const DATE_PATTERN = "YYYY-MM-DD";
					const DATE_TIME_PATTERN = "YYYY-MM-DD HH:mm:ss";
					vm.pattern = 'YYYY-MM-DD';
					vm.reinit = function (data) {
						vm.data = data;
						vm.init();
					};

					vm.init = function () {
						$scope.autocompleteValues = [];
						$scope.value = {};
						$scope.uuid = generateUuid('_');
						$scope.vm.data.initValue = $scope.vm.reinit;
						let reloaded = vm.data.dashboardReloaded;
						if (
							vm.data.type == 'numeric' &&
							vm.data.operator !== 'between' &&
							vm.data.operator !== 'not_between'
						) {
							if (vm.data.slider) {
								$scope.simpleSliderOptions = {
									min: vm.data.slider_min,
									max: vm.data.slider_max,
									value: vm.data.value.numeric_value,
									step: vm.data.slider_step,
									showRange: false,
								};
								$scope.simpleSliderOptions.onValueChanged = function (data) {
									vm.data.value.numeric_value = data.value;
								};
								$scope.simpleSliderOptions.label = { visible: true };
								$scope.simpleSliderOptions.tooltip = {
									enabled: true,
									position: 'bottom',
								};
							}
						} else if (
							vm.data.type == 'numeric' &&
							(vm.data.operator == 'between' ||
								vm.data.operator == 'not_between')
						) {
							if (vm.data.slider) {
								if (
									!vm.data.value.between_value1 &&
									!vm.data.value.between_value2
								) {
									vm.data.value.between_value1 = vm.data.slider_min;
									vm.data.value.between_value2 = vm.data.slider_max;
								}
								$scope.rangeSliderOptions = {
									min: vm.data.slider_min,
									max: vm.data.slider_max,
									start: vm.data.value.between_value1,
									end: vm.data.value.between_value2,
									step: vm.data.slider_step,
									showRange: false,
								};
								$scope.rangeSliderOptions.onValueChanged = function (data) {
									vm.data.value.between_value1 = data.start;
									vm.data.value.between_value2 = data.end;
								};
								$scope.rangeSliderOptions.label = { visible: true };
								$scope.rangeSliderOptions.tooltip = {
									enabled: true,
									position: 'bottom',
								};
							}
						} else if (
							vm.data.type == 'date' &&
							(vm.data.operator == 'between' ||
								vm.data.operator == 'not_between')
						) {
							if (vm.data.slider) {
								const isWithoutTime = vm.data && vm.data.value && vm.data.value.without_time;
								const minVal =  isWithoutTime ? vm.data.value.between_date_without_time_value1 : vm.data.value.between_date_value1;
								const maxVal =  isWithoutTime ? vm.data.value.between_date_without_time_value2 : vm.data.value.between_date_value2;
								const pattern = isWithoutTime ? DATE_PATTERN : DATE_TIME_PATTERN;

								if (!isWithoutTime) {
									vm.data.value.between_date_value1 =  sliderDateFormatted(vm.data.slider_date_min, pattern);
									vm.data.value.between_date_value2 =  sliderDateFormatted(vm.data.slider_date_max, pattern);
								} else  {
									vm.data.value.between_date_without_time_value1 = sliderDateFormatted(vm.data.slider_date_min, pattern);
									vm.data.value.between_date_without_time_value2 = sliderDateFormatted(vm.data.slider_date_max, pattern);
								}
								$scope.dateRangeSelectorOptions = {
									size: { height: 40 },
									sliderMarker: {
										customizeText: function () {
											return moment(this.value).tz($rootScope.getDefaultTimezone()).format(isWithoutTime ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm:ss");
										},
									},
								};

								$scope.dateRangeSelectorOptions.scale = {
									startValue: loadSliderValues(vm.data.slider_date_min),
									endValue: loadSliderValues(vm.data.slider_date_max),
									minorTickInterval: vm.data.slider_date_step,
									minorTick: { visible: true },
									label: { visible: false },
								};

								$scope.dateRangeSelectorOptions.value = [
									loadSliderValues(minVal),
									loadSliderValues(maxVal)
								];
								$scope.dateRangeSelectorOptions.onValueChanged = function (
									data
								) {
									const formattedString1 = sliderAfterChangeFormatValue(moment(data.value[0]).format(pattern), pattern, isWithoutTime);
									const formattedString2 =  sliderAfterChangeFormatValue(moment(data.value[1]).format(pattern), pattern, isWithoutTime);

									if (isWithoutTime) {
										vm.data.value.between_date_without_time_value1 = formattedString1;
										vm.data.value.betweenIsoDateWithoutTimeValue1 = formattedString1;
										vm.data.value.between_date_without_time_value2 = formattedString2;
										vm.data.value.betweenIsoDateWithoutTimeValue2 = formattedString2;
									} else {
										vm.data.value.between_date_value1 = formattedString1;
										vm.data.value.betweenIsoDateValue1 = formattedString1;
										vm.data.value.between_date_value2 = formattedString2;
										vm.data.value.betweenIsoDateValue2 = formattedString2;
									}

								};
							}
						}
						if (
							vm.data.type == 'string' &&
							vm.data.autocomplete &&
							vm.data.value.string_value
						) {
							$scope.autocompleteValues = [
								{ value: vm.data.value.string_value },
							];
							$scope.value = {
								selected: { value: vm.data.value.string_value },
							};
						}
					};

					$scope.selectValue = function (selectedValue) {
						vm.data.value.string_value =
							$scope.value && $scope.value.selected
								? $scope.value.selected.value
								: undefined;
						//update centering
						if (vm.data.updated_centering && vm.updateCentering) {
							let filterAndAggregate = {
								aggregation_column: vm.data.updated_centering_column,
								filter_column: vm.data.autocomplete_column,
								filter_value: vm.data.value.string_value,
								source: vm.data.autocomplete_source,
								centering_mode: '',
							};
							let filterAndAggregateService = !vm.publicationLink
								? hdSourceService.filterAndAggregate(filterAndAggregate)
								: hdSourceService.filterAndAggregatePublication(
										filterAndAggregate,
										vm.publicationLink
								  );
							filterAndAggregateService.then(function (response) {
								vm.updateCentering(
									vm.data.updated_centering,
									vm.data.updated_centering_operator,
									response.data,
									vm.data.label,
									vm.data.color
								);
							});
						}
					};

					$scope.refreshAutocompleteValues = function (text, isTagsInput) {
						if (!text || text.length < 3) {
							if (isTagsInput) {
								return [];
							} else {
								return;
							}
						}
						$scope.loading = true;
						let promise;
						$scope.autocompleteValues = [];
						if (!vm.publicationLink) {
							let source_columns = {};
							let elementCenterings = vm.getCenterings();
							if (vm.data.autocomplete_mode == 'reference') {
								source_columns[vm.data.autocomplete_source] =
									vm.data.autocomplete_column;
							} else if (vm.data.autocomplete_mode == 'simple') {
								source_columns = elementCenterings.columns;
							}
							promise = hdSourceService
								.autocomplete({
									text: text,
									source_columns: source_columns,
									centering_modes: elementCenterings.modes,
								})
								.then(function (response) {
									return fillAutocompleteValues(
										response.data,
										isTagsInput,
										text
									);
								});
						} else {
							promise = hdSourceService
								.autocompletePublication(
									{ text: text, centering: vm.data.uuid },
									vm.publicationLink
								)
								.then(function (response) {
									return fillAutocompleteValues(
										response.data,
										isTagsInput,
										text
									);
								});
						}
						if (isTagsInput) {
							return promise;
						}
					};

					var fillAutocompleteValues = function (data, isTagsInput, text) {
						for (var d in data) {
							$scope.autocompleteValues.push({ value: data[d] });
						}
						// sort alphabetically
						$scope.autocompleteValues = _.sortBy(
							$scope.autocompleteValues,
							function (el) {
								return el.value.toLowerCase();
							}
						);
						// show the element that start with text first
						$scope.autocompleteValues = _.sortBy(
							$scope.autocompleteValues,
							function (el) {
								return el.value.toLowerCase().startsWith(text.toLowerCase())
									? -1
									: 1;
							}
						);
						// add empty element
						$scope.autocompleteValues.unshift({});
						$scope.loading = false;
						if (isTagsInput) {
							return _.map($scope.autocompleteValues, function (elm) {
								return elm.value;
							});
						}
					};

					$scope.getSelectionValues = function (isTagsInput) {
						return isTagsInput
							? vm.data.selection_values
							: [''].concat(vm.data.selection_values);
					};

					function sliderDateFormatted(date, pattern) {
						if (!date || (date && date.includes("T"))) {
							return date;
						}
						const dateJs = DateService.getJSDateForDcCalendarWithPattern(date, pattern, true);

						return moment(dateJs).format(pattern);
					}

					function sliderAfterChangeFormatValue(newDate, pattern, dateOnly) {
					  let parsedDate = moment(newDate, pattern);
					  let date;
					  if (dateOnly && parsedDate._isValid ) {
					  	parsedDate = parsedDate.toDate();
					  	date = moment(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()))
					  } else {
					  	date = parsedDate;
					  }

						return DateService.dateToISOString(date);
					}

					function loadSliderValues(date) {
						if (!date) {
							return date;
						}
						if (date.includes("T")) {
							return moment(date).tz($rootScope.getDefaultTimezone());
						}

						if (moment(date, DATE_PATTERN).isValid()) {
							return moment.tz(date, DATE_PATTERN, $rootScope.getDefaultTimezone());
						} else {
								return moment.tz(date, DATE_TIME_PATTERN, $rootScope.getDefaultTimezone());
						}
					}

				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					publicationLink: '=',
					getCenterings: '&',
					updateCentering: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/centeringValue/centeringValue.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.vm.init();
				},
			};
		},
	]);
})();
