(function () {
	'use strict';

	angular.module('dcApp').controller('ParametersController', [
		'ParametersServices',
		'$scope',
		'$rootScope',
		'toaster',
		'permissionChecker',
		'gettextCatalog',
		function (
			ParametersServices,
			$scope,
			$rootScope,
			toaster,
			permissionChecker,
			gettextCatalog
		) {
			let toasterSuccess = gettextCatalog.getString('Succès');
			let toasterError = gettextCatalog.getString('Erreur');
			let toasterErrorPassword = gettextCatalog.getString(
				'Le mot de passe et la confirmation du mot de passe sont différents'
			);
			let toasterSaveParameters = gettextCatalog.getString(
				'Paramètres enregistrés'
			);
			let toasterSaveLogin = gettextCatalog.getString('Logo enregistré !');
			let toasterDeleteLogin = gettextCatalog.getString('Logo supprimé !');
			let toasterSaveParameter = gettextCatalog.getString('Paramètre enregistré');
			let toasterNotSaveParameter = gettextCatalog.getString("Paramètre n'est pas enregistré");

			$scope.securityList = [
				{ code: '' },
				{ code: 'TLS' },
				{ code: 'STARTTLS' },
			];

			var init = function () {
				$scope.parameters = {};
				ParametersServices.getAllParameters().then(function (response) {
					$scope.parameters = response.data.v1;
					$scope.advancedParameters = response.data.v2;
					$scope.auth = $scope.parameters['dc.app.smtp.auth'] === 'true';
					$scope.ihmdb = $scope.parameters['dc.app.smtp.config'] === 'ihmdb';
					$scope.oldPwd = $scope.parameters['dc.app.smtp.pwd'];
					$scope.jobCacheEnabled = $scope.parameters['dc.app.jobs.cache.enabled'] === 'true';
					if (
						$scope.parameters['dc.app.establishment.logo.type'] &&
						$scope.parameters['dc.app.establishment.logo.type'].length > 0 &&
						$scope.parameters['dc.app.establishment.logo'] &&
						$scope.parameters['dc.app.establishment.logo'].length > 0
					) {
						$scope.logo =
							'data:' +
							$scope.parameters['dc.app.establishment.logo.type'] +
							';base64,' +
							$scope.parameters['dc.app.establishment.logo'];
					} else {
						$scope.logo = '';
					}
					if (
						$scope.parameters['dc.app.handledata.logo.type'] &&
						$scope.parameters['dc.app.handledata.logo.type'].length > 0 &&
						$scope.parameters['dc.app.handledata.logo'] &&
						$scope.parameters['dc.app.handledata.logo'].length > 0
					) {
						$scope.handledataLogo =
							'data:' +
							$scope.parameters['dc.app.handledata.logo.type'] +
							';base64,' +
							$scope.parameters['dc.app.handledata.logo'];
					} else {
						$scope.handledataLogo = '';
					}
					if (
						$scope.parameters['dc.app.genericsdata.logo.type'] &&
						$scope.parameters['dc.app.genericsdata.logo.type'].length > 0 &&
						$scope.parameters['dc.app.genericsdata.logo'] &&
						$scope.parameters['dc.app.genericsdata.logo'].length > 0
					) {
						$scope.genericsdataLogo =
							'data:' +
							$scope.parameters['dc.app.genericsdata.logo.type'] +
							';base64,' +
							$scope.parameters['dc.app.genericsdata.logo'];
					} else {
						$scope.genericsdataLogo = '';
					}
					$rootScope.establishmentName =
						$scope.parameters['dc.app.establishment.name'];
					$rootScope.establishmentLogo = $scope.logo;
					$rootScope.handledataLogo = $scope.handledataLogo;
					$rootScope.genericsdataLogo = $scope.genericsdataLogo;
				});
			};

			permissionChecker.checkGlobalPermission(
				'parameters',
				function () {
					init();
				},
				function () {
					$scope.parameters_unauthorised = true;
				}
			);

			$scope.cutParamLib = function (lib) {
				str.substring(0, 40);
			};

			$scope.cancelSave = function () {
				$('#confirmationModal').modal('hide');
			};

			$scope.saveParameters = function () {
				if (
					$scope.ihmdb &&
					$scope.oldPwd !== $scope.parameters['dc.app.smtp.pwd'] &&
					$scope.parameters['dc.app.smtp.pwd'] !== $scope.password_confirmation
				) {
					toaster.pop('error', toasterError, toasterErrorPassword);
					$rootScope.fields.push('dc.app.smtp.pwd');
					$rootScope.fields.push('password_confirmation');
					$('#confirmationModal').modal('hide');
					return;
				}
				$('#confirmationModal').modal('show');
			};

			$scope.confirmSave = function () {
				$scope.parameters['dc.app.smtp.auth'] = $scope.auth;

				$('#confirmationModal').modal('hide');
				var params = $scope.parameters;
				delete params['dc.app.establishment.logo'];
				delete params['dc.app.establishment.logo.type'];
				delete params['dc.app.handledata.logo'];
				delete params['dc.app.handledata.logo.type'];
				delete params['dc.app.genericsdata.logo'];
				delete params['dc.app.genericsdata.logo.type'];
				delete params['dc.app.jobs.cache.enabled'];
				ParametersServices.updateParameters(params).then(function (response) {
					$rootScope.establishmentName =
						$scope.parameters['dc.app.establishment.name'];
					toaster.pop('success', toasterSuccess, toasterSaveParameters);
				});
			};

			$scope.showLogoUpload = function (modalId) {
				$('#' + modalId).modal('show');
			};

			$scope.showLogoDelete = function (modalId, type) {
				$('#' + modalId).modal('show');
				$scope.logoType = type;
			};
			$scope.logoFile = {};
			$scope.saveLogo = function (modalId) {
				ParametersServices.saveLogo($scope.logoFile).then(function (response) {
					var data = response.data;
					if (
						data['dc.app.establishment.logo.type'] &&
						data['dc.app.establishment.logo.type'].length > 0 &&
						data['dc.app.establishment.logo'] &&
						data['dc.app.establishment.logo'].length > 0
					) {
						$scope.logo =
							'data:' +
							data['dc.app.establishment.logo.type'] +
							';base64,' +
							data['dc.app.establishment.logo'];
					} else {
						$scope.logo = '';
					}
					$rootScope.establishmentName = data['dc.app.establishment.name'];
					$rootScope.establishmentLogo = $scope.logo;
					toaster.pop('success', toasterSuccess, toasterSaveLogin);
					$('#' + modalId).modal('hide');
				});
			};

			$scope.saveHandledataLogo = function (modalId) {
				ParametersServices.saveHandledataLogo($scope.logoFile).then(function (
					response
				) {
					var data = response.data;
					if (
						data['dc.app.handledata.logo.type'] &&
						data['dc.app.handledata.logo.type'].length > 0 &&
						data['dc.app.handledata.logo'] &&
						data['dc.app.handledata.logo'].length > 0
					) {
						$scope.handledataLogo =
							'data:' +
							data['dc.app.handledata.logo.type'] +
							';base64,' +
							data['dc.app.handledata.logo'];
					} else {
						$scope.handledataLogo = '';
					}
					$rootScope.handledataLogo = $scope.handledataLogo;
					toaster.pop('success', toasterSuccess, toasterSaveLogin);
					$('#' + modalId).modal('hide');
				});
			};

			$scope.saveGenericsdataLogo = function (modalId) {
				ParametersServices.saveGenericsdataLogo($scope.logoFile).then(function (
					response
				) {
					var data = response.data;
					if (
						data['dc.app.genericsdata.logo.type'] &&
						data['dc.app.genericsdata.logo.type'].length > 0 &&
						data['dc.app.genericsdata.logo'] &&
						data['dc.app.genericsdata.logo'].length > 0
					) {
						$scope.genericsdataLogo =
							'data:' +
							data['dc.app.genericsdata.logo.type'] +
							';base64,' +
							data['dc.app.genericsdata.logo'];
					} else {
						$scope.genericsdataLogo = '';
					}
					$rootScope.genericsdataLogo = $scope.genericsdataLogo;
					toaster.pop('success', toasterSuccess, toasterSaveLogin);
					$('#' + modalId).modal('hide');
				});
			};

			$scope.deleteLogo = function (modalId) {
				if ($scope.logoType == 'establishmentLogo') {
					ParametersServices.deleteLogo().then(function (response) {
						$rootScope.establishmentLogo = '';
						$scope.logo = '';
						$scope.logoType = '';
						toaster.pop('success', toasterSuccess, toasterDeleteLogin);
						$('#' + modalId).modal('hide');
					});
				} else if ($scope.logoType == 'handledataLogo') {
					ParametersServices.deleteHandledataLogo().then(function (response) {
						$rootScope.handledataLogo = '';
						$scope.handledataLogo = '';
						$scope.logoType = '';
						toaster.pop('success', toasterSuccess, toasterDeleteLogin);
						$('#' + modalId).modal('hide');
					});
				} else if ($scope.logoType == 'genericsdataLogo') {
					ParametersServices.deleteGenericdataLogo().then(function (response) {
						$rootScope.genericsdataLogo = '';
						$scope.genericsdataLogo = '';
						$scope.logoType = '';
						toaster.pop('success', toasterSuccess, toasterDeleteLogin);
						$('#' + modalId).modal('hide');
					});
				}
			};

			$scope.changeJobCacheStatus = function() {
					ParametersServices.updateCacheStatus($scope.jobCacheEnabled).then(
							function () {
								toaster.pop('success', toasterSuccess, toasterSaveParameter);
							 },
							function (error) {
								$scope.jobCacheEnabled = !$scope.jobCacheEnabled;
								toaster.pop('error', toasterError, toasterNotSaveParameter);
							}
						);
			}
		},
	]);
})();
