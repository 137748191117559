(function () {
	'use strict';

	angular.module('dcApp').factory('ExportHistoryService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'httpService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, httpService) {
			var ExportHistoryService = {};

			ExportHistoryService.getAll = function (skip, take, options) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DATABLOCKS_EXPORT_HISTORY');
				if (take) {
					url = url + '?skip=' + skip + '&take=' + take;
				}
				return httpService.postRequest(url, options);
			};

			ExportHistoryService.getProjectExportHistory = function (
				projectId,
				skip,
				take,
				options
			) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_EXPORT_HISTORY_BY_PROJECT');
				url = url.replace('{id}', projectId);
				if (take) {
					url = url + '?skip=' + skip + '&take=' + take;
				}
				return httpService.postRequest(url, options);
			};

			ExportHistoryService.getProjectExportHistoryCount = function (projectId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_EXPORT_HISTORY_BY_PROJECT_COUNT');
				url = url.replace('{id}', projectId);
				const deferred = $q.defer();
				httpService.getRequest(url).then(function (response) {
					deferred.resolve(response);
				});
				return deferred.promise;
			};

			ExportHistoryService.getFilesListByJobId = function (jobId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('EXPORT_GET_FILE');
					url = url.replace('{jobId}', jobId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ExportHistoryService.getRequestDetailsById = function (historyId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATABLOCKS_EXPORT_HISTORY_REQUEST');
				url = url.replace('{exportHistoryId}', historyId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return ExportHistoryService;
		},
	]);
})();
