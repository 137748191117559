(function () {
	'use strict';

	angular.module('dcApp').directive('downloadFiles', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$window',
				'DownloadFileService',
				function ($scope, $window, DownloadFileService) {

					var vm = this;
					vm.downloadAll = downloadAll;
					vm.downloadOne = downloadOne;
					vm.cancel = closeModal;
					vm.init = init;
					vm.files = [];

					function downloadAll() {
						downloadFiles(vm.files);
					};

					function downloadOne(filePath, type) {
						DownloadFileService.download(filePath, type);
					};

					function init(files) {
						vm.files = [];
						for (var f in files) {
							const fileName =
								files[f].file_path && files[f].file_path.includes('/')
									? files[f].file_path.split('/')[1]
									: files[f].file_path;
							vm.files.push({
								path: files[f].file_path,
								name: fileName,
								type: files[f].format,
								root: files[f].root,
							});
						}
					};

					function downloadFiles(files) {
						function download_next(i) {
							if (i >= files.length) {
								return;
							}
							downloadOne(files[i].path, files[i].type);
							setTimeout(function () {
								download_next(i + 1);
							}, 500);
						}
						// Initiate the first download.
						download_next(0);
					}

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showDownloadFiles = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/downloadFiles/downloadFiles.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showDownloadFiles = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showDownloadFiles;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.vm.init(scope.vm.widgetData.filesToExport);
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
