(function () {
	'use strict';

	angular
		.module('dcApp')
		.constant('CHART_TYPE', {
			GaugeBar: 'gauge_bar',
			GaugeLinear: 'gauge_linear',
			GaugeCircular: 'gauge_circular',
			BarLine: 'barline',
			Pie: 'pie',
			TreeMap: 'treemap',
			Funnel: 'funnel',
			Sankey: 'sankey',
			Scatter: 'scatter',
			Range: 'range',
			Indicator: 'indicator',
		})
		.constant('BARCHART_SUBTYPE', {
			Line: 'line',
			Bar: 'bar',
			Area: 'area',
		})
		.directive('charttype', [
			'$parse',
			function ($parse) {
				var controller = [
					'$scope',
					'CHART_TYPE',
					'BARCHART_SUBTYPE',
					function ($scope, CHART_TYPE, BARCHART_SUBTYPE) {
						var vm = this;
						vm.ChartType = CHART_TYPE;
						vm.BarChartType = BARCHART_SUBTYPE;
					},
				];

				return buildDirectiveReturnObject(
					{ type: '<', subType: '<' },
					controller,
					'./src/components/directives/handledata/chartType/chartType.html',
					function postLink(scope, element, attrs) {}
				);
			},
		]);
})();
