(function () {
	'use strict';

	angular.module('dcApp').directive('grammarFormatter', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$window',
				'$stateParams',
				'$timeout',
				'DataBlocksService',
				function ($scope, $window, $stateParams, $timeout, DataBlocksService) {
					var vm = this;

					$scope.isOlder = true;
					$scope.fromEntity = false;
					$scope.isCopied = false;
					$scope.notFound = false;
					$scope.handleRadioClick = function () {
						$scope.isOlder = $scope.etatGrammaire === 'isOlder';
						$scope.grammar = $scope.isOlder
							? $scope.savedGrammar
							: $scope.currentGrammar;
					};
					$scope.handleSearch = function () {
						$scope.notFound = false;
						$scope.results = getValues($scope.grammar, $scope.toSearch);
						if ($scope.results.length > 0) {
							$scope.grammar = $scope.results;
						} else if ($scope.results.length == 0) {
							$scope.notFound = true;
						}
						if ($scope.toSearch === '') {
							$scope.notFound = false;
							if (!$scope.isOlder) $scope.grammar = $scope.currentGrammar;
							if ($scope.isOlder) $scope.grammar = $scope.savedGrammar;
							if ($scope.fromEntity) $scope.grammar = vm.data;
						}
					};
					$scope.getEntity = function () {
						$scope.grammar = vm.data;
						vm.element.modal('show');
					};
					$scope.getDatablock = function () {
						$scope.etatGrammaire = 'isOlder';
						$scope.currentGrammar = {
							from_datablock: vm.data.from_datablock,
							columns: vm.data.columns,
							summary_config: vm.data.summary_config,
							steps: vm.data.steps,
						};
						let datablockId = $stateParams.id;
						DataBlocksService.getDataBlock(datablockId).then(function (
							response
						) {
							$scope.datablock = response.data;
							$scope.savedGrammar = {
								from_datablock: $scope.datablock.grammar.from_datablock,
								columns: $scope.datablock.grammar.columns,
								summary_config: $scope.datablock.grammar.summary_config,
								steps: $scope.datablock.grammar.steps,
							};
							$scope.grammar = $scope.savedGrammar;
						});
						$(vm.element).modal('show');
					};
					$scope.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showGrammarDialog = false;
						$scope.toSearch = '';
						$scope.notFound = false;
					};
					$scope.copyGrammar = function () {
						copyToClipboard(JSON.stringify($scope.grammar));
						$scope.isCopied = true;
						$timeout(function () {
							$scope.isCopied = false;
						}, 600);
					};
					function getValues(obj, val) {
						var objects = [];
						for (var i in obj) {
							if (!obj.hasOwnProperty(i)) continue;
							if (typeof obj[i] == 'object') {
								objects = objects.concat(getValues(obj[i], val));
							} else if (
								obj[i] &&
								obj[i].toString().toUpperCase().includes(val.toUpperCase())
							) {
								if (objects.lastIndexOf(obj) == -1) objects.push(obj);
							}
						}
						return objects;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dialog/grammarFormatter/grammarFormatter.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showGrammarDialog = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data ? scope.vm.data.showGrammarDialog : false;
						},
						function () {
							if (!scope.vm.data.fromEntity) scope.getDatablock();
							else scope.fromEntity = true;
							scope.getEntity();
						},
						true
					);
				}
			);
		},
	]);
})();
