(function () {
	'use strict';

	angular.module('dcApp').config([
		'$locationProvider',
		function ($locationProvider) {
			$locationProvider.html5Mode(false);
			$locationProvider.hashPrefix('');
		},
	]);

	angular.module('dcApp').config([
		'$logProvider',
		'$provide',
		function ($logProvider, $provide) {
			$provide.decorator('$log', [
				'$delegate',
				function ($delegate) {
					var origInfo = $delegate.info;
					var origLog = $delegate.log;

					console.warn = () => {};
					console.debug = () => {};
					console.info = () => {};

					$delegate.info = function () {
						if ($logProvider.debugEnabled()) origInfo.apply(null, arguments);
					};

					$delegate.log = function () {
						if ($logProvider.debugEnabled()) origLog.apply(null, arguments);
					};

					$delegate.warn = function () {
						if ($logProvider.debugEnabled()) origLog.apply(null, arguments);
					};

					return $delegate;
				},
			]);
		},
	]);
})();
