(function () {
	'use strict';

	angular.module('AdministrationModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('administration-parent', {
			url: '/',
			views: {
				content: {
					template: '<ui-view/>',
				},
			},
			parent: 'logged',
			abstract: true,
		});

		// 	User Groups
		$stateProvider.state('user-groups-parent', {
			url: 'group',
			parent: 'administration-parent',
			abstract: true,
		});

		$stateProvider.state('user-groups', {
			url: '/',
			parent: 'user-groups-parent',
			data: {
				admin: true,
				access: 'admin',
				title: "Groupes d'utilisateurs",
			},
			views: {
				'@user-groups-parent': {
					templateUrl: './src/components/modules/user-group/groups.html',
					controller: 'UserGroupListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('user-groups-edit', {
			url: '/edit/:id',
			parent: 'user-groups-parent',
			data: {
				admin: true,
				access: 'admin',
				title: "Edition Groupe d'utilisateur",
			},
			views: {
				'@user-groups-parent': {
					templateUrl: './src/components/modules/user-group/groupForm.html',
					controller: 'EditUserGroupController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('user-groups-new', {
			url: '/new',
			parent: 'user-groups-parent',
			data: {
				admin: true,
				access: 'admin',
				title: "Nouveau Groupe d'utilisateur",
			},
			views: {
				'@user-groups-parent': {
					templateUrl: './src/components/modules/user-group/groupForm.html',
					controller: 'EditUserGroupController',
					controllerAs: '$ctrl',
				},
			},
		});

		// 	Users
		$stateProvider.state('user-parent', {
			url: 'user',
			parent: 'administration-parent',
			abstract: true,
		});

		$stateProvider.state('users', {
			url: '/',
			parent: 'user-parent',
			data: {
				admin: true,
				access: 'admin',
				title: 'Utilisateurs',
			},
			views: {
				'@user-parent': {
					templateUrl: './src/components/modules/user/users.html',
					controller: 'UserListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('users-edit', {
			url: '/edit/:id',
			parent: 'user-parent',
			data: {
				admin: true,
				access: 'admin',
				title: "Edition de l'utilisateur",
			},
			views: {
				'@user-parent': {
					templateUrl: './src/components/modules/user/userForm.html',
					controller: 'EditUserController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('users-new', {
			url: '/new',
			parent: 'user-parent',
			data: {
				admin: true,
				access: 'admin',
				title: 'Nouvel utilisateur',
			},
			views: {
				'@user-parent': {
					templateUrl: './src/components/modules/user/userForm.html',
					controller: 'EditUserController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('users-profile', {
			url: '/profile',
			parent: 'user-parent',
			data: {
				admin: true,
				access: '*',
				title: 'Mon compte',
			},
			views: {
				'@user-parent': {
					templateUrl: './src/components/modules/user/userProfile.html',
					controller: 'UserProfileController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('users-groups', {
			url: '/groups',
			parent: 'user-parent',
			data: {
				admin: true,
				access: '*',
				title: 'Mes groupes',
			},
			views: {
				'@user-parent': {
					templateUrl: './src/components/modules/user/groups.html',
					controller: 'UserGroupsController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
