(function () {
	'use strict';

	angular
		.module('dcApp')
		.factory('ChartViewCommonFactory', ChartViewCommonFactory);

	ChartViewCommonFactory.$inject = [
		'$filter',
		'$state',
		'$window',
		'ChartService',
		'hdSourceService',
		'PermissionService',
		'gettextCatalog',
	];

	function ChartViewCommonFactory(
		$filter,
		$state,
		$window,
		ChartService,
		hdSourceService,
		PermissionService,
		gettextCatalog
	) {
		return {
			initialize: initialize,
			initializeDirectiveScope: initializeDirectiveScope,
			chartAutoResizing: chartAutoResizing,
		};

		// Chart does not fit the width after the page resizing. Dx does not handle it natively. The solution is to render the chart after each resize
		function chartAutoResizing(scope, dxInstance, timeout) {
			var resizeTimeout;
			function handleResize() {
				// Clear the previous resizeTimeout (if any)
				clearTimeout(resizeTimeout);
				// Set a new timeout to trigger your desired action after resizing has "finished"
				resizeTimeout = setTimeout(function () {
					// Render the chart
					dxInstance.render();
				}, timeout || 320);
			}
			// Bind the handleResize function to the window's resize event
			angular.element(window).on('resize', handleResize);
			// Clean up the event listener when the controller is destroyed
			scope.$on('$destroy', function () {
				angular.element(window).off('resize', handleResize);
			});
		}

		function initialize(
			type,
			scope,
			checkConfig,
			loadGroupDataInChart,
			ignoreCache
		) {
			scope.dataMessage = false;
			scope.disableDataSource = false;
			scope.disableDataSourceFromMenu = true;
			scope.uuid = generateUuid('_');

			scope.chartType = type;
			if (!scope.vm.data.full_data) {
				PermissionService.havePermission(
					scope.vm.data.chartId,
					'edit',
					'chart'
				).then(function (response) {
					scope.editIsPermitted = response.data;
				});
				ChartService.getChartConfig(type, scope.vm.data.chartId).then(function (
					response
				) {
					scope.config = response.data;
					initializeWithConfig(
						scope,
						hdSourceService,
						loadGroupDataInChart,
						ignoreCache,
						$filter,
						gettextCatalog,
						checkConfig,
						false
					);
				});
			} else {
				switch (type) {
					case 'barline':
						scope.config = scope.vm.data.full_data.bar;
						break;
					case 'pie':
						scope.config = scope.vm.data.full_data.pie;
						break;
					case 'funnel':
						scope.config = scope.vm.data.full_data.funnel;
						break;
					case 'range':
						scope.config = scope.vm.data.full_data.range;
						break;
					case 'sankey':
						scope.config = scope.vm.data.full_data.sankey;
						break;
					case 'scatter':
						scope.config = scope.vm.data.full_data.scatter;
						break;
					case 'gauge_linear':
						scope.config = scope.vm.data.full_data.gauge_linear;
						break;
					case 'gauge_circular':
						scope.config = scope.vm.data.full_data.gauge_circular;
						break;
					case 'gauge_bar':
						scope.config = scope.vm.data.full_data.gauge_bar;
						break;
					case 'indicator':
						scope.config = scope.vm.data.full_data.indicator;
						break;
				}
				initializeWithConfig(
					scope,
					hdSourceService,
					loadGroupDataInChart,
					ignoreCache,
					$filter,
					gettextCatalog,
					checkConfig,
					true
				);
			}
		}

		function initializeDirectiveScope(scope, vm, type) {
			scope.rerun = function () {
				scope.showTableData = false;
				vm.init(false);
			};

			scope.rerunWithoutCache = function () {
				vm.init(true);
			};

			scope.edit = function () {
				const url = $state.href('charts-edit', {
					type: type,
					id: scope.vm.data.chartId,
				});
				$window.open(url, '_blank');
				return;
			};

			scope.switchDataVisibility = function () {
				scope.showTableData = !scope.showTableData;
			};
		}

		/**
		 * Private functions
		 */

		function initializeWithConfig(
			scope,
			hdSourceService,
			loadGroupDataInChart,
			ignoreCache,
			$filter,
			gettextCatalog,
			checkConfig,
			fullData
		) {
			if (!scope.config.groups[0]) {
				scope.dataMessage = gettextCatalog.getString(
					'Aucune source disponible'
				);
				return;
			}

			scope.showPopover = function ($event, obj) {
				if (Object.keys(obj).length === 0) {
					scope.noFilterApplied = true;
				} else {
					scope.noFilterApplied = false;
				}
				// Set a bigger z-index
				$(function () {
					DevExpress.ui.dxOverlay.baseZIndex(1999999998);
				});
			};

			scope.group = angular.copy(scope.config.groups[0]);
			if (!checkConfig(scope.config)) {
				scope.dataMessage = gettextCatalog.getString(
					'Le Graphique ne peut pas être visualisé. Certains paramètres ne sont pas valides.'
				);
				return;
			}

			if (scope.jobDetails && scope.jobDetails.init) {
				scope.jobDetails.init();
			}

			if (!fullData) {
				initializeGroup(
					scope,
					hdSourceService,
					loadGroupDataInChart,
					ignoreCache,
					$filter
				);
			} else {
				initializeGroupWithFullData(
					scope,
					hdSourceService,
					loadGroupDataInChart,
					ignoreCache,
					$filter
				);
			}
		}

		function initializeGroup(
			scope,
			hdSourceService,
			loadGroupDataInChart,
			ignoreCache,
			$filter
		) {
			function initPopover() {
				scope.filterDetailsPopover = {
					target: '#filterInfoPopover' + scope.uuid,
					showEvent: 'dxclick',
					position: 'top',
					width: 450,
					bindingOptions: {
						visible: 'visibleMetaData',
					},
				};
			}
			hdSourceService
				.getSource(scope.group.source_id, 'CHART', scope.vm.data.chartId)
				.then(function (response) {
					scope.group.grammar = response.data.grammar;
					scope.group.dataviewData = {
						auto_execute: true,
						hide_operations: true,
						hdType: 'CHART',
						hdId: scope.vm.data.chartId,
					};
					scope.group.dataviewData.afterDataLoaded = function (data, columns) {
						scope.group.columns = columns;
						let formattedData = formatDataForChartDataSource(
							data,
							columns,
							scope.$root.getDefaultTimezone()
						);
						initializeChartConfig(
							scope.config,
							$filter,
							scope.$root.getDefaultTimezone()
						);
						loadGroupDataInChart(formattedData);
					};
					initPopover();
					scope.group.dataviewData.jobDetails = scope.jobDetails;
					scope.group.dataviewData.dataview = {
						source_id: scope.group.source_id,
						grammar: scope.group.grammar,
						lib: scope.group.lib,
						centering: scope.vm.data.centering,
						ignore_cache_on_init: ignoreCache,
					};
				});
		}

		function initializeGroupWithFullData(
			scope,
			hdSourceService,
			loadGroupDataInChart,
			ignoreCache,
			$filter
		) {
			scope.group.grammar = scope.vm.data.full_data.sources[0].grammar;
			scope.group.dataviewData = {
				full_data: scope.vm.data.full_data.sources[0],
				auto_execute: true,
				hide_operations: true,
				hdType: 'CHART',
				hdId: scope.vm.data.chartId,
			};
			scope.group.dataviewData.afterDataLoaded = function (data, columns) {
				scope.group.columns = columns;
				let formattedData = formatDataForChartDataSource(
					data,
					columns,
					scope.$root.getDefaultTimezone()
				);
				initializeChartConfig(
					scope.config,
					$filter,
					scope.$root.getDefaultTimezone()
				);
				loadGroupDataInChart(formattedData);
			};
			scope.group.dataviewData.jobDetails = scope.jobDetails;
			scope.group.dataviewData.dataview = {
				source_id: scope.group.source_id,
				grammar: scope.group.grammar,
				lib: scope.group.lib,
				centering: scope.vm.data.centering,
				ignore_cache_on_init: ignoreCache,
			};
		}

		function formatDataForChartDataSource(data, columns, defaultTZ) {
			return _.map(data, function (item) {
				var newItem = {};
				for (var c in columns) {
					if (columns[c].type == 'date') {
						const date = new Date(item[columns[c].uuid].timestamp);
						newItem[columns[c].uuid] =
							defaultTZ === 'UTC'
								? new Date(
										date.getUTCFullYear(),
										date.getUTCMonth(),
										date.getUTCDate(),
										date.getUTCHours(),
										date.getUTCMinutes(),
										date.getUTCMilliseconds()
								  )
								: date;
					} else {
						newItem[columns[c].uuid] = item[columns[c].uuid].val;
					}
				}
				return newItem;
			});
		}

		function initializeChartConfig(config, $filter, defaultTZ) {
			config.global = keysToCamel(config.global);

			if (config.global.palette) {
				config.global.palette = config.global.palette.replace('_', ' ');
			}

			if (config.global.export) {
				config.global.export = {
					enabled: true,
					fileName: $filter('date')(new Date(), 'yyyy_MM_dd'),
				};
				if (config.global.exportFileName) {
					config.global.export.fileName =
						config.global.exportFileName + '_' + config.global.export.fileName;
				}
			}

			if (config.global.zoomMode != 'NONE') {
				let dragToZoom = config.global.zoomMode == 'COMPLETE';
				let valueAxis = config.global.zoomMode == 'COMPLETE' ? 'both' : 'pan';
				config.global.zoomAndPan = {
					argumentAxis: 'both',
					valueAxis: valueAxis,
					dragToZoom: dragToZoom,
					allowMouseWheel: true,
					panKey: 'shift',
				};
				config.global.zoomingMode = 'all';
				delete config.global.zoom;
			}

			if (config.global.scroll) {
				config.global.scrollBar = { visible: true };
				config.global.scrollingMode = 'all';
				delete config.global.scroll;
			}

			// format dates in default tz
			if (
				config.global.argumentAxis &&
				config.global.argumentAxis.argumentType === 'date' &&
				config.global.argumentAxis.label
			) {
				if (defaultTZ !== 'UTC') {
					config.global.argumentAxis.label.customizeText = function (e) {
						return ChartService.getFormattedDxChartDateToTZ(
							e.value,
							config.global.argumentAxis.label.format,
							defaultTZ,
							true,
							$filter
						);
					};
				}
				if (
					config.global.argumentAxis.label &&
					!config.global.argumentAxis.label.format
				) {
					delete config.global.argumentAxis.label;
				}
			}
		}
	}
})();
