(function () {
	'use strict';

	angular.module('dcApp').directive('frameData', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'toaster',
				'$sce',
				'TypeEntiteService',
				function ($scope, toaster, $sce, TypeEntiteService) {
					var vm = this;
					$scope.init = function () {
						TypeEntiteService.getEntiteTypeGrammar(vm.data.id).then(function (
							response
						) {
							if (response.data) {
								$scope.datablockData = {};
								$scope.dbW = buildDataBlock(
									{ grammar: response.data },
									'',
									undefined
								);
							}
						});
					};
					vm.closeModal = function () {
						delete $scope.datablockData;
						delete $scope.dbW;
						vm.data.showFrameData = false;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/frameData/frameData.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showFrameData;
						},
						function () {
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
