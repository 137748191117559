(function () {
	'use strict';

	angular.module('dcApp').directive('exportDatablock', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'toaster',
				'gettextCatalog',
				function ($scope, $rootScope, toaster, gettextCatalog) {
					/*export*/
					$scope.checkPermissionData = {};
					$scope.sep = ';';

					// event listeners
					$scope.$on(
						'exportJobFinished:success:to-ajs',
						function (event, notif) {
							if (
								notif.data.status === 'SUCCESS' ||
								notif.data.status === 'CACHED'
							) {
								if (
									notif.data.full_result &&
									notif.data.full_result.length > 0
								) {
									$scope.widgetData.filesToExport = notif.data.full_result;
									$scope.widgetData.showDownloadFiles = true;
									$scope.cancel();
								} else {
									let message =
										notif.data.result && notif.data.result.length > 0
											? gettextCatalog.getString('Export réalisé dans') +
											  ' : ' +
											  notif.data.result
											: gettextCatalog.getString('Export réalisé') + ' ';
									toaster.pop(
										'success',
										gettextCatalog.getString('Succès'),
										message
									);
									$scope.cancel();
								}
								$scope.loadingExportData = false;
							} else if (notif.data.status === 'ERROR') {
								toaster.pop(
									'Error',
									errorTra,
									gettextCatalog.getString(
										"Erreur lors de l'export des données"
									)
								);
								$scope.loadingExportData = false;
								$scope.cancel();
							}
						}
					);

					$scope.widgetData = {};
					let errorTra = gettextCatalog.getString('Erreur');

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$scope.loadingExportData = false;
						$scope.checkPermissionData = {};
						$('#' + $scope.modalId).modal('hide');
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/exportDatablock/exportDatablock.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showExportDatablock = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data ? scope.vm.data.showExportDatablock : false;
						},
						function () {
							scope.showExportModal();
						},
						true
					);
				}
			);
		},
	]);
})();
