(function () {
	'use strict';

	angular.module('dcApp').service('GrammarUtils', [
		'FIELD_FUNCTION_OPERATION',
		function (FIELD_FUNCTION_OPERATION) {
			this.isFunctionAllowedForType = function (fn, colType) {
				if (fn.code === 'CONCAT' && colType !== 'string') {
					return false;
				}
				if (colType === 'string' || colType === 'file') {
					return (
						fn.code === 'CONCAT' ||
						fn.code === 'COUNT_DISTINCT' ||
						fn.code === 'COUNT_DISTINCT_APPROX' ||
						fn.code === 'COUNT' ||
						fn.code === 'COLLECT_LIST' ||
						fn.code === 'COLLECT_SET' ||
						fn.code === 'LEAD' ||
						fn.code === 'LAG' ||
						fn.code === 'FIRST' ||
						fn.code === 'LAST' ||
						fn.code === 'FIRST_VALUE' ||
						fn.code === 'LAST_VALUE'
					);
				}
				if (colType === 'boolean') {
					return (
						fn.code === 'COUNT_DISTINCT' ||
						fn.code === 'COUNT_DISTINCT_APPROX' ||
						fn.code === 'COUNT' ||
						fn.code === 'COLLECT_LIST' ||
						fn.code === 'COLLECT_SET'
					);
				}
				if (colType === 'words') {
					return (
						fn.code === 'COUNT_DISTINCT' ||
						fn.code === 'COUNT_DISTINCT_APPROX' ||
						fn.code === 'COUNT' ||
						fn.code === 'COLLECT_LIST' ||
						fn.code === 'COLLECT_SET'
					);
				}
				if (colType === 'time' || colType === 'date') {
					return (
						fn.code === 'MAX' ||
						fn.code === 'MIN' ||
						fn.code === 'COUNT_DISTINCT' ||
						fn.code === 'COUNT_DISTINCT_APPROX' ||
						fn.code === 'COUNT' ||
						fn.code === 'COLLECT_LIST' ||
						fn.code === 'COLLECT_SET' ||
						fn.code === 'LEAD' ||
						fn.code === 'LAG' ||
						fn.code === 'FIRST' ||
						fn.code === 'LAST' ||
						fn.code === 'FIRST_VALUE' ||
						fn.code === 'LAST_VALUE'
					);
				}

				return (
					colType === 'integer' ||
					colType === 'double' ||
					colType === 'decimal' ||
					colType === 'big_integer'
				);
			};

			this.isFunctionAllowedForAggregationType = function (
				fn,
				aggregationType
			) {
				const countDistinctFnRule =
					fn.code === FIELD_FUNCTION_OPERATION.distinctCount &&
					(Number(aggregationType) === 1 || Number(aggregationType) === 0);
				const otherFnRule =
					(fn.code === FIELD_FUNCTION_OPERATION.lead ||
						fn.code === FIELD_FUNCTION_OPERATION.lag ||
						fn.code === FIELD_FUNCTION_OPERATION.first ||
						fn.code === FIELD_FUNCTION_OPERATION.last ||
						fn.code === FIELD_FUNCTION_OPERATION.firstValue ||
						fn.code === FIELD_FUNCTION_OPERATION.lastValue) &&
					Number(aggregationType) !== 1;
				return !countDistinctFnRule && !otherFnRule;
			};
		},
	]);
})();
