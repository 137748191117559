(function () {
	'use strict';

	angular.module('DcEntityModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('entity-parent', {
			url: '/entity',
			parent: 'target-project',
			abstract: true,
		});

		// FIXME: rename name state
		$stateProvider.state('entites', {
			url: '/',
			parent: 'entity-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Entités Métier',
			},
			views: {
				'@entity-parent': {
					templateUrl: './src/components/modules/entites/entites.html',
					controller: 'EntiteListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('entites-new', {
			url: '/new',
			parent: 'entity-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Nouvelle Entité Métier',
			},
			params: { fromGraphe: false },
			views: {
				'@entity-parent': {
					templateUrl: './src/components/modules/entites/entiteForm.html',
					controller: 'EntiteController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('entites-edit', {
			url: '/edit/:entiteId',
			parent: 'entity-parent',
			data: {
				admin: true,
				access: 'member',
				title: "Edition d'Entité Métier",
			},
			params: { fromGraphe: false }, // FIXME: pourquoi ?
			views: {
				'@entity-parent': {
					templateUrl: './src/components/modules/entites/entiteForm.html',
					controller: 'EntiteController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('entites-duplicate', {
			url: '/duplicate/:duplicatedId',
			parent: 'entity-parent',
			data: {
				admin: true,
				access: 'member',
				title: "Dupliquer l'Entité Métier",
			},
			params: { fromGraphe: false }, // FIXME: pourquoi ?
			views: {
				'@entity-parent': {
					templateUrl: './src/components/modules/entites/entiteForm.html',
					controller: 'EntiteController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
