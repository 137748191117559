(function () {
	'use strict';

	angular.module('dcApp').factory('GroupAdapter', [
		function () {
			const GroupAdapter = {};

			GroupAdapter.generateGroup = function (response) {
				return generateGroup(response);
			};

			GroupAdapter.generateGroupList = function (data) {
				return data.map(function (groupData) {
					return generateGroup(groupData);
				});
			};

			function generateGroup(data) {
				return {
					id: data.id,
					description: data.description,
					has_member_role: data.has_member_role.own,
					has_project_role: data.has_project_role.own,
					has_admin_role: data.has_admin_role.own,
					label: data.label,
					name: data.name,
				};
			}

			return GroupAdapter;
		},
	]);
})();
