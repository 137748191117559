(function () {
	'use strict';

	angular.module('dcApp').directive('dhSource', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'hdSourceService',
				'toaster',
				'API_BASE_URL_BACKEND',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'DataBlocksService',
				'$q',
				'frameService',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					$state,
					hdSourceService,
					toaster,
					API_BASE_URL_BACKEND,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					DataBlocksService,
					$q,
					frameService,
					gettextCatalog
				) {
					var vm = this;
					let _sourceDataGridOptions;
					let _dataBlockGridOptions;
					let successTra = gettextCatalog.getString('Succès');
					let updateSuccessTra = gettextCatalog.getString(
						'Modification effectuée'
					);
					let libTra = gettextCatalog.getString('Libellé');
					let descTra = gettextCatalog.getString('Description');
					let actionsTra = gettextCatalog.getString('Actions');
					let sourceDbTra = gettextCatalog.getString('source.datablock.label');

					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.accessToken = window._keycloak.token;

					$scope.sources = [];
					$scope.dataviewData = {};
					$scope.iconsSelector = {};
					$scope.sourceTypeTitle = {};
					$scope.showCurrentProjectOnly = true;

					let thisTra = gettextCatalog.getString('cette');
					let thisMTra = gettextCatalog.getString('ce');

					vm.init = function () {
						delete $scope.dataviewData.dataview;
						delete $scope.selectedSource;
						$scope.origineDatablock = {};
						$scope.dataviewData.hdType = vm.data.hdType;
						$scope.dataviewData.hdId = vm.data.hdId;
						$scope.dataviewData.enableCount = true;
						$scope.dataviewData.loadGrid = true;
						let typeArticle;
						let typeElement;
						switch ($scope.dataviewData.hdType) {
							case 'MAP':
								typeElement = gettextCatalog.getString('Carte');
								typeArticle = thisTra;
								break;
							case 'CHART':
								typeElement = gettextCatalog.getString('Graphique');
								typeArticle = thisMTra;
								break;
							case 'TIMELINE':
								typeElement = gettextCatalog.getString('TimeLine');
								typeArticle = thisTra;
								break;
							case 'GRID':
								typeElement = gettextCatalog.getString('Tableau');
								typeArticle = thisMTra;
								break;
							case 'MEDIA':
								typeElement = gettextCatalog.getString('Média');
								typeArticle = thisMTra;
								break;
							default:
								break;
						}
						$scope.sourceTypeTitle.addSource =
							gettextCatalog.getString('Ajouter une Source à') +
							' ' +
							typeArticle +
							' ' +
							typeElement;
						$scope.sourceTypeTitle.refresh =
							gettextCatalog.getString('Refresh Pictograms list') +
							' ' +
							typeArticle +
							' ' +
							typeElement;
						$scope.sourceTypeTitle.editAction =
							gettextCatalog.getString('Editer la source de') +
							' ' +
							typeArticle +
							' ' +
							typeElement;
						$scope.sourceTypeTitle.deleteAction =
							gettextCatalog.getString('Supprimer la source de') +
							' ' +
							typeArticle +
							' ' +
							typeElement;
						initSources();

						var lookupTagDataSource = {
							store: new DevExpress.data.CustomStore({
								loadMode: 'raw',
								load: function () {
									return getAvailableTags().then(function (response) {
										return response;
									});
								},
							}),
						};

						_dataBlockGridOptions = {
							paging: {
								enabled: true,
								pageSize: PAGINATIONS_SIZE,
							},
							pager: {
								showPageSizeSelector: true,
								allowedPageSizes: PAGINATIONS_SIZES,
								showInfo: true,
								visible: true,
							},
							filterRow: {
								visible: true,
								applyFilter: 'auto',
							},
							selection: {
								mode: vm.data.unableMultiSource ? 'single' : 'multiple',
								showCheckBoxesMode: 'always',
							},
							rowAlternationEnabled: true,
							headerFilter: {
								visible: true,
								applyFilter: 'auto',
							},
							onRowDblClick: function (item) {
								saveSelectedSources([item.data]);
							},
							showColumnLines: true,
							showRowLines: true,
							allowColumnReordering: true,
							allowColumnResizing: true,
							columnAutoWidth: true,
							showBorders: true,
							columnFixing: {
								enabled: true,
							},
							columns: [
								{
									caption: libTra,
									dataField: 'lc',
									width: '35.8%',
									allowReordering: false,
								},
								{
									dataField: 'tags',
									caption: 'Tags',
									cellTemplate: 'tagTemplate',
									allowHeaderFiltering: true,
									allowFiltering: false,
									enableCellEdit: false,
									width: '32%',
									lookup: {
										dataSource: lookupTagDataSource,
									},
									calculateFilterExpression: function (
										filterValue,
										selectedFilterOperation
									) {
										if (!filterValue) return;
										return function (rowData) {
											return _.indexOf(rowData.tagAsArray, filterValue) != -1;
										};
									},
								},
								{
									caption: descTra,
									dataField: 'desc',
									width: '29%',
								},
								{
									dataField: 'updateDate',
									caption: 'Date de mise à jour',
									sortOrder: 'desc',
								},
								{ dataField: 'updatedBy', caption: 'Dernier modificateur' },
							],
							onInitialized: function (e) {
								$scope.gridDataBlockInstance = e.component;
							},
							onSelectionChanged: function (selectedItems) {
								$scope.selectedDataBlocks = selectedItems.selectedRowsData;
							},
						};

						_sourceDataGridOptions = getDefaultDxGridConfig(
							PAGINATIONS_SIZE,
							PAGINATIONS_SIZES
						);
						_sourceDataGridOptions.columns = [
							{
								caption: libTra,
								cellTemplate: 'iconAndLib',
								dataField: 'lc',
								sortOrder: 'asc',
								allowReordering: false,
							},
							{
								caption: descTra,
								dataField: 'desc',
								width: '35.8%',
							},
							{
								caption: sourceDbTra,
								dataField: 'hdSourceDataBlock',
								cellTemplate: 'dataBlockSourceLib',
								width: '35.8%',
							},
							{
								caption: actionsTra,
								alignment: 'center',
								cellTemplate: 'actions',
								width: '200px',
								allowReordering: false,
							},
						];

						$scope.tagBox = {
							displayExpr: 'code',
							valueExpr: 'id',
							searchEnabled: false,
							editEnabled: false,
							tagTemplate: 'tagTemplate',
						};
					};

					$scope.getTagBox = function (list) {
						$scope.tagBox.value = list.data.tags;

						return $scope.tagBox;
					};

					$scope.addNewSources = function () {
						saveSelectedSources($scope.selectedDataBlocks);
					};

					var initSources = function () {
						delete $scope.sourceDataGridOptions;
						hdSourceService
							.getSourcesByElement(vm.data.hdType, vm.data.hdId)
							.then(function (response) {
								// add index
								var i = 0;
								for (var source in response.data) {
									response.data[source]['idx'] = i;
									response.data[source].hd_source_data_block.hdSourceDataBlockHref = $state.href('datablocks-edit', {
										id: response.data[source].hd_source_data_block.id,
										projectId: response.data[source].hd_source_data_block.project_id,
									});
									i++;
								}
								$scope.sources = response.data;
								if (vm.data.onLoadSource) {
									vm.data.onLoadSource(!$scope.sources[0]);
								}
								fillSourceDataGrid();
							});
					};

					var fillSourceDataGrid = function () {
						$scope.sourceDataGridOptions = angular.copy(_sourceDataGridOptions);
						$scope.sourceDataGridOptions.dataSource = $scope.sources;
					};

					$scope.openSource = function (source) {
						$scope.getOriginieDatablockByid(source.data_block_id);
						hdSourceService
							.getSource(source.id, vm.data.hdType, vm.data.hdId)
							.then(function (response) {
								setSourceDataView(response.data);
								delete $scope.sourceDataGridOptions;
								if (vm.data.onOpenSource) {
									vm.data.onOpenSource();
								}
							});
					};

					$scope.reloadDatablocks = function () {
						fillDatablockGrid();
					};

					$scope.addSource = function () {
						$scope.showCurrentProjectOnly = true;
						fillDatablockGrid();
						if (vm.data.onOpenSource) {
							vm.data.onOpenSource();
						}
					};

					$scope.deleteSource = function (source) {
						hdSourceService
							.deleteSource(source.id, vm.data.hdType, vm.data.hdId)
							.then(function (response) {
								toaster.pop(
									'success',
									successTra,
									gettextCatalog.getString('Suppression effectuée')
								);
								initSources();
							});
					};

					$scope.activateSource = function (source) {
						hdSourceService
							.activateSource(
								source.id,
								source.active,
								vm.data.hdType,
								vm.data.hdId
							)
							.then(function (response) {
								toaster.pop('success', successTra, updateSuccessTra);
							});
					};

					var saveSelectedSources = function (selectedSources) {
						var newSources = [];
						for (var s in selectedSources) {
							newSources.push({ data_block_id: selectedSources[s].id });
						}

						hdSourceService
							.addSources(newSources, vm.data.hdType, vm.data.hdId)
							.then(function (response) {
								toaster.pop(
									'success',
									successTra,
									gettextCatalog.getString('Enregistrement effectué')
								);
								delete $scope.dataBlockGridOptions;
								if (vm.data.onOpenSource) {
									vm.data.onOpenSource();
								}
								initSources();
							});
					};

					$scope.save = function () {
						$scope.selectedSource.grammar = $scope.dataviewData.getGrammar();
						var source = {
							grammar: $scope.dataviewData.getGrammar(),
							label: $scope.selectedSource.label,
							desc: $scope.selectedSource.desc,
							icon_id: $scope.selectedSource.icon_id,
						};
						hdSourceService
							.editSource(
								$scope.selectedSource.id,
								source,
								vm.data.hdType,
								vm.data.hdId
							)
							.then(function (response) {
								toaster.pop('success', successTra, updateSuccessTra);
							});
					};

					$scope.returnToList = function () {
						initSources();
						delete $scope.dataviewData.dataview;
						delete $scope.selectedSource;
						delete $scope.dataBlockGridOptions;
						if (vm.data.onOpenSource) {
							vm.data.onOpenSource();
						}
					};

					var setSourceDataView = function (source) {
						if (!source.grammar.steps[1]) {
							source.grammar.steps.push({ alias: '2' });
							source.grammar.steps.push({ alias: '3' });
						}
						$scope.dataviewData.auto_execute = false;
						if (!source.source_id) {
							source.source_id = source.id;
						}
						$scope.selectedSource = source;
						if ($scope.dataviewData.dataview) {
							$scope.dataviewData.reinit(source);
						} else {
							$scope.dataviewData.dataview = source;
						}
					};

					$scope.selectIcon = function () {
						$scope.iconsSelector.saveSelection = function (icon) {
							$scope.selectedSource.icon_id = icon;
						};
						$scope.iconsSelector.showIconsSelector = true;
					};

					$scope.getOriginieDatablockByid = function (id) {
						DataBlocksService.getDataBlock(id).then(function (content) {
							$scope.origineDatablock.id = content.data.id;
							$scope.origineDatablock.creationDate =
								$rootScope.getDateTimeWithPattern(
									content.data.metadata.creation_date
								);
							$scope.origineDatablock.updateDate =
								$rootScope.getDateTimeWithPattern(
									content.data.metadata.update_date
								);
							$scope.origineDatablock.lib = content.data.metadata.label;

							frameService
								.getStatus(content.data.metadata.code)
								.then(function (response) {
									const data = response.data;
									if (data.cached == true && data.persisted == false) {
										$scope.origineDatablock.status = gettextCatalog.getString("source.status.cached");
									} else if (data.cached == false && data.persisted == true) {
										$scope.origineDatablock.status = gettextCatalog.getString("source.status.persisted");
									} else if (data.cached == true && data.persisted == true) {
										$scope.origineDatablock.status = gettextCatalog.getString("source.status.persisted.cached");
									} else {
										$scope.origineDatablock.status = gettextCatalog.getString("source.status.no.cached.persisted");
									}
								})
								.catch(function (error) {
									// FIXME: error.data.code ????
									if (
										error &&
										error.data &&
										(error.data.code == '37' ||
											error.data.code == '38' ||
											error.data.code == '39')
									) {
										$rootScope.disableAjaxLoading = false;
									}
								});
						});
					};

					function getAvailableTags() {
						return new Promise(function (resolve) {
							setTimeout(function () {
								resolve(
									_.map($scope.tags, function (el) {
										return el.label;
									})
								);
							}, 50);
						});
					}

					function getDataBlocks() {
						var deferred = $q.defer();
						const excludeInactive = true;
						DataBlocksService.getDatablocks($scope.showCurrentProjectOnly, excludeInactive).then(
							function (response) {
								deferred.resolve(response.data);
							}
						);
						return deferred.promise;
					}

					function fillDatablockGrid() {
						delete $scope.dataBlockGridOptions;
						getDataBlocks().then(function (content) {
							if (content !== undefined) {
								var datablocks = [];
								$scope.tags = [];
								var tags = [];
								var i = 0;
								for (var r in content) {
									datablocks.push({
										idx: i,
										id: content[r].id,
										code: content[r].metadata.code,
										lc: content[r].metadata.label,
										desc: content[r].metadata.description,
										updateDate: $rootScope.getDateTimeWithPattern(
											content[r].metadata.update_date
										),
										updatedBy: content[r].metadata.updated_by,
										tags: content[r].metadata.tags,
										tagAsArray: $rootScope.mapTags( content[r].metadata.tags),
									});
									i++;
									tags = _.union(tags, content[r].metadata.tags);
								}
								for (var i in tags) {
									if (_.find($scope.tags, { id: tags[i].id }) == null) {
										$scope.tags.push({ id: tags[i].id, label: tags[i].code });
									}
								}

								$scope.dataBlockGridOptions = angular.copy(
									_dataBlockGridOptions
								);
								$scope.dataBlockGridOptions.dataSource = datablocks;
							}
						});
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/dhSource/dhSource.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$ctrl.data.reinit = $ctrl.init;
					$ctrl.init();
				},
			};
		},
	]);
})();
