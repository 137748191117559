(function () {
	angular.module('dcApp').factory('UrlParamService', [
		function () {
			var UrlParamService = {};
			// Replace + & with encoding value (to be used in url params)
			UrlParamService.escapeCharsForEncoding = function (param) {
				return param ? param.replace('&', '%26').replace('+', '%2B') : param;
			};

			return UrlParamService;
		},
	]);
})();
