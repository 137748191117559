(function () {
	'use strict';

	angular.module('dcApp').factory('ngCommService', NgCommService);

	NgCommService.$inject = ['$log', 'ModalService', '$q', 'toaster'];

	function NgCommService($log, ModalService, $q, toaster) {
		return {
			openPathExplorerModal: openPathExplorerModal,
			notifyOnError: notifyOnError,
			notifyOnWarning: notifyOnWarning,
			notifyOnSuccess: notifyOnSuccess,
		};


		function openPathExplorerModal(options) {
			const deferred = $q.defer();
			ModalService.open({
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/modules/connector/remote-file-explorer/remote-file-explorer.html',
				controller: 'RemoteFilesExplorerController',
				disableBackdrop: true,
				locals: options,
			}).then(function (path){
				deferred.resolve(path);
			}, function (err) {
				deferred.resolve(undefined);
				console.error(err);
			});
			return deferred.promise;
		}

		function notifyOnSuccess(str) {
			toaster.pop('success', str);
		}

		function notifyOnWarning(str) {
			toaster.pop('warning', str);
		}

		function notifyOnError(str) {
			toaster.pop('error', str);
		}
	}
})();
