(function () {
	angular.module('dcApp').factory('ContextServices', [
		'$http',
		'UrlService',
		function ($http, UrlService) {
			const ContextServices = {};

			ContextServices.getContextList = function (all) {
				const url = UrlService.generateUrl('SPARK_CONTEXTS', {}, { all: all });

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return ContextServices;
		},
	]);
})();
