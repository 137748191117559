(function () {
	'use strict';

	angular.module('dcApp').controller('NewGridController', [
		'$scope',
		'$state',
		function ($scope, $state) {
			$scope.selectGridType = function (type) {
				switch (type) {
					case 'pivot':
						$state.go('grids-new', { type: 'pivot' });
						break;
					case 'simple':
						$state.go('grids-new', { type: 'simple' });
						break;
				}
			};

			$scope.returnToList = function () {
				$state.go('grids', {});
			};
		},
	]);
})();
