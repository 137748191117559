(function () {
	'use strict';
	angular.module('dcApp').factory('DatablockLinkService', [
		'DatablockLinkMappingEntity',
		'LINK_TYPES',
		'LINK_MAPPING_OPERATOR',
		'gettextCatalog',
		function (
			DatablockLinkMappingEntity,
			LINK_TYPES,
			LINK_MAPPING_OPERATOR,
			gettextCatalog
		) {
			return {
				generateMappingOperators: getAvailableOperators,
				generateLinkTypes: getAvailableLinkTypes,
			};

			function getAvailableLinkTypes() {
				return [
					{
						code: LINK_TYPES.Inner,
						label: 'Inner',
					},
					{
						code: LINK_TYPES.Outer,
						label: 'Outer',
					},
					{
						code: LINK_TYPES.Left,
						label: 'Left',
					},
					{
						code: LINK_TYPES.Right,
						label: 'Right',
					},
					{
						code: LINK_TYPES.Cross,
						label: 'Cross',
					},
				];
			}
			function getAvailableOperators() {
				return [
					{
						value: LINK_MAPPING_OPERATOR.Equal,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Egale'),
						apply_to: [
							'string',
							'integer',
							'date',
							'big_integer',
							'decimal',
							'boolean',
							'file',
						],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.NotEqual,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Différent'),
						apply_to: [
							'string',
							'integer',
							'date',
							'big_integer',
							'decimal',
							'boolean',
							'file',
						],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.Less,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Inférieur'),
						apply_to: ['integer', 'date', 'big_integer', 'decimal'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.LessOrEqual,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Inférieur ou égale'),
						apply_to: ['integer', 'date', 'big_integer', 'decimal'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.Greater,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Supérieur'),
						apply_to: ['integer', 'date', 'big_integer', 'decimal'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.GreaterOrEqual,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Supérieur ou égale'),
						apply_to: ['integer', 'date', 'big_integer', 'decimal'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.BeginsWith,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Commence par'),
						apply_to: ['string', 'file'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.NotBeginWith,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Ne commence pas par'),
						apply_to: ['string', 'file'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.Contains,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Contient'),
						apply_to: ['string', 'file'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.NotContains,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Ne contient pas'),
						apply_to: ['string', 'file'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.EndsWith,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Termine par'),
						apply_to: ['string', 'file'],
						list: false,
					},
					{
						value: LINK_MAPPING_OPERATOR.NotEndWith,
						nb_inputs: 1,
						lib: gettextCatalog.getString('Ne se termine pas par'),
						apply_to: ['string', 'file'],
						list: false,
					},
				];
			}
		},
	]);
})();
