(function () {
	'use strict';

	angular.module('dcApp').directive('gaugebarchartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;
					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'gauge_bar',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope(
						$scope,
						$state,
						vm,
						$window,
						'gauge_bar'
					);

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					var loadGroupDataInChart = function (data) {
						initPopover();
						if (data[0]) {
							$scope.config.global.startValue =
								data[0][$scope.config.global.startValue];
							$scope.config.global.endValue =
								data[0][$scope.config.global.endValue];
							var values = [];
							var palette = [];
							for (var v in $scope.config.global.values) {
								values.push(data[0][$scope.config.global.values[v].value]);

								if (
									$scope.config.global.values[v].colorColumn &&
									data[0][$scope.config.global.values[v].colorColumn]
								) {
									palette.push(
										data[0][$scope.config.global.values[v].colorColumn]
									);
								} else if ($scope.config.global.values[v].color) {
									palette.push($scope.config.global.values[v].color);
								}
							}
							$scope.config.global.values = values;
							if (palette[0]) {
								$scope.config.global.palette = palette;
							}
						}
						$scope.config.global.tooltip = { enabled: true };
						$scope.dxChartOptions = $scope.config.global;
						$('#chart' + $scope.uuid).dxBarGauge($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxBarGauge('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxBarGauge('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/gauge_bar/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
