(function () {
	'use strict';

	angular.module('dcApp').factory('MonitoringServices', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var MonitoringServices = {};

			MonitoringServices.getFsMemoryStats = function (ctx, level) {
				level = level !== undefined ? level : 0;
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('MONITORING_CONTEXT_FS') +
					'?level=' +
					level;
				url = url.replace('{ctx}', ctx);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MonitoringServices.freeDisk = function (ctx, clearExportedData) {
				clearExportedData = !!clearExportedData;
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('MONITORING_FREE_CONTEXT_DISK') +
					'?clearExportedData=' +
					clearExportedData;
				url = url.replace('{ctx}', ctx);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			return MonitoringServices;
		},
	]);
})();
