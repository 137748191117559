(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller('RemoteFilesExplorerController', RemoteFilesExplorerController);

	RemoteFilesExplorerController.$inject = [
		'$log',
		'$timeout',
		'ConnectorService',
		'toaster',
		'gettextCatalog',
	];
	function RemoteFilesExplorerController(
		$log,
		$timeout,
		ConnectorService,
		toaster,
		gettextCatalog
	) {
		const vm = this;
		const provider = new DevExpress.fileManagement.CustomFileSystemProvider({
			getItems: function (pathInfo) {
				return vm.connector ? getItems(pathInfo) : getItemsById(pathInfo);
			},
		});

		vm.fileManagerOptions = {
			name: 'fileManager',
			fileSystemProvider: provider,
			currentPath: '/',
			permissions: {
				create: false,
				copy: false,
				move: false,
				delete: false,
				rename: false,
				upload: false,
				download: false,
			},
			onInitialized: function (e) {
				vm.gridInstance = e.component;
			},
			onSelectionChanged: function (elm) {
				if (elm && elm.selectedItemKeys && elm.selectedItemKeys.length > 0)
					vm.fullUrl = elm.selectedItems[0].isDirectory
						? '/' + elm.selectedItemKeys[0]
						: null;
			},
		};

		function getItemsById(pathInfo) {
			let path = pathInfo.relativeName ? '/' + pathInfo.relativeName : '/';
			return ConnectorService.listRemoteFilesById(vm.connectorId, path, vm.type)
				.then(function (resp) {
					return resp.data.map((p) => {
						return {
							name: p.display_name,
							isDirectory: p.is_directory,
							size: p.size,
							hasSubDirectories: p.has_sub_directories,
							dateModified: p.creation_date,
						};
					});
				})
				.catch(function (e) {
					if (e.message === 'type.non.supported') {
						toaster.pop(
							'error',
							gettextCatalog.getString('Erreur'),
							gettextCatalog.getString('type.non.supported')
						);
					}
					$log.error(e);
				});
		}

		function getItems(pathInfo) {
			let path = pathInfo.relativeName ? '/' + pathInfo.relativeName : '/';
			return ConnectorService.listRemoteFiles(vm.connector, path, vm.type)
				.then(function (resp) {
					return resp.data.map((p) => {
						return {
							name: p.display_name,
							isDirectory: p.is_directory,
							size: p.size,
							hasSubDirectories: p.has_sub_directories,
							dateModified: p.creation_date,
						};
					});
				})
				.catch(function (e) {
					if (e.message === 'type.non.supported') {
						toaster.pop(
							'error',
							gettextCatalog.getString('Erreur'),
							gettextCatalog.getString('type.non.supported')
						);
					}
					$log.error(e);
				});
		}

		function init() {
			$log.info(vm.connectorId);
			$log.info(vm.connector);
			$log.info(vm.type);
		}

		init();
	}
})();
