(function () {
	'use strict';
	angular.module('dcApp').controller('DataCatalogueController', [
		'$log',
		'$rootScope',
		'$scope',
		'DataCatalogService',
		'PermissionService',
		'gettextCatalog',
		function (
			$log,
			$rootScope,
			$scope,
			DataCatalogService,
			PermissionService,
			gettextCatalog
		) {
			const vm = this;
			var gridInstance;
			var lookupTags;

			vm.search = search;
			vm.showGraph = showGraph;
			vm.showData = showData;
			vm.showElementDetails = showElementDetails;
			vm.openSendMessage = sendMessage;
			vm.searchValue = '';
			vm.elementGraphData = {};
			vm.dataPreviewConf = {};
			vm.elementData = {};
			vm.message = { sendMessageDialog: false };
			vm.dataGridOptions = {
				dataSource: [],
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: false,
				columnAutoWidth: true,
				showBorders: true,
				noDataText: gettextCatalog.getString(
					'Aucun élément n’est référencé au DataCatalogue'
				),
				rowAlternationEnabled: true,
				scrolling: {
					mode: 'infinite',
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				filterPanel: {
					visible: true,
				},
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				columns: [
					{
						caption: gettextCatalog.getString("Type d'élément"),
						dataField: 'type',
						width: '10%',
						cellTemplate: 'typeTemplate',
						alignment: 'center',
					},
					{
						caption: gettextCatalog.getString('Libellé'),
						dataField: 'label',
						cellTemplate: 'label',
						width: '20%',
					},
					{
						dataField: 'tags',
						caption: gettextCatalog.getString('Tags'),
						cellTemplate: 'tagTemplate',
						headerFilter: {
							dataSource: lookupTags,
						},
						width: '20%',
						calculateFilterExpression: function (filterValue) {
							if (!filterValue) {
								return ['tagAsArray', '=', null];
							}

							return ['tagAsArray', 'contains', filterValue];
						},
					},
					{
						dataField: 'project_label',
						caption: gettextCatalog.getString('Projet'),
						width: '10%',
					},
					{
						dataField: 'description',
						caption: gettextCatalog.getString('Description'),
						width: '25%',
					},
					{
						dataField: 'update_date',
						caption: gettextCatalog.getString('Dernière mise à jour'),
						dataType: 'datetime',
						format: 'dd/MM/yyyy HH:mm:ss',
						sortOrder: 'desc',
					},
					{
						caption: gettextCatalog.getString('Actions'),
						cellTemplate: 'actions',
						width: '10%',
						alignment: 'center',
					},
				],
				onInitialized: function (e) {
					gridInstance = e.component;
				},
				onRowPrepared: function (e, x, y) {
					if (e.rowType === 'data') {
						PermissionService.havePermission(
							e.data.id,
							'view',
							'datablock'
						).then(function (response) {
							e.data.view = response.data;
						});
					}
				},
			};
			vm.searchTags = [];
			vm.tooltip = {
				message: gettextCatalog.getString('datacatalog.search.warning'),
				enabled: true,
			};
			vm.hasNoFilteringTags = true;

			vm.filter = search;
			vm.submit = onKeyPress;
			vm.removeTag = removeSelectedTag;
			vm.removeAll = removeAllTags;
			vm.addOne = addOneTag;

			init();

			function init() {
				search(vm.searchTags);
				initWatchers();
			}

			function addOneTag() {
				const val = vm.searchValue.trim();
				if (!val) {
					return;
				}
				vm.searchTags.push(val);
				vm.searchValue = '';
			}

			function removeAllTags() {
				if (vm.searchTags.length === 0) {
					return;
				}
				vm.searchTags = [];
				search(vm.searchTags);
				search(vm.searchTags);
			}

			function removeSelectedTag(tag) {
				vm.searchTags = vm.searchTags.reduce(function (acc, curr) {
					if (curr === tag) {
						return acc;
					}
					acc.push(curr);
					return acc;
				}, []);
				search(vm.searchTags);
			}

			function onKeyPress($event) {
				if ($event.key === ';') {
					parseSearchedValue($event);
				}
				if ($event.key === 'Enter') {
					if (vm.searchValue) {
						parseSearchedValue($event);
					}
					search(vm.searchTags);
				}
			}

			function search(tags) {
				if (tags.length === 0) {
					$log.warn('searching for all referenced datablocks');
				}
				const searchRequest = {
					keywords: tags,
				};
				DataCatalogService.findByKeywords(searchRequest).then(function (
					response
				) {
					if (gridInstance && gridInstance.option) {
						gridInstance.option('dataSource', response.data);
						let headerFilter = gridInstance.columnOption(
							'tags',
							'headerFilter'
						);
						headerFilter.dataSource = _.map(response.tags, function (el) {
							return { text: el, value: el };
						});
						setTimeout(function () {
							headerFilter.dataSource.unshift({
								text: gettextCatalog.getString('grid.header.filter.blank'),
								value: null,
							});
							gridInstance.columnOption('tags', 'headerFilter', headerFilter);
						}, 400);
					}
				});
			}

			function showGraph(data) {
				vm.elementGraphData.centeredElementLabel = data.label;
				vm.elementGraphData.centeredElementType = data.type;
				vm.elementGraphData.centeredElementId = data.id;
				vm.elementGraphData.axe = 'db';
				vm.elementGraphData.showHdhElementGraph = true;
			}

			function showData(data) {
				vm.dataPreviewConf.elementId = data.id;
				vm.dataPreviewConf.elementLabel = data.label;
				vm.dataPreviewConf.show = true;
			}

			function showElementDetails(elementId) {
				vm.elementData.id = elementId;
				vm.elementData.show = true;
			}

			function sendMessage(elementId) {
				vm.message.sendMessageDialog = true;
				vm.message.elementId = elementId;
			}

			function initWatchers() {
				$scope.$watch(
					function () {
						return vm.searchTags.length;
					},
					function (newVal, oldVal) {
						vm.tooltip.enabled = newVal === 0;
						vm.hasNoFilteringTags = newVal === 0;
					}
				);
			}

			function parseSearchedValue($event) {
				vm.searchTags = $event.target.value
					.trim()
					.split(';')
					.reduce(function (acc, curr) {
						const str = curr.trim();
						if (str.length !== 0) {
							acc.push(str);
						}
						return acc;
					}, vm.searchTags);

				vm.searchValue = '';
			}
		},
	]);
})();
