(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller('ModalTestController', ModalTestController);

	ModalTestController.$inject = ['$log'];
	function ModalTestController($log) {
		const vm = this;
		vm.num = Math.random();
		vm.goFullScreen = false;

		init();

		vm.toggleFullScreen = function () {
			vm.goFullScreen = !vm.goFullScreen;
		};

		function init() {
			$log.debug('loading page controller');
		}
	}
})();
