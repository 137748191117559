(function () {
	'use strict';

	/* Constants */

	angular.module('dcApp').constant('MEDIA_UPLOAD_PDF_MAX_SIZE', 314572800);
	angular.module('dcApp').constant('MEDIA_UPLOAD_IMAGE_MAX_SIZE', 104857600);
	angular.module('dcApp').constant('DX_RERENDER_TIMEOUT', 320);
	angular
		.module('dcApp')
		.constant('LANGUAGES', { FRENCH: 'fr', ENGLISH: 'en' });

	angular.module('dcApp').constant('POSITIONS', [
		{
			value: 1,
			name: 'Gauche',
		},
		{
			value: 3,
			name: 'Centre',
		},
		{
			value: 2,
			name: 'Droite',
		},
	]);

	angular.module('dcApp').constant(
		'DATA_TYPE',
		{
			boolean: 'SIMPLE_TYPES_BOOLEAN_ITEM',
		},
		{
			string: 'SIMPLE_TYPES_STRING_ITEM',
		}
	);

	angular.module('dcApp').constant('DATE_TYPE_CODE', 'SIMPLE_TYPES_DATE_ITEM');
	angular.module('dcApp').constant('GEOMETRY_TYPE_CODE', 'TYPES_GEOMETRY_ITEM');

	angular.module('dcApp').constant('DATE_PATTERN', 'yyyy-MM-dd');
	angular.module('dcApp').constant('DATE_TIME_PATTERN', 'yyyy-MM-dd HH:mm:ss');
	angular.module('dcApp').constant('DATE_TIME_PATTERN_MN', 'dd/MM/yyyy HH:mm');

	angular.module('dcApp').constant('REFRESH_TOKEN_NAME', 'dc_refresh_token');
	angular.module('dcApp').constant('LOGIN_NAME', 'hdh_login');
	angular.module('dcApp').constant('LANG_NAME', 'hdh_lang');
	angular.module('dcApp').constant('USER_ID_NAME', 'dc_user_id');

	angular.module('dcApp').constant('CTX_VAR_NAME', 'ctx');
	angular.module('dcApp').constant('PROJECT_VAR_NAME', 'prt');
	angular.module('dcApp').constant('PROJECT_LABEL_VAR_NAME', 'prt_label');

	angular.module('dcApp').constant('CURRENT_LOCATION_NAME', 'current_location');

	angular.module('dcApp').constant('STORAGE_SUFFIX', 'list-storage'); // FIXME: to remove

	angular.module('dcApp').constant('SELECTED_MENU', 'selected_menu');

	angular.module('dcApp').constant('PAGINATIONS_SIZES', [25, 50, 100]);
	angular.module('dcApp').constant('PAGINATIONS_SIZE', 25);

	angular.module('dcApp').constant('JOBS_CACHE_STATUS', 'jobs_cache_status');

	angular.module('dcApp').constant('NUMBER_PATTERNS', [
		{
			label: '0',
			code: '0',
		},
		{
			label: '0.0',
			code: '0.0',
		},
		{
			label: '0.00',
			code: '0.00',
		},
		{
			label: '0.000',
			code: '0.000',
		},
	]);

	angular.module('dcApp').constant('COUNTING_FUNCTION_OPERATORS', [
		{
			label: '%',
			code: '%',
		},
		{
			label: '/',
			code: '/',
		},
		{
			label: '-',
			code: '-',
		},
		{
			label: '+',
			code: '+',
		},
		{
			label: '*',
			code: '*',
		},
	]);

	angular.module('dcApp').constant('ALIGNEMENTS', [
		{
			value: '0',
			name: '',
		},
		{
			value: 'G',
			name: 'Gauche',
		},
		{
			value: 'C',
			name: 'Centré',
		},
		{
			value: 'D',
			name: 'Droit',
		},
	]);

	angular
		.module('dcApp')
		.constant(
			'DEFAULT_CELL_TEMPLATE',
			'<div class="ngCellText left-space-5 white-space-pre"><span>{{row.entity[col.colDef.name]}}</span></div>'
		);

	angular.module('dcApp').constant('elem', {
		type: {
			repository: 'repository',
			exportTemplate: 'exportTemplate',
			entity: 'entity',
			datablock: 'datablock',
			dashboard: 'dashboard',
			hdElements: 'hdElements',
			connector: 'connector',
			extractRepository: 'extractRepository',
			localData: 'localData',
			noneContext: 'noneContext',
			sameContext: 'sameContext',
		},
	});

	angular.module('dcApp').constant('DECIMAL_PATTERNS', [
		{
			label: '0',
			code: '0',
		},
		{
			label: '0.0',
			code: '1',
		},
		{
			label: '0.00',
			code: '2',
		},
		{
			label: '0.000',
			code: '3',
		},
		{
			label: '0.0000',
			code: '4',
		},
		{
			label: '0.00000',
			code: '5',
		},
		{
			label: '0.000000',
			code: '6',
		},
	]);

	angular.module('dcApp').constant('DECIMAL_CONVERSION_PATTERNS', [
		{ name: '##,##', value: '##,##' },
		{ name: '##.##', value: '##.##' },
	]);

	angular.module('dcApp').constant('UPLOAD_FILES_URL', 'import/files/upload');
	angular.module('dcApp').constant('UPLOAD_DOCUMENTS_URL', 'document/upload');

	angular.module('dcApp').constant('MAP_ELEMENT_TYPES', [
		{
			label: 'Point',
			code: 'POINT',
		},
		{
			label: 'Shape',
			code: 'SHAPE',
		},
		{
			label: 'Cercle',
			code: 'CIRCLE',
		},
	]);

	angular.module('dcApp').constant('DATA_TYPES', [
		{ lib: 'Chaîne de caractères', value: 'string' },
		{ lib: 'Nombre entier (<2 Md)', value: 'integer' },
		{ lib: 'Grand nombre entier', value: 'big_integer' },
		{ lib: 'Nombre décimal', value: 'decimal' },
		{ lib: 'Date', value: 'date' },
		{ lib: 'Booléen', value: 'boolean' },
		{ lib: 'Mots (Contexte Sémantique)', value: 'words' },
		{ lib: 'Fichier Binaire', value: 'file' },
		{ lib: 'Géométrie', value: 'geometry' },
	]);

	angular.module('dcApp').constant('GEO_FORMATS', [
		{
			label: 'WKB',
			code: 'wkb',
		},
		{
			label: 'WKT',
			code: 'wkt',
		},
		{
			label: 'GeoJson',
			code: 'geo_json',
		},
	]);

	angular.module('dcApp').constant('MAPS_TILES_PROVIDERS', [
		{
			providerName: 'Aucun',
			none: true,
		},
		{
			providerName: 'OpenStreetMap',
			providerUrl: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
			providerCredits:
				'&copy; Openstreetmap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			requireApiKey: false,
			requireIdInput: false,
		},
		{
			providerName: 'Mapbox',
			providerUrl:
				'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
			providerCredits:
				'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			requireApiKey: true,
			requireIdInput: true,
			defaultId: 'mapbox/streets-v11',
		},
		{
			providerName: 'OpenTopoMap',
			providerUrl: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
			providerCredits:
				'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
			requireApiKey: false,
			requireIdInput: false,
		},
		{
			providerName: 'Personnaliser...',
			custom: true,
		},
	]);

	angular
		.module('dcApp')
		.constant('UNAUTHORIZED_TYPES_FOR_STAT', [
			'words',
			'file',
			'binary',
			'geometry',
		]);

	angular.module('dcApp').constant('GRIDSTER_OPTIONS', {
		columns: 50,
		rowHeight: 30, // TODO: changing this value will impact previously saved publication and total page height !
		colWidth: 'auto',
		defaultSizeX: 10,
		defaultSizeY: 10,
		minSizeX: 10,
		minSizeY: 2,
		sparse: true,
		pushing: false,
		floating: false,
		swapping: true,
		maxRows: 200,

		draggable: {
			enabled: true,
			handle: '.draghandle',
			scrollSensitivity: 1,
		},

		resizable: {
			enabled: true,
			handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
			start: function (event, $element, element) {},
			resize: function (event, $element, element) {},
			stop: function (event, $element, element) {
				//element.gridHeight = $element.context.clientHeight - 150
			},
		},
	});

	angular.module('dcApp').constant('UPDATE_EVENT', {
		DATABLOCK: 'update:datablock',
		ENTITETYPE: 'update:entity',
		DEPOT: 'update:datasource',
		ENTITETYPE_DEPOT: 'update:entity-datasource',
		CARAC_MAPPING: 'update:carac-mapping',
		ENTITETYPE_SAVE: 'update:entity-save',
	});

	angular.module('dcApp').constant('WEB_SOCKET_TOPICS', {
		project_bulk_execution: '/user/queue/project_bulk_execution/private',
		user_private_job: '/user/queue/job/private',
		user_import_from_database_progress:
			'/user/queue/import_db/progress/private',
		user_replay: '/user/queue/replay',
		global_datablock: '/topic/job/global/datablock',
		global_export_datablock: '/topic/exports/export',
		global_expose_datablock: '/topic/exports/expose',
		global_running_jobs: '/topic/job/running',
		global_jobs: '/topic/job/global',
		global_context: '/topic/context/global',
	});
	angular.module('dcApp').constant('GLOBAL_PERMISSION_CODES', {
		project_export: 'project-export',
		project_import: 'project-import',
		public_project_create: 'public-project-create',
	});

	angular.module('dcApp').constant('PROGRESS_STATUS', {
		finished: 'FINISHED',
		running: 'RUNNING',
		canceled: 'CANCELLED',
		failed: 'FAILED',
		failed_version: 'FAILED_VERSION',
	});

	angular.module('dcApp').constant('PROJECT_PROGRESS_OPERATION', {
		export: 'export',
		import: 'import',
		duplication: 'duplication',
		export_analysis: 'export_analysis',
		import_analysis: 'import_analysis',
	});

	angular.module('dcApp').constant('PROJECT_OPERATION_DONE', {
		export: 'project_export_done',
		import: 'project_import_done',
		duplication: 'project_duplication_done',
	});

	angular.module('dcApp').constant('AGGREGATION_TYPE', {
		multiDimensional: '0',
		columnDetails: '1',
		simple: '2',
		pivot: '3',
	});

	angular.module('dcApp').constant('FIELD_FUNCTION_OPERATION', {
		count: 'COUNT',
		distinctCount: 'COUNT_DISTINCT',
		approxDistinctCount: 'COUNT_DISTINCT_APPROX',
		lag: 'LAG',
		lead: 'LEAD',
		sum: 'SUM',
		average: 'AVG',
		minimum: 'MIN',
		maximum: 'MAX',
		median: 'MEDIAN',
		first: 'FIRST',
		last: 'LAST',
		firstValue: 'FIRST_VALUE',
		lastValue: 'LAST_VALUE',
		concatenate: 'CONCAT',
		collectToList: 'COLLECT_LIST',
		collectToSet: 'COLLECT_SET',
	});

	angular
		.module('dcApp')
		.constant('FRAME_OPERATION_EVENT', 'FRAME_OPERATION_EVENT');
})();
