(function () {
	'use strict';

	angular.module('dcApp').directive('confirmationreturnmodal', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					$scope.showConfirmationModal = function () {
						$('#confirmationModal').modal('show');
					};

					$scope.save = function () {
						vm.data.save(true);
					};

					$scope.redirectionToList = function () {
						vm.data.redirectionToList();
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dialog/confirmationReturnModal/confirmationReturnModal.html'
			);
		},
	]);
})();
