(function () {
	'use strict';

	angular.module('dcApp').directive('dbOutput', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'CommonServices',
				'toaster',
				'$sce',
				'DataBlocksService',
				'DATA_TYPES',
				'DECIMAL_CONVERSION_PATTERNS',
				'gettextCatalog',
				'DateService',
				function (
					$rootScope,
					$scope,
					CommonServices,
					toaster,
					$sce,
					DataBlocksService,
					DATA_TYPES,
					DECIMAL_CONVERSION_PATTERNS,
					gettextCatalog,
					DateService
				) {
					var vm = this;

					$scope.data = {};
					$scope.allColStatus = true;
					$scope.defaultDateInputPattern = 'ISO 8601';
					$scope.defaultDecimalInputPattern = '##,##';
					$scope.defaultDateOutputPattern = 'YYYY-MM-DD HH:mm:ss';
					$scope.decimalConversionPatterns = DECIMAL_CONVERSION_PATTERNS;
					$scope.availableTimezones = [];
					$scope.availableDateLanguages = [];

					let dataTypesOptions = _.each(DATA_TYPES, function (e) {
						e.lib = gettextCatalog.getString(e.lib);
					});

					CommonServices.getDateMasks().then(function (response) {
						$scope.dateOutputPatterns = response.data;
					});

					CommonServices.getDateReadingMasks().then(function (response) {
						$scope.datePatterns = response.data;
					});

					var type_conversion = {};

					type_conversion['string'] = [
						'boolean',
						'integer',
						'big_integer',
						'decimal',
						'date',
						'words',
					];
					type_conversion['integer'] = [
						'big_integer',
						'string',
						'date',
						'decimal',
						'boolean',
					];
					type_conversion['big_integer'] = [
						'integer',
						'string',
						'date',
						'decimal',
						'boolean',
					];
					type_conversion['decimal'] = [
						'big_integer',
						'integer',
						'boolean',
						'string',
					];
					type_conversion['boolean'] = ['string', 'integer'];
					type_conversion['binary'] = ['string', 'words'];
					type_conversion['date'] = ['string', 'big_integer'];
					type_conversion['words'] = ['string'];
					type_conversion['geometry'] = ['string'];

					$scope.getColumnOrigin = function (uuid) {
						vm.data.columnOperationsColumnId = uuid;
						vm.data.columnOperationsParentId = vm.datablock.source_id;
						vm.data.columnOperationsParentType = 'DATABLOCK';
						vm.data.showColumnOperations = true;
					};

					$scope.getHtmlIconByType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList));
						}
					};

					$scope.changeConvertTo = function (column) {
						delete column.replace_null_by;
						delete column.date_output_pattern;
						delete column.date_pattern;
						let type = $scope.getFinalType(column);
						if (type == 'type' && !column.is_list) {
							column.date_output_pattern = $scope.defaultDateOutputPattern;
						}
						if (column.convert_to == 'string' && column.type == 'date') {
							column.date_pattern = $scope.defaultDateOutputPattern;
						}
						if (column.convert_to == 'date' && column.type == 'string') {
							column.date_pattern = $scope.defaultDateInputPattern;
						}
						if (column.convert_to == 'decimal' && column.type == 'string') {
							column.decimal_pattern = $scope.defaultDecimalInputPattern;
						}
						if (column.convert_to == 'string' && column.type == 'decimal') {
							column.decimal_pattern = $scope.defaultDecimalInputPattern;
						}
					};

					$scope.changeReplaceNull = function (column) {
						delete column.replace_null_by;
						if (
							column.replace_null &&
							$scope.getFinalType(column) == 'string'
						) {
							column.replace_null_by = '';
						}
					};

					$scope.getFinalType = function (column) {
						return column.convert_to ? column.convert_to : column.type;
					};

					$scope.getConvertToTypes = function (column) {
						return _.filter(dataTypesOptions, function (item) {
							return type_conversion[column.type].indexOf(item.value) > -1;
						});
					};

					$scope.showReplaceNull = function (column) {
						let type = $scope.getFinalType(column);
						return (
							type == 'string' ||
							type == 'integer' ||
							type == 'big_integer' ||
							type == 'decimal' ||
							type == 'boolean' ||
							type == 'date'
						);
					};

					$scope.showReplaceNullBy = function (column) {
						let type = $scope.getFinalType(column);
						return (
							column.replace_null &&
							(type == 'string' ||
								type == 'integer' ||
								type == 'big_integer' ||
								type == 'decimal' ||
								type == 'boolean' ||
								type == 'date')
						);
					};

					$scope.setDatePattern = function (column) {
						column.tmpDatePattern = column.date_pattern;
						column.tmpDateTimezone = column.date_timezone;
						column.tmpDateLanguage = column.date_language;
						$scope.defaultDatePattern =
							column.convert_to == 'string'
								? $scope.defaultDateOutputPattern
								: $scope.defaultDateInputPattern;
						if (!$scope.datePattern || !$scope.datePattern[0]) {
							$scope.datePattern = $scope.defaultDatePattern;
						}
						$scope.validateTimezoneOnPatternChange(column);
					};

					$scope.setDecimalPattern = function (column) {
						column.tmpDecimalPattern = column.decimal_pattern;
					};

					$scope.setDecimalScale = function (column) {
						column.tmpDecimalScale = column.decimal_scale;
					};

					let invalidConfTra = gettextCatalog.getString(
						'Configuration invalide'
					);
					$scope.saveDatePattern = function (column) {
						if (!column.tmpDatePattern || !column.tmpDatePattern[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Le masque de conversion est obligatoire'
								)
							);
							return;
						}

						column.date_pattern = column.tmpDatePattern;
						column.date_timezone = column.tmpDateTimezone;
						column.date_language = column.tmpDateLanguage;
						delete column.tmpDatePattern;
						delete column.tmpDateTimezone;
						delete column.tmpDateLanguage;
						$scope.closePopover(column);
					};

					$scope.saveDecimalPattern = function (column) {
						if (!column.tmpDecimalPattern || !column.tmpDecimalPattern[0]) {
							toaster.pop(
								'error',
								'Configuration invalide',
								'Le masque de conversion est obligatoire'
							);
							return;
						}

						column.decimal_pattern = column.tmpDecimalPattern;
						$scope.closeDecPopover(column);
					};

					$scope.saveDecimalScale = function (column) {
						column.decimal_scale = column.tmpDecimalScale;
						delete column.tmpDecimalScale;
						$scope.closeDecScalePopover(column);
					};

					$scope.closePopover = function (column) {
						$('#popover' + column.column_uuid).dxPopover('hide');
					};

					$scope.closeDecPopover = function (column) {
						$('#popover_dc' + column.column_uuid).dxPopover('hide');
					};

					$scope.closeDecScalePopover = function (column) {
						$('#popover_dec_scale' + column.column_uuid).dxPopover('hide');
					};

					$scope.inputPatternTooltip = {
						width: 600,
						height: 260,
						contentTemplate: 'infoIPT',
						showTitle: true,
						position: 'top',
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'visibleInputPatternTooltip',
						},
						title: gettextCatalog.getString(
							'databloc.output.define.reading.mask'
						),
					};

					$scope.metadataDetailsPopover = {
						target: '#dddddd',
						showEvent: 'dxclick',
						position: 'top',
						width: 450,
						visible: false,
						title: gettextCatalog.getString(
							'databloc.output.define.reading.mask'
						),
					};

					$scope.init = function () {
						// get timezones
						// display tz used in display mask / timezone column
						$scope.availableTimezones = DateService.getMomentTZList();
						// conversion tz used in convert to col
						$scope.availableConversionTimezones = $rootScope.availableTimezones;
						// date languages
						$scope.availableDateLanguages = $rootScope.availableDateLanguages;
						// init columns
						$scope.columns = [];
						var columns = vm.data.step.output
							? angular.copy(vm.data.step.output)
							: [];

						for (let c in columns) {
							let column = columns[c];
							let col = _.find($scope.vm.data.columns, function (item) {
								return item.uuid == column.column_uuid;
							});
							if (col) {
								column.type = col.type;
								column.original_lib = col.lib;
								column.is_list = col.is_list;
								$scope.columns.push(column);
							}
							let finalType = $scope.getFinalType(column);
							if (
								column.replace_null_by &&
								(finalType == 'integer' ||
									finalType == 'big_integer' ||
									finalType == 'decimal')
							) {
								column.replace_null_by = Number(column.replace_null_by);
							}
						}

						for (let c in $scope.vm.data.columns) {
							let col = _.find($scope.columns, function (item) {
								return item.column_uuid == $scope.vm.data.columns[c].uuid;
							});
							if (!col) {
								let column = {
									column_uuid: $scope.vm.data.columns[c].uuid,
									original_lib: $scope.vm.data.columns[c].lib,
									lib: $scope.vm.data.columns[c].lib,
									type: $scope.vm.data.columns[c].type,
									active: true,
									is_list: $scope.vm.data.columns[c].is_list,
								};
								$scope.columns.push(column);
							}
						}

						for (let c in $scope.columns) {
							if (
								$scope.getFinalType($scope.columns[c]) == 'date'
							) {
								$scope.columns[c].date_output_pattern = $scope.columns[c].date_output_pattern
								? $rootScope.getCorrespondentDatePattern($scope.columns[c].date_output_pattern)
								: $scope.defaultDateOutputPattern;
							}
						}

						$scope.searchCaracByText();
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showOutput = false;
					};

					var validate = function () {
						for (var c in $scope.columns) {
							let column = $scope.columns[c];
							if (!column.lib) {
								toaster.pop(
									'error',
									invalidConfTra,
									gettextCatalog.getString(
										'Attention ! Au moins une des colonnes en sortie est transtypée vers un type non compatible. Vérifier la compatibilité des conversions avant de poursuivre.'
									)
								);
								return false;
							}

							if (
								column.replace_null &&
								column.replace_null_by == undefined &&
								$scope.getFinalType(column) != 'string'
							) {
								toaster.pop(
									'error',
									invalidConfTra,
									gettextCatalog.getString(
										"Le type de la valeur de remplacement des valeurs nulles d'une des colonnes en sortie est invalide."
									)
								);
								return false;
							}

							if (
								((column.type == 'string' && column.convert_to == 'date') ||
									(column.type == 'date' && column.convert_to == 'string')) &&
								!column.date_pattern
							) {
								toaster.pop(
									'error',
									invalidConfTra,
									gettextCatalog.getString(
										"Le masque de conversion d'au moins une des colonnes en sortie est vide. Le renseigner avant de poursuivre."
									)
								);
								return false;
							}

							if (
								((column.type == 'string' && column.convert_to == 'decimal') ||
									(column.type == 'decimal' &&
										column.convert_to == 'string')) &&
								!column.decimal_pattern
							) {
								toaster.pop(
									'error',
									'Configuration invalide',
									"Le masque de conversion d'au moins une des colonnes en sortie est vide. Le renseigner avant de poursuivre."
								);
								return false;
							}
						}
						return true;
					};

					$scope.save = function () {
						if (validate()) {
							vm.data.saveOutputMethod($scope.columns, $scope.saveAndClose);
						}
					};

					$scope.saveAndClose = function () {
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					};

					$scope.cancel = function () {
						vm.closeModal();
					};

					$scope.searchCaracByText = function () {
						if (
							$scope.caracSearchText == undefined ||
							$scope.caracSearchText.length == 0
						) {
							$scope.columns_ = $scope.columns;
						} else {
							$scope.columns_ = _.filter($scope.columns, function (item) {
								return (
									item.lib
										.toLowerCase()
										.indexOf($scope.caracSearchText.toLowerCase()) !== -1
								);
							});
						}
					};

					$scope.updateAllColStatus = function () {
						for (let i = 0; i < $scope.columns.length; i++) {
							$scope.columns[i].active = $scope.allColStatus;
						}
						$scope.searchCaracByText();
					};

					$scope.validateTimezoneOnPatternChange = function(column) {
						if (column.tmpDatePattern === "ISO 8601") {
							column.tmpDateTimezone = undefined;
							$scope.disableTimezone = true;
						} else {
							$scope.disableTimezone = false;
						}
					}
					$scope.validateTimezoneOnPatternChangeSelect = function (item, model, label, event, column) {
						event.stopPropagation();
						$scope.validateTimezoneOnPatternChange(column);
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					datablock: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/dbOutput/dbOutput.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.vm.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showOutput;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
