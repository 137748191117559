(function () {
	'use strict';

	angular.module('dcApp').factory('GenericService', [
		'$http',
		'$q',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'httpService',
		'ProjectStatAdapter',
		'UrlService',
		function (
			$http,
			$q,
			$rootScope,
			API_BASE_URL_BACKEND,
			httpService,
			ProjectStatAdapter,
			UrlService
		) {
			let GenericService = {},
				endpoint = API_BASE_URL_BACKEND;

			GenericService.ispathAliasExists = function (path) {
				return $http
					.get(API_BASE_URL_BACKEND + 'path_aliases_check_exists?path=' + path)
					.then(function (response) {
						return response;
					});
			};

			GenericService.editPathAlias = function (aliasId, newLib) {
				return $http
					.get(
						API_BASE_URL_BACKEND +
							'edit_path_aliases?id=' +
							aliasId +
							'&new_lib=' +
							newLib
					)
					.then(function (response) {
						return response;
					});
			};

			GenericService.getPathAlias = function (aliasId, newLib) {
				return $http
					.get(
						API_BASE_URL_BACKEND +
							'get_path_aliases?id=' +
							aliasId +
							'&new_lib=' +
							newLib
					)
					.then(function (response) {
						return response;
					});
			};

			GenericService.getPathAlias = function (aliasId) {
				return $http
					.get(API_BASE_URL_BACKEND + 'path_aliases?id=' + aliasId)
					.then(function (response) {
						return response;
					});
			};

			GenericService.getTraces = function (skip, take, options) {
				let url = API_BASE_URL_BACKEND + 'traces';
				if (take) {
					url = url + '?skip=' + skip + '&take=' + take;
				}
				return httpService.postRequest(url, options);
			};

			GenericService.getTraceByProject = function (dcType) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_TRACES');
				url = url.replace('{dcType}', dcType);
				return httpService.getRequest(url);
			};

			GenericService.getTraceByProjectStats = function (dcType) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_TRACES');
				url = url.replace('{dcType}', dcType);
				httpService.getRequest(url).then(function (response) {
					deferred.resolve(ProjectStatAdapter.generateTraces(response.data));
				});
				return deferred.promise;
			};

			GenericService.createPathAlias = function (alias) {
				return httpService.postRequest(
					API_BASE_URL_BACKEND + 'path_aliases',
					alias
				);
			};

			GenericService.deletePathAlias = function (aliases) {
				return httpService.putRequest(
					API_BASE_URL_BACKEND + 'path_aliases',
					aliases,
					false
				);
			};

			GenericService.getCaracsByFullText = function (
				text,
				lc,
				ll,
				desc,
				tags,
				poids,
				datablock,
				filterType
			) {
				let list = [];
				if (!poids) {
					poids = 0;
				}

				const queryObject = {
					text: text, code: true,
					lc: lc, ll: ll,
					dec: desc, tag: tags,
					actif: true, poids: poids,
					type: filterType, datablocks: typeof datablock === 'boolean' ? datablock : false
				}
				const url = UrlService.generateUrl('FULL_TEXT_FIND_ALL_STRUCTURE', {}, queryObject);

				return httpService.postRequest(url, list);
			};

			GenericService.getConnectedCaracs = function (
				caracId,
				text,
				lc,
				ll,
				desc,
				tags,
				poids,
				type,
				datablock,
				filterType
			) {
				let path =
					endpoint +
					'/graph/get_connected_cara/' +
					caracId +
					'/' +
					type +
					'/?text=' +
					text +
					'&code=true&lc=' +
					lc +
					'&ll=' +
					ll +
					'&dec=' +
					desc +
					'&tag=' +
					tags +
					'&actif=true&poids=' +
					poids;
				if (!isEmpty(filterType)) {
					path = path + '&filterType=' + filterType;
				}
				if (datablock) {
					path = path + '&datablocks=true';
				} else {
					path = path + '&datablocks=false';
				}

				return $http.get(path).then(function (response) {
					return response;
				});
			};

			GenericService.getAllPathFormattedAliases = function (
				oldPathes,
				start,
				end,
				loop,
				startType,
				endType,
				nbPage,
				poids,
				datablock
			) {
				let path =
					endpoint +
					'/graph/get_all_path_formatted_with_aliases/' +
					start +
					'/' +
					startType +
					'/' +
					end +
					'/' +
					endType +
					'/?nb_page=' +
					nbPage +
					'&poids=' +
					poids;
				if (loop) {
					path = path + '&loop=' + loop;
				}
				if (datablock) {
					path = path + '&datablocks=true';
				} else {
					path = path + '&datablocks=false';
				}
				return httpService.postRequest(path, oldPathes);
			};

			GenericService.getElementaryData = function (caracs, includeStats) {
				let path = endpoint + '/entite_types/get_elementary_data/';
				path =
					includeStats !== undefined
						? path + '?includeStats=' + includeStats
						: path;
				return httpService.postRequest(path, caracs);
			};

			GenericService.getStatFunctions = function (
				grammar,
				colId,
				clientId,
				datablockId,
				colType,
				isList,
				useCache
			) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('COLUMN_STAT') +
					'?colId=' +
					colId +
					'&colType=' +
					colType +
					'&isList=' +
					isList +
					'&clientId=' +
					clientId;

				if (datablockId) {
					path = path + '&datablockId=' + datablockId;
				}
				if ($rootScope.jobCacheActive && useCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, grammar);
			};

			GenericService.getCountDistinct = function (
				grammar,
				colId,
				clientId,
				datablockId,
				colType,
				isList,
				useCache
			) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('COLUMN_COUNT_DISTINCT') +
					'?colId=' +
					colId +
					'&colType=' +
					colType +
					'&isList=' +
					isList +
					'&clientId=' +
					clientId;

				if (datablockId) {
					path = path + '&datablockId=' + datablockId;
				}
				if ($rootScope.jobCacheActive && useCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, grammar);
			};

			GenericService.getCountDistinctApprox = function (
				grammar,
				colId,
				clientId,
				datablockId,
				colType,
				isList,
				useCache
			) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('COLUMN_COUNT_DISTINCT_APPROX') +
					'?colId=' +
					colId +
					'&colType=' +
					colType +
					'&isList=' +
					isList +
					'&clientId=' +
					clientId;

				if (datablockId) {
					path = path + '&datablockId=' + datablockId;
				}
				if ($rootScope.jobCacheActive && useCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, grammar);
			};

			GenericService.getHistogram = function (
				grammar,
				colId,
				bucketCount,
				clientId,
				datablockId,
				useCache
			) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('COLUMN_HISTOGRAM') +
					'?colId=' +
					colId +
					'&bucketCount=' +
					bucketCount +
					'&clientId=' +
					clientId;

				if (datablockId) {
					path = path + '&datablockId=' + datablockId;
				}
				if ($rootScope.jobCacheActive && useCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, grammar);
			};

			GenericService.getTopValues = function (
				grammar,
				colId,
				clientId,
				limit,
				order,
				datablockId,
				hdSourceId,
				useCache
			) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('COLUMN_STAT_TOP_VALUES') +
					'?colId=' +
					colId +
					'&clientId=' +
					clientId +
					'&order=' +
					order +
					'&limit=' +
					limit;

				if (datablockId) {
					path = path + '&datablockId=' + datablockId;
				}
				if (hdSourceId) {
					path = path + '&hdSourceId=' + hdSourceId;
				}
				if ($rootScope.jobCacheActive && useCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, grammar);
			};

			GenericService.getCountAll = function (grammar, clientId) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATA_STAT_COUNT') +
					'?clientId=' +
					clientId;
				return httpService.postRequest(path, grammar);
			};

			GenericService.getValuesCountDistinct = function (request, clientId) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATA_STAT_COUNT_DISTINCT') +
					'?clientId=' +
					clientId;
				return httpService.postRequest(path, request);
			};

			GenericService.getValuesStat = function (grammar, column, clientId) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATA_STAT_COLUMN_VALUES_STAT') +
					'?column=' +
					column +
					'&clientId=' +
					clientId;
				return httpService.postRequest(path, grammar);
			};

			GenericService.getCountLinked = function (request, distinct, clientId) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATA_STAT_LINK_ANALYSE') +
					'?clientId=' +
					clientId +
					'&distinct=' +
					distinct;
				return httpService.postRequest(path, request);
			};

			GenericService.getLinkedData = function (request, distinct, linked) {
				let path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DATA_STAT_LINK_VALUES') +
					'?linked=' +
					linked +
					'&distinct=' +
					distinct;
				return httpService.postRequest(path, request);
			};

			GenericService.getWebUiUrl = function () {
				let path = API_BASE_URL_BACKEND + 'webUiUrl';
				return httpService.getRequest(path);
			};

			return GenericService;
		},
	]);
})();
