'use strict';

angular
	.module('dcApp')
	.controller('UserGroupListController', [
		'PermissionService',
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'UserGroupService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$q',
		'permissionChecker',
		'UserService',
		'gettextCatalog',
		function (
			PermissionService,
			$timeout,
			$scope,
			$state,
			$rootScope,
			toaster,
			UserGroupService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$q,
			permissionChecker,
			UserService,
			gettextCatalog
		) {
			let permissionTra = gettextCatalog.getString('Permission');
			let unauthorisedActionTra = gettextCatalog.getString(
				'Action non autorisée'
			);
			let successTra = gettextCatalog.getString('Succès');
			let groupDeleteSuccessTra = gettextCatalog.getString(
				'Suppression du groupe effectuée'
			);

			$scope.showDeleteModal = function (obj) {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop('error', permissionTra, unauthorisedActionTra);
						return;
					} else {
						$scope.objToDelete = obj;
						$scope.bulkDelete = false;
						$('#delModal').modal('show');
					}
				});
			};

			$scope.showDeleteManyGroups = function () {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						$('#delModal').modal('show');
						toaster.pop('error', permissionTra, unauthorisedActionTra);
						return;
					} else {
						$scope.bulkDelete = true;
						$('#delModal').modal('show');
					}
				});
			};

			var deleteManyGroups = function () {
				let groupIds = _.map($scope.selectedGroups, function (group) {
					return group.id;
				});
				UserGroupService.deleteBulkGroups(groupIds)
					.then(function () {
						initGroups();
						delete $scope.selectedGroups;
						$('#delModal').modal('hide');
						toaster.pop('success', successTra, groupDeleteSuccessTra);
					})
					.catch(function (e) {
						if (e && e.data && e.data.code == '40' && $scope.map_authorised) {
							initGroups();
						}
					});
			};

			$scope.confirmDelete = function () {
				if ($scope.bulkDelete) {
					deleteManyGroups();
				} else {
					deleteGroup();
				}
			};

			var deleteGroup = function () {
				UserGroupService.deleteGroup($scope.objToDelete.id).then(function (
					response
				) {
					toaster.pop('success', successTra, groupDeleteSuccessTra);
					$('#delModal').modal('hide');
					initGroups();
				});
			};

			function getListGroups() {
				var deferred = $q.defer();
				UserGroupService.getGroups().then(
					function (groups) {
						deferred.resolve(groups);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListGroups().then(function (content) {
					if (content !== undefined) {
						$scope.groups = content;
						for (var g in $scope.groups) {
							$scope.groups[g].creation_date =
								$rootScope.getDateTimeWithPatternMn(
									$scope.groups[g].creation_date
								);
							$scope.groups[g].update_date =
								$rootScope.getDateTimeWithPatternMn(
									$scope.groups[g].update_date
								);
						}
						$scope.dataGridOptions.dataSource = $scope.groups;
						$scope.showGrid = true;
					}
				});
			};

			const dataGirdColumnName = {};
			dataGirdColumnName.labelTra = gettextCatalog.getString('Libellé');
			dataGirdColumnName.descTra = gettextCatalog.getString('Description');
			dataGirdColumnName.createdByTra = gettextCatalog.getString('Créé par');
			dataGirdColumnName.lastUpdaterTra = gettextCatalog.getString(
				'Dernier modificateur'
			);
			dataGirdColumnName.creationDateTra =
				gettextCatalog.getString('Date de création');
			dataGirdColumnName.updateDateTra = gettextCatalog.getString(
				'Date de mise à jour'
			);
			dataGirdColumnName.roles = gettextCatalog.getString('role');
			dataGirdColumnName.actionsTra = gettextCatalog.getString('Actions');

			$scope.dataGridOptions = {
				dataSource: $scope.groups,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				selection: {
					mode: 'multiple',
					showCheckBoxesMode: 'always',
					selectAllMode: 'page',
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.labelTra,
						dataField: 'Libellé',
						cellTemplate: 'clickableCell',
					},
					// {
					//     caption: dataGirdColumnName.descTra,
					//     dataField: "description"
					// },
					// {
					//     caption: dataGirdColumnName.createdByTra,
					//     cellTemplate : "createdBy"
					// },
					// {
					//     caption: dataGirdColumnName.lastUpdaterTra,
					//     cellTemplate : "updatedBy"
					// },
					// {
					//     caption: dataGirdColumnName.creationDateTra,
					//     dataField: "creation_date"
					// },
					// {
					//     caption: dataGirdColumnName.updateDateTra,
					//     dataField: "update_date"
					// },
					{
						caption: dataGirdColumnName.roles,
						alignment: 'center',
						cellTemplate: 'groupRoles',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.actionsTra,
						alignment: 'center',
						cellTemplate: 'actions',
						width: 120,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
				onSelectionChanged: function (selectedItems) {
					$scope.selectedGroups = selectedItems.selectedRowsData;
				},
				onRowPrepared: function (e) {
					if (e.rowType === 'data') {
						e.rowElement.css({ height: 55 });
					}
				},
			};

			function initGroups() {
				$scope.getGridData();
			}

			$scope.refreshGroupList = function () {
				$scope.showGrid = false;
				$scope.getGridData();
			};

			permissionChecker.checkGlobalPermission(
				'user-groups',
				function () {
					initGroups();
				},
				function () {
					$scope.groups_unauthorised = true;
				}
			);
		},
	])
	.controller('EditUserGroupController', [
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'$filter',
		'toaster',
		'UserGroupService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'DATE_PATTERN',
		'PermissionService',
		'UserService',
		'gettextCatalog',
		function (
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			$filter,
			toaster,
			UserGroupService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			DATE_PATTERN,
			PermissionService,
			UserService,
			gettextCatalog
		) {
			let permissionTra = gettextCatalog.getString('Permission');
			let unauthorisedActionTra = gettextCatalog.getString(
				'Action non autorisée'
			);
			let successTra = gettextCatalog.getString('Succès');
			let groupDeleteSuccessTra = gettextCatalog.getString(
				'Suppression du groupe effectuée'
			);
			let groupCreateSuccessTra = gettextCatalog.getString(
				'Création du groupe effectuée'
			);
			let groupUpdateSuccessTra = gettextCatalog.getString(
				'Modification du groupe effectuée'
			);
			let addSuccessTra = gettextCatalog.getString('Ajout effectué');
			let deleteSuccessTra = gettextCatalog.getString('Suppression effectuée');

			$rootScope.fields = [];

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('user-groups');
				}, 300);
			};

			$scope.saveAndReturnToList = function () {
				if (!$scope.groupTabHidden) {
					$scope.saveGroup(true);
				} else {
					$('#confirmationModal').modal('hide');
					$timeout(function () {
						$state.go('user-groups');
					}, 300);
				}
			};
			$scope.dataConfirmationModal = {};
			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.saveGroup = function (returnToList, updateLabel) {
				$('#confirmationModal').modal('hide');
				$rootScope.fields = [];
				if ($scope.isNew) {
					UserGroupService.createGroup($scope.group).then(function (response) {
						toaster.pop('success', successTra, groupCreateSuccessTra);
						if (returnToList) {
							$timeout(function () {
								$state.go('user-groups');
							}, 300);
						} else {
							$timeout(function () {
								$state.go('user-groups-edit', { id: response.data.id });
							}, 300);
						}
					});
				} else {
					UserGroupService.editGroup($scope.group.id, $scope.group).then(
						function (response) {
							toaster.pop('success', successTra, groupUpdateSuccessTra);
							if (updateLabel) {
								$scope.group.label = $scope.group.name;
								$scope.initialGroupObject = angular.copy($scope.group);
							}
							if (returnToList) {
								$timeout(function () {
									$state.go('user-groups');
								}, 300);
							}
						}
					);
				}
			};

			init();

			function init() {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop('error', permissionTra, unauthorisedActionTra);
						$state.go('user-groups');
					} else {
						$scope.isNew = true;
						$scope.group = {};
						if ($stateParams.id) {
							// edit or duplicate
							$scope.isNew = false;

							var groupId = $stateParams.id;

							UserGroupService.getGroup(groupId).then(function (group) {
								$scope.group = group;
								$scope.initialGroupObject = angular.copy($scope.group);
							});
						}
					}
				});
			}

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showDeleteModal = function () {
				$scope.objToDelete = $scope.group;
				$('#delModal').modal('show');
			};

			function createList(selector, items, isForGroupUsers) {
				var list = $(selector)
					.dxList({
						items: items,
						repaintChangesOnly: true,
						keyExpr: 'user_id',
						displayExpr: 'name',
						itemDragging: {
							allowReordering: true,
							group: 'users',
							onDragStart: function (e) {
								e.itemData = items[e.fromIndex];
							},
							onAdd: function (e) {
								if (isForGroupUsers) {
									addUser(e.itemData.user_id);
								}
								items.splice(e.toIndex, 0, e.itemData);
								list.option('items', items);
							},
							onRemove: function (e) {
								if (isForGroupUsers) {
									removeUser(e.itemData.user_id);
								}
								items.splice(e.fromIndex, 1);
								list.option('items', items);
							},
						},
					})
					.dxList('instance');
			}

			function addUser(userId) {
				UserGroupService.addUser($scope.group.id, userId).then(function (
					response
				) {
					toaster.pop('success', successTra, addSuccessTra);
				});
			}

			function removeUser(userId) {
				UserGroupService.removeUser($scope.group.id, userId).then(function (
					response
				) {
					toaster.pop('success', successTra, deleteSuccessTra);
				});
			}

			$scope.deleteGroup = function () {
				$('#delModal').modal('hide');
				UserGroupService.deleteGroup($scope.objToDelete.id).then(function (
					response
				) {
					toaster.pop('success', successTra, groupDeleteSuccessTra);
					$timeout(function () {
						$state.go('user-groups');
					}, 300);
				});
			};

			$scope.getGlobalPermissions = function () {
				if (!_.isEqual($scope.group, $scope.initialGroupObject))
					showConfirmationDialog();
				$scope.groupTabHidden = true;
				$scope.showGlobalPermissions = true;
			};

			let showConfirmationDialog = function () {
				$scope.dataConfirmationModal.confirmationDialogTitle =
					gettextCatalog.getString('Sauvegarder les changements');
				$scope.dataConfirmationModal.confirmationDialogMessage =
					gettextCatalog.getString('Voulez-vous enregistrer les changements ?');
				$scope.dataConfirmationModal.confirmationDialogActionName =
					gettextCatalog.getString('Enregistrer');
				$scope.dataConfirmationModal.enableConfirmDialogAction = true;
				$scope.dataConfirmationModal.confirmationGreenAction = true;
				$scope.dataConfirmationModal.confirmDialogAction = function () {
					$scope.saveGroup(false, true);
				};
				$scope.dataConfirmationModal.showConfirmationDialog = true;
			};
			$scope.getGroupUsers = function () {
				$scope.groupTabHidden = true;
				UserGroupService.getMembers($scope.group.id).then(function (response) {
					var groupUsers = response.data;
					UserGroupService.getAvailableUsers($scope.group.id).then(function (
						availableUsersResponse
					) {
						var availableUsers = _.filter(
							availableUsersResponse.data,
							function (item) {
								return !_.filter(groupUsers, function (user) {
									return user.user_id == item.user_id;
								})[0];
							}
						);
						createList('#availableUsers', availableUsers);
						_.each(groupUsers, function (gUser) {
							let u = _.find(availableUsersResponse.data, function (aUser) {
								return aUser.user_id == gUser.user_id;
							});
							if (u) {
								gUser.name = u.name;
							}
						});
						createList('#groupUsers', groupUsers, true);
					});
				});
			};
		},
	]);
