(function () {
	'use strict';

	angular.module('dcApp').factory('SearchService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var SearchService = {};

			SearchService.searchEntitesByEntiteTypeByText = function (
				text,
				ignoreCase,
				entiteType,
				clientId,
				multicolumnMode,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('VALUE_SEARCH_BASIC_EXEC');
				url =
					url +
					'?entite_type=' +
					entiteType +
					'&text=' +
					text +
					'&clientId=' +
					clientId;
				url = url + '&multicolumnMode=' + multicolumnMode;
				if (ignoreCase !== undefined) {
					url = url + '&ignoreCase=' + ignoreCase;
				}

				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}

				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			SearchService.getSearchResultByJobKey = function (jobKey, pos) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('VALUE_SEARCH_DATA');
				url = url + '?jobKey=' + jobKey + '&pos=' + pos;
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			SearchService.searchEntitesByEntiteType = function (
				rules,
				entiteType,
				clientId,
				multicolumnMode,
				noCache
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('VALUE_SEARCH_ADVANCED_EXEC');
				url = url + '?entite_type=' + entiteType + '&clientId=' + clientId;
				url = url + '&multicolumnMode=' + multicolumnMode;

				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}

				$http.post(url, rules).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			SearchService.getSearchGrammarFilter = function (
				rules,
				columns,
				multicolumnMode
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('VALUE_SEARCH_GET_FILTER');
				url = url + '?multicolumnMode=' + multicolumnMode;
				$http.post(url, { columns: columns, rules: rules }).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			return SearchService;
		},
	]);
})();
