var getDefaultDxGridConfig = function (PAGINATIONS_SIZE, PAGINATIONS_SIZES) {
	return {
		paging: {
			enabled: true,
			pageSize: PAGINATIONS_SIZE,
		},
		pager: {
			showPageSizeSelector: true,
			allowedPageSizes: PAGINATIONS_SIZES,
			showInfo: true,
			visible: true,
		},
		scrolling: {
			rowRenderingMode: 'Standard',
			showScrollbar: 'onHover',
		},
		filterPanel: {
			visible: true,
		},
		filterRow: {
			visible: true,
			applyFilter: 'auto',
		},
		rowAlternationEnabled: true,
		headerFilter: {
			visible: true,
			applyFilter: 'auto',
		},
		showColumnLines: true,
		showRowLines: true,
		allowColumnReordering: true,
		allowColumnResizing: true,
		columnAutoWidth: true,
		showBorders: true,
		columnChooser: {
			enabled: false,
		},
		columnFixing: {
			enabled: true,
		},
	};
};
