(function () {
	'use strict';

	angular.module('dcApp').directive('columnInfo', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				function ($scope, GenericService) {
					$scope.saveValue = function () {
						$scope.vm.widgetData.updateaction_l2($scope.columnAlias);
					};

					var vm = this;

					$scope.init = function (column) {
						delete $scope.pathGraphMode;
						delete $scope.columnFullPathNodes;
						delete $scope.columnCarac;
						delete $scope.columnEntiteType;

						$scope.selectedColumn = column;
						$scope.columnAlias = column.alias
							? column.alias
							: column.column_alias;
						if (column.path) {
							var elementaryDataMap = [];

							setElementaryDataMap(elementaryDataMap, column.path);
							GenericService.getElementaryData(elementaryDataMap).then(
								function (response) {
									var carac = _.filter(response.data, function (item) {
										return item.id == column.uca_id && item.type == 'c';
									})[0];

									$scope.columnCarac = carac.lib_court;
									$scope.columnCaracId = carac.id;
									$scope.columnEntiteType = carac.parent_entite_type_lib;
									$scope.columnEntiteTypeId = carac.parent_entite_type_id;
									$scope.columnFullPathNodes = [];
									var nodes = column.path.split('-');
									for (var n in nodes) {
										var c = _.filter(response.data, function (item) {
											return (
												(item.type == 'c' && nodes[n] == 'c' + item.id) ||
												(item.type == 't' && nodes[n] == item.id)
											);
										})[0];
										$scope.columnFullPathNodes.push(c);
									}
								}
							);
						}
					};

					$scope.save = function () {
						$scope.saveValue();
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					$scope.showCaraInfo = function (id) {
						delete vm.widgetData.columnInfoId;
						delete vm.widgetData.columnInfoGrammar;
						vm.widgetData.caracInfoId = $scope.columnCaracId;
						vm.widgetData.caracInfoEntiteId = $scope.columnEntiteTypeId;
						vm.widgetData.caracInfoEntiteLabel = $scope.columnCarac;
						vm.widgetData.showColumnStat = true;
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showColumnInfo = false;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=' },
				controller,
				'./src/components/directives/columnInfo/columnInfo.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.widgetData.showColumnInfo = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showColumnInfo;
						},
						function () {
							scope.init(scope.vm.widgetData.column);
						}
					);
				}
			);
		},
	]);
})();
