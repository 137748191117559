(function () {
	//Timeline data provider
	angular.module('dcApp').service('timelineDataProvider', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		'DateService',
		function ($http, API_BASE_URL_BACKEND, $rootScope, DateService) {
			// $http.defaults.headers.post["Content-Type"] =
			// "application/x-www-form-urlencoded";

			this.frFormat = 'DD/MM/Y HH:mm:ss';
			this.defaultFormat = 'MM/D/Y HH:mm:ss';
			this.uiGridDefaultFormat = 'Y-MM-DD HH:mm:ss';

			this.formatByLang = {
				fr: this.frFormat,
				en: this.defaultFormat,
			};

			this.getDefaultFormat = function() {
				return this.formatByLang[$rootScope.currentLangCode] ? this.formatByLang[$rootScope.currentLangCode] : this.defaultFormat;
			}

			this.fetchData = function (data, body, timelineId) {
				data = data || {};

				var query = Object.keys(data)
					.reduce(function (a, k) {
						a.push(k + '=' + encodeURIComponent(data[k]));
						return a;
					}, [])
					.join('&');
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('TIMELINES_EXEC') +
					'?' +
					query;
				if (timelineId) {
					url = url + '&timelineId=' + timelineId;
				}
				return $http({
					method: 'post',
					url: url,
					headers: { 'Content-Type': 'application/json' },
					data: JSON.stringify(body),
				});
			};

			this.getDefaultDates = function () {
				return {
					start: DateService.dateToStringWithPattern(moment().add(-1, 'days'),this.defaultFormat),
					end: DateService.dateToStringWithPattern(moment(),this.defaultFormat),
				};
			};

			this.translateDates = function (dates, fromLang, toLang) {
				return {
					start: dates.start
						? DateService.dateToStringWithPattern(moment(dates.start, this.formatByLang[fromLang]), 	this.formatByLang[toLang])
						: null,
					end: dates.end
						? DateService.dateToStringWithPattern(moment(dates.end, this.formatByLang[fromLang]), 	this.formatByLang[toLang])
						: null,
				};
			};
		},
	]);
})();
