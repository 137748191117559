(function () {
	'use strict';

	angular.module('dcApp').directive('framestatus', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'frameService',
				'SparkJobsServices',
				'gettextCatalog',
				'FRAME_OPERATION_EVENT',
				function (
					$scope,
					$rootScope,
					frameService,
					SparkJobsServices,
					gettextCatalog,
					FRAME_OPERATION_EVENT
				) {
					let confirmationPersistMemoryDialog = {};
					confirmationPersistMemoryDialog.title = gettextCatalog.getString(
						'Tous les droits seront perdus'
					);
					confirmationPersistMemoryDialog.message = gettextCatalog.getString(
						'Vous êtes sur le point de réaliser une pérsistance disque. Cette opération entrainera la perte de tous vos droits. Etes vous sûrs de vouloir continuer cette opération ?'
					);

					confirmationPersistMemoryDialog.action =
						gettextCatalog.getString('Oui');

					let confirmationDepersistMemoryDialog = {};
					confirmationDepersistMemoryDialog.title = gettextCatalog.getString(
						'Dépersister de la mémoire'
					);
					confirmationDepersistMemoryDialog.message = gettextCatalog.getString(
						'Vous allez dépersister de la mémoire un DataBlock. Cette opération est définitive. Confirmer cette action ?'
					);
					confirmationDepersistMemoryDialog.action =
						gettextCatalog.getString('Oui');

					let confirmationDepersistDiskDialog = {};
					confirmationDepersistDiskDialog.title = gettextCatalog.getString(
						'Dépersister du disque'
					);
					confirmationDepersistDiskDialog.message = gettextCatalog.getString(
						'frame.depersiste.confrim'
					);
					confirmationDepersistDiskDialog.action =
						gettextCatalog.getString('Oui');

					$scope.confirmationDialogRepersist = gettextCatalog.getString(
						'Êtes vous sûrs de vouloir repersister cet élément ?'
					);
					$scope.confirmDialogRepersist =
						gettextCatalog.getString('Repersister');

					var vm = this;

					$scope.$on('job_global', function (event, message) {
						if (message.message == $scope.frame.code) {
							setFrameStatusFromSocket(message);
						}
					});

					$scope.$on('FRAME_OPERATION_EVENT', function (event, message) {
						handleOperationResponse(message);
					});

					$scope.getFrameStatus = function () {
						frameService
							.getStatus($scope.frame.code)
							.then(function (response) {
								$scope.setFrameStatus(response.data);
							})
							.catch(function (error) {
								$rootScope.disableAjaxLoading = false;
							});
					};

					$scope.stop = function () {
						$rootScope.disableAjaxLoading = true;
						$scope.persistenceSize = undefined;
						if ($scope.frame.status == 'PREPARATION') {
							$scope.frame.status = 'CANCELLED';
							return;
						}
						SparkJobsServices.killJob($scope.frame.job_id)
							.then(function (response) {
								$rootScope.disableAjaxLoading = false;
							})
							.catch(function (error) {
								$rootScope.disableAjaxLoading = false;
							});
					};

					$scope.cache = function () {
						$rootScope.disableAjaxLoading = true;
						var currentStatus = $scope.frame.status;
						$scope.frame.status = 'PREPARATION';
						frameService.cache($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.persist = function () {
						vm.widgetData.confirmationDialogTitle =
							confirmationPersistMemoryDialog.title;
						vm.widgetData.confirmationDialogMessage =
							confirmationPersistMemoryDialog.message;
						vm.widgetData.confirmationDialogMessageDetails = '';
						vm.widgetData.confirmationDialogActionName =
							confirmationPersistMemoryDialog.action;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.doPersist;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.doPersist = function () {
						var currentStatus = $scope.frame.status;
						$scope.frame.status = 'PREPARATION';
						$rootScope.disableAjaxLoading = true;
						$scope.persistenceSize = undefined;
						frameService.persist($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.uncache = function () {
						vm.widgetData.confirmationDialogTitle =
							confirmationDepersistMemoryDialog.title;
						vm.widgetData.confirmationDialogMessage =
							confirmationDepersistMemoryDialog.message;
						vm.widgetData.confirmationDialogMessageDetails = '';
						vm.widgetData.confirmationDialogActionName =
							confirmationDepersistMemoryDialog.action;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.doUncache;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.unpersist = function () {
						vm.widgetData.confirmationDialogTitle =
							confirmationDepersistDiskDialog.title;
						vm.widgetData.confirmationDialogMessage =
							confirmationDepersistDiskDialog.message;
						vm.widgetData.confirmationDialogMessageDetails = '';
						vm.widgetData.confirmationDialogActionName =
							confirmationDepersistDiskDialog.action;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.doUnpersist;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.doUnpersist = function () {
						var currentStatus = $scope.frame.status;
						$scope.persistenceSize = undefined;
						$scope.frame.status = 'PREPARATION';
						$rootScope.disableAjaxLoading = true;
						frameService.unpersist($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.repersist = function () {
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Repersistance disque'
						);
						vm.widgetData.confirmationDialogMessage =
							$scope.confirmationDialogRepersist;
						vm.widgetData.confirmationDialogActionName =
							$scope.confirmDialogRepersist;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.doRepersist;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.doRepersist = function () {
						var currentStatus = $scope.frame.status;
						$scope.frame.status = 'PREPARATION';
						$rootScope.disableAjaxLoading = true;
						$scope.persistenceSize = undefined;
						frameService.repersist($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.doUncache = function () {
						var currentStatus = $scope.frame.status;
						$scope.frame.status = 'PREPARATION';
						$rootScope.disableAjaxLoading = true;
						frameService.uncache($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.recache = function () {
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Repersistance mémoire'
						);
						vm.widgetData.confirmationDialogMessage =
							$scope.confirmationDialogRepersist;
						vm.widgetData.confirmationDialogActionName =
							$scope.confirmDialogRepersist;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.doRecache;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.doRecache = function () {
						var currentStatus = $scope.frame.status;
						$scope.frame.status = 'PREPARATION';
						$rootScope.disableAjaxLoading = true;
						frameService.recache($scope.frame.code).then(function (response) {
							handleOperationResponse(response);
							$rootScope.disableAjaxLoading = false;
						});
					};

					$scope.setFrameStatus = function (data) {
						$scope.frame.job_id = data.job_id;
						$scope.frame.status = data.job_status
							? data.job_status
							: data.status;
						// set grid data cache & persistence date
						vm.persistenceDate = data.persisted ? data.persistence_date : '';
						vm.cacheDate = data.cached ? data.caching_date : '';
						$scope.frame.persisted = data.persisted;
						$scope.frame.cached = data.cached;
						$scope.frame.caching_date = $rootScope.getDateTimeWithPattern(
							data.caching_date
						);
						$scope.frame.persistence_date = $rootScope.getDateTimeWithPattern(
							data.persistence_date
						);
						$scope.frame.caching_execution_time = data.caching_execution_time;
						$scope.frame.persistence_execution_time =
							data.persistence_execution_time;
						$scope.frame.current_repartition_number =
							data.current_repartition_number;
						$scope.frame.current_repartition_columns_count =
							data.current_repartition_columns
								? data.current_repartition_columns.length
								: 0;
						$scope.frame.current_repartition_columns =
							data.current_repartition_columns &&
							Array.isArray(data.current_repartition_columns)
								? data.current_repartition_columns.join(', ')
								: data.current_repartition_columns;
						$scope.persistenceSize = data.persistence_size;
					};

					$scope.getUserFriendlyJobStatus = function (jobStatus) {
						switch (jobStatus) {
							case 'ERROR':
								return gettextCatalog.getString('Erreur');
							case 'CANCELLED':
								return gettextCatalog.getString('Tâche annulée');
							case 'UNKNOWN':
								return gettextCatalog.getString('Inconnu');
							default:
								return jobStatus;
						}
					};

					$scope.loadingSize = false;
					$scope.persistenceSize = undefined;
					$scope.getPersistenceSize = function () {
						$scope.loadingSize = true;
						frameService
							.getPersistenceSize($scope.frame.code)
							.then(function (response) {
								$scope.persistenceSize = response.data;
								$scope.loadingSize = false;
							})
							.catch(function (error) {
								$scope.loadingSize = false;
							});
					};

					function setFrameStatusFromSocket(notification) {
						let isPersistenceJob = notification.type
							.toLowerCase()
							.includes('persist');
						let data = notification.data;
						$scope.frame.status = data.job_status;
						$scope.frame.job_id = data.job_id;
						if ($scope.frame.status == 'SUCCESS') {
							if (isPersistenceJob) {
								$scope.frame.persisted = data.persisted;
								$scope.frame.persistence_date =
									$rootScope.getDateTimeWithPattern(data.persistence_date);
								$scope.frame.persistence_execution_time =
									data.persistence_execution_time;
								$scope.frame.current_repartition_number =
									data.current_repartition_number;
								$scope.frame.current_repartition_columns_count =
									data.current_repartition_columns
										? data.current_repartition_columns.length
										: 0;
								$scope.frame.current_repartition_columns =
									data.current_repartition_columns &&
									Array.isArray(data.current_repartition_columns)
										? data.current_repartition_columns.join(', ')
										: data.current_repartition_columns;
								$scope.persistenceSize = data.persistence_size;
								// set grid data cache & persistence date
								vm.persistenceDate = data.persisted ? data.persistence_date : '';
							} else {
								$scope.frame.status = data.status;
								$scope.frame.cached = data.cached;
								$scope.frame.caching_date = $rootScope.getDateTimeWithPattern(
									data.caching_date
								);
								$scope.frame.caching_execution_time =
									data.caching_execution_time;
								// set grid data cache & persistence date
								vm.cacheDate = data.cached ? data.caching_date : '';
							}
						}
					}

					function handleOperationResponse(data) {
						if (data.hasOwnProperty($scope.frame.code)) {
							$scope.frame.status = data[$scope.frame.code].job_status;
							$scope.frame.job_id = data[$scope.frame.code].value;
						}
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					widgetData: '=',
					persistenceDate: '=',
					cacheDate: '='
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/frameStatus/frameStatus.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.frame = angular.copy(scope.vm.data);
					scope.randomUuid = uuidv4();
					scope.getFrameStatus();
				},
			};
		},
	]);
})();
