var loadPieChart = function (data, title, hideLabel, hideLegend) {
	var series = [];

	series = [
		{
			argumentField: 'valeur',
			valueField: 'count',
			name: 'nombre',
			label: {
				visible: !hideLabel,
				customizeText: function (arg) {
					return arg.argumentText + ' ( ' + arg.percentText + ')';
				},
			},
		},
	];

	var config = {
		dataSource: data,
		scrollingMode: 'all',
		zoomingMode: 'all',
		series: series,
		title: title,
		legend: {
			orientation: 'horizontal',
			visible: !hideLegend,
			verticalAlignment: 'top',
			horizontalAlignment: 'right',
			position: 'outside',
		},
		resolveLabelOverlapping: 'hide',
		tooltip: {
			enabled: true,
			zIndex: 99999999999,
			customizeTooltip: function (obj) {
				return {
					text:
						obj.argumentText +
						' : ' +
						obj.valueText +
						' ( ' +
						obj.percentText +
						')',
				};
			},
		},
		onPointClick: function (e) {
			var point = e.target;
			toggleVisibility(point);
		},
		onLegendClick: function (e) {
			var arg = e.target;
			toggleVisibility(e.component.getAllSeries()[0].getPointsByArg(arg)[0]);
		},
	};
	return config;
};

function toggleVisibility(item) {
	if (item.isVisible()) {
		item.hide();
	} else {
		item.show();
	}
}
