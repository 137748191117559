(function () {
	'use strict';

	angular.module('dcApp').controller('DbExposeController', [
		'$scope',
		'$stateParams',
		'DataBlocksService',
		'$location',
		'toaster',
		'$http',
		'$rootScope',
		'gettextCatalog',
		'$window',
		'LANG_NAME',
		function (
			$scope,
			$stateParams,
			DataBlocksService,
			$location,
			toaster,
			$http,
			$rootScope,
			gettextCatalog,
			$window,
			LANG_NAME
		) {
			// set default lang
			const lang = $window.localStorage.getItem(LANG_NAME) ? $window.localStorage.getItem(LANG_NAME) :  'fr';
			$rootScope.setDefaultLanguage(lang);
			// columns
			$scope.columnsGridOptions = {
				columns: [
					{ caption: gettextCatalog.getString('Nom'), dataField: 'name', width: '30%' },
					{ caption: gettextCatalog.getString('Type'), dataField: 'type', width: '10%' },
					{ caption: gettextCatalog.getString('Description'), dataField: 'description', width: '60%' },
				],
				showBorders: true,
			};
			$scope.apiName = $stateParams.dbName;
			$rootScope.noRedirectionIfUnauthorized = true;
			$scope.dataGridOptions = {
				showBorders: true,
				scrolling: { mode: 'virtual' },
				export: { enabled: true },
				loadPanel: { enabled: true },
			};

			$scope.getExposeInfo = function () {
				delete $scope.data;
				delete $scope.columnsGridOptions.dataSource;
				$http.defaults.headers.common.Authorization = 'Bearer ' + $scope.token;
				DataBlocksService.getExposeInfo($scope.apiName).then(function (
					response
				) {
					$scope.data = response.data;
					$scope.columnsGridOptions.dataSource = $scope.data.columns;
					$scope.query =
						$scope.data.root_url + '/service/data/' + $scope.data.api_name;
				});
			};

			$scope.copyQueryToClipboard = function () {
				copyToClipboard($scope.query);
				toaster.pop('success', gettextCatalog.getString('Copie effectuée !'));
			};

			$scope.execQuery = function () {
				delete $scope.dataGridOptions.dataSource;
				$scope.isLoading = true;
				$http.get($scope.query).then(
					function (response) {
						$scope.dataGridOptions.dataSource = angular.isArray(response.data)
							? response.data
							: [response.data];
						$scope.isLoading = false;
					},
					function (response) {
						$scope.isLoading = false;
					}
				);
			};
		},
	]);
})();
