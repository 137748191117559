(function () {
	angular.module('dcApp').directive('globalPermissions', [
		'$parse',
		function ($parse) {
			let controller = [
				'$log',
				'$scope',
				'$rootScope',
				'PermissionService',
				'toaster',
				'gettextCatalog',
				'GLOBAL_PERMISSION_CODES',
				function (
					$log,
					$scope,
					$rootScope,
					PermissionService,
					toaster,
					gettextCatalog,
					GLOBAL_PERMISSION_CODES
				) {
					var vm = this;

					$scope.init = function () {
						$scope.getGlobalPermissions();
					};

					$scope.global_elements = [
						{
							code: 'connector',
							label: gettextCatalog.getString('Connecteurs'),
						},
						{
							code: 'connector-create',
							label: gettextCatalog.getString('Connecteurs - Création'),
						},
						{ code: 'data_source', label: gettextCatalog.getString('Dépôts') },
						{
							code: 'data_source-create',
							label: gettextCatalog.getString('Dépôts - Création'),
						},
						{
							code: 'entity',
							label: gettextCatalog.getString('Entités Métier'),
						},
						{
							code: 'entity-create',
							label: gettextCatalog.getString('Entités Métier - Création'),
						},
						{ code: 'datablock', label: gettextCatalog.getString('DataBlock') },
						{
							code: 'datablock-create',
							label: gettextCatalog.getString('DataBlock - Création'),
						},
						{
							code: 'value_search',
							label: gettextCatalog.getString('Recherche de valeurs'),
						},
						{
							code: 'datachain_graph',
							label: gettextCatalog.getString('Graphe DataChain'),
						},
						{
							code: 'link_graph',
							label: gettextCatalog.getString('Graphe Liens'),
						},
						{
							code: 'express_integration',
							label: gettextCatalog.getString('Intégration rapide'),
						},
						{
							code: 'datachain_import',
							label: gettextCatalog.getString('Import DataChain'),
						},
						{
							code: 'database_import',
							label: gettextCatalog.getString('Import base de données'),
						},
						{
							code: 'job_monitoring',
							label: gettextCatalog.getString('Supervision des tâches'),
						},
						{ code: 'traces', label: gettextCatalog.getString('Traçabilité') },
						{ code: 'tags', label: gettextCatalog.getString('Tags') },
						{
							code: 'tags-create',
							label: gettextCatalog.getString('Tags - Création'),
						},
						{
							code: 'metadatas',
							label: gettextCatalog.getString('Gestion des Métadonnées'),
						},
						{ code: 'icons', label: gettextCatalog.getString('Pictogrammes') },
						{
							code: 'icons-create',
							label: gettextCatalog.getString('Pictogrammes - Création'),
						},
						{
							code: 'dashboard',
							label: gettextCatalog.getString('Tableaux de Bord'),
						},
						{
							code: 'dashboard-create',
							label: gettextCatalog.getString('Tableaux de Bord - Création'),
						},
						{ code: 'timeline', label: gettextCatalog.getString('TimeLine') },
						{
							code: 'timeline-create',
							label: gettextCatalog.getString('TimeLine - Création'),
						},
						{ code: 'chart', label: gettextCatalog.getString('Graphiques') },
						{
							code: 'chart-create',
							label: gettextCatalog.getString('Graphiques - Création'),
						},
						{ code: 'media', label: gettextCatalog.getString('Médias') },
						{
							code: 'media-create',
							label: gettextCatalog.getString('Médias - Création'),
						},
						{ code: 'grid', label: gettextCatalog.getString('Tableaux') },
						{
							code: 'grid-create',
							label: gettextCatalog.getString('Tableaux - Création'),
						},
						{ code: 'map', label: gettextCatalog.getString('Cartes') },
						{
							code: 'map-create',
							label: gettextCatalog.getString('Cartes - Création'),
						},
						{
							code: 'classement',
							label: gettextCatalog.getString('Classements'),
						},
						{
							code: 'classement-create',
							label: gettextCatalog.getString('Classements - Création'),
						},
						{
							code: 'semantic_context',
							label: gettextCatalog.getString('Règles Sémantiques'),
						},
						{
							code: 'semantic_context-create',
							label: gettextCatalog.getString('Règles Sémantiques - Création'),
						},
						{
							code: 'formula_catalog',
							label: gettextCatalog.getString('Catalogue des Formules'),
						},
						{
							code: 'data_catalog-listing',
							label: gettextCatalog.getString('Référencement au DataCatalogue'),
						},
						{
							code: 'export_template',
							label: gettextCatalog.getString('permissions.global.export.template'),
						},
						{
							code: 'export_template-create',
							label: gettextCatalog.getString('permissions.global.export.template.create'),
						},
					];

					PermissionService.getGlobalPermissionRules().then(function (
						response
					) {
						var globalPermissions = response.data;
						$scope.generic_data_element = [];
						for (let e in globalPermissions.generic_data) {
							let perm = _.filter($scope.global_elements, function (item) {
								return item.code == globalPermissions.generic_data[e];
							});
							if (perm[0]) {
								$scope.generic_data_element.push(perm[0]);
							} else {
								$scope.generic_data_element.push({
									code: globalPermissions.generic_data[e],
									label: globalPermissions.generic_data[e],
								});
							}
						}
						$scope.handle_data_element = [];
						for (let e in globalPermissions.handle_data) {
							let perm = _.filter($scope.global_elements, function (item) {
								return item.code == globalPermissions.handle_data[e];
							});
							if (perm[0]) {
								$scope.handle_data_element.push(perm[0]);
							} else {
								$scope.handle_data_element.push({
									code: globalPermissions.handle_data[e],
									label: globalPermissions.handle_data[e],
								});
							}
						}
					});

					$scope.changeGlobalPermission = function (item) {
						saveGlobalPermission({
							permission: item.code,
							global: true,
							permitted: item.permitted,
						});
					};

					$scope.changeProjectExportPermission = function () {
						saveGlobalPermission({
							permission: GLOBAL_PERMISSION_CODES.project_export,
							global: true,
							permitted: $scope.hasProjectExportPermission,
						});
					};

					$scope.changeProjectImportPermission = function () {
						saveGlobalPermission({
							permission: GLOBAL_PERMISSION_CODES.project_import,
							global: true,
							permitted: $scope.hasProjectImportPermission,
						});
					};

					$scope.changeCreatePublicProjectPermission = function () {
						saveGlobalPermission({
							permission: GLOBAL_PERMISSION_CODES.public_project_create,
							global: true,
							permitted: $scope.hasCreatePublicProject,
						});
					};

					var saveGlobalPermission = function (permission) {
						let update;
						if (vm.userId) {
							permission.user_id = vm.userId;
							update = PermissionService.updateUserGlobalPermission(permission);
						} else {
							permission.group_id = vm.groupId;
							update =
								PermissionService.updateGroupGlobalPermission(permission);
						}
						update.then(function (response) {
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								gettextCatalog.getString(
									'Enregistrement des permissions effectué'
								)
							);
						});
					};

					var saveManyGlobalPermissions = function (permission) {
						let update;
						if (vm.userId) {
							permission.user_id = vm.userId;
							update =
								PermissionService.updateUserManyGlobalPermissions(permission);
						} else {
							permission.group_id = vm.groupId;
							update =
								PermissionService.updateGroupManyGlobalPermissions(permission);
						}
						update.then(function (response) {
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								gettextCatalog.getString(
									'Enregistrement des permissions effectué'
								)
							);
						});
					};

					$scope.getGlobalPermissions = function () {
						$scope.allGenericData = true;
						$scope.allHandleData = true;
						let getPermissions;
						if (vm.userId) {
							getPermissions = PermissionService.getUserGlobalPermissions(
								vm.userId
							);
						} else {
							getPermissions = PermissionService.getGroupGlobalPermissions(
								vm.groupId
							);
						}
						getPermissions.then(function (response) {
							$scope.globalPermissions = response.data;
							for (let perm in $scope.generic_data_element) {
								let permission = _.filter(response.data, function (item) {
									return (
										item.permission == $scope.generic_data_element[perm].code
									);
								})[0];
								if (permission) {
									$scope.generic_data_element[perm].permitted =
										permission.permitted;
								} else {
									$log.debug(
										'not exists ' + $scope.generic_data_element[perm].code
									);
								}
								if (!$scope.generic_data_element[perm].permitted) {
									$scope.allGenericData = false;
								}
							}

							for (let perm in $scope.handle_data_element) {
								let permission = _.filter(response.data, function (item) {
									return (
										item.permission == $scope.handle_data_element[perm].code
									);
								})[0];
								if (permission) {
									$scope.handle_data_element[perm].permitted =
										permission.permitted;
								} else {
									$log.debug(
										'not exists ' + $scope.handle_data_element[perm].code
									);
								}
								if (!$scope.handle_data_element[perm].permitted) {
									$scope.allHandleData = false;
								}
							}
							let projectExportPermission = getPermissionByCode(
								GLOBAL_PERMISSION_CODES.project_export,
								$scope.globalPermissions
							);
							$scope.hasProjectExportPermission = projectExportPermission
								? projectExportPermission.permitted
								: false;

							let projectImportPermission = getPermissionByCode(
								GLOBAL_PERMISSION_CODES.project_import,
								$scope.globalPermissions
							);
							$scope.hasProjectImportPermission = projectImportPermission
								? projectImportPermission.permitted
								: false;
							let publicProjectCreatePermission = getPermissionByCode(
								GLOBAL_PERMISSION_CODES.public_project_create,
								$scope.globalPermissions
							);
							$scope.hasCreatePublicProject = publicProjectCreatePermission
								? publicProjectCreatePermission.permitted
								: false;
						});
					};

					$scope.changeAllGenericData = function () {
						let permissions = [];
						for (let perm in $scope.generic_data_element) {
							if (
								$scope.generic_data_element[perm].permitted !=
								$scope.allGenericData
							) {
								$scope.generic_data_element[perm].permitted =
									$scope.allGenericData;
								permissions.push($scope.generic_data_element[perm].code);
							}
						}
						saveManyGlobalPermissions({
							permissions: permissions,
							global: true,
							permitted: $scope.allGenericData,
						});
					};

					$scope.changeAllHandleData = function () {
						let permissions = [];
						for (let perm in $scope.handle_data_element) {
							if (
								$scope.handle_data_element[perm].permitted !=
								$scope.allHandleData
							) {
								$scope.handle_data_element[perm].permitted =
									$scope.allHandleData;
								permissions.push($scope.handle_data_element[perm].code);
							}
						}
						saveManyGlobalPermissions({
							permissions: permissions,
							global: true,
							permitted: $scope.allHandleData,
						});
					};

					var getPermissionByCode = function (code, permissions) {
						return _.filter($scope.globalPermissions, function (item) {
							return item.permission == code;
						})[0];
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					userId: '=',
					groupId: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl:
					'./src/components/directives/permission/global/globalPermissions.html',
				link: function (scope, element, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
