(function () {
	'use strict';

	angular.module('dcApp').factory('ProjectStatAdapter', [
		'$rootScope',
		'$timeout',
		'ELEMENT',
		'filterValues',
		'gettextCatalog',
		function ($rootScope, $timeout, ELEMENT, filterValues, gettextCatalog) {
			const ProjectStatAdapter = {};
			ProjectStatAdapter.getAll = getAll;
			ProjectStatAdapter.generateTraces = generateTraces;

			function getAll(gdStatsRes, hdStatsRes, projectDataRes) {
				$timeout(function () {
					let projectLink =
						projectDataRes.project_links && projectDataRes.project_links[0]
							? projectDataRes.project_links[0].link_url
							: '';
					$rootScope.$broadcast('project:link-update', projectLink);
				}, 2000);
				return {
					gd: {
						connector: {
							count: gdStatsRes.connector || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.connector
							),
						},
						repository: {
							count: gdStatsRes.repository || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.repository
							),
						},
						entity: {
							count: gdStatsRes.entity || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.entity),
						},
						datablock: {
							count: gdStatsRes.datablock || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.datablock
							),
						},
					},
					hd: {
						map: {
							count: hdStatsRes.map || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.map),
						},
						chart: {
							count: hdStatsRes.chart || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.chart),
						},
						grid: {
							count: hdStatsRes.grid || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.grid),
						},
						media: {
							count: hdStatsRes.media || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.media),
						},
						timeline: {
							count: hdStatsRes.timeline || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.timeline
							),
						},
						dashboard: {
							count: hdStatsRes.dashboard || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.dashboard
							),
						},
					},
					projectStats: {
						title: projectDataRes.metadata.label,
						description: projectDataRes.metadata.description,
						tags: projectDataRes.metadata.tags,
						visibility: projectDataRes.is_public,
						admin: projectDataRes.admin,
					},

					actionsFilterDataSource: {
						datablock: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
							{
								code: filterValues.persist,
								label: gettextCatalog.getString('projectStat.persist'),
							},
							{
								code: filterValues.cache,
								label: gettextCatalog.getString('projectStat.cache'),
							},
							{
								code: filterValues.uncache,
								label: gettextCatalog.getString('projectStat.uncache'),
							},
							{
								code: filterValues.unpersist,
								label: gettextCatalog.getString('projectStat.unpersist'),
							},
							{
								code: filterValues.expose,
								label: gettextCatalog.getString('projectStat.expose'),
							},
							{
								code: filterValues.export,
								label: gettextCatalog.getString('projectStat.export'),
							},
							{
								code: filterValues.recache,
								label: gettextCatalog.getString('projectStat.recache'),
							},
							{
								code: filterValues.repersist,
								label: gettextCatalog.getString('projectStat.repersist'),
							},
						],
						connector: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],
						entity: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
							{
								code: filterValues.persist,
								label: gettextCatalog.getString('projectStat.persist'),
							},
							{
								code: filterValues.cache,
								label: gettextCatalog.getString('projectStat.cache'),
							},
							{
								code: filterValues.uncache,
								label: gettextCatalog.getString('projectStat.uncache'),
							},
							{
								code: filterValues.unpersist,
								label: gettextCatalog.getString('projectStat.unpersist'),
							},
							{
								code: filterValues.recache,
								label: gettextCatalog.getString('projectStat.recache'),
							},
							{
								code: filterValues.repersist,
								label: gettextCatalog.getString('projectStat.repersist'),
							},
						],

						repository: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
							{
								code: filterValues.extract,
								label: gettextCatalog.getString('projectStat.extract'),
							},
						],

						dashboard: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
							{
								code: filterValues.publication,
								label: gettextCatalog.getString('projectStat.publication'),
							},
						],

						map: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],

						chart: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],

						grid: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],

						media: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],

						timeline: [
							{
								code: ELEMENT.actions.edit,
								label: gettextCatalog.getString('projectStat.edit'),
							},
							{
								code: ELEMENT.actions.create,
								label: gettextCatalog.getString('projectStat.create'),
							},
							{
								code: ELEMENT.actions.deleteElem,
								label: gettextCatalog.getString('projectStat.delete'),
							},
						],
					},
				};
			}

			function getTimeDifference(elementDate) {
				var end = moment(elementDate).format();
				var now = moment(new Date()); //todays date
				var duration = moment.duration(now.diff(end));
				return duration.asDays();
			}

			function generateTraces(response) {
				let elements = [];
				let element = {};
				for (const elem of response) {
					element = {
						action: elem.action,
						date: elem.date,
						elementId: elem.element_id,
						elementLib: elem.element_lib,
						subType: elem.subtype,
						userId: elem.user_id,
						timeDifference: getTimeDifference(elem.date),
					};
					elements.push(element);
				}
				return elements;
			}
			return ProjectStatAdapter;
		},
	]);
})();
