(function () {
	'use strict';

	angular.module('dcApp').factory('PictogrammeService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var PictogrammeService = {};

			PictogrammeService.getPictogramme = function (id) {
				return $http
					.get(API_BASE_URL_BACKEND + 'pictogrammes/' + id)
					.then(function (response) {
						return response;
					});
			};

			PictogrammeService.getElementsPictogrammes = function () {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ELEMENTS_PICTOGRAMMES');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PictogrammeService.getPictogrammesElements = function () {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PICTOGRAMMES_ELEMENTS');
				$http.get(url).then(
					function (data) {
						deferred.resolve(data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			PictogrammeService.getPictogrammes = function (metadataId) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PICTOGRAMMES') +
					(metadataId !== undefined ? '?metadataId=' + metadataId : '');
				$http.get(url).then(
					function (data) {
						deferred.resolve(data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			PictogrammeService.getHdhTypesElements = function (obj) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ALL_ELEMENTS_HDH');
				$http.post(url, obj).then(
					function (data) {
						deferred.resolve(data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			PictogrammeService.createPictogramme = function (picto) {
				var fd = new FormData();
				fd.append('lib', picto.lib);
				fd.append('file', picto.file);
				return $http.post(
					API_BASE_URL_BACKEND + $rootScope.getUrl('PICTOGRAMMES_CREATE'),
					fd,
					{
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					}
				);
			};

			PictogrammeService.deletePictogramme = function (id) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PICTOGRAMME_DELETE');
				url = url.replace('{id}', id);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			PictogrammeService.saveAttachedPictoToElement = function (elementIcon) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('ATTACH_PICTOGRAMME_TO_ELEMENT');
				$http.post(url, elementIcon).then(
					function (data) {
						deferred.resolve(data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			PictogrammeService.saveAttachedElementsToPicto = function (iconElements) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('ATTACH_ELEMENTS_TO_PICTOGRAMME');
				$http.post(url, iconElements).then(
					function (data) {
						deferred.resolve(data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			PictogrammeService.getPictogrammeByMetatDataId = function (id) {
				var url = API_BASE_URL_BACKEND + '/metadata/icon_id';
				url = id !== undefined ? url + '?metadataId=' + id : url;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return PictogrammeService;
		},
	]);
})();
