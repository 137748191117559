(function () {
	'use strict';

	angular.module('dcApp').service('UrlService', UrlService);

	UrlService.$inject = ['API_BASE_URL_BACKEND', 'InitializationService'];

	// FIXME: extract usage with back-end base url token or use typescipe equivalent service
	function UrlService(API_BASE_URL_BACKEND, InitializationService) {
		this.generateUrl = function (code, pathVariables = {}, queryParams = {}) {
			let _url = `${API_BASE_URL_BACKEND}/${
				InitializationService.getAvailablePaths()[code]
			}`;
			_url = replacePathVariables(_url, pathVariables);
			_url = generateWithQueryParams(_url, queryParams);
			return _url.replace(/(\w+)\/{2,}/, '$1/');
		};

		function replacePathVariables(url, variables) {
			return Object.keys(variables).reduce(
				(fullUrl, variableKey) =>
					fullUrl.replace(
						`{${variableKey}}`,
						parseValue(variables[variableKey])
					),
				url
			);
		}

		function generateWithQueryParams(url, params) {
			const trailingParams = Object.keys(params).reduce((acc, param) => {
				const currentParam = params[param];
				if (currentParam === null || currentParam === undefined) {
					return acc;
				}
				if (acc === '') {
					return `${acc}?${param}=${parseQueryParam(currentParam)}`;
				}
				return `${acc}&${param}=${parseQueryParam(currentParam)}`;
			}, '');
			return `${url}${trailingParams}`;
		}

		function parseValue(val) {
			if (val === null || val === undefined) {
				return '';
			}
			if (typeof val === 'number') {
				return val.toString();
			}

			if (typeof val === 'boolean') {
				return val ? 'true' : 'false';
			}
			return val;
		}

		function parseQueryParam(param) {
			if (Array.isArray(param)) {
				return param.reduce((acc, curr) => {
					if (acc === '') {
						return `${curr}`;
					}
					return `${acc},${curr}`;
				}, '');
			}
			return parseValue(param);
		}
	}
})();
