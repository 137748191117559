(function () {
	'use strict';

	angular.module('dcApp').directive('agregation', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'NUMBER_PATTERNS',
				'COUNTING_FUNCTION_OPERATORS',
				'AGGREGATION_TYPE',
				'toaster',
				'$sce',
				'DataBlocksService',
				'gettextCatalog',
				'GrammarUtils',
				function (
					$scope,
					$rootScope,
					NUMBER_PATTERNS,
					COUNTING_FUNCTION_OPERATORS,
					AGGREGATION_TYPE,
					toaster,
					$sce,
					DataBlocksService,
					gettextCatalog,
					GrammarUtils
				) {
					var vm = this;
					vm.AggregationType = AGGREGATION_TYPE;

					$scope.widgetData = {};

					$scope.getHtmlIconByType = function (type, isList) {
						if (type !== undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList));
						}
					};

					//Help
					$scope.helpTitleTra = gettextCatalog.getString(
						'Aide Agrégations et Pivot'
					);

					let ascTra = gettextCatalog.getString('Ascendant');
					let descTra = gettextCatalog.getString('Descendant');
					let yesTra = gettextCatalog.getString('Oui');
					let noTra = gettextCatalog.getString('Non');

					let functionsTrans = [
						{ code: 'COUNT', label: gettextCatalog.getString('Nombre total') },
						{
							code: 'COUNT_DISTINCT',
							label: gettextCatalog.getString('Nombre (uniques)'),
						},
						{
							code: 'COUNT_DISTINCT_APPROX',
							label: gettextCatalog.getString(
								'aggregation.column-operation.count-distinct-approx'
							),
						},
						{ code: 'LAG', label: gettextCatalog.getString('Précédent') },
						{ code: 'LEAD', label: gettextCatalog.getString('Suivant') },
						{ code: 'SUM', label: gettextCatalog.getString('Somme') },
						{ code: 'AVG', label: gettextCatalog.getString('Moyenne') },
						{ code: 'MIN', label: gettextCatalog.getString('Minimum') },
						{ code: 'MAX', label: gettextCatalog.getString('Maximum') },
						{ code: 'MEDIAN', label: gettextCatalog.getString('Médiane') },
						{ code: 'FIRST', label: gettextCatalog.getString('Premier') },
						{ code: 'LAST', label: gettextCatalog.getString('Dernier') },
						{
							code: 'FIRST_VALUE',
							label: gettextCatalog.getString('Première valeur'),
						},
						{
							code: 'LAST_VALUE',
							label: gettextCatalog.getString('Dernière valeur'),
						},
						{
							code: 'CONCAT',
							label: gettextCatalog.getString('Concaténation'),
						},
						{
							code: 'COLLECT_LIST',
							label: gettextCatalog.getString('Collecter en liste'),
						},
						{
							code: 'COLLECT_SET',
							label: gettextCatalog.getString('Collecter en ensemble'),
						},
						{ code: 'STDDEV_POP', label: gettextCatalog.getString('Ecart Type') },
						{ code: 'VARIANCE', label: gettextCatalog.getString('Variance') }
					];

					let globalFunctionsTrans = [
						{ code: 'COUNT', label: gettextCatalog.getString('Nombre total') },
						{ code: 'CUME_DIST', label: gettextCatalog.getString('CUME_DIST') },
						{
							code: 'DENSE_RANK',
							label: gettextCatalog.getString('DENSE_RANK'),
						},
						{ code: 'RANK', label: gettextCatalog.getString('RANK') },
						{
							code: 'ROW_NUMBER',
							label: gettextCatalog.getString('ROW_NUMBER'),
						},
						{
							code: 'PERCENT_RANK',
							label: gettextCatalog.getString('PERCENT_RANK'),
						},
						{ code: 'NTILE', label: gettextCatalog.getString('NTILE') },
					];

					$scope.trieModes = [
						{
							code: 'ASC',
							label: ascTra,
						},
						{
							code: 'DESC',
							label: descTra,
						},
					];

					$scope.ignoreNullOptions = [
						{
							code: true,
							label: yesTra,
						},
						{
							code: false,
							label: noTra,
						},
					];

					$scope.functionOptions = functionsTrans;

					$scope.numberPatterns = NUMBER_PATTERNS;
					$scope.countingFunctionOperations = COUNTING_FUNCTION_OPERATORS;
					$scope.data = {};
					$scope.attributs = [];

					// Translate start
					let firstRowOfGroupTra = gettextCatalog.getString(
						'La première ligne du groupe'
					);
					let lastRowOfGroupTra = gettextCatalog.getString(
						'La dernière ligne du groupe'
					);
					let currentRowTra = gettextCatalog.getString('La ligne en cours');
					let previousRowTra = gettextCatalog.getString(
						"L'énième ligne précédente"
					);
					let nextRowTra = gettextCatalog.getString("L'énième ligne suivante");
					let allPreviousRowsTra = gettextCatalog.getString(
						'La dernière valeur non nulle'
					);
					let allNextRowsTra = gettextCatalog.getString(
						'La prochaine valeur non nulle'
					);
					let microSecTra = gettextCatalog.getString('Microseconde');
					let milSecTra = gettextCatalog.getString('Milliseconde');
					let secTra = gettextCatalog.getString('Seconde');
					let minTra = gettextCatalog.getString('Minute');
					let hourTra = gettextCatalog.getString('Heure');
					let dayTra = gettextCatalog.getString('Jour');
					let weekTra = gettextCatalog.getString('Semaine');
					// Translate end
					$scope.partitionRowStart = [
						{ code: '', label: '' },
						{ code: 'UNBOUNDED PRECEDING', label: firstRowOfGroupTra },
						{ code: 'CURRENT ROW', label: currentRowTra },
						{ code: 'PRECEDING', label: previousRowTra },
						{ code: 'FOLLOWING', label: nextRowTra },
						{ code: 'INFINITY PRECEDING', label: allPreviousRowsTra },
						{ code: 'INFINITY FOLLOWING', label: allNextRowsTra },
					];
					$scope.partitionRowEnd = [
						{ code: '', label: '' },
						{ code: 'UNBOUNDED FOLLOWING', label: lastRowOfGroupTra },
						{ code: 'CURRENT ROW', label: currentRowTra },
						{ code: 'PRECEDING', label: previousRowTra },
						{ code: 'FOLLOWING', label: nextRowTra },
						{ code: 'INFINITY PRECEDING', label: allPreviousRowsTra },
						{ code: 'INFINITY FOLLOWING', label: allNextRowsTra },
					];
					$scope.timeIntervals = [
						{ label: '' },
						{ code: 'microseconds', label: microSecTra },
						{ code: 'milliseconds', label: milSecTra },
						{ code: 'seconds', label: secTra },
						{ code: 'minutes', label: minTra },
						{ code: 'hours', label: hourTra },
						{ code: 'days', label: dayTra },
						{ code: 'weeks', label: weekTra },
					];

					$scope.unauthorizedPivotTypes = ['words', 'file', 'binary'];

					$scope.deleteAggregations = function () {
						vm.data.widgetData.showConfirmationDialog = true;
						vm.data.widgetData.confirmationDialogTitle =
							gettextCatalog.getString('Confirmer la suppression');
						vm.data.widgetData.confirmationDialogMessage =
							gettextCatalog.getString(
								'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
							);
						vm.data.widgetData.confirmationDialogActionName = yesTra;
						vm.data.widgetData.enableConfirmDialogAction = true;
						vm.data.widgetData.confirmDialogAction = function () {
							confirmDeleteAggregations();
						};
					};

					var confirmDeleteAggregations = function () {
						$scope.agregationType = undefined;
						clearAggregation();
						$scope.save();
					};

					$scope.changeAgregationType = function () {
						clearAggregation();
					};

					var clearAggregation = function () {
						$scope.globalFunctions = angular.copy(globalFunctionsTrans);
						$scope.groups = [];
						$scope.pivots = [];
						$scope.pivotValues = [];
						$scope.functions = [];
						$scope.sorts = [];
						delete $scope.partition;
					};

					$scope.init = function () {
						$scope.globalFunctions = angular.copy(globalFunctionsTrans);
						$scope.partitions = [];
						$scope.groups = [];
						$scope.pivots = [];
						$scope.pivotValues = [];
						$scope.functions = [];
						$scope.sorts = [];
						$scope.agregationType = AGGREGATION_TYPE.simple;
						delete $scope.partition;
						if (!vm.data.step.agregation) {
							vm.data.step.agregation = {};
						}
						if (vm.data.step.agregation.multi_dimensional) {
							$scope.agregationType = AGGREGATION_TYPE.multiDimensional;
						} else if (vm.data.step.agregation.with_details) {
							$scope.agregationType = AGGREGATION_TYPE.columnDetails;
						} else if (vm.data.step.agregation.pivot) {
							$scope.agregationType = AGGREGATION_TYPE.pivot;
						}

						if (!vm.data.step.agregation.with_details) {
							$scope.groups = getGroups(vm.data.step.agregation.groups);
							$scope.functions = getFunctions(
								vm.data.step.agregation.functions
							);
							if (vm.data.step.agregation.pivot) {
								$scope.pivots = getPivots(vm.data.step.agregation.pivot_column);
								$scope.pivotValues = vm.data.step.agregation.pivot_values;
							}
						} else {
							$scope.partitions = angular.copy(
								vm.data.step.agregation.partitions
							);
							for (var p in $scope.partitions) {
								$scope.partitions[p].groups = getGroups(
									$scope.partitions[p].groups
								);
								$scope.partitions[p].functions = getFunctions(
									$scope.partitions[p].functions
								);
								$scope.partitions[p].sorts = getSorts(
									$scope.partitions[p].sorts
								);
							}
							if ($scope.partitions[0]) {
								$scope.selectPartition(
									$scope.partitions[$scope.partitions.length - 1]
								);
							}
						}
					};

					var getGroups = function (groups) {
						if (!groups) {
							return [];
						}
						var gs = [];
						for (var g in groups) {
							var group = angular.copy(
								findElement(
									$scope.attributs,
									'column_uuid',
									groups[g].column_uuid
								)
							);
							group.uuid = groups[g].uuid;
							group.interval = groups[g].interval;
							group.interval_unit = groups[g].interval_unit;
							group.step = groups[g].step;
							group.step_unit = groups[g].step_unit;
							group.time_lag = groups[g].time_lag;
							group.time_lag_unit = groups[g].time_lag_unit;
							gs.push(group);
						}
						return gs;
					};

					var getPivots = function (pivot) {
						if (!pivot) {
							return [];
						}
						var gs = [];
						pivot = angular.copy(
							findElement($scope.attributs, 'column_uuid', pivot)
						);
						gs.push(pivot);
						return gs;
					};

					var getFunctions = function (functions) {
						if (!functions) {
							return [];
						}
						var fs = [];
						for (var g in functions) {
							if (
								$scope.agregationType !== AGGREGATION_TYPE.columnDetails &&
								functions[g].global
							) {
								let func = _.find($scope.globalFunctions, function (item) {
									return item.code == functions[g].func;
								});
								func.selected = true;
								func.ntile = functions[g].ntile;
							}
							var func = angular.copy(
								findElement(
									$scope.attributs,
									'column_uuid',
									functions[g].column_uuid
								)
							);
							func.func = functions[g].func;
							func.uuid = functions[g].uuid;
							func.offset = functions[g].offset;
							func.default_value = functions[g].default_value;
							func.global = functions[g].global;
							func.ntile = functions[g].ntile;
							func.ignore_null = functions[g].ignore_null;
							fs.push(func);
						}
						return fs;
					};

					var getSorts = function (sorts) {
						if (!sorts) {
							return [];
						}
						var ss = [];
						for (var g in sorts) {
							var sort = angular.copy(
								findElement(
									$scope.attributs,
									'column_uuid',
									sorts[g].column_uuid
								)
							);
							sort.order = sorts[g].order;
							ss.push(sort);
						}
						return ss;
					};

					$scope.dropCallbackGroups = function (event, index, item) {
						return item;
					};

					$scope.dropCallbackPivots = function (event, index, item) {
						if ($scope.pivots[0]) {
							return false;
						}
						return item;
					};

					$scope.dropCallbackFunctions = function (event, index, item) {
						item.func = functionsTrans[0].code;
						return item;
					};

					$scope.dropCallbackSorts = function (event, index, item) {
						item.order = $scope.trieModes[0].code;
						return item;
					};

					$scope.confirmMoveAllColumns = function () {
						$scope.groups = [];
						$scope.groups = angular.copy($scope.attributs);
						if ($scope.agregationType === AGGREGATION_TYPE.columnDetails) {
							$scope.partition.groups = angular.copy($scope.attributs);
						}
					};

					$scope.moveAllColumns = function () {
						vm.data.widgetData.showConfirmationDialog = true;
						vm.data.widgetData.confirmationDialogTitle =
							gettextCatalog.getString('Déplacer les colonnes');
						vm.data.widgetData.confirmationDialogMessage =
							gettextCatalog.getString(
								"Toutes les colonnes seront intégrées dans la zone regroupement. Confirmez vous l'action ?"
							);
						vm.data.widgetData.confirmationDialogActionName = yesTra;
						vm.data.widgetData.enableConfirmDialogAction = true;
						vm.data.widgetData.confirmDialogAction = function () {
							$scope.confirmMoveAllColumns();
						};
					};

					$scope.moveCallbackGroups = function (index) {
						$scope.groups.splice(index, 1);
					};

					$scope.moveCallbackPivots = function (index) {
						$scope.pivots.splice(index, 1);
					};

					$scope.moveCallbackFunctions = function (index) {
						$scope.functions.splice(index, 1);
					};

					$scope.moveCallbackSorts = function (index) {
						$scope.sorts.splice(index, 1);
					};

					$scope.moveCallbackPivotValues = function (index) {
						$scope.pivotValues.splice(index, 1);
					};

					$scope.addPartition = function () {
						let partition = {
							groups: [],
							functions: [],
							sorts: [],
							lib:
								gettextCatalog.getString('Groupe') +
								' ' +
								($scope.partitions.length + 1),
							row_start_value: 1,
							row_end_value: 1,
						};
						$scope.partitions.push(partition);
						$scope.selectPartition(partition);
					};

					$scope.selectPartition = function (partition) {
						$scope.globalFunctions = angular.copy(globalFunctionsTrans);
						$scope.partition = partition;
						$scope.functions = partition.functions;
						for (var g in $scope.functions) {
							if ($scope.functions[g].global) {
								let func = _.find($scope.globalFunctions, function (item) {
									return item.code == $scope.functions[g].func;
								});
								func.selected = true;
								func.ntile = $scope.functions[g].ntile;
							}
						}

						$scope.groups = partition.groups;
						$scope.sorts = partition.sorts;
					};

					$scope.deletePartition = function (partition) {
						if (
							$scope.partitions.indexOf(partition) ==
							$scope.partitions.indexOf($scope.partition)
						) {
							delete $scope.partition;
						}
						$scope.partitions.splice($scope.partitions.indexOf(partition), 1);
					};

					$scope.addGlobalFunction = function (code) {
						if ($scope.agregationType === AGGREGATION_TYPE.columnDetails) {
							$scope.partition.functions.push({ func: code, global: true });
						} else {
							$scope.functions.push({ func: code, global: true });
						}
					};

					$scope.removeGlobalFunction = function (code) {
						let functions = $scope.functions;
						if ($scope.agregationType === AGGREGATION_TYPE.columnDetails) {
							functions = $scope.partition.functions;
						}
						let index = _.findIndex(functions, function (elem) {
							return elem && elem.global && elem.func === code;
						});
						functions.splice(index, 1);
					};

					$scope.changeGlobalFunction = function (selected, code) {
						if (selected) {
							$scope.addGlobalFunction(code);
						} else {
							$scope.removeGlobalFunction(code);
						}
					};

					$scope.changeNtileValue = function (ntile) {
						let func = _.find($scope.functions, function (item) {
							return item.global && item.func === 'NTILE';
						});
						func.ntile = ntile;
					};

					$scope.setAttributes = function () {
						$scope.attributs = [];
						for (var i = 0; i < vm.data.columns.length; i++) {
							var obj = {};
							obj.id = i;
							obj.column_uuid = vm.data.columns[i].uuid;
							obj.name = vm.data.columns[i].lib;
							obj.unauthorized = vm.data.columns[i].unauthorized;
							obj.type = vm.data.columns[i].type;
							obj.is_list = vm.data.columns[i].is_list;
							$scope.attributs.push(obj);
						}
					};

					$scope.getDateTimeWithPattern = function (newDate) {
						return $rootScope.getDateTimeWithPattern(newDate);
					};

					$scope.functionSelectionInit = function (column) {
						if (!column.eligibleFunctions) {
							column.eligibleFunctions = functionsTrans
								.filter((fn) =>
									GrammarUtils.isFunctionAllowedForAggregationType(
										fn,
										$scope.agregationType
									)
								)
								.filter((fn) =>
									GrammarUtils.isFunctionAllowedForType(fn, column.type)
								);
						}
						if (column.func) {
							return column.func;
						}
						return column.eligibleFunctions[0].code;
					};

					$scope.searchInAttributs = undefined;
					$scope.filterAttributs = function () {
						if (
							$scope.searchInAttributs == undefined ||
							$scope.searchInAttributs == ''
						) {
							$scope.filteredAttributs = $scope.attributs;
						} else {
							$scope.filteredAttributs = _.filter(
								$scope.attributs,
								function (el) {
									return (
										el.name
											.toLowerCase()
											.indexOf($scope.searchInAttributs.toLowerCase()) > -1
									);
								}
							);
						}
					};

					$scope.attributsSort = 0;
					$scope.sortFilteredAttributs = function () {
						if (
							$scope.attributsSort == undefined ||
							$scope.attributsSort == -1
						) {
							$scope.attributsSort = 0;
							$scope.filteredAttributs = _.sortBy(
								$scope.filteredAttributs,
								function (item) {
									return item.name.trim().toUpperCase();
								}
							);
						} else if ($scope.attributsSort == 1) {
							$scope.attributsSort = -1;
							$scope.filteredAttributs = _.sortBy(
								$scope.filteredAttributs,
								function (item) {
									return item.name.trim().toUpperCase();
								}
							).reverse();
						} else {
							$scope.filteredAttributs = $scope.attributs;
							$scope.attributsSort = 1;
						}
					};

					$scope.addPivotValue = function () {
						$scope.pivotValues.push({ value: '' });
					};

					$scope.removePivotValue = function (index) {
						$scope.pivotValues.splice(index, 1);
					};

					$scope.saveSelectedColumnValues = function (values) {
						var newValues = [];
						for (var d in values) {
							var pv = { value: values[d] };

							for (var p in $scope.pivotValues) {
								if (pv.value == $scope.pivotValues[p].value) {
									pv.uuid = $scope.pivotValues[p].uuid;
									break;
								}
							}
							newValues.push(pv);
						}
						$scope.pivotValues = newValues;
					};

					$scope.getColumnValues = function () {
						vm.data.showSelectColumnValues = true;
						vm.data.selectColumnValuesData = {
							column_lib: $scope.pivots[0].name,
							column_type: $scope.pivots[0].type,
							column_uuid: $scope.pivots[0].column_uuid,
							type: $scope.pivots[0].column_type,
							sort: 'ASC',
							take: 150,
							grammar: {
								columns: vm.data.datablock.grammar.columns,
								steps: vm.data.currentSteps,
							},
						};
						vm.data.saveSelectedColumnValues = $scope.saveSelectedColumnValues;
						return;
					};

					$scope.sortableOptions = {
						cursor: 'move',
						update: function (e, ui) {},
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showBDAgregation = false;
					};

					let invalidConfTra = gettextCatalog.getString(
						'Configuration invalide'
					);
					$scope.save = function () {
						if ($scope.agregationType === AGGREGATION_TYPE.pivot) {
							if (!validatePivotAgregation()) {
								return;
							}
						} else if (
							$scope.agregationType === AGGREGATION_TYPE.columnDetails
						) {
							if (!validatePartitionAgregation()) {
								return;
							}
						} else if ($scope.agregationType === AGGREGATION_TYPE.simple) {
							if (!validateSimpleAgregation()) {
								return;
							}
						} else if (
							$scope.agregationType === AGGREGATION_TYPE.multiDimensional
						) {
							if (!$scope.groups[0]) {
								toaster.pop(
									'error',
									invalidConfTra,
									gettextCatalog.getString(
										'Veuillez ajouter des colonnes du regroupement / fonctions'
									)
								);
								return;
							}
						}
						if (
							($scope.agregationType === AGGREGATION_TYPE.multiDimensional ||
								$scope.agregationType === AGGREGATION_TYPE.simple ||
								$scope.agregationType === AGGREGATION_TYPE.pivot) &&
							(!validateAgregationFunctions($scope.functions) ||
								!validateAgregationGroups($scope.groups))
						) {
							return;
						}

						let agregation = getAgregation(
							$scope.groups,
							$scope.functions,
							$scope.partitions,
							$scope.agregationType,
							$scope.pivots,
							$scope.pivotValues
						);
						vm.data.saveAgregationMethod(agregation, $scope.saveAndClose);
					};

					var getAgregation = function (
						groups,
						functions,
						partitions,
						agregationType,
						pivotColumn,
						pivotValues
					) {
						var agregation = {};
						agregationType = Number(agregationType);
						agregation.multi_dimensional = false;
						agregation.with_details = false;
						if (agregationType == 0) {
							agregation.multi_dimensional = true;
						} else if (agregationType == 1) {
							agregation.with_details = true;
						} else if (agregationType == 3) {
							agregation.pivot = true;
						}
						if (agregationType !== 1) {
							for (var g in groups) {
								groups[g].uuid = groups[g].uuid
									? groups[g].uuid
									: generateUuid('_');
							}
							for (var f in functions) {
								functions[f].uuid = functions[f].uuid
									? functions[f].uuid
									: generateUuid('_');
							}
							agregation.groups = groups;
							agregation.functions = functions;
							if (agregationType == 3) {
								if (pivotColumn && pivotColumn[0]) {
									agregation.pivot_column = pivotColumn[0].column_uuid;
								}
								for (var pv in pivotValues) {
									pivotValues[pv].uuid = pivotValues[pv].uuid
										? pivotValues[pv].uuid
										: generateUuid('_');
								}
								agregation.pivot_values = pivotValues;
							}
						} else {
							agregation.partitions = partitions;
							delete agregation.groups;
							delete agregation.functions;
							for (var p in partitions) {
								groups = partitions[p].groups;
								functions = partitions[p].functions;
								for (let g in groups) {
									groups[g].uuid = groups[g].uuid
										? groups[g].uuid
										: generateUuid('_');
								}
								for (let j in functions) {
									functions[j].uuid = functions[j].uuid
										? functions[j].uuid
										: generateUuid('_');
								}
							}
						}

						return agregation;
					};

					var validateSimpleAgregation = function () {
						if (!$scope.functions[0] && !$scope.groups[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									"Configurer l'agrégation avant de poursuivre"
								)
							);
							return false;
						}
						return true;
					};

					var validatePivotAgregation = function () {
						if (!$scope.functions[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez ajouter une ou plusieurs fonction(s)'
								)
							);
							return false;
						}
						if (!$scope.pivots[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez ajouter une ou plusieurs colonne(s) de Pivot'
								)
							);
							return false;
						}
						if (
							_.filter($scope.groups, function (grp) {
								return grp.column_uuid == $scope.pivots[0].column_uuid;
							})[0]
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Une colonne ne peut pas être utilisée à la fois comme pivot et pour le regroupement'
								)
							);
							return false;
						}

						if (
							$scope.unauthorizedPivotTypes.indexOf($scope.pivots[0].type) > -1
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La colonne pivot ne peut pas être de type'
								) +
									' ' +
									$scope.pivots[0].type
							);
							return false;
						}

						if (!$scope.pivotValues[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez ajouter une ou plusieurs valeur(s)'
								)
							);
							return;
						}
						return true;
					};

					var validatePartitionAgregation = function () {
						if (!$scope.partitions[0]) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez ajouter un ou plusieurs groupe(s) de fonctions'
								)
							);
							return false;
						}

						for (var p in $scope.partitions) {
							let partition = $scope.partitions[p];
							let msgTitle =
								gettextCatalog.getString('Configuration invalide (groupe :') +
								' ' +
								partition.lib +
								')';
							if (!partition.functions[0]) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString(
										'Veuillez ajouter une ou plusieurs fonction(s)'
									)
								);
								return false;
							}

							if (!validateAgregationGroups($scope.partitions[p].groups)) {
								return false;
							}

							if (
								!validateAgregationFunctions($scope.partitions[p].functions)
							) {
								return false;
							}

							let lagOrLeagFunctionExists =
								_.filter(partition.functions, function (item) {
									return item.func == 'LAG' || item.func == 'LEAD';
								})[0] !== undefined;

							if (
								lagOrLeagFunctionExists &&
								(partition.row_start || partition.row_end)
							) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString(
										"Les fonctions 'Précédent' et 'Suivant' ne sont pas compatibles avec une saisie dans l'intervalle de calcul de la fonction"
									)
								);
								return false;
							}

							let notCompatibleFunctionsExists =
								_.filter(partition.functions, function (item) {
									return (
										item.func == 'CUME_DIST' ||
										item.func == 'DENSE_RANK' ||
										item.func == 'RANK' ||
										item.func == 'ROW_NUMBER' ||
										item.func == 'PERCENT_RANK' ||
										item.func == 'NTILE'
									);
								})[0] !== undefined;

							if (
								notCompatibleFunctionsExists &&
								(partition.row_start || partition.row_end)
							) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString(
										"Les fonctions 'Groupes' ne sont pas compatibles avec une saisie dans l'intervalle de calcul de la fonction"
									)
								);
								return false;
							}

							if (partition.row_start && !partition.row_end) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString(
										"La valeur 'Jusqu'au' est obligatoire"
									)
								);
								return false;
							}

							if (
								(partition.row_start == 'FOLLOWING' ||
									partition.row_start == 'PRECEDING') &&
								!partition.row_start_value
							) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString("La valeur 'Depuis' est obligatoire")
								);
								return false;
							}

							if (!partition.row_start && partition.row_end) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString("La valeur 'Depuis' est obligatoire")
								);
								return false;
							}

							if (
								(partition.row_end == 'FOLLOWING' ||
									partition.row_end == 'PRECEDING') &&
								!partition.row_end_value
							) {
								toaster.pop(
									'error',
									msgTitle,
									gettextCatalog.getString(
										"La valeur 'Jusqu'au' est obligatoire"
									)
								);
								return false;
							}
						}

						return true;
					};

					var validateAgregationGroups = function (groups) {
						let msgTitle = invalidConfTra;
						let windowExists = false;
						for (var g in groups) {
							if (groups[g].type == 'date') {
								if (
									windowExists &&
									(groups[g].interval || groups[g].step || groups[g].time_lag)
								) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											"Il n'est pas possible de créer une agrégation contenant plus d'un intervalle, glissement ou décalage."
										)
									);
									return false;
								} else if (
									groups[g].interval ||
									groups[g].step ||
									groups[g].time_lag
								) {
									windowExists = true;
								}
								if (groups[g].interval && !groups[g].interval_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											"L'unité de l'intervalle est obligatoire"
										)
									);
									return false;
								}

								if (!groups[g].interval && groups[g].interval_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											"La valeur de l'intervalle est obligatoire"
										)
									);
									return false;
								}

								if (groups[g].step && !groups[g].step_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											"L'unité du glissement est obligatoire"
										)
									);
									return false;
								}

								if (!groups[g].step && groups[g].step_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString('Sliding value is mandatory')
									);
									return false;
								}

								if (groups[g].time_lag && !groups[g].time_lag_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											"L'unité du décalage est obligatoire"
										)
									);
									return false;
								}

								if (!groups[g].time_lag && groups[g].time_lag_unit) {
									toaster.pop(
										'error',
										msgTitle,
										gettextCatalog.getString(
											'La valeur du décalage est obligatoire'
										)
									);
									return false;
								}

								if (groups[g].interval && groups[g].interval_unit) {
									let intervalTime = getTimeMicroSecondsValue(
										groups[g].interval,
										groups[g].interval_unit
									);
									if (groups[g].step && groups[g].step_unit) {
										let stepTime = getTimeMicroSecondsValue(
											groups[g].step,
											groups[g].step_unit
										);
										if (intervalTime < stepTime) {
											toaster.pop(
												'error',
												msgTitle,
												gettextCatalog.getString(
													"La valeur de l'intervalle doit être supérieure ou égale à celle du glissement"
												)
											);
											return false;
										}
									}
									if (groups[g].time_lag && groups[g].time_lag_unit) {
										let timeLagTime = getTimeMicroSecondsValue(
											groups[g].time_lag,
											groups[g].time_lag_unit
										);
										if (intervalTime < timeLagTime) {
											toaster.pop(
												'error',
												msgTitle,
												gettextCatalog.getString(
													"La valeur de l'intervalle doit être supérieure ou égale à celle du décalage"
												)
											);
											return false;
										}
									}
								}
								if (groups[g].step && groups[g].step_unit) {
									let stepTime = getTimeMicroSecondsValue(
										groups[g].step,
										groups[g].step_unit
									);
									if (groups[g].time_lag && groups[g].time_lag_unit) {
										let timeLagTime = getTimeMicroSecondsValue(
											groups[g].time_lag,
											groups[g].time_lag_unit
										);
										if (stepTime <= timeLagTime) {
											toaster.pop(
												'error',
												msgTitle,
												gettextCatalog.getString(
													'La valeur du glissement doit être strictement supérieure à celle du décalage'
												)
											);
											return false;
										}
									}
								}
							}
						}
						return true;
					};

					var validateAgregationFunctions = function (functions) {
						if (
							_.filter(functions, function (f) {
								return f.func == 'NTILE' && !f.ntile;
							})[0]
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez saisir une valeur pour la fonction NTILE'
								)
							);
							return false;
						}

						if (
							_.filter(functions, function (f) {
								return (f.func == 'LAG' || f.func == 'LEAD') && !f.offset;
							})[0]
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Veuillez saisir une valeur de décalage pour la fonction Suivant/Précédent'
								)
							);
							return false;
						}

						return true;
					};

					$scope.saveAndClose = function () {
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					};

					$scope.cancel = function () {
						vm.closeModal();
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dataOperationTools/agregation/agregation.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showBDAgregation;
						},
						function () {
							scope.setAttributes();
							scope.init();
							scope.filterAttributs();
						}
					);
				}
			);
		},
	]);
})();
