(function () {
	'use strict';

	angular.module('dcApp').factory('ModalService', ModalService);

	ModalService.$inject = [
		'$compile',
		'$rootScope',
		'$log',
		'$q',
		'$http',
		'$controller',
		'$timeout',
		'$window',
		'$sce',
		'$templateCache',
		'gettextCatalog',
	];

	function ModalService(
		$compile,
		$rootScope,
		$log,
		$q,
		$http,
		$controller,
		$timeout,
		$window,
		$sce,
		$templateCache,
		gettextCatalog
	) {
		var service = {
			open: openModal,
			confirm: openConfirmModal,
			info: openInformationModal,
			input: openInputModal,
			text: openTextModal,
			accept: _accept,
			ignore: _ignore,
			cancel: _cancel,
		};

		var dcModalEl = null;
		var deferred;
		var stopWatching = [];
		var forceShowing = false;

		var modal = {
			el: null,
			headerEl: null,
			contentEl: null,
			actionEl: null,
			scope: null,
		};

		activate();
		return service;

		function openModal(options) {
			$log.debug('ModalService.openModal with ' + angular.toJson(options));
			deferred = $q.defer();
			dcModalEl.addClass('opacity-0');
			dcModalEl.removeClass('ng-hide');
			angular.element('body').addClass('overflow-disabled');

			if (
				options.locals.acceptLabel &&
				!options.locals.acceptLabel.$$unwrapTrustedValue
			) {
				options.locals.acceptLabel = $sce.trustAsHtml(
					options.locals.acceptLabel
				);
			}
			if (
				options.locals.cancelLabel &&
				!options.locals.cancelLabel.$$unwrapTrustedValue
			) {
				options.locals.cancelLabel = $sce.trustAsHtml(
					options.locals.cancelLabel
				);
			}
			if (options.locals.title && !options.locals.title.$$unwrapTrustedValue) {
				options.locals.title = $sce.trustAsHtml(options.locals.title);
			}
			if (
				options.locals.subTitle &&
				!options.locals.subTitle.$$unwrapTrustedValue
			) {
				options.locals.subTitle = $sce.trustAsHtml(options.locals.subTitle);
			}

			$http
				.get(options.templateUrl, { cache: $templateCache })
				.then(function (response) {
					var modalController, modalControllerInstance;

					modal.scope = createModalScope(options.locals);
					modalController = $controller(
						options.controller,
						modal.scope,
						true,
						options.controllerAs
					);

					if (options.controllerAs && options.bindToController) {
						modalControllerInstance = modalController.instance;
						modalControllerInstance.$accept = _accept;
						modalControllerInstance.$ignore = _ignore;
						modalControllerInstance.$cancel = _cancel;
						angular.extend(modalControllerInstance, options.locals);
					}

					modalController();
					dcModalEl.html(response.data);
					dcModalEl.children().data('$ngControllerController', modalController);
					modal.el = $compile(dcModalEl.contents())(modal.scope.$scope).filter(
						'.dc-modal-container'
					);
					modal.headerEl = modal.el.find('.dc-modal-header');
					modal.contentEl = modal.el.find('.dc-modal-content');
					modal.actionEl = modal.el.find('.dc-modal-action');
					addClass(modal.el);
					open(modal.el);
					initEventListeners(options.disableBackdrop);
				});
			return deferred.promise;
		}

		function openConfirmModal(options) {
			var defaultLocals = {
				title: gettextCatalog.getString('modal.confirm.title'),
				subTitle: '',
				acceptLabel: gettextCatalog.getString('modal.accept.label'),
				cancelLabel: gettextCatalog.getString('modal.cancel.label'),
			};
			var locals = angular.extend(defaultLocals, options);
			if (locals.content && !locals.content.$$unwrapTrustedValue) {
				locals.content = $sce.trustAsHtml(locals.content);
			}
			return openModal({
				templateUrl: './src/components/base/modal/confirm-modal.html',
				controller: function () {},
				controllerAs: 'vm',
				bindToController: true,
				locals: locals,
				disableBackdrop: locals.disableBackdrop || false,
			});
		}

		function openInformationModal(options) {
			var defaultLocals = {
				title: gettextCatalog.getString('modal.info.title'),
				subTitle: '',
				acceptLabel: gettextCatalog.getString('modal.accept.label'),
			};
			var locals = angular.extend(defaultLocals, options);
			if (locals.content && !locals.content.$$unwrapTrustedValue) {
				locals.content = $sce.trustAsHtml(locals.content);
			}
			return openModal({
				templateUrl: './src/components/base/modal/information-modal.html',
				controller: function () {},
				controllerAs: 'vm',
				bindToController: true,
				locals: locals,
				disableBackdrop: locals.disableBackdrop || false,
			});
		}

		function openInputModal(options) {
			var defaultLocals = {
				title: gettextCatalog.getString('modal.input.title'),
				subTitle: '',
				value: '',
				placeholder: '',
				acceptLabel: gettextCatalog.getString('modal.accept.label'),
				cancelLabel: gettextCatalog.getString('modal.cancel.label'),
			};
			var locals = angular.extend(defaultLocals, options);
			return openModal({
				templateUrl: './src/components/base/modal/input-modal.html',
				controller: function () {},
				controllerAs: 'vm',
				bindToController: true,
				locals: locals,
				disableBackdrop: locals.disableBackdrop || false,
			});
		}

		function openTextModal(options) {
			var defaultLocals = {
				title: gettextCatalog.getString('modal.input.title'),
				subTitle: '',
				value: '',
				placeholder: '',
				acceptLabel: gettextCatalog.getString('modal.accept.label'),
				cancelLabel: gettextCatalog.getString('modal.cancel.label'),
			};
			var locals = angular.extend(defaultLocals, options);
			return openModal({
				templateUrl: './src/components/base/modal/text-modal.html',
				controller: function () {},
				controllerAs: 'vm',
				bindToController: true,
				locals: locals,
				disableBackdrop: locals.disableBackdrop || false,
			});
		}

		function _accept(result) {
			deferred.resolve(result);
			$log.debug(
				'ModalService has been accepted with result : ' + angular.toJson(result)
			);
			close(modal.el);
		}

		function _ignore(result) {
			deferred.resolve(result);
			$log.debug(
				'ModalService has been ignored with result : ' + angular.toJson(result)
			);
			close(modal.el);
		}

		function _cancel(result) {
			deferred.reject(result);
			$log.debug(
				'ModalService has been canceled with result : ' + angular.toJson(result)
			);
			close(modal.el);
		}

		/**
		 * Private functions
		 */

		function activate() {
			if (!dcModalEl) {
				createModalContainer();
			}
		}

		function createModalContainer() {
			var dcModalTpl = `
				<dc-modal class="dc-modal ng-hide opacity-0"></dc-modal>
			`;
			dcModalEl = $compile(dcModalTpl)($rootScope);
			angular.element(document.body).append(dcModalEl);
			$rootScope.showDcModal = false;
			$rootScope.closeDcModal = function ($evt) {
				if ($evt && $evt.target !== dcModalEl[0]) {
					return;
				}
				close(modal.el);
			};
		}

		function createModalScope(locals) {
			var scope = angular.extend($rootScope.$new(true), locals);
			return {
				$scope: scope,
			};
		}

		function open(el) {
			$log.debug('open start : ' + angular.toJson(el));
			dcModalEl.append(el);

			$timeout(function () {
				modal.el.addClass('opacity-1');
				modal.el.removeClass('opacity-0');
				dcModalEl.addClass('opacity-1');
				dcModalEl.removeClass('opacity-0');
			});

			angular.element($window).on('resize.modal', updateSize);

			stopWatching.push(
				$rootScope.$watch(
					getModalContainerHeight,
					function (newValue, oldValue) {
						$log.debug(
							'getModalContainerHeight: ' + oldValue + ' -> ' + newValue
						);
						updateSize();
					}
				)
			);

			stopWatching.push(
				$rootScope.$watch(getModalHeight, function (newValue, oldValue) {
					$log.debug('getModalHeight: ' + oldValue + ' -> ' + newValue);
					updateSize();
				})
			);

			stopWatching.push(
				$rootScope.$watch(getHeaderHeight, function (newValue, oldValue) {
					$log.debug('getHeaderHeight: ' + oldValue + ' -> ' + newValue);
					updateSize();
				})
			);
			stopWatching.push(
				$rootScope.$watch(getFooterHeight, function (newValue, oldValue) {
					$log.debug('getFooterHeight: ' + oldValue + ' -> ' + newValue);
					updateSize();
				})
			);

			stopWatching.push($rootScope.$watch(forceShowing, updateSize));
			$log.debug('open end : ' + angular.toJson(el));
		}

		function close(el) {
			$log.debug('close start : ' + angular.toJson(el));

			$log.debug('removing listeners');
			removeListeners();

			modal.el.addClass('opacity-0');
			modal.el.removeClass('opacity-1');
			dcModalEl.addClass('opacity-0');
			dcModalEl.removeClass('opacity-1');
			dcModalEl.addClass('opacity-0 ng-hide');
			angular.element('body').removeClass('overflow-disabled');

			el.remove();
			modal.scope.$scope.$destroy();
			$log.debug('close end : ' + angular.toJson(el));
		}

		function addClass(el) {
			el.addClass('dc-modal-container');
		}

		function getModalContainerHeight() {
			return modal.el.innerHeight();
		}

		function getModalHeight() {
			return dcModalEl.innerHeight();
		}

		function getHeaderHeight() {
			return modal.headerEl.outerHeight(true);
		}

		function getFooterHeight() {
			return modal.actionEl.outerHeight(true);
		}

		function updateSize() {
			$log.debug('ModalService.updateSize');
			modal.headerEl = modal.el.find('.dc-modal-header');
			modal.contentEl = modal.el.find('.dc-modal-content');
			modal.actionEl = modal.el.find('.dc-modal-action');

			var windowHeight = $window.innerHeight;
			var modalHeight = getModalContainerHeight();
			var headerHeight = getHeaderHeight();
			var footerHeight = getFooterHeight();

			$log.debug('ModalService.updateSize windowHeight : ' + windowHeight);
			$log.debug('ModalService.updateSize modalHeight : ' + modalHeight);
			$log.debug('ModalService.updateSize headerHeight : ' + headerHeight);
			$log.debug('ModalService.updateSize footerHeight : ' + footerHeight);

			var maxHeight =
				windowHeight -
				50 -
				headerHeight -
				footerHeight -
				parseInt(modal.el.css('padding-top'), 10) -
				parseInt(modal.el.css('padding-bottom'), 10);
			modal.contentEl.css('max-height', maxHeight);
			modal.contentEl.css('overflow', 'auto');
		}

		function initEventListeners(isBackdropDisabled) {
			angular.element(modal.el).on('click.modal:container', function ($evt) {
				$evt.stopPropagation();
				$evt.preventDefault();
			});

			if (isBackdropDisabled !== undefined && isBackdropDisabled) {
				return;
			}
			angular.element(dcModalEl).on('click.modal:backdrop', function () {
				close(modal.el);
			});

			angular.element('body').on('keydown.modal', function (ev) {
				if (ev.key === 'Escape') {
					close(modal.el);
				}
			});
		}

		function removeListeners() {
			if (stopWatching && stopWatching.length) {
				stopWatching.forEach(function (stop) {
					stop();
				});
			}
			stopWatching = [];

			angular.element($window).off('resize.modal');
			angular.element(dcModalEl).off('click.modal:backdrop');
			angular.element(modal.el).off('click.modal:container');
			angular.element('body').off('keydown.modal');
		}
	}
})();
