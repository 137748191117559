(function () {
	'use strict';

	angular.module('dcApp').directive('summaryConfig', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'$sce',
				'UNAUTHORIZED_TYPES_FOR_STAT',
				function ($scope, $rootScope, $sce, UNAUTHORIZED_TYPES_FOR_STAT) {
					var vm = this;
					$scope.selection = {};
					$scope.unauthorizedTypesForStat = UNAUTHORIZED_TYPES_FOR_STAT;
					$scope.initConfig = function (columns) {
						$scope.output_columns = columns;
						setSummaryConfig();
					};

					var setSummaryConfig = function () {
						if (!vm.data.config.count_columns) {
							$scope.selection.summaryAllColCount = false;
							vm.data.config.count_columns = [];
							$scope.changeSummaryAllColCount();
						} else {
							for (var c in $scope.output_columns) {
								vm.data.config.count_columns = _.filter(
									vm.data.config.count_columns,
									function (item) {
										return _.filter($scope.output_columns, function (col) {
											return col.uuid == item;
										})[0];
									}
								);
								if (
									vm.data.config.count_columns.indexOf(
										$scope.output_columns[c].uuid
									) > -1
								) {
									$scope.output_columns[c].count = true;
								}
							}
						}

						if (!vm.data.config.summary_columns) {
							$scope.selection.summaryAllColStat = false;
							vm.data.config.summary_columns = [];
							$scope.changeSummaryAllColStat();
						} else {
							for (var c in $scope.output_columns) {
								vm.data.config.summary_columns = _.filter(
									vm.data.config.summary_columns,
									function (item) {
										return _.filter($scope.output_columns, function (col) {
											return col.uuid == item;
										})[0];
									}
								);
								if (
									vm.data.config.summary_columns.indexOf(
										$scope.output_columns[c].uuid
									) > -1
								) {
									$scope.output_columns[c].summary = true;
								}
							}
						}
					};

					$scope.changeSummaryAllColStat = function () {
						for (var c in $scope.output_columns) {
							if ($scope.output_columns[c].is_list) {
								return;
							}
							if (
								$scope.output_columns[c].summary !=
								$scope.selection.summaryAllColStat
							) {
								$scope.output_columns[c].summary =
									$scope.selection.summaryAllColStat;
								$scope.changeSummaryColStat($scope.output_columns[c]);
							}
						}
					};

					$scope.changeSummaryAllColCount = function () {
						for (var c in $scope.output_columns) {
							if (
								$scope.output_columns[c].count !=
								$scope.selection.summaryAllColCount
							) {
								$scope.output_columns[c].count =
									$scope.selection.summaryAllColCount;
								$scope.changeSummaryColCount($scope.output_columns[c]);
							}
						}
					};

					$scope.changeSummaryColStat = function (column) {
						if (column.summary) {
							vm.data.config.summary_columns.push(column.uuid);
						} else {
							vm.data.config.summary_columns.splice(
								vm.data.config.summary_columns.indexOf(column.uuid),
								1
							);
						}
					};

					$scope.changeSummaryColCount = function (column) {
						if (column.count) {
							vm.data.config.count_columns.push(column.uuid);
						} else {
							vm.data.config.count_columns.splice(
								vm.data.config.count_columns.indexOf(column.uuid),
								1
							);
						}
					};

					$scope.setMax25Length = function (str) {
						return setMaxStringLength(str, 25);
					};

					$scope.getTypeHtml = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList));
						} else {
							return '';
						}
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					data: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl:
					'./src/components/directives/dataOperationTools/summaryConfig/summaryConfig.html',
				link: function (scope, element, attrs) {
					scope.vm.data.initConfig = scope.initConfig;
				},
			};
		},
	]);
})();
