(function () {
	'use strict';

	angular.module('dcApp').controller('ImportDCController', [
		'$rootScope',
		'$scope',
		'$stateParams',
		'$filter',
		'toaster',
		'ImportDCService',
		'API_BASE_URL_BACKEND',
		'gettextCatalog',
		function (
			$rootScope,
			$scope,
			$stateParams,
			$filter,
			toaster,
			ImportDCService,
			API_BASE_URL_BACKEND,
			gettextCatalog
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopError = gettextCatalog.getString('Erreur');
			let toasterPopImported = gettextCatalog.getString('Import effectué');

			$scope.uploadFile = function (flow) {
				flow.opts.headers = {
					Authorization: 'Bearer ' + window._keycloak.token,
				};

				flow.upload();
				$rootScope.showSpinner = true;
			};

			$scope.url =
				API_BASE_URL_BACKEND +
				'import_dc/upload?project=' +
				$rootScope.currentProjectId;

			$scope.getData = function (flow, file, message) {
				var datas = JSON.parse(message);
				$scope.fetchedData = datas;
				$scope.showUpload = false;
				$scope.hideCaracs = [];
				$scope.hideSources = [];
				$scope.metadataLoaded = [];
				$scope.isNew = [];
				$scope.entiteLib = [];
				$scope.caracs = [];
				$scope.token = [];
				$scope.metadata = [];
				$scope.sources = [];
				$scope.mappingSelected = [];
				for (var i in datas) {
					$scope.hideCaracs[i] = true;
					$scope.hideSources[i] = true;
					$scope.metadataLoaded[i] = true;
					$scope.entiteSelected[i] = false;
					$scope.sourcesSelected[i] = false;
					$scope.mappingSelected[i] = false;
					$scope.isNew[i] = true;
					$scope.entiteLib[i] = datas[i].uet.label;
					$scope.caracs[i] = datas[i].caracs;
					//set caracs lib
					for (var t in $scope.caracs[i]) {
						$scope.caracs[i][t].lib = $scope.caracs[i][t].label;
					}
					$scope.token[i] = datas[i].token;
					$scope.metadata[i] = datas[i].uet;
					$scope.metadata[i].actif = $scope.metadata.active;
					$scope.metadata[i].code = '';
					$scope.sources[i] = [];

					angular.forEach(datas[i].sources, function (elem) {
						var newDs = {};
						newDs = elem.source;
						newDs.lib = elem.source.metadata.label;
						$scope.sources[i].push(newDs);
					});

					$rootScope.showSpinner = false;
				}
			};

			$scope.uploadError = function (file, response) {
				var data = JSON.parse(response);
				toaster.pop('error', toasterPopError, data.message, 20000);
				$rootScope.showSpinner = false;
			};

			$scope.selectCarac = function (index) {
				$scope.selectedCaracs[index] = $filter('filter')($scope.caracs[index], {
					checked: true,
				});
				$scope.entiteSelected[index] =
					$scope.selectedCaracs[index].length == 0 ? false : true;
			};

			$scope.selectSource = function (index) {
				$scope.selectedSources[index] = $filter('filter')(
					$scope.sources[index],
					{
						checked: true,
					}
				);
				$scope.sourcesSelected[index] =
					$scope.selectedSources[index].length == 0 ? false : true;
			};

			$scope.selectAllCaracs = function (index) {
				if ($scope.entiteSelected[index]) {
					for (var i = 0; i < $scope.caracs[index].length; i++) {
						$scope.caracs[index][i].checked = true;
					}
				} else {
					for (var j = 0; j < $scope.caracs[index].length; j++) {
						$scope.caracs[index][j].checked = false;
					}
				}
				$scope.selectCarac(index);
			};

			$scope.selectAllSources = function (index) {
				if ($scope.sourcesSelected[index]) {
					for (var k = 0; k < $scope.sources[index].length; k++) {
						$scope.sources[index][k].checked = true;
						$scope.sources[index][k].code =
							$scope.sources[index][k].metadata.code;
					}
				} else {
					for (var l = 0; l < $scope.sources[index].length; l++) {
						$scope.sources[index][l].checked = false;
						$scope.sources[index][l].code =
							$scope.sources[index][l].metadata.code;
					}
				}
				$scope.selectSource(index);
			};

			$scope.importData = function () {
				var hdhImports = getHdhImportsData();
				ImportDCService.import(hdhImports).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopImported);
					$scope.initPage();
				});
			};

			function getHdhImportsData() {
				var hdhImports = [];
				for (var i in $scope.fetchedData) {
					var selectedCaracsList = [];
					var selectedSourcesList = [];
					if ($scope.selectedCaracs && $scope.selectedCaracs[i]) {
						for (var m = 0; m < $scope.selectedCaracs[i].length; m++) {
							selectedCaracsList.push($scope.selectedCaracs[i][m].id);
						}
					}
					if ($scope.selectedSources && $scope.selectedSources[i]) {
						for (var n = 0; n < $scope.selectedSources[i].length; n++) {
							var dataSource = {
								id: $scope.selectedSources[i][n].id,
								doc: $scope.selectedSources[i][n].doc,
								db: $scope.selectedSources[i][n].db,
							};
							selectedSourcesList.push(dataSource);
						}
					}

					var hdhImport = {
						selected_caracs: selectedCaracsList,
						selected_sources: selectedSourcesList,
						mapping_selected:
							$scope.mappingSelected && $scope.mappingSelected[i]
								? $scope.mappingSelected[i]
								: false,
						token: $scope.token[i],
						metadata: $scope.metadata[i],
					};

					hdhImports.push(hdhImport);
				}

				return hdhImports;
			}

			$scope.initPage = function () {
				$scope.import = {};
				$scope.import.flow = '';
				$scope.selectedCaracs = [];
				$scope.selectedSources = [];
				$scope.caracs = [];
				$scope.sources = [];
				$scope.showUpload = true;
				$scope.entiteSelected = [];
				$scope.sourcesSelected = [];
				$scope.mappingSelected = false;
			};

			$scope.initPage();
		},
	]);
})();
