(function () {
	'use strict';

	angular.module('DcConnectorModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('connector-parent', {
			url: '/connector',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('connectors', {
			url: '/',
			parent: 'connector-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Connecteurs',
			},
			views: {
				'@connector-parent': {
					templateUrl: './src/components/modules/connector/connectors.html',
					controller: 'ConnectorListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('connectors-new', {
			url: '/new',
			parent: 'connector-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Nouveau Connecteur',
			},
			views: {
				'@connector-parent': {
					templateUrl: './src/components/modules/connector/connector-form.html',
					controller: 'ConnectorEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('connectors-edit', {
			url: '/edit/:type/:id',
			parent: 'connector-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Edition du Connecteur',
			},
			views: {
				'@connector-parent': {
					templateUrl: './src/components/modules/connector/connector-form.html',
					controller: 'ConnectorEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('connectors-duplicate', {
			url: '/:type/duplicate/:duplicatedId',
			parent: 'connector-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Dupliquer le Connecteur',
			},
			views: {
				'@connector-parent': {
					templateUrl: './src/components/modules/connector/connector-form.html',
					controller: 'ConnectorEditController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
