(function () {
	'use strict';

	angular.module('dcApp').directive('jobError', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					$scope.copyToClipboard = function () {
						var $temp_input = $('<input>');
						$('body').append($temp_input);
						$temp_input.val(vm.data.errorMessage).select();
						document.execCommand('copy');
						$temp_input.remove();
					};

					$scope.showError = function () {
						if (
							vm.data.error &&
							vm.data.errorMessage &&
							vm.data.errorMessage.length > 0
						) {
							$('#jobErrorModal-' + $scope.$id).modal('show');
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/job/jobError/jobError.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {},
			};
		},
	]);
})();
