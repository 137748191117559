(function () {
	'use strict';

	angular.module('dcApp').controller('TagsController', [
		'API_BASE_URL_BACKEND',
		'TagService',
		'$state',
		'$scope',
		'$rootScope',
		'$q',
		'toaster',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'permissionChecker',
		'gettextCatalog',
		function (
			API_BASE_URL_BACKEND,
			TagService,
			$state,
			$scope,
			$rootScope,
			$q,
			toaster,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			permissionChecker,
			gettextCatalog
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Tags');

			let dataGirdColumnName = {};
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.code = gettextCatalog.getString('Code');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteTag = gettextCatalog.getString(
				'Suppression du Tag effectuée'
			);
			let toasterPopCreationTag = gettextCatalog.getString(
				'Création du Tag effectuée'
			);
			let toasterPopUpdateTag = gettextCatalog.getString(
				'Modification du Tag effectuée'
			);

			$scope.tags = [];

			$scope.dataGridOptions = {
				dataSource: $scope.tagsGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				scrolling: {
					rowRenderingMode: 'Standard',
					showScrollbar: 'onHover',
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.code,
						cellTemplate: 'clickableLabel',
						dataField: 'code',
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actions',
						width: 120,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
				onCellPrepared: function (cellInfo) {
					if (
						cellInfo.rowType == 'data' &&
						cellInfo.column.dataField === 'code'
					) {
						var color =
							cellInfo.data.color !== undefined
								? cellInfo.data.color
								: '#dbf5d1';
						cellInfo.cellElement.css('backgroundColor', color);
					}
				},
			};

			function getTags() {
				var deferred = $q.defer();
				TagService.getTags('').then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getTags().then(function (content) {
					if (content !== undefined) {
						$scope.tags = [];
						for (var i in content) {
							$scope.tags.push({
								id: content[i].id,
								code: content[i].code,
								color: content[i].color,
							});
						}
						$scope.tagsGridData = $scope.tags;
						$scope.dataGridOptions.dataSource = $scope.tagsGridData;
						$scope.showGrid = true;
					}
				});
			};

			$scope.initTags = function () {
				$scope.getGridData();
			};

			$scope.showEditModal = function (modalId, tagId, isNew) {
				$scope.tag = isNew
					? {}
					: angular.copy(
							_.find($scope.tags, function (elm) {
								return elm.id == tagId;
							})
					  );
				$scope.isNew = isNew;
				$scope.tag.color = isNew ? '#dbf5d1' : $scope.tag.color;
				$('#' + modalId).modal('show');
			};

			$scope.saveTag = function (modalId) {
				TagService.saveTag($scope.tag).then(function (response) {
					if ($scope.isNew) {
						$scope.tagsGridData.unshift(response.data);
						$scope.gridInstance.refresh();
					} else {
						for (var i in $scope.tagsGridData) {
							if (response.data.id == $scope.tagsGridData[i].id) {
								$scope.tagsGridData[i] = response.data;
								$scope.gridInstance.refresh();
								break;
							}
						}
					}
					toaster.pop(
						'success',
						toasterPopSuccess,
						$scope.isNew ? toasterPopCreationTag : toasterPopUpdateTag
					);
					$('#' + modalId).modal('hide');
				});
			};

			permissionChecker.checkGlobalPermission(
				'tags',
				function () {
					$scope.initTags();
				},
				function () {
					$scope.tags_unauthorised = true;
				}
			);

			permissionChecker.checkGlobalPermission(
				'tags-create',
				function () {
					$scope.create_authorised = true;
				},
				function () {}
			);

			$scope.showDeleteModal = function (modalId, tag) {
				$scope.tagTodelete = tag;
				$('#' + modalId).modal('show');
			};

			$scope.deleteTag = function (modalId) {
				TagService.deleteTag($scope.tagTodelete.id).then(function (response) {
					for (var i in $scope.tagsGridData) {
						if (response.data == $scope.tagsGridData[i].id) {
							$scope.tagsGridData.splice(i, 1);
							$scope.gridInstance.refresh();
							break;
						}
					}
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteTag);
					$('#' + modalId).modal('hide');
				});
			};

			$scope.refreshTagList = function () {
				$scope.getGridData();
			};

			$scope.closeMe = function (modalId) {
				$('#' + modalId).modal('hide');
			};
		},
	]);
})();
