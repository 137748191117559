(function () {
	'use strict';

	const modules = [
		'ngResource',
		'dndLists',
		'gridster',
		'dx',
		'ui.router',
		'ui.select',
		'ngSanitize',
		'ui.tree',
		'ui.bootstrap',
		'ui.bootstrap.datetimepicker',
		'toaster',
		'ngCookies',
		'ui.sortable',
		'ngTagsInput',
		'scrollable-table',
		'angularSpectrumColorpicker',
		'flow',
		'ui.toggle',
		'jsonFormatter',
		'ui.indeterminate',
		'gettext',
		'ngIdle',
		'ngMaterial',
		'AdministrationModule',
		'MiscellaneousSettingsModule',
		'ProjectModule',
		'DatablockModule',
		'DcEntityModule',
		'DcConnectorModule',
		'DcDepotModule',
		'HdModule',
	];

	angular.module('dcApp', modules);
})();
