(function () {
	'use strict';

	angular.module('dcApp').directive('elSize', [
		'$parse',
		function ($parse) {
			return function (scope, elem, attrs) {
				var fn = $parse(attrs.elSize);
				scope.$watch(
					function () {
						return { width: elem.width(), height: elem.height() };
					},
					function (size) {
						fn.assign(scope, size);
					},
					true
				);
			};
		},
	]);
})();
