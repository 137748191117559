(function () {
	'use strict';

	angular.module('dcApp').factory('MoveElementService', [
		'$rootScope',
		'$http',
		'$q',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, $q, API_BASE_URL_BACKEND) {
			let MoveElementService = {};

			MoveElementService.getElementToMove = function (
				elements,
				destProject,
				type
			) {
				let url = getUrl(elements, destProject, type, true);
				if (!url) {
					return $q.reject('NonSupportedType');
				}
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MoveElementService.moveElements = function (elements, destProject, type) {
				let url = getUrl(elements, destProject, type, false);
				if (!url) {
					return $q.reject('NonSupportedType');
				}
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			function getUrl(elements, destProject, type, view) {
				let path = getMoveOrPathViewElements(type, view);
				if (!path || !destProject || !elements) {
					return undefined;
				}
				let url = API_BASE_URL_BACKEND + path;
				if (Array.isArray(elements)) {
					url = url + '?elmsIds=' + elements[0];
					for (let i = 1; i < elements.length; i++) {
						url = url + '&elmsIds=' + elements[i];
					}
				} else {
					url = url + '?elmsIds=' + elements;
				}
				url = url + '&destProject=' + destProject;

				return url;
			}

			function getMoveOrPathViewElements(type, view) {
				switch (type) {
					case 'connector':
						return view
							? $rootScope.getUrl('CONNECTORS_LINKED_ELEMS')
							: $rootScope.getUrl('CONNECTORS_MOVE_ELEMS');
					case 'depot':
						return view
							? $rootScope.getUrl('DEPOTS_LINKED_ELEMS')
							: $rootScope.getUrl('DEPOTS_MOVE_ELEMS');
					case 'datablock':
						return view
							? $rootScope.getUrl('DATABLOCKS_LINKED_ELEMS')
							: $rootScope.getUrl('DATABLOCKS_MOVE_ELEMS');
					case 'entiteType':
						return view
							? $rootScope.getUrl('ENTITE_TYPE_LINKED_ELEMS')
							: $rootScope.getUrl('ENTITE_TYPE_MOVE_ELEMS');
					case 'media':
						return view
							? $rootScope.getUrl('MEDIA_LINKED_ELEMS')
							: $rootScope.getUrl('MEDIA_MOVE_ELEMS');
					case 'grid':
						return view
							? $rootScope.getUrl('GRIDS_LINKED_ELEMS')
							: $rootScope.getUrl('GRIDS_MOVE_ELEMS');
					case 'timeline':
						return view
							? $rootScope.getUrl('TIMELINES_LINKED_ELEMS')
							: $rootScope.getUrl('TIMELINES_MOVE_ELEMS');
					case 'map':
						return view
							? $rootScope.getUrl('MAPS_LINKED_ELEMS')
							: $rootScope.getUrl('MAPS_MOVE_ELEMS');
					case 'chart':
						return view
							? $rootScope.getUrl('CHARTS_LINKED_ELEMS')
							: $rootScope.getUrl('CHARTS_MOVE_ELEMS');
					case 'dashboard':
						return view
							? $rootScope.getUrl('DASHBOARDS_LINKED_ELEMS')
							: $rootScope.getUrl('DASHBOARDS_MOVE_ELEMS');
					default:
						return null;
				}
			}

			return MoveElementService;
		},
	]);
})();
