(function () {
	'use strict';

	angular.module('dcApp').factory('PermissionService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'UrlService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, UrlService) {
			var PermissionService = {};

			PermissionService.getElementPermissionRules = function () {
				var url = API_BASE_URL_BACKEND + '/permissions/codes';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getGlobalPermissionRules = function () {
				var url = API_BASE_URL_BACKEND + '/permissions/codes/global';

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getUserElementPermissions = function (metadataId) {
				var url =
					API_BASE_URL_BACKEND + '/permissions/by_element_user/' + metadataId;

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getUserPermissions = function (
				projectId,
				userId,
				isGroup,
				type
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/' +
					projectId +
					'/' +
					userId +
					'/' +
					isGroup +
					'?type=' +
					type;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getUserColumnPermissions = function (
				userId,
				isGroup,
				metadataId
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/by_user/' +
					userId +
					'/' +
					isGroup +
					'/' +
					metadataId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.updateUserPermissionManyElements = function (
				projectId,
				permission
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/' +
					projectId +
					'/update/many_element';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updateUserPermission = function (
				projectId,
				permission
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + '/permissions/' + projectId + '/update';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updatePermissionManyUsers = function (
				projectId,
				permission
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/' +
					projectId +
					'/update/many_user';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updateUserPermissionManyActions = function (
				projectId,
				permission
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/' +
					projectId +
					'/update/many_action';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.getUserGlobalPermissions = function (userId) {
				var url = API_BASE_URL_BACKEND + '/permissions/global/' + userId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getGroupGlobalPermissions = function (groupId) {
				var url = API_BASE_URL_BACKEND + '/permissions/global/group/' + groupId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.updateGroupGlobalPermission = function (permission) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + '/permissions/global/group/update';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updateGroupManyGlobalPermissions = function (
				permission
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + '/permissions/global/group/update/many';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updateUserGlobalPermission = function (permission) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + '/permissions/global/update';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.updateUserManyGlobalPermissions = function (
				permission
			) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + '/permissions/global/update/many';
				$http.post(url, permission).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PermissionService.havePermission = function (elementId, action, type) {
				var url =
					API_BASE_URL_BACKEND +
					'/permissions/check/' +
					type +
					'/' +
					elementId +
					'/' +
					action;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.havePermissionForManyActions = function (
				elementId,
				actions,
				type
			) {
				var url =
					API_BASE_URL_BACKEND + '/permissions/check/' + type + '/' + elementId;
				if (Array.isArray(actions)) {
					url = url + '?actions=' + actions[0];
					for (let i = 1; i < actions.length; i++) {
						url = url + '&actions=' + actions[i];
					}
				} else {
					url = url + '?actions=' + actions;
				}
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.haveGlobalPermission = function (action, ignoreMemberRole) {
				const url = UrlService.generateUrl('PERMISSIONS_CHECK_USER_GLOBAL_PERMISSION', {action: action},
					{
						ignoreMemberRole: ignoreMemberRole ? ignoreMemberRole : false
					}
				);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PermissionService.getCurrentUserGlobalPermissions = function () {
				var url = API_BASE_URL_BACKEND + '/permissions/current/global';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return PermissionService;
		},
	]);
})();
