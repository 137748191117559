(function () {
	'use strict';

	angular.module('dcApp').factory('MapService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'httpService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, httpService) {
			var MapService = {};

			MapService.getMaps = function (excludePublic, excludeInactive) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_GET_ALL');
				var url = excludePublic ? url + '?excludePublic=true' : url + '?excludePublic=false';
				var url = excludeInactive ? url + '&excludeInactive=true' : url + '&excludeInactive=false';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MapService.getMap = function (mapId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_GET');
				url = url.replace('{id}', mapId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MapService.createMap = function (map) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_CREATE');
				return httpService.postRequest(url, map);
			};

			MapService.createFromDataBlock = function (dbId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_CREATE_FROM_DB');
				return httpService.postRequest(url, dbId);
			};

			MapService.editMap = function (mapId, map) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_EDIT');
				url = url.replace('{id}', mapId);
				$http.put(url, map).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			MapService.deleteMap = function (mapId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_DELETE');
				url = url.replace('{id}', mapId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			MapService.duplicateMap = function (mapId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_DUPLICATE');
				url = url.replace('{id}', mapId);
				return duplicateMap(url);
			};

			MapService.deleteBulkMaps = function (mapIds) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_DELETE_BULK');
				if (Array.isArray(mapIds)) {
					url = url + '?mapsId=' + mapIds[0];
					for (let i = 1; i < mapIds.length; i++) {
						url = url + '&mapsId=' + mapIds[i];
					}
				} else {
					url = url + '?mapsId=' + mapIds;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			MapService.editMapConfig = function (mapId, config) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_EDIT_CONFIG');
				url = url.replace('{id}', mapId);
				$http.put(url, config).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			MapService.getMapConfig = function (mapId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_GET_CONFIG');
				url = url.replace('{id}', mapId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MapService.checkMapConfig = function (mapId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('MAPS_CHECK_CONFIG');
				url = url.replace('{id}', mapId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var duplicateMap = function (url) {
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return MapService;
		},
	]);
})();
