(function () {
	'use strict';

	angular.module('dcApp').directive('exportHdh', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'$filter',
				'CaracService',
				'EntiteDepotService',
				'toaster',
				'ExportDcService',
				'DownloadFileService',
				function (
					$rootScope,
					$scope,
					$filter,
					CaracService,
					EntiteDepotService,
					toaster,
					ExportDcService,
					DownloadFileService
				) {
					var vm = this;

					$scope.init = function (id, lib, code) {
						$scope.entiteLib = lib;
						$scope.entiteId = id;
						$scope.selectedCaracs = [];
						$scope.selectedSources = [];
						$scope.fileName = code;
						CaracService.getSimpleCaracsByEntiteId($scope.entiteId).then(
							function (response) {
								$scope.hideCaracs = true;
								$scope.caracs = response.data;
								$scope.getEntiteSources($scope.entiteId);
							}
						);
					};

					$scope.getEntiteSources = function (entiteId) {
						EntiteDepotService.getLinks(entiteId).then(function (response) {
							$scope.sources = [];
							$scope.hideSources = true;
							angular.forEach(response.data, function (entity) {
								var data = entity.depot;
								data.is_db =
									data.depot_type != 'CSV' && data.depot_type != 'MEDIA';
								data.is_doc =
									data.depot_type != 'CSV' && data.depot_type != 'SQL';
								data.relation_active = entity.active;
								data.relation_id = entity.id;
								data.is_active = data.metadata.actif;
								data.lib = data.metadata.label;
								$scope.sources.push(data);
							});
						});
					};

					$scope.selectCarac = function () {
						$scope.selectedCaracs = $filter('filter')($scope.caracs, {
							checked: true,
						});
						$scope.entiteSelected =
							$scope.selectedCaracs.length == 0 ? false : true;
					};

					$scope.selectSource = function () {
						$scope.selectedSources = $filter('filter')($scope.sources, {
							checked: true,
						});
						$scope.sourcesSelected =
							$scope.selectedSources.length == 0 ? false : true;
					};

					$scope.selectAllCaracs = function () {
						if ($scope.entiteSelected) {
							for (var i = 0; i < $scope.caracs.length; i++) {
								$scope.caracs[i].checked = true;
							}
						} else {
							for (var j = 0; j < $scope.caracs.length; j++) {
								$scope.caracs[j].checked = false;
							}
						}
						$scope.selectCarac();
					};

					$scope.selectAllSources = function () {
						if ($scope.sourcesSelected) {
							for (var k = 0; k < $scope.sources.length; k++) {
								$scope.sources[k].checked = true;
								$scope.sources[k].db = $scope.sources[k].is_db;
								$scope.sources[k].doc = $scope.sources[k].is_doc;
								$scope.sources[k].code = $scope.sources[k].metadata.code;
							}
						} else {
							for (var l = 0; l < $scope.sources.length; l++) {
								$scope.sources[l].checked = false;
								$scope.sources[l].db = $scope.sources[l].is_db;
								$scope.sources[l].doc = $scope.sources[l].is_doc;
								$scope.sources[l].code = $scope.sources[l].metadata.code;
							}
						}
						$scope.selectSource();
					};

					$scope.export = function () {
						for (var m = 0; m < $scope.selectedCaracs.length; m++) {
							$scope.selectedCaracs[m].date_effet =
								$rootScope.getDateWithPattern(
									$scope.selectedCaracs[m].date_effet
								);
							$scope.selectedCaracs[m].date_revocation =
								$rootScope.getDateWithPattern(
									$scope.selectedCaracs[m].date_revocation
								);
						}
						$scope.dataToExport = {
							entite_type_id: $scope.entiteId,
							selected_caracs: $scope.selectedCaracs,
							selected_sources: $scope.selectedSources,
							file_name: $scope.fileName,
						};

						ExportDcService.export($scope.dataToExport).then(function (
							response
						) {
							DownloadFileService.download(response.data.file_path, 'ZIP');
							$scope.cancel();
						});
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						vm.showExportHdh = false;
						$scope.entiteLib = null;
						$scope.entiteId = null;
						$scope.caracs = [];
						$scope.sources = [];
						$scope.selectedCaracs = [];
						$scope.entiteSelected = false;
						$scope.selectedSources = [];
						$scope.sourcesSelected = false;
						$(vm.element).modal('hide');
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					entiteTypeId: '=',
					entiteTypeLib: '=',
					entiteTypeCode: '=',
					showExportHdh: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				transclude: true,
				templateUrl: './src/components/directives/exportHdh/exportHdh.html',
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.showExportHdh = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.showExportHdh;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init(
									scope.vm.entiteTypeId,
									scope.vm.entiteTypeLib,
									scope.vm.entiteTypeCode
								);
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
