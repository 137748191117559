(function () {
	'use strict';

	angular.module('dcApp').controller('LdapController', [
		'$log',
		'LdapServices',
		'$scope',
		'$rootScope',
		'toaster',
		'permissionChecker',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'gettextCatalog',
		function (
			$log,
			LdapServices,
			$scope,
			$rootScope,
			toaster,
			permissionChecker,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			gettextCatalog
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopSuccessSavedParams = gettextCatalog.getString(
				'Paramètres enregistrés'
			);
			let toasterPopSuccessConnectionToLdap = gettextCatalog.getString(
				'La connexion au serveur LDAP réussie'
			);
			let toasterPopFailedConnectionToLdap = gettextCatalog.getString(
				'La connexion au serveur LDAP a échoué. Vérifier les paramètres de connexion.'
			);
			let toasterPopFailedSyncLdap = gettextCatalog.getString(
				'La synchronisation des utilisateurs et groupes LDAP a échoué. Vérifier les paramètres de synchronisation.'
			);

			$scope.checkError = function (fieldName) {
				return $rootScope.checkErrorBoolean(fieldName) ? 'has-error' : '';
			};

			var initDefaultValues = function () {
				if ($scope.parameters['CONNECTION_PORT']) {
					$scope.parameters['CONNECTION_PORT'] = Number(
						$scope.parameters['CONNECTION_PORT']
					);
				}
				if ($scope.parameters['USER_SYNCHRONISATION_ACTIVE'] == 'true') {
					$scope.parameters['USER_SYNCHRONISATION_ACTIVE'] = true;
				}
				if ($scope.parameters['GROUP_SYNCHRONISATION_ACTIVE'] == 'true') {
					$scope.parameters['GROUP_SYNCHRONISATION_ACTIVE'] = true;
				}
				if (!$scope.parameters['GROUP_MAPPING_MODE']) {
					$scope.parameters['GROUP_MAPPING_MODE'] = 'static';
				}
				if (!$scope.parameters['CONNECTION_PROTOCOL']) {
					$scope.parameters['CONNECTION_PROTOCOL'] = 'ldap';
				}
				if (!$scope.parameters['GROUP_MAPPING_IDENTIFIER_VALUE']) {
					$scope.parameters['GROUP_MAPPING_IDENTIFIER_VALUE'] = 'identifier';
				}
			};

			var init = function () {
				$scope.parameters = {};
				LdapServices.getParameters().then(function (response) {
					$scope.parameters = response.data;
					initDefaultValues();
				});
			};

			permissionChecker.checkGlobalPermission(
				'parameters',
				function () {
					init();
				},
				function () {
					$scope.ldap_unauthorised = true;
				}
			);

			$scope.saveParameters = function () {
				LdapServices.updateParameters($scope.parameters).then(function (
					response
				) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopSuccessSavedParams
					);
					$('#confirmationModal').modal('hide');
				});
			};

			$scope.testIsEnabled = function () {
				return (
					$scope.parameters &&
					$scope.parameters['CONNECTION_BASE'] &&
					$scope.parameters['CONNECTION_PROTOCOL'] &&
					$scope.parameters['CONNECTION_PORT'] &&
					$scope.parameters['CONNECTION_PASSWORD'] &&
					$scope.parameters['CONNECTION_USERDN'] &&
					$scope.parameters['CONNECTION_HOSTNAME']
				);
			};

			$scope.testConnection = function () {
				let parameters = {};
				parameters['CONNECTION_BASE'] = $scope.parameters['CONNECTION_BASE'];
				parameters['CONNECTION_PROTOCOL'] =
					$scope.parameters['CONNECTION_PROTOCOL'];
				parameters['CONNECTION_PORT'] = $scope.parameters['CONNECTION_PORT'];
				parameters['CONNECTION_PASSWORD'] =
					$scope.parameters['CONNECTION_PASSWORD'];
				parameters['CONNECTION_USERDN'] =
					$scope.parameters['CONNECTION_USERDN'];
				parameters['CONNECTION_HOSTNAME'] =
					$scope.parameters['CONNECTION_HOSTNAME'];
				LdapServices.testConnection(parameters).then(function (response) {
					if (response.data) {
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopSuccessConnectionToLdap
						);
					} else {
						toaster.pop('warning', toasterPopFailedConnectionToLdap);
					}
				});
			};

			$scope.getRemoteUsers = function () {
				delete $scope.userGridOptions;
				delete $scope.synchronisationResult;
				$scope.isLoading = true;
				LdapServices.getRemoteUsers($scope.parameters)
					.then(function (response) {
						$('#usersModal').modal('show');
						$scope.userGridOptions = getDefaultDxGridConfig(
							PAGINATIONS_SIZE,
							PAGINATIONS_SIZES
						);
						$scope.userGridOptions.columns = getUserGridColumns();
						$scope.userGridOptions.dataSource = response.data;
						$scope.isLoading = false;
					})
					.catch(function (ex) {
						$log.debug(ex);
						$scope.isLoading = false;
					});
			};

			$scope.synchroniseUsers = function () {
				delete $scope.synchronisationResult;
				$scope.isLoading = true;
				LdapServices.synchroniseUsers($scope.parameters)
					.then(function (response) {
						$scope.isLoading = false;
						if (!response.data.error) {
							$scope.synchronisationResult = response.data;
						} else {
							toaster.pop('error', toasterPopFailedSyncLdap);
						}
					})
					.catch(function (ex) {
						$log.debug(ex);
						$scope.isLoading = false;
					});
			};

			let loginTra = gettextCatalog.getString("Identifiant de l'utilisateur");
			let mailTra = gettextCatalog.getString('Courriel');
			let lastNameTra = gettextCatalog.getString('Nom');
			let firstNameTra = gettextCatalog.getString('Prénom');
			let phoneTra = gettextCatalog.getString('Téléphone');
			let groupsTra = gettextCatalog.getString('Groupes');
			var getUserGridColumns = function () {
				let columns = [];
				columns.push({
					caption: loginTra,
					dataField: 'login',
					sortOrder: 'asc',
					allowReordering: false,
				});
				columns.push({
					caption: mailTra,
					dataField: 'email',
					allowReordering: false,
				});

				if ($scope.parameters['USER_MAPPING_FIRST_NAME']) {
					columns.push({
						caption: lastNameTra,
						dataField: 'first_name',
						allowReordering: false,
					});
				}

				if ($scope.parameters['USER_MAPPING_LAST_NAME']) {
					columns.push({
						caption: firstNameTra,
						dataField: 'last_name',
						allowReordering: false,
					});
				}

				if ($scope.parameters['USER_MAPPING_PHONE']) {
					columns.push({
						caption: phoneTra,
						dataField: 'phone',
						allowReordering: false,
					});
				}

				if ($scope.parameters['GROUP_SYNCHRONISATION_ACTIVE']) {
					columns.push({
						caption: groupsTra,
						dataField: 'groups',
						allowReordering: false,
					});
				}

				return columns;
			};
		},
	]);
})();
