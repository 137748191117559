(function () {
	'use strict';

	angular.module('dcApp').controller('UserProfileController', [
		'$rootScope',
		'$scope',
		'$stateParams',
		'$window',
		'toaster',
		'UserService',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'gettextCatalog',
		'LangueService',
		'MEMBER_ROLE_NAME',
		'ADMIN_ROLE_NAME',
		'PROJECT_ROLE_NAME',
		'LANG_NAME',
		function (
			$rootScope,
			$scope,
			$stateParams,
			$window,
			toaster,
			UserService,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			gettextCatalog,
			LangueService,
			MEMBER_ROLE_NAME,
			ADMIN_ROLE_NAME,
			PROJECT_ROLE_NAME,
			LANG_NAME
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopSaveAvatar = gettextCatalog.getString(
				'Avatar enregistré !'
			);
			let toasterPopSaveCompte = gettextCatalog.getString(
				'Compte enregistré !'
			);
			let toasterPopEditPassword = gettextCatalog.getString(
				'Mot de passe modifié !'
			);
			let toasterPopEditMail = gettextCatalog.getString('E-mail modifié !');
			let toasterPopTokenSaved = gettextCatalog.getString('Jeton enregistré');
			let toasterPopTokenDeleted = gettextCatalog.getString('Jeton supprimé');
			let toasterPopResetMailPassword = gettextCatalog.getString(
				'E-mail de réinitialisation du mot de passe envoyé'
			);

			$scope.datePattern = 'DD/MM/YYYY HH:mm';
			$scope.languages = [];

			$scope.projectRoleName = PROJECT_ROLE_NAME;
			$scope.adminRoleName = ADMIN_ROLE_NAME;
			$scope.memberRoleName = MEMBER_ROLE_NAME;
			$scope.copyTokenTitle = gettextCatalog.getString('Copier le jeton');
			let noActiveToken = gettextCatalog.getString(
				"Vous n'avez aucun jeton actif pour l'instant"
			);
			let nameTra = gettextCatalog.getString('Nom');
			let creationDateTra = gettextCatalog.getString('Date de création');
			let lastAccessDateTra = gettextCatalog.getString('Dernier accès');
			let expDelay = gettextCatalog.getString("Délai d'expiration");

			var tokenDataGridOptions = getDefaultDxGridConfig(
				PAGINATIONS_SIZE,
				PAGINATIONS_SIZES
			);
			tokenDataGridOptions.noDataText = noActiveToken;
			tokenDataGridOptions.columns = [
				{ caption: 'Id', dataField: 'id' },
				{
					caption: creationDateTra,
					dataField: 'start',
					width: '35.8%',
					allowReordering: true,
				},
				{
					caption: lastAccessDateTra,
					dataField: 'last_access',
					width: '35.8%',
					allowReordering: true,
				},
			];

			let yearsTra = gettextCatalog.getString('an.s');
			let monthsTra = gettextCatalog.getString('mois');
			let daysTra = gettextCatalog.getString('jour.s');
			let hoursTra = gettextCatalog.getString('heure.s');
			let minTra = gettextCatalog.getString('min.s');

			$scope.sendResetPassword = function () {
				UserService.sendResetPasswordMail().then(function (response) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopResetMailPassword
					);
				});
			};

			$scope.updateUserProfile = function () {
				UserService.updateUserProfile($scope.currentUser).then(function () {
					$window.localStorage.setItem(LANG_NAME, $scope.currentUser.locale);
					toaster.pop('success', toasterPopSuccess, toasterPopSaveCompte);
					$window.location.reload();
				});
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.currentUser = {};
				$scope.currentUser.active = true;
				$scope.currentUser.visible = true;
				$scope.currentUser.editable = true;
				$scope.currentUser.locked = true;
				$scope.currentUser.password = '';
				$scope.currentUser.password_confirmation = '';
				$scope.currentUser.password = '';

				UserService.getUserProfile().then(function (response) {
					$scope.currentUser = response.data;
					if (!$scope.currentUser.locale) {
						$scope.currentUser.locale = $rootScope.currentLangCode;
					}
				});

				LangueService.getLangues().then(function (response) {
					$scope.languages = response.data;
				});
			}

			$scope.changeClient = function () {
				if ($scope.ckClient.selected) {
					getTokens();
				}
			};
			$scope.ckClient = {};
			$scope.changeClientType = function () {
				delete $scope.newTokenCreated;
				delete $scope.ckClient.selected;
				if ($scope.clientType === 'generic') {
					UserService.getClients().then(function (response) {
						$scope.clients = response.data;
					});
				} else {
					getTokens();
				}
			};

			$scope.refreshTokenList = function () {
				getTokens();
			};

			var getTokens = function () {
				$scope.newToken = {};
				delete $scope.tokenDataGridOptions;
				let service;
				if ($scope.clientType === 'generic' && $scope.ckClient.selected) {
					service = UserService.getTokens(
						$scope.ckClient.selected.id,
						$scope.onlyOffline === true
					);
				} else {
					service = UserService.getExpositionAccessTokens();
				}
				service.then(function (response) {
					$scope.tokensCount = response.data.length;
					$scope.tokenDataGridOptions = angular.copy(tokenDataGridOptions);
					if ($scope.clientType === 'generic' && !$scope.onlyOffline) {
						$scope.tokenDataGridOptions.columns.push({
							cellTemplate: 'actions',
						});
					}
					for (var t in response.data) {
						response.data[t].start = formatDate(response.data[t].start);
						response.data[t].last_access = formatDate(
							response.data[t].last_access
						);
					}

					$scope.tokenDataGridOptions.dataSource = response.data;
				});
			};

			$scope.createToken = function () {
				let service;
				if ($scope.clientType === 'generic' && $scope.ckClient.selected) {
					service = UserService.createToken({
						password: $scope.userPassword,
						client_id: $scope.ckClient.selected.id,
						offline: $scope.offline,
					});
				} else {
					service = UserService.createExpositionAccessToken({
						password: $scope.userPassword,
					});
				}
				service.then(function (response) {
					$scope.newTokenCreated = response.data;
					$scope.newTokenCreated.formatted_expires_in = formatDuration(
						$scope.newTokenCreated.expires_in * 1000
					);
					$scope.newTokenCreated.formatted_refresh_expires_in = formatDuration(
						$scope.newTokenCreated.refresh_expires_in * 1000
					);
					toaster.pop('success', toasterPopSuccess, toasterPopTokenSaved);
					getTokens();
				});
			};

			$scope.deleteToken = function (sessionId) {
				UserService.deleteToken(sessionId).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopTokenDeleted);
					delete $scope.newTokenCreated;
					getTokens();
				});
			};

			$scope.deleteAllTokens = function () {
				UserService.deleteAllTokens(
					$scope.ckClient.selected.id,
					$scope.onlyOffline === true
				).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopTokenDeleted);
					delete $scope.newTokenCreated;
					getTokens();
				});
			};

			$scope.copyToClipboard = function (value) {
				copyToClipboard(value);
				toaster.pop(
					'success',
					toasterPopSuccess,
					gettextCatalog.getString('Copie effectuée !')
				);
			};

			var formatDuration = function (durationMS) {
				let duration = moment.duration(durationMS);
				let days = Math.trunc(duration.asDays());
				let hours = duration.hours();
				let minutes = duration.minutes();
				let months = duration.months();
				let years = duration.years();

				if (years >= 1) {
					return years + ' ' + yearsTra;
				}

				if (months >= 1) {
					return months + ' ' + monthsTra;
				}

				if (days >= 1) {
					return days + ' ' + daysTra;
				}

				if (hours >= 1) {
					return hours + ' ' + hoursTra;
				}

				return minutes + ' ' + minTra;
			};
		},
	]);
})();
