(function () {
	'use strict';

	angular.module('dcApp').directive('dcScroller', [
		'$document',
		function ($document) {
			let stickyElement = function (
				elem,
				elementClassToFind,
				elementClassToAdd,
				offset
			) {
				let elemChild = elem[0].querySelector(elementClassToFind);
				if (elemChild) {
					let $page = window.pageYOffset;
					let top = elemChild.getBoundingClientRect().top;
					if ($page >= top - offset) {
						if (
							elemChild.classList &&
							!elemChild.classList.contains(elementClassToAdd)
						) {
							elemChild.classList.add(elementClassToAdd);
						}
					} else {
						if (
							elemChild.classList &&
							elemChild.classList.contains(elementClassToAdd)
						) {
							elemChild.classList.remove(elementClassToAdd);
						}
					}
				}
			};
			return {
				restrict: 'A',
				link: function (scope, elem, attrs) {
					$document.bind('scroll', function () {
						// dc bar element menu
						stickyElement(elem, '.right-menu', 'right-menu-sticky', 40);
						// dash board visualisation centering header
						stickyElement(
							elem,
							'.dc-dashboard-header-content',
							'dc-dashboard-header-content-fixed',
							0
						);
					});
				},
			};
		},
	]);
})();
