(function () {
	'use strict';

	angular.module('dcApp').factory('DownloadFileService', [
		'$rootScope',
		'$http',
		'$window',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, $window, API_BASE_URL_BACKEND) {
			var DownloadFileService = {};

			DownloadFileService.download = function (filePath, type) {
				const url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('FILE_DOWNLOAD_TOKEN') +
					'?filePath=' +
					filePath +
					'&type=' +
					type;
				return $http.get(url).then(function (response) {
					const downloadFileUrl =
						API_BASE_URL_BACKEND +
						$rootScope.getUrl('FILE_DOWNLOAD') +
						'?token=' +
						encodeURIComponent(response.data);
					$window.open(downloadFileUrl, '_blank');
				});
			};

			return DownloadFileService;
		},
	]);
})();
