(function () {
	'use strict';

	angular.module('dcApp').controller('TracesController', [
		'$scope',
		'$location',
		'GenericService',
		'permissionChecker',
		'ProjectService',
		'gettextCatalog',
		'AppSelectorService',
		function (
			$scope,
			$location,
			GenericService,
			permissionChecker,
			ProjectService,
			gettextCatalog,
			AppSelectorService
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.element = gettextCatalog.getString('Elément');
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.codeAction = gettextCatalog.getString('Code action');
			dataGirdColumnName.detail = gettextCatalog.getString('Détails');
			dataGirdColumnName.user = gettextCatalog.getString('Utilisateur');

			var traces = new DevExpress.data.CustomStore({
				load: function (loadOptions) {
					var parameters = {};

					parameters.skip = loadOptions.skip || 0;
					parameters.take = loadOptions.take || 12;
					var options = { filter: [], sort: {} };

					for (var s in loadOptions.sort) {
						options.sort[loadOptions.sort[s].selector] =
							loadOptions.sort[s].desc;
					}

					if (loadOptions.filter && loadOptions.filter[0]) {
						if (loadOptions.filter[0] instanceof Array) {
							for (var f in loadOptions.filter) {
								if (loadOptions.filter[f] instanceof Array) {
									options.filter.push({
										field: loadOptions.filter[f][0],
										operator: loadOptions.filter[f][1],
										value: loadOptions.filter[f][2],
									});
								}
							}
						} else if (typeof loadOptions.filter[0] === 'string') {
							options.filter.push({
								field: loadOptions.filter[0],
								operator: loadOptions.filter[1],
								value: loadOptions.filter[2],
							});
						}
					}
					$scope.elementFilter = false;
					if ($location.search().element_id && $location.search().type) {
						$scope.elementFilter = true;
						// FIXME: should use $state params
						options.element_id = $location.search().element_id;
						options.element_type = $location.search().type;
					}

					return GenericService.getTraces(
						loadOptions.skip,
						loadOptions.take,
						options
					).then(
						function (response) {
							return {
								data: response.data,
								totalCount: Number(response.headers['x-total-count']),
							};
						},
						function (response) {}
					);
				},
			});

			$scope.deleteElementFilter = function () {
				$location.search({});
				$scope.reloadData();
			};

			$scope.reloadData = function () {
				var grid = $('#gridContainer').dxDataGrid('instance');
				grid.clearFilter();
				grid.clearSorting();
				grid.refresh();
			};

			$scope.filterRow = {
				visible: true,
				applyFilter: 'auto',
			};

			$scope.headerFilter = {
				visible: true,
			};

			let lookupUserDataSource = {
				store: new DevExpress.data.CustomStore({
					loadMode: 'raw',
					load: function () {
						const selectedProjectId = AppSelectorService.getSelectedProjectId();
						return ProjectService.getMembersByProjectId(selectedProjectId).then(
							function (data) {
								return data;
							}
						);
					},
				}),
			};

			let gridOptions = {
				dataSource: {
					store: traces,
				},
				bindingOptions: {
					filterRow: 'filterRow',
				},
				columns: [
					{
						filterOperations: ['contains'],
						caption: dataGirdColumnName.codeAction,
						dataField: 'action',
					},
					{
						filterOperations: ['=', '>=', '<='],
						dataField: 'date',
						caption: 'Date',
						dataType: 'datetime',
						format: 'dd/MM/yyyy HH:mm:ss',
					},
					{
						filterOperations: ['contains'],
						allowFiltering: false,
						allowSorting: false,
						caption: dataGirdColumnName.actions,
						dataField: 'msg',
					},
					{
						allowFiltering: false,
						allowSorting: false,
						caption: dataGirdColumnName.detail,
						dataField: 'details',
					},
					{
						cellTemplate: 'user',
						caption: dataGirdColumnName.user,
						dataField: 'user_full_name',
						allowSorting: false,
						lookup: {
							dataSource: lookupUserDataSource,
							valueExpr: 'memberId',
							displayExpr: 'memberLabel',
						},
					},
					{
						filterOperations: ['contains'],
						caption: dataGirdColumnName.element,
						allowSorting: false,
						dataField: 'element_lib',
					},
				],
				remoteOperations: {
					sorting: true,
					paging: true,
					filtering: true,
				},
				paging: {
					pageSize: 20,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: [20, 50, 100],
				},
				onCellClick: function (e) {
					showValuePreviewOnCellDoubleClick(e, $scope.getShowValuePopup);
				},
			};

			$scope.previewModal = { showValuePreview: false, value: '' };
			$scope.getShowValuePopup = function (val) {
				$scope.previewModal.value = val;
				$scope.previewModal.showValuePreview = true;
			};

			var init = function () {
				$scope.gridOptions = gridOptions;
			};

			permissionChecker.checkGlobalPermission(
				'traces',
				function () {
					init();
				},
				function () {
					$scope.traces_unauthorised = true;
				}
			);
		},
	]);
})();
