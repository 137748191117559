'use strict';

angular
	.module('dcApp')
	.controller('GridListController', [
		'$location',
		'$scope',
		'$http',
		'$state',
		'$stateParams',
		'$rootScope',
		'GridService',
		'toaster',
		'$q',
		'API_BASE_URL_BACKEND',
		'DEFAULT_CELL_TEMPLATE',
		'$timeout',
		'PermissionService',
		'gettextCatalog',
		'DcElementListService',
		function (
			$location,
			$scope,
			$http,
			$state,
			$stateParams,
			$rootScope,
			GridService,
			toaster,
			$q,
			API_BASE_URL_BACKEND,
			DEFAULT_CELL_TEMPLATE,
			$timeout,
			PermissionService,
			gettextCatalog,
			DcElementListService
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.type = gettextCatalog.getString('Type');
			dataGirdColumnName.updateDate = gettextCatalog.getString(
				'Date de mise à jour'
			);
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.code = gettextCatalog.getString('Code');
			dataGirdColumnName.creationDate =
				gettextCatalog.getString('Date de création');
			dataGirdColumnName.description = gettextCatalog.getString('Description');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteTableau = gettextCatalog.getString(
				'Suppression du Tableau effectuée'
			);
			let toasterPopDeleteTableaux = gettextCatalog.getString(
				'Suppression des Tableaux effectuée'
			);
			let toasterPopDuplicateTableau = gettextCatalog.getString(
				'Duplication du tableau effectuée'
			);

			$scope.checkPermissionData = {};
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			PermissionService.haveGlobalPermission('grid-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'grid';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.editGrid = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'grid';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.duplicateGrid = function (obj) {
				GridService.duplicateGrid(obj.type, obj.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDuplicateTableau);
					$state.go('grids-edit', { id: response.data, type: obj.type });
				});
			};

			$scope.createGrid = function () {
				$state.go('grids-select', {});
			};

			$scope.goToEdit = function () {
				$state.go('grids-edit', {
					id: $scope.objToEdit.id,
					type: $scope.objToEdit.type,
				});
			};

			$scope.viewGrid = function (obj) {
				$state.go('grids-view', {
					id: obj.id,
					type: obj.type,
					mode: 'visualisation',
				});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkGrids
						: $scope.deleteGrid,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.deleteGrid = function () {
				GridService.deleteGrid(
					$scope.objToDelete.type,
					$scope.objToDelete.id
				).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableau);
					afterDeleteGrid();
				});
			};

			$scope.disableAllSelectedElems = true;
			$scope.deleteBulkGridsModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkGrids = function () {
				let gridIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				GridService.deleteBulkGrids(gridIds)
					.then(function () {
						if ($scope.grid_authorised) {
							afterDeleteGrid();
						}
						toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableaux);
					})
					.catch(function (e) {
						if (e && e.data && e.data.code == '40' && $scope.grid_authorised) {
							afterDeleteGrid();
						}
					});
			};

			var afterDeleteGrid = function () {
				initGrids();
				$scope.gridInstance.clearSelection();
			};

			function getListGrids() {
				var deferred = $q.defer();
				GridService.getAllGrids($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);

				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListGrids().then(function (content) {
					if (content !== undefined) {
						$scope.grids = [];
						$scope.tags = [];
						var tags = [];
						for (var r in content) {
							$scope.grids.push(
								DcElementListService.buildHdListElement(
									content[r],
									$rootScope,
									false
								)
							);
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.showGrid = true;
						$scope.gridData = angular.copy($scope.grids);
						$scope.dataGridOptions.dataSource = $scope.gridData;
					}
				});
			};

			function initGrids() {
				PermissionService.haveGlobalPermission('grid').then(function (
					response
				) {
					$scope.grid_authorised = response.data;
					if ($scope.grid_authorised) {
						$scope.getGridData();
					}
				});
			}

			$scope.moveOrCopyDataGrid = {};
			$scope.moveOrCopyGridsToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyDataGrid = {
					elements: connectorIds,
					type: 'grid',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneGridToProject = function (elementId) {
				$scope.moveOrCopyDataGrid = {
					elements: [elementId],
					type: 'grid',
					showMoveCopy: true,
				};
			};

			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'grid', false);
			initGrids();
		},
	])
	.controller('GridEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'GridService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'hdSourceService',
		'gettextCatalog',
		'ChartService',
		'PermissionService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			GridService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			hdSourceService,
			gettextCatalog,
			ChartService,
			PermissionService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Tableaux');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteTableau = gettextCatalog.getString(
				'Suppression du Tableau effectuée'
			);
			let toasterPopSaveTableau = gettextCatalog.getString(
				'Enregistrement des paramètres effectué'
			);
			let toasterPopCreationTableau = gettextCatalog.getString(
				'Création du Tableau effectuée'
			);
			let toasterPopEditTableau = gettextCatalog.getString(
				'Modification du Tableau effectuée'
			);

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};
			$scope.dataModel = {};
			$scope.gridView = {};
			$scope.columnLabelMaxLength = 12;

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('grids');
				}, 300);
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.grid && $scope.grid.metadata
							? $scope.grid.metadata.label
							: undefined,
					doDelete: $scope.deleteGrid,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.grid.id;
				$scope.checkPermissionData.type = 'grid';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteGrid = function () {
				GridService.deleteGrid($scope.grid.type, $scope.grid.id).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableau);
					$timeout(function () {
						$state.go('grids');
					}, 300);
				});
			};

			$scope.toggleShowTabs = function () {
				$scope.hideTabs = !$scope.hideTabs;
			};

			//handle data source directive
			$scope.onLoadSource = function (noAvailableSources) {
				$scope.disabledConfig = noAvailableSources;
				$scope.sourceExists = !noAvailableSources;
				if ($scope.disabledConfig) {
					$scope.disabledVisualisation = true;
				}
			};
			$scope.dataSource = {
				onOpenSource: $scope.toggleShowTabs,
				onLoadSource: $scope.onLoadSource,
			};
			//handle data source directive

			$scope.showDataBlockViews = function (saveSelectedDataBlockViews) {
				$scope.widgetData.saveSelectedDataBlockViews =
					saveSelectedDataBlockViews;
				$scope.widgetData.showDataBlockViews = true;
			};

			$scope.dataSource.showDataBlockViews = $scope.showDataBlockViews;

			$scope.showSources = function () {
				if (!$scope.configSaved && $scope.config) {
					$scope.saveConfig(showSources);
					return;
				}
				showSources();
			};

			var showSources = function () {
				delete $scope.gridView.gridId;
				$scope.dataSource.reinit();
				$scope.configSaved = true;
			};

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.grid.metadata.label;
				$scope.elementGraphData.centeredElementType = 'GRID';
				$scope.elementGraphData.centeredElementId = $scope.grid.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.grid.id;
				$scope.checkPermissionData.type = 'grid';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.grid.metadata.id;
				$scope.permissionData.type = 'grid';
				$scope.permissionData.elementId = $scope.grid.id;
				$scope.permissionData.lib = $scope.grid.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showConfig = function () {
				delete $scope.gridView.gridId;
				$scope.configSaved = false;
				delete $scope.columnsLoaded;
				GridService.getGridConfig($stateParams.type, $stateParams.id).then(
					function (response) {
						$scope.config = response.data;
						GridService.getGridOptions().then(function (responseGOptions) {
							initConstants(responseGOptions.data);
							if ($scope.config.global.title.text === undefined) {
								$scope.config.global.title.text = $scope.config.groups[0].lib;
								$scope.config.global.title.subtitle.text =
									$scope.config.groups[0].desc;
							}
							setGroupColumns(0);
						});
					}
				);
			};

			$scope.getPaletteColors = function (paletteName) {
				return paletteName
					? DevExpress.viz.getPalette(paletteName.replace('_', ' ')).simpleSet
					: [];
			};

			var setGridColumns = function () {
				if ($stateParams.type == 'simple') {
					setSimpleGridColumns();
				} else {
					setPivotGridColumns();
				}
			};
			var dxListConfig = {
				repaintChangesOnly: true,
				keyExpr: 'uuid',
				displayExpr: 'lib',
				itemDragging: {
					allowReordering: true,
				},
			};

			var rowColsItems = [];
			var columnColsItems = [];
			var valueColsItems = [];

			var setPivotGridColumns = function () {
				rowColsItems = _.filter(
					$scope.config.groups[0].columns,
					function (item) {
						return $scope.config.global.rows.indexOf(item.uuid) > -1;
					}
				);
				columnColsItems = _.filter(
					$scope.config.groups[0].columns,
					function (item) {
						return $scope.config.global.columns.indexOf(item.uuid) > -1;
					}
				);
				valueColsItems = _.filter(
					$scope.config.groups[0].columns,
					function (item) {
						return $scope.config.global.values.indexOf(item.uuid) > -1;
					}
				);
				var availableColumnsItems = $scope.config.groups[0].columns;

				let availableColumns = angular.copy(dxListConfig);
				availableColumns.items = availableColumnsItems;
				availableColumns.itemDragging.group = 'all';
				availableColumns.itemDragging.onDragStart = function (e) {
					e.itemData = availableColumnsItems[e.fromIndex];
				};
				availableColumns.itemDragging.onAdd = function (e) {
					e.itemData = availableColumnsItems[e.fromIndex];
				};
				availableColumns.itemDragging.onRemove = function (e) {};
				$('#availableColumns').dxList(availableColumns).dxList('instance');

				let rowCols = angular.copy(dxListConfig);
				rowCols.itemDragging.group = 'all';
				rowCols.items = rowColsItems;
				rowCols.itemDragging.onDragStart = function (e) {
					e.itemData = rowColsItems[e.fromIndex];
				};
				rowCols.itemDragging.onAdd = function (e) {
					rowColsItems.splice(e.toIndex, 0, e.itemData);
					rowColsList.option('items', rowColsItems);
				};
				rowCols.itemDragging.onRemove = function (e) {
					rowColsItems.splice(e.fromIndex, 1);
					rowColsList.option('items', rowColsItems);
				};
				var rowColsList = $('#rowCols').dxList(rowCols).dxList('instance');

				let columnCols = angular.copy(dxListConfig);
				columnCols.itemDragging.group = 'all';
				columnCols.items = columnColsItems;
				columnCols.itemDragging.onDragStart = function (e) {
					e.itemData = columnColsItems[e.fromIndex];
				};
				columnCols.itemDragging.onAdd = function (e) {
					columnColsItems.splice(e.toIndex, 0, e.itemData);
					columnColsList.option('items', columnColsItems);
				};
				columnCols.itemDragging.onRemove = function (e) {
					columnColsItems.splice(e.fromIndex, 1);
					columnColsList.option('items', columnColsItems);
				};
				var columnColsList = $('#columnCols')
					.dxList(columnCols)
					.dxList('instance');

				let valueCols = angular.copy(dxListConfig);
				valueCols.itemDragging.group = 'all';
				valueCols.items = valueColsItems;
				valueCols.itemDragging.onDragStart = function (e) {
					e.itemData = valueColsItems[e.fromIndex];
				};
				valueCols.itemDragging.onAdd = function (e) {
					valueColsItems.splice(e.toIndex, 0, e.itemData);
					valueColsList.option('items', valueColsItems);
				};
				valueCols.itemDragging.onRemove = function (e) {
					valueColsItems.splice(e.fromIndex, 1);
					valueColsList.option('items', valueColsItems);
				};
				var valueColsList = $('#valueCols')
					.dxList(valueCols)
					.dxList('instance');
			};

			var setSimpleGridColumns = function () {
				if (!$scope.config.global.columns) {
					$scope.config.global.columns = [];
				}
				if (!$scope.config.groups[0]) {
					return;
				}

				for (var c in $scope.config.groups[0].columns) {
					var column = _.find($scope.config.global.columns, function (item) {
						return (
							item.source_column == $scope.config.groups[0].columns[c].uuid
						);
					});
					if (!column) {
						$scope.config.global.columns.push({
							visible: true,
							source_column: $scope.config.groups[0].columns[c].uuid,
							lib: $scope.config.groups[0].columns[c].lib,
						});
					} else {
						column.lib = $scope.config.groups[0].columns[c].lib;
					}
				}

				//delete unvailable columns
				let newColumns = [];
				for (var c1 in $scope.config.global.columns) {
					if (
						_.find($scope.config.groups[0].columns, function (item) {
							return (
								$scope.config.global.columns[c1].source_column == item.uuid
							);
						})
					) {
						newColumns.push($scope.config.global.columns[c1]);
					}
				}
				$scope.config.global.columns = newColumns;
				$scope.columnsLoaded = true;
			};

			var initConstants = function (data) {
				$scope.gridOptions = data;
			};

			var setGroupColumns = function (i) {
				if (!$scope.config.groups[i]) {
					setGridColumns();
					return;
				}
				hdSourceService
					.getSourceColumns(
						$scope.config.groups[i].source_id,
						'GRID',
						$stateParams.id
					)
					.then(function (response) {
						$scope.config.groups[i].columns = response.data;
						setGroupColumns(i + 1);
					});
			};

			$scope.getHorizontalAlignements = function () {
				if ($scope.gridOptions) {
					return _.filter($scope.gridOptions.alignment_types, function (item) {
						return (
							item.code == 'left' ||
							item.code == 'center' ||
							item.code == 'right'
						);
					});
				}
				return [];
			};

			$scope.colCaptionOnEdit = {};
			$scope.waitEditInputToDisappear = {};
			$scope.initColumnShownLabel = function (col) {
				$scope.colCaptionOnEdit[col.source_column] = false;
				$scope.waitEditInputToDisappear[col.source_column] = false;
				col.caption = col.caption ? col.caption : col.lib;
			};

			$scope.activeColCaptionEditMode = function (colId) {
				$scope.colCaptionOnEdit[colId] = true;
				$scope.waitEditInputToDisappear[colId] = true;
				$timeout(function () {
					$('#grids_config_column_caption_' + colId).focus();
				}, 50);
			};

			$scope.endColEditMode = function (coldId, $event) {
				if ($event.keyCode == 13) {
					$scope.colCaptionOnEdit[coldId] = false;
					$timeout(function () {
						// prevent html changing too fast
						$scope.waitEditInputToDisappear[coldId] = false;
					}, 250);
				}
			};

			$scope.getSubTypes = function (type) {
				if (!$scope.gridOptions) {
					return [];
				}
				if (type == 'bar') {
					return $scope.gridOptions.bar_chart_sub_types;
				}
				if (type == 'line') {
					return $scope.gridOptions.line_chart_sub_types;
				}
				if (type == 'area') {
					return $scope.gridOptions.area_chart_sub_types;
				}
			};

			var getSimpleGridConfig = function () {
				var config = angular.copy($scope.config);
				if (config.groups[0]) {
					config.groups = [{ id: config.groups[0].id, config: {} }];
				} else {
					config.groups = [];
				}
				for (var c in config.global.columns) {
					delete config.global.columns[c].lib;
				}
				return config;
			};

			var getPivotGridConfig = function () {
				var config = angular.copy($scope.config);
				config.global.rows = [];
				config.global.columns = [];
				config.global.values = [];
				delete config.columns;

				for (let c in rowColsItems) {
					config.global.rows.push(rowColsItems[c].uuid);
				}
				for (let c in columnColsItems) {
					config.global.columns.push(columnColsItems[c].uuid);
				}
				for (let c in valueColsItems) {
					config.global.values.push(valueColsItems[c].uuid);
				}
				return config;
			};

			$scope.saveConfig = function (afterSave) {
				if (!checkConfig()) {
					return;
				}
				var config =
					$stateParams.type == 'simple'
						? getSimpleGridConfig()
						: getPivotGridConfig();

				GridService.editGridConfig(
					$stateParams.type,
					$stateParams.id,
					config
				).then(function (response) {
					//$scope.configSaved = true;
					toaster.pop('success', toasterPopSuccess, toasterPopSaveTableau);
					$scope.disabledVisualisation = false;
					$scope.configIsValid = true;
					if (afterSave) {
						afterSave();
					}
				});
			};

			function checkConfig() {
				return true;
			}

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.config) {
					PermissionService.havePermission(
						$stateParams.id,
						'edit',
						'grid'
					).then(function (response) {
						if (response.data) {
							$scope.saveConfig(showVisualization);
						} else {
							showVisualization();
						}
					});
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				$scope.configSaved = true;
				if ($scope.sourceExists && $scope.configIsValid) {
					if (!$scope.gridView.gridId) {
						$scope.gridView.gridId = $stateParams.id;
					} else {
						$scope.gridView.reinit();
					}
				}
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.type = $stateParams.type;
				$scope.savingInProgress = false;

				if ($stateParams.id) {
					$scope.isNew = false;
					$scope.metadata.isNew = false;
					//if no source is available => deactivate config and visualisation tab. Else activate config tab
					hdSourceService
						.getNbrSourcesByElement('GRID', $stateParams.id)
						.then(function (response) {
							$scope.sourceExists = response.data;
							if ($scope.sourceExists) {
								GridService.checkGridConfig(
									$stateParams.type,
									$stateParams.id
								).then(function (responseCheck) {
									$scope.configIsValid = responseCheck.data;
									if ($stateParams.mode === 'visualisation') {
										$('.nav-tabs a[data-target="#visualization"]').tab('show');
										$scope.showVisualization();
									} else {
										$('.nav-tabs a[data-target="#config"]').tab('show');
										$scope.disabledVisualisation = !$scope.configIsValid;
										$scope.showConfig();
									}
								});
							} else if ($stateParams.mode === 'visualisation') {
								$scope.disabledConfig = true;
								$('.nav-tabs a[data-target="#visualization"]').tab('show');
								$scope.showVisualization();
							} else {
								$scope.disabledConfig = true;
								$scope.disabledVisualisation = true;
							}
						});
					var gridId = $stateParams.id;
					$scope.dataSource.hdId = gridId;
					$scope.dataSource.hdType = 'GRID';
					$scope.dataSource.unableMultiSource = true;

					GridService.getGrid($stateParams.type, gridId).then(function (
						response
					) {
						$scope.grid = response.data;
						$scope.metadata = $scope.grid.metadata;
						$scope.metadataLoaded = true;
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveGrid(true);
			};

			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchGridSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveGrid = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var grid = {};

				grid.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					grid.metadata.code = $scope.metadata.code;
					GridService.createGrid($stateParams.type, grid)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopCreationTableau
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('grids');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('grids-edit', {
										id: response.data,
										type: $stateParams.type,
									});
								}, 300);
							}
						})
						.catch(catchGridSaveErrors);
				} else {
					GridService.editGrid($stateParams.type, $scope.grid.id, grid)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopEditTableau);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('grids');
								}, 300);
							}
						})
						.catch(catchGridSaveErrors);
				}
			};
		},
	]);
