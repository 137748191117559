(function () {
	'use strict';

	angular.module('dcApp').directive('gridtype', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;
					$scope.grid_type = getFormattedGridType(vm.type);
				},
			];

			return {
				restrict: 'E',

				scope: {
					type: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/gridType/gridType.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
				},
			};
		},
	]);
})();
