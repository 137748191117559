(function () {
	'use strict';
	angular.module('dcApp').factory('ErrorInterceptor', ErrorInterceptor);

	ErrorInterceptor.$inject = [
		'$log',
		'$q',
		'$location',
		'toaster',
		'$rootScope',
	];

	function ErrorInterceptor($log, $q, $location, toaster, $rootScope) {
		$rootScope.fields = [];
		return {
			responseError: function (rejection) {
				let data = rejection.data;
				if (data) {
					// Unique Messages to avoid same toaster popup as many times
					if (data instanceof Object || data instanceof Array) {
						data.field_errors =
							data.field_errors != undefined &&
							data.field_errors instanceof Array
								? _.uniq(data.field_errors, function (x) {
										return x.message;
								  })
								: data.field_errors;
					}
					$rootScope.fields = [];
					if (data.code == '37' || data.code == '38' || data.code == '39') {
						$log.debug(
							"Unavailable Context, Code' ",
							data.code,
							"' Message: ",
							data.message
						);
					} else if (data.code == '34') {
						toaster.pop(
							'error',
							$rootScope.controlPermissionErrorMsg,
							data.message
						);
					} else if (data.code == '50') {
						toaster.pop('warning', data.message);
					} else if (data.code == '40') {
						$rootScope.errorData = data.delete_errors_report;
						$rootScope.showDeleteErr = true;
					} else if (data.field_errors) {
						for (var i = 0; i < data.field_errors.length; i++) {
							$rootScope.fields.push(data.field_errors[i].field);
							toaster.pop(
								'error',
								$rootScope.errorMessage,
								data.field_errors[i].message
							);
						}
					} else if (data.code == '44') {
						$log.error('error', data);
					} else if (data.message) {
						$log.error('data.code', data);
						toaster.pop('error', data.message);
					} else if (
						data.error == 'invalid_token' &&
						!$rootScope.noRedirectionIfUnauthorized
					) {
						$rootScope.handleInvalidToken();
					} else if (data.error == 'invalid_grant') {
						$log.debug('invalid_grant');
					} else {
						if (data instanceof Blob) {
							var reader = new FileReader();
							var text = '';
							reader.addEventListener('loadend', (e) => {
								text = e.srcElement.result;
							});
							reader.readAsText(data);
							setTimeout(function () {
								if (JSON.parse(text).code == '34') {
									toaster.pop('warning', JSON.parse(text).message);
								} else {
									toaster.pop('error', JSON.parse(text).message);
								}
							}, 50);
						} else {
							toaster.pop('error', 'Erreur');
						}
					}
				}
				return $q.reject(rejection);
			},
		};
	}
})();
