(function () {
	'use strict';

	angular.module('dcApp').directive('gotoEntitedatablock', [
		'$parse',
		'$window',
		function ($parse, $window) {
			var controller = [
				'$scope',
				'$state',
				function ($scope, $state) {
					var vm = this;
					$scope.goToEntite = function () {
						let url = $state.href('entites-edit', {
							entiteId: $scope.entiteId,
						});
						$window.open(url, '_blank');
						return;
					};
					$scope.goToDataBlock = function () {
						let url = $state.href('datablocks-edit', {
							id: $scope.datablockId,
						});
						$window.open(url, '_blank');
						return;
					};

					$scope.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showGotoEntitedatablock = false;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/goToEntiteDataBlock/goToEntiteDataBlock.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.vm.data.showGotoEntitedatablock = false;
					});
					scope.$watch(
						function () {
							return scope.vm.data
								? scope.vm.data.showGotoEntitedatablock
								: false;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.entiteId = scope.vm.data.gotoEntitedatablockEntiteId;
								scope.datablockId =
									scope.vm.data.gotoEntitedatablockDataBlockId;
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
