(function () {
	'use strict';

	angular.module('dcApp').controller('UserGroupsController', [
		'$rootScope',
		'$scope',
		'$q',
		'toaster',
		'UserGroupService',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		function (
			$rootScope,
			$scope,
			$q,
			toaster,
			UserGroupService,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES
		) {
			function getListGroups() {
				var deferred = $q.defer();
				UserGroupService.getCurrentUserGroups().then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListGroups().then(function (content) {
					if (content !== undefined) {
						$scope.groups = content;
						for (var g in $scope.groups) {
							$scope.groups[g].creation_date =
								$rootScope.getDateTimeWithPatternMn(
									$scope.groups[g].creation_date
								);
							$scope.groups[g].update_date =
								$rootScope.getDateTimeWithPatternMn(
									$scope.groups[g].update_date
								);
						}
						$scope.dataGridOptions.dataSource = $scope.groups;
						$scope.showGrid = true;
					}
				});
			};

			$scope.dataGridOptions = {
				dataSource: $scope.groups,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: 'Libellé',
						dataField: 'label',
					},
					{
						caption: 'Description',
						dataField: 'description',
					},
					{
						caption: 'Date de création',
						dataField: 'creation_date',
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
				onSelectionChanged: function (selectedItems) {
					$scope.selectedGroups = selectedItems.selectedRowsData;
				},
				onRowPrepared: function (e) {
					if (e.rowType === 'data') {
						e.rowElement.css({ height: 55 });
					}
				},
			};

			$scope.getGridData();
		},
	]);
})();
