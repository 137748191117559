(function () {
	'use strict';

	angular.module('dcApp').factory('hdSourceService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$q',
		'$rootScope',
		'httpService',
		function ($http, API_BASE_URL_BACKEND, $q, $rootScope, httpService) {
			var hdSourceService = {};

			var formatAndGetUrl = function (code, sourceId, hdType, hdId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl(code);
				if (sourceId) {
					url = url.replace('{id}', sourceId);
				}
				if (hdType) {
					url = url.replace('{hdType}', hdType);
				}
				if (hdId) {
					url = url.replace('{hdId}', hdId);
				}
				return url;
			};

			hdSourceService.getSource = function (sourceId, hdType, hdId) {
				let url = formatAndGetUrl('HD_SOURCE_GET', sourceId, hdType, hdId);
				return httpService.getRequest(url);
			};

			hdSourceService.getSourceColumns = function (
				sourceId,
				hdType,
				hdId,
				ignoreOperations
			) {
				let url = formatAndGetUrl(
					'HD_SOURCE_GET_COLUMNS',
					sourceId,
					hdType,
					hdId
				);
				if (ignoreOperations) {
					url = url + '?ignoreOperations=' + ignoreOperations;
				}
				return httpService.getRequest(url);
			};

			hdSourceService.getSourcesByElement = function (hdType, hdId) {
				let url = formatAndGetUrl(
					'HD_SOURCE_BY_ELEMENT',
					undefined,
					hdType,
					hdId
				);
				return httpService.getRequest(url);
			};

			hdSourceService.getNbrSourcesByElement = function (hdType, hdId) {
				let url = formatAndGetUrl(
					'HD_SOURCE_BY_ELEMENT_NBR',
					undefined,
					hdType,
					hdId
				);
				return httpService.getRequest(url);
			};

			hdSourceService.editSource = function (sourceId, source, hdType, hdId) {
				let url = formatAndGetUrl('HD_SOURCE_EDIT', sourceId, hdType, hdId);
				return httpService.putRequest(url, source, false);
			};

			hdSourceService.autocomplete = function (autocompleteEntry) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('HD_SOURCE_AUTOCOMPLETE');
				return httpService.postRequest(url, autocompleteEntry);
			};

			hdSourceService.filterAndAggregate = function (filterAndAggregateEntry) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('HD_SOURCE_FILTER_AND_AGGREGATE');
				return httpService.postRequest(url, filterAndAggregateEntry);
			};

			hdSourceService.filterAndAggregatePublication = function (
				filterAndAggregateEntry,
				link
			) {
				let url =
					API_BASE_URL_BACKEND +
					'dashboard_publication/filter_and_aggregate?link=' +
					link;
				return httpService.postRequest(url, filterAndAggregateEntry);
			};

			hdSourceService.autocompletePublication = function (
				autocompleteEntry,
				link
			) {
				let url =
					API_BASE_URL_BACKEND +
					'dashboard_publication/autocomplete?link=' +
					link;
				return httpService.postRequest(url, autocompleteEntry);
			};

			hdSourceService.addSources = function (sources, hdType, hdId) {
				let url = formatAndGetUrl('HD_SOURCE_CREATE', undefined, hdType, hdId);
				return httpService.postRequest(url, sources);
			};

			hdSourceService.deleteSource = function (sourceId, hdType, hdId) {
				let url = formatAndGetUrl('HD_SOURCE_DELETE', sourceId, hdType, hdId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			hdSourceService.activateSource = function (
				sourceId,
				active,
				hdType,
				hdId
			) {
				let url = formatAndGetUrl('HD_SOURCE_ACTIVATE', sourceId, hdType, hdId);
				url = url.replace('{active}', active);
				return httpService.getRequest(url);
			};

			hdSourceService.execData = function (
				config,
				elementId,
				parentId,
				clientId,
				noCache
			) {
				var path = API_BASE_URL_BACKEND + $rootScope.getUrl('HD_SOURCE_EXEC');
				if (elementId !== undefined) {
					path = path + '?elementId=' + elementId;
					if (clientId !== undefined) {
						path = path + '&clientId=' + clientId;
					}
				} else {
					if (clientId !== undefined) {
						path = path + '?clientId=' + clientId;
					}
				}
				if ($rootScope.jobCacheActive && !noCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, config);
			};

			hdSourceService.getDataByJobId = function (jobKey) {
				var path =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('HD_SOURCE_EXEC_RESULT') +
					'?jobKey=' +
					jobKey;
				return httpService.getRequest(path);
			};

			hdSourceService.getHdSourceColumnOrigin = function (
				sourceId,
				uuid,
				hdType,
				hdId
			) {
				let url = formatAndGetUrl(
					'HD_SOURCE_COLUMN_ORIGIN',
					sourceId,
					hdType,
					hdId
				);
				url = url.replace('{uuid}', uuid);
				return httpService.getRequest(url);
			};

			return hdSourceService;
		},
	]);
})();
