(function () {
	'use strict';

	angular.module('dcApp').factory('LdapServices', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var LdapServices = {};

			LdapServices.getParameters = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('LDAP_PARAMETERS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			LdapServices.updateParameters = function (parameters) {
				var deferred = $q.defer();
				$http
					.post(
						API_BASE_URL_BACKEND + $rootScope.getUrl('LDAP_PARAMETERS'),
						parameters
					)
					.then(function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					});
				return deferred.promise;
			};

			LdapServices.testConnection = function (parameters) {
				var deferred = $q.defer();
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('LDAP_CONNECTION_TEST');
				$http.post(url, parameters).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			LdapServices.getRemoteUsers = function (parameters) {
				var deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('LDAP_USERS');
				$http.post(url, parameters).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			LdapServices.synchroniseUsers = function (parameters) {
				var deferred = $q.defer();
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('LDAP_SYNCHRONISE');
				$http.post(url, parameters).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			return LdapServices;
		},
	]);
})();
