'use strict';

angular
	.module('dcApp')
	.constant('LINK_MAPPING_OPERATOR', {
		Equal: 'equal',
		NotEqual: 'not_equal',
		Less: 'less',
		LessOrEqual: 'less_or_equal',
		Greater: 'greater',
		GreaterOrEqual: 'greater_or_equal',
		BeginsWith: 'begins_with',
		NotBeginWith: 'not_begins_with',
		Contains: 'contains',
		NotContains: 'not_contains',
		EndsWith: 'ends_with',
		NotEndWith: 'not_ends_with',
	})
	.factory('DatablockLinkMappingEntity', [
		'LINK_MAPPING_OPERATOR',
		'gettextCatalog',
		function (LINK_MAPPING_OPERATOR, gettextCatalog) {
			function DatablockLinkMappingInstance(data) {
				this.source_mapping_disabled = data && data.has_target_value_mapping;
				this.target_mapping_disabled = data && data.has_source_value_mapping;
				this.left_column_uuid =
					data && data.left_column_uuid ? data.left_column_uuid : undefined;
				this.operator =
					data && data.operator ? data.operator : LINK_MAPPING_OPERATOR.Equal;
				this.right_column_uuid =
					data && data.right_column_uuid ? data.right_column_uuid : undefined;
				this.right_carac_code =
					data && data.right_carac_code ? data.right_carac_code : undefined;
				this.source_value =
					data && data.source_value ? data.source_value : undefined;
				this.has_source_value_mapping =
					data && data.has_source_value_mapping
						? data.has_source_value_mapping
						: false;
				this.target_value =
					data && data.target_value ? data.target_value : undefined;
				this.has_target_value_mapping =
					data && data.has_target_value_mapping
						? data.has_target_value_mapping
						: false;
				this.eligibileColumns = [];
			}

			DatablockLinkMappingInstance.prototype = Object.create(null);
			DatablockLinkMappingInstance.prototype.constructor =
				DatablockLinkMappingInstance;

			DatablockLinkMappingInstance.prototype.checkMappingValidity =
				checkValidity;
			DatablockLinkMappingInstance.prototype.toggleSourceMapping =
				toggleSourceMapping;
			DatablockLinkMappingInstance.prototype.toggleTargetMapping =
				toggleTargetMapping;
			DatablockLinkMappingInstance.prototype.computeEligibleTargetColumns =
				computeEligibleTargetColumns;

			function toggleSourceMapping(originalTargetCols) {
				if (this.has_target_value_mapping) {
					return;
				}
				this.target_mapping_disabled = !this.target_mapping_disabled;
				this.left_column_uuid = undefined;
				this.source_value = '';
				if (this.has_source_value_mapping) {
					this.eligibileColumns = angular.copy(originalTargetCols);
				}
			}

			function toggleTargetMapping() {
				if (this.has_source_value_mapping) {
					return;
				}
				this.source_mapping_disabled = !this.source_mapping_disabled;
				this.right_column_uuid = undefined;
				this.right_carac_code = undefined;
				this.target_value = '';
			}

			function checkValidity(parentLink) {
				if (
					!this.has_source_value_mapping &&
					isInvalidMappingValue(this.left_column_uuid)
				) {
					parentLink.errorMessage = gettextCatalog.getString(
						"La colonne source dans la définition de l'union est obligatoire"
					);
					parentLink.not_valid = true;
				} else if (
					!this.has_target_value_mapping &&
					isInvalidMappingValue(this.right_column_uuid) &&
					isInvalidMappingValue(this.right_carac_code)
				) {
					parentLink.errorMessage = gettextCatalog.getString(
						"La cible destination dans la définition de l'union est obligatoire"
					);
					parentLink.not_valid = true;
				} else if (this.has_source_value_mapping && !this.source_value) {
					parentLink.errorMessage = gettextCatalog.getString(
						'datablock.link.source.valueMapping.invalid'
					);
					parentLink.not_valid = true;
				} else if (this.has_target_value_mapping && !this.target_value) {
					parentLink.errorMessage = gettextCatalog.getString(
						'datablock.link.target.valueMapping.invalid'
					);
					parentLink.not_valid = true;
				}
				return parentLink.not_valid;
			}

			function computeEligibleTargetColumns(
				selectedColumn,
				originalTargetCols
			) {
				if (
					!selectedColumn &&
					this.has_source_value_mapping &&
					!this.source_mapping_disabled
				) {
					this.eligibileColumns = angular.copy(originalTargetCols);
					return;
				}
				if (!selectedColumn) {
					this.eligibileColumns = angular.copy(originalTargetCols);
					return;
				}
				this.eligibileColumns = originalTargetCols.filter(function (tgcl) {
					return tgcl.type === selectedColumn.type;
				});
				return this.eligibileColumns;
			}

			function isInvalidMappingValue(val) {
				return val === undefined || val === null || val === '';
			}

			return DatablockLinkMappingInstance;
		},
	]);
