(function () {
	'use strict';

	angular.module('dcApp').controller('ImportDbController', [
		'$rootScope',
		'$scope',
		'$stateParams',
		'$filter',
		'$http',
		'$window',
		'$q',
		'toaster',
		'ConnectorService',
		'ImportDbService',
		'API_BASE_URL_BACKEND',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'gettextCatalog',
		function (
			$rootScope,
			$scope,
			$stateParams,
			$filter,
			$http,
			$window,
			$q,
			toaster,
			ConnectorService,
			ImportDbService,
			API_BASE_URL_BACKEND,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			gettextCatalog
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopOp = gettextCatalog.getString('Oups..');
			let toasterPopTable = gettextCatalog.getString('Table');
			let toasterPopNotFound = gettextCatalog.getString('introuvable');
			let toasterPopImported = gettextCatalog.getString('Import effectué');

			let entityDepotCreation = {};
			entityDepotCreation.entityAndDepot = gettextCatalog.getString(
				'Entité métier + Dépôt'
			);
			entityDepotCreation.onlyEntity = gettextCatalog.getString(
				'Entité métier seule'
			);
			entityDepotCreation.onlyDepot = gettextCatalog.getString('Dépôt seul');

			let translateErrorMessage = gettextCatalog.getString(
				'context.unavailable'
			);

			$scope.selectedSchemas = {};
			$scope.showHideElmfLagsTable = {};
			$scope.showHideElmfLags = {};
			$scope.isIndeterminate = {};
			$scope.isTableIndeterminate = {};
			$scope.showSystemData = false;
			$scope.param = { createRepDetails: false };

			function init() {
				ConnectorService.getConnectorsList(undefined, 'SQL', false, false, true).then(
					function (response) {
						$scope.connectors = _.sortBy(response.data, function (elm) {
							return elm.lib.toLowerCase();
						});
					}
				);

				ImportDbService.getTypesMaps().then(function (response) {
					$scope.typeMapper = _.object(
						_.map(response.data, function (item) {
							return [item.original_type, item.new_type];
						})
					);
				});
			}

			$scope.getMaxHeight = function () {
				let height = $window.innerHeight * 0.5;
				return {
					'max-height': height,
				};
			};

			/**
			 *
			 * Check if a table is referenced in any other tables
			 * @param schema
			 * @param schemaName
			 * @param tableName
			 * @param notChangeColumnsSelection
			 */
			function isTableIndeterminate(
				schema,
				schemaName,
				tableName,
				notChangeColumnsSelection
			) {
				if (
					schema != undefined &&
					schemaName != undefined &&
					tableName != undefined
				) {
					let table = schema.tables[tableName];
					let selectedColumns = 0;
					if (!table || !table.columns) {
						$scope.isTableIndeterminate[tableName] = false;
					}
					for (let i in table.columns) {
						if (table.columns[i].selected) {
							selectedColumns = selectedColumns + 1;
						}
					}
					let columnsCount = Object.keys(table.columns).length;
					$scope.isTableIndeterminate[tableName] =
						selectedColumns > 0 && selectedColumns < columnsCount;
					if (
						!$scope.isTableIndeterminate[tableName] &&
						columnsCount == selectedColumns &&
						selectedColumns > 0
					) {
						table.selected = true;
					} else if (
						selectedColumns > 0 &&
						$scope.isTableIndeterminate[tableName]
					) {
						$scope.isTableIndeterminate[tableName] = true;
						table.selected = false;
					} else if (selectedColumns == 0) {
						table.selected = false;
					}

					//check for schema
					$scope.checkIsIndeterminate(
						schema,
						schemaName,
						tableName,
						notChangeColumnsSelection,
						true
					);
				}
			}

			$scope.possibleEntitiesCreation = [
				{ value: 'ALL', label: entityDepotCreation.entityAndDepot },
				{
					value: 'ENTITE_ONLY',
					label: entityDepotCreation.onlyEntity,
				},
				{ value: 'DEPOT_ONLY', label: entityDepotCreation.onlyDepot },
			];

			/**
			 * Check if at lest one table is not checked
			 * @param schema
			 * @param schemaName
			 * @param tableName
			 * @param forceSelect
			 * @param notChangeColumnsSelection
			 * @param destLink
			 * @param event
			 */
			$scope.checkIsIndeterminate = function (
				schema,
				schemaName,
				tableName,
				forceSelect,
				notChangeColumnsSelection,
				destLink,
				event
			) {
				let selectedTables = 0;
				let indeterminateTables = 0;
				if (!schema || !schema.tables) {
					$scope.isIndeterminate[schemaName] = false;
				}

				for (let i in schema.tables) {
					selectedTables = schema.tables[i].selected
						? selectedTables + 1
						: selectedTables;
					indeterminateTables = $scope.isTableIndeterminate[tableName]
						? indeterminateTables + 1
						: indeterminateTables;
				}
				let tablesCount = Object.keys(schema.tables).length;
				$scope.isIndeterminate[schemaName] =
					selectedTables > 0 && selectedTables < tablesCount;
				if (
					!$scope.isIndeterminate[schemaName] &&
					tablesCount == selectedTables &&
					selectedTables > 0
				) {
					schema.selected = true;
				} else if (selectedTables == 0 && indeterminateTables > 0) {
					$scope.isIndeterminate[schemaName] = true;
				} else if (selectedTables == 0) {
					schema.selected = false;
				}
				if (!notChangeColumnsSelection) {
					$scope.changeColumnsAndLinksSelection(
						schema.tables[tableName],
						tableName,
						forceSelect,
						destLink,
						notChangeColumnsSelection,
						event
					);
				}
			};

			$scope.unselectColmun = function (
				schema,
				schemaName,
				tableName,
				column,
				modalId
			) {
				if (column.referenced && column.referenced.length > 0) {
					$scope.unselectedData = {
						schema: schema,
						schemaName: schemaName,
						tableName: tableName,
						columnName: column.name,
					};
					$('#' + modalId).modal('show');
				} else {
					$scope.checkTableIndeterminate(
						schema,
						schemaName,
						tableName,
						modalId
					);
				}
			};

			/**
			 * Check if at least one element of a table is not checked and select columns and links
			 * @param schema
			 * @param schemaName
			 * @param tableName
			 * @param modalId
			 * @param destLink
			 * @param notChangeColumnsSelection
			 */
			$scope.checkTableIndeterminate = function (
				schema,
				schemaName,
				tableName,
				modalId,
				destLink,
				notChangeColumnsSelection
			) {
				let table = schema.tables[tableName];
				let selectedColumns = 0;
				if (!table || !table.columns) {
					$scope.isTableIndeterminate[tableName] = false;
				}
				for (let i in table.columns) {
					if (table.columns[i].selected) {
						selectedColumns = selectedColumns + 1;
					} else {
						let refs = table.columns[i].referenced;
						for (let u in refs) {
							if (
								$scope.selectedSchemas[refs[u].schema] &&
								$scope.selectedSchemas[refs[u].schema].tables &&
								$scope.selectedSchemas[refs[u].schema].tables[refs[u].table] &&
								$scope.selectedSchemas[refs[u].schema].tables[refs[u].table]
									.links &&
								$scope.selectedSchemas[refs[u].schema].tables[refs[u].table]
									.links[refs[u].link]
							) {
								$scope.selectedSchemas[refs[u].schema].tables[
									refs[u].table
								].links[refs[u].link].selected = false;
								for (let x in $scope.selectedSchemas[refs[u].schema].tables[
									refs[u].table
								].links[refs[u].link].columns) {
									let elm =
										$scope.selectedSchemas[refs[u].schema].tables[refs[u].table]
											.links[refs[u].link].columns[x];
									$scope.selectedSchemas[elm.from.schema].tables[
										elm.from.table
									].columns[elm.from.name].referenced = _.filter(
										$scope.selectedSchemas[elm.from.schema].tables[
											elm.from.table
										].columns[elm.from.name].referenced,
										function (item) {
											return item.link !== refs[u].link;
										}
									);
									$scope.selectedSchemas[elm.to.schema].tables[
										elm.to.table
									].columns[elm.to.name].referenced = _.filter(
										$scope.selectedSchemas[elm.to.schema].tables[elm.to.table]
											.columns[elm.to.name].referenced,
										function (item) {
											return item.link !== refs[u].link;
										}
									);
								}
							}
						}
						for (let t in table.links) {
							if (table.links[t].from && table.links[t].from.name == i) {
								table.links[t].selected = false;
							}
						}

						table.columns[i].referenced = [];
					}
				}
				let columnsCount = Object.keys(table.columns).length;
				$scope.isTableIndeterminate[tableName] =
					selectedColumns > 0 && selectedColumns < columnsCount;
				if (
					!$scope.isTableIndeterminate[tableName] &&
					columnsCount == selectedColumns &&
					selectedColumns > 0
				) {
					table.selected = true;
				} else if (
					selectedColumns > 0 &&
					$scope.isTableIndeterminate[tableName]
				) {
					$scope.isTableIndeterminate[tableName] = true;
					table.selected = false;
				} else if (selectedColumns == 0) {
					table.selected = false;
				}

				//check for schema
				$scope.checkIsIndeterminate(
					schema,
					schemaName,
					tableName,
					false,
					notChangeColumnsSelection,
					destLink
				);

				if (modalId) {
					$('#' + modalId).modal('hide');
				}
			};

			$scope.changeTablesSelection = function (schema) {
				for (let i in schema.tables) {
					schema.tables[i].selected = schema.selected;
					$scope.changeColumnsAndLinksSelection(
						schema.tables[i],
						i,
						true,
						false,
						false,
						i
					);
				}
			};

			/**
			 * Select colmuns and links
			 * @param table
			 * @param tableName
			 * @param forceSelect
			 * @param destLink
			 * @param notChangeColumnsSelection
			 * @param event
			 */
			$scope.changeColumnsAndLinksSelection = function (
				table,
				tableName,
				forceSelect,
				destLink,
				notChangeColumnsSelection,
				event
			) {
				if (!$scope.isTableIndeterminate[tableName] || forceSelect) {
					for (let i in table.columns) {
						table.columns[i].selected = table.selected;
					}
					if (tableName == event) {
						for (let t in table.links) {
							table.links[t].selected = table.selected;
						}
					}
					if (!destLink) {
						for (let t in table.links) {
							let link = table.links[t];
							let completeFromLink =
								link.fromColumnName != undefined &&
								link.fromSchemaName != undefined &&
								link.fromTableName != undefined;
							let completeToLink =
								link.toColumnName != undefined &&
								link.toSchemaName != undefined &&
								link.toTableName != undefined;
							let completeLink = completeFromLink && completeToLink;
							if (
								table.links[t].selected &&
								link.fromSchemaName != undefined &&
								completeLink
							) {
								$scope.showTableView(
									link.toTableName,
									link.fromSchemaName,
									link.linkName,
									link.fromTableName,
									link.fromColumnName,
									link.toColumnName,
									link.toSchemaName,
									undefined,
									true,
									forceSelect,
									destLink
								);
								if (
									$scope.selectedSchemas[link.fromSchemaName] &&
									$scope.selectedSchemas[link.toSchemaName]
								) {
									isTableIndeterminate(
										$scope.selectedSchemas[link.fromSchemaName],
										link.fromSchemaName,
										link.fromTableName,
										notChangeColumnsSelection
									);
									isTableIndeterminate(
										$scope.selectedSchemas[link.toSchemaName],
										link.toSchemaName,
										link.toTableName,
										notChangeColumnsSelection
									);
								}
							} else {
								isTableIndeterminate(
									$scope.selectedSchemas[link.fromSchemaName],
									link.fromSchemaName,
									link.fromTableName,
									notChangeColumnsSelection
								);
								isTableIndeterminate(
									$scope.selectedSchemas[link.toSchemaName],
									link.toSchemaName,
									link.toTableName,
									notChangeColumnsSelection
								);
							}

							if (!completeLink) {
								if (
									completeFromLink &&
									$scope.selectedSchemas[link.fromSchemaName].tables[
										link.fromTableName
									].links[link.linkName] != undefined
								) {
									$scope.selectedSchemas[link.fromSchemaName].tables[
										link.fromTableName
									].links[link.linkName].selected = false;
								}
								if (
									completeToLink &&
									$scope.selectedSchemas[link.toSchemaName].tables[
										link.toTableName
									].links[link.linkName] != undefined
								) {
									$scope.selectedSchemas[link.toSchemaName].tables[
										link.toTableName
									].links[link.linkName].selected = false;
								}
							}
						}
					}
				}
			};
			$scope.excludePossibleValues = [
				{ value: 'ALL', label: gettextCatalog.getString('Tout') },
				{
					value: 'INCLUDE',
					label: gettextCatalog.getString('Inclure uniquement'),
				},
				{ value: 'EXCLUDE', label: gettextCatalog.getString('Exclure') },
			];
			$scope.excludeSchemas = 'ALL';
			$scope.excludeTables = 'ALL';
			$scope.foreignKey = true;
			$scope.views = true;
			$scope.inclusion = { tables: undefined, schemas: undefined };
			$scope.setSelectedConnector = function (connector) {
				$scope.connectorId = connector != undefined ? connector.id : null;
			};
			$scope.showErrorMessage = false;
			$scope.dataFetched = false;
			$scope.connect = function () {
				$scope.showErrorMessage = false;
				$scope.metadataLoaded = false;
				$scope.dataFetched = false;
				$scope.schemas = [];
				$scope.selectedSchemas = {};
				$scope.showHideElmfLagsTable = {};
				$scope.showHideElmfLags = {};
				$scope.isIndeterminate = {};
				$scope.isTableIndeterminate = {};
				let clientId = generateClientId($rootScope.account.login);
				$scope.currentImportDbConnectClientId = clientId;
				$scope.cancelConnect = true;
				$scope.showSystemData =
					$scope.showSystemData != undefined ? $scope.showSystemData : true;
				let request = {
					connector_id: $scope.connectorId,
					system_data: $scope.showSystemData,
					import_schema_mode: $scope.excludeSchemas,
					import_table_mode: $scope.excludeTables,
					include_foreign_keys: $scope.foreignKey,
					client_id: $scope.currentImportDbConnectClientId,
					include_views: $scope.views,
					tables: $scope.inclusion.tables,
					schemas: $scope.inclusion.schemas,
				};
				ImportDbService.getDbSchemas(request)
					.then(function (response) {
						$scope.schemas = response.data;
						$scope.cancelConnect = false;
						$scope.dataFetched = true;
						$scope.metadataLoaded = true;
					})
					.catch(function (error) {
						$scope.errorMessage =
							error.data && error.data.message
								? error.data.message
								: error.data;
						$scope.showErrorMessage = true;
						$scope.cancelConnect = false;
					});
			};

			$scope.typeFormatter = function (type) {
				if (type != undefined && type != '' && type.length > 0) {
					type = type.replace(/\d/, '').toLowerCase();
					return type.charAt(0).toUpperCase() + type.slice(1);
				} else {
					return type;
				}
			};

			$scope.finalTypesMap = {
				Integer: 'SIMPLE_TYPES_INTEGER_ITEM',
				Boolean: 'SIMPLE_TYPES_BOOLEAN_ITEM',
				Date: 'SIMPLE_TYPES_DATE_ITEM',
				String: 'SIMPLE_TYPES_STRING_ITEM',
				Decimal: 'SIMPLE_TYPES_DECIMAL_ITEM',
				'Big int': 'SIMPLE_TYPES_BIG_INTEGER_ITEM',
				Words: 'TYPES_WORDS_ITEM',
				File: 'TYPES_FILE_ITEM',
			};

			$scope.getType = function (type) {
				type =
					type != undefined && type != '' && type.length > 0
						? type.replace(/\d/, '').toLowerCase()
						: type;
				if (type in $scope.typeMapper) {
					return $scope.typeFormatter($scope.typeMapper[type]);
				} else {
					return 'String';
				}
			};

			$scope.getFinaleType = function (type) {
				if (type in $scope.finalTypesMap) {
					return $scope.finalTypesMap[type];
				} else {
					return 'SIMPLE_TYPES_STRING_ITEM';
				}
			};

			$scope.closeOpenedTables = function (schema) {
				if (schema && schema.tables) {
					for (let i in schema.tables) {
						$scope.showHideElmfLagsTable[schema.tables[i].name] = false;
					}
				}
			};

			/**
			 * show Link table and update referenced columns list
			 * @param tableName
			 * @param schemaName
			 * @param linkName
			 * @param fromTableName
			 * @param columnName
			 * @param toColumnName
			 * @param toSchemaName
			 * @param modalId
			 * @param hideModal
			 * @param forceSelect
			 * @param destLink
			 */
			$scope.showTableView = function (
				tableName,
				schemaName,
				linkName,
				fromTableName,
				columnName,
				toColumnName,
				toSchemaName,
				modalId,
				hideModal,
				forceSelect,
				destLink
			) {
				$scope.table = {};
				let schema = _.find($scope.schemas, function (elem) {
					return elem.name === schemaName;
				});
				let toSchema = _.find($scope.schemas, function (elem) {
					return elem.name === toSchemaName;
				});
				if (schema != undefined && toSchema != undefined) {
					$scope.table = _.find(toSchema.tables, function (elem) {
						return elem.name === tableName;
					});
					let fromTable = _.find(schema.tables, function (elem) {
						return elem.name === fromTableName;
					});

					let fLink = _.find(fromTable.foreign_keys, function (elem) {
						return elem.name === linkName;
					});
					let items = _.map(fLink.column_references, function (item) {
						return [item.foreign_key.name, item.primary_key.name];
					});
					if ($scope.table != undefined && fromTable != undefined) {
						$scope.table.schema = schemaName;
						$scope.table.linkName = linkName;
						$scope.table.fromTableName = fromTableName;
						$scope.table.toSchema = toSchemaName;
						if (
							$scope.selectedSchemas[schemaName].tables[fromTableName].links[
								linkName
							].selected
						) {
							// set ref
							$scope.selectedSchemas[schemaName].tables[fromTableName].links[
								linkName
							].columns = [];
							for (let i in items) {
								columnName = items[i][0];
								toColumnName = items[i][1];
								let from =
									$scope.selectedSchemas[schemaName].tables[fromTableName]
										.columns[columnName];
								from.schema = schemaName;
								from.table = fromTableName;
								from.parent = fromTableName;
								from.name = columnName;
								from.data_type = $scope.getFinaleType(from.type);
								let definedColumns =
									$scope.selectedSchemas[schemaName] !== undefined &&
									$scope.selectedSchemas[schemaName].tables !== undefined &&
									$scope.selectedSchemas[schemaName].tables[fromTableName]
										.columns !== undefined &&
									$scope.selectedSchemas[schemaName].tables[fromTableName]
										.columns[columnName] !== undefined &&
									$scope.selectedSchemas[toSchemaName] !== undefined &&
									$scope.selectedSchemas[toSchemaName].tables !== undefined &&
									$scope.selectedSchemas[toSchemaName].tables[tableName]
										.columns !== undefined &&
									$scope.selectedSchemas[toSchemaName].tables[tableName]
										.columns[toColumnName] !== undefined;
								if (definedColumns) {
									$scope.selectedSchemas[schemaName].tables[
										fromTableName
									].columns[columnName].selected = true;
									$scope.selectedSchemas[toSchemaName].tables[
										tableName
									].columns[toColumnName].selected = true;
									let ref =
										$scope.selectedSchemas[schemaName].tables[fromTableName]
											.columns[columnName].referenced;
									ref = ref != undefined ? ref : [];
									ref.push({
										schema: toSchemaName,
										table: tableName,
										link: linkName,
										col: toColumnName,
									});
									$scope.selectedSchemas[schemaName].tables[
										fromTableName
									].columns[columnName].referenced = ref;
									let to =
										$scope.selectedSchemas[toSchemaName].tables[tableName]
											.columns[toColumnName];
									to.schema = toSchemaName;
									to.table = tableName;
									to.parent = tableName;
									to.name = toColumnName;
									to.data_type = $scope.getFinaleType(to.type);
									let obj = { from: from, to: to };
									$scope.selectedSchemas[schemaName].tables[
										fromTableName
									].links[linkName].columns.push(obj);
									// set ref
									let ref_to =
										$scope.selectedSchemas[toSchemaName].tables[tableName]
											.columns[toColumnName].referenced;
									ref_to = ref_to != undefined ? ref : [];
									ref_to.push({
										schema: schemaName,
										table: fromTableName,
										link: linkName,
										col: columnName,
									});
									$scope.selectedSchemas[toSchemaName].tables[
										tableName
									].columns[toColumnName].referenced = ref_to;
									if ((!hideModal || forceSelect) && !destLink) {
										$scope.checkTableIndeterminate(
											$scope.selectedSchemas[toSchemaName],
											toSchemaName,
											tableName,
											destLink
										);
										$scope.checkTableIndeterminate(
											$scope.selectedSchemas[schemaName],
											schemaName,
											fromTableName,
											destLink
										);
									}

									if (hideModal) {
										for (let l in $scope.selectedSchemas[toSchemaName].tables[
											tableName
										].columns) {
											$scope.selectedSchemas[toSchemaName].tables[
												tableName
											].columns[l].selected = true;
										}
									}
								}
							}
						} else {
							$scope.selectedSchemas[schemaName].tables[fromTableName].links[
								linkName
							].selected = false;
							for (let i in items) {
								columnName = items[i][0];
								toColumnName = items[i][1];
								//Reset ref
								for (let j in $scope.selectedSchemas[toSchemaName].tables[
									tableName
								].columns[toColumnName].referenced) {
									if (
										linkName ==
										$scope.selectedSchemas[toSchemaName].tables[tableName]
											.columns[toColumnName].referenced[j].link
									) {
										$scope.selectedSchemas[toSchemaName].tables[
											tableName
										].columns[toColumnName].referenced.splice(j, 1);
										break;
									}
								}

								for (let t in $scope.selectedSchemas[schemaName].tables[
									fromTableName
								].columns[columnName].referenced) {
									if (
										linkName ==
										$scope.selectedSchemas[schemaName].tables[fromTableName]
											.columns[columnName].referenced[t].link
									) {
										$scope.selectedSchemas[schemaName].tables[
											fromTableName
										].columns[columnName].referenced.splice(t, 1);
										break;
									}
								}
							}
						}
						if (!hideModal) {
							$('#' + modalId).modal('show');
						}
					} else {
						let tName = $scope.table != undefined ? tableName : fromTableName;
						toaster.pop(
							'error',
							toasterPopOp,
							toasterPopTable + ' ' + tName + '' + toasterPopNotFound
						);
					}
				} else {
					toaster.pop(
						'error',
						toasterPopOp,
						toasterPopTable + ' ' + schemaName + ' ' + toasterPopNotFound
					);
				}
			};

			$scope.loadTags = function ($query) {
				return $http.get(API_BASE_URL_BACKEND + '/tags?text=' + $query);
			};

			$scope.executeRunning = false;
			$scope.execute = function () {
				let data = [];
				$scope.executeRunning = true;
				$scope.opEnd = false;
				angular.forEach($scope.selectedSchemas, function (value, key) {
					if (value.selected || $scope.isIndeterminate[key]) {
						let schema = { name: key, tables: [] };
						angular.forEach(value.tables, function (tableData, tableKey) {
							if (tableData.selected || $scope.isTableIndeterminate[tableKey]) {
								let t = {
									name: tableKey,
									schema: key,
									create:
										tableData.entitiesToCreate != undefined
											? tableData.entitiesToCreate.value
											: 'ALL',
									lib: tableData.newLib,
								};
								let columns = [];
								let fKeys = [];
								angular.forEach(tableData.columns, function (colData, colKey) {
									if (colData.selected) {
										let col = {
											name: colKey,
											lib: colData.newLib,
											data_type: $scope.getFinaleType(colData.type),
											schema: key,
											parent: tableKey,
											is_primary_key: colData.is_primary_key,
										};
										columns.push(col);
									}
								});
								angular.forEach(tableData.links, function (linkData, linkKey) {
									if (linkData.selected) {
										let fkey = {
											name: linkKey,
											columns: linkData.columns,
											lib: linkData.newLib,
										};

										fKeys.push(fkey);
									}
								});
								t.columns = columns;
								t.links = fKeys;
								schema.tables.push(t);
							}
						});
						data.push(schema);
					}
				});

				for (let t in $scope.selectedSchemas.tags) {
					$scope.selectedSchemas.tags[t].color =
						$scope.selectedSchemas.tags[t].color !== undefined
							? $scope.selectedSchemas.tags[t].color
							: '#dbf5d1';
				}
				let clientId = generateClientId($rootScope.account.login);
				$scope.currentImportDbClientId = clientId;
				let schema = {
					tags: $scope.selectedSchemas.tags,
					description: $scope.selectedSchemas.description,
					schemas: data,
					client_id: clientId,
				};

				ImportDbService.sendDbSchema(
					schema,
					$scope.connectorId,
					$scope.param.createRepDetails
				).then(function (response) {
					$scope.responseData = response.data;
					$scope.createdDepots = [];
					$scope.createdUets = [];
					for (let i in $scope.responseData) {
						if ($scope.responseData[i].is_depot) {
							$scope.createdDepots.push($scope.responseData[i]);
						} else {
							$scope.createdUets.push($scope.responseData[i]);
						}
					}
					$scope.resultsSize =
						$scope.createdDepots.length > $scope.createdUets.length
							? $scope.createdDepots.length
							: $scope.createdUets.length;
					toaster.pop('success', toasterPopSuccess, toasterPopImported);
					$('#resultModal').modal('show');
					$scope.opEnd = true;
					$scope.progressBarValue = 100;
				});
			};

			$scope.getRefColumns = function (link) {
				let elms = _.map(link.column_references, function (e) {
					return e && e.foreign_key ? e.foreign_key.name : '';
				});

				return elms.join(', ');
			};

			$scope.getPrimColumns = function (link) {
				let elms = _.map(link.column_references, function (e) {
					return e && e.foreign_key && e.foreign_key.refrenced_column
						? e.foreign_key.refrenced_column.name
						: '-';
				});

				return elms.join(', ');
			};

			$scope.jobError = {};
			$scope.openDataPreviewer = function (modalId, schemaName, tableName) {
				$scope.jobError = {};
				ImportDbService.getTablePreview(
					tableName,
					schemaName,
					$scope.connectorId
				)
					.then(function (response) {
						$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
						$scope.entiteTypeFileDataPreview = response.data.data_preview;
						$scope.initGridData();
						$('#' + modalId).modal('show');
					})
					.catch(function (error) {
						if (
							error &&
							error.data &&
							(error.data.code == '37' ||
								error.data.code == '38' ||
								error.data.code == '39')
						) {
							$scope.jobError.errorMessage = translateErrorMessage;
						}
						$scope.jobError.error = true;
					});
			};

			$scope.moreThanTenColumns = false;
			$scope.gridOptions = {};
			$scope.gridOptions.data = [];
			$scope.initGridData = function () {
				$scope.showDataPreviewGrid = false;
				$scope.gridOptions = {};
				let generatedColumns = [];
				$scope.gridData = [];
				//Create Grid Columns
				let headearsWithNoSpecChar = [];
				for (let i = 0; i < $scope.entiteTypeFileHeader.length; i++) {
					let columnName = $scope.entiteTypeFileHeader[i];
					let headerNoSpecChar = columnName
						.replace(/[^a-zA-Z0-9_-]/g, '_')
						.toLowerCase();
					headearsWithNoSpecChar[i] = headerNoSpecChar;
					if (i < 10) {
						$scope.moreThanTenColumns = false;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: columnName,
						});
					} else {
						$scope.moreThanTenColumns = true;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: columnName,
							visible: false,
						});
					}
				}
				//Load Data
				for (
					let count = 0;
					count < $scope.entiteTypeFileDataPreview.length;
					count++
				) {
					let object = $scope.gridData[count];
					let value = $scope.entiteTypeFileDataPreview[count];
					for (let l = 0; l < headearsWithNoSpecChar.length; l++) {
						let colName = headearsWithNoSpecChar[l];
						if (object == undefined) {
							object = {};
						}

						object[colName] = value[l];
						$scope.gridData[count] = object;
					}
				}
				$scope.gridOptions.pager = {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				};
				$scope.gridOptions.paging = {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				};
				$scope.gridOptions.filterRow = {
					visible: true,
					applyFilter: 'auto',
				};
				$scope.gridOptions.rowAlternationEnabled = true;
				$scope.gridOptions.headerFilter = {
					visible: true,
					applyFilter: 'auto',
				};
				$scope.gridOptions.columnChooser = {
					enabled: true,
				};
				$scope.gridOptions.showColumnLines = true;
				$scope.gridOptions.showRowLines = true;
				$scope.gridOptions.allowColumnReordering = true;
				$scope.gridOptions.allowColumnResizing = true;
				$scope.gridOptions.columnAutoWidth = true;
				$scope.gridOptions.showBorders = true;
				$scope.gridOptions.onInitialized = function (e) {
					$scope.gridInstance = e.component;
				};
				// Set a bigger z-index
				$(function () {
					DevExpress.ui.dxOverlay.baseZIndex(1999999998);
				});
				$scope.gridOptions.dataSource = $scope.gridData;
				$scope.showDataPreviewGrid = true;
				if ($scope.gridInstance && $scope.gridInstance.option) {
					$scope.gridInstance.option('columns', generatedColumns);
					$scope.gridInstance.option('dataSource', $scope.gridData);
				}
			};
			$scope.selectUnSelectAll = function (
				schema,
				schemaName,
				tableName,
				forceSelect,
				value
			) {
				for (let i in $scope.selectedSchemas[schemaName].tables[tableName]
					.columns) {
					$scope.selectedSchemas[schemaName].tables[tableName].columns[
						i
					].selected = value;
				}

				$scope.checkTableIndeterminate(
					schema,
					schemaName,
					tableName,
					undefined,
					true,
					true
				);
			};

			$scope.changeTableIndeterminationAfterClose = function (table, modalId) {
				isTableIndeterminate(
					$scope.selectedSchemas[table.toSchema],
					table.toSchema,
					table.name
				);
				isTableIndeterminate(
					$scope.selectedSchemas[table.schema],
					table.schema,
					table.fromTableName
				);
				$('#' + modalId).modal('hide');
			};

			$scope.schemaContainsFilterVal = {};
			$scope.searchValue = '';
			$scope.checkSchemaContainsFilter = function (schema, searchV) {
				$scope.searchValue = searchV;
				if ($scope.searchValue == undefined || $scope.searchValue == '') {
					$scope.schemaContainsFilterVal[schema.name] = true;
				} else {
					if (
						schema.name &&
						schema.name.toLowerCase().indexOf($scope.searchValue) > -1
					) {
						$scope.schemaContainsFilterVal[schema.name] = true;
						if (checkTableContainsFilter(schema.tables, schema.name)) {
							$scope.schemaContainsFilterVal[schema.name] = true;
							$scope.showHideElmfLags[schema.name] = true;
						}
					} else if (checkTableContainsFilter(schema.tables, schema.name)) {
						$scope.schemaContainsFilterVal[schema.name] = true;
						$scope.showHideElmfLags[schema.name] = true;
					} else {
						$scope.schemaContainsFilterVal[schema.name] = false;
					}
				}
			};

			function checkTableContainsFilter(tables, schemaName) {
				let countTrue = 0;
				for (let i in tables) {
					$scope.showHideElmfLagsTable[tables[i].name] = false;
					if (
						tables[i].name &&
						tables[i].name.toLowerCase().indexOf($scope.searchValue) > -1
					) {
						$scope.schemaContainsFilterVal[schemaName] = true;
						$scope.showHideElmfLags[schemaName] = true;
						if (checkColumnsAndFKeys(tables[i])) {
							$scope.schemaContainsFilterVal[schemaName] = true;
							$scope.showHideElmfLags[schemaName] = true;
							$scope.showHideElmfLagsTable[tables[i].name] = true;
						}
						countTrue = countTrue + 1;
					} else if (checkColumnsAndFKeys(tables[i])) {
						$scope.schemaContainsFilterVal[schemaName] = true;
						$scope.showHideElmfLags[schemaName] = true;
						$scope.showHideElmfLagsTable[tables[i].name] = true;
						countTrue = countTrue + 1;
					} else if ($scope.schemaContainsFilterVal[schemaName]) {
						$scope.schemaContainsFilterVal[schemaName] = true;
						if (countTrue > 0) {
							$scope.showHideElmfLags[schemaName] = true;
						}
					} else {
						$scope.showHideElmfLagsTable[tables[i].name] = false;
					}
				}
				return countTrue > 0;
			}

			function checkColumnsAndFKeys(table) {
				let showTable = false;
				for (let i in table.columns) {
					if (
						table.columns[i].name &&
						table.columns[i].name.toLowerCase().indexOf($scope.searchValue) > -1
					) {
						$scope.showHideElmfLagsTable[table.name] = true;
						showTable = true;
						break;
					}
				}
				for (let i in table.foreign_keys) {
					if (
						table.foreign_keys[i].name &&
						table.foreign_keys[i].name
							.toLowerCase()
							.indexOf($scope.searchValue) > -1
					) {
						$scope.showHideElmfLagsTable[table.name] = true;
						showTable = true;
						break;
					}
				}

				return showTable;
			}

			$scope.schemaContainsFilterVal =
				$scope.schemaContainsFilterVal != undefined
					? $scope.schemaContainsFilterVal
					: {};
			$scope.showHideElmfLags =
				$scope.showHideElmfLags != undefined ? $scope.showHideElmfLags : {};
			$scope.filterSchemas = function (searchV) {
				$scope.searchValue = searchV;
				for (let i in $scope.schemas) {
					$scope.schemaContainsFilterVal[$scope.schemas[i].name] = false;
					$scope.showHideElmfLags[$scope.schemas[i].name] = false;
					$scope.checkSchemaContainsFilter($scope.schemas[i], searchV);
				}
			};

			$scope.highlightInput = function (key, SchemaName, searchValue) {
				return (
					key &&
					key.toLowerCase().indexOf(searchValue) > -1 &&
					$scope.schemaContainsFilterVal[SchemaName]
				);
			};

			$scope.metadataConfig = {
				htmlId: 'importdb',
				hideActive: true,
				hideLicence: true,
				hideLabel: true,
				hideIcon: true,
				hidePanel: true,
				appendTagListToBody: true,
			};

			$scope.progressBarValue = 0;
			$scope.progressBarOptions = {
				min: 0,
				max: 100,
				width: '100%',
				bindingOptions: {
					value: 'progressBarValue',
				},
				statusFormat: function (value) {
					return Math.round(value * 100) + '%';
				},
				onComplete: function (e) {
					e.element.addClass('complete');
				},
			};

			$scope.importProgressMessage = '';
			$scope.importProgressCountMessage = '';
			$scope.$on('import_db_progress', function (event, notif) {
				if (
					notif &&
					notif.data &&
					notif.data.client_id == $scope.currentImportDbClientId
				) {
					$scope.$apply(function () {
						$scope.progressBarValue =
							(notif.data.cumulative / notif.data.all_ops_count) * 100;
						$scope.importProgressMessage = notif.data.link
							? ' ' +
							  gettextCatalog.getString('Création du Lien entre') +
							  ' ' +
							  notif.data.first_elm +
							  ' ' +
							  gettextCatalog.getString('et') +
							  ' ' +
							  notif.data.second_elm
							: gettextCatalog.getString('Traitement en cours de') +
							  ' ' +
							  notif.data.first_elm +
							  ' - ' +
							  notif.data.second_elm;
						$scope.importProgressCountMessage =
							notif.data.current_table +
							' ' +
							gettextCatalog.getString('sur') +
							' ' +
							notif.data.total_tables;
					});
				}
			});

			$scope.cancelOperationConfirmation = function (modalId, isConnect) {
				$scope.isConnect = isConnect;
				$('#' + modalId).modal('show');
			};

			$scope.cancelConnect = false;
			$scope.cancelOperation = function (modalId) {
				$scope.cancelConnect = true;
				ImportDbService.stopOperation(
					$scope.isConnect
						? $scope.currentImportDbConnectClientId
						: $scope.currentImportDbClientId
				).then(function () {
					$('#' + modalId).modal('hide');
					$scope.cancelConnect = false;
					$scope.executeRunning = false;
					if (!$scope.isConnect) {
						$scope.schemas = undefined;
					}
				});
			};

			$scope.quiteOp = function () {
				$scope.schemas = undefined;
				$scope.cancelConnect = false;
				$scope.opEnd = false;
				$scope.executeRunning = false;
				$scope.metadataLoaded = false;
				$scope.dataFetched = false;
				$scope.progressBarValue = 0;
			};

			$scope.clearInputOnAllSchema = function () {
				if ($scope.excludeSchemas == 'ALL') {
					$scope.inclusion.schemas = null;
				}
			};

			$scope.clearInputOnAlTable = function () {
				if ($scope.excludeTables == 'ALL') {
					$scope.inclusion.tables = null;
				}
			};

			init();
		},
	]);
})();
