(function () {
	'use strict';

	angular.module('dcApp').controller('DepotListController', [
		'$scope',
		'$rootScope',
		'$timeout',
		'PermissionService',
		'gettextCatalog',
		function ($scope, $rootScope, $timeout, PermissionService, gettextCatalog) {
			$scope.depotListConf = {};
			$scope.depotListConf.disableAllSelectedLink = true;

			function init() {
				$scope.active = true;
				PermissionService.haveGlobalPermission('data_source').then(function (
					response
				) {
					$scope.data_source_authorised = response.data;
				});
			}

			// Filter on imported property
			// Binded to imported checkbox
			$scope.filterImported = function () {
				if ($scope.importedOnly) {
					$scope.gridInstance.filter(['imported', '=', true]);
				} else {
					$scope.gridInstance.clearFilter('dataSource');
				}
			};

			init();
		},
	]);
})();
