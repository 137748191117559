(function () {
	'use strict';

	angular.module('dcApp').directive('userInfo', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'UserService',
				function ($scope, UserService) {
					var vm = this;

					$scope.init = function () {
						if (vm.userId) {
							UserService.getUserById(vm.userId, vm.isGroup).then(function (
								response
							) {
								$scope.userName = response.data.name;
							});
						}
					};
				},
			];
			return {
				restrict: 'E',

				scope: {
					userId: '=',
					isGroup: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/userInfo/userInfo.html',
				transclude: true,
				replace: true,
				link: function (scope, elem, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
