angular
	.module('dcApp')
	.controller('TreeElementsContentController', ['$scope', function ($scope) {}])
	.directive('treeElementsContent', function () {
		var controller = ['$scope', function ($scope) {}];
		return {
			restrict: 'AECM',
			templateUrl:
				'./src/components/modules/project/deleteProject/treeElementsContent.html',
			replace: true,
			scope: {
				treeViewOptions: '=',
			},
			link: function (scope) {},
		};
	});
