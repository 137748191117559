(function () {
	'use strict';

	angular.module('dcApp').directive('pdfmediaview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'$window',
				'$rootScope',
				'MediaService',
				'hdSourceService',
				'PermissionService',
				'UrlService',
				function (
					$scope,
					$state,
					$window,
					$rootScope,
					MediaService,
					hdSourceService,
					PermissionService,
					UrlService
				) {
					var vm = this;

					vm.init = function (ignoreCache) {
						$scope.currentNumber = 0;

						// the following method attaches 'config' object to $scope
						initializeMedia(
							'pdf',
							$scope,
							MediaService,
							hdSourceService,
							checkConfig,
							loadMedia,
							ignoreCache,
							PermissionService
						);
					};

					// FIXME: replace with service factory
					initializeMediaDirectiveScope($scope, $state, vm, $window, 'pdf');

					var checkConfig = function (config) {
						return true;
					};

					$scope.previousItem = function () {
						if ($scope.currentNumber < 1) {
							return;
						}
						$scope.currentNumber = $scope.currentNumber - 1;
						setCurrentItem();
					};

					$scope.nextItem = function () {
						if ($scope.currentNumber == $scope.total - 1) {
							return;
						}
						$scope.currentNumber = $scope.currentNumber + 1;
						setCurrentItem();
					};

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					var loadMedia = function () {
						// FIXME: this is line should be moved elsewhere. Initialization will fail otherwise
						$scope.total = $scope.config.global_config.items.length;

						if (!Array.isArray($scope.config.global_config.items)) {
							$scope.pdfs = [];
						} else {
							$scope.pdfs = $scope.config.global_config.items.map(function (
								item
							) {
								initPopover();
								if (!isEmpty(item.link)) {
									return {
										link: item.link,
										mode: 'ExternalLink',
										mediaItemId: item.id,
									};
								}
								if (isEmpty(item.link) && $scope.vm.data.full_data) {
									return {
										mode: 'Publication',
										mediaItemId: item.id,
										link: $scope.vm.data.full_data.link,
									};
								}
								return {
									mode: 'Visualisation',
									mediasId: $scope.vm.data.mediaId,
									mediaItemId: item.id,
								};
							});
						}
						setCurrentItem();
					};

					function setCurrentItem() {
						const pdfConfig = $scope.pdfs[$scope.currentNumber];
						if (pdfConfig.mode === 'ExternalLink') {
							PDFObject.embed(pdfConfig.link, '#pdfViewer', {
								pdfOpenParams: {
									toolbar: $scope.config.global_config.toolbar_active
										? '1'
										: '0',
								},
							});
							return;
						}
						if (pdfConfig.mode === 'Publication') {
							const url = UrlService.generateUrl(
								'DASHBOARDS_PUBLICATION_MEDIA',
								{},
								{
									mediaItemId: pdfConfig.mediaItemId,
									link: pdfConfig.link,
								}
							);
							PDFObject.embed(url, '#pdfViewer', {
								pdfOpenParams: {
									toolbar: $scope.config.global_config.toolbar_active
										? '1'
										: '0',
								},
							});
							return;
						}
						if (pdfConfig.mode === 'Visualisation') {
							MediaService.getDownLoadToken(
								pdfConfig.mediaItemId,
								pdfConfig.mediasId
							).then(function (url) {
								PDFObject.embed(url, '#pdfViewer', {
									pdfOpenParams: {
										toolbar: $scope.config.global_config.toolbar_active
											? '1'
											: '0',
									},
								});
							});
						}
					}

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/mediaView/pdf/mediaView.html',
				function postLink(scope, element, attrs) {
					scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
					scope.jobDetailsId = generateUuid('_');
					scope.vm.init(false);
				}
			);
		},
	]);
})();
