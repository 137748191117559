'use strict';

angular
	.module('dcApp')
	.constant('LINK_TYPES', {
		Inner: 'INNER',
		Outer: 'OUTER',
		Left: 'LEFT',
		Right: 'RIGHT',
		Cross: 'CROSS',
	})
	.factory('DatablockLinkEntity', [
		'LINK_TYPES',
		'DatablockLinkMappingEntity',
		'gettextCatalog',
		function (LINK_TYPES, DatablockLinkMappingEntity, gettextCatalog) {
			function DatablockLinkInstance(beLink) {
				this.id = beLink && beLink.id ? beLink.id : null;
				this.lib = beLink && beLink.lib ? beLink.lib : 'new-link';
				this.db_id = beLink && beLink.db_id ? beLink.db_id : null;
				this.columns = beLink && beLink.columns ? beLink.columns : [];
				this.data_columns =
					beLink && beLink.data_columns ? beLink.data_columns : [];
				this.type = beLink && beLink.type ? beLink.type : LINK_TYPES.Cross;
				this.not_valid = false;
				this.errorMessage = '';
				this.mappings = beLink && beLink.mappings ? beLink.mappings : [];
				this.allSelected =
					beLink && beLink.columns && beLink.data_columns
						? beLink.columns.length === beLink.data_columns.length
						: false;
			}

			DatablockLinkInstance.prototype = Object.create(null);
			DatablockLinkInstance.prototype.constructor = DatablockLinkInstance;

			DatablockLinkInstance.prototype.checkLinkValidity = checkLinkValidity;
			DatablockLinkInstance.prototype.createMapping = createMapping;
			DatablockLinkInstance.prototype.addSelectColumns = addColumns;
			DatablockLinkInstance.prototype.generateMappings = generateMappings;
			DatablockLinkInstance.prototype.setLinkType = setType;
			DatablockLinkInstance.prototype.updateMappings =
				computeEligibleTargetColumns;

			function setType(type) {
				this.type = type;
			}

			function checkLinkValidity(datablockId) {
				this.not_valid = false;
				this.errorMessage = '';
				if (!this.lib) {
					this.errorMessage = gettextCatalog.getString(
						'Le champ libellé est obligatoire'
					);
					this.not_valid = true;
				} else if (!this.db_id) {
					this.errorMessage = gettextCatalog.getString(
						'Le champ DataBlock est obligatoire'
					);
					this.not_valid = true;
				} else if (datablockId === this.db_id) {
					this.errorMessage = gettextCatalog.getString('Lien cyclique');
					this.not_valid = true;
				} else {
					var that = this;
					this.not_valid = this.mappings.reduce(function (acc, currentMapping) {
						return acc || currentMapping.checkMappingValidity(that);
					}, false);
				}
				return this.not_valid;
			}

			function createMapping() {
				this.mappings.push(new DatablockLinkMappingEntity());
			}

			function addColumns() {
				this.columns = this.data_columns
					.filter(function (dc) {
						return dc.selected;
					})
					.map(function (dc) {
						return {
							carac_code: dc.carac_code,
							column_uuid: dc.column_uuid,
							uuid: dc.uuid ? dc.uuid : generateUuid('_'),
						};
					});
			}

			function generateMappings(inputMappings) {
				var that = this;
				this.mappings = inputMappings.map(function (m) {
					return new DatablockLinkMappingEntity(m);
				});
			}

			function computeEligibleTargetColumns(sourceColumns) {
				var that = this;
				that.mappings.forEach(function (mapping) {
					const selected = sourceColumns.find(function (scl) {
						return scl.uuid === mapping.left_column_uuid;
					});
					mapping.computeEligibleTargetColumns(selected, that.data_columns);
				});
			}

			return DatablockLinkInstance;
		},
	]);
