function baseTimeline() {
	this.getTimeline = function () {
		return this.timeline;
	};

	this.draw = function () {
		this.timeline = new vis.Timeline(
			this.el,
			this.items,
			this.groups,
			this.options
		);
	};

	this.getTimeByX = function (x) {
		return this.timeline.body.util.toTime(x);
	};

	this.setGroupedItems = function () {
		var g = this.getClusters();
		this.items = new vis.DataSet(g);
		this.timeline.setItems(this.items);
	};

	this.getDiffDate = function (date1, date2) {
		//Get 1 second in milliseconds
		var second = 1000;
		var minute = second * 60;
		var hour = minute * 60;
		var day = hour * 24;
		var month = day * 31;
		var year = month * 12;

		// Convert both dates to milliseconds
		var date1_ms = date1.getTime();
		var date2_ms = date2.getTime();

		// Calculate the difference in milliseconds
		var difference_ms = date2_ms - date1_ms;

		// Convert back to days and return
		return {
			years: Math.round(difference_ms / year),
			months: Math.round(difference_ms / month),
			days: Math.round(difference_ms / day),
			hours: Math.round(difference_ms / hour),
			minutes: Math.round(difference_ms / minute),
		};
	};

	this.getScale = function () {
		var start = this.timeline.getWindow().start;
		var end = this.timeline.getWindow().end;

		var diff = this.getDiffDate(start, end);

		if (diff.months > 12 * 2) {
			return 'year';
		} else if (diff.days > 31) {
			return 'month';
		} else if (diff.hours > 24) {
			return 'day';
		} else if (diff.minutes > 60) {
			return 'hour';
		} else {
			return 'second';
		}
	};

	this.getClusters = function () {
		var scale = this.getScale();

		var group = this.groupedItems;
		var result = {};

		var formatByScale = {
			year: 'Y',
			month: 'Y-M',
			weekday: 'Y-M-week-W',
			day: 'Y-M-day-D',
			hour: 'Y-M-D-H',
			second: 'Y-M-D-H-m',
		};

		group.forEach(function (items, i) {
			items.forEach(function (item, j) {
				var index =
					moment(item.start).format(formatByScale[scale]) +
					'-group' +
					item.group;

				if (!result[index]) {
					result[index] = {
						firstid: item.id,
						count: 1,
						items: [],
						group: item.group,
						start: item.start,
					};
				}

				result[index].items.push(item);
				result[index].end = item.start;
				result[index].count++;
			});
		});

		return _.toArray(result);
	};
}
