'use strict';

angular
	.module('dcApp')
	.controller('EntiteController', [
		'CaracService',
		'EntiteFileService',
		'CaracFileMappingService',
		'POSITIONS',
		'$timeout',
		'$rootScope',
		'$location',
		'API_BASE_URL_BACKEND',
		'DATE_PATTERN',
		'$window',
		'$http',
		'$state',
		'$stateParams',
		'$scope',
		'$filter',
		'LangueService',
		'TypeEntiteService',
		'TagService',
		'toaster',
		'LinkMappingService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'DataSourceService',
		'DATE_TYPE_CODE',
		'DATE_TIME_PATTERN',
		'StorageModeService',
		'CommonServices',
		'PictogrammeService',
		'AllDepotService',
		'EntiteDepotService',
		'DepotExtractionFilterService',
		'DataBlocksService',
		'$sce',
		'gettextCatalog',
		'UNAUTHORIZED_TYPES_FOR_STAT',
		'UPDATE_EVENT',
		function (
			CaracService,
			EntiteFileService,
			CaracFileMappingService,
			POSITIONS,
			$timeout,
			$rootScope,
			$location,
			API_BASE_URL_BACKEND,
			DATE_PATTERN,
			$window,
			$http,
			$state,
			$stateParams,
			$scope,
			$filter,
			LangueService,
			TypeEntiteService,
			TagService,
			toaster,
			LinkMappingService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			DataSourceService,
			DATE_TYPE_CODE,
			DATE_TIME_PATTERN,
			StorageModeService,
			CommonServices,
			PictogrammeService,
			AllDepotService,
			EntiteDepotService,
			DepotExtractionFilterService,
			DataBlocksService,
			$sce,
			gettextCatalog,
			UNAUTHORIZED_TYPES_FOR_STAT,
			UPDATE_EVENT
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Entités Métier');
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.picture = gettextCatalog.getString('Image');

			let toasterPopSucess = gettextCatalog.getString('Succès');
			let toasterPopCreationEntity = gettextCatalog.getString(
				"Création de l'Entité métier effectuée"
			);
			let toasterPopEditEntity = gettextCatalog.getString(
				"Modification de l'Entité métier effectuée"
			);
			let toasterPopDeleteEntity = gettextCatalog.getString(
				"Suppression de l'Entité métier effectuée"
			);

			$scope.accessToken = window._keycloak.token;
			$scope.isSimple = true;
			$scope.checkPermissionData = {};
			$scope.grammar = {};
			$scope.deleteElementData = {};
			$scope.deleteCaracFromElementData = {};
			$scope.DEFAUT_DATE_TIME_PATTERN = DATE_TIME_PATTERN;
			$scope.persistanceParams = {};
			$scope.widgetData = {};
			$scope.valueWidgetData = {
				hideWidgetMenu: true,
				originType: 'DATABLOCK',
			};
			$scope.dbBuilder = {};
			$scope.datablockForFormula = {};
			$scope.permissionData = {};
			$scope.caracListScope = {};
			$scope.dataGridsVisibility = {
				caracs: false,
				links: false,
				depots: false,
				datablocks: false,
			};
			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.entite_type.metadata_id;
				$scope.permissionData.type = 'entity';
				$scope.permissionData.elementId = $scope.entite_type.id;
				$scope.permissionData.lib = $scope.entite_type.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showGrammar = function () {
				TypeEntiteService.getEntiteTypeGrammar($scope.entite_type.id).then(
					function (response) {
						if (response.data) {
							$scope.grammar.savedGrammar = response.data;
						}
					}
				);
				$scope.grammar.fromEntity = true;
				$scope.grammar.showGrammarDialog = true;
			};
			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.entite_type.id;
				$scope.checkPermissionData.type = 'entity';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			/* export DC*/
			$scope.exportDataChain = function () {
				$scope.checkPermissionData.elementId = $scope.entite_type.id;
				$scope.checkPermissionData.type = 'entity';
				$scope.checkPermissionData.action = 'export_dc';
				$scope.checkPermissionData.actionIfPermitted = $scope.doExportDataChain;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doExportDataChain = function () {
				$scope.exportData = {};
				$scope.exportData.entiteTypeId = $scope.entite_type.id;
				$scope.exportData.entiteTypeLib = $scope.entite_type.lib;
				$scope.exportData.entiteTypeCode = $scope.entite_type.code;
				$scope.exportData.showExportHdh = true;
			};

			$scope.getHtmlIconByType = function (type, isList) {
				if (type != undefined) {
					type = type.toLowerCase();
					return $sce.trustAsHtml(getHtmlIconByType(type, isList));
				}
			};

			$scope.hdhFormats = [
				{ label: 'Formule', value: 'FORMULA' },
				{ label: 'Simple', value: 'SIMPLE' },
			];

			$scope.elementGraphData = {};
			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel = $scope.entite_type.label;
				$scope.elementGraphData.centeredElementType = 'ENTITETYPE';
				$scope.elementGraphData.centeredElementId = $scope.entite_type.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			CommonServices.getDateMasks().then(function (response) {
				$scope.datePatterns = response.data;
			});

			$scope.setSelectedStorageMode = function (mode) {
				for (var i = 0; i < $scope.storageModes.length; i++) {
					if ($scope.storageModes[i].code === mode.code) {
						$scope.entiteTypeData.entiteType.storage_mode =
							$scope.storageModes[i];
					}
				}
			};

			$scope.showAdvanced = function () {
				$scope.selectedLang = $scope.currentLang;
				$('#advancedDataModalCarac').modal('show');
			};

			$scope.closeAdvanced = function () {
				$('#advancedDataModalCarac').modal('hide');
			};

			$scope.saveAdvanced = function () {
				$('#advancedDataModalCarac').modal('hide');
			};

			$scope.newType = function () {
				reset();
				$scope.isNew = true;
				$scope.selectedNodeId = null;

				// set first tab active
				$('#infosTab').tab('show');
			};

			function getLinksCount() {
				TypeEntiteService.getLinksCount($scope.entite_type.id).then(function (
					response
				) {
					$scope.entiteForDir.caracsComplexLength = response.data;
				});
			}

			function getDepotEntiteCount(entiteId) {
				EntiteDepotService.getEntiteDepotLinksCount(entiteId).then(function (
					responseLinksC
				) {
					$scope.entiteTypeFilesCount = responseLinksC.data;
				});
			}

			function init() {
				reset();
				$scope.type = {};
				$scope.selectedSimpleType = {};
				$scope.metadataLoaded = true;
				$scope.entiteTypeData = {};
				$scope.entiteTypeData.entiteType = $scope.entite_type;
				$scope.entiteTypeData.isEntiteType = true;
				$scope.isNewCarac = true;
				$scope.isToDuplicate = false;
				$scope.selectedNodeId = null;
				$scope.fromGraph = $stateParams.fromGraphe;
				$scope.savingInProgress = false;

				if ($stateParams.entiteId || $stateParams.duplicatedId) {
					$scope.isNew = $stateParams.duplicatedId != undefined;
					$scope.isToDuplicate = $scope.isNew;
					$scope.caracListScope.isToDuplicate = $scope.isToDuplicate;
					var entiteId =
						$stateParams.entiteId != undefined
							? $stateParams.entiteId
							: $stateParams.duplicatedId;
					$scope.persistanceParams = {
						id: $stateParams.entiteId,
						excludeLinks: true,
					};
					TypeEntiteService.getTypeWithLibs(entiteId).then(function (response) {
						$scope.entite_type = angular.copy(response.data);
						$scope.entite_type.id = $scope.isNew
							? undefined
							: $scope.entite_type.id;
						$scope.entite_type.code = $scope.isNew
							? undefined
							: $scope.entite_type.code;
						$scope.entite_type.metadata_id = $scope.isNew
							? undefined
							: $scope.entite_type.metadata_id;
						$scope.entiteTypeData.entiteType = $scope.entite_type;
						$scope.entiteTypeData.entiteType.actif = $scope.entite_type.active;
						$scope.entiteForDir = response.data;
						StorageModeService.getStorageModes().then(function (
							responseStorage
						) {
							$scope.storageModes = responseStorage.data;
							$scope.setSelectedStorageMode(
								$scope.entiteTypeData.entiteType.storage_mode
							);
						});

						if (!$scope.isToDuplicate) {
							getDepotEntiteCount(entiteId);
							DataBlocksService.getCountUsingEntite(entiteId).then(function (
								responseDBCountU
							) {
								$scope.datablocksUsing = { count: responseDBCountU.data };
							});
						}
						if (!$scope.isNew) {
							getLinksCount();
						}
						$scope.dataGridsVisibility.caracs = true;
					});
				} else {
					$scope.isNew = true;

					$scope.newType();

					StorageModeService.getStorageModes().then(function (response) {
						$scope.storageModes = response.data;
						angular.forEach($scope.storageModes, function (mode) {
							if (mode.text == 'Disque') {
								$scope.entiteTypeData.entiteType.storage_mode = mode;
								return;
							}
						});
					});
				}
			}

			init();

			$scope.selectNode = function (treeNode) {
				$('#entite_form').collapse('show');
				$scope.collapsedForm = false;
				reset();
				$scope.isNew = false;
				$scope.tree_node = angular.copy(treeNode);
				$scope.selectedNodeId = $scope.tree_node.id;
				$scope.selectLibelles();
				loadTags($scope.selectedNodeId);
			};

			function reset() {
				$rootScope.fields = [];
				$scope.entite_type = { actif: true };
			}

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('entites');
				}, 300);
			};

			$scope.saveAndReturnToList = function () {
				$scope.save(true);
			};

			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.savingInProgress = false;
			let catchEntitySaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveEntity = function () {
				if (!$scope.isNew) {
					$scope.checkPermissionData.elementId = $scope.entite_type.id;
					$scope.checkPermissionData.type = 'entity';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.save;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.save();
				}
			};

			let saveWithNoGoTab = function () {
				$scope.save(false, true);
			};

			$scope.saveEntityWithNoGo = function () {
				if (!$scope.isNew) {
					$scope.checkPermissionData.elementId = $scope.entite_type.id;
					$scope.checkPermissionData.type = 'entity';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = saveWithNoGoTab;
					$scope.checkPermissionData.showPermissionCheck = true;

					return $scope.checkPermissionData.actionIfPermitted;
				} else {
					return saveWithNoGoTab();
				}
			};

			$scope.save = function (returnToList, doNotGoToTab) {
				$('#confirmationModal').modal('hide');
				$scope.entiteTypeData.entiteType.active =
					$scope.entiteTypeData.entiteType.actif;
				$scope.entite_type = $scope.entiteTypeData.entiteType;
				// set metadata
				for (var t in $scope.entite_type.tags) {
					$scope.entite_type.tags[t].color =
						$scope.entite_type.tags[t].color !== undefined
							? $scope.entite_type.tags[t].color
							: '#dbf5d1';
				}

				$scope.savingInProgress = true;
				if ($scope.isNew && !$scope.entite_type.id) {
					if ($scope.isToDuplicate) {
						$scope.entite_type.is_duplicated = true;
						$scope.entite_type.caracs = [];
						if ($scope.caracListScope.getSimpleCaracs) {
							$scope.entite_type.caracs =
								$scope.caracListScope.getSimpleCaracs();
						}
					}
					TypeEntiteService.createType($scope.entite_type)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSucess,
								toasterPopCreationEntity
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('entites');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$scope.createdEntiteTypeId = response.data;
									$state.go('entites-edit', {
										entiteId: $scope.createdEntiteTypeId,
									}, {reload: true});
								}, 300);
							}
						})
						.catch(catchEntitySaveErrors);
				} else {
					// Modif Entite Type

					TypeEntiteService.editType($scope.entite_type)
						.then(function (response) {
							toaster.pop('success', toasterPopSucess, toasterPopEditEntity);
							$scope.persistanceParams.updatePersistanceParams();

							$scope.entite_type = response.data;

							$scope.entiteTypeData.entiteType = $scope.entite_type;
							$scope.entiteTypeData.entiteType.actif =
								$scope.entite_type.active;
							$scope.setSelectedStorageMode(
								$scope.entiteTypeData.entiteType.storage_mode
							);
							$scope.savingInProgress = false;

							if (returnToList) {
								$timeout(function () {
									$state.go('entites');
								}, 300);
							}
							if ($scope.fromGraph) {
								$state.go('graphe');
							}
						})
						.catch(catchEntitySaveErrors);
				}
				// TODO remove when entity params realtime exec is correctly handled
				if (!doNotGoToTab) {
					// set first tab active
					$scope.isSimple = true;
					$('#caracTab').tab('show');
					$scope.setIsValueTabFalseAndCaracMode(false);
				} else {
					$timeout(function () {
						$rootScope.$broadcast(UPDATE_EVENT['ENTITETYPE_SAVE']);
					}, 300);
				}
			};

			$scope.redirect = function (formGraph) {
				if ($scope.fromGraph) {
					$state.go('graphe', { nodeId: $scope.createdEntiteTypeId });
				} else {
					$state.go('entites');
				}
				$('#redirectConfirmationModal').modal('hide');
				$('.modal-backdrop').remove();
			};

			$scope.openEntiteTypeEditionMode = function (id) {
				var params = { entiteId: id };
				$('#redirectConfirmationModal').modal('hide');
				$('.modal-backdrop').remove();
				$state.go('entites-edit', params);
			};

			$scope.initPersistanceParams = function () {
				if ($scope.persistanceParams.init) {
					$scope.persistanceParams.init();
				}
			};

			// load existant tags on modification
			function loadTags(entiteTypeId) {
				TagService.getTagsByEntiteTypeId(entiteTypeId).then(function (
					response
				) {
					$scope.entite_type.tags = response.data;
				});
			}

			function loadCaracTags(caracId) {
				TagService.getTagsByCaracId(caracId).then(function (response) {
					$scope.carac.tags = response.data;
				});
			}

			// load tags for autocomplete
			$scope.loadTags = function ($query) {
				return $http.get(API_BASE_URL_BACKEND + '/tags?text=' + $query);
			};

			$scope.selectType = function () {
				if ($scope.isSimple == 'true') {
					$scope.selectedSimple = $scope.carac.entite_type.id;
				} else {
					$scope.type.selected = undefined;
					//$scope.$broadcast('uiSelectTypes');
				}
				$scope.showSelectTypes = true;
			};

			$scope.cancelEditType = function () {
				// $scope.type.selected = undefined;
				$scope.$broadcast('uiSelectTypes');
				$scope.showSelectTypes = false;
			};

			$scope.openAndLoadFilesData = function (modalId) {
				$scope.getSourcesGridData(modalId);
			};

			$scope.getSourcesGridData = function (modalId) {
				DataSourceService.getAvailableDataSources().then(function (data) {
					$scope.dataSources = [];
					if (data !== undefined) {
						var content = data.data;
						for (var i in content) {
							$scope.dataSources.push({
								id: content[i].id,
								code: content[i].code,
								lib: content[i].lib,
								name: content[i].name,
								actif: content[i].actif ? 'Oui' : 'Non',
								db: content[i].db ? 'BDD' : 'Fichier',
								is_db: content[i].db,
							});
						}
					}

					$scope.loadData = $scope.dataSources;
					$scope.gridOptions.data = $scope.dataSources;
					$timeout(function () {
						angular.forEach($scope.gridOptions.data, function (file, index) {
							if ($scope.entiteTypeFilesMap.has(file.id)) {
								$scope.gridApi.selection.selectRow(
									$scope.gridOptions.data[index]
								);
							}
						});
					});

					$('#' + modalId).modal('show');
				});
			};

			$scope.gridOptions = {
				enableSelectAll: true,
				enableRowSelection: true,
				selectionRowHeaderWidth: 30,
				data: 'loadData',
				rowHeight: 25,
				onRegisterApi: function (gridApi) {
					$scope.gridApi = gridApi;
				},
				enablePaginationControls: true,
				paginationPageSize: PAGINATIONS_SIZE,
				paginationPageSizes: PAGINATIONS_SIZES,
				enableFiltering: true,
				columnDefs: [
					{
						name: 'code',
						enableHiding: true,
						enableCellEdit: false,
						enableFiltering: true,
						enableSorting: true,
						displayName: 'Code',
						sort: { direction: 'asc', priority: 1 },
						width: '10%',
						cellClass: 'grid-align',
					},
					{
						name: 'lib',
						enableHiding: true,
						enableCellEdit: false,
						enableFiltering: true,
						enableSorting: true,
						displayName: 'Libellé',
						cellClass: 'grid-align',
					},
					{
						name: 'name',
						enableHiding: true,
						enableCellEdit: false,
						enableFiltering: true,
						enableSorting: true,
						displayName: 'Nom source de données',
						cellClass: 'grid-align',
					},
					{
						name: 'db',
						enableHiding: true,
						enableCellEdit: false,
						enableFiltering: true,
						enableSorting: true,
						displayName: 'Fichier/BDD',
						cellClass: 'grid-align',
					},
					{
						name: 'actif',
						enableHiding: true,
						enableCellEdit: false,
						enableFiltering: true,
						enableSorting: true,
						displayName: 'Actif',
						width: '9%',
						cellClass: function (
							grid,
							row,
							col,
							rowRenderIndex,
							colRenderIndex
						) {
							if (grid.getCellValue(row, col).toLowerCase() === 'oui') {
								return 'file-actif grid-align';
							} else {
								return 'file-non-actif grid-align';
							}
						},
					},
				],
			};

			$scope.showTable = false;
			$scope.datablocksUsing = {
				count: 0,
			};
			$scope.getDataBlocksUsing = function () {
				$scope.dataGridsVisibility.datablocks = true;
				$scope.isValueTab = false;
				$scope.showTable = true;
			};

			$scope.widgets = [];
			$scope.grammarChanged = true;
			$scope.changeColumnStatActivation = function () {
				if ($scope.widgets[0].columnStat) {
					$scope.widgets[0].steps[0].summary_config.count_columns = [];
					for (var c in $scope.widgets[0].grammar.columns) {
						$scope.widgets[0].steps[0].summary_config.count_columns.push(
							$scope.widgets[0].grammar.columns[c].uuid
						);
						if (
							!$scope.widgets[0].grammar.columns[c].list &&
							UNAUTHORIZED_TYPES_FOR_STAT.indexOf(
								$scope.widgets[0].grammar.columns[c].type
							) < 0
						) {
							$scope.widgets[0].steps[0].summary_config.summary_columns.push(
								$scope.widgets[0].grammar.columns[c].uuid
							);
						}
					}
				} else {
					$scope.widgets[0].steps[0].summary_config.count_columns = [];
					$scope.widgets[0].steps[0].summary_config.summary_columns =
						$scope.widgets[0].steps[0].summary_config.count_columns;
				}
			};
			$scope.getValues = function () {
				$scope.isValueTab = true;
				$scope.widgets = [];
				TypeEntiteService.getEntiteTypeGrammar($scope.entite_type.id).then(
					function (response) {
						if (response.data) {
							var wid = buildDataBlock(
								{ grammar: response.data },
								$scope.entiteTypeLib,
								$scope.entite_type.id
							);
							wid.steps = [
								{
									formulas: [],
									data_operation: {},
									agregation: {},
									sort: {},
									filter: { condition: 'AND' },
									summary_config: {
										summary: true,
										total_count: true,
										data: true,
										count_columns: [],
										summary_columns: [],
									},
								},
							];
							$scope.widgets.push(wid);
						}
					}
				);
				$scope.grammarChanged = false;
			};

			$scope.setIsValueTabFalseAndCaracMode = function (caracMode) {
				$scope.isValueTab = false;
			};

			$scope.caracTypeToFormulaType = function (caracType) {
				switch (caracType) {
					case 'SIMPLE_TYPES_STRING_ITEM':
						return 'string';
					case 'SIMPLE_TYPES_INTEGER_ITEM':
						return 'integer';
					case 'SIMPLE_TYPES_BIG_INTEGER_ITEM':
						return 'big_integer';
					case 'SIMPLE_TYPES_BOOLEAN_ITEM':
						return 'boolean';
					case 'SIMPLE_TYPES_DECIMAL_ITEM':
						return 'decimal';
					case 'SIMPLE_TYPES_DATE_ITEM':
						return 'date';
					case 'TYPES_WORDS_ITEM':
						return 'words';
					case 'TYPES_FILE_ITEM':
						return 'file';
					case 'TYPES_FILE_ITEM':
						return 'file';
					case 'TYPES_BINARY_ITEM':
						return 'binary';
					default:
						return 'string';
				}
			};

			$scope.closeModal = function (modalId) {
				$('#' + modalId).modal('hide');
			};

			$scope.showPictogrammesModal = function () {
				$scope.getGridDataPictogrammes($scope.carac.metadata_id);

				$('#listPictogrammesModalCarac').modal('show');
			};

			$scope.getGridDataPictogrammes = function (id) {
				$scope.showPictoGridPopUp = false;
				PictogrammeService.getPictogrammes(id).then(function (response) {
					const content = response.data;
					if (content !== undefined) {
						$scope.pictogrammes = [];
						for (var r in content) {
							$scope.pictogrammes.push({
								id: content[r].id,
								lib: content[r].lib,
								selected: content[r].selected,
							});
						}
						$scope.pictosPopupGridData = $scope.pictogrammes;
						$scope.pictoPopupDataGridOptions.dataSource =
							$scope.pictosPopupGridData;
						$scope.showPictoGridPopUp = true;
					}
				});
			};
			$scope.pictoPopupDataGridOptions = {
				dataSource: $scope.pictosPopupGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				selection: {
					mode: 'single',
					showCheckBoxesMode: 'always',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.label,
						dataField: 'lib',
						width: '70%',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.picture,
						alignment: 'center',
						cellTemplate: 'imageAndLibPopUp',
						width: '30%',
					},
				],
				onInitialized: function (e) {
					$scope.gridPictoPopupInstance = e.component;
				},
				onSelectionChanged: function (selectedItems) {
					$scope.selectedIconPopu = selectedItems.selectedRowsData;
					if ($scope.selectedIconPopu && $scope.selectedIconPopu[0]) {
						for (let i in $scope.pictoPopupDataGridOptions.dataSource) {
							if (
								$scope.pictoPopupDataGridOptions.dataSource[i].id ===
								$scope.selectedIconPopu[0].id
							) {
								$scope.pictoPopupDataGridOptions.dataSource[i].selected = true;
							} else {
								$scope.pictoPopupDataGridOptions.dataSource[i].selected = false;
							}
						}
					} else {
						for (let j in $scope.pictoPopupDataGridOptions.dataSource) {
							$scope.pictoPopupDataGridOptions.dataSource[j].selected = false;
						}
					}
				},
				onContentReady: function (e) {
					if (
						$scope.gridPictoPopupInstance &&
						$scope.gridPictoPopupInstance.selectRowsByIndexes
					) {
						var selectedItems = [];
						for (
							let i = 0;
							i < $scope.pictoPopupDataGridOptions.dataSource.length;
							i++
						) {
							if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
								selectedItems.push(i);
							}
						}
						$scope.gridPictoPopupInstance.selectRowsByIndexes(selectedItems);
					}
				},
			};

			$scope.meta = {
				imageURL: API_BASE_URL_BACKEND + 'pictogramme_image?id=',
			};

			$scope.buildIconUrl = function (iconId) {
				if (iconId != undefined) {
					$scope.iconId = iconId;
				}
				$scope.iconId = $scope.iconId != undefined ? $scope.iconId : -1;
				$scope.imageURL = $scope.meta.imageURL + $scope.iconId;
				$scope.carac.icon_id = $scope.iconId;
			};

			$scope.saveAttachedPictogrammes = function () {
				var indice = -1;
				for (
					var i = 0;
					i < $scope.pictoPopupDataGridOptions.dataSource.length;
					i++
				) {
					if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
						$scope.pictogrammes[i].selected = true;
						indice = i;
					} else {
						$scope.pictogrammes[i].selected = false;
					}
				}
				if (indice != -1) {
					$scope.iconId = $scope.pictogrammes[indice].id;
				} else {
					$scope.iconId = -1;
				}

				$scope.buildIconUrl();
				$('#listPictogrammesModalCarac').modal('hide');
			};

			$scope.addNewLink = function () {
				$scope.entiteTypeDepotLinks.push({ lib: '---', unCheckedDepot: true });
			};

			$scope.entiteTypeDepotLinksMap = new Map();

			$scope.entiteTypeDepotLinks = [];
			$scope.getEntiteTypeDepotLinks = function () {
				$scope.dataGridsVisibility.depots = true;
			};

			$scope.disableDeleteTypeEntite = true;
			$scope.showDeleteEntiteModal = function (obj) {
				$scope.elementToDelete = angular.copy($scope.entite_type);
				$scope.checkPermissionData.elementId = $scope.elementToDelete.id;
				$scope.checkPermissionData.type = 'entity';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.elementToDelete
						? $scope.elementToDelete.label
						: undefined,
					doDelete: $scope.deleteType,
					showConfirmDeleteElement: true,
					bulk: false,
				};
			};
			$scope.deleteType = function () {
				TypeEntiteService.deleteEntiteType(
					$scope.elementToDelete.id,
					true
				).then(function (response) {
					toaster.pop('success', toasterPopSucess, toasterPopDeleteEntity);
					$timeout(function () {
						$state.go('entites');
					}, 300);
				});
			};

			$scope.metadataConfig = {
				htmlId: 'uet',
			};

			$scope.switchToSimpleCaracList = function () {
				$scope.dataGridsVisibility.caracs = true;
				$scope.isSimple = true;
			};

			$scope.switchToLinksList = function () {
				$scope.dataGridsVisibility.links = true;
				$scope.isSimple = false;
			};

			//refresh depot-entity count
			$scope.$on(UPDATE_EVENT['ENTITETYPE_DEPOT'], function (event) {
				if ($scope.entite_type.id) {
					getDepotEntiteCount($scope.entite_type.id);
				}
			});
		},
	])
	.controller('EntiteListController', [
		'$scope',
		'$rootScope',
		function ($scope, $rootScope) {
			/* entiteList directive */
			$scope.entiteConfig = {};
			$scope.entiteConfig.isDepotType = false;
		},
	]);
