var mainTimeline = function ($rootScope, gettextCatalog) {
	// private constructor
	this.init = function (
		uuid,
		el,
		itemsData,
		groupedItems,
		groupsData,
		options,
		simple
	) {
		var tl = {};
		tl.intervalLocked = false;

		tl.el = el;
		tl.uuid = uuid;
		tl.itemsData = itemsData;
		tl.groupedItems = groupedItems;
		tl.groupsData = groupsData;

		tl.items = new vis.DataSet(tl.itemsData);
		tl.groups = new vis.DataSet(tl.groupsData);
		tl.options = _.extend(
			{
				template: function (item) {
					var format = 'Y-MMM-D H:s';
					var group = tl.groups.get(item.group);
					var ids;
					var items = [];
					if (item.items && item.items.length > 1) {
						for (var i in item.items) {
							items.push(item.items[i].id);
						}
						ids = items.join('-');
					} else {
						ids = item.firstid;
					}

					if (!simple) {
						if (group.icon_id) {
							var str =
								$rootScope.API_BASE_URL_BACKEND +
								'pictogramme_image?id=' +
								group.icon_id;

							return (
								'<span  onmouseover=\'showInfo("' +
								uuid +
								'", event, "' +
								item.group +
								'",' +
								'"' +
								ids +
								'", true)\'>' +
								(item.items ? '<b>' + item.items.length + '</b> ' : '') +
								(group
									? "<i class='category " +
									  group.class +
									  "'><img style='width: 15px; height: 15px; margin-top:-5px'  src='" +
									  str +
									  "' type='button' title='" +
									  gettextCatalog.getString('Pictogramme') +
									  "'/><i>"
									: '') +
								'</span>'
							);
						}
					} else {
						var str =
							$rootScope.API_BASE_URL_BACKEND + 'pictogramme_image?id=-1';

						return (
							'<span>' +
							(item.items ? '<b>' + item.items.length + '</b> ' : '') +
							(group
								? "<i><img style='width: 15px; height: 15px; margin-top:-5px'  src='" +
								  str +
								  "' type='button' title='" +
								  gettextCatalog.getString('Pictogramme') +
								  "'/><i>"
								: '') +
							'</span>'
						);
					}
				},
			},
			{
				locales: {
					// create a new locale (text strings should be replaced with localized strings)
					fr: {
						current: 'courant',
						time: 'temps',
					},
				},
			},
			typeof options == 'undefined' ? {} : options
		);

		draw(tl);
		setGroupedItems(tl);

		initInterval(tl);
		initEvents_Main(tl);
		return tl;
	};
};
angular
	.module('dcApp')
	.service('mainTimeline', ['$rootScope', 'gettextCatalog', mainTimeline]);
