(function () {
	'use strict';

	angular.module('dcApp').factory('LangueService', [
		'$http',
		'API_BASE_URL_BACKEND',
		function ($http, API_BASE_URL_BACKEND) {
			var LangueService = {};

			LangueService.getLangues = function () {
				return $http
					.get(API_BASE_URL_BACKEND + 'langues')
					.then(function (response) {
						return response;
					});
			};

			return LangueService;
		},
	]);
})();
