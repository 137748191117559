(function () {
	'use strict';

	angular.module('dcApp').constant('validateLength', {
		maxLength: 50,
		minLength: 0,
	});
	angular.module('dcApp').constant('duplicationStatus', {
		finished: 'FINISHED',
		running: 'RUNNING',
		canceled: 'CANCELLED',
		failed: 'FAILED',
	});

	angular.module('dcApp').directive('duplicateModal', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ProjectService',
				'DataBlocksService',
				'$rootScope',
				'$stateParams',
				'validateLength',
				'duplicationStatus',
				'elem',
				'PROJECT_PROGRESS_OPERATION',
				'PROJECT_OPERATION_DONE',
				'gettextCatalog',
				function (
					$scope,
					$state,
					ProjectService,
					DataBlocksService,
					$rootScope,
					$stateParams,
					validateLength,
					duplicationStatus,
					elem,
					PROJECT_PROGRESS_OPERATION,
					PROJECT_OPERATION_DONE,
					gettextCatalog
				) {
					var vm = this;
					let execId;
					vm.MAXLENGTH = validateLength.maxLength;
					vm.MINLENGTH = validateLength.minLength;
					vm.progress = {};
					vm.from = 'duplicate';
					vm.finished = duplicationStatus.finished;
					vm.running = duplicationStatus.running;
					vm.canceled = duplicationStatus.canceled;
					vm.failed = duplicationStatus.failed;
					vm.elem = elem;
					vm.projectStats = {};
					vm.closeModal = closeModal;
					vm.duplicateProject = duplicateProject;
					vm.init = init;
					vm.projectInfos = {};
					vm.duplicatedProjectInfos = {};
					vm.terms = {};
					vm.confirmationData = {};
					vm.currentOperation = PROJECT_PROGRESS_OPERATION.duplication;
					vm.workDone = false;
					vm.cancelDuplicate = cancelDuplicate;
					vm.gotToNewProject = gotToNewProject;

					function init() {
						if (!vm.projectId) {
							return;
						}
						ProjectService.getStats(vm.projectId).then(function (data) {
							vm.projectStats = data.stats;
							vm.projectInfos = {
								currentUser: data.currentUser,
								projectLabel: data.projectLabel,
							};
						});
						DataBlocksService.getExposedDataBlocks($stateParams.projectId).then(
							function (response) {
								vm.expositions = response;
								vm.isExposed = checkExpositionStatus(vm.expositions);
								ProjectService.getDuplicationUserAgreement().then(function (
									responseTerms
								) {
									let dataTerms = responseTerms.data;
									let code = _.keys(dataTerms)[0];
									vm.terms = {
										type: code,
										text: dataTerms[code],
										accepted: false,
									};
								});
							}
						);
					}

					function duplicateProject() {
						let options = {
							label: vm.duplicatedProjectInfos.duplicatedProjectLabel,
							expiration_date: Date.parse(
								vm.duplicatedProjectInfos.duplicatedProjectExpiration
							),
							elements: {
								connector: vm.elementTypes.connector.checked,
								export_template: vm.elementTypes.exportTemplate.checked,
								depot: vm.elementTypes.repository.checked,
								entity: vm.elementTypes.entity.checked,
								datablock: vm.elementTypes.datablock.checked,
								hd_element: vm.elementTypes.hdElements.checked,
								dashboard: vm.elementTypes.dashboard.checked,
								extraction: vm.elementTypes.extractRepository.checked,
								local_data: vm.elementTypes.localData.checked,
							},
							metadata: {
								with_contexts: vm.duplicatedProjectInfos.newProjectContext,
								with_template_permissions:
									vm.duplicatedProjectInfos.templateRightValue,
							},
							data_exposition: {
								datablocks: vm.duplicatedExpositions
									? vm.duplicatedExpositions
									: [],
							},
							user_agreements: [vm.terms],
							exposures_root_code: vm.exposuresRootCode ? vm.exposuresRootCode : null,
						};
						let elementsGroupedByCheck = _.countBy(
							options.elements,
							function (e) {
								return e ? 'checked' : 'notChecked';
							}
						);
						let countElements = elementsGroupedByCheck.checked;
						if (vm.elementTypes.entity.checked) {
							countElements = countElements + 1;
						}

						vm.progress = {
							countOps: countElements * 2 + 5,
							to: vm.duplicatedProjectInfos.duplicatedProjectLabel,
							projectLabel: vm.projectInfos.projectLabel,
							operation: vm.currentOperation,
							progressBarInitValue: null,
							running: true,
							projectId: vm.projectId,
						};
						$rootScope.$broadcast('bulk_status_progress', vm.progress);
						vm.duplicationInProgress = true;

						ProjectService.duplicateProject(vm.projectId, options)
							.then(function (responseExp) {
								execId = responseExp.data;
								vm.progress.progressBarInitValue = 0;
								vm.progress.execId = execId;
								$rootScope.$broadcast('bulk_status_progress', vm.progress);
							})
							.catch(function (error) {
								vm.duplicationInProgress = false;
							});
					}

					function closeModal() {
						vm.data = false;
						vm.projectStats = {};
						vm.projectInfos = {};
						vm.duplicatedProjectInfos = {};
						vm.elementTypes = {};
						vm.duplicationInProgress = false;
						vm.workDone = false;
						vm.progress = {};
						$(vm.element).modal('hide');
					}

					function cancelDuplicate() {
						if (!vm.workDone && vm.projectId && execId) {
							ProjectService.cancelDuplicateProject(vm.projectId, execId).then(
								function () {
									closeModal();
								}
							);
						} else {
							closeModal();
						}
					}

					vm.showCancelConfirmation = function () {
						vm.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString('cancel.duplication');
						vm.confirmationData.confirmationDialogMessage =
							gettextCatalog.getString('duplicate.cancel.message');
						vm.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('yes.cancel.action');
						vm.confirmationData.cancelDialogActionName =
							gettextCatalog.getString('no.cancel.action');
						vm.confirmationData.confirmationGreenAction = false;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.showConfirmationDialog = true;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.confirmDialogAction = vm.cancelDuplicate;
						vm.confirmationData.showConfirmationDialog = true;
					};

					function checkExpositionStatus(expositions) {
						return expositions.length > 0;
					}

					$scope.$on(
						PROJECT_OPERATION_DONE.duplication,
						function (event, data) {
							vm.workDone = true;
							vm.newProjectId = data;
						}
					);

					function gotToNewProject() {
						if (vm.workDone && vm.newProjectId) {
							closeModal();
							$state.go('projects-edit', { projectId: vm.newProjectId });
						}
					}
				},
			];
			return buildDirectiveReturnObject(
				{ projectId: '=', data: '=', elementTypes: '<' },
				controller,
				'./src/components/directives/duplicateProject/duplicateProjectModal.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data;
						},
						function () {
							scope.vm.init();
						},
						false
					);
				}
			);
		},
	]);
})();
