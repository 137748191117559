(function () {
	'use strict';

	angular.module('dcApp').directive('linkCreator', [
		'$parse',
		function ($parse) {
			let controller = [
				'$rootScope',
				'$scope',
				'$state',
				'$filter',
				'$q',
				'TypeEntiteService',
				'toaster',
				'CaracService',
				'LinkMappingService',
				'$window',
				'SparkJobsServices',
				'gettextCatalog',
				function (
					$rootScope,
					$scope,
					$state,
					$filter,
					$q,
					TypeEntiteService,
					toaster,
					CaracService,
					LinkMappingService,
					$window,
					SparkJobsServices,
					gettextCatalog
				) {
					let vm = this;
					let translateDataGridTitle = {};
					translateDataGridTitle.towards = gettextCatalog.getString('vers');
					translateDataGridTitle.value = gettextCatalog.getString('Valeurs');
					translateDataGridTitle.lignes = gettextCatalog.getString('Lignes');
					translateDataGridTitle.linked = gettextCatalog.getString('Liées');
					translateDataGridTitle.noLinked =
						gettextCatalog.getString('Non liées');
					translateDataGridTitle.is = gettextCatalog.getString('soit');

					let toasterPopSucess = gettextCatalog.getString('Succès');
					let toasterPopUpdate = gettextCatalog.getString(
						'Mise à jour du lien  effectuée'
					);
					let toasterPopSCreation = gettextCatalog.getString(
						'Création du lien effectuée'
					);

					let translateConfirmationDialog = {};
					translateConfirmationDialog.title = gettextCatalog.getString(
						'Contrôle de fermeture'
					);
					translateConfirmationDialog.message = gettextCatalog.getString(
						'Voulez-vous enregistrer les modifications avant de fermer ?'
					);
					translateConfirmationDialog.btnName =
						gettextCatalog.getString('Enregistrer');

					let translateErrorMessage = gettextCatalog.getString(
						'context.unavailable'
					);

					$scope.operators = [
						{
							value: 'equal',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Egale'),
							apply_to: [
								'string',
								'integer',
								'date',
								'big_integer',
								'decimal',
								'boolean',
								'file',
							],
							list: false,
						},
						{
							value: 'not_equal',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Différent'),
							apply_to: [
								'string',
								'integer',
								'date',
								'big_integer',
								'decimal',
								'boolean',
								'file',
							],
							list: false,
						},
						{
							value: 'less',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Inférieur'),
							apply_to: ['integer', 'date', 'big_integer', 'decimal'],
							list: false,
						},
						{
							value: 'less_or_equal',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Inférieur ou égale'),
							apply_to: ['integer', 'date', 'big_integer', 'decimal'],
							list: false,
						},
						{
							value: 'greater',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Supérieur'),
							apply_to: ['integer', 'date', 'big_integer', 'decimal'],
							list: false,
						},
						{
							value: 'greater_or_equal',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Supérieur ou égale'),
							apply_to: ['integer', 'date', 'big_integer', 'decimal'],
							list: false,
						},
						{
							value: 'begins_with',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Commence par'),
							apply_to: ['string', 'file'],
							list: false,
						},
						{
							value: 'not_begins_with',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Ne commence pas par'),
							apply_to: ['string', 'file'],
							list: false,
						},
						{
							value: 'contains',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Contient'),
							apply_to: ['string', 'file'],
							list: false,
						},
						{
							value: 'not_contains',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Ne contient pas'),
							apply_to: ['string', 'file'],
							list: false,
						},
						{
							value: 'ends_with',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Termine par'),
							apply_to: ['string', 'file'],
							list: false,
						},
						{
							value: 'not_ends_with',
							nb_inputs: 1,
							lib: gettextCatalog.getString('Ne se termine pas par'),
							apply_to: ['string', 'file'],
							list: false,
						},
					];

					$scope.joinTypes = ['LEFT', 'INNER', 'RIGHT', 'CROSS'];

					$scope.defaultJoinType = 'LEFT';

					let catchErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code === '37' ||
									error.data.code === '38' ||
									error.data.code === '39')) ||
							(error &&
								(error.code === '37' ||
									error.code === '38' ||
									error.code === '39'))
						) {
						}
						$rootScope.disableAjaxLoading = false;
					};

					$scope.widgetData = {};
					$scope.widgetData.widgetMenuOpenAction = {};
					$scope.widgetData.widgetMenuData = {};
					$scope.widgetData.hideWidgetMenu = true;
					$scope.showFormule = function (i, type) {
						$scope.selectedIndexForFormula = i;
						$scope.selectedEntiteType = type;
						$scope.initWidget(type);
						$scope.widgetData.widgetMenuOpenAction = {};
						$scope.widgetData.widgetMenuData = {};
						$scope.widgetData.hideWidgetMenu = true;
						$scope.widgetData.showFormule = true;
					};

					$scope.initWidget = function (type) {
						let widget = {};
						widget.grammar = {};
						widget.grammar.columns = [];
						let caracs = $scope.fromEntiteSimpleCaracsInit;
						if (type === 'to') {
							caracs = $scope.toEntiteSimpleCaracs;
						}
						for (let c in caracs) {
							let caracType =
								caracs[c].entite_type && caracs[c].entite_type.code
									? getTypeFromEntiteTypeCode(caracs[c].entite_type.code)
									: undefined;
							let col = {
								column_alias: caracs[c].lib,
								type: caracType !== undefined ? caracType : 'string',
								field: caracs[c].lib,
								value: caracs[c].code,
							};
							widget.grammar.columns.push(col);
						}
						if (type === 'from') {
							if (
								$scope.caracComplexMap.fromEntiteType[
									$scope.selectedIndexForFormula
								] &&
								$scope.caracComplexMap.fromEntiteType[
									$scope.selectedIndexForFormula
								].formula instanceof Object
							) {
								widget.grammar.columns.push(
									$scope.caracComplexMap.fromEntiteType[
										$scope.selectedIndexForFormula
									]
								);
							}
						} else {
							if (
								$scope.caracComplexMap.toEntiteType[
									$scope.selectedIndexForFormula
								] &&
								$scope.caracComplexMap.toEntiteType[
									$scope.selectedIndexForFormula
								].formula instanceof Object
							) {
								widget.grammar.columns.push(
									$scope.caracComplexMap.toEntiteType[
										$scope.selectedIndexForFormula
									]
								);
							}
						}
						$scope.widgetData.widget = widget;
						$scope.widgetData.widget.grammar.columnsTemp = angular.copy(
							$scope.widgetData.widget.grammar.columns
						);
						$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
					};

					$scope.saveFormuleEditeur = function () {
						if ($scope.selectedEntiteType === 'from') {
							$scope.caracComplexMap.fromEntiteType[
								$scope.selectedIndexForFormula
							] =
								$scope.widgetData.widget.grammar.columns[
									$scope.widgetData.widget.grammar.columns.length - 1
								];
							$scope.caracLinkMappingList[
								$scope.selectedIndexForFormula
							].operators = _.filter($scope.operators, function (item) {
								return (
									item.apply_to.indexOf(
										$scope.caracComplexMap.fromEntiteType[
											$scope.selectedIndexForFormula
										].type
									) > -1
								);
							});
						} else {
							$scope.caracComplexMap.toEntiteType[
								$scope.selectedIndexForFormula
							] =
								$scope.widgetData.widget.grammar.columns[
									$scope.widgetData.widget.grammar.columns.length - 1
								];
						}
					};

					$scope.init = function (node, id, lib, destinationId) {
						$scope.isNew = true;
						$scope.entiteTypeDest = {};
						$scope.entiteTypeDest.selected = {};
						$scope.caracComplexMap = {
							fromEntiteType: {},
							toEntiteType: {},
						};
						$scope.deletedLinksList = [];
						$scope.selection = {};
						$scope.availableLinks = [];
						$scope.availableLinks.push(0);
						$scope.toEntiteSimpleCaracs = [];
						$scope.fromEntiteSimpleCaracsFiltered = {};
						$scope.mappedCaracsIds = [];
						$scope.destEntiteType = {};
						$scope.carac = {};
						$scope.carac.poids = 100;
						$scope.carac.date_effet = $rootScope.getDateWithPattern(new Date());

						$scope.carac.date_revocation = '2099-12-31';
						delete $scope.entiteTypeDestinationStatus;
						delete $scope.entiteTypeSourceStatus;
						$scope.sourceLib = lib;
						$scope.entiteTypeSourceId = id;
						if (!$scope.carac.join_type) {
							$scope.carac.join_type = $scope.defaultJoinType;
						}
						$scope.metadataLoaded = true;
						if (node) {
							$scope.isNew = false;
							$scope.getEntitesLinks(node, id);
							$scope.entiteDestinationCode =
								vm.linkCreatorData.node.entite_type.code;
						} else {
							const excludePublic = false;
							const excludeInactive = true;
							TypeEntiteService.getTypes(
								id,
								excludePublic,
								excludeInactive
							).then(function (response) {
								$scope.types = response.data;
								for (let i = 0; i < $scope.types.length; i++) {
									$scope.types[i].lib = $scope.types[i].label;
									if ($scope.types[i].id == destinationId) {
										$scope.entiteTypeDest.selected = $scope.types[i];
										$scope.setCaractoMap($scope.types[i]);
										break;
									}
								}
								$scope.caracLinkMappingList = [];
								$scope.caracLinkMappingList.push({});
								$scope.caracLinkMappingList[0]['toType'] = 'val';
								$scope.caracLinkMappingList[0]['fromType'] = 'val';
								$scope.caracLinkMappingList[0]['operator'] = 'equal';
								$scope.entiteDestinationCode =
									$scope.entiteTypeDest.selected.code;
							});
							$scope.getSimpleCaracByEntiteId(id).then(function (data) {
								$scope.fromEntiteSimpleCaracsInit =
									$scope.filterCaracListByLib(data);
								$scope.fromEntiteSimpleCaracsFiltered[0] =
									$scope.fromEntiteSimpleCaracsInit;
							});
						}
					};

					$scope.getSimpleCaracByEntiteId = function (entiteId) {
						if (entiteId != null) {
							return CaracService.getSimpleCaracsByEntiteId(entiteId).then(
								function (response) {
									return response.data;
								}
							);
						} else {
							return [];
						}
					};
					$scope.showError = false;
					$scope.toEntiteSimpleCaracsFiltered = {};

					$scope.filterDestinationCaracs = function (i) {
						let selectedCarac = $scope.caracComplexMap.fromEntiteType[i];
						$scope.toEntiteSimpleCaracsFiltered[i] =
							$scope.toEntiteSimpleCaracs.filter(function (item) {
								if (
									selectedCarac !== null &&
									selectedCarac.entite_type !== null &&
									selectedCarac.entite_type !== undefined
								) {
									return (
										selectedCarac.entite_type.code == item.entite_type.code
									);
								} else {
									return true;
								}
							});
						if (selectedCarac) {
							$scope.caracLinkMappingList[i].operators = _.filter(
								$scope.operators,
								function (item) {
									if (
										!selectedCarac.formula ||
										(selectedCarac.hdh_format &&
											selectedCarac.hdh_format.value === 'FORMULA')
									) {
										return (
											item.apply_to.indexOf(
												getTypeFromEntiteTypeCode(
													selectedCarac.entite_type.code
												)
											) > -1
										);
									} else {
										return item.apply_to.indexOf(selectedCarac.type) > -1;
									}
								}
							);
						}

						$scope.toEntiteSimpleCaracsFiltered[i] =
							$scope.filterCaracListByLib(
								$scope.toEntiteSimpleCaracsFiltered[i]
							);
					};

					$scope.filterSourceCaracs = function (i) {
						let selectedCarac = $scope.caracComplexMap.toEntiteType[i];
						$scope.fromEntiteSimpleCaracsFiltered[i] =
							$scope.fromEntiteSimpleCaracsInit.filter(function (item) {
								if (
									selectedCarac &&
									selectedCarac.entite_type !== null &&
									selectedCarac.entite_type !== undefined
								) {
									return (
										selectedCarac.entite_type.code == item.entite_type.code
									);
								} else {
									return true;
								}
							});

						$scope.fromEntiteSimpleCaracsFiltered[i] =
							$scope.filterCaracListByLib(
								$scope.fromEntiteSimpleCaracsFiltered[i]
							);
					};
					$scope.validateSelectedLink = function (linkIndex) {
						let destinationCarac =
							$scope.caracComplexMap.toEntiteType[linkIndex];
						let sourceCarac = $scope.caracComplexMap.fromEntiteType[linkIndex];
						if (destinationCarac) {
							if (
								sourceCarac.entite_type.code !=
								destinationCarac.entite_type.code
							) {
								$scope.caracComplexMap.toEntiteType[linkIndex] = '';
								$scope.showError = true;
								delete $scope.caracComplexMap.toEntiteType[linkIndex];
							}
						}
					};

					$scope.mappedCaracsIds = [];
					$scope.fromEntiteSimpleCaracsFiltered = {};
					$scope.toEntiteSimpleCaracsFiltered = {};

					$scope.addNewAvailableLink = function () {
						$scope.showErrorMappingRequired = false;
						$scope.availableLinks.push($scope.availableLinks.length);
						let index = $scope.availableLinks.length - 1;
						$scope.fromEntiteSimpleCaracsFiltered[index] =
							$scope.filterCaracListByLib($scope.fromEntiteSimpleCaracsInit);
						$scope.toEntiteSimpleCaracsFiltered[index] =
							$scope.filterCaracListByLib($scope.toEntiteSimpleCaracs);
						$scope.caracLinkMappingList.push({});
						$scope.caracLinkMappingList[index].toType = 'val';
						$scope.caracLinkMappingList[index].fromType = 'val';
						$scope.caracLinkMappingList[index].operator = 'equal';
					};

					$scope.removeAvailableLink = function (index) {
						if (
							$scope.caracComplexMap.toEntiteType[index] &&
							$scope.caracComplexMap.toEntiteType[index].linkId !== undefined
						) {
							$scope.deletedLinksList.push(
								$scope.caracComplexMap.toEntiteType[index].linkId
							);
						}

						delete $scope.caracComplexMap.fromEntiteType[index];
						delete $scope.caracComplexMap.toEntiteType[index];
						$scope.availableLinks.splice(index, 1);
					};

					$scope.disableSaveButton = false;

					$scope.$watch(
						'caracComplexMap',
						function (newValue, oldValue) {
							if (
								newValue !== oldValue &&
								newValue !== '' &&
								newValue !== undefined
							) {
								$scope.disableSaveButton =
									Object.keys($scope.caracComplexMap.fromEntiteType).length !==
									Object.keys($scope.caracComplexMap.toEntiteType).length;
								keepTrackOnLinckId();
							}
						},
						true
					);

					let keepTrackOnLinckId = function () {
						if ($scope.caracComplexMap.fromEntiteType) {
							for (let i in $scope.caracComplexMap.fromEntiteType) {
								let linkId =
									$scope.caracComplexMap.fromEntiteType[i] !== undefined &&
									$scope.caracComplexMap.fromEntiteType[i].linkId !== undefined
										? $scope.caracComplexMap.fromEntiteType[i].linkId
										: $scope.caracComplexMap.toEntiteType[i] !== undefined &&
										  $scope.caracComplexMap.toEntiteType[i].linkId !==
												undefined
										? $scope.caracComplexMap.toEntiteType[i].linkId
										: undefined;
								if ($scope.caracComplexMap.fromEntiteType[i] !== undefined) {
									$scope.caracComplexMap.fromEntiteType[i].linkId = linkId;
								}
								if ($scope.caracComplexMap.toEntiteType[i] !== undefined) {
									$scope.caracComplexMap.toEntiteType[i].linkId = linkId;
								}
							}
						}
					};

					$scope.updateToEntiteType = function () {
						$scope
							.getSimpleCaracByEntiteId($scope.toEntiteType.id)
							.then(function (data) {
								$scope.toEntiteSimpleCaracs = $scope.filterCaracListByLib(data);

								for (let i = 0; i < $scope.availableLinks.length; i++) {
									$scope.filterDestinationCaracs(i);
								}
							})
							.catch(catchErrors);
					};

					$scope.setCaractoMap = function (newValue) {
						$scope.destEntiteType = newValue;

						if (newValue !== '' && newValue !== undefined) {
							$scope.entiteDestinationCode =
								$scope.entiteTypeDest.selected.code;
							$scope.entiteTypeDestinationId =
								$scope.entiteTypeDest.selected.id;
							$scope
								.getSimpleCaracByEntiteId(newValue.id)
								.then(function (data) {
									$scope.toEntiteSimpleCaracs =
										$scope.filterCaracListByLib(data);
									$scope.toEntiteSimpleCaracsFiltered[0] =
										$scope.toEntiteSimpleCaracs;
									$scope.filterDestinationCaracs(0);
									$scope.caracComplexMap.toEntiteType = {};
								})
								.catch(catchErrors);
						}
					};

					$scope.getEntitesLinks = function (node, entiteId) {
						$scope.caracComplexToBeMapped = node;
						$scope.selection = {};
						$scope.availableLinks = [];
						$scope.availableLinks.push(0);
						$scope.toEntiteSimpleCaracs = [];
						$scope.mappedCaracsIds = [];
						let desEntiteId = node.entite_type.id;
						$scope.caracComplexMap = { fromEntiteType: {}, toEntiteType: {} };

						$q.all({
							data_1: $scope.getSimpleCaracByEntiteId(entiteId),
							data_2: $scope.getSimpleCaracByEntiteId(node.entite_type.id),
							linkMappingData: LinkMappingService.getLinkMapping(
								$scope.caracComplexToBeMapped.id
							),
						}).then(function (results) {
							$scope.fromEntiteSimpleCaracsInit = $scope.filterCaracListByLib(
								results.data_1
							);
							$scope.fromEntiteSimpleCaracsFiltered[0] =
								$scope.fromEntiteSimpleCaracsInit;
							$scope.toEntiteSimpleCaracs = $scope.filterCaracListByLib(
								results.data_2
							);
							$scope.caracLinkMappingList = results.linkMappingData.data;

							if ($scope.caracLinkMappingList.length > 0) {
								$scope.availableLinks = [];
								for (let i = 0; i < $scope.caracLinkMappingList.length; i++) {
									$scope.availableLinks.push(i);
									$scope.filterSourceCaracs(i);
									$scope.caracLinkMappingList[i]['fromType'] = 'val';
									$scope.caracLinkMappingList[i]['toType'] = 'val';

									let toCarac = $scope.toEntiteSimpleCaracs.find(
										(carac) =>
											carac.id ===
											$scope.caracLinkMappingList[i]['id_cara_right']
									);

									// Maybe we should add a flag on the back to tell if is a reverse relation, so we can avoid this ugliness
									if (toCarac === undefined) {
										toCarac = angular.copy(
											$scope.toEntiteSimpleCaracs.find(
												(carac) =>
													carac.id ===
													$scope.caracLinkMappingList[i]['id_cara_left']
											)
										);
									}

									if (
										$scope.caracLinkMappingList[i]['formula_grammar_cara_left']
									) {
										toCarac =
											$scope.caracLinkMappingList[i][
												'formula_grammar_cara_left'
											];
										$scope.caracLinkMappingList[i]['toType'] = 'form';
									}

									$scope.caracComplexMap.toEntiteType[i] =
										angular.copy(toCarac);
									$scope.caracComplexMap.toEntiteType[i].linkId =
										$scope.caracLinkMappingList[i]['id'];

									let fromCarac = $scope.fromEntiteSimpleCaracsInit.find(
										(carac) =>
											carac.id ===
											$scope.caracLinkMappingList[i]['id_cara_left']
									);

									// Maybe we should add a flag on the back to tell if is a reverse relation, so we can avoid this ugliness
									if (fromCarac === undefined) {
										fromCarac = $scope.fromEntiteSimpleCaracsInit.find(
											(carac) =>
												carac.id ===
												$scope.caracLinkMappingList[i]['id_cara_right']
										);
									}

									if (
										$scope.caracLinkMappingList[i].formula_grammar_cara_right
									) {
										fromCarac =
											$scope.caracLinkMappingList[i][
												'formula_grammar_cara_right'
											];
										$scope.caracLinkMappingList[i]['fromType'] = 'form';
									}

									$scope.caracComplexMap.fromEntiteType[i] =
										angular.copy(fromCarac);
									$scope.caracComplexMap.fromEntiteType[i].linkId =
										$scope.caracLinkMappingList[i]['id'];
									$scope.filterDestinationCaracs(i);

									let fromCaracCodes = _.map(
										$scope.fromEntiteSimpleCaracsInit,
										function (obj) {
											return obj.code;
										}
									);
									let toCaracCodes = _.map(
										$scope.toEntiteSimpleCaracs,
										function (obj) {
											return obj.code;
										}
									);

									if (
										$scope.caracComplexMap.fromEntiteType[i].formula instanceof
											Object &&
										!checkAllFormulaCaracParams(
											fromCaracCodes,
											$scope.caracComplexMap.fromEntiteType[i].formula
										)
									) {
										$scope.caracComplexMap.fromEntiteType[i] = undefined;
									}

									if (
										$scope.caracComplexMap.toEntiteType[i].formula instanceof
											Object &&
										!checkAllFormulaCaracParams(
											toCaracCodes,
											$scope.caracComplexMap.toEntiteType[i].formula
										)
									) {
										$scope.caracComplexMap.toEntiteType[i] = undefined;
									}

									if (
										!$scope.caracComplexMap.fromEntiteType[i] ||
										!$scope.caracComplexMap.toEntiteType[i]
									) {
										$scope.caracLinkMappingList[i]['disabled'] = true;
									}
								}
							}
						});
						vm.hide_advanced = true;

						// load libelles
						CaracService.getLibellesLang($scope.caracComplexToBeMapped.id).then(
							function (response) {
								if (response.data) {
									$scope.carac = node;
									$scope.carac.lib = response.data.label;
									$scope.carac.comm = response.data.description;
									$scope.carac.label = response.data.label;
									$scope.carac.description = response.data.description;
								}
							}
						);

						TypeEntiteService.getTypes(entiteId).then(function (response) {
							$scope.types = response.data;
							for (let i = 0; i < $scope.types.length; i++) {
								if ($scope.types[i].id == desEntiteId) {
									$scope.types[i].lib = $scope.types[i].label;
									$scope.entiteTypeDest.selected = $scope.types[i];
									$scope.destEntiteType = $scope.types[i];
									break;
								}
							}
						});
					};

					var checkLinkMapping = function () {
						if (
							$scope.destEntiteType.id === undefined ||
							$scope.destEntiteType.id === ''
						) {
							$scope.showErrorChooseEntiteDest = true;
							return false;
						}

						if ($scope.availableLinks[0] === undefined) {
							$scope.showErrorMappingRequired = true;
							return false;
						}

						for (let j in $scope.availableLinks) {
							if (
								(!$scope.caracComplexMap.toEntiteType[j] ||
									!$scope.caracComplexMap.fromEntiteType[j]) &&
								!$scope.caracLinkMappingList[j]['disabled']
							) {
								$scope.showErrorChooseCarac = true;
								return false;
							} else if (!$scope.caracLinkMappingList[j]['operator']) {
								$scope.showErrorChooseOperator = true;
								return false;
							}
						}

						return true;
					};

					$scope.save = function () {
						$scope.showErrorChooseEntiteDest = false;
						$scope.showErrorChooseCarac = false;
						$scope.showErrorMappingRequired = false;
						$scope.showErrorChooseOperator = false;
						if ($scope.carac.tags !== undefined) {
							for (let i = 0; i < $scope.carac.tags.length; i++) {
								$scope.carac.tags[i].color = '#DDF663';
							}
						}
						if ($scope.isNew) {
							$scope.carac.complex = true;
							$scope.carac.id = null;
							$scope.carac.entite_type = {};
						}

						if (!checkLinkMapping()) {
							return;
						}
						$scope.carac.entite_type.id = $scope.destEntiteType.id;
						let v1 = '';
						let MappedCarac = [];

						for (let l in $scope.caracComplexMap.toEntiteType) {
							let v2 = $scope.caracComplexMap.toEntiteType[l].id;
							let v3 = $scope.caracComplexMap.fromEntiteType[l].id;
							v1 = $scope.caracComplexMap.toEntiteType[l].linkId;
							let linkMapping = {
								operator: $scope.caracLinkMappingList[l]['operator'],
								id: v1,
								id_cara_complex:
									$scope.caracComplexToBeMapped &&
									$scope.caracComplexToBeMapped.id !== undefined
										? $scope.caracComplexToBeMapped.id
										: null,
								id_cara_left: !(
									$scope.caracComplexMap.toEntiteType[l].formula instanceof
									Object
								)
									? v2
									: null,
								id_cara_right: !(
									$scope.caracComplexMap.fromEntiteType[l].formula instanceof
									Object
								)
									? v3
									: null,
								formula_grammar_cara_right:
									$scope.caracComplexMap.fromEntiteType[l].formula instanceof
									Object
										? $scope.caracComplexMap.fromEntiteType[l]
										: null,
								formula_grammar_cara_left:
									$scope.caracComplexMap.toEntiteType[l].formula instanceof
									Object
										? $scope.caracComplexMap.toEntiteType[l]
										: null,
							};

							MappedCarac.push(linkMapping);
						}

						let caracMappingObj = {
							entite_type_id: $scope.entiteTypeSourceId,
							carac: $scope.carac,
							links: MappedCarac,
							deleted_links: $scope.deletedLinksList,
						};

						if ($scope.isNew) {
							$scope.savenewLink(caracMappingObj);
						} else {
							$scope.updateLink(caracMappingObj);
						}
					};

					$scope.updateLink = function (caracMappingObj) {
						LinkMappingService.saveCaracLinkMappings(caracMappingObj).then(
							function (response) {
								toaster.pop('success', toasterPopSucess, toasterPopUpdate);
								let carac = response.data;
								carac.lib_entite_type = $scope.sourceLib;
								for (let i in vm.caracsComplexList) {
									if (vm.caracsComplexList[i].id == carac.id) {
										vm.caracsComplexList[i] = carac;
										break;
									}
								}
								if (vm.updateLink) {
									vm.updateLink(carac, undefined);
								}
								$scope.exit();
							}
						);
					};

					$scope.savenewLink = function (caracMappingObj) {
						LinkMappingService.createCaracLinkMappings(caracMappingObj).then(
							function (response) {
								toaster.pop('success', toasterPopSucess, toasterPopSCreation);
								let carac = response.data;
								carac.lib_entite_type = $scope.sourceLib;
								if (vm.caracsComplexList) {
									vm.caracsComplexList.push(carac);
								}

								if (vm.updateLink) {
									vm.updateLink(undefined, carac);
								}

								if (vm.updateAction) {
									vm.updateAction();
								}
								vm.refresh();
								$scope.exit();
							}
						);
					};

					$scope.close = function () {
						vm.widgetData.confirmationDialogTitle =
							translateConfirmationDialog.title;
						vm.widgetData.confirmationDialogMessage =
							translateConfirmationDialog.message;
						vm.widgetData.confirmationDialogMessageDetails = '';
						vm.widgetData.confirmationDialogActionName =
							translateConfirmationDialog.btnName;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.save;
						vm.widgetData.closeDialogAction = $scope.exit;
						vm.widgetData.showConfirmationDialog = true;
					};

					vm.closeModal = function () {
						$scope.close();
					};

					$scope.exit = function () {
						vm.linkCreatorData.entiteTypeSourceId = '';
						vm.linkCreatorData.entiteTypeLib = '';
						vm.linkCreatorData.node = undefined;
						vm.linkCreatorData.showLinkCreator = false;
						$scope.isNew = true;
						$scope.showError = false;
					};

					$scope.goToSourceEntite = function () {
						const url = $state.href('entites-edit', {
							entiteId: vm.linkCreatorData.entiteTypeSourceId,
						});
						$window.open(url, '_blank');
						return;
					};

					$scope.goToDestinationEntite = function () {
						const url = $state.href('entites-edit', {
							entiteId: $scope.entiteTypeDestinationId,
						});
						$window.open(url, '_blank');
						return;
					};

					$scope.linkStatData = {};
					$scope.executeStat = function (noCache) {
						if (!checkLinkMapping()) {
							return;
						}
						$scope.caracComplexMap =
							$scope.caracComplexMap !== undefined
								? $scope.caracComplexMap
								: { fromEntiteType: {}, toEntiteType: {} };
						$scope.linkStatData.targetId = vm.linkCreatorData.node
							? vm.linkCreatorData.node.entite_type.id
							: $scope.entiteTypeDest.selected.id;
						$scope.linkStatData.targetLib = vm.linkCreatorData.node
							? vm.linkCreatorData.node.entite_type.lib
							: $scope.entiteTypeDest.selected.lib;
						$scope.linkStatData.targetCode = vm.linkCreatorData.node
							? vm.linkCreatorData.node.entite_type.code
							: $scope.entiteTypeDest.selected.code;
						$scope.linkStatData.sourceId =
							vm.linkCreatorData.entiteTypeSourceId;
						$scope.linkStatData.sourceLib =
							vm.linkCreatorData.entiteTypeSourceLib;
						$scope.linkStatData.sourceCode =
							vm.linkCreatorData.entiteTypeSourceCode;
						$scope.linkStatData.fromEntite = true;
						$scope.linkStatData.linkData = {
							from: $scope.caracComplexMap.fromEntiteType,
							to: $scope.caracComplexMap.toEntiteType,
						};
						$scope.linkStatData.availableLinks = $scope.availableLinks;
						$scope.linkStatData.caracLinkMappingList =
							$scope.caracLinkMappingList;
						$scope.widgetData.showLinkStat = true;
					};

					let checkAllFormulaCaracParams = function (caracs, formula) {
						if (formula.type === 'function') {
							for (let p in formula.params) {
								let bool = checkAllFormulaCaracParams(
									caracs,
									formula.params[p]
								);
								if (!bool) {
									return false;
								}
							}
						} else if (formula.type === 'carac') {
							return caracs.indexOf(formula.value) > -1;
						} else {
							return true;
						}
						return true;
					};

					//filter carac
					$scope.filterCaracListByLib = function (caracs) {
						return _.sortBy(caracs, function (elm) {
							return elm.lib !== undefined ? elm.lib.toLowerCase() : elm.lib;
						});
					};
				},
			];
			return {
				restrict: 'E',

				scope: {
					linkCreatorData: '=',
					updateAction: '=',
					updateLink: '=',
					widgetData: '=',
					caracsComplexList: '=',
					refresh: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				transclude: true,
				templateUrl:
					'./src/components/directives/entiteTypeLinkCreator/entiteTypeLinkCreator.html',
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					element.bind('keydown keypress', function (event) {
						if (event.which == 27) {
							event.stopPropagation();
							event.stopImmediatePropagation();
							event.stopPropagation();
							if (event.target.id == element[0].id) {
								scope.vm.closeModal();
							} else {
								$(event.target).modal('hide');
							}
						}
					});
					scope.$watch(
						function () {
							return $ctrl.linkCreatorData.showLinkCreator;
						},
						function (value) {
							$ctrl.element = element;
							if (value) {
								scope.jobDetails = { rerunMethod: scope.execWithoutCache };
								scope.jobDetailsId = generateUuid('_');
								scope.showErrorChooseEntiteDest = false;
								scope.showErrorChooseCarac = false;
								scope.showErrorChooseCarac = false;
								scope.entiteTypeSourceId =
									$ctrl.linkCreatorData.entiteTypeSourceId;
								if ($ctrl.linkCreatorData.entiteTypeDestinationId) {
									scope.entiteTypeDestinationId =
										$ctrl.linkCreatorData.entiteTypeDestinationId;
								} else if ($ctrl.linkCreatorData.node) {
									scope.entiteTypeDestinationId =
										$ctrl.linkCreatorData.node.entite_type.id;
								}
								scope.statExecuted = false;
								scope.init(
									$ctrl.linkCreatorData.node,
									$ctrl.linkCreatorData.entiteTypeSourceId,
									$ctrl.linkCreatorData.entiteTypeSourceLib,
									$ctrl.linkCreatorData.entiteTypeDestinationId
								);
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
