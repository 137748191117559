(function () {
	'use strict';

	angular.module('dcApp').directive('moveElement', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$window',
				'ProjectService',
				'MoveElementService',
				'toaster',
				'gettextCatalog',
				'API_BASE_URL_BACKEND',
				'PROJECT_VAR_NAME',
				'PROJECT_LABEL_VAR_NAME',
				function (
					$scope,
					$window,
					ProjectService,
					MoveElementService,
					toaster,
					gettextCatalog,
					API_BASE_URL_BACKEND,
					PROJECT_VAR_NAME,
					PROJECT_LABEL_VAR_NAME
				) {
					let vm = this;
					let accessToken = window._keycloak.token;
					let currentProject = $window.sessionStorage.getItem(PROJECT_VAR_NAME)
						? $window.sessionStorage.getItem(PROJECT_VAR_NAME)
						: $window.localStorage.getItem(PROJECT_VAR_NAME);
					$scope.currentProjectLabel = $window.sessionStorage.getItem(
						PROJECT_LABEL_VAR_NAME
					)
						? $window.sessionStorage.getItem(PROJECT_LABEL_VAR_NAME)
						: $window.localStorage.getItem(PROJECT_LABEL_VAR_NAME);
					$scope.step = undefined;

					$scope.selectedProject = undefined;
					$scope.init = function (elements, type, elementModal) {
						$scope.atLeastOneProjectSelected = false;
						delete $scope.selectedProject;
						delete $scope.elementsOrg;
						delete $scope.elementsTree;
						delete $scope.selectedProject;
						delete $scope.currentSelected;
						$scope.elements = elements;
						$scope.type = type;
						loadProjects(elementModal);
						$scope.step = 'first';
					};

					$scope.projects = [];
					var loadProjects = function (element) {
						ProjectService.getProjects().then(function (response) {
							const data = response.data;
							let currentProjectObject = _.find(data, function (item) {
								return item.id == currentProject;
							});
							let privateProject = !currentProjectObject.is_public;

							if (privateProject) {
								$scope.projects = _.filter(data, function (item) {
									return item.is_public;
								});
							}
							$scope.projects = _.filter(
								privateProject ? $scope.projects : data,
								function (item) {
									return item.id != currentProject;
								}
							);
							_.each($scope.projects, function (item) {
								item.inactive = item.expired || !item.metadata.actif;
							});
							$scope.projects = _.sortBy($scope.projects, function (item) {
								return !item.favorite_project;
							});
							$scope.projects = _.sortBy($scope.projects, function (item) {
								return item.inactive;
							});
							for (let p in $scope.projects) {
								$scope.projects[p].expiration_date = $scope.projects[p]
									.expiration_date
									? moment($scope.projects[p].expiration_date)
									: '';
								$scope.projects[p].imgUrl =
									API_BASE_URL_BACKEND +
									'pictogramme_image?id=' +
									($scope.projects[p].metadata.icon_id
										? $scope.projects[p].metadata.icon_id
										: -1);
							}
							$scope.filteredProjects = $scope.projects;
							ProjectService.getProjectMembersCount().then(function (response) {
								const dataM = response.data;
								for (let p in $scope.projects) {
									$scope.projects[p].member_count = dataM[$scope.projects[p].id]
										? dataM[$scope.projects[p].id]
										: 0;
								}
							});
							ProjectService.getProjectContextsCount().then(function (
								response
							) {
								const dataC = response.data;
								for (let p in $scope.projects) {
									$scope.projects[p].context_count = dataC[
										$scope.projects[p].id
									]
										? dataC[$scope.projects[p].id]
										: 0;
								}
							});
							ProjectService.getProjectContextAvailabilities().then(function (
								response
							) {
								const dataCA = response.data;
								for (let p in $scope.projects) {
									$scope.projects[p].unavailable_contexts =
										dataCA[$scope.projects[p].id] == false;
								}
							});
							$(element).modal('show');
						});
					};
					loadProjects();

					$scope.filterProjects = function () {
						$scope.filteredProjects = _.filter(
							$scope.projects,
							function (item) {
								return (
									(item.metadata.label &&
										item.metadata.label
											.toLowerCase()
											.indexOf($scope.searchText.toLowerCase()) > -1) ||
									(item.metadata.description &&
										item.metadata.description
											.toLowerCase()
											.indexOf($scope.searchText.toLowerCase()) > -1) ||
									(item.metadata.tags &&
										_.filter(item.metadata.tags, function (it) {
											return (
												it.code
													.toLowerCase()
													.indexOf($scope.searchText.toLowerCase()) > -1
											);
										})[0])
								);
							}
						);
					};

					$scope.currentSelected = undefined;
					$scope.selectCurrent = function (project) {
					$scope.atLeastOneProjectSelected = true;
						for (let p in $scope.filteredProjects) {
							$scope.filteredProjects[p].selected = $scope.filteredProjects[p].id === project.id;
						}
						$scope.currentSelected = project;
					};

					function formatDataAsTreeItem(data) {
						if (!data) {
							return;
						}
						let newItem = {
							id: _.uniqueId(data.id + '_' + data.type),
							text: data.label,
							expanded: data.linked_elements && data.linked_elements.length > 0,
							type: data.type,
							items: [],
							originalId: data.id,
							itemSelected: true,
						};
						if (newItem.expanded) {
							for (let i in data.linked_elements) {
								newItem.items.push(
									formatDataAsTreeItem(data.linked_elements[i])
								);
							}
						}

						return newItem;
					}

					function formatDataAsTree(data) {
						let newData = [];
						for (let i in data) {
							newData[i] = formatDataAsTreeItem(data[i]);
						}

						return newData;
					}

					$scope.moveToNextStep = function () {
						if ($scope.step === 'first') {
							let selectedP = _.find($scope.filteredProjects, function (item) {
								return item.selected;
							});
							if (selectedP) {
								MoveElementService.getElementToMove(
									$scope.elements,
									selectedP.id,
									$scope.type
								)
									.then(function (response) {
										$scope.elementsOrg = response.data;
										$scope.step = 'second';
										$scope.elementsTree = formatDataAsTree($scope.elementsOrg);
										$scope.selectedProject = selectedP;
										$scope.treeViewOptions = {
											items: $scope.elementsTree,
											width: 600,
										};
									})
									.catch(function (e) {
										if (e == 'NonSupportedType') {
											toaster.pop('error', 'Unsupported Type');
										}
									});
							} else {
								return;
							}
						} else if ($scope.step === 'second') {
							$scope.step = 'third';
							MoveElementService.moveElements(
								$scope.elements,
								$scope.selectedProject.id,
								$scope.type
							).then(function (response) {
								toaster.pop('success', 'Elements déplacés');
								$scope.moveReport = response.data;
								$scope.step = 'last';
							});
						}
					};

					$scope.backToProjectChoice = function () {
						if ($scope.step === 'last') {
							closeModal();
						} else {
							$scope.selectedProject = undefined;
							$scope.step = 'first';
						}
					};

					$scope.getElementIcon = function (item) {
						let type = item.type;
						switch (type) {
							case 'CONNECTOR':
								return '<i class="far fa-plug fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'DEPOT':
								return '<i class="far fa-boxes fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'ENTITETYPE':
								return '<i class="fal fa-credit-card-front fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'DATABLOCK':
							case 'DATAVIEW':
								return '<i class="fal fa-table fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'CHART':
								return '<i class="fal fa-chart-line fa-lg item-icon-rad" aria-hidden="true"> </i>';
							case 'TIMELINE':
								return '<i class="fal fa-line-height fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'MAP':
								return '<i class="fal fal fa-map-marked-alt fa-lg item-icon-rad" aria-hidden="true"></i>';
							case 'GRID':
								return '<i class="fal fas fa-table fa-lg item-icon-rad" aria-hidden="true"> </i>';
							case 'MEDIA':
								return '<i class="fal fa-photo-video fa-lg item-icon-rad" aria-hidden="true"> </i>';
							case 'DASHBOARD':
								return '<i class="fal fa-file-medical-alt fa-lg item-icon-rad" aria-hidden="true"> </i>';
							default:
								return '';
						}
					};

					function closeModal() {
						delete $scope.selectedProject;
						delete $scope.step;
						delete $scope.elementsOrg;
						delete $scope.elementsTree;
						delete $scope.selectedProject;
						delete $scope.currentSelected;
						$(vm.element).modal('hide');
						vm.showMoveCopy = false;
					}

					$scope.closeModal = closeModal;
				},
			];

			return {
				restrict: 'E',

				scope: {
					showMoveCopy: '=',
					elements: '=',
					type: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/moveProjectElement/moveProjectElement.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.showMoveCopy = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.showMoveCopy;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.saveAction = scope.vm.saveAction;
								scope.init(scope.vm.elements, scope.vm.type, element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
