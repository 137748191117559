(function () {
	angular.module('dcApp').service('subTimeline', [
		'$rootScope',
		'gettextCatalog',
		function ($rootScope, gettextCatalog) {
			this.init = function (
				uuid,
				el,
				itemsData,
				groupedItems,
				groupsData,
				options,
				parentTimeline
			) {
				var tl = {};
				tl.parentTimeline = parentTimeline;

				tl.itemsData = itemsData;
				tl.groupedItems = groupedItems;
				tl.groupsData = groupsData;

				tl.items = new vis.DataSet(tl.itemsData);
				tl.groups = new vis.DataSet(tl.groupsData);
				tl.options = $.extend(
					{
						template: function (item) {
							var format = 'Y-MMM-D H:s';
							var title =
								item.items && item.items.length > 1
									? 'from: ' +
									  moment(item.start).format(format) +
									  ' to: ' +
									  moment(_.last(item.items).start).format(format)
									: '';

							var group = tl.groups.get(item.group);
							var items = [];
							var ids;
							if (item.items && item.items.length > 1) {
								for (var i in item.items) {
									items.push(item.items[i].id);
								}
								ids = items.join('-');
							} else {
								ids = item.firstid;
							}

							var str =
								$rootScope.API_BASE_URL_BACKEND +
								'pictogramme_image?id=' +
								group.icon_id;

							return (
								'<span onmouseover=\'showInfo("' +
								uuid +
								'", event, "' +
								item.group +
								'",' +
								'"' +
								ids +
								'")\'  onclick=\'doReboundMany("' +
								uuid +
								'", "' +
								item.group +
								'",' +
								'"' +
								ids +
								"\")' class='vis-span item-clickable' title='" +
								title +
								"'>" +
								(item.items ? '<b>' + item.items.length + '</b> ' : '') +
								(group
									? "<i class='category'><img style='width: 15px; height: 15px; margin-top:-5px'  src='" +
									  str +
									  "' type='button' title='" +
									  gettextCatalog.getString('Pictogramme') +
									  "'/><i>"
									: '') +
								'</span>'
							);
						},
					},
					{
						locales: {
							// create a new locale (text strings should be replaced with localized strings)
							fr: {
								current: 'current',
								time: 'temps',
							},
						},
					},
					getDefaultTimelineDates(tl),
					typeof options == 'undefined' ? {} : options
				);

				tl.el = el;
				draw(tl);
				zoom(-1, tl);

				initEvents(tl);

				return tl;
			};
		},
	]);
})();
