(function () {
	'use strict';

	angular.module('dcApp').directive('columnStat', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$rootScope',
				'CaracService',
				'GenericService',
				'EntiteDepotService',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'SparkJobsServices',
				'DataBlocksService',
				'$window',
				'gettextCatalog',
				'TypeEntiteService',
				function (
					$log,
					$scope,
					$rootScope,
					CaracService,
					GenericService,
					EntiteDepotService,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					SparkJobsServices,
					DataBlocksService,
					$window,
					gettextCatalog,
					TypeEntiteService
				) {
					var vm = this;
					$scope.topValueNumbers = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
					$scope.histogramBucketCount = 15;

					$scope.$on('job_private', function (event, message) {
						handleSocketMessageJobRunning(message);
					});

					var catchStatJobErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.statJobError.errorMessage = gettextCatalog.getString(
								'context.unavailable'
							);
						}
						$scope.statJobError.error = true;
						$scope.columnStatIsLoading = false;
						$rootScope.disableAjaxLoading = false;
					};

					var catchTopValueJobErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.topValueJobError.errorMessage = gettextCatalog.getString(
								'context.unavailable'
							);
						}
						$scope.topValueJobError.error = true;
						$scope.columnTopValueIsLoading = false;
						$rootScope.disableAjaxLoading = false;
					};

					var catchHistogramJobErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.histogramJobError.errorMessage = gettextCatalog.getString(
								'context.unavailable'
							);
						}
						$scope.histogramJobError.error = true;
						$scope.columnHistogramIsLoading = false;
						$rootScope.disableAjaxLoading = false;
					};

					var catchCountDistinctJobErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.countDistinctJobError.errorMessage =
								gettextCatalog.getString('context.unavailable');
						}
						$scope.countDistinctJobError.error = true;
						$scope.countDistinctIsLoading = false;
						$rootScope.disableAjaxLoading = false;
					};

					var catchCountDistinctApproxJobErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.countDistinctApproxJobError.errorMessage =
								gettextCatalog.getString('context.unavailable');
						}
						$scope.countDistinctApproxJobError.error = true;
						$scope.countDistinctApproxIsLoading = false;
						$rootScope.disableAjaxLoading = false;
					};

					var handleSocketMessageJobRunning = function (notif) {
						if (
							$scope.statJobClientId != notif.data.client_id &&
							$scope.topValueJobClientId != notif.data.client_id &&
							$scope.histogramJobClientId != notif.data.client_id &&
							$scope.countDistinctJobClientId != notif.data.client_id &&
							$scope.countDistinctApproxJobClientId != notif.data.client_id
						) {
							return;
						}
						if (
							!$scope.statJobClientId &&
							!$scope.topValueJobClientId &&
							!$scope.histogramJobClientId &&
							!$scope.countDistinctJobClientId &&
							!$scope.countDistinctApproxJobClientId
						) {
							return;
						}
						if (
							notif.data.status == 'CANCELLED' ||
							notif.data.status == 'ERROR'
						) {
							if ($scope.statJobClientId == notif.data.client_id) {
								$scope.columnStatIsLoading = false;
								$scope.statJobError.errorMessage = notif.data.error_message;
								$scope.statJobError.error = true;
							} else if ($scope.topValueJobClientId == notif.data.client_id) {
								$scope.columnTopValueIsLoading = false;
								$scope.topValueJobError.errorMessage = notif.data.error_message;
								$scope.topValueJobError.error = true;
							} else if ($scope.histogramJobClientId == notif.data.client_id) {
								$scope.columnHistogramIsLoading = false;
								$scope.histogramJobError.errorMessage =
									notif.data.error_message;
								$scope.histogramJobError.error = true;
							} else if (
								$scope.countDistinctJobClientId == notif.data.client_id
							) {
								$scope.countDistinctIsLoading = false;
								$scope.countDistinctJobError.errorMessage =
									notif.data.error_message;
								$scope.countDistinctJobError.error = true;
							} else if (
								$scope.countDistinctApproxJobClientId == notif.data.client_id
							) {
								$scope.countDistinctApproxIsLoading = false;
								$scope.countDistinctApproxJobError.errorMessage =
									notif.data.error_message;
								$scope.countDistinctApproxJobError.error = true;
							}
						}

						if (
							notif.data.status == 'SUCCESS' ||
							(notif.data.status == 'CACHED' && notif.data.cached_after_waiting)
						) {
							$rootScope.disableAjaxLoading = true;
							DataBlocksService.getDataByJobId(notif.data.job_id)
								.then(function (response) {
									$rootScope.disableAjaxLoading = false;
									if (response != undefined && response.data) {
										if ($scope.statJobClientId == notif.data.client_id) {
											loadStatResult(response.data, response.data.status);
										} else if (
											$scope.topValueJobClientId == notif.data.client_id
										) {
											loadTopValueResult(
												response.data,
												response.data.response_status
											);
										} else if (
											$scope.histogramJobClientId == notif.data.client_id
										) {
											loadHistogramResult(
												response.data,
												response.data.response_status
											);
										} else if (
											$scope.countDistinctJobClientId == notif.data.client_id
										) {
											loadCountDistinctResult(
												response.data,
												response.data.response_status
											);
										} else if (
											$scope.countDistinctApproxJobClientId ==
											notif.data.client_id
										) {
											loadCountDistinctApproxResult(
												response.data,
												response.data.response_status
											);
										}
									}
								})
								.catch(function (error) {
									if ($scope.statJobClientId == notif.data.client_id) {
										catchStatJobErrors(error);
									} else if (
										$scope.topValueJobClientId == notif.data.client_id
									) {
										catchTopValueJobErrors(error);
									} else if (
										$scope.histogramJobClientId == notif.data.client_id
									) {
										catchHistogramJobErrors(error);
									} else if (
										$scope.countDistinctJobClientId == notif.data.client_id
									) {
										catchCountDistinctJobErrors(error);
									} else if (
										$scope.countDistinctApproxJobClientId ==
										notif.data.client_id
									) {
										catchCountDistinctApproxJobErrors(error);
									}
								});
						}
					};

					$scope.startStats = function (withoutCache) {
						if ($scope.column && $scope.column.type == 'words') {
							return;
						}

						if ($scope.statJobDetails.init) {
							$scope.statJobDetails.init();
						}

						$rootScope.disableAjaxLoading = true;
						$scope.statJobClientId = generateClientId($rootScope.account.login);
						$scope.statJobError = {};
						$scope.columnStatIsLoading = true;
						GenericService.getStatFunctions(
							vm.widgetData.columnInfoGrammar,
							vm.widgetData.columnInfoId,
							$scope.statJobClientId,
							vm.widgetData.columnInfoDataBlockId,
							vm.widgetData.columnInfoType,
							vm.widgetData.columnInfoIsList,
							!withoutCache
						)
							.then(function (response_) {
								if (!response_.data || !response_.data.value) {
									$scope.statJobError.error = true;
									$scope.columnStatIsLoading = false;
									return;
								}
								$scope.statJobId = response_.data.value;
								if (response_.data.exec_data_result) {
									delete $scope.statJobClientId;
									loadStatResult(
										response_.data.exec_data_result,
										response_.data.status
									);
									$scope.columnStatIsLoading = false;
									return;
								}
							})
							.catch(catchStatJobErrors);
					};

					$scope.stopStats = function () {
						if ($scope.statJobId) {
							$scope.statJobError = {};
							SparkJobsServices.killJob($scope.statJobId)
								.then(function (response) {
									$scope.columnStatIsLoading = false;
								})
								.catch(catchStatJobErrors);
						}
					};

					$scope.startCountDistinct = function (withoutCache) {
						if ($scope.column && $scope.column.type == 'words') {
							return;
						}
						if ($scope.countDistinctJobDetails.init) {
							$scope.countDistinctJobDetails.init();
						}
						$rootScope.disableAjaxLoading = true;
						$scope.countDistinctJobClientId = generateClientId(
							$rootScope.account.login
						);
						$scope.countDistinctJobError = {};
						$scope.countDistinctIsLoading = true;
						GenericService.getCountDistinct(
							vm.widgetData.columnInfoGrammar,
							vm.widgetData.columnInfoId,
							$scope.countDistinctJobClientId,
							vm.widgetData.columnInfoDataBlockId,
							vm.widgetData.columnInfoType,
							vm.widgetData.columnInfoIsList,
							!withoutCache
						)
							.then(function (response_) {
								if (!response_.data || !response_.data.value) {
									$scope.countDistinctJobError.error = true;
									$scope.countDistinctIsLoading = false;
									return;
								}
								$scope.countDistinctJobId = response_.data.value;
								if (response_.data.exec_data_result) {
									delete $scope.countDistinctJobClientId;
									loadCountDistinctResult(
										response_.data.exec_data_result,
										response_.data.status
									);
									$scope.countDistinctIsLoading = false;
									return;
								}
							})
							.catch(catchCountDistinctJobErrors);
					};

					$scope.startCountDistinctApprox = function (withoutCache) {
						if ($scope.column && $scope.column.type == 'words') {
							return;
						}
						if ($scope.countDistinctApproxJobDetails.init) {
							$scope.countDistinctApproxJobDetails.init();
						}
						$rootScope.disableAjaxLoading = true;
						$scope.countDistinctApproxJobClientId = generateClientId(
							$rootScope.account.login
						);
						$scope.countDistinctApproxJobError = {};
						$scope.countDistinctApproxIsLoading = true;
						GenericService.getCountDistinctApprox(
							vm.widgetData.columnInfoGrammar,
							vm.widgetData.columnInfoId,
							$scope.countDistinctApproxJobClientId,
							vm.widgetData.columnInfoDataBlockId,
							vm.widgetData.columnInfoType,
							vm.widgetData.columnInfoIsList,
							!withoutCache
						)
							.then(function (response_) {
								if (!response_.data || !response_.data.value) {
									$scope.countDistinctApproxJobError.error = true;
									$scope.countDistinctApproxIsLoading = false;
									return;
								}
								$scope.countDistinctApproxJobId = response_.data.value;
								if (response_.data.exec_data_result) {
									delete $scope.countDistinctApproxJobClientId;
									loadCountDistinctApproxResult(
										response_.data.exec_data_result,
										response_.data.status
									);
									$scope.countDistinctApproxIsLoading = false;
									return;
								}
							})
							.catch(catchCountDistinctApproxJobErrors);
					};

					$scope.stopCountDistinct = function () {
						if ($scope.countDistinctJobId) {
							$scope.countDistinctJobError = {};
							SparkJobsServices.killJob($scope.countDistinctJobId)
								.then(function (response) {
									$scope.countDistinctIsLoading = false;
								})
								.catch(catchCountDistinctJobErrors);
						}
					};

					$scope.stopCountDistinctApprox = function () {
						if ($scope.countDistinctApproxJobId) {
							$scope.countDistinctApproxJobError = {};
							SparkJobsServices.killJob($scope.countDistinctApproxJobId)
								.then(function (response) {
									$scope.countDistinctApproxIsLoading = false;
								})
								.catch(catchCountDistinctApproxJobErrors);
						}
					};

					$scope.startHistogram = function (withoutCache) {
						if (!$scope.enableHistogram) {
							return;
						}
						if ($scope.histogramJobDetails.init) {
							$scope.histogramJobDetails.init();
						}
						delete $scope.histogramChartOptions;
						delete $scope.histogramMessage;
						$rootScope.disableAjaxLoading = true;
						$scope.histogramJobClientId = generateClientId(
							$rootScope.account.login
						);
						$scope.histogramJobError = {};
						$scope.columnHistogramIsLoading = true;

						GenericService.getHistogram(
							vm.widgetData.columnInfoGrammar,
							vm.widgetData.columnInfoId,
							$scope.histogramBucketCount,
							$scope.histogramJobClientId,
							vm.widgetData.columnInfoDataBlockId,
							!withoutCache
						)
							.then(function (response_) {
								if (!response_.data || !response_.data.value) {
									$scope.histogramJobError.error = true;
									$scope.columnHistogramIsLoading = false;
									return;
								}
								$scope.histogramJobId = response_.data.value;
								if (response_.data.exec_data_result) {
									delete $scope.histogramJobClientId;
									loadHistogramResult(
										response_.data.exec_data_result,
										response_.data.status
									);
									$scope.columnHistogramIsLoading = false;
									return;
								}
							})
							.catch(catchHistogramJobErrors);
					};

					$scope.stopHistogram = function () {
						if ($scope.histogramJobId) {
							$scope.histogramJobError = {};
							SparkJobsServices.killJob($scope.histogramJobId)
								.then(function (response) {
									$scope.columnHistogramIsLoading = false;
								})
								.catch(catchHistogramJobErrors);
						}
					};

					var loadHistogramResult = function (result, jobStatus) {
						$scope.histogramJobDetails.update({
							name: gettextCatalog.getString('Répartition - histogramme'),
							pushDate: result.push_date,
							endExecutionDate: result.exec_end_date,
							duration: result.time_exec,
						});
						$scope.histogramJobDetails.setFromCache(result.is_cached);

						let data = result.data;
						if (!data || !data[0] || !data[1]) {
							$log.debug('missed data');
							$scope.columnHistogramIsLoading = false;
							return;
						}
						if (
							data[0].length == 2 &&
							data[0][0] == data[0][data[0].length - 1]
						) {
							$scope.histogramMessage =
								gettextCatalog.getString('Données invalides');
							$scope.columnHistogramIsLoading = false;
							return;
						}
						let series = [];

						let dataSource = [];
						for (var i = 0; i < data[0].length - 1; i = i + 1) {
							let serie = {
								color: 'blue',
								name: 'serie' + i,
								argumentField: 'arg' + i,
								min: data[0][i].toFixed(2),
							};
							let point = {};
							point[serie.argumentField] = data[0][i].toFixed(2);
							point.valField = data[1][i];

							let point1 = {};
							point1[serie.argumentField] = data[0][i + 1].toFixed(2);
							point1.valField = data[1][i];

							series.push(serie);
							dataSource.push(point);
							dataSource.push(point1);
						}

						loadHistogramChart(dataSource, series);
						$scope.columnHistogramIsLoading = false;
					};

					var loadHistogramChart = function (dataSource, series) {
						var chartOptions = {
							commonSeriesSettings: { valueField: 'valField', type: 'area' },
							dataSource: dataSource,
							legend: { visible: false },
							series: series,
							scrollBar: { visible: false },
							zoomAndPan: { argumentAxis: 'both' },
						};
						$scope.histogramChartOptions = {
							commonSeriesSettings: { valueField: 'valField', type: 'area' },
							dataSource: dataSource,
							legend: { visible: false },
							series: series,
							scrollBar: { visible: false },
							zoomAndPan: { argumentAxis: 'both' },
						};
					};

					$scope.startTopValues = function (withoutCache) {
						if ($scope.topValueJobDetails.init) {
							$scope.topValueJobDetails.init();
						}
						$rootScope.disableAjaxLoading = true;
						delete $scope.topValueGridOptions;
						$scope.topValueJobClientId = generateClientId(
							$rootScope.account.login
						);
						$scope.topValueJobError = {};
						$scope.columnTopValueIsLoading = true;
						GenericService.getTopValues(
							vm.widgetData.columnInfoGrammar,
							vm.widgetData.columnInfoId,
							$scope.topValueJobClientId,
							$scope.topValueNumber,
							$scope.topValueOrder,
							vm.widgetData.columnInfoDataBlockId,
							vm.widgetData.columnInfoDataViewId,
							!withoutCache
						)
							.then(function (response) {
								if (!response.data || !response.data.value) {
									$scope.topValueJobError.error = true;
									$scope.columnTopValueIsLoading = false;
									return;
								}
								$scope.topValueJobId = response.data.value;
								if (response.data.exec_data_result) {
									delete $scope.topValueJobClientId;
									loadTopValueResult(
										response.data.exec_data_result,
										response.data.status
									);
									$scope.columnTopValueIsLoading = false;
									return;
								}
							})
							.catch(catchTopValueJobErrors);
					};

					$scope.stopTopValue = function () {
						if ($scope.topValueJobId) {
							$scope.topValueJobError = {};
							SparkJobsServices.killJob($scope.topValueJobId)
								.then(function (response) {
									$scope.columnTopValueIsLoading = false;
								})
								.catch(catchTopValueJobErrors);
						}
					};

					var loadTopValueResult = function (result, jobStatus) {
						$scope.topValueJobDetails.update({
							name: gettextCatalog.getString('Top valeurs'),
							pushDate: result.push_date,
							endExecutionDate: result.exec_end_date,
							duration: result.time_exec,
						});
						$scope.topValueJobDetails.setFromCache(result.is_cached);
						let data = result.data;
						let formattedData = [];
						let pieData = [];
						let total = 0;
						for (var d in data) {
							let val =
								vm.widgetData.columnInfoType == 'date'
									? $rootScope.getDateTimeWithPattern(data[d][0])
									: data[d][0];
							formattedData.push({ valeur: val, count: data[d][1] });
							pieData.push({ valeur: '' + val, count: data[d][1] });
							total = total + data[d][1];
						}
						if ($scope.stats.totalCount > total) {
							pieData.push({
								valeur: gettextCatalog.getString('Autres'),
								count: $scope.stats.totalCount - total,
							});
						}

						loadChart('topValuesChart', pieData);
						initTopValueGrid(formattedData);
						$scope.columnTopValueIsLoading = false;
					};

					var loadStatResult = function (result, jobStatus) {
						$scope.statJobDetails.update({
							name: gettextCatalog.getString('Synthèse'),
							pushDate: result.push_date,
							endExecutionDate: result.exec_end_date,
							duration: result.time_exec,
						});
						$scope.statJobDetails.setFromCache(result.is_cached);
						let data = result.data;
						$scope.stats.totalCount = data[0][0];
						$scope.stats.nonNullCount = data[0][1];
						$scope.stats.nullCount =
							$scope.stats.totalCount - $scope.stats.nonNullCount;
						$scope.stats.real_minimum = data[0][2];
						$scope.stats.real_maximum = data[0][3];
						$scope.stats.minimum =
							vm.widgetData.columnInfoType == 'date'
								? $rootScope.getDateTimeWithPattern(data[0][2])
								: vm.widgetData.columnInfoType == 'string'
								? setMaxStringLength(data[0][2], 25)
								: formatStatValue(data[0][2]);
						$scope.stats.maximum =
							vm.widgetData.columnInfoType == 'date'
								? $rootScope.getDateTimeWithPattern(data[0][3])
								: vm.widgetData.columnInfoType == 'string'
								? setMaxStringLength(data[0][3], 25)
								: formatStatValue(data[0][3]);

						$scope.stats.average = formatStatValue(data[0][4]);
						$scope.stats.median = formatStatValue(data[0][5]);
						$scope.stats.sum = formatStatValue(data[0][6]);
						$scope.stats.variance = formatStatValue(data[0][7]);
						$scope.stats.ecarttype = formatStatValue(data[0][8]);
						$scope.stats.quartile1 = formatStatValue(data[0][9]);
						$scope.stats.quartile2 = formatStatValue(data[0][10]);
						$scope.stats.quartile3 = formatStatValue(data[0][11]);
						$scope.columnStatIsLoading = false;
						$scope.valueCountChartOptions = {
							bindingOptions: { value: $scope.stats.nonNullCount },
							geometry: { orientation: 'horizontal' },
							scale: {
								label: { visible: false },
								startValue: 0,
								endValue: $scope.stats.totalCount,
								customTicks: [
									$scope.stats.nonNullCount,
									$scope.stats.totalCount,
								],
							},
							rangeContainer: {
								ranges: [
									{
										startValue: 0,
										endValue: $scope.stats.nonNullCount,
										color: 'green',
									},
									{
										startValue: $scope.stats.nonNullCount,
										endValue: $scope.stats.totalCount,
										color: 'red',
									},
								],
							},
							valueIndicator: { type: 'none' },
						};
					};

					var loadCountDistinctResult = function (result, jobStatus) {
						$scope.countDistinctJobDetails.update({
							name: gettextCatalog.getString('Synthèse'),
							pushDate: result.push_date,
							endExecutionDate: result.exec_end_date,
							duration: result.time_exec,
						});
						$scope.countDistinctJobDetails.setFromCache(result.is_cached);
						let data = result.data;
						$scope.stats.countDistinct = data[0][0];
						$scope.countDistinctIsLoading = false;
					};

					var loadCountDistinctApproxResult = function (result, jobStatus) {
						$scope.countDistinctApproxJobDetails.update({
							name: gettextCatalog.getString('Synthèse'),
							pushDate: result.push_date,
							endExecutionDate: result.exec_end_date,
							duration: result.time_exec,
						});
						$scope.countDistinctApproxJobDetails.setFromCache(result.is_cached);
						let data = result.data;
						$scope.stats.countDistinctApprox = data[0][0];
						$scope.countDistinctApproxIsLoading = false;
					};

					var loadChart = function (chart, datas) {
						var chartOptions = loadPieChart(datas, '', false, false);
						$('#' + chart)
							.dxPieChart(chartOptions)
							.dxPieChart('instance');
						if ($('#' + chart).dxPieChart('instance')) {
							$('#' + chart)
								.dxPieChart('instance')
								.render();
						}
					};

					var initTopValueGrid = function (data) {
						$scope.topValueGridOptions = getDefaultDxGridConfig(
							PAGINATIONS_SIZES,
							PAGINATIONS_SIZE
						);
						$scope.topValueGridOptions.filterPanel = { visible: false };
						$scope.topValueGridOptions.paging = { enabled: false };
						$scope.topValueGridOptions.pager = { visible: false };
						$scope.topValueGridOptions.columns = new Array();
						$scope.topValueGridOptions.columns.push({
							dataField: 'valeur',
							caption: gettextCatalog.getString('Valeurs'),
							allowHeaderFiltering: false,
							allowFiltering: false,
							allowSorting: false,
							width: 300,
						});
						$scope.topValueGridOptions.columns.push({
							dataField: 'count',
							width: 150,
							caption: gettextCatalog.getString('Compte'),
							cellClass: 'text-right',
							allowHeaderFiltering: false,
							allowFiltering: false,
							allowSorting: false,
						});
						$scope.topValueGridOptions.dataSource = data;
					};

					$scope.refreshTopValueChart = function () {
						$('#topValuesChart').dxPieChart('instance').refresh();
					};

					$scope.init = function () {
						if (vm.widgetData.columnInfoId) {
							init();
						} else if (vm.widgetData.caracInfoId) {
							initCarac();
						}
					};

					var initCarac = function () {
						TypeEntiteService.getEntiteTypeGrammar(
							vm.widgetData.caracInfoEntiteId
						).then(function (response) {
							let columns = response.data.columns;
							let column = _.find(columns, function (item) {
								return item.uca_id == vm.widgetData.caracInfoId;
							});
							if (column) {
								vm.widgetData.columnInfoType = column.type;
								vm.widgetData.columnInfoId = column.uuid;
								vm.widgetData.columnInfoIsList = column.list;
								vm.widgetData.columnInfoLabel = column.column_alias;
								vm.widgetData.columnInfoOrigin =
									vm.widgetData.caracInfoEntiteLabel;
								vm.widgetData.columnInfoGrammar = {
									columns: columns,
									steps: [],
								};
								init();
							}
						});
					};

					var init = function () {
						$scope.columnStatIsLoading = false;
						$scope.columnTopValueIsLoading = false;
						$scope.columnHistogramIsLoading = false;
						$scope.countDistinctIsLoading = false;
						$scope.countDistinctApproxIsLoading = false;

						delete $scope.histogramChartOptions;

						$scope.columnType = gettextCatalog.getString(
							getTypeLabel(vm.widgetData.columnInfoType)
						);

						$scope.isDecimal = vm.widgetData.columnInfoType === 'decimal';

						$scope.stats = {};

						delete $scope.valueCountChartOptions;

						delete $scope.topValueGridOptions;

						$scope.topValueNumber = $scope.topValueNumbers[0];

						loadChart('topValuesChart', []);

						$scope.columnStatIsLoading = false;

						$scope.topValueOrder = 'DESC';
						delete $scope.histogramMessage;
						$scope.enableHistogram =
							!vm.widgetData.columnInfoIsList &&
							['integer', 'big_integer', 'decimal'].indexOf(
								vm.widgetData.columnInfoType
							) > -1;
						$scope.enableNumericStat =
							!vm.widgetData.columnInfoIsList &&
							['integer', 'big_integer', 'decimal'].indexOf(
								vm.widgetData.columnInfoType
							) > -1;
						$scope.enableMinMaxStat =
							!vm.widgetData.columnInfoIsList &&
							['integer', 'big_integer', 'decimal', 'string', 'date'].indexOf(
								vm.widgetData.columnInfoType
							) > -1;
						$scope.startStats();
					};

					$scope.closeMe = function () {
						vm.widgetData.showColumnStat = false;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=' },
				controller,
				'./src/components/directives/columnStat/columnStat.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.widgetData.showColumnStat = false;
						delete scope.statJobDetails;
						delete scope.histogramJobDetails;
						delete scope.topValueJobDetails;
						delete scope.countDistinctJobDetails;
						delete scope.countDistinctApproxJobDetails;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showColumnStat;
						},
						function () {
							scope.statJobDetails = {
								rerunMethod: function () {
									scope.startStats(true);
								},
							};
							scope.statJobDetailsId = generateUuid('_');
							scope.countDistinctJobDetails = {
								rerunMethod: function () {
									scope.startCountDistinct(true);
								},
							};
							scope.countDistinctJobDetailsId = generateUuid('_');
							scope.countDistinctApproxJobDetails = {
								rerunMethod: function () {
									scope.startCountDistinctApprox(true);
								},
							};
							scope.countDistinctApproxJobDetailsId = generateUuid('_');
							scope.histogramJobDetails = {
								rerunMethod: function () {
									scope.startHistogram(true);
								},
							};
							scope.histogramJobDetailsId = generateUuid('_');
							scope.topValueJobDetails = {
								rerunMethod: function () {
									scope.startTopValues(true);
								},
							};
							scope.topValueJobDetailsId = generateUuid('_');
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
