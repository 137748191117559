(function () {
	'use strict';
	angular.module('dcApp').factory('DataCatalogAdapter', [
		'$rootScope',
		function ($rootScope) {
			return {
				generateDataCatalogList: generateDataCatalogList,
				generateDataCatalogItem: generateCatalogItemDetails,
				generateDataCatalogItemCount: generateCatalogItemCount,
			};

			function generateDataCatalogList(data) {
				var tags = [];
				return {
					data: data.map(function (catalogItem) {
						let ele = generateCatalogItem(catalogItem);
						tags = tags.concat(ele.tagAsArray);
						return ele;
					}),
					tags: _.sortBy(tags, function (tag) {
						return tag ? tag.toLowerCase() :  tag;
					}),
				};
			}

			function generateCatalogItem(catalogItem) {
				return {
					id: catalogItem.id,
					type: catalogItem.type,
					label: catalogItem.label,
					description: catalogItem.description,
					update_date: catalogItem.update_date,
					tags: catalogItem.tags,
					project_label: catalogItem.project_label,
					tagAsArray: $rootScope.mapTags(catalogItem.tags),
				};
			}

			function generateCatalogItemDetails(catalogItem) {
				let item = generateCatalogItem(catalogItem);
				item.licence = catalogItem.licence;
				item.update_by = catalogItem.update_by;
				item.referencing_date = catalogItem.referencing_date;
				item.referenced_by = catalogItem.referenced_by;
				item.icon_id = catalogItem.icon_id;
				return item;
			}

			function generateCatalogItemCount(catalogItemCount) {
				return {
					columns: catalogItemCount.columns,
					rows: catalogItemCount.rows,
				};
			}
		},
	]);
})();
