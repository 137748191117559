(function () {
	'use strict';

	angular.module('dcApp').directive('pictocreation', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'PictogrammeService',
				'toaster',
				'gettextCatalog',
				function ($scope, PictogrammeService, toaster, gettextCatalog) {
					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopSaveSuccess = gettextCatalog.getString(
						'Création du Pictogramme effectuée'
					);

					var vm = this;
					vm.createPictogramme = createPictogramme;
					vm.closeModal = closeModal;
					vm.cancel = cancel;
					vm.pictoToEdit = {};

					function createPictogramme() {
						PictogrammeService.createPictogramme(vm.pictoToEdit).then(
							function () {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopSaveSuccess
								);
								vm.data.updateAction();
								vm.closeModal();
							}
						);
					}

					function closeModal() {
						angular.forEach(
							angular.element("input[type='file']"),
							function (inputElem) {
								angular.element(inputElem).val(null);
							}
						);
						vm.data.showPictoModal = false;
					}

					function cancel() {
						vm.closeModal();
					}
				},
			];

			return {
				restrict: 'E',
				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/pictoCreation/pictoCreation.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target.id == element[0].id) {
							scope.vm.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showPictoModal;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.vm.pictoToEdit = {};
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
