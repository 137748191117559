(function () {
	'use strict';

	angular.module('dcApp').directive('selectMediatype', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;
					$scope.select = function (type) {
						$scope.vm.data.action(type);
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/selectMediaType/selectMediaType.html',
				function postLink(scope, element, attrs) {}
			);
		},
	]);
})();
