(function () {
	'use strict';

	angular.module('dcApp').directive('sendMail', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'ProjectMessageService',
				'toaster',
				'gettextCatalog',
				function ($scope, ProjectMessageService, toaster, gettextCatalog) {
					$scope.init = function () {
						initMessage();
					};

					$scope.sendMail = function () {
						const projectId = $scope.vm.data.projectId;
						const memberId = $scope.vm.data.member.member_id
							? $scope.vm.data.member.member_id
							: $scope.vm.data.member.memberId;
						ProjectMessageService.sendMessage(projectId, {
							recipient_user_id: memberId,
							subject: $scope.message.subject,
							message_body: $scope.message.body,
						}).then(function (response) {
							let toasterPopSuccess = gettextCatalog.getString('Succès');
							let toasterPopSaveSuccess = gettextCatalog.getString(
								'mail.send.notification'
							);
							toaster.pop('success', toasterPopSuccess, toasterPopSaveSuccess);
							$scope.vm.data.showSendMailModal = false;
						});
					};

					$scope.cancel = function () {
						initMessage();
					};

					function initMessage() {
						$scope.message = {
							subject: '',
							body: '',
						};
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/sendMail/sendMail.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.init();
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target === element[0]) {
							scope.vm.data.showSendMailModal = false;
						}
						scope.cancel();
					});

					scope.$watch(
						function () {
							return scope.vm.data.showSendMailModal;
						},
						function (isVisible) {
							scope.vm.element = element;
							if (isVisible) {
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
