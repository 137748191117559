(function () {
	'use strict';
	angular.module('dcApp').factory('MetadataService', [
		'$http',
		'$q',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		function ($http, $q, $rootScope, API_BASE_URL_BACKEND) {
			var MetadataService = {},
				endpoint = API_BASE_URL_BACKEND + 'metadata';

			MetadataService.getMetadataList = function () {
				return $http.get(endpoint).then(function (response) {
					return response;
				});
			};

			MetadataService.saveMetadata = function (metadata) {
				return $http.put(endpoint, metadata).then(function (response) {
					return response;
				});
			};

			MetadataService.getElementIdByMetadataId = function (metadataId, type) {
				return $http
					.get(endpoint + '/' + metadataId + '/' + type)
					.then(function (response) {
						return response;
					});
			};

			MetadataService.getMetadataTrace = function (metadataId) {
				return $http
					.get(endpoint + '/' + metadataId + '/trace')
					.then(function (response) {
						return response;
					});
			};

			MetadataService.getMatadataUniqueCode = function () {
				return $http.get(endpoint + '/generate_code').then(function (response) {
					return response;
				});
			};

			return MetadataService;
		},
	]);
})();
