(function () {
	'use strict';

	angular.module('dcApp').directive('importProjectDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'elem',
				function ($scope, $rootScope, elem) {
					var vm = this;
					vm.elem = elem;
					vm.init = function () {
						vm.data =
							vm.data && Object.keys(vm.data).length !== 0
								? vm.data
								: { contexts: [] };

						if (vm.contexts) {
							for (let i = 0; i < vm.contexts.length; i++) {
								let context = {
									context_id: vm.contexts[i].id,
									persistence: false,
									jobs: false,
								};
								vm.data.contexts.push(context);
							}
						}
					};

					vm.checkDateValidity = function (type) {
						if (type === 'effect' && vm.data.importedEffectDate) {
							var end = moment(vm.data.importedEffectDate).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
						if (type === 'expire' && vm.data.importExpirationDate) {
							var end = moment(vm.data.importExpirationDate).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
					};
				},
			];
			return buildDirectiveReturnObject(
				{
					data: '=',
					projectInfos: '=',
					contexts: '=',
				},
				controller,
				'./src/components/directives/importProject/importProjectDetails.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();

					initElementForDirective(element, attrs, function () {
						$ctrl.data = undefined;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return $ctrl.data;
						},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
