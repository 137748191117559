(function () {
	'use strict';

	angular.module('dcApp').controller('PictogrammeController', [
		'PictogrammeService',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$interval',
		'permissionChecker',
		'gettextCatalog',
		function (
			PictogrammeService,
			$scope,
			$state,
			$rootScope,
			toaster,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$interval,
			permissionChecker,
			gettextCatalog
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Pictogrammes');

			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.type = gettextCatalog.getString('Type');
			dataGirdColumnName.picture = gettextCatalog.getString('Image');
			dataGirdColumnName.Pictogramme = gettextCatalog.getString('Pictogramme');
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.code = gettextCatalog.getString('Code');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeletePicto = gettextCatalog.getString(
				'Suppression du Pictogramme effectuée'
			);
			let toasterPopSavePictoLink = gettextCatalog.getString(
				'Lien avec le Pictogramme effectué'
			);
			let toasterPopCreationPicto = gettextCatalog.getString(
				'Création du Pictogramme effectuée'
			);

			let translateELeListTitle = {};
			translateELeListTitle.icon = gettextCatalog.getString(
				'Pictogramme par défaut'
			);

			$scope.entiteTypes = [];
			$scope.pictogrammes = [];

			$scope.isPictogrammesView = true;

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';

			$scope.pictogrammeObj = {
				label: 'Attributs',
				allowedTypes: ['attribut'],
				//max: 4,
				list: [
					{ name: '1111', type: 'attribut' },
					{ name: '2222', type: 'attribut' },
				],
			};

			$scope.elementObj = {
				label: 'Arguments',
				allowedTypes: ['argument', 'attribut'],
				list: [
					{ name: 'aaaa', type: 'attribut' },
					{ name: 'bbbb', type: 'attribut' },
				],
			};

			$scope.valueObj = {
				label: 'Valeurs',
				allowedTypes: ['value', 'attribut'],
				values: [],
			};

			$scope.serieObj = {
				label: 'Séries',
				allowedTypes: ['serie', 'attribut'],
				series: [],
			};

			$scope.showEditModal = function (id) {
				if (id != undefined) {
					// $scope.objToEdit = obj;
				}
				if ($scope.pictoToEdit != undefined) {
					$scope.pictoToEdit.lib = '';
					$scope.pictoToEdit.file = null;
				}

				$('#editModal').modal('show');
			};

			$scope.showPictogrammesModal = function (row) {
				if (row != undefined && row.id != undefined) {
					$scope.lastMetadataId = row.id;
					$scope.lastType = row.type;
					$scope.getGridDataPictogrammes(row.id);
				}

				$('#listPictogrammesModal').modal('show');
			};

			$scope.showHdhTypesModal = function (row) {
				if (row != undefined && row.id_icon != undefined) {
					var obj = {};
					obj.icon = {};
					obj.icon.id = row.id_icon;
					obj.image = row.image;
					$scope.lastIconId = row.id_icon;
					$scope.getGridDataHdhTypes(obj);
				}
			};

			$scope.showDeleteModal = function (row) {
				$scope.objToDelete = row;
				$('#deleteModal').modal('show');
			};

			$scope.deletePictogramme = function () {
				PictogrammeService.deletePictogramme($scope.objToDelete.id_icon).then(
					function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopDeletePicto);
						$('#deleteModal').modal('hide');
						$scope.getGridDataPictogrammesElements();
					}
				);
			};

			$scope.saveAttachedPictogrammes = function () {
				var indice = -1;
				for (
					var i = 0;
					i < $scope.pictoPopupDataGridOptions.dataSource.length;
					i++
				) {
					if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
						$scope.pictogrammes[i].selected = true;
						indice = i;
					} else {
						$scope.pictogrammes[i].selected = false;
					}
				}

				var elementIcon = {};
				elementIcon.metadata = {};
				elementIcon.metadata.id = $scope.lastMetadataId;
				elementIcon.icon = {};
				if (indice != -1) {
					elementIcon.icon.id = $scope.pictogrammes[indice].id;
				} else {
					elementIcon.icon.id = -1;
				}
				PictogrammeService.saveAttachedPictoToElement(elementIcon).then(
					function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopSavePictoLink);
						$scope.getGridDataEntiteTypesPictogrammes();
					}
				);
				$('#listPictogrammesModal').modal('hide');
			};

			$scope.saveAttachedHdhElements = function () {
				$scope.iconElements = {};
				$scope.iconElements.metadatas = [];
				$scope.iconElements.icon = {};
				$scope.iconElements.icon.id = $scope.lastIconId;
				for (
					var i = 0;
					i < $scope.hdhTypesPopupDataGridOptions.dataSource.length;
					i++
				) {
					if ($scope.hdhTypesPopupDataGridOptions.dataSource[i].selected) {
						if (
							$scope.hdhTypesPopupDataGridOptions.dataSource[i].type ==
							'METADATA'
						) {
							$scope.iconElements.metadatas.push(
								$scope.listIconElements[i].metadata
							);
							$scope.iconElements.metadatas[
								$scope.iconElements.metadatas.length - 1
							].selected = true;
						}
					} else {
						if (
							$scope.hdhTypesPopupDataGridOptions.dataSource[i].type ==
							'METADATA'
						) {
							$scope.iconElements.metadatas.push(
								$scope.listIconElements[i].metadata
							);
							$scope.iconElements.metadatas[
								$scope.iconElements.metadatas.length - 1
							].selected = false;
						}
					}
				}

				PictogrammeService.saveAttachedElementsToPicto(
					$scope.iconElements
				).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopSavePictoLink);
					$scope.getGridDataPictogrammesElements();
				});
				$('#listHdhTypesModal').modal('hide');
			};

			$scope.cancelModal = function (idModal) {
				$('#' + idModal).modal('hide');
			};

			$scope.hdhTypesPopupDataGridOptions = {
				dataSource: $scope.hdhTypesPopupGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				selection: {
					mode: 'single',
					showCheckBoxesMode: 'always',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.label,
						dataField: 'lib',
						width: '70%',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.type,
						dataField: 'type',
						width: '30%',
					},
				],
				onInitialized: function (e) {
					$scope.gridHdhTypesInstance = e.component;
				},
				onSelectionChanged: function (selectedItems) {
					$scope.selectedIconPopu = selectedItems.selectedRowsData;
					if ($scope.selectedIconPopu && $scope.selectedIconPopu[0]) {
						for (var t in $scope.selectedIconPopu) {
							for (var i in $scope.hdhTypesPopupDataGridOptions.dataSource) {
								if (
									$scope.hdhTypesPopupDataGridOptions.dataSource[i].id ===
									$scope.selectedIconPopu[t].id
								) {
									$scope.hdhTypesPopupDataGridOptions.dataSource[
										i
									].selected = true;
									break;
								}
							}
						}
					}
				},
				onContentReady: function (e) {
					if (
						$scope.gridHdhTypesInstance &&
						$scope.gridHdhTypesInstance.selectRows
					) {
						var selectedIndexs = [];
						for (
							var i = 0;
							i < $scope.hdhTypesPopupDataGridOptions.dataSource.length;
							i++
						) {
							if ($scope.hdhTypesPopupDataGridOptions.dataSource[i].selected) {
								selectedIndexs.push(i);
							}
						}
						if (selectedIndexs.length > 0) {
							$scope.gridHdhTypesInstance.selectRowsByIndexes(selectedIndexs);
						}
					}
				},
			};

			$scope.getGridDataHdhTypes = function (obj) {
				$scope.showHdhTypesGrid = false;
				$scope.icon = obj.image;
				PictogrammeService.getHdhTypesElements(obj).then(function (response) {
					const content = response.data;
					$scope.listIconElements = [];
					if (content !== undefined) {
						$scope.hdhTypes = [];
						$scope.listIconElements = content;
						for (var r in content) {
							$scope.hdhTypes.push({
								id: content[r].id,
								selected: content[r].selected,
							});

							if (content[r].metadata != undefined) {
								$scope.hdhTypes[$scope.hdhTypes.length - 1].type = 'METADATA';
								$scope.hdhTypes[$scope.hdhTypes.length - 1].id =
									$scope.hdhTypes[$scope.hdhTypes.length - 1].id != undefined
										? $scope.hdhTypes[$scope.hdhTypes.length - 1].id
										: content[r].metadata.id;
								$scope.hdhTypes[$scope.hdhTypes.length - 1].lib =
									content[r].metadata.label;
								$scope.hdhTypes[$scope.hdhTypes.length - 1].type =
									getTypeLabelFromHdhType(content[r].metadata.hdh_type);
							}
						}
						$scope.hdhTypesPopupGridData = $scope.hdhTypes;
						$scope.hdhTypesPopupDataGridOptions.dataSource =
							$scope.hdhTypesPopupGridData;
						$scope.showHdhTypesGrid = true;
						$('#listHdhTypesModal').modal('show');
					}
				});
			};

			$scope.pictoPopupDataGridOptions = {
				dataSource: $scope.pictosPopupGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				selection: {
					mode: 'single',
					showCheckBoxesMode: 'always',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.label,
						dataField: 'lib',
						width: '70%',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.picture,
						alignment: 'center',
						cellTemplate: 'imageAndLibPopUp',
						width: '30%',
					},
				],
				onInitialized: function (e) {
					$scope.gridPictoPopupInstance = e.component;
				},
				onSelectionChanged: function (selectedItems) {
					$scope.selectedIconPopu = selectedItems.selectedRowsData;
					if ($scope.selectedIconPopu && $scope.selectedIconPopu[0]) {
						for (let i in $scope.pictoPopupDataGridOptions.dataSource) {
							if (
								$scope.pictoPopupDataGridOptions.dataSource[i].id ===
								$scope.selectedIconPopu[0].id
							) {
								$scope.pictoPopupDataGridOptions.dataSource[i].selected = true;
							} else {
								$scope.pictoPopupDataGridOptions.dataSource[i].selected = false;
							}
						}
					} else {
						for (let i in $scope.pictoPopupDataGridOptions.dataSource) {
							$scope.pictoPopupDataGridOptions.dataSource[i].selected = false;
						}
					}
				},
				onContentReady: function (e) {
					if (
						$scope.gridPictoPopupInstance &&
						$scope.gridPictoPopupInstance.selectRowsByIndexes
					) {
						let selectedItems = [];
						for (
							let i = 0;
							i < $scope.pictoPopupDataGridOptions.dataSource.length;
							i++
						) {
							if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
								selectedItems.push(i);
							}
						}
						$scope.gridPictoPopupInstance.selectRowsByIndexes(selectedItems);
					}
				},
			};

			$scope.getGridDataPictogrammes = function (metadataId) {
				$scope.showPictoGridPopUp = false;
				PictogrammeService.getPictogrammes(metadataId).then(function (
					response
				) {
					const content = response.data;
					if (content !== undefined) {
						$scope.pictogrammes = [];
						for (var r in content) {
							$scope.pictogrammes.push({
								id: content[r].id,
								lib: content[r].lib,
								selected: content[r].selected,
							});
						}
						$scope.pictosPopupGridData = $scope.pictogrammes;
						$scope.pictoPopupDataGridOptions.dataSource =
							$scope.pictosPopupGridData;
						$scope.showPictoGridPopUp = true;
					}
				});
			};

			$scope.createPictogramme = function () {
				PictogrammeService.createPictogramme($scope.pictoToEdit).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopCreationPicto);
					$scope.getGridDataPictogrammesElements();
					angular.forEach(
						angular.element("input[type='file']"),
						function (inputElem) {
							angular.element(inputElem).val(null);
						}
					);
					$('#editModal').modal('hide');
				});
			};

			$scope.getGridDataPictogrammesElements = function () {
				$scope.showElementsGrid = false;
				PictogrammeService.getPictogrammesElements().then(function (response) {
					const content = response.data;
					if (content !== undefined) {
						$scope.formattedIconElements = [];
						for (var r in content) {
							$scope.formattedIconElements.push({
								id_icon: content[r].icon.id,
								lib_icon: content[r].icon.lib,
								uri: content[r].icon.uri,
								image:
									'data:' +
									content[r].icon.type +
									';base64,' +
									content[r].icon.file,
								elements: _.map(content[r].metadatas, function (el) {
									el.lib_element = el.label;
									return el;
								}),
							});
						}

						$scope.iconElementsGridData = $scope.formattedIconElements;
						$scope.iconElementDataGridOptions.dataSource =
							$scope.iconElementsGridData;
						$scope.showElementsGrid = true;
					}
				});
			};

			$scope.iconElementDataGridOptions = {
				dataSource: $scope.iconElementsGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: false,
				},
				columns: [
					{
						caption: dataGirdColumnName.Pictogramme,
						cellTemplate: 'imageAndLib',
						dataField: 'lib_icon',
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actionsElements',
						width: 120,
						allowReordering: false,
					},
				],
				masterDetail: {
					enabled: true,
					template: function (container, options) {
						var currentIcon = options.data;
						$('<div>')
							.addClass('master-detail-caption bottom-space-10')
							.text('Types:')
							.appendTo(container);

						$('<div>')
							.dxDataGrid({
								headerFilter: {
									visible: true,
									applyFilter: 'auto',
								},
								filterRow: {
									visible: true,
									applyFilter: 'auto',
								},
								columnAutoWidth: true,
								showBorders: true,
								showColumnLines: true,
								showRowLines: true,
								columns: [
									{
										caption: dataGirdColumnName.code,
										dataField: 'code',
										dataType: 'string',
										width: '40%',
									},
									{
										caption: dataGirdColumnName.label,
										dataField: 'lib_element',
										dataType: 'string',
										width: '60%',
									},
								],
								dataSource: currentIcon.elements,
							})
							.appendTo(container);
					},
				},
				onInitialized: function (e) {
					$scope.gridIconElementsInstance = e.component;
				},
				onCellPrepared: function (e) {
					if (e.rowType === 'data' && e.column.command === 'expand') {
						if (!e.data || !e.data.elements || e.data.elements.length === 0) {
							e.cellElement.removeClass('dx-datagrid-expand');
							e.cellElement.empty();
						}
					}
				},
				onRowPrepared: function (e) {
					if (e.rowType === 'data') {
						e.rowElement.css({ height: 55 });
					}
				},
			};

			$scope.getGridDataEntiteTypesPictogrammes = function () {
				$scope.showPictoGrid = false;
				PictogrammeService.getElementsPictogrammes().then(function (content) {
					$scope.showPictoGrid = false;
					if (content.data !== undefined) {
						$scope.elementIconList = [];
						for (var r in content.data) {
							$scope.elementIconList.push({
								switch_off: content.data[r].switch_off ? 'Oui' : 'Non',
							});

							if (content.data[r].icon != undefined) {
								$scope.elementIconList[
									$scope.elementIconList.length - 1
								].id_icon = content.data[r].icon.id;
								$scope.elementIconList[
									$scope.elementIconList.length - 1
								].lib_icon = content.data[r].icon.lib;
							} else {
								$scope.elementIconList[
									$scope.elementIconList.length - 1
								].id_icon = -1;
								$scope.elementIconList[
									$scope.elementIconList.length - 1
								].lib_icon = translateELeListTitle.icon;
							}

							if (content.data[r].metadata != undefined) {
								$scope.elementIconList[$scope.elementIconList.length - 1].id =
									content.data[r].metadata.id;
								$scope.elementIconList[$scope.elementIconList.length - 1].code =
									content.data[r].metadata.code;
								$scope.elementIconList[$scope.elementIconList.length - 1].lib =
									content.data[r].metadata.label;
								$scope.elementIconList[$scope.elementIconList.length - 1].type =
									getTypeLabelFromHdhType(content.data[r].metadata.hdh_type);
							}
						}
						$scope.pictosGridData = $scope.elementIconList;
						$scope.pictoDataGridOptions.dataSource = $scope.pictosGridData;
						$scope.showPictoGrid = true;
					}
				});
			};

			$scope.pictoDataGridOptions = {
				dataSource: $scope.pictosGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						dataField: 'code',
						caption: dataGirdColumnName.code,
					},
					{
						caption: dataGirdColumnName.label,
						dataField: 'lib',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.type,
						dataField: 'type',
					},
					{
						caption: dataGirdColumnName.Pictogramme,
						cellTemplate: 'imageAndLib',
						dataField: 'type',
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actionsPicto',
						width: 70,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
			};

			function init() {
				$scope.active = true;
				$scope.getGridDataEntiteTypesPictogrammes();
				$scope.getGridDataPictogrammesElements();
			}

			permissionChecker.checkGlobalPermission(
				'icons',
				function () {
					init();
				},
				function () {
					$scope.icons_unauthorised = true;
				}
			);

			permissionChecker.checkGlobalPermission(
				'icons-create',
				function () {
					$scope.create_authorised = true;
				},
				function () {}
			);
		},
	]);
})();
