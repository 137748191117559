function setSelectedEntiteTypeCarac(
	carac,
	index,
	caracIsList,
	selectedFileHeader,
	mappings,
	selectedEntiteTypeCarac
) {
	if (carac != undefined && index != undefined) {
		carac.list = caracIsList[index] != undefined ? caracIsList[index] : false;
	}
	if (carac != null && carac.id != undefined && carac.id in mappings) {
		selectedFileHeader[index] = mappings[carac.id];
	}
	if (index != null) {
		selectedEntiteTypeCarac[index] = carac;
	}
}

function actionsAfterEntityExpress(uetId, vm) {
	vm.reloadDataConfirmation.entiteTypeId = uetId;
	vm.reloadDataConfirmation.gotoEntitedatablockEntiteId = uetId;
	delete vm.reloadDataConfirmation.gotoEntitedatablockDataBlockId;
	vm.reloadDataConfirmation.showReloadConfirmattionOnOK = function (
		dataBlockId
	) {
		vm.reloadDataConfirmation.gotoEntitedatablockDataBlockId = dataBlockId;
		vm.reloadDataConfirmation.showGotoEntitedatablock = true;
	};

	vm.reloadDataConfirmation.showReloadConfirmattionOnNotOK = function () {
		vm.reloadDataConfirmation.showGotoEntitedatablock = true;
	};

	vm.reloadDataConfirmation.showReloadConfirmattion = true;

	if (angular.isFunction(vm.updateAction)) {
		vm.updateAction(uetId);
	}
}

function setSelectedType(carac, defaultTypes) {
	for (let o in defaultTypes) {
		if (
			carac &&
			carac.entite_type &&
			carac.entite_type.code == defaultTypes[o].code
		) {
			carac.entite_type = defaultTypes[o];
			break;
		}
	}
}

function setSelectedPattern(carac, datePatterns, selectedFileHeaderPattern) {
	for (let o in datePatterns) {
		if (selectedFileHeaderPattern[carac.code] == datePatterns[o].value) {
			carac.date_pattern = datePatterns[o];
			break;
		}
	}
}

function setSelectedConnector(connector, connexions, depot) {
	for (let v in connexions) {
		if (connector && connector.id == connexions[v].id) {
			depot.connector = connexions[v];
			break;
		}
	}
}

function getRootType(type) {
	return type != undefined ? type.replace(/\((.+?)\)/gi, '') : type;
}

function getListRealType(column, type, caracIsList) {
	if (
		type &&
		type.toLowerCase() &&
		type.toLowerCase().startsWith('arraytype')
	) {
		caracIsList[column] = true;
		let typeB = type.replace('ArrayType(', '').replace(')', '');
		let typeAsArray = typeB.split(',');
		return typeAsArray[0];
	} else {
		caracIsList[column] = false;
		return getRootType(type);
	}
}

function mapSimpleTypes(defaultTypes, typeMapper, simpleTypes) {
	angular.forEach(defaultTypes, function (type) {
		switch (type.code) {
			case 'SIMPLE_TYPES_INTEGER_ITEM':
				typeMapper['IntegerType'] = type.id;
				typeMapper['ShortType'] = type.id;
				break;
			case 'SIMPLE_TYPES_BIG_INTEGER_ITEM':
				typeMapper['LongType'] = type.id;
				break;
			case 'SIMPLE_TYPES_BOOLEAN_ITEM':
				typeMapper['BooleanType'] = type.id;
				break;
			case 'SIMPLE_TYPES_DATE_ITEM':
				typeMapper['TimestampType'] = type.id;
				typeMapper['DateType'] = type.id;
				break;
			case 'SIMPLE_TYPES_DECIMAL_ITEM':
				typeMapper['DoubleType'] = type.id;
				typeMapper['FloatType'] = type.id;
				typeMapper['DecimalType(22,0)'] = type.id;
				typeMapper['DecimalType(17,0)'] = type.id;
				typeMapper['DecimalType'] = type.id;
				break;
			case 'SIMPLE_TYPES_STRING_ITEM':
				typeMapper['StringType'] = type.id;
				break;
			case 'TYPES_BINARY_ITEM':
				typeMapper['BinaryType'] = type.id;
				typeMapper['ByteType'] = type.id;
				break;
			default:
				simpleTypes[type.id] = type;
		}
		simpleTypes[type.id] = type;
	});
}
