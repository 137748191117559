(function () {
	'use strict';

	angular.module('dcApp').directive('dbFilter', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'TypeEntiteService',
				'toaster',
				'$sce',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					TypeEntiteService,
					toaster,
					$sce,
					gettextCatalog
				) {
					var vm = this;
					$scope.data = {};

					let translateMap = {
						true: gettextCatalog.getString('bool.true'),
						false: gettextCatalog.getString('bool.false'),
					};

					$scope.getDateWithPattern = function (date) {
						return $rootScope.getDateWithPattern(date);
					};

					$scope.getHtmlIconByType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList));
						}
					};

					$scope.updateGrammar = function () {
						if ($scope.qBuilderInstance) {
							var r = $scope.qBuilderInstance.getRulesForText();
							var str = new StringBuffer();
							getTextFromRule(str, r.queries, $scope.availableColumns);
							$scope.selectedWidget.rules_description = str.toString();
						}
					};

					$scope.addColumnToRules = function (col) {
						$scope.addRule($scope.qBuilderInstance, $scope.rulesRoot, col);
					};

					$scope.getColumnFromId = function (uuid) {
						for (var c in $scope.availableColumns) {
							if ($scope.availableColumns[c].uuid == uuid) {
								return $scope.availableColumns[c];
							}
						}
					};

					$scope.openSelectTopValue = function (
						rule_id,
						valueIndex,
						valueInputName
					) {
						$scope.selectedRule = getRuleById(rule_id);
						$scope.selectedValueInputName = valueInputName;
						vm.widgetData.defautValue = $scope.selectedRule.value;

						vm.widgetData.updateaction_l2 = $scope.saveTopCaracValue;
						let steps = angular.copy(vm.data.currentSteps);
						delete steps[steps.length - 1].data_operation;
						delete steps[steps.length - 1].filter;
						delete steps[steps.length - 1].agregation;
						delete steps[steps.length - 1].output;

						vm.widgetData.topCaracValueCurrentGrammar = {
							columns: vm.data.datablock.grammar.columns,
							steps: steps,
						};
						for (var c in $scope.availableColumns) {
							if (
								$scope.selectedRule.column_alias ==
								'COL_' + $scope.availableColumns[c].uuid
							) {
								vm.widgetData.topCaracValueColumn =
									$scope.availableColumns[c].uuid;
								vm.widgetData.topCaracValueColumnType =
									$scope.availableColumns[c].type;
								break;
							}
						}

						$scope.$apply();
						vm.widgetData.showTopCaracValue = true;
						$scope.$apply();
					};

					$scope.saveTopCaracValue = function (value) {
						$scope.selectedRule.value = value;
						$scope.qBuilderInstance.setRuleInputValue(
							$scope.selectedValueInputName,
							value
						);
						$scope.updateGrammar();
					};

					$scope.openSelectColumn = function (
						rule_id,
						valueIndex,
						valueInputName
					) {
						$scope.selectedRule = getRuleById(rule_id);
						$scope.selectedValueIndex = valueIndex;
						$scope.selectedValueInputName = valueInputName;
						vm.widgetData.columns = $scope.availableColumns;
						if ($scope.selectedValueIndex == 0) {
							vm.widgetData.valueColumn = $scope.selectedRule.valueColumn;
						} else {
							vm.widgetData.valueColumn = $scope.selectedRule.valueColumn2;
						}
						vm.widgetData.selectedRule = $scope.selectedRule;
						vm.widgetData.updateaction_l2 = $scope.saveSelectColumn;
						$scope.$apply();
						vm.widgetData.showValueColumnFilter = true;
						$scope.$apply();
					};

					$scope.deleteSelectedColumn = function (
						rule_id,
						valueIndex,
						valueInputName
					) {
						$scope.selectedValueIndex = valueIndex;
						$scope.selectedValueInputName = valueInputName;
						$scope.selectedRule = getRuleById(rule_id);
						if ($scope.selectedValueIndex == 0) {
							delete $scope.selectedRule.valueColumn;
						} else {
							delete $scope.selectedRule.valueColumn2;
						}
						$scope.qBuilderInstance.resetRuleInputs(
							$scope.selectedValueInputName
						);
					};

					$scope.saveSelectColumn = function () {
						if (vm.widgetData.valueColumn.column == undefined) {
							$('#selectColumnModal').modal('hide');
							return;
						}
						$scope.selectedRule.value = '';
						if ($scope.selectedValueIndex == 0) {
							$scope.selectedRule.valueColumn =
								vm.widgetData.valueColumn.column;
						} else {
							$scope.selectedRule.valueColumn2 =
								vm.widgetData.valueColumn.column;
						}

						$scope.qBuilderInstance.setRuleInputs(
							$scope.selectedValueInputName,
							$scope.getColumnFromId(vm.widgetData.valueColumn.column).lib
						);
						$scope.updateGrammar();
						$('#selectColumnModal').modal('hide');
					};

					$scope.openSemanticContext = function (rule_id) {
						vm.widgetData.selectedRule = getRuleById_(rule_id);
						$scope.oldRuleSemanticContext = angular.copy(
							vm.widgetData.selectedRule.semantic_context
						);
						vm.widgetData.columns = $scope.selectedWidget.grammar
							? $scope.selectedWidget.grammar.columns
							: [];
						$scope.$apply();
						vm.widgetData.showSemanticContext = true;
						vm.widgetData.cancelaction_l2 = $scope.cancelSemanticContext;
						vm.widgetData.generateText = $scope.updateGrammar;
					};

					$scope.cancelSemanticContext = function () {
						vm.widgetData.selectedRule.semantic_context =
							$scope.oldRuleSemanticContext;
					};

					$scope.setRoot = function (builder, root) {
						$scope.rulesRoot = root;
						$scope.qBuilderInstance = builder;
					};

					$scope.resetColumns = function () {};

					$scope.deleteGroup = function (that, model) {
						that.deleteGroup(model);
						return;
					};

					$scope.setFilters = function (filters) {
						$scope.filters = filters;
					};

					$scope.addRule = function (that, mod, column) {
						$scope.selectedThat = that;
						$scope.selectedMod = mod;
						var model = $scope.selectedThat.addRule($scope.selectedMod);

						var selectedColumnFilter = getFilterFromColumn(
							column,
							translateMap
						);
						selectedColumnFilter.id = $scope.rule_id;
						model.id = $scope.rule_id;
						if (column.media) {
							selectedColumnFilter.is_unstructed_content = true;
						}
						$scope.selectedThat.addFilter(selectedColumnFilter);
						model.filter = selectedColumnFilter;
						model.list = selectedColumnFilter.list;
						model.path = column.path;
						model.is_unstructed_content =
							selectedColumnFilter.is_unstructed_content;
						model.parent_entite_type_id = column.parent_entite_type_id;
						model.column_alias = column.alias;
						$scope.nbRules++;
						model.id_ = $scope.rule_id;
						$scope.rule_id++;
					};

					$scope.resetRuleOperator = function (rule) {
						if (rule != undefined) {
							if (rule.rules == undefined || rule.rules.length == 0) {
								if (
									rule.operator != undefined &&
									rule.operator.code != undefined
								) {
									rule.operator = rule.operator.code;
								}
							} else {
								for (var r in rule.rules) {
									$scope.resetRuleOperator(rule.rules[r]);
								}
							}
						}
					};

					$scope.loadQueryBuilder = function () {
						$scope.displayJockers = false;
						if ($scope.qBuilderInstance) {
							$scope.qBuilderInstance.reset();
						}
						$scope.selectedWidget.rules_description = '';
						$scope.filters = [];
						$scope.nbRules = 0;
						$scope.rule_id = 1;

						var query_filters = [];
						var filter = {
							id: 'xxx',
							label: 'xxx',
							type: 'date',
							unique: true,
							description: 'xxx',
						};
						query_filters.push(filter);

						var query = angular.copy($scope.vm.data.filterRules);

						var rules = [];

						if (query.rules !== undefined && query.rules.length !== 0) {
							parseRules(rules, query);
						}

						for (let r in rules) {
							if ($scope.rule_id < rules[r].id_) {
								$scope.rule_id = rules[r].id_;
							}
						}

						for (let r in rules) {
							rules[r].value = parseValue(rules[r], $rootScope.getDefaultTimezone());

							let filter = getFilterFromRule(rules[r], translateMap);

							filter.is_unstructed_content =
								rules[r].is_unstructed_content ||
								rules[r].operator == 'semantic_context';
							query_filters.push(filter);

							filter.id = $scope.rule_id;
							rules[r].id = $scope.rule_id;
							$scope.rule_id++;
							$scope.nbRules++;
							if (!rules[r].id_) {
								rules[r].id_ = $scope.rule_id;
							}
						}

						if ($scope.qBuilderInstance) {
							$scope.qBuilderInstance.setFilters(query_filters);
						}

						if ($scope.qBuilderInstance) {
							$scope.qBuilderInstance.destroy();
						}

						$('#builder-plugins').queryBuilder({
							plugins: ['sortable', 'bt-tooltip-errors'],
							filters: query_filters,
							mainInstance: true,
							mainInstanceDivId: 'qBuilder',
							lang_code: $rootScope.currentLangCode
								? $rootScope.currentLangCode
								: 'fr',
							rootCondition: query ? query.condition : 'AND',
						});
						$('#builder-plugins').on(
							'afterCreateRuleInput.queryBuilder',
							function (e, rule) {
								if (rule.filter.type === 'date') {
									var $input = rule.$el.find(
										'.rule-value-container input[name*=_value_]:not([name^=sc])'
									);
									$input.on('dp.change', function () {
										$input.trigger('change');
									});
									// position of date picker
									setOnClickListenerToDateInput($input);
								}
							}
						);

						if (query && query.rules && query.rules.length !== 0) {
							$('#builder-plugins').queryBuilder('setRules', query);
						}
					};

					$scope.addGroup = function (that, mod) {
						that.addGroup(mod);
						return;
					};

					$scope.deleteRule = function (that, model) {
						$scope.nbRules--;
						that.deleteRule(model);
						$scope.updateGrammar();
					};

					$scope.openValueList = function (rule_id) {
						$scope.selectedRule = getRuleById_(rule_id);
						$scope.listValue = angular.copy($scope.selectedRule.value_list);

						vm.widgetData.columns = $scope.selectedWidget.grammar
							? $scope.selectedWidget.grammar.columns
							: [];
						vm.widgetData.listValue = $scope.listValue;
						vm.widgetData.selectedRule = $scope.selectedRule;
						vm.widgetData.updateaction_l2 = $scope.saveListValue;
						$scope.$apply();
						vm.widgetData.showListValueFilter = true;
						$scope.$apply();
					};

					$scope.saveListValue = function () {
						$scope.selectedRule.value_list = $scope.vm.widgetData.listValue;
						$scope.updateGrammar();
					};

					var getRuleById_ = function (id) {
						var rules = getAllShildrenRules($scope.rulesRoot.rules);

						for (var r in rules) {
							if (rules[r].id_ == id) {
								return rules[r];
							}
						}
					};

					var getRuleById = function (id) {
						var rules = getAllShildrenRules($scope.rulesRoot.rules);

						for (var r in rules) {
							if (rules[r].id == id) {
								return rules[r];
							}
						}
					};

					$scope.searchInColumns = undefined;
					$scope.filterColumnsForConditions = function () {
						if (
							$scope.searchInColumns == undefined ||
							$scope.searchInColumns == ''
						) {
							$scope.filteredColumnsForConditions = $scope.columnsForConditions;
						} else {
							$scope.filteredColumnsForConditions = _.filter(
								$scope.columnsForConditions,
								function (el) {
									return (
										el.lib
											.toLowerCase()
											.indexOf($scope.searchInColumns.toLowerCase()) > -1
									);
								}
							);
						}
					};

					vm.closeModal = function () {
						getScopeQB = $scope.getScopeQB;
						$(vm.element).modal('hide');
						vm.data.showFilter = false;

						if ($scope.qBuilderInstance) {
							$scope.qBuilderInstance.reset();
						}
						$scope.selectedWidget.rules_description = '';
					};

					$scope.deleteFilter = function () {
						vm.widgetData.showConfirmationDialog = true;
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Confirmer la suppression'
						);
						vm.widgetData.confirmationDialogMessage = gettextCatalog.getString(
							'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
						);
						vm.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = function () {
							confirmDeleteFilter();
						};
					};

					let successTra = gettextCatalog.getString('Succès');
					let successSaveTra = gettextCatalog.getString(
						'Enregistrement effectué'
					);
					var confirmDeleteFilter = function () {
						vm.data.saveFilterMethod(
							{ condition: 'AND', rules: [] },
							undefined
						);
						toaster.pop('success', successSaveTra);
						vm.closeModal();
					};

					$scope.save = function () {
						var result = $scope.qBuilderInstance.getRules();
						if (result.invalid !== true) {
							resetBetweenOperator(result.queries);
							result.queries.inactive = !vm.data.filterRules.active;
							vm.data.saveFilterMethod(result.queries, $scope.limit);
							toaster.pop('success', successTra, successSaveTra);
							vm.closeModal();
						}
					};

					$scope.closeConfirmation = function () {
						vm.closeModal();
					};

					$scope.getObjectLength = function (obj) {
						return Object.keys(obj).length;
					};

					$scope.reverseComlumnsSort = function () {
						if ($scope.columnsSort == 'up') {
							$scope.columnsSort = 'down';
							$scope.columnsForConditions = _.sortBy(
								$scope.columnsForConditions,
								function (item) {
									return item.lib;
								}
							).reverse();
						} else {
							$scope.columnsSort = 'up';
							$scope.columnsForConditions = _.sortBy(
								$scope.columnsForConditions,
								function (item) {
									return item.lib;
								}
							);
						}
						$scope.filterColumnsForConditions();
					};

					function setOnClickListenerToDateInput(inputs) {
						let length = inputs.length;
						if (!length) {
							return;
						}
						for (let i = 0; i < length; i++) {
							inputs[i].addEventListener('click', function (e) {
								var datePicker = document.getElementsByClassName(
									'bootstrap-datetimepicker-widget'
								);
								if (datePicker && datePicker[0]) {
									let coordinates = inputs[i].getBoundingClientRect();
									if (coordinates) {
										let width = coordinates.width;
										for (let i = 1; i < inputs.length; i++) {
											let widthS = inputs[i].getBoundingClientRect().width;
											if (widthS) {
												width = width + widthS;
											}
										}
										datePicker[0].style = datePicker[0].style
											? datePicker[0].style
											: {};
										datePicker[0].style.position = 'fixed';
										datePicker[0].style.top = coordinates.y + 5 + 'px';
										// 30 represents margin left -right
										datePicker[0].style.left =
											coordinates.x - coordinates.width - 45 + 'px';
									}
								}
							});
						}
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					datablock: '=',
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/dbFilter/dbFilter.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.vm.closeModal();
						}
					});
					scope.$watch(
						function () {
							return scope.vm.data.showFilter;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.getScopeQB = getScopeQB;
								getScopeQB = function () {
									return angular.element($('#qBuilder')).scope();
								};
								scope.limit = scope.vm.data.step
									? scope.vm.data.step.limit
									: undefined;
								scope.selectedWidget = scope.vm.datablock;
								scope.availableColumns = angular.copy(scope.vm.data.columns);
								if (scope.vm.data.additionalColumns) {
									scope.availableColumns = scope.availableColumns.concat(
										scope.vm.data.additionalColumns
									);
								}

								scope.columnsForConditions = angular.copy(
									scope.availableColumns
								);
								scope.vm.data.filterRules.active =
									!scope.vm.data.filterRules.inactive;
								scope.reverseComlumnsSort();
								if (scope.vm.data.filterTitle) {
									scope.title = scope.vm.data.filterTitle;
								} else {
									scope.title = 'Filtres';
								}
								for (var c in scope.columnsForConditions) {
									scope.columnsForConditions[c].id = c;
								}
								scope.resetRuleOperator(scope.vm.data.filterRules);

								scope.loadQueryBuilder();

								scope.updateGrammar();

								scope.filterColumnsForConditions();

								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
