(function () {
	'use strict';

	angular.module('dcApp').directive('elementMetadata', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'API_BASE_URL_BACKEND',
				'DashboardService',
				'ChartService',
				'GridService',
				'MediaService',
				'MapService',
				'TimeLineService',
				'DataBlocksService',
				'TypeEntiteService',
				'ConnectorService',
				'AllDepotService',
				'EntiteDepotService',
				function (
					$scope,
					$rootScope,
					$state,
					API_BASE_URL_BACKEND,
					DashboardService,
					ChartService,
					GridService,
					MediaService,
					MapService,
					TimeLineService,
					DataBlocksService,
					TypeEntiteService,
					ConnectorService,
					AllDepotService,
					EntiteDepotService
				) {
					var vm = this;
					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

					$scope.$watchGroup(
						['vm.id', 'vm.type'],
						function (newValue, oldValue) {
							$scope.loadData();
						}
					);

					$scope.loadData = function () {
						delete $scope.frameStatusData;
						delete $scope.metadata;
						getElementInfo();
					};

					$scope.showData = function () {
						vm.dataframeConfig.id = vm.id;
						vm.dataframeConfig.showFrameData = true;
					};

					var getElementInfo = function () {
						let service = undefined;
						$scope.typeLabel = getTypeLabelFromHdhType(vm.type);
						switch (vm.type) {
							case 'dashboard':
								service = DashboardService.getDashboard(vm.id);
								break;
							case 'chart':
								service = ChartService.getChart(vm.subType, vm.id);
								break;
							case 'grid':
								service = GridService.getGrid(vm.subType, vm.id);
								break;
							case 'media':
								service = MediaService.getMedia(vm.subType, vm.id);
								break;
							case 'timeline':
								service = TimeLineService.getTimeLine(vm.id);
								break;
							case 'connector':
								service = ConnectorService.getConnectorById(vm.id);
								break;
							case 'depot':
								service = AllDepotService.findOneDepotById(vm.id);
								break;
							case 'map':
								service = MapService.getMap(vm.id);
								break;
							case 'datablock':
								service = DataBlocksService.getDataBlock(vm.id);
								break;
							case 'entity':
							default:
								service = TypeEntiteService.getTypeWithLibs(vm.id);
								break;
						}
						service.then(function (response) {
							let metadata;
							if (vm.type !== 'entity') {
								metadata = response.data.metadata;
							} else {
								metadata = {
									id: response.data.metadata_id,
									code: response.data.code,
									label: response.data.label,
									creation_date: response.data.creation_date,
									update_date: response.data.update_date,
									created_by: response.data.created_by,
									updated_by: response.data.updated_by,
									project: response.data.project,
								};
							}
							$scope.metadata = metadata;
							$scope.metadata.creation_date = $rootScope.getDateTimeWithPattern(
								$scope.metadata.creation_date
							);
							$scope.metadata.update_date = $rootScope.getDateTimeWithPattern(
								$scope.metadata.update_date
							);
							if (vm.type === 'datablock' || vm.type === 'entity') {
								$scope.frameStatusData = {};
								setSpecificInfo();
							}
						});
					};

					var setSpecificInfo = function () {
						if (vm.type === 'entity') {
							EntiteDepotService.getEntiteDepotLinksCount(vm.id).then(function (
								response
							) {
								$scope.dataSourceCount = response.data;
							});
							TypeEntiteService.getCaracCount(vm.id).then(function (response) {
								$scope.columnCount = response.data;
							});
						}
						if (vm.type === 'datablock') {
							DataBlocksService.getColumnCount(vm.id).then(function (response) {
								$scope.columnCount = response.data;
							});
						}
					};
				},
			];
			return {
				restrict: 'E',

				scope: {
					type: '=',
					subType: '=',
					id: '=',
					iconId: '=',
					dataframeConfig: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/elementMetadata/elementMetadata.html',
				transclude: true,
				replace: true,
				link: function (scope, elem, attrs) {
					scope.loadData();
				},
			};
		},
	]);
})();
