(function () {
	'use strict';

	angular.module('dcApp').controller('HomeController', HomeController);

	HomeController.$inject = ['selectedProject'];

	function HomeController(selectedProject) {
		const vm = this;

		init();

		function init() {
			vm.projectId = selectedProject;
		}
	}
})();
