(function () {
	'use strict';

	angular.module('dcApp').factory('DcElementCommentService', [
		'$rootScope',
		'$http',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, API_BASE_URL_BACKEND) {
			let DcElementCommentService = {};

			DcElementCommentService.postComment = function (
				comment,
				elementId,
				type
			) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('COMMENTS');
				url = url + '?dcType=' + type + '&elementId=' + elementId;
				return $http.post(url, comment).then(function (response) {
					return response;
				});
			};

			DcElementCommentService.findAll = function (elementId, type) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('COMMENTS');
				url = url + '?dcType=' + type + '&elementId=' + elementId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DcElementCommentService.deleteComment = function (commentId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('COMMENTS_BY_ID');
				url = url.replace('{commentId}', commentId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DcElementCommentService.deleteSelectedComments = function (
				selectedComments
			) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('COMMENTS');
				if (Array.isArray(selectedComments)) {
					url = url + '?ids=' + selectedComments[0];
					for (let i = 1; i < selectedComments.length; i++) {
						url = url + '&ids=' + selectedComments[i];
					}
				} else {
					url = url + '?ids=' + selectedComments;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			return DcElementCommentService;
		},
	]);
})();
