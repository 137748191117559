(function () {
	'use strict';

	angular.module('dcApp').factory('ExpressIntegration', [
		'$rootScope',
		'$http',
		'$q',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, $q, API_BASE_URL_BACKEND) {
			let ExpressIntegration = {};

			ExpressIntegration.saveExpressIntegration = function (
				data,
				createRepDetails
			) {
				createRepDetails = createRepDetails ? createRepDetails : false;
				let url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('EXPRESS_INTEGRATION') +
					'?createRepDetails=' +
					createRepDetails;
				return $http.post(url, data).then(function (response) {
					return response;
				});
			};

			ExpressIntegration.refreshExpressIntegrationFile = function (
				csvDepot,
				inferSchema,
				withData
			) {
				let deferred = $q.defer();
				inferSchema = inferSchema !== undefined ? inferSchema : false;
				withData = withData !== undefined ? withData : false;
				const url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('EXPRESS_INTEGRATION_REFRESH') +
					'?inferSchema=' +
					inferSchema +
					'&withData=' +
					withData;
				$http.post(url, csvDepot).then(
					function (response) {
						let results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (errorMessage) {
						deferred.reject(errorMessage);
					}
				);
				return deferred.promise;
			};

			ExpressIntegration.detectCharset = function (depotCode, readingMask) {
				const url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('EXPRESS_INTEGRATION_CHARSET_DETECT') +
					'?code=' +
					depotCode +
					'&readingMask=' +
					readingMask;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return ExpressIntegration;
		},
	]);
})();
