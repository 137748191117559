(function () {
	'use strict';

	StringSubstringController.$inject = [];
	function StringSubstringController() {
		const vm = this;
		vm.chunkSize = 50;

		vm.$onInit = onInit;
		vm.$onChanges = onChanges;

		function onInit() {
			vm.chunkedCenteringLabel = chunkSubstr(vm.widgetData.label, vm.chunkSize);
		}

		function onChanges(changes) {
			if (changes['widgetData'] && changes['widgetData'].currentValue) {
				const data = changes['widgetData'].currentValue;
				vm.chunkedCenteringLabel = chunkSubstr(data.label, vm.chunkSize);
			}
		}

		function chunkSubstr(str, size) {
			const numChunks = Math.ceil(str.length / size);
			const chunks = new Array(numChunks);

			for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
				chunks[i] = str.substr(o, size);
			}
			return chunks;
		}
	}

	const stringSubstring = {
		restrict: 'E',
		bindings: {
			widgetData: '<',
		},
		controller: StringSubstringController,
		templateUrl:
			'./src/components/directives/string-substring/string-substring.component.html',
		controllerAs: 'vm',
	};

	angular.module('dcApp').component('stringSubstring', stringSubstring);
})();
