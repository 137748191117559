(function () {
	'use strict';

	angular.module('dcApp').directive('funnelchartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;
					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'funnel',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope($scope, vm, 'funnel');

					var loadGroupDataInChart = function (data) {
						$scope.config.global.item = {
							border: {
								visible: $scope.config.global.borderSeparated,
							},
						};

						if (
							$scope.config.global.tooltipColumns &&
							$scope.config.global.tooltipColumns[0]
						) {
							$scope.config.global.tooltip = {
								enabled: true,
								format: 'fixedPoint',
								customizeTooltip: function (arg) {
									return {
										html: ChartService.getTooltip(
											arg.item.data,
											$scope.config.global.tooltipColumns,
											$scope.group.columns,
											$scope.config.global.argumentAxis
										),
									};
								},
							};
						}

						if ($scope.config.global.algorithm == 'dynamicSlope') {
							$scope.config.global.label = {
								visible: $scope.config.global.labelVisible,
								position: $scope.config.global.labelPosition,
								horizontalAlignment:
									$scope.config.global.labelHorizontalAlignment,
								backgroundColor: 'none',
								customizeText: function (e) {
									return (
										"<span style='font-size: 28px'>" +
										e.percentText +
										'</span><br/>' +
										e.item.argument
									);
								},
							};
						} else {
							$scope.config.global.label = {
								visible: $scope.config.global.labelVisible,
								backgroundColor: 'none',
								horizontalAlignment:
									$scope.config.global.labelHorizontalAlignment,
								font: {
									size: 16,
								},
							};
						}

						delete $scope.config.global.borderSeparated;
						delete $scope.config.global.labelVisible;
						delete $scope.config.global.labelHorizontalAlignment;
						delete $scope.config.global.labelPosition;

						$scope.dxChartOptions = $scope.config.global;
						$scope.dxChartOptions.dataSource = data;

						$('#chart' + $scope.uuid).dxFunnel($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxFunnel('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxFunnel('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/funnel/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
