(function () {
	'use strict';

	angular.module('dcApp').factory('PublicationService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var PublicationService = {};

			PublicationService.findAllPublications = function (dashboardId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_GET_ALL');
				url = url.replace('{dashId}', dashboardId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.generateHashedLink = function (dashboardId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_LINK_GENERATE');
				url = url.replace('{dashId}', dashboardId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.findPublication = function (
				dashboardId,
				publicationId
			) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_GET');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.getAllPublicationViews = function (
				dashboardId,
				publicationId
			) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_VIEWS_GET_ALL');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.createPublication = function (
				dashboardId,
				publication
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_CREATE');
				url = url.replace('{dashId}', dashboardId);
				$http.post(url, publication).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PublicationService.editPublication = function (
				dashboardId,
				publicationId,
				publication
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_EDIT');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				$http.put(url, publication).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PublicationService.deleteOrArchivePublication = function (
				dashboardId,
				publicationId
			) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_DELETE_OR_ARCHIVE');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			PublicationService.deletePublication = function (
				dashboardId,
				publicationId
			) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_DELETE');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			PublicationService.restorePublication = function (
				dashboardId,
				publicationId
			) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_RESTORE');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.updatePublication = function (
				dashboardId,
				publicationId
			) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_UPDATE');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			PublicationService.updatePublicationStatus = function (
				dashboardId,
				publicationId,
				status
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DASHBOARDS_PUBLICATION_UPDATE_STATUS');
				url = url.replace('{dashId}', dashboardId);
				url = url.replace('{id}', publicationId);
				$http.put(url, status).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PublicationService.getDashboardData = function (
				link,
				centerings,
				applyInitialCenterings
			) {
				var deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND +
					'dashboard_publication/{link}?applyInitialCenterings=' +
					applyInitialCenterings;
				url = url.replace('{link}', link);
				$http.post(url, centerings).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			PublicationService.getPublicationLink = function () {
				let url = API_BASE_URL_BACKEND + 'dashboard_publication/link';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return PublicationService;
		},
	]);
})();
