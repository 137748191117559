(function () {
	'use strict';

	angular.module('dcApp').directive('topCaracValue', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'CaracService',
				'DataBlocksService',
				function ($scope, CaracService, DataBlocksService) {
					$scope.caracNumber = 3;
					$scope.defautValue = '';
					var vm = this;
					$scope.refreshValues = function (text) {
						if (!text || text.length < $scope.caracNumber) {
							return;
						}
						if ($scope.caracId) {
							CaracService.getTopValues(
								$scope.caracId,
								text,
								100,
								$scope.searchMode
							).then(function (response) {
								$scope.values = [];
								for (var d in response.data) {
									$scope.values.push({ value: d });
								}
								$(vm.element).modal('show');
							});
						} else if (vm.widgetData.topCaracValueColumn) {
							DataBlocksService.findTopValues(
								text,
								$scope.searchMode,
								'',
								vm.widgetData.topCaracValueColumn,
								vm.widgetData.topCaracValueColumnType,
								vm.widgetData.topCaracValueCurrentGrammar,
								100
							).then(function (response) {
								$scope.values = [];
								for (var d in response.data) {
									$scope.values.push({ value: response.data[d] });
								}
								$(vm.element).modal('show');
							});
						}
					};

					$scope.save = function () {
						vm.widgetData.updateaction_l2($scope.value.selected.value);
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showTopCaracValue = false;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=' },
				controller,
				'./src/components/directives/dataOperationTools/topCaracValue/topCaracValue.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.widgetData.showTopCaracValue = false;
						delete scope.caracId;
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showTopCaracValue = false;
							delete scope.caracId;
						});
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showTopCaracValue;
						},
						function () {
							scope.values = [];
							scope.value = {};
							scope.defautValue = scope.vm.widgetData.defautValue;
							scope.caracId = scope.vm.widgetData.caracId;
							scope.searchMode = 'contains';
						}
					);
				}
			);
		},
	]);
})();
