(function () {
	angular.module('dcApp').directive('sourceList', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'$state',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'$filter',
				'$window',
				'API_BASE_URL_BACKEND',
				'TypeEntiteService',
				'CaracService',
				'CaracFileMappingService',
				'DATE_TYPE_CODE',
				'toaster',
				'DataBlocksService',
				'$sce',
				'EntiteDepotService',
				'AllDepotService',
				'DepotExtractionFilterService',
				'$timeout',
				'gettextCatalog',
				'UPDATE_EVENT',
				function (
					$scope,
					$rootScope,
					$state,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					$filter,
					$window,
					API_BASE_URL_BACKEND,
					TypeEntiteService,
					CaracService,
					CaracFileMappingService,
					DATE_TYPE_CODE,
					toaster,
					DataBlocksService,
					$sce,
					EntiteDepotService,
					AllDepotService,
					DepotExtractionFilterService,
					$timeout,
					gettextCatalog,
					UPDATE_EVENT
				) {
					let dataGirdColumnName = {};
					// linked depot datagird
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.mapping = gettextCatalog.getString('Mapping');
					dataGirdColumnName.actions = gettextCatalog.getString('Actions');
					dataGirdColumnName.actif = gettextCatalog.getString('Actif');
					dataGirdColumnName.live = gettextCatalog.getString('En direct');
					dataGirdColumnName.filter = gettextCatalog.getString('Filtre');

					// depots to be added datagrid
					dataGirdColumnName.code = gettextCatalog.getString('Code');
					dataGirdColumnName.type = gettextCatalog.getString('Type');

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopDeleteLink = gettextCatalog.getString(
						'Suppression du lien Entité métier-Dépôt effectuée'
					);
					let toasterPopLink = gettextCatalog.getString(
						'Lien Entité métier-Dépôt effectué'
					);
					let toasterPopSaveFilter = gettextCatalog.getString(
						'Enregistrement du Filtre effectué'
					);
					let toasterPopFilterWarmingMessage = gettextCatalog.getString(
						"Vous devez d'abord affecter un filtre"
					);
					let toasterPopSaveLink = gettextCatalog.getString(
						'Enregistrement du lien Entité métier-Dépôt effectué'
					);
					let toasterPopCreationLink = gettextCatalog.getString(
						'Création du lien Entité métier-Dépôt effectuée'
					);

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.showCurrentProjectOnly = true;
					$scope.caracsSimple = [];
					$scope.caracsComplex = [];
					$scope.caracs = [];

					$scope.entiteTypeDepotLinks = [];
					$scope.getEntiteTypeDepotLinks = function () {
						$scope.showList = false;
						$scope.entiteTypeDepotLinks = [];
						EntiteDepotService.getEntiteTypeDepotLinksList(
							$scope.entite_type.id,
							true
						).then(function (response) {
							let content = response.data;
							for (let i = 0; i < content.length; i++) {
								let depot = content[i].depot;
								depot.depot_type = getDepotReaderParentType(depot.depot_type);
								$scope.entiteTypeDepotLinks.push({
									id: content[i].id,
									depot: depot,
									lib: content[i].depot.metadata.label,
									mapping_counts: content[i].mapping_counts,
									unCheckedDepot: content[i].unCheckedDepot,
									active: content[i].active,
									icon_id: content[i].depot.metadata.icon_id,
									extraction_date_filter: content[i].extraction_date_filter,
									live: content[i].live,
									index: i,
								});
							}
							$scope.sourceGridData = angular.copy($scope.entiteTypeDepotLinks);
							$scope.sourceDataGridOptions.dataSource = $scope.sourceGridData;
							$scope.showList = true;
						});
					};
					$scope.sourceDataGridOptions = {
						dataSource: $scope.sourceData,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						scrolling: {
							rowRenderingMode: 'Standard',
							showScrollbar: 'onHover',
						},
						searchPanel: {
							visible: true,
							width: 240,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						filterPanel: {
							visible: true,
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						enableFiltering: true,
						columnChooser: {
							enabled: true,
						},
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.label,
								cellTemplate: 'imageAndLib',
								dataField: 'lib',
								allowReordering: false,
								allowFiltering: true,
								allowSorting: true,
							},
							{
								caption: dataGirdColumnName.mapping,
								cellTemplate: 'mapping',
								width: '12%',
								alignment: 'center',
								allowReordering: false,
								allowFiltering: false,
								allowSorting: false,
							},
							{
								caption: dataGirdColumnName.actif,
								cellTemplate: 'actif',
								allowHeaderFiltering: true,
								enableCellEdit: false,
								width: '12%',
							},
							{
								caption: dataGirdColumnName.live,
								cellTemplate: 'live',
								allowHeaderFiltering: true,
								enableCellEdit: false,
								width: '12%',
							},
							{
								dataField: 'extraction_date_filter.label',
								caption: dataGirdColumnName.filter,
								cellTemplate: 'filtre',
								allowHeaderFiltering: true,
								allowFiltering: true,
								enableCellEdit: false,
								width: '32%',
							},
							{
								caption: dataGirdColumnName.actions,
								alignment: 'center',
								cellTemplate: 'actions',
								width: '150px',
								allowReordering: false,
							},
						],
						onInitialized: function (e) {
							$scope.gridInstance = e.component;
						},
					};

					$scope.getCaracs = function () {
						TypeEntiteService.getCaracs($scope.entite_type.id, true).then(
							function (response) {
								angular.forEach(response.data, function (carac, key) {
									if (carac.complex) {
										$scope.caracsComplex.push(carac);
									} else {
										$scope.caracsSimple.push(carac);
									}
								});

								$scope.caracs = $scope.caracsSimple;
							}
						);
					};
					/* delete */
					$scope.showDeleteUetDepotLinkModal = function (modalId, link, index) {
						if (link.id === undefined) {
							$scope.entiteTypeDepotLinks.splice(index, 1);
						} else {
							$('#' + modalId).modal('show');
							$scope.entiteTypeCsvDepotLinkToDelete = {};
							$scope.entiteTypeCsvDepotLinkToDelete.Id = link.id;
							$scope.entiteTypeCsvDepotLinkToDelete.depot = link.depot;
						}
					};
					$scope.deleteEntiteCsvDepotLink = function (modalId) {
						EntiteDepotService.deleteEntiteTypeDepotLink(
							$scope.entiteTypeCsvDepotLinkToDelete.Id
						).then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopDeleteLink);
							$scope.getEntiteTypeDepotLinks();
							$rootScope.$broadcast(UPDATE_EVENT['ENTITETYPE_DEPOT']);
							$('#' + modalId).modal('hide');
						});
					};
					/* delete */

					/*actif*/
					$scope.updateEntiteTypeDataSourceStatus = function (link) {
						EntiteDepotService.updateEntiteTypeDepotLink(
							link.id,
							link.active
						).then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopLink);
						});
					};
					/*actif*/

					/*choose filtre*/
					$scope.extractionFilters = [];
					$scope.showFilterList = false;
					$scope.showDateFilter = function (depotId, link, modalId) {
						$scope.showFilterList = false;
						$scope.selectedLinkForFilter = link.id;
						$scope.filterId =
							link != undefined && link.extraction_date_filter != undefined
								? link.extraction_date_filter.id
								: undefined;
						DepotExtractionFilterService.findAllExtractionsFilters(
							depotId,
							link.depot.depot_type
						).then(function (response) {
							$scope.extractionFilters = response.data;
							$scope.dateFiltersGridData = $scope.extractionFilters;
							$scope.dateFiltersGridOptions.dataSource =
								$scope.dateFiltersGridData;
							$scope.showFilterList = true;
							$('#' + modalId).modal('show');
							$timeout(function () {
								angular.forEach(
									$scope.dateFiltersGridOptions.dataSource,
									function (elm, index) {
										if ($scope.filterId == elm.id) {
											$scope.dateFiltersGridOptions.dataSource[
												index
											].selected = true;
										}
									}
								);
							});
						});
					};

					$scope.dateFiltersGridOptions = {
						dataSource: $scope.dateFiltersGridData,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						selection: {
							mode: 'single',
							showCheckBoxesMode: 'always',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.label,
								dataField: 'label',
								allowReordering: false,
							},
						],
						onInitialized: function (e) {
							$scope.gridFiltersInstance = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							$scope.selectedfilters = selectedItems.selectedRowsData;
							if ($scope.selectedIconPopu && $scope.selectedfilters[0]) {
								for (let i in $scope.dateFiltersGridOptions.dataSource) {
									if (
										$scope.dateFiltersGridOptions.dataSource[i].id ===
										$scope.selectedfilters[0].id
									) {
										$scope.dateFiltersGridOptions.dataSource[i].selected = true;
									} else {
										$scope.dateFiltersGridOptions.dataSource[
											i
										].selected = false;
									}
								}
							} else {
								for (let i in $scope.dateFiltersGridOptions.dataSource) {
									$scope.dateFiltersGridOptions.dataSource[i].selected = false;
								}
							}
						},
						onContentReady: function (e) {
							if (
								$scope.gridFiltersInstance &&
								$scope.gridFiltersInstance.selectRowsByIndexes
							) {
								let selectedItems = [];
								for (
									let i = 0;
									i < $scope.dateFiltersGridOptions.dataSource.length;
									i++
								) {
									if ($scope.dateFiltersGridOptions.dataSource[i].selected) {
										selectedItems.push(i);
									}
								}
								$scope.gridFiltersInstance.selectRowsByIndexes(selectedItems);
							}
						},
					};

					$scope.saveEntiteDepotExtractionFilter = function (modalId) {
						let filterId =
							$scope.selectedfilters && $scope.selectedfilters[0]
								? $scope.selectedfilters[0].id
								: '';
						if (filterId !== '') {
							EntiteDepotService.updateEntiteTypeDateExtractionFilter(
								$scope.selectedLinkForFilter,
								filterId
							).then(function (response) {
								toaster.pop('success', toasterPopSuccess, toasterPopSaveFilter);
								for (let i in $scope.entiteTypeDepotLinks) {
									if ($scope.entiteTypeDepotLinks[i].id == response.data.id) {
										$scope.entiteTypeDepotLinks[i].live = response.data.live;
										$scope.entiteTypeDepotLinks[i].extraction_date_filter =
											response.data.extraction_date_filter;
										break;
									}
								}
							});
						}

						if ($scope.gridInstance && $scope.gridInstance.option) {
							$scope.gridInstance.option(
								'dataSource',
								$scope.entiteTypeDepotLinks
							);
							$scope.gridInstance.refresh();
						}
						$('#' + modalId).modal('hide');
					};
					/*choose filtre*/

					/*apply filtre*/
					$scope.updateEntiteTypeLiveStatus = function (link) {
						if (
							!link.live &&
							(link.extraction_date_filter == undefined ||
								link.extraction_date_filter.id == undefined)
						) {
							toaster.pop('error', toasterPopFilterWarmingMessage);
							link.live = true;
							return;
						}
						EntiteDepotService.updateEntiteTypeLoadingLive(
							link.id,
							link.live
						).then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopSaveLink);
							for (let i in $scope.entiteTypeDepotLinks) {
								if ($scope.entiteTypeDepotLinks[i].id == response.data.id) {
									$scope.entiteTypeDepotLinks[i].live = response.data.live;
									$scope.entiteTypeDepotLinks[i].extraction_date_filter =
										response.data.extraction_date_filter;
									break;
								}
							}
						});

						if ($scope.gridInstance && $scope.gridInstance.option) {
							$scope.gridInstance.option(
								'dataSource',
								$scope.entiteTypeDepotLinks
							);
						}
					};
					/*apply filtre*/

					/* edit depot*/
					$scope.checkPermissionData = {};
					$scope.editSource = function (obj) {
						$scope.objToEdit = obj.depot;
						$scope.checkPermissionData.elementId = $scope.objToEdit.id;
						$scope.checkPermissionData.type = 'data_source';
						$scope.checkPermissionData.action = 'edit';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.goToEditSource;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.goToEditSource = function () {
						let url = $state.href('depots-edit', {
							id: $scope.objToEdit.id,
							type: $scope.objToEdit.depot_type,
						});
						window.open(url, '_blank');
					};
					/* edit depot*/

					/*edit*/
					$scope.showLinkedDataSourcesModal = function (modalId, index) {
						if ($scope.entiteTypeDepotLinks[index]) {
							$scope.selectedLinkId = $scope.entiteTypeDepotLinks[index].id;
						}

						$('#' + modalId).modal('show');
						$scope.getDepotsForLinksGridData();
					};

					$scope.getDepotsForLinksGridData = function () {
						$scope.depots = [];
						$scope.showElementsGrid = false;
						AllDepotService.findAllDepots(
							undefined,
							undefined,
							undefined,
							false,
							true
						).then(function (data) {
							if (data !== undefined) {
								let content = data.data;
								for (let i in content) {
									$scope.depots.push({
										id: content[i].id,
										code: content[i].metadata.code,
										lc: content[i].metadata.label,
										actif: content[i].metadata.actif,
										type: getDepotReaderParentType(content[i].depot_type),
										tags: content[i].metadata.tags,
										tagAsArray: $rootScope.mapTags(content[i].metadata.tags),
										updateDate: $rootScope.getDateTimeWithPattern(
											content[i].metadata.update_date
										),
										updatedBy: content[i].metadata.updated_by,
									});
								}
								$scope.loadDepotDataforLinks = $scope.depots;
								$scope.depotsLinkGridOptions.dataSource =
									$scope.loadDepotDataforLinks;
								$scope.showElementsGrid = true;
								if (
									$scope.gridInstanceDepotsLink &&
									$scope.gridInstanceDepotsLink.option
								) {
									$scope.gridInstanceDepotsLink.option(
										'dataSource',
										$scope.loadDepotDataforLinks
									);
									$scope.gridInstanceDepotsLink.refresh();
								}
							}
						});
					};
					$scope.depotsLinkGridOptions = {
						dataSource: $scope.loadDepotDataforLinks,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						selection: {
							mode: 'multiple',
							showCheckBoxesMode: 'always',
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						enablePaginationControls: true,
						paginationPageSize: PAGINATIONS_SIZE,
						paginationPageSizes: PAGINATIONS_SIZES,
						enableFiltering: true,
						columns: [
							{
								dataField: 'code',
								caption: dataGirdColumnName.code,
								sort: { direction: 'asc', priority: 1 },
								width: '10%',
								cellClass: 'grid-align',
							},
							{
								dataField: 'lc',
								caption: dataGirdColumnName.label,
								allowReordering: false,
							},
							{
								dataField: 'type',
								caption: dataGirdColumnName.type,
							},
							{
								dataField: 'tagAsArray',
								caption: 'Tags',
								name: 'tags',
								cellTemplate: 'tagTemplate',
								allowHeaderFiltering: true,
								enableCellEdit: false,
								calculateFilterExpression: function (filterValue) {
									if (!filterValue) return;
									return ['tagAsArray', 'contains', filterValue];
								},
							},
							{
								dataField: 'updateDate',
								caption: gettextCatalog.getString('Date de mise à jour'),
								sortOrder: 'desc',
							},
							{
								dataField: 'updatedBy',
								caption: gettextCatalog.getString('Dernier modificateur'),
							},
						],
						onInitialized: function (e) {
							$scope.gridInstanceDepotsLink = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							$scope.selectedDepots = selectedItems.selectedRowsData;
						},
						onRowDblClick: function (item) {
							$scope.selectedDepots = [item.data];
							$scope.saveEntiteDepotLinks('depotUetLink');
						},
					};

					$scope.getTagBox = function (list) {
						$scope.tagBox.value = list.data.tags;
						return $scope.tagBox;
					};

					$scope.tagBox = {
						displayExpr: 'code',
						valueExpr: 'id',
						searchEnabled: false,
						editEnabled: false,
						tagTemplate: 'tagTemplate',
					};

					$scope.saveEntiteDepotLinks = function (modalId) {
						$scope.depotIds = [];
						if ($scope.selectedDepots != undefined) {
							let depotsIds = _.map($scope.selectedDepots, function (de) {
								return de.id;
							});
							EntiteDepotService.createEntiteTypeDepotLinks(
								$scope.entite_type.id,
								depotsIds
							).then(function (response) {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopCreationLink
								);
								$scope.getEntiteTypeDepotLinks();
								$rootScope.$broadcast(UPDATE_EVENT['ENTITETYPE_DEPOT']);
							});
						}
						$('#' + modalId).modal('hide');
					};
					/*edit*/

					/* show mapping*/
					$scope.modes = {
						caracMode: true,
						qualityMode: false,
						refByLabel: false,
					};
					$scope.bulkMappingScope = { showBulkMappingFlag: false };
					$scope.showBulkMapping = function () {
						$scope.entiteTypeData = {};
						$scope.entiteTypeData.entiteType = $scope.entite_type;
						$scope.entiteTypeData.isEntiteType = true;
						$scope.bulkMappingScope.showBulkMappingFlag = true;
						$scope.bulkMappingScope.enititeTypeId =
							$scope.entiteTypeData.entiteType.id;
						$scope.bulkMappingScope.showFormule = $scope.showFormule;
						$scope.widgetData.updateaction = $scope.updateMappingCountForLink;
					};

					$scope.widgetData = {};
					$scope.dbBuilder = {};
					$scope.showFormule = function (caracs, keepOldData, fileId) {
						$scope.selectedfileId = fileId;
						if (!keepOldData || $scope.widgetData.widget == undefined) {
							let widget = {};
							widget.grammar = {};
							widget.grammar.columns = [];
							for (let c in caracs) {
								let c_ =
									caracs[c].column != undefined ? caracs[c].column : caracs[c];
								let t_ =
									caracs[c].data_type != undefined
										? caracs[c].data_type
										: 'string';
								let col = {
									column_alias: c_,
									field: c_,
									type: formulasTypesMap(t_),
									lib: c_,
									alias: 'COLL' + c,
								};
								widget.grammar.columns.push(col);
							}
							$scope.widgetData.widget = widget;
							$scope.dbBuilder.columns = widget.grammar.columns;
							$scope.modes.caracMode = false;
							$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
							$scope.widgetData.showFormule = true;
							$scope.widgetData.widgetMenuOpenAction = {};
							$scope.widgetData.widgetMenuData = {};
							$scope.widgetData.hideWidgetMenu = true;
							let typeToFilter =
								typesMap[$scope.caracToBeMapped.entite_type.code];
							$scope.widgetData.rootFunctionType =
								typeToFilter != undefined ? typeToFilter : 'string';
							$scope.widgetByFile[$scope.selectedfileId] =
								$scope.widgetData.widget;
						} else {
							$scope.initWidget(
								$scope.headersByFile[$scope.selectedfileId],
								$scope.formulaGrammar[$scope.selectedfileId]
							);
							$scope.modes.caracMode = false;
							$scope.widgetData.showFormule = true;
						}
					};
					$scope.updateMappingCountForLink = function () {
						if (
							$scope.entiteTypeDepotLinks != undefined &&
							$scope.entiteTypeDepotLinks.length > 0
						) {
							$scope.getEntiteTypeDepotLinks();
						}
					};

					/*refresh link list*/
					$scope.refreshLinkedSource = function () {
						$scope.showList = false;
						$scope.getEntiteTypeDepotLinks();
					};

					$scope.createCaracsAndMappingFromDepotByLinkConfirmation = function (
						linkId,
						modalId
					) {
						$scope.selectedLinkId = linkId;
						$('#' + modalId).modal('show');
					};

					$scope.createCaracsAndMappingFromDepotByLink = function (modalId) {
						if ($scope.selectedLinkId !== undefined) {
							CaracService.createCaracsAndMappingFromDepot(
								$scope.selectedLinkId
							).then(function (response) {
								$scope.refreshLinkedSource();
								$rootScope.$broadcast(UPDATE_EVENT['CARAC_MAPPING']);
								toaster.pop(
									'success',
									toasterPopSuccess,
									gettextCatalog.getString('Caractéristiques créées')
								);
								$('#' + modalId).modal('hide');
							});
						}
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					entite: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl: './src/components/directives/sourceList/sourceList.html',
				link: function (scope, element, attrs) {
					scope.entite_type = scope.vm.entite;
					scope.getEntiteTypeDepotLinks();
					scope.getCaracs();
				},
			};
		},
	]);
})();
