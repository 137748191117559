(function () {
	angular.module('dcApp').directive('caracList', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'$filter',
				'API_BASE_URL_BACKEND',
				'TypeEntiteService',
				'CaracService',
				'CaracFileMappingService',
				'DATE_TYPE_CODE',
				'toaster',
				'DataBlocksService',
				'ModalService',
				'$sce',
				'TagService',
				'StorageModeService',
				'$stateParams',
				'EntiteDepotService',
				'DepotFileService',
				'CommonServices',
				'GEOMETRY_TYPE_CODE',
				'GEO_FORMATS',
				'gettextCatalog',
				'UPDATE_EVENT',
				'STORAGE_SUFFIX',
				function (
					$scope,
					$rootScope,
					$state,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					$filter,
					API_BASE_URL_BACKEND,
					TypeEntiteService,
					CaracService,
					CaracFileMappingService,
					DATE_TYPE_CODE,
					toaster,
					DataBlocksService,
					ModalService,
					$sce,
					TagService,
					StorageModeService,
					$stateParams,
					EntiteDepotService,
					DepotFileService,
					CommonServices,
					GEOMETRY_TYPE_CODE,
					GEO_FORMATS,
					gettextCatalog,
					UPDATE_EVENT,
					STORAGE_SUFFIX
				) {
					const LABEL_HEADERS_REF_MODE = 'LABEL';

					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.dataType =
						gettextCatalog.getString('Type de données');
					dataGirdColumnName.actions = gettextCatalog.getString('Actions');
					dataGirdColumnName.code = gettextCatalog.getString('Code');
					dataGirdColumnName.key = gettextCatalog.getString('Clé');
					dataGirdColumnName.icon = gettextCatalog.getString('Icône');

					let toasterPopSucess = gettextCatalog.getString('Succès');
					let toasterCreationCarac = gettextCatalog.getString(
						'Création de la Caractéristique effectuée'
					);
					let toasterEditCarac = gettextCatalog.getString(
						'Modification de la Caractéristique effectuée'
					);
					let toasterEditMapping = gettextCatalog.getString(
						'Modification du Mapping effectuée'
					);

					let toasterDeleteCarac = gettextCatalog.getString(
						'Suppression de la Caractéristique effectuée'
					);
					let toasterDeleteLink = gettextCatalog.getString(
						'Suppression du lien effectué'
					);
					let toasterSaveCaracFormule = gettextCatalog.getString(
						'Enregistrement de la Caractéristique virtuelle '
					);

					$scope.noDesc = gettextCatalog.getString('Aucune description');

					let refreshList = false;

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.availableGeoFormats = GEO_FORMATS;
					var vm = this;
					$scope.persistanceParams = {};
					$scope.widgetData = {};

					$scope.selectedLang = $rootScope.account.lang;
					$scope.selectedLangCarac = $rootScope.account.lang;

					$scope.type = {};
					$scope.caracs = [];
					$scope.isNewCarac = true;
					$scope.isToDuplicate = false;

					$scope.hdhFormats = [
						{ label: gettextCatalog.getString('Formule'), value: 'FORMULA' },
						{ label: gettextCatalog.getString('Simple'), value: 'SIMPLE' },
					];

					$scope.showCaraInfo = function (id) {
						$scope.widgetData.caracInfoId = id;
						$scope.widgetData.caracInfoEntiteId = $scope.entite_type.id;
						$scope.widgetData.caracInfoEntiteLabel = $scope.entite_type.label;
						$scope.widgetData.showColumnStat = true;
					};

					$scope.getCaracsSimple = function () {
						$scope.showList = false;
						$scope.caracs = [];
						TypeEntiteService.getCaracs($scope.entite_type.id, true, true).then(
							function (response) {
								let caracsSimple = [];
								angular.forEach(response.data, function (carac, key) {
									carac.label = carac.lib;
									caracsSimple.push(carac);
								});
								$scope.caracsSimple = caracsSimple;
								var content;
								if ($scope.isCaracSimple) {
									content = $scope.caracsSimple;
								} else {
									content = $scope.caracsComplex;
								}
								$scope.tags = [];
								let tags = [];
								for (var i in content) {
									$scope.caracs.push({
										id: $scope.isToDuplicate ? null : content[i].id,
										code: content[i].code,
										entite_type: content[i].entite_type,
										lib: content[i].lib,
										description: content[i].description,
										hdh_format: content[i].hdh_format,
										cf: content[i].cf,
										isCaracSimple: $scope.isCaracSimple,
										mapping_count: content[i].mapping_count,
										isToDuplicate: $scope.isToDuplicate,
										structured: content[i].structured,
										list: content[i].list,
										complex: content[i].complex,
										lib_entite_type: content[i].lib_entite_type,
										poids: content[i].poids,
										metadata_id: $scope.isToDuplicate ? null : content[i].metadata_id,
										formula: content[i].formula,
										tags: content[i].tags,
										tagAsArray:  $rootScope.mapTags(content[i].tags),
									});
									tags = _.union(tags, content[i].tags);
								}
								for (var c in $scope.caracs) {
									$scope.caracs[c].data_type = getTypeFromEntiteTypeCode(
										$scope.caracs[c].entite_type.code
									);
								}
								for (var i in tags) {
									if (_.find($scope.tags, { id: tags[i].id }) == null) {
										$scope.tags.push({ id: tags[i].id, label: tags[i].code });
									}
								}
								$scope.caracsGridData = angular.copy($scope.caracs);
								$scope.caracsDataGridOptions.dataSource = $scope.caracsGridData;
								$scope.showList = true;
								// get date patterns
								CommonServices.getDateReadingMasks().then(function (
									responseDatePatterns
								) {
									$scope.datePatterns = responseDatePatterns.data;
								});
								refreshList = true;
							}
						);
					};
					$scope.getCaracsComplex = function () {
						$scope.showList = false;
						$scope.caracs = [];
						TypeEntiteService.getCaracs(
							$scope.entite_type.id,
							false,
							true
						).then(function (response) {
							let caracsComplex = [];
							angular.forEach(response.data, function (carac, key) {
								carac.label = carac.lib;
								caracsComplex.push(carac);
							});
							$scope.caracsComplex = caracsComplex;
							var content = $scope.caracsComplex;
							$scope.tags = [];
							let tags = [];
							for (var i in content) {
								$scope.caracs.push({
									id: $scope.isToDuplicate ? null : content[i].id,
									code: content[i].code,
									entite_type: content[i].entite_type,
									lib: content[i].lib,
									description: content[i].description,
									hdh_format: content[i].hdh_format,
									cf: content[i].cf,
									isCaracSimple: $scope.isCaracSimple,
									mapping_count: content[i].mapping_count,
									isToDuplicate: $scope.isToDuplicate,
									structured: content[i].structured,
									list: content[i].list,
									complex: content[i].complex,
									join_type: content[i].join_type,
									lib_entite_type: content[i].lib_entite_type,
									poids: content[i].poids,
									tags: content[i].tags,
									tagAsArray:  $rootScope.mapTags(content[i].tags),
								});
								tags = _.union(tags, content[i].tags);
							}
							for (var c in $scope.caracs) {
								$scope.caracs[c].data_type = getTypeFromEntiteTypeCode(
									$scope.caracs[c].entite_type.code
								);
							}
							for (var i in tags) {
								if (_.find($scope.tags, { id: tags[i].id }) == null) {
									$scope.tags.push({ id: tags[i].id, label: tags[i].code });
								}
							}
							$scope.caracsGridData = angular.copy($scope.caracs);
							$scope.caracsDataGridOptions.dataSource = $scope.caracsGridData;
							$scope.showList = true;
							refreshList = true;
						});
					};

					let lookupTags = [];
					let columns = [
						{
							caption: dataGirdColumnName.icon,
							cellTemplate: 'imageAndLib',
							dataField: 'id',
							width: '56px',
							allowReordering: false,
							allowFiltering: false,
							allowSorting: false,
						},
						{
							dataField: 'lib',
							caption: dataGirdColumnName.label,
							cellTemplate: 'lib',
							allowHeaderFiltering: true,
							allowFiltering: true,
							enableCellEdit: false,
							width: '17%',
							headerFilter: {
								allowSearch: true,
							},
						},
						{
							dataField: 'entite_type.lib',
							name: 'parentLabel',
							caption: dataGirdColumnName.dataType,
							cellTemplate: 'types',
							allowHeaderFiltering: true,
							allowFiltering: true,
							enableCellEdit: false,
						},
						{
							dataField: 'tagAsArray',
							caption: gettextCatalog.getString('Tags'),
							name: 'tags',
							cellTemplate: 'tagTemplate',
							allowHeaderFiltering: true,
							enableCellEdit: false,
							allowFiltering: false,
							width: '15%',
							headerFilter: {
								dataSource: lookupTags,
							},
							calculateFilterExpression: function (filterValue) {
								if (!filterValue) {
									return ['tagAsArray', '=', null];
								}

								return ['tagAsArray', 'contains', filterValue];
							},
						}
					];
					vm.init = function () {
						refreshList = false;
						// add or not cols
						if (!$scope.isToDuplicate) {
							updateColumns()
						}
						$scope.caracsDataGridOptions = {
							dataSource: $scope.caracs,
							paging: {
								enabled: true,
								pageSize: PAGINATIONS_SIZE,
							},
							pager: {
								showPageSizeSelector: true,
								allowedPageSizes: PAGINATIONS_SIZES,
								infoText: $rootScope.dxGridTextInfo,
								showInfo: true,
								visible: true,
							},
							filterRow: {
								visible: true,
								applyFilter: 'auto',
							},
							rowAlternationEnabled: true,
							headerFilter: {
								visible: true,
								applyFilter: 'auto',
							},
							scrolling: {
								rowRenderingMode: 'Standard',
								showScrollbar: 'onHover',
							},
							searchPanel: {
								visible: true,
								width: 240,
							},
							stateStoring: {
								enabled: true,
								type: 'localStorage',
								storageKey: (vm.isCaracSimple ? "carac_simple_" : "carac_link_") +  STORAGE_SUFFIX
							},
							filterPanel: {
								visible: true,
							},
							showColumnLines: true,
							showRowLines: true,
							allowColumnReordering: true,
							allowColumnResizing: true,
							columnAutoWidth: true,
							showBorders: true,
							enableFiltering: true,
							columnChooser: {
								enabled: true,
							},
							columnFixing: {
								enabled: true,
							},
							columns: columns,
							onInitialized: function (e) {
								$scope.gridInstance = e.component;
								// set tag lookups
								lookupTags = _.map(
									_.sortBy($scope.tags, function (i) {
										return i.label.toLowerCase();
									}),
									function (el) {
										return {'text': el.label, 'value': el.label};
									}
								);
								if ($scope.gridInstance && $scope.gridInstance.option) {
									let headerFilter = $scope.gridInstance.columnOption('tags', 'headerFilter');
									headerFilter.dataSource = lookupTags;
									setTimeout(function () {
									headerFilter.dataSource.unshift({'text': gettextCatalog.getString('grid.header.filter.blank'), 'value': null})
										$scope.gridInstance.columnOption('tags', 'headerFilter', headerFilter);
									}, 400);
								}
							},
						};

						if (!$scope.isCaracSimple) {
							$scope.caracsDataGridOptions.columns.splice(1, 1);
						} else {
						let codeCol = {
														dataField: 'code',
														caption: dataGirdColumnName.code,
														cellTemplate: 'code',
														allowHeaderFiltering: true,
														allowFiltering: true,
														enableCellEdit: false,
														width: '14%',
														visible: $scope.isCaracSimple,
													};
						$scope.caracsDataGridOptions.columns.splice($scope.caracsDataGridOptions.columns.length - 2, 0, codeCol);
						}
					};

					/*key checkbox*/
					$scope.setCaracAsCf = function (id, isCf) {
						CaracService.setCaracCf(id, isCf).then(function (response) {});
					};
					/*key checkbox*/

					/*htmlIcon*/
					$scope.getHtmlIconByType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList));
						}
					};
					/*htmlIcon*/

					$scope.checkPermissionData = {};

					$scope.onClickModifCarac = function (carac) {
						if ($scope.isToDuplicate) {
							return;
						}
						$scope.caracToEdit = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.showModifCarac;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					let eventHiddenSet = false;
					$scope.showModifCarac = function () {
						$scope.metadataLoaded = false;
						var node = $scope.caracToEdit;
						if (node.complex) {
							$scope.isSimple = 'false';
						} else {
							$scope.isSimple = 'true';
						}

						$scope.isNewCarac = false;
						$scope.selectedType = '';
						$scope.showSelectTypes = false;

						// store current object (displayed object)
						$scope.displayedCarac = node;

						$scope.carac = angular.copy(node);

						// laod existant tags on modification
						loadCaracTags($scope.carac.id);
						// load libelles
						CaracService.getLibellesLang($scope.carac.id).then(function (
							response
						) {
							if (response.data) {
								$scope.carac.lib = response.data.label;
								$scope.carac.label = response.data.label;
								$scope.carac.description = response.data.description;
								$scope.carac.comm = response.data.description;
								$scope.carac.entite_type.lib = node.complex
									? $scope.carac.entite_type.lib
									: $rootScope.simpleTypesTra[$scope.carac.entite_type.lib];
							}
						});

						// Set hdh format value
						for (var l in $scope.hdhFormats) {
							if ($scope.hdhFormats[l].value == $scope.carac.hdh_format.value) {
								$scope.carac.hdh_format = $scope.hdhFormats[l];
								break;
							}
						}

						$scope.selectedSimpleType = { elm: $scope.carac.entite_type.id };
						$scope.oldSimpleTypeId = $scope.carac.entite_type.id;
						$scope.oldSimpleTypeCode = $scope.carac.entite_type.code;

						$scope.buildIconUrl(node.icon_id);
						$scope.metadataLoaded = true;
						// set metadataLoaded to false
						if (!eventHiddenSet) {
							eventHiddenSet = true;
							let frmModalElm = $('#frmModal');
							frmModalElm.on('hidden.bs.modal', function (event) {
								if (event.target == frmModalElm[0]) {
									$scope.metadataLoaded = false;
								}
							});
						}
						$('#frmModal').modal('show');
					};

					$scope.meta = {
						imageURL: API_BASE_URL_BACKEND + 'pictogramme_image?id=',
					};

					function loadCaracTags(caracId) {
						TagService.getTagsByCaracId(caracId).then(function (response) {
							$scope.carac.tags = response.data;
						});
					}

					$scope.buildIconUrl = function (iconId) {
						if (iconId != undefined) {
							$scope.iconId = iconId;
						}
						$scope.iconId = $scope.iconId != undefined ? $scope.iconId : -1;
						$scope.imageURL =
							$scope.meta.imageURL +
							$scope.iconId;
						$scope.carac.icon_id = $scope.iconId;
					};

					/* save carac*/
					$scope.saveCarac = function (modalId) {
						if ($scope.isNewCarac) {
							createCarac(modalId);
						} else {
							editCarac(modalId, false);
						}
					};

					function createCarac(modalId) {
						// Remplir tags color.
						remplirTagsColorForCarac();

						// fill carac object (tree_node) before post.
						prepareCaracBeforeSaving();

						// http put service call to save changes on carac.
						CaracService.createCarac($scope.entite_type.id, $scope.carac).then(
							function (results) {
								$('#' + modalId).modal('hide');
								toaster.pop('success', toasterPopSucess, toasterCreationCarac);
								$scope.grammarChanged = true;
								CaracService.getCarac(results.data).then(function (response1) {
									var c = response1.data;
									c.formulas_quality_count = 0;
									$scope.caracs.unshift(c);
									$scope.caracsSimple.length = $scope.caracs.length;
									if ($scope.gridInstance && $scope.gridInstance.option) {
										$scope.gridInstance.option('dataSource', $scope.caracs);
									}
								});
							}
						);
					}

					// Fill colors for tags
					function remplirTagsColorForCarac() {
						if ($scope.carac.tags != undefined) {
							for (var i = 0; i < $scope.carac.tags.length; i++) {
								$scope.carac.tags[i].color =
									$scope.carac.tags[i].color != undefined
										? $scope.carac.tags[i].color
										: '#DDF663';
							}
						}
					}

					// fill carac object (tree_node) before saving (save or update)
					function prepareCaracBeforeSaving() {
						$scope.carac.entite_type = {};
						if ($scope.isSimple == 'true') {
							$scope.carac.entite_type.id = $scope.selectedSimpleType.elm;
						} else {
							if ($scope.type && $scope.type.selected) {
								$scope.carac.entite_type.id = $scope.type.selected.id;
							}
						}
					}

					$scope.confirmModifCarac = function (forcer) {
						$('#imcompatibleCaracTypeModal').modal('hide');
						// Remplir tags color.
						remplirTagsColorForCarac();

						// fill carac object (tree_node) before put.
						prepareCaracBeforeSaving();

						// http put service call to save changes on carac
						CaracService.editCarac($scope.entite_type.id, $scope.carac).then(
							function (response) {
								$('#frmModal').modal('hide');
								toaster.pop('success', toasterPopSucess, toasterEditCarac);
								$scope.grammarChanged = true;
								CaracService.getCarac($scope.carac.id).then(function (
									responseCaracsR
								) {
									copy(responseCaracsR.data, $scope.displayedCarac);
									for (var i in $scope.caracs) {
										if ($scope.caracs[i].id === $scope.displayedCarac.id) {
											$scope.caracs[i] = $scope.displayedCarac;
											$scope.caracs[i].data_type = getTypeFromEntiteTypeCode(
												$scope.caracs[i].entite_type.code
											);
											break;
										}
									}
									if ($scope.gridInstance && $scope.gridInstance.option) {
										$scope.gridInstance.option('dataSource', $scope.caracs);
									}
								});
							}
						);
					};

					function editCarac(modalId, forcerModif) {
						if ($scope.oldSimpleTypeId == $scope.selectedSimpleType.elm) {
							$scope.confirmModifCarac();
							return;
						}
						$scope.imcompatibleMappingType = false;
						$scope.usedInLinkMapping = false;
						TypeEntiteService.searchLinksByMappedCarac($scope.carac.id).then(
							function (response) {
								if (response.data[0]) {
									$scope.usedInLinkMapping = true;
									$scope.mappedLinks = response.data;
									$('#imcompatibleCaracTypeModal').modal('show');
									return;
								} else {
									var selectedType = _.filter(
										$scope.simpletypes,
										function (item) {
											return item.id == $scope.selectedSimpleType.elm;
										}
									)[0].code;

									if ($scope.carac.formula) {
										if (
											JSON.parse($scope.carac.formula).type !=
											getTypeFromEntiteTypeCode(selectedType)
										) {
											$scope.imcompatibleMappingType = true;
											$('#imcompatibleCaracTypeModal').modal('show');
											return;
										} else {
											$scope.confirmModifCarac();
										}
									} else {
										CaracFileMappingService.getMappingByCaracId(
											$scope.carac.id
										).then(function (responseMappingByCId) {
											for (var m in responseMappingByCId.data) {
												if (
													responseMappingByCId.data[m]
														.formula_data_source_grammar &&
													JSON.parse(
														responseMappingByCId.data[m]
															.formula_data_source_grammar
													)[0].type != getTypeFromEntiteTypeCode(selectedType)
												) {
													$scope.imcompatibleMappingType = true;
													$('#imcompatibleCaracTypeModal').modal('show');
													return;
												}
											}
											$scope.confirmModifCarac();
										});
									}
								}
							}
						);
					}

					/*edit */

					$scope.modes = {
						caracMode: true,
						qualityMode: false,
						refByLabel: false,
					};
					$scope.setIsValueTabFalseAndCaracMode = function (caracMode) {
						$scope.isValueTab = false;
						$scope.modes.caracMode = caracMode;
					};
					$scope.isFormula = {};
					/* show mapping*/
					$scope.showAddMappingModal = function (carac) {
						$scope.caracToEdit = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowAddMappingModal;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.doShowAddMappingModal = function () {
						var node = $scope.caracToEdit;
						$scope.caracToBeMappedId = node.id;
						$scope.caracToBeMappedLib = node.lib;
						$scope.caracToBeMapped = node;
						$scope.caracToBeMappedIsDate =
							node.entite_type.code == DATE_TYPE_CODE;
						$scope.caracToBeMappedIsGeometry =
							node.entite_type.code == GEOMETRY_TYPE_CODE;
						$scope.selection = {};
						$scope.patterns = {};
						$scope.timezones = {};
						$scope.dateLanguages = {};
						$scope.disabledTimezones = {};
						$scope.geoFormats = {};
						$scope.formDisplayed = {};
						$scope.form = {};
						$scope.availableTimezones = $rootScope.availableTimezones;
						$scope.availableDateLanguages = $rootScope.availableDateLanguages;
						CaracFileMappingService.getHeaders($scope.entite_type.id).then(
							function (response) {
								var generatedId = 0;
								var data = response.data;
								$scope.SourceHeaders = [];
								$scope.sourcesMap = {};
								$scope.sourcesIdsMap = {};
								angular.forEach(data, function (dataSource) {
									dataSource.original_id =
										dataSource.source_id + '_' + dataSource.link_id;
									dataSource.element_id = dataSource.source_id;
									dataSource.source_id = generatedId;
									dataSource.headers = dataSource.depot_headers;
									$scope.SourceHeaders.push(dataSource);
									generatedId++;
									$scope.sourcesMap[dataSource.original_id] =
										dataSource.source_id;
									$scope.sourcesIdsMap[dataSource.source_id] =
										dataSource.original_id;
								});
								$scope.headersMap = new Map(
									$scope.SourceHeaders.map((a) => [a.source_id, a])
								);
								CaracFileMappingService.getMappingByCaracId(
									$scope.caracToBeMappedId
								).then(function (responseCaracMapping) {
									var caracMapping = responseCaracMapping.data;
									for (var x in caracMapping) {
										var allFormulas = [];
										if (caracMapping[x].formula_data_source_grammar) {
											allFormulas = JSON.parse(
												caracMapping[x].formula_data_source_grammar
											);
										}

										var id = '';
										var uniqIdDataSource =
											caracMapping[x].id_data_source +
											'_' +
											caracMapping[x].link_id;
										if (!caracMapping[x].is_formula || !allFormulas[0]) {
											id = $scope.sourcesMap[uniqIdDataSource];
											var fileHeaders = $scope.headersMap.get(id);
											fileHeaders = fileHeaders != undefined ? fileHeaders : {};
											for (var i in fileHeaders.depot_headers) {
												if (
													caracMapping[x].header &&
													fileHeaders &&
													fileHeaders.depot_headers &&
													fileHeaders.depot_headers[i].id ==
														caracMapping[x].header.id
												) {
													$scope.selection[id] = fileHeaders.depot_headers[i];
													break;
												}
											}
											$scope.patterns[id] = caracMapping[x].pattern;
											$scope.timezones[id] = caracMapping[x].timezone;
											$scope.dateLanguages[id] = caracMapping[x].language;
											if (!$scope.patterns[id]) {
												$scope.patterns[id] = '';
											}
											$scope.data = {};
											for (var j in $scope.datePatterns) {
												if (
													$scope.patterns[id] == $scope.datePatterns[j].value
												) {
													$scope.data.selectedOption = $scope.datePatterns[j];
													break;
												}
											}
											if (!$scope.caracToBeMappedIsDate) {
												delete $scope.patterns[id];
												delete $scope.timezones[id];
												delete $scope.dateLanguages[id];
											}
											$scope.geoFormats[id] = caracMapping[x].geo_format;
											if (!$scope.geoFormats[id]) {
												$scope.geoFormats[id] = 'wkb';
											}
											if (!$scope.caracToBeMappedIsGeometry) {
												delete $scope.geoFormats[id];
											}
											$scope.isFormula[id] = false;
										} else {
											var formula = allFormulas[allFormulas.length - 1];
											id = $scope.sourcesMap[uniqIdDataSource];
											$scope.selection[id] = formula.column_alias;
											$scope.formDisplayed[id] = formula.column_alias;
											$scope.formulaGrammar[id] = allFormulas;
											$scope.isFormula[id] = true;
											$scope.form[id] = formula.expression_formule;
											$scope.headersByFile = {};
											angular.forEach($scope.SourceHeaders, function (element) {
												$scope.headersByFile[element.source_id] =
													element.headers;
											});
											var headers = '';
											for (var k in $scope.SourceHeaders) {
												if (
													$scope.SourceHeaders[k].original_id ==
													uniqIdDataSource
												) {
													headers = $scope.SourceHeaders[k].headers;
												}
											}
											$scope.initWidget(headers, allFormulas);
										}
									}
									$('#addMappingModal').modal('show');
								});
							}
						);
					};
					/*save*/
					$scope.preSaveDepotCaracMapping = function () {
						$scope.saveCaracDepotMapping();
					};
					$scope.saveCaracDepotMapping = function () {
						$scope.columnDetails = {};
						$scope.formulaDetails = {};
						var caracMappings = [];
						angular.forEach($scope.selection, function (column, sourceId) {
							var original_id = $scope.sourcesIdsMap[sourceId];
							var ids = original_id.split('_');
							var caracMapping = {
								uca_id: $scope.caracToBeMappedId,
								id_data_source: Number(ids[0]),
								link_id: Number(ids[1]),
								pattern: $scope.patterns[sourceId],
								timezone: $scope.timezones[sourceId],
								language: $scope.dateLanguages[sourceId],
								geo_format: $scope.geoFormats[sourceId],
							};
							if ($scope.isFormula[sourceId]) {
								$scope.formulaDetails[sourceId] = column;
								caracMapping.lib_data_source = $scope.form[sourceId];
								caracMapping.is_formula = true;
								caracMapping.formula_data_source_grammar = JSON.stringify(
									$scope.formulaGrammar[sourceId]
								);
							} else {
								$scope.columnDetails[sourceId] = column;
								caracMapping.lib_data_source = column.column;
								caracMapping.is_formula = false;
								caracMapping.header = column;
							}

							caracMappings.push(caracMapping);
						});

						CaracFileMappingService.saveCaracDepotMapping(
							caracMappings,
							$scope.caracToBeMappedId
						).then(function (response) {
							toaster.pop('success', toasterPopSucess, toasterEditMapping);

							var caracIndex = $scope.caracs.indexOf($scope.caracToBeMapped);
							$scope
								.getMappedCaracCount($scope.caracToBeMappedId)
								.then(function (data) {
									$scope.caracToBeMapped.mapping_count = data;
									$scope.caracs[caracIndex] = $scope.caracToBeMapped;
									$scope.caracToBeMapped = {};
								});

							$scope.selection = {};
							$scope.caracToBeMappedId = '';
						});

						$('#addMappingModal').modal('hide');
					};
					/*delete*/
					$scope.preResetAllSelectedRadioAndSave = function () {
						$scope.resetAllSelectedRadioAndSave();
					};
					$scope.resetAllSelectedRadioAndSave = function () {
						$scope.selection = {};
						$scope.patterns = {};
						$scope.timezones = {};
						$scope.disabledTimezones = {};
						$scope.saveCaracDepotMapping();
					};
					$scope.resetSelectedRadio = function (source_id, linkId) {
						var dbsKeys = Object.keys($scope.sourcesIdsMap);
						var depotId = source_id.toString();
						var is_db = dbsKeys.indexOf(depotId) != -1 ? true : false;
						var original_id = is_db
							? $scope.sourcesIdsMap[source_id]
							: $scope.filesSourcesIdsMap[source_id];
						var realId = original_id.split('_');
						CaracFileMappingService.deleteByCaracIdAndDepotId(
							$scope.caracToBeMappedId,
							Number(realId[0]),
							is_db,
							linkId
						).then(function () {
							delete $scope.selection[source_id];
						});
					};
					$scope.getMappedCaracCount = function (caracId) {
						return TypeEntiteService.getMappedCaracCount(caracId).then(
							function (response) {
								return response.data;
							}
						);
					};
					/* show mapping*/

					/*delete*/
					$scope.showDeleteCarac = function (carac) {
						$scope.caracToDelete = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteCarac();
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.doShowDeleteCarac = function () {
						$scope.caracDataBlocks = [];
						DataBlocksService.getCaracUsage(
							$scope.entite_type.id,
							$scope.caracToDelete.id
						).then(function (response) {
							$scope.caracDataBlocks = response.data;
							$scope.elementToDelete = $scope.caracToDelete;
							$(
								'#' + ($scope.isCaracSimple ? 'delModalSimple' : 'delModalLink')
							).modal('show');
						});
					};

					$scope.deleteCarac = function (reloadData, modalId) {
						CaracService.deleteCarac($scope.elementToDelete.id).then(function (
							response
						) {
							$('#' + modalId).modal('hide');
							toaster.pop(
								'success',
								toasterPopSucess,
								$scope.elementToDelete.complex
									? toasterDeleteLink
									: toasterDeleteCarac
							);

							// Load Caracs
							TypeEntiteService.getCaracs(
								$scope.entite_type.id,
								$scope.isCaracSimple,
								true
							).then(function (responseCa) {
								$scope.caracsComplex = [];
								$scope.caracsSimple = [];
								angular.forEach(responseCa.data, function (carac, key) {
									if (carac.complex) {
										$scope.caracsComplex.push(carac);
									} else {
										$scope.caracsSimple.push(carac);
									}
								});
								if ($scope.isCaracSimple) {
									$scope.caracs = $scope.caracsSimple;
								} else {
									$scope.caracs = $scope.caracsComplex;
								}
								if ($scope.gridInstance && $scope.gridInstance.option) {
									$scope.gridInstance.option('dataSource', $scope.caracs);
								}
							});
						});
					};
					/*delete*/

					/**********************carac complex********************************/
					/*edit*/
					$scope.linkCreatorData = {};
					$scope.showAddCaracComplexMappingModal = function (carac) {
						$scope.caracToEdit = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'link';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowAddCaracComplexMappingModal;
						$scope.checkPermissionData.showPermissionCheck = true;
					};
					$scope.doShowAddCaracComplexMappingModal = function () {
						$scope.entiteTypeSourceId = $scope.entite_type.id;
						$scope.entiteTypeSourceLib = $scope.entite_type.label;
						$scope.caracsComplexList = $scope.caracsComplex;
						$scope.caracToEdit.lanCode = $scope.selectedLangCarac.code;
						$scope.node = $scope.caracToEdit;
						$scope.linkCreatorData.entiteTypeSourceId =
							$scope.entiteTypeSourceId;
						$scope.linkCreatorData.entiteTypeSourceCode =
							$scope.entite_type.code;
						$scope.linkCreatorData.entiteTypeSourceLib =
							$scope.entiteTypeSourceLib;
						$scope.linkCreatorData.caracsComplexList = $scope.caracsComplexList;
						$scope.linkCreatorData.node = $scope.node;
						$scope.linkCreatorData.showLinkCreator = true;
					};
					/*edit*/

					/*delete*/
					$scope.showDeleteLink = function (carac) {
						$scope.caracToDelete = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'link';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteCarac;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.showDeleteCarac = function (carac) {
						$scope.caracToDelete = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteCarac;
						$scope.checkPermissionData.showPermissionCheck = true;
					};
					$scope.doShowDeleteCarac = function () {
						$scope.caracDataBlocks = [];
						DataBlocksService.getCaracUsage(
							$scope.entite_type.id,
							$scope.caracToDelete.id
						).then(function (response) {
							$scope.caracDataBlocks = response.data;
							$scope.elementToDelete = $scope.caracToDelete;
							$(
								'#' + ($scope.isCaracSimple ? 'delModalSimple' : 'delModalLink')
							).modal('show');
						});
					};
					/**********************carac complex********************************/

					/* show mapping*/
					$scope.bulkMappingScope = { showBulkMappingFlag: false };
					$scope.showBulkMapping = function () {
						$scope.entiteTypeData = {};
						$scope.entiteTypeData.entiteType = $scope.entite_type;
						$scope.bulkMappingScope.showBulkMappingFlag = true;
						$scope.bulkMappingScope.enititeTypeId =
							$scope.entiteTypeData.entiteType.id;
						$scope.bulkMappingScope.showFormule = $scope.showFormule;
					};

					$scope.widgetData = {};
					$scope.showFormule = function (
						caracs,
						keepOldData,
						fileId,
						referencingMode
					) {
						$scope.selectedfileId = fileId;
						if (!keepOldData || $scope.widgetData.widget == undefined) {
							var widget = {};
							widget.grammar = {};
							widget.grammar.columns = [];
							for (var c in caracs) {
								var c_ =
									caracs[c].column != undefined ? caracs[c].column : caracs[c];
								var t_ =
									caracs[c].data_type != undefined
										? caracs[c].data_type
										: 'string';
								var col = {
									column_alias: c_,
									field: c_,
									type: formulasTypesMap(t_),
									lib: c_,
									alias: 'COLL' + c,
								};
								widget.grammar.columns.push(col);
							}
							$scope.widgetData.widget = widget;
							$scope.dbBuilder.columns = widget.grammar.columns;
							$scope.modes.caracMode = false;
							$scope.modes.refByLabel =
								referencingMode === LABEL_HEADERS_REF_MODE;
							$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
							$scope.widgetData.showFormule = true;
							$scope.widgetData.widgetMenuOpenAction = {};
							$scope.widgetData.widgetMenuData = {};
							$scope.widgetData.hideWidgetMenu = true;
							var typeToFilter = $scope.caracTypeToFormulaType(
								$scope.caracToBeMapped.entite_type.code
							);
							$scope.widgetData.rootFunctionType =
								typeToFilter != undefined ? typeToFilter : 'string';
							$scope.widgetByFile[$scope.selectedfileId] =
								$scope.widgetData.widget;
						} else {
							$scope.initWidget(
								$scope.headersByFile[$scope.selectedfileId],
								$scope.formulaGrammar[$scope.selectedfileId]
							);
							$scope.modes.caracMode = false;
							$scope.modes.refByLabel =
								referencingMode === LABEL_HEADERS_REF_MODE;
							$scope.widgetData.showFormule = true;
						}
					};

					$scope.openDisplayDataModal = function (
						depot,
						depotType,
						selectedColumn
					) {
						if (!depot || !depotType) {
							return;
						}
						$('#addMappingModal').modal('hide');
						var locals = {
							depot: depot,
							depotType: depotType,
							selectedColumn: selectedColumn,
						};
						ModalService.open({
							controllerAs: 'vm',
							bindToController: true,
							templateUrl:
								'./src/components/modules/depot/data-depot-displayer/display-data-from-depot.html',
							controller: 'DisplayDataFromDepotController',
							disableBackdrop: true,
							locals: locals,
						})
							.then(function (depot) {})
							.finally(function () {
								$('#addMappingModal').modal('show');
							});
					};

					$scope.initWidget = function (headers, formula) {
						var widget = {};
						widget.grammar = {};
						widget.grammar.columns = [];
						for (var c in headers) {
							var c_ =
								headers[c].column != undefined ? headers[c].column : headers[c];
							var t_ =
								headers[c].data_type != undefined
									? headers[c].data_type
									: 'string';
							var col = {
								column_alias: c_,
								type: formulasTypesMap(t_),
								field: c_,
								lib: c_,
								alias: 'COLL' + c,
							};
							widget.grammar.columns.push(col);
						}
						$scope.widgetData.widget = widget;
						$scope.dbBuilder.columns = widget.grammar.columns;
						$scope.widgetData.widgetMenuOpenAction = {};
						$scope.widgetData.widgetMenuData = {};
						$scope.widgetData.hideWidgetMenu = true;
						$scope.widgetData.rootFunctionType = undefined;

						$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
						for (var i = 0; i < formula.length; i++) {
							$scope.widgetData.widget.grammar.columns[
								$scope.widgetData.widget.grammar.columns.length
							] = formula[i];
						}
						$scope.widgetData.widget.grammar.columnsTemp = angular.copy(
							$scope.widgetData.widget.grammar.columns
						);
					};

					$scope.formulaGrammar = {};

					$scope.saveFormuleEditeur = function (widget) {
						var objectFormSize =
							$scope.widgetData.widget.grammar.columnsTemp.length - 1;
						$scope.widgetColumnsTemp =
							widget.grammar.columnsTemp[objectFormSize];
						$scope.form[$scope.selectedfileId] =
							widget.grammar.columnsTemp[objectFormSize].expression_formule;
						$scope.formDisplayed[$scope.selectedfileId] =
							widget.grammar.columnsTemp[objectFormSize].column_alias;
						if ($scope.selectedfileId != null) {
							$scope.selection[$scope.selectedfileId] =
								$scope.formDisplayed[$scope.selectedfileId];
							$scope.isFormula[$scope.selectedfileId] = true;
						}
						$scope.formulaGrammar[$scope.selectedfileId] = [];
						$scope.widgetData.widget.grammar.columns = angular.copy(
							$scope.widgetData.widget.grammar.columnsTemp
						);
						for (
							var i = 0;
							i < $scope.widgetData.widget.grammar.columnsTemp.length;
							i++
						) {
							var column = $scope.widgetData.widget.grammar.columnsTemp[i];
							if (column.col != undefined) {
								$scope.formulaGrammar[$scope.selectedfileId].push(column);
							}
						}
						$scope.widgetByFile[$scope.selectedfileId] =
							$scope.widgetData.widget;
						$scope.widgetData.hideWidgetMenu = true;
					};

					$scope.isFormula = {};

					$scope.widgetByFile = {};

					$scope.checkIfFormula = function (selectedValue) {
						if (selectedValue != $scope.formDisplayed[$scope.selectedfileId]) {
							$scope.isFormula[selectedValue] = false;
						}
					};

					/*add new carac*/
					TypeEntiteService.getSimpleTypes().then(function (response) {
						$scope.simpletypes = $rootScope.translateSimpleTypes(response.data);
						$scope.dateTypeId = _.filter($scope.simpletypes, function (item) {
							return item.code == DATE_TYPE_CODE;
						})[0].id;
					});

					$scope.onClickNewCarac = function () {
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted = $scope.openNewCarac;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.openNewCarac = function () {
						$scope.isSimple = 'true';
						delete $scope.selectedSimpleType;
						$scope.isNewCarac = true;
						$scope.showSelectTypes = true;
						resetCarac();
						$scope.selectedSimpleType = { elm: $scope.simpletypes[0].id };
						$scope.carac.poids = 100;
						$scope.carac.hdh_format = $scope.hdhFormats[1];
						$scope.metadataLoaded = true;
						$('#frmModal').modal('show');
					};

					function resetCarac() {
						$rootScope.fields = [];
						$scope.carac = {};
						$scope.carac.poids = 0;
						$scope.props = [];
						$scope.type.selected = undefined;
						$scope.selectedType = undefined;
					}

					/*add new link*/
					$scope.showAddCaracLinkMappingDirective = function () {
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'link';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowAddCaracLinkMappingDirective;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.doShowAddCaracLinkMappingDirective = function () {
						$scope.entiteTypeSourceId = $scope.entite_type.id;
						$scope.entiteTypeSourceLib = $scope.entite_type.label;
						$scope.caracsComplexList = $scope.caracsComplex;

						$scope.linkCreatorData.entiteTypeSourceId =
							$scope.entiteTypeSourceId;
						$scope.linkCreatorData.entiteTypeSourceCode =
							$scope.entite_type.code;
						$scope.linkCreatorData.entiteTypeSourceLib =
							$scope.entiteTypeSourceLib;
						$scope.linkCreatorData.caracsComplexList = $scope.caracsComplexList;
						$scope.linkCreatorData.showLinkCreator = true;
					};

					/*refresh link list*/
					$scope.refreshCaracList = function () {
						$scope.showList = false;
						if ($scope.vm.isCaracSimple) {
							$scope.getCaracsSimple();
						} else {
							$scope.getCaracsComplex();
						}
					};

					/* save link*/
					// Update mapping count on links and add newly created carac
					$scope.updateLink = function (node, newNode) {
						if (newNode != undefined) {
							node = newNode;
							$scope.caracsComplex.push(node);
						}

						if ($scope.gridInstance && $scope.gridInstance.option) {
							$scope.gridInstance.option('dataSource', $scope.caracsComplex);
						}

						if (node != undefined && node.id != undefined) {
							$scope.getLinksCount(node.id).then(function (data) {
								for (var t = 0; t < $scope.caracsComplex.length; t++) {
									if ($scope.caracsComplex[t].id == node.id) {
										$scope.caracsComplex[t].mapping_count = data;
										break;
									}
								}
							});
						}
					};
					$scope.getLinksCount = function (caracId) {
						return TypeEntiteService.getLinksCountByCarac(caracId).then(
							function (response) {
								return response.data;
							}
						);
					};

					/*edit type*/
					$scope.selectType = function () {
						if ($scope.isSimple == 'true') {
							$scope.selectedSimple = $scope.carac.entite_type.id;
						} else {
							$scope.type.selected = undefined;
							//$scope.$broadcast('uiSelectTypes');
						}
						$scope.showSelectTypes = true;
					};
					$scope.cancelEditType = function () {
						// $scope.type.selected = undefined;
						$scope.$broadcast('uiSelectTypes');
						$scope.showSelectTypes = false;
					};

					$scope.$watch(
						'caracsSimple',
						function (newValue) {
							if ($scope.isCaracSimple && newValue && refreshList)
								$scope.entite_type.caracsSimpleLength =
									newValue && Array.isArray(newValue) ? newValue.length : 0;
						},
						true
					);

					$scope.$watch(
						'caracsComplex',
						function (newValue) {
							if (!$scope.isCaracSimple && newValue && refreshList) {
								$scope.entite_type.caracsComplexLength =
									newValue && Array.isArray(newValue) ? newValue.length : 0;
							}
						},
						true
					);

					$scope.widgetByFile = {};
					$scope.caracTypeToFormulaType = function (caracType) {
						switch (caracType) {
							case 'SIMPLE_TYPES_STRING_ITEM':
								return 'string';
							case 'SIMPLE_TYPES_INTEGER_ITEM':
								return 'integer';
							case 'SIMPLE_TYPES_BIG_INTEGER_ITEM':
								return 'big_integer';
							case 'SIMPLE_TYPES_BOOLEAN_ITEM':
								return 'boolean';
							case 'SIMPLE_TYPES_DECIMAL_ITEM':
								return 'decimal';
							case 'SIMPLE_TYPES_DATE_ITEM':
								return 'date';
							case 'TYPES_WORDS_ITEM':
								return 'words';
							case 'TYPES_FILE_ITEM':
								return 'file';
							case 'TYPES_FILE_ITEM':
								return 'file';
							case 'TYPES_BINARY_ITEM':
								return 'binary';
							case 'TYPES_GEOMETRY_ITEM':
								return 'geometry';
							default:
								return 'string';
						}
					};

					$scope.dbBuilder = {};
					$scope.showFormulaEditorOnCarac = function (carac) {
						$scope.caracToEdit = carac;
						$scope.checkPermissionData.elementId = $scope.entite_type.id;
						$scope.checkPermissionData.type = 'entity';
						$scope.checkPermissionData.action = 'carac';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowFormulaEditorOnCarac;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.doShowFormulaEditorOnCarac = function () {
						var node = $scope.caracToEdit;
						$scope.modes = {
							caracMode: true,
							qualityMode: false,
						};
						$scope.caracFormulaEdition = node;
						var widget = {};
						widget.grammar = {};
						widget.grammar.columns = [];
						for (var c in $scope.caracs) {
							if (node.code != $scope.caracs[c].code) {
								var col = {
									column_alias: $scope.caracs[c].lib,
									lib: $scope.caracs[c].lib,
									type:
										$scope.caracs[c] != undefined &&
										$scope.caracs[c].entite_type != undefined &&
										$scope.caracs[c].entite_type.code != undefined
											? $scope.caracTypeToFormulaType(
													$scope.caracs[c].entite_type.code
											  )
											: 'string',
									field: $scope.caracs[c].lib,
									value: $scope.caracs[c].code,
									alias: $scope.caracs[c].code,
									list: $scope.caracs[c].list,
									isVirtual:
										$scope.caracs[c].hdh_format &&
										$scope.caracs[c].hdh_format.value &&
										$scope.caracs[c].hdh_format.value == 'FORMULA',
								};
								widget.grammar.columns.push(col);
							}
						}
						if (
							$scope.caracFormulaEdition.formula != undefined &&
							$scope.caracFormulaEdition.formula != ''
						) {
							widget.grammar.columns.push(
								JSON.parse($scope.caracFormulaEdition.formula)
							);
						}
						var typeToFilter = $scope.caracTypeToFormulaType(
							node.entite_type.code
						);
						$scope.widgetData.widget = widget;
						$scope.dbBuilder.columns = widget.grammar.columns;
						$scope.datablockForFormula = widget;
						$scope.widgetData.rootFunctionType =
							typeToFilter != undefined ? typeToFilter : 'string';
						$scope.widgetData.isReturnList = node.list;
						$scope.widgetData.widget.grammar.columnsTemp = angular.copy(
							$scope.widgetData.widget.grammar.columns
						);
						$scope.widgetData.updateaction =
							$scope.showReloadConfirmationSaveCaracformula;
						$scope.initialWidgetLength =
							$scope.widgetData.widget.grammar.columns.length;
						$scope.widgetData.widgetMenuOpenAction = {};
						$scope.widgetData.widgetMenuData = {};
						$scope.widgetData.hideWidgetMenu = true;
						$scope.widgetData.showFormule = true;
					};

					$scope.showReloadConfirmationSaveCaracformula = function () {
						if (
							$scope.initialWidgetLength >
							$scope.widgetData.widget.grammar.columns.length
						) {
							$scope.caracFormulaEdition.formula = null;
						} else {
							$scope.caracFormulaEdition.formula = JSON.stringify(
								$scope.widgetData.widget.grammar.columns[
									$scope.widgetData.widget.grammar.columns.length - 1
								]
							);
						}
						$scope.widgetData.showFormule = false;
						$scope.saveCaracFormuleEditeur();
					};

					$scope.saveCaracFormuleEditeur = function (reload) {
						var data = {
							id: $scope.caracFormulaEdition.id,
							formula: $scope.caracFormulaEdition.formula,
						};
						CaracService.saveFormula(data).then(function (reponse) {
							toaster.pop('success', toasterPopSucess, toasterSaveCaracFormule);
							for (var c in $scope.caracs) {
								if ($scope.caracs[c].id == $scope.caracFormulaEdition.id) {
									$scope.caracs[c] = $scope.caracFormulaEdition;
									$scope.caracs[c].mapping_count = 0;
									break;
								}
							}
						});
					};

					$scope.cancelModifCarac = function () {
						$('#imcompatibleCaracTypeModal').modal('hide');
					};

					$scope.checkError = function (fieldName) {
						return $rootScope.checkError(fieldName);
					};

					$scope.metadataConfig = {
						htmlId: 'carac',
						hideActive: true,
						hideLicence: true,
					};

					$scope.getParentTypeLib = function (lib) {
						return $rootScope.simpleTypesTra[lib]
							? $rootScope.simpleTypesTra[lib]
							: lib;
					};

					$scope.tagBox = {
						displayExpr: 'code',
						valueExpr: 'id',
						searchEnabled: false,
						editEnabled: false,
						tagTemplate: 'tagTemplate',
					};
					$scope.getTagBox = function (list) {
						$scope.tagBox.value = list.data.tags;

						return $scope.tagBox;
					};

					// refresh mapping count
					$scope.$on(UPDATE_EVENT['CARAC_MAPPING'], function (event) {
						$scope.getCaracsSimple();
					});

					// validate date mapping
					$scope.validateTimezoneOnPatternChange = function(sourceId) {
						if ($scope.patterns[sourceId] === "ISO 8601") {
							$scope.timezones[sourceId] = undefined;
							$scope.disabledTimezones[sourceId] = true;
						} else {
							$scope.disabledTimezones[sourceId] = false;
						}
					}

					$scope.validateTimezoneOnPatternChangeSelect = function (item, model, label, event, sourceId) {
						event.stopPropagation();
						$scope.validateTimezoneOnPatternChange(sourceId);
					}

					function updateColumns() {
						// if not to duplicate add keys and actions cols
						columns.push({
							caption: dataGirdColumnName.actions,
							alignment: 'center',
							cellTemplate: 'actions',
							width: '150px',
							allowReordering: false,
						});
						columns.splice(1, 0, {
							caption: dataGirdColumnName.key,
							cellTemplate: 'keys',
							dataField: 'cf',
							width: '5%',
							alignment: 'left',
							allowReordering: false,
							allowFiltering: false,
							allowSorting: false,
						});
					}
				},
			];
			return {
				restrict: 'E',
				scope: {
					entite: '=',
					isCaracSimple: '=',
					istoduplicate: '=',
					parentScope: '=',
					caracsSimpleLength: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl: './src/components/directives/caracList/caracList.html',
				link: function (scope, element, attrs) {
					scope.entite_type = scope.vm.entite;
					scope.isCaracSimple = scope.vm.isCaracSimple;
					scope.isToDuplicate = scope.vm.istoduplicate;
					scope.vm.init();
					if (scope.isCaracSimple) {
						scope.vm.parentScope.getSimpleCaracs = function () {
							return scope.caracsSimple;
						};
						scope.getCaracsSimple();
					} else {
						scope.vm.parentScope.getComplexCaracs = function () {
							return scope.caracsComplex;
						};
						scope.getCaracsComplex();
					}
				},
			};
		},
	]);
})();
