angular
	.module('dcApp')
	.controller('cannotDeleteProjectController', ['$scope', function ($scope) {}])
	.directive('cannotDeleteProjectModal', function () {
		var controller = ['$scope', function ($scope) {}];
		return {
			restrict: 'AECM',
			templateUrl:
				'./src/components/modules/project/deleteProject/cannotDeleteProject.html',
			replace: true,
			scope: {
				projectName: '=',
			},
			link: function (scope) {},
		};
	});
