(function () {
	'use strict';

	angular.module('dcApp').directive('depotList', [
		'$parse',
		function ($parse) {
			let controller = [
				'$log',
				'$scope',
				'$rootScope',
				'$state',
				'DepotService',
				'$filter',
				'$window',
				'API_BASE_URL_BACKEND',
				'DbSQLDepotService',
				'CsvDepotService',
				'ParquetDepotService',
				'JsonDepotService',
				'BinaryDepotService',
				'ExcelDepotService',
				'toaster',
				'$q',
				'PermissionService',
				'AllDepotService',
				'XmlDepotService',
				'MongodbDepotService',
				'Neo4jDepotService',
				'gettextCatalog',
				'UPDATE_EVENT',
				'DcElementListService',
				function (
					$log,
					$scope,
					$rootScope,
					$state,
					DepotService,
					$filter,
					$window,
					API_BASE_URL_BACKEND,
					DbSQLDepotService,
					CsvDepotService,
					ParquetDepotService,
					JsonDepotService,
					BinaryDepotService,
					ExcelDepotService,
					toaster,
					$q,
					PermissionService,
					AllDepotService,
					XmlDepotService,
					MongodbDepotService,
					Neo4jDepotService,
					gettextCatalog,
					UPDATE_EVENT,
					DcElementListService
				) {
					let toasterPopSucess = gettextCatalog.getString('Succès');
					let toasterPopActivated = gettextCatalog.getString(
						'Activation effectuée'
					);
					let toasterPopDeactivated = gettextCatalog.getString(
						'Désactivation effectuée'
					);

					// depot type
					let depotSqlType = gettextCatalog.getString('Syntaxe SQL');
					let depotCsvType = gettextCatalog.getString(
						'Fichier avec séparateur'
					);
					let depotParquetType = gettextCatalog.getString('Fichier Parquet');
					let depotJsonType = gettextCatalog.getString('Fichier JSON');
					let depotExcelType = gettextCatalog.getString('Fichier Excel');
					let depotBinaryType = gettextCatalog.getString(
						'Fichier Binaire (Tous fichiers)'
					);
					let depotXmlType = gettextCatalog.getString('Fichier XML');
					let depotMongodbType = gettextCatalog.getString('Collection MongoDB');
					let depotNeo4jType = gettextCatalog.getString('depot.reader.no4j');

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.showCurrentProjectOnly = true;
					$scope.activeElementsOnly = false;
					$scope.checkPermissionData = {};
					$scope.depotListConf = {};
					$scope.depotListConf.disableAllSelectedElems = true;
					$scope.currentProjectId = $rootScope.currentProjectId;
					$scope.disableAllSelectedElems = true;

					// Consts
					const csvFile = depotCsvType;
					const parquetFile = depotParquetType;
					const jsonFile = depotJsonType;
					const excelFile = depotExcelType;
					const binaryFile = depotBinaryType;
					const xmlFile = depotXmlType;
					const sqlSyn = depotSqlType;
					const mongodbCollection = depotMongodbType;
					const neo4j = depotNeo4jType;

					$scope.$on(UPDATE_EVENT['DEPOT'], function (event) {
						$scope.refreshDepotList();
					});

					$scope.init = function () {
						$scope.active = true;
						PermissionService.haveGlobalPermission('data_source').then(
							function (response) {
								$scope.data_source_authorised = response.data;
								if ($scope.data_source_authorised) {
									$scope.connectorId = $scope.vm.connectorId;
									$scope.typeDepotList = $scope.vm.typeDepotList;
									if (
										$scope.typeDepotList == 'connector' &&
										$scope.connectorId != undefined
									) {
										$scope.getLinkedDepots();
									} else if ($scope.typeDepotList == 'depot') {
										$scope.getGridData();
									}
								}
							}
						);

						PermissionService.haveGlobalPermission('data_source-create').then(
							function (response) {
								$scope.create_authorised = response.data;
							}
						);
					};

					/* depotList for connector */
					$scope.getLinkedDepots = function () {
						$scope.showDepotGrid = false;
						AllDepotService.findAllDepotsByConnectorId(
							$scope.connectorId,
							undefined,
							undefined,
							false
						).then(function (response) {
							if (response.data !== undefined) {
								let content = response.data;
								$scope.fillDepotList(content);
							}
						});
					};
					/* depotList for connector */

					$scope.getGridData = function () {
						$scope.showDepotGrid = false;
						getListDepots().then(function (data) {
							if (data != undefined) {
								$scope.fillDepotList(data);
							}
						});
					};

					/* depotList for depot */
					function getListDepots() {
						let deferred = $q.defer();
						AllDepotService.findAllDepots(
							undefined,
							undefined,
							undefined,
							$scope.showCurrentProjectOnly,
							$scope.activeElementsOnly
						).then(
							function (response) {
								deferred.resolve(response.data);
							},
							function (err) {
								$log.error(err);
								deferred.reject(err);
							}
						);
						return deferred.promise;
					}
					/* depotList for depot */

					$scope.fillDepotList = function (content) {
						$scope.depots = [];
						$scope.tags = [];
						let tags = [];
						for (let i in content) {
							content[i].connector =
								content[i].connector != undefined
									? content[i].connector
									: { connector_type: 'LOCAL' };
							let connectorLib =
								content[i].connector.connector_type == 'LOCAL'
									? 'Mode Local'
									: content[i].connector.metadata.label;
							$scope.depots.push({
								id: content[i].id,
								code: content[i].metadata.code,
								lc: content[i].metadata.label,
								depot_lib:
									content[i].depot_type in $scope.readerTypesMap
										? $scope.readerTypesMap[content[i].depot_type]
										: '-',
								depot_type: getDepotReaderParentType(content[i].depot_type),
								connector_lib:
									connectorLib +
									' (' +
									$filter('connectorTranslator')(
										content[i].connector.connector_type
									) +
									')',
								connector_type: content[i].connector.connector_type,
								active: content[i].metadata.actif,
								metadataId: content[i].metadata.id,
								imported: content[i].metadata.imported,
								icon_id: content[i].metadata.icon_id,
								desc: content[i].metadata.description,
								connectorId: content[i].connector.id,
								tags: content[i].metadata.tags,
								count_uets:
									content[i].linked_uet_count != undefined
										? content[i].linked_uet_count
										: 0,
								tagAsArray: $rootScope.mapTags(content[i].metadata.tags),
								creationDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.creation_date
								),
								updateDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.update_date
								),
								createdBy: content[i].metadata.created_by,
								updatedBy: content[i].metadata.updated_by,
								project: content[i].metadata.project,
							});
							tags = _.union(tags, content[i].metadata.tags);
						}

						for (let i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}

						$scope.gridData = angular.copy($scope.depots);
						$scope.dataGridOptions.dataSource = $scope.gridData;
						$scope.showDepotGrid = true;
					};

					// Get DxGrid dataGridOptions
					DcElementListService.getDcElementDxList($scope, 'depot', false);

					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});

					$scope.readerTypesMap = {
						SQL: sqlSyn,
						MONGODB: mongodbCollection,
						NEO4J: neo4j,
						CSV: csvFile,
						S3CSV: csvFile,
						SFTPCSV: csvFile,
						HTTP_CSV: csvFile,
						HDFS_CSV: csvFile,
						PARQUET: parquetFile,
						SFTP_PARQUET: parquetFile,
						HTTP_PARQUET: parquetFile,
						S3_PARQUET: parquetFile,
						HDFS_PARQUET: parquetFile,
						JSON: jsonFile,
						SFTP_JSON: jsonFile,
						HDFS_JSON: jsonFile,
						S3_JSON: jsonFile,
						HTTP_JSON: jsonFile,
						EXCEL: excelFile,
						SFTP_EXCEL: excelFile,
						HDFS_EXCEL: excelFile,
						HTTP_EXCEL: excelFile,
						BINARY: binaryFile,
						S3_BINARY: binaryFile,
						SFTP_BINARY: binaryFile,
						HDFS_BINARY: binaryFile,
						XML: xmlFile,
						S3_XML: xmlFile,
						SFTP_XML: xmlFile,
						HDFS_XML: xmlFile,
						HTTP_XML: xmlFile,
					};

					$scope.readerTypes = [
						{
							value: csvFile,
							label: csvFile,
						},
						{
							value: sqlSyn,
							label: sqlSyn,
						},
						{
							value: mongodbCollection,
							label: mongodbCollection,
						},
						{
							value: parquetFile,
							label: parquetFile,
						},
						{
							value: jsonFile,
							label: jsonFile,
						},
						{
							value: excelFile,
							label: excelFile,
						},
						{
							value: binaryFile,
							label: binaryFile,
						},
						{
							value: xmlFile,
							label: xmlFile,
						},
					];

					$scope.checkPermissionData = {};

					/* edit depot*/
					$scope.editDepot = function (obj) {
						$scope.objToEdit = obj;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = 'data_source';
						$scope.checkPermissionData.action = 'edit';
						$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.goToEdit = function () {
						$state.go('depots-edit', {
							id: $scope.objToEdit.id,
							type: $scope.objToEdit.depot_type,
						});
					};
					/* edit depot*/

					/* create type with this depot*/
					$scope.showCreateUet = function (depot) {
						$scope.showMapping(depot);
					};

					$scope.reloadDataConfirmation = {};
					$scope.entiteFromDepot = {};
					$scope.entiteFromDepot.show = false;
					$scope.showMapping = function (depot) {
						$scope.entiteFromDepot.depotType = depot.depot_type;
						$scope.entiteFromDepot.depotId = depot.id;
						$scope.entiteFromDepot.lib = depot.lc;
						$scope.entiteFromDepot.updateAction = $scope.goToCreatedUet;
						$scope.entiteFromDepot.show = true;
					};

					$scope.goToCreatedUet = function (uetId) {
						if (uetId) {
							const url = $state.href('entites-edit', {
								entiteId: uetId,
							});
							$window.open(url, '_blank');
							return;
						}
					};
					/* create type with this depot*/

					/* delete ce depot*/
					function refreshListAfterDelete() {
						if (
							$scope.typeDepotList == 'connector' &&
							$scope.connectorId != undefined
						) {
							$scope.getLinkedDepots();
						} else if ($scope.typeDepotList == 'depot') {
							$scope.getGridData();
						}
						$scope.gridInstance.clearSelection();
					}

					$scope.bulkDelete = false;
					$scope.showDeleteModal = function (obj) {
						$scope.objToDelete = obj;
						$scope.bulkDelete = false;
						$scope.checkPermissionData.elementId = obj.id;
						$scope.checkPermissionData.type = 'data_source';
						$scope.checkPermissionData.action = 'delete';
						$scope.checkPermissionData.actionIfPermitted =
							$scope.doShowDeleteModal;
						$scope.checkPermissionData.showPermissionCheck = true;
					};

					$scope.deleteElementData = {};
					$scope.doShowDeleteModal = function (count) {
						$scope.deleteElementData = {
							label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
							doDelete: $scope.bulkDelete
								? $scope.deleteselectedElems
								: $scope.deleteDepot,
							showConfirmDeleteElement: true,
							bulk: $scope.bulkDelete,
							count: count,
						};
					};

					function actionsAfterDeleteDepot(type) {
						toaster.pop(
							'success',
							'Succès',
							gettextCatalog.getString('Suppression du Dépôt') +
								type +
								' ' +
								gettextCatalog.getString('effectuée')
						);
						refreshListAfterDelete();
					}

					$scope.deleteDepot = function () {
						if ($scope.objToDelete.depot_type == 'SQL') {
							DbSQLDepotService.deleteDbDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(sqlSyn);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'MONGODB') {
							MongodbDepotService.deleteMongodbDepot(
								$scope.objToDelete.id
							).then(function (response) {
								actionsAfterDeleteDepot(mongodbCollection);
							});
						} else if ($scope.objToDelete.depot_type == 'NEO4J') {
							Neo4jDepotService.deleteNeo4jDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(neo4j);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'CSV') {
							CsvDepotService.deleteCsvDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(csvFile);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'PARQUET') {
							ParquetDepotService.deleteParquetDepot(
								$scope.objToDelete.id
							).then(function (response) {
								actionsAfterDeleteDepot(parquetFile);
							});
						} else if ($scope.objToDelete.depot_type == 'JSON') {
							JsonDepotService.deleteJsonDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(jsonFile);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'BINARY') {
							BinaryDepotService.deleteBinaryDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(binaryFile);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'EXCEL') {
							ExcelDepotService.deleteExcelDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(excelFile);
								}
							);
						} else if ($scope.objToDelete.depot_type == 'XML') {
							XmlDepotService.deleteXmlDepot($scope.objToDelete.id).then(
								function (response) {
									actionsAfterDeleteDepot(xmlFile);
								}
							);
						}
					};

					/* delete ce depot*/
					$scope.deleteselectedElems = function (modalId) {
						let depotIds = _.map($scope.selectedElems, function (elm) {
							return elm.id;
						});
						AllDepotService.deleteDepots(depotIds)
							.then(function () {
								toaster.pop(
									'success',
									toasterPopSucess,
									$scope.toasterPopDeleteSelectedDepot
								);
								refreshListAfterDelete();
							})
							.catch(function (e) {
								if (e && e.data && e.data.code == '40') {
									refreshListAfterDelete();
								}
							});
					};
					/* delete multiple depot*/
					$scope.showDeleteSelectedConfirmation = function (modalId) {
						$scope.bulkDelete = true;
						$scope.doShowDeleteModal($scope.selectedElems.length);
					};

					/* refresh depotList*/
					$scope.refreshDepotList = function () {
						$scope.showDepotGrid = false;
						setTimeout(function () {
							$scope.$apply(function () {
								if (
									$scope.typeDepotList == 'connector' &&
									$scope.connectorId != undefined
								) {
									$scope.getLinkedDepots();
								} else if ($scope.typeDepotList == 'depot') {
									$scope.getGridData();
								}
							});
						}, 10);
					};

					$scope.toggleChanged = function (depot) {
						DepotService.updateDepotActivationStatus(
							depot.id,
							depot.active,
							depot.depot_type
						)
							.then(function () {
								$scope.gridInstance.refresh();
								toaster.pop(
									'success',
									toasterPopSucess,
									depot.active ? toasterPopActivated : toasterPopDeactivated
								);
							})
							.catch(function () {
								depot.active = !depot.active;
							});
					};

					$scope.moveOrCopyData = {};
					$scope.moveOrCopyElementToProject = function () {
						let depotIds = _.map($scope.selectedElems, function (con) {
							return con.id;
						});
						$scope.moveOrCopyData = {
							elements: depotIds,
							type: 'depot',
							showMoveCopy: true,
						};
					};
					$scope.moveOrCopyOneElementToProject = function (elementId) {
						$scope.moveOrCopyData = {
							elements: [elementId],
							type: 'depot',
							showMoveCopy: true,
						};
					};

					// refresh mapping count
					$scope.$on(UPDATE_EVENT['CARAC_MAPPING'], function (event) {
						$scope.getGridData();
					});
				},
			];

			return {
				restrict: 'E',
				scope: {
					connectorId: '=',
					typeDepotList: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl: './src/components/directives/depotList/depotList.html',
				link: function (scope, element, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
