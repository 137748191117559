(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller('UriParserController', UriParserController);

	UriParserController.$inject = ['$log', '$timeout'];
	function UriParserController($log, $timeout) {
		const vm = this;
		const UPDATE_TIMEOUT = 650;
		let originalParamsKeys = [];
		let updateParamKeyCb;
		let updateParamValueCb;

		init();

		vm.addEntry = addUriParam;
		vm.onParamKeyChange = onParamKeyChange;
		vm.onParamValueChange = onParamValueChange;
		vm.deleteEntry = deleteUriParam;

		function init() {
			$log.info(vm.uri);
			vm.base = getBasePath(vm.uri);
			vm.paramsMap = parserUri(vm.uri);
			originalParamsKeys = Object.keys(vm.paramsMap);
			vm.fullUrl = updateFullUri(originalParamsKeys, vm.base, vm.paramsMap);
		}

		function parserUri(uri) {
			if (!uri || uri === '') {
				return {};
			}
			const [basePath, queryParams] = uri.split('?');
			$log.info(basePath);
			if (!queryParams || queryParams === '') {
				return {};
			}
			const params = queryParams.split('&');
			if (params && params.length === 0) {
				return {};
			}
			return params.reduce(function (acc, curr) {
				const [key, value] = curr.split('=');
				acc[key] = value;
				return acc;
			}, {});
		}

		function onParamKeyChange(idx, newParamKey) {
			if (updateParamKeyCb) {
				$timeout.cancel(updateParamKeyCb);
			}
			const targetKey = originalParamsKeys[idx];
			const paramValue = vm.paramsMap[targetKey];

			const updateParamsMap = Object.keys(vm.paramsMap).reduce(function (
				acc,
				curr
			) {
				if (curr === targetKey) {
					acc[newParamKey] = paramValue;
					return acc;
				}
				acc[curr] = vm.paramsMap[curr];
				return acc;
			},
			{});

			updateParamKeyCb = $timeout(function () {
				vm.paramsMap = updateParamsMap;
				originalParamsKeys = Object.keys(vm.paramsMap);
				vm.fullUrl = updateFullUri(originalParamsKeys, vm.base, vm.paramsMap);
			}, UPDATE_TIMEOUT);
		}

		function onParamValueChange(idx, newParamValue) {
			if (updateParamValueCb) {
				$timeout.cancel(updateParamValueCb);
			}
			const targetKey = originalParamsKeys[idx];

			const updateParamsMap = Object.keys(vm.paramsMap).reduce(function (
				acc,
				curr
			) {
				if (curr === targetKey) {
					acc[curr] = newParamValue;
					return acc;
				}
				acc[curr] = vm.paramsMap[curr];
				return acc;
			},
			{});

			updateParamValueCb = $timeout(function () {
				vm.paramsMap = updateParamsMap;
				originalParamsKeys = Object.keys(vm.paramsMap);
				vm.fullUrl = updateFullUri(originalParamsKeys, vm.base, vm.paramsMap);
			}, UPDATE_TIMEOUT);
		}

		function addUriParam() {
			vm.paramsMap['newKey'] = 'new value';
			originalParamsKeys = Object.keys(vm.paramsMap);
			vm.fullUrl = updateFullUri(originalParamsKeys, vm.base, vm.paramsMap);
		}

		function deleteUriParam(entryIdx) {
			vm.paramsMap = Object.keys(vm.paramsMap).reduce(function (
				acc,
				curr,
				idx
			) {
				if (entryIdx === idx) {
					return acc;
				}
				acc[curr] = vm.paramsMap[curr];
				return acc;
			},
			{});
			originalParamsKeys = Object.keys(vm.paramsMap);
			vm.fullUrl = updateFullUri(originalParamsKeys, vm.base, vm.paramsMap);
		}

		function getBasePath(uri) {
			if (!uri || uri === '') {
				return '';
			}
			const [basePath, _] = uri.split('?');
			return basePath;
		}

		function updateFullUri(keys, base, paramsMap) {
			if (!base || base === '') {
				return;
			}
			let fullUrl = base;

			keys.reduce(function (acc, curr, idx) {
				if (idx === 0) {
					fullUrl = `${fullUrl}?${curr}=${paramsMap[curr]}`;
					return fullUrl;
				}
				fullUrl = `${fullUrl}&${curr}=${paramsMap[curr]}`;
				return fullUrl;
			}, fullUrl);
			return fullUrl;
		}
	}
})();
