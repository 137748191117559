(function () {
	'use strict';

	angular.module('dcApp').controller('NewChartController', [
		'$scope',
		'$state',
		function ($scope, $state) {
			$scope.selectChartType = function (type, subType) {
				if (!subType) {
					$state.go('charts-new', { type: type });
				} else {
					$state.go('charts-new', { type: type, barType: subType });
				}
			};

			$scope.returnToList = function () {
				$state.go('charts', {});
			};
		},
	]);
})();
