(function () {
	'use strict';

	angular.module('dcApp').directive('configTooltip', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				function ($scope, $rootScope) {
					var vm = this;
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/configTooltipColumns/configTooltipColumns.html'
			);
		},
	]);
})();
