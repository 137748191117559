(function () {
	'use strict';

	angular.module('dcApp').service('DcElementListService', DcElementListService);

	DcElementListService.$inject = [
		'$rootScope',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'STORAGE_SUFFIX',
		'API_BASE_URL_BACKEND',
		'UserService',
		'ProjectStorage',
	];

	function DcElementListService(
		$rootScope,
		PAGINATIONS_SIZE,
		PAGINATIONS_SIZES,
		STORAGE_SUFFIX,
		API_BASE_URL_BACKEND,
		UserService,
		ProjectStorage
	) {
		const preConfiguredColumnsSize = {
			grid: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			chart: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			media: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			dashboard: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			map: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			timeline: {
				lib: '300px',
				type: '11%',
				tags: '15%',
				desc: '17%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				project: '150px',
			},
			connector: {
				lib: '250px',
				type: '6%',
				tags: '10%',
				desc: '14%',
				creationDate: '8%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '11%',
				imported: '5%',
				actions: '190px',
				project: '200px',
			},
			depot: {
				lib: '300px',
				type: '6%',
				tags: '10%',
				desc: '18%',
				creationDate: '8%',
				createdBy: '8%',
				updateDate: '',
				updateBy: '',
				imported: '5%',
				connectorLib: '8%',
				depotType: '10%',
				active: '5%',
				NbEntities: '6%',
				actions: '190px',
				project: '150px',
			},
			entity: {
				lib: '300px',
				type: '20%',
				imported: '5%',
				tags: '15%',
				desc: '22%',
				active: '6%',
				cache: '15%',
				mode: '13%',
				depot: '7%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '200px',
				actionsDb: '120px',
				project: '200px',
			},
			datablock: {
				lib: '300px',
				tags: '15%',
				cache: '13%',
				structure: '8%',
				source: '8%',
				desc: '22%',
				creationDate: '11%',
				createdBy: '8%',
				updateDate: '11%',
				updateBy: '8%',
				actions: '240px',
				project: '150px',
			},
			exportTemplate: {
				lib: '290px',
				type: '6%',
				desc: '14%',
				creationDate: '10%',
				createdBy: '8%',
				updateDate: '9%',
				updateBy: '100px',
				actions: '160px',
				project: '220px',
			},
		};

		this.getDcElementDxList = function (scope, type, isLinkedElem) {
			const translatedCaptions = $rootScope.dataGirdCaptionsTra;
			let savedProjectData = ProjectStorage.getValue();
			if (savedProjectData === undefined) {
				console.log('project not defined');
			}
			const currentProjectId = savedProjectData.projectId;
			const currentProjectLib = savedProjectData.projectLabel;
			scope.tagBox = {
				displayExpr: 'code',
				valueExpr: 'id',
				searchEnabled: false,
				editEnabled: false,
				tagTemplate: 'tagTemplate',
			};
			scope.getTagBox = function (list) {
				scope.tagBox.value = list.data.tags;
				return scope.tagBox;
			};

			if ($('#popup').length === 0) {
				$(document.body).append('<div id="popup"></div>');
			}
			const popup = $('#popup')
				.dxPopover({ width: 550, showEvent: { name: 'click', delay: 3000 } })
				.dxPopover('instance');
			scope.disableAllSelectedLink = true;
			scope.dataGridOptions = {
				dataSource: scope.gridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: translatedCaptions['pager'],
					showInfo: true,
					visible: true,
				},
				scrolling: {
					rowRenderingMode: 'Standard',
					showScrollbar: 'onHover',
				},
				selection: {
					mode: 'multiple',
					showCheckBoxesMode: 'always',
					selectAllMode: 'page',
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				filterPanel: {
					visible: true,
				},
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				stateStoring: {
					enabled: false,
					type: 'localStorage',
				},
				showColumnLines: true,
				showRowLines: false,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: getDcElementColumns(
					type,
					scope.lookupTags,
					scope.isDatablock,
					translatedCaptions,
					currentProjectId,
					currentProjectLib
				),
				onInitialized: onInitialized(
					type,
					scope,
					translatedCaptions['grid.header.filter.blank']
				),
				onRowPrepared: onRowPrepared,
				onSelectionChanged: onSelectionChanged(scope),
				onCellClick: onCellClick(
					scope,
					popup,
					currentProjectId,
					API_BASE_URL_BACKEND,
					translatedCaptions,
					UserService
				),
			};
			enhanceScopeBasedOnDcElementType(scope, type, isLinkedElem);
		};

		this.buildHdListElement = function (content, $rootScope, subType) {
			let element = {
				id: content.id,
				code: content.metadata.code,
				type: content.type,
				lc: content.metadata.label,
				desc: content.metadata.description,
				tags: content.metadata.tags,
				icon_id: content.metadata.icon_id,
				tagAsArray: _.map(content.metadata.tags, function (item) {
					return item.code;
				}),
				creationDate: $rootScope.getDateTimeWithPattern(
					content.metadata.creation_date
				),
				updateDate: $rootScope.getDateTimeWithPattern(
					content.metadata.update_date
				),
				createdBy: content.metadata.created_by,
				updatedBy: content.metadata.updated_by,
				project: content.metadata.project,
			};

			if (subType) {
				element.sub_type = content.sub_type;
			}

			return element;
		};

		function getDcElementColumns(
			type,
			lookupTags,
			isDatablock,
			translatedCaptions,
			currentProjectId,
			currentProjectLib
		) {
			let columns = [
				{
					dataField: 'code',
					caption: translatedCaptions['Code']
						? translatedCaptions['Code']
						: 'Code',
					visible: false,
					alignment: 'center',
				},
				{
					caption: translatedCaptions['Projet']
						? translatedCaptions['Projet']
						: 'Projet',
					cellTemplate: function (container, options) {
						if (currentProjectId != options.data.project.id) {
							let content =
								'<div class="project-label-value clickable" style="display: inline-block;"><span >' +
								(options.data.project.is_public
									? '<i class="fas fa-lock-open fa-lg p-icon left-space-5" aria-hidden="true" style="color: #333;" ></i>'
									: '') +
								'</span><span style="color: #252525;" class="dx-lib-align' +
								(!options.data.project.is_public ? 'left-space-30' : '') +
								'"> ' +
								options.data.project.metadata.label +
								' </span></div>';
							$(content).appendTo(container);
						}
					},
					dataField: 'project.metadata.label',
					isProjectCol: true,
					SelectedFilterOperation: '=',
					//filterValue: currentProjectLib ? currentProjectLib : null,
					width: preConfiguredColumnsSize[type]['project'],
				},
				{
					caption: translatedCaptions['Libellé']
						? translatedCaptions['Libellé']
						: 'Libellé',
					cellTemplate: 'imageAndLib',
					dataField: type === 'entity' ? 'lib' : 'lc',
					width: preConfiguredColumnsSize[type]['lib'],
					fixed: true,
					fixedPosition: 'left',
					allowReordering: false,
				},
				{
					dataField: 'tagAsArray',
					caption: translatedCaptions['Tags']
						? translatedCaptions['Tags']
						: 'Tags',
					name: 'tags',
					cellTemplate: 'tagTemplate',
					allowHeaderFiltering: true,
					allowFiltering: false,
					enableCellEdit: false,
					width: preConfiguredColumnsSize[type]['tags'],
					headerFilter: {
						dataSource: lookupTags,
					},
					calculateFilterExpression: function (filterValue) {
						if (!filterValue) {
							return ['tagAsArray', '=', null];
						}

						return ['tagAsArray', 'contains', filterValue];
					},
				},
				{
					caption: translatedCaptions['Description']
						? translatedCaptions['Description']
						: 'Description',
					dataField: 'desc',
					width: preConfiguredColumnsSize[type]['desc'],
					visible:
						type == 'depot' || type == 'entity' || type == 'datablock'
							? false
							: true,
				},
				{
					dataField: 'creationDate',
					caption: translatedCaptions['Date de création']
						? translatedCaptions['Date de création']
						: 'Date de création',
					width: preConfiguredColumnsSize[type]['creationDate'],
					visible: false,
					sortingMethod: function (value1, value2) {
						return compareTwoDatesForDxGrid(value1, value2, this.sortOrder);
					},
				},
				{
					cellTemplate: 'createdByUser',
					caption: translatedCaptions['Créateur']
						? translatedCaptions['Créateur']
						: 'Créateur',
					width: preConfiguredColumnsSize[type]['createdBy'],
					visible: false,
					sortingMethod: function (value1, value2) {
						return compareTwoDatesForDxGrid(value1, value2, this.sortOrder);
					},
				},
				{
					dataField: 'updateDate',
					caption: translatedCaptions['Date de mise à jour']
						? translatedCaptions['Date de mise à jour']
						: 'Date de mise à jour',
					width: preConfiguredColumnsSize[type]['updateDate'],
					visible: true,
					sortOrder: 'desc',
					sortingMethod: function (value1, value2) {
						return compareTwoDatesForDxGrid(value1, value2, this.sortOrder);
					},
				},
				{
					cellTemplate: 'updatedByUser',
					caption: translatedCaptions['Dernier modificateur']
						? translatedCaptions['Dernier modificateur']
						: 'Dernier modificateur',
					visible: true,
					width: preConfiguredColumnsSize[type]['updateBy'],
					sortingMethod: function (value1, value2) {
						return compareTwoDatesForDxGrid(value1, value2, this.sortOrder);
					},
				},
				{
					caption: translatedCaptions['Actions']
						? translatedCaptions['Actions']
						: 'Actions',
					alignment: 'center',
					cellTemplate: 'actions',
					width: isDatablock
						? preConfiguredColumnsSize[type]['actionsDb']
						: preConfiguredColumnsSize[type]['actions'],
					fixed: true,
					fixedPosition: 'right',
					allowReordering: false,
				},
			];

			if (type == 'grid' || type == 'chart' || type == 'media' || isDatablock) {
				columns.splice(2, 0, {
					caption: translatedCaptions['Type']
						? translatedCaptions['Type']
						: 'Type',
					dataField: 'type',
					cellTemplate: 'elemType',
					width: preConfiguredColumnsSize[type]['type'],
				});
			}
			if (type == 'connector') {
				columns.splice(4, 0, {
					caption: translatedCaptions['Type']
						? translatedCaptions['Type']
						: 'Type',
					dataField: 'connector_type',
					width: preConfiguredColumnsSize[type]['type'],
				});
			}
			if (type == 'depot') {
				columns.splice(4, 0, {
					caption: translatedCaptions['Connecteur']
						? translatedCaptions['Connecteur']
						: 'Connecteur',
					dataField: 'connector_lib',
					cellTemplate: 'connectorLibTemplate',
					width: preConfiguredColumnsSize[type]['connectorLib'],
				});
				columns.splice(5, 0, {
					caption: translatedCaptions['Type de Dépôt']
						? translatedCaptions['Type de Dépôt']
						: 'Type de Dépôt',
					dataField: 'depot_lib',
					name: 'depotType',
					lookup: { dataSource: [] },
					allowHeaderFiltering: true,
					enableCellEdit: false,
					width: preConfiguredColumnsSize[type]['depotType'],
				});
				columns.splice(6, 0, {
					caption: translatedCaptions['Nb entités métier']
						? translatedCaptions['Nb entités métier']
						: 'Nb Entités Métier',
					cellTemplate: 'linkedEntities',
					width: preConfiguredColumnsSize[type]['NbEntities'],
				});
			}
			if (type == 'connector' || type == 'depot' || type == 'entity') {
				columns.splice(columns.length - 2, 0, {
					caption: translatedCaptions['Importé']
						? translatedCaptions['Importé']
						: 'Importé',
					dataField: 'imported',
					alignment: 'center',
					visible: false,
					width: preConfiguredColumnsSize[type]['imported'],
				});
				if (type != 'connector') {
					let spliceIndex = type == 'depot' ? 5 : 3;
					let visible = type == 'depot' ? true : false;
					columns.splice(spliceIndex, 0, {
						caption: translatedCaptions['Actif']
							? translatedCaptions['Actif']
							: 'Actif',
						dataField: 'active',
						cellTemplate: type == 'depot' ? 'activeCellTemplate' : '',
						visible: visible,
						lookup:
							type == 'depot'
								? ''
								: {
										dataSource: [
											translatedCaptions['Oui']
												? translatedCaptions['Oui']
												: 'Oui',
											translatedCaptions['Non']
												? translatedCaptions['Non']
												: 'Non',
										],
								  },
						width: preConfiguredColumnsSize[type]['active'],
					});
				}
			}
			if (type == 'entity') {
				columns.splice(4, 0, {
					caption: translatedCaptions['Cache et persistance']
						? translatedCaptions['Cache et persistance']
						: 'Cache et persistance',
					cellTemplate: 'cacheTemplate',
					alignment: 'center',
					width: preConfiguredColumnsSize[type]['cache'],
				});
				columns.splice(5, 0, {
					caption: translatedCaptions['Mode']
						? translatedCaptions['Mode']
						: 'Mode',
					cellTemplate: 'storageMode',
					dataFiled: 'frame_storage_mode',
					alignment: 'center',
					visible: false,
					width: preConfiguredColumnsSize[type]['mode'],
				});
				columns.splice(6, 0, {
					caption: translatedCaptions['Nb de dépôts']
						? translatedCaptions['Nb de dépôts']
						: 'Nb de dépôts',
					dataField: 'data_source_count',
					alignment: 'center',
					dataType: 'Number',
					visible: false,
					width: preConfiguredColumnsSize[type]['depot'],
				});
			}
			if (type == 'datablock') {
				columns.splice(5, 0, {
					caption: translatedCaptions['Cache et persistance']
						? translatedCaptions['Cache et persistance']
						: 'Cache et persistance',
					cellTemplate: 'cacheTemplate',
					alignment: 'center',
					width: preConfiguredColumnsSize[type]['cache'],
				});
			}
			if (type == 'exportTemplate') {
				columns.splice(3, 1);
			}

			if (type == 'datablock' || type == 'entity') {
				columns.push(
					{
						dataField: 'persistenceDate',
						caption: translatedCaptions['frame.status.persistence.date']
							? translatedCaptions['frame.status.persistence.date']
							: 'Date de persistance',
						visible: false,
						alignment: 'center',
						dataType: 'date',
						format: 'yyyy-MM-dd HH:mm:ss'
					}
				)
				columns.push(
					{
						dataField: 'cacheDate',
						caption: translatedCaptions['frame.status.cache.date']
							? translatedCaptions['frame.status.cache.date']
							: 'Date de cache',
						visible: false,
						alignment: 'center',
						dataType: 'date',
						format: 'yyyy-MM-dd HH:mm:ss'
					}
				)
			}

			return columns;
		}

		function onInitialized(type, scope, caption) {
			return (e) => {
				scope.gridInstance = e.component;
				if (type === 'depot') {
					setDepotTypeLookup(scope);
				}
				if (type !== 'exportTemplate') {
					setTagsLookup(scope, caption);
				}
			};
		}

		function onRowPrepared(e) {
			if (e.rowType === 'data') {
				e.rowElement.css({ height: 55 });
			}
		}

		function onSelectionChanged(scope) {
			return (selectedItems) => {
				scope.selectedElems = selectedItems.selectedRowsData;
				scope.disableAllSelectedElems =
					selectedItems.selectedRowsData.length === 0;
			};
		}

		function onCellClick(
			scope,
			popup,
			currentProjectId,
			API_BASE_URL_BACKEND,
			translatedCaptions,
			UserService
		) {
			return (e) => {
				let currentRowLi = e.rowIndex + '_' + e.columnIndex;
				let lastRowPop = undefined;
				if (
					e &&
					e.column &&
					e.column.isProjectCol &&
					e.rowType === 'data' &&
					currentRowLi != lastRowPop &&
					e.eventType === 'dxclick'
				) {
					let data = e.data ? e.data.project : undefined;
					if (data && data.id != currentProjectId) {
						lastRowPop = e.rowIndex + '_' + e.columnIndex;
						let createdBy;
						let updatedBy = '-';

						let loadPopup = function () {
							popup.option('contentTemplate', function (el) {
								const popupContent = buildPopupContent(
									createdBy,
									updatedBy,
									data,
									scope,
									popup,
									API_BASE_URL_BACKEND,
									translatedCaptions
								);
								$('<div/>')
									.append(popupContent.img)
									.append(popupContent.desc)
									.append(popupContent.createdByFull)
									.append(popupContent.updatedByFull)
									.appendTo(el);
							});
							popup.option('target', $('.project-label-value', e.cellElement));
							popup.show();
							setTimeout(function () {
								lastRowPop = undefined;
								popup.hide();
							}, 4000);
						};

						UserService.getUserById(data.metadata.created_by).then(function (
							response
						) {
							createdBy = response.data.name;
							if (data.metadata.updated_by) {
								UserService.getUserById(data.metadata.updated_by).then(
									function (response1) {
										updatedBy = response1.data.name;
										loadPopup();
									}
								);
							} else {
								loadPopup();
							}
						});
					}
				}
			};
		}

		function buildPopupContent(
			createdBy,
			updatedBy,
			data,
			scope,
			popup,
			API_BASE_URL_BACKEND,
			translatedCaptions
		) {
			let imgURL =
				API_BASE_URL_BACKEND +
				'pictogramme_image?id=' +
				(data.metadata.icon_id ? data.metadata.icon_id : -1);

			const creation = translatedCaptions['Création']
				? translatedCaptions['Création']
				: 'Création';
			const update = translatedCaptions['Mise à jour']
				? translatedCaptions['Mise à jour']
				: 'Mise à jour';
			const by = translatedCaptions['Par'] ? translatedCaptions['Par'] : 'Par';
			const des = data.metadata.description ? data.metadata.description : '-';
			const label = data.metadata.label ? data.metadata.label : '-';
			const creationDate = data.metadata.creation_date
				? scope.$parent.getDateTimeWithPattern(data.metadata.creation_date)
				: '-';
			const updateDate = data.metadata.update_date
				? scope.$parent.getDateTimeWithPattern(data.metadata.update_date)
				: '-';

			const img =
				'<div class="col-md-12 flex-center no-gutters padding-right-0 padding-left-0" style="display: flex;justify-content: center;"> <div class="col-md-2 no-gutters"><img alt="Project icon" class="right-space-10 pull-left circle-img-60" src="' +
				imgURL +
				'"/></div><div class="col-md-10" style="padding: 10px;">' +
				label +
				'</div></div>';

			const desc =
				'<div class="col-md-12 flex-center no-gutters padding-right-0 padding-left-0 top-space-10" style="display: flex;justify-content: center;font-size: 12px;">' +
				des +
				'</div>';

			const createdByFull =
				'<div class="col-md-12 flex-center no-gutters padding-right-0 padding-left-0 top-space-20" style="display: flex;justify-content: center;"><div class="col-md-3 no-gutters"><strong>' +
				creation +
				'</strong></div><div class="col-md-5">' +
				creationDate +
				'</div><div class="col-md-1"><strong>' +
				by +
				'</strong></div><div class="col-md-3">' +
				createdBy +
				'</div></div>';

			const updatedByFull =
				'<div class="col-md-12 flex-center no-gutters padding-right-0 padding-left-0 top-space-10" style="display: flex;justify-content: center;"><div class="col-md-3 no-gutters"><strong>' +
				update +
				'</strong></div><div class="col-md-5">' +
				updateDate +
				'</div><div class="col-md-1"><strong>' +
				by +
				'</strong></div><div class="col-md-3">' +
				updatedBy +
				'</div></div>';

			return {
				img,
				desc,
				createdByFull,
				updatedByFull,
			};
		}

		function enhanceScopeBasedOnDcElementType(scope, type, isLinkedElem) {
			switch (type) {
				case 'depot':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'entity':
					{
						if (isLinkedElem) {
							scope.dataGridOptions.stateStoring.enabled = true;
							scope.dataGridOptions.stateStoring.storageKey =
								generateLocalStorageKey('entity_linked');
						} else {
							scope.dataGridOptions.stateStoring.enabled = true;
							scope.dataGridOptions.stateStoring.storageKey =
								generateLocalStorageKey(type);
						}
					}
					break;
				case 'connector':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'datablock':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'grid':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'chart':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'media':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'timeline':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'map':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'dashboard':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				case 'exportTemplate':
					{
						scope.dataGridOptions.stateStoring.enabled = true;
						scope.dataGridOptions.stateStoring.storageKey =
							generateLocalStorageKey(type);
					}
					break;
				default:
					return;
			}
		}

		function generateLocalStorageKey(type) {
			return `devextreme:${type}:${STORAGE_SUFFIX}`;
		}

		function setTagsLookup(scope, blankLabel) {
			scope.lookupTags = _.map(
				_.sortBy(scope.tags, function (tag) {
					return tag.label.toLowerCase();
				}),
				function (el) {
					return { text: el.label, value: el.label };
				}
			);
			if (scope.gridInstance && scope.gridInstance.option) {
				let headerFilter = scope.gridInstance.columnOption(
					'tags',
					'headerFilter'
				);
				headerFilter.dataSource = scope.lookupTags;
				setTimeout(function () {
					headerFilter.dataSource.unshift({ text: blankLabel, value: null });
					scope.gridInstance.columnOption('tags', 'headerFilter', headerFilter);
				}, 400);
			}
		}

		function setDepotTypeLookup(scope) {
			let depotTypeLookups = _.map(scope.readerTypes, function (el) {
				return el.value;
			});
			if (scope.gridInstance && scope.gridInstance.option) {
				let lookup = scope.gridInstance.columnOption('depotType', 'lookup');
				lookup.dataSource = depotTypeLookups;
				scope.gridInstance.columnOption('depotType', 'lookup', lookup);
			}
		}
	}
})();
