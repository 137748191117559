(function () {
	'use strict';

	angular.module('dcApp').controller('SemanticRulesController', [
		'API_BASE_URL_BACKEND',
		'TagService',
		'$state',
		'$scope',
		'$rootScope',
		'$q',
		'toaster',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'SemanticContextService',
		'PermissionService',
		'gettextCatalog',
		function (
			API_BASE_URL_BACKEND,
			TagService,
			$state,
			$scope,
			$rootScope,
			$q,
			toaster,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			SemanticContextService,
			PermissionService,
			gettextCatalog
		) {
			//  loadNatures();
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.description = gettextCatalog.getString('Description');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopSaveRule = gettextCatalog.getString(
				'Enregistrement de la règle sémantique effectué'
			);
			let toasterPopDeleteRule = gettextCatalog.getString(
				'Suppression de la Règle Sémantique effectuée'
			);

			PermissionService.haveGlobalPermission('semantic_context-create').then(
				function (response) {
					$scope.create_authorised = response.data;
				}
			);

			$scope.rules = [];

			$scope.dataGridOptions = {
				dataSource: $scope.rulesGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: false,
				},
				columns: [
					{
						caption: dataGirdColumnName.label,
						cellTemplate: 'clickableLabel',
						dataField: 'label',
						width: '48%',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.description,
						cellTemplate: 'clickableLabel',
						dataField: 'description',
						width: '60%',
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actions',
						width: 120,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
			};

			function getRules() {
				var deferred = $q.defer();
				SemanticContextService.getSemantics('').then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getRules().then(function (content) {
					if (content !== undefined) {
						$scope.rules = [];
						for (var i in content) {
							$scope.rules.push({
								id: content[i].id,
								description: content[i].description,
								label: content[i].label,
							});
						}
						$scope.rulesGridData = $scope.rules;
						$scope.dataGridOptions.dataSource = $scope.rulesGridData;
						$scope.showGrid = true;
					}
				});
			};

			$scope.initSemanticRules = function () {
				PermissionService.haveGlobalPermission('semantic_context').then(
					function (response) {
						$scope.semantic_context_authorised = response.data;
						if ($scope.semantic_context_authorised) {
							$scope.getGridData();
						}
					}
				);
			};

			$scope.rule = {};
			$scope.init = function () {
				$scope.rule = {};
				$scope.rule.position_in_text = false;
				$scope.rule.rule = {};
				$scope.rule.relative_rules_operator = 'AND';
				$scope.rule.relative_rules = {};
				$scope.rule.relative_rules.rules = [];
				$scope.rule.relative_rules_operator = 'AND';
			};

			$scope.addRule = function () {
				$scope.rule.relative_rules.rules.push({
					carac_id: $scope.rule.carac_id,
					type: $scope.rule.rule.type,
					search_interval: true,
					search_in: false,
				});
			};

			$scope.deleteRelativeRule = function (rule) {
				$scope.rule.relative_rules.rules.splice(
					$scope.rule.relative_rules.rules.indexOf(rule),
					1
				);
			};

			$scope.changeRulesOperator = function (op) {
				$scope.rule.relative_rules_operator = op;
			};

			$scope.data = {
				initFilter: $scope.init,
			};

			$scope.hideMe = function (modalId) {
				$('#' + modalId).modal('hide');
			};

			$scope.init();

			$scope.showEditModal = function (modalId, ruleId, isNew) {
				if (!isNew) {
					SemanticContextService.getSemanticRuleById(ruleId).then(function (
						response
					) {
						$scope.rule = response.data;
						$scope.isNew = isNew;
						$('#' + modalId).modal('show');
					});
				} else {
					$scope.init();
					$scope.isNew = isNew;
					$('#' + modalId).modal('show');
				}
			};

			$scope.duplicateRule = function (modalId, ruleId) {
				SemanticContextService.getSemanticRuleById(ruleId).then(function (
					response
				) {
					$scope.rule = response.data;
					delete $scope.rule.id;
					$scope.isNew = true;
					$('#' + modalId).modal('show');
				});
			};

			$scope.saveSemanticRule = function (modalId) {
				if ($scope.isNew) {
					let data = angular.copy($scope.rule);
					SemanticContextService.createSemantic(data).then(function (response) {
						$('#' + modalId).modal('hide');
						toaster.pop('success', toasterPopSuccess, toasterPopSaveRule);
						$scope.rulesGridData.push(response.data);
						$scope.gridInstance.refresh();
					});
				} else {
					let data = angular.copy($scope.rule);
					SemanticContextService.updateSemantic(data).then(function (response) {
						$('#' + modalId).modal('hide');
						toaster.pop('success', toasterPopSuccess, toasterPopSaveRule);
						for (var i in $scope.rulesGridData) {
							if (
								response.data &&
								response.data.id == $scope.rulesGridData[i].id
							) {
								$scope.rulesGridData[i] = response.data;
								$scope.gridInstance.refresh();
								break;
							}
						}
					});
				}
			};

			$scope.initSemanticRules();

			$scope.showDeleteModal = function (modalId, rule) {
				$scope.ruleTodelete = rule.id;
				$('#' + modalId).modal('show');
			};

			$scope.deleteRule = function (modalId) {
				SemanticContextService.deleteSemantic($scope.ruleTodelete).then(
					function (response) {
						for (var i in $scope.rulesGridData) {
							if (response.data == $scope.rulesGridData[i].id) {
								$scope.rulesGridData.splice(i, 1);
								$scope.gridInstance.refresh();
								break;
							}
						}
						$('#' + modalId).modal('hide');
						toaster.pop('success', toasterPopSuccess, toasterPopDeleteRule);
					}
				);
			};
		},
	]);
})();
