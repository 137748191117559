(function () {
	'use strict';

	angular.module('dcApp').directive('hdhRebound', [
		'$parse',
		function ($parse) {
			var controller = [
				'$state',
				'toaster',
				'$stateParams',
				'$sce',
				'$rootScope',
				'$scope',
				'$timeout',
				'PAGINATIONS_SIZES',
				'$q',
				'GenericService',
				'PAGINATIONS_SIZE',
				'API_BASE_URL_BACKEND',
				'$http',
				'$location',
				'$window',
				function (
					$state,
					toaster,
					$stateParams,
					$sce,
					$rootScope,
					$scope,
					$timeout,
					PAGINATIONS_SIZES,
					$q,
					GenericService,
					PAGINATIONS_SIZE,
					API_BASE_URL_BACKEND,
					$http,
					$location,
					$window
				) {
					var vm = this;
					vm.API_BASE_URL_BACKEND = $rootScope.API_BASE_URL_BACKEND;
					$scope.accessToken = window._keycloak.token;

					$scope.showCaraInfo = function (id) {
						delete vm.widgetData.columnInfoId;
						delete vm.widgetData.columnInfoGrammar;
						vm.widgetData.caracInfoId = id;
						vm.widgetData.showColumnStat = true;
					};

					$scope.isUrl = function (str) {
						if (str && typeof str === 'string') {
							str = str.toLowerCase();
							return str.startsWith('http://') || str.startsWith('https://');
						} else {
							return false;
						}
					};

					$scope.initRebound = function (itemIndex) {
						$scope.renoundedRow = $scope.renoundedRows[itemIndex];
						$scope.columnsRebound = $scope.columnsRebounds[itemIndex];
						$scope.itemIndex = itemIndex;
						$scope.valuesCaraMap = {};

						var i = 0;
						for (var v in $scope.renoundedRow) {
							$scope.valuesCaraMap[
								Object.values($scope.columnsRebound)[i].uca_id
							] = $scope.renoundedRow[v].val;
							i++;
						}

						var listId = [];
						for (var col in $scope.columnsRebound) {
							if ($scope.columnsRebound[col].uca_id) {
								listId.push($scope.columnsRebound[col].uca_id);
							}
						}
						$scope.listId = listId;
					};

					$scope.defaultDateFormat = 'Y-MM-DD HH:mm:ss';

					$scope.getValue = function (value, column) {
						if (column.type == 'date' && isNumeric(value.val)) {
							return moment
								.tz(value.val, $rootScope.serverTimezone)
								.format($scope.defaultDateFormat);
						} else {
							return value.val;
						}
					};

					$scope.showFileAnalyzerForOne = function (col, docName) {
						if (col && docName) {
							var data = [];
							data.push({
								col_id: 1,
								display_name: col,
								docs: [
									{
										file_name: docName,
										display_name: docName.replace(/^.*[\\\/]/, ''),
									},
								],
							});
							vm.widgetData.widget = data;
							vm.widgetData.token = null;
							vm.widgetData.showAnalyzer = true;
							vm.widgetData.id = $scope.$id;
						}
					};

					$scope.close = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showRebound = false;
					}

					$scope.goToSearchValue = function (val, en) {
						$window.open(
							'/#/value-search' + '?value=' + val + '&entite=' + en,
							'_blank'
						);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/rebound/rebound.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.uuid = generateUuid('_');
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showRebound = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showRebound;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.itemsNumber = 0;
								if (scope.vm.widgetData.multipleRebound) {
									scope.itemsNumber = scope.vm.widgetData.renoundedRow.length;
									scope.renoundedRows = scope.vm.widgetData.renoundedRow;
									scope.columnsRebounds = scope.vm.widgetData.columnsRebound;
								} else {
									scope.renoundedRows = [scope.vm.widgetData.renoundedRow];
									scope.columnsRebounds = [scope.vm.widgetData.columnsRebound];
								}

								scope.reboundElement = element;
								scope.initRebound(0);

								$('#reboundModalv2' + scope.uuid).modal('show');
							} else {
								$('#reboundModalv2' + scope.uuid).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
