(function () {
	'use strict';

	angular.module('dcApp').directive('deleteLinkedSources', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'EntiteDepotService',
				'DbSQLDepotService',
				'CsvDepotService',
				'BinaryDepotService',
				'ExcelDepotService',
				'DepotService',
				'JsonDepotService',
				'ParquetDepotService',
				function (
					$rootScope,
					$scope,
					EntiteDepotService,
					DbSQLDepotService,
					CsvDepotService,
					BinaryDepotService,
					ExcelDepotService,
					DepotService,
					JsonDepotService,
					ParquetDepotService
				) {
					let vm = this;
					$scope.depots = [];
					$scope.init = function (id) {
						$scope.uetId = id;
						EntiteDepotService.getEntiteTypeCsvDepotList(id).then(function (
							response
						) {
							$scope.depots = response.data;
							vm.data.showMessage = $scope.depots.length === 0;
							vm.data.containsAtLeastOneDe = $scope.depots.length > 0;
						});
					};

					$scope.deleteAllLinkedSources = function () {
						let depotIds = _.map($scope.depots, function (elm) {
							return elm.id;
						});
						DepotService.deleteDepots(depotIds).then(function () {
							$scope.depots = [];
							vm.data.showMessage = $scope.depots.length === 0;
						});
					};

					$scope.deleteDepot = function (depot) {
						if (depot.depot_type == 'SQL') {
							DbSQLDepotService.deleteDbDepot(depot.id).then(function (
								response
							) {
								for (let j in $scope.depots) {
									if ($scope.depots[j].id == response.data) {
										$scope.depots.splice(j, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						} else if (depot.depot_type == 'CSV') {
							CsvDepotService.deleteCsvDepot(depot.id).then(function (
								response
							) {
								for (let i in $scope.depots) {
									if ($scope.depots[i].id == response.data) {
										$scope.depots.splice(i, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						} else if (depot.depot_type == 'BINARY') {
							BinaryDepotService.deleteBinaryDepot(depot.id).then(function (
								response
							) {
								for (let i in $scope.depots) {
									if ($scope.depots[i].id == response.data) {
										$scope.depots.splice(i, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						} else if (depot.depot_type == 'EXCEL') {
							ExcelDepotService.deleteExcelDepot(depot.id).then(function (
								response
							) {
								for (let i in $scope.depots) {
									if ($scope.depots[i].id == response.data) {
										$scope.depots.splice(i, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						} else if (depot.depot_type == 'JSON') {
							JsonDepotService.deleteJsonDepot(depot.id).then(function (
								response
							) {
								for (let i in $scope.depots) {
									if ($scope.depots[i].id == response.data) {
										$scope.depots.splice(i, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						} else if (depot.depot_type == 'PARQUET') {
							ParquetDepotService.deleteParquetDepot(depot.id).then(function (
								response
							) {
								for (let i in $scope.depots) {
									if ($scope.depots[i].id == response.data) {
										$scope.depots.splice(i, 1);
										vm.data.showMessage = $scope.depots.length === 0;
										break;
									}
								}
							});
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					enableOptions: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/deleteLinkedSources/deleteLinkedSources.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					if (scope.vm.data) {
						scope.vm.data.init = scope.init;
					}
				},
			};
		},
	]);
})();
