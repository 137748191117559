(function () {
	'use strict';

	FirstCmpController.$inject = ['$log', '$scope', '$state', 'ModalService'];
	function FirstCmpController($log, $scope, $state, ModalService) {
		const vm = this;
		vm.test = 'FIRST COMPONENT';

		vm.$onInit = onInit;

		function onInit() {
			var duration = 5 * 1000;
			var end = Date.now() + duration;

			(function frame() {
				// launch a few confetti from the left edge
				confetti({
					particleCount: 3,
					angle: 60,
					spread: 124,
					origin: { x: 0 },
					startVelocity: 40,
				});
				// and launch a few from the right edge
				confetti({
					particleCount: 3,
					angle: 120,
					spread: 137,
					origin: { x: 1 },
					startVelocity: 45,
				});

				// keep going until we are out of time
				if (Date.now() < end) {
					requestAnimationFrame(frame);
				}
			})();
		}

		vm.openModal = function () {
			var defaultLocals = {
				title: 'Modal title',
				subTitle: '',
				acceptLabel: 'Close',
			};

			ModalService.open({
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/base/modal/modal.html',
				controller: 'ModalTestController',
				disableBackdrop: true,
				locals: defaultLocals,
			}).then(function (data) {
				$log.debug(data);
			});
		};

		vm.confirm = function () {
			ModalService.confirm({
				content: `<span>test</span>`,
			});
		};

		vm.info = function () {
			ModalService.info({
				content: `<span>test</span>`,
			});
		};

		vm.inputModal = function () {
			ModalService.input();
		};

		vm.textModal = function () {
			ModalService.text({
				disableBackdrop: true,
			});
		};
	}

	const firstCmp = {
		restrict: 'E',
		controller: FirstCmpController,
		templateUrl:
			'./src/components/directives/first-component/first-component.html',
		controllerAs: 'vm',
	};

	angular.module('demosModule').component('firstCmp', firstCmp);
})();
