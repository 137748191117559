(function () {
	'use strict';

	ModuleSwitcherCmpController.$inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$log',
		'$window',
	];
	function ModuleSwitcherCmpController(
		$rootScope,
		$scope,
		$state,
		$log,
		$window
	) {
		const vm = this;
		let moduleCache = [];

		vm.$onInit = onInit;
		vm.switchTo = switchTo;

		function onInit() {
			vm.availableModules = buildAvailableLinks(vm.isInGenericsMod);
			initEvents();
			initWatchers();
		}

		function switchTo(module) {
			$log.debug(`go to: ${module.url || module.stateName}`);
			$rootScope.$broadcast('isGenericDataMode', !vm.isGenericDataMode);
			if (module.url !== '' && module.stateName === undefined) {
				$window.open(module.url);
			}
			$state.go(module.stateName);
		}

		function buildAvailableLinks(isInGenericsMode) {
			const [dcModule, ...links] = moduleCache;

			if (isInGenericsMode) {
				return [
					{
						img: 'src/img/handleData-color-solo.svg',
						label: 'Handle Data',
						stateName: 'home',
					},
					...links,
				];
			}
			return [
				{
					img: 'src/img/genericsData-color-solo.svg',
					label: 'Generics Data',
					stateName: 'gd-home',
				},
				...links,
			];
		}

		function initEvents() {
			$scope.$on('project:link-update', function (event, val) {
				const first = vm.availableModules[0];
				if (val === '' || val === undefined || val === null) {
					moduleCache = [first];
					vm.availableModules = moduleCache;
					return;
				}
				const modules = [
					{
						img: 'src/img/pyDc-color.svg',
						label: 'PyDc',
						stateName: undefined,
						url: val,
					},
				];
				moduleCache = [first, ...modules];
				vm.availableModules = moduleCache;
			});
		}

		function initWatchers() {
			$scope.$watch(
				function () {
					return vm.isInGenericsMod;
				},
				function (newVal, oldVal) {
					vm.isGenericDataMode = newVal;
					vm.availableModules = buildAvailableLinks(newVal);
				}
			);
		}
	}

	const firstCmp = {
		restrict: 'E',
		bindings: {
			isInGenericsMod: '<',
		},
		controller: ModuleSwitcherCmpController,
		templateUrl:
			'./src/components/directives/module-switcher/module-switcher.component.html',
		controllerAs: 'vm',
	};

	angular.module('dcApp').component('moduleSwitcherCmp', firstCmp);
})();
