(function () {
	'use strict';

	angular.module('dcApp').directive('selectConnectedColumn', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				'DataBlocksService',
				'toaster',
				'$sce',
				'gettextCatalog',
				function (
					$scope,
					GenericService,
					DataBlocksService,
					toaster,
					$sce,
					gettextCatalog
				) {
					var vm = this;
					$scope.filterType = '';

					$scope.init = function () {
						delete $scope.searchText;
						$scope.loadConnectedCaracs();
					};

					$scope.loadConnectedCaracs = function () {
						let text = $scope.searchText ? $scope.searchText : '';
						GenericService.getConnectedCaracs(
							vm.widgetData.selectConnectedColumnPivotId,
							text,
							true,
							true,
							true,
							true,
							100,
							'c',
							false,
							'c'
						).then(function (response) {
							$scope.caracs = _.filter(response.data, function (item) {
								return (
									!item.complex &&
									vm.widgetData.selectConnectedColumnType ==
										getTypeFromEntiteTypeCode(item.code_type) &&
									(item.list == true) ==
										(vm.widgetData.selectConnectedColumnIsList == true)
								);
							});
						});
					};

					$scope.selectCarac = function (carac) {
						$scope.selectedCarac = carac;
						if (
							vm.widgetData.selectConnectedColumnParentId ==
							carac.parent_entite_type_id
						) {
							let path =
								vm.widgetData.selectConnectedColumnPivotId == carac.id
									? 'c' + carac.id
									: 'c' +
									  vm.widgetData.selectConnectedColumnPivotId +
									  '-' +
									  vm.widgetData.selectConnectedColumnParentId +
									  '-c' +
									  carac.id;
							$scope.selectPath(path);
							vm.closeModal();
							return;
						}
						vm.widgetData.updateaction_l2 = $scope.selectPath;
						vm.widgetData.source = vm.widgetData.selectConnectedColumnPivotId;
						vm.widgetData.sourceType = 'c';
						vm.widgetData.targetLib = carac.lib_court;
						vm.widgetData.targetParentLib = carac.parent_entite_type_lib;
						vm.widgetData.targetParentId = carac.parent_entite_type_id;
						vm.widgetData.target = carac.id;
						vm.widgetData.targetType = 'c';
						vm.widgetData.oldPathes = vm.widgetData.selectConnectedColumnPaths;
						vm.widgetData.showSelectPath = true;
					};

					$scope.selectPath = function (path) {
						vm.widgetData.saveSelectConnectedColumn(path, $scope.selectedCarac);
						vm.closeModal();
					};

					$scope.getHtmlIconByCaracType = function (type) {
						return $sce.trustAsHtml(
							getHtmlIconByType(getTypeFromEntiteTypeCode(type))
						);
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.widgetData.showSelectConnectedColumn = false;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=', saveAction: '&' },
				controller,
				'./src/components/directives/dataOperationTools/selectConnectedColumn/selectConnectedColumn.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showSelectConnectedColumn;
						},
						function () {
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
