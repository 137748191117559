(function () {
	'use strict';

	angular.module('dcApp').controller('RootController', RootController);

	RootController.$inject = [
		'$scope',
		'$rootScope',
		'$state',
		'LoggerFactory',
		'SELECTED_MENU',
		'USER_ROLE_NAME',
		'BACKEND_CLIENT_ID',
		'ContextServices',
		'$window',
		'gettextCatalog',
		'AppSelectorService',
		'permissionChecker',
		'SparkJobsServices',
	];

	function RootController(
		$scope,
		$rootScope,
		$state,
		LoggerFactory,
		SELECTED_MENU,
		USER_ROLE_NAME,
		BACKEND_CLIENT_ID,
		ContextServices,
		$window,
		gettextCatalog,
		AppSelectorService,
		permissionChecker,
		SparkJobsServices
	) {
		const logger = LoggerFactory.getLogger('RootController');
		init();

		$scope.contextNotSet = gettextCatalog.getString('context.not.set');
		$scope.contextUnavailable = gettextCatalog.getString('context.unavailable');

		$scope.$on('running', function (event, message) {
			handleSocketMessageJobRunning(message);
		});

		$scope.$on('update:project:selection', function (event) {
			getContexts();
		});

		$scope.$on('isGenericDataMode', function (event, message) {
			$scope.isGenericDataMode = message;
		});

		$scope.$on('update:menu:selection', function (event, message) {
			$scope.isGenericDataMode = message;
			$scope.selectedMenu = $window.sessionStorage.getItem(SELECTED_MENU);
		});

		// Context status
		$scope.$on('context_status_update', function (event, data) {
			updateContextAvailability(data);
		});

		$scope.$on('running', function (event, message) {
			handleSocketMessage(message);
		});

		$scope.changeContext = function (context) {
			$scope.currentContext = context;
			AppSelectorService.setSelectedContext($scope.currentContext.id);
			$state.reload();
		};

		$scope.monitoringContext = function (context) {
			var url = $state.href('monitoring-spark', { ctx: context.id });
			window.open(url, '_blank');
		};

		$scope.getStyle = function (isOk) {
			if (isOk) {
				return { background: '#E9850E !important' };
			}
		};

		$scope.showExpressIntegration = function () {
			permissionChecker.checkGlobalPermission(
				'express_integration',
				function () {
					$rootScope.mappingScope.showMappingFlag = true;
					$rootScope.$broadcast(
						'express-integration',
						$rootScope.mappingScope.showMappingFlag
					);
				}
			);
		};

		/**
		 * private functions
		 */

		function init() {
			if (
				$rootScope.hasMemberRole ||
				$rootScope.hasAdminRole ||
				$rootScope.hasProjectRole
			) {
				getContexts();
			}

			$scope.reloadDataConfirmation = {};
			$scope.mappingScope = {
				showMappingFlag: false,
				saveAction: function (id, lib) {},
			};
			$rootScope.mappingScope = $scope.mappingScope;
			$scope.hasUserRole = $rootScope.keycloak.hasResourceRole(
				USER_ROLE_NAME,
				BACKEND_CLIENT_ID
			);

			$scope.checkPermissionData = {};
			$scope.jobsDataModel = {};
			$scope.showJobs = function () {
				permissionChecker.checkGlobalPermission('job_monitoring', function () {
					$scope.jobsDataModel.showJobs = true;
					$('#jobsModal').modal('show');
				});
			};

			$scope.$on('express-integration', function (evt, val) {
				// FIXME should not have multiple instances of root controller !
				logger.warn('synchronizing multiple instances of RootController');
				$scope.mappingScope.showMappingFlag = val;
			});
		}

		function getContexts() {
			ContextServices.getContextList(false).then(function (response) {
				delete $scope.currentContext;
				if (response.data) {
					$scope.contextList = response.data;
					delete $scope.currentContext;

					if (AppSelectorService.getSelectedCtxId()) {
						for (var ctx in $scope.contextList) {
							// FIXME: possible string to number comparison
							if (
								$scope.contextList[ctx].id ==
								AppSelectorService.getSelectedCtxId()
							) {
								$scope.currentContext = $scope.contextList[ctx];
								break;
							}
						}
					}

					if (!$scope.currentContext && $scope.contextList.length !== 0) {
						$scope.currentContext = $scope.contextList[0];
						AppSelectorService.setSelectedContext($scope.currentContext.id);
						getRunningJobsForSelectedContext();
					}

					if ($scope.currentContext) {
						AppSelectorService.setSelectedContext($scope.currentContext.id);
						getRunningJobsForSelectedContext();
					}
				}
			});
		}

		function updateContextAvailability(message) {
			let ctxId = message.context_id;
			let ctxData = message.data;
			for (var ctx in $scope.contextList) {
				if ($scope.contextList[ctx].id === ctxId) {
					$scope.contextList[ctx] = ctxData;
					break;
				}
			}
			if ($scope.currentContext.id === ctxId) {
				$scope.currentContext = ctxData;
			}
		}

		function handleSocketMessageJobRunning(notif) {
			var job = notif.data;
			for (var ctx in $scope.contextList) {
				if ($scope.contextList[ctx].id == notif.context_id) {
					$scope.contextList[ctx].current_jobs_count =
						notif.running_count + notif.waiting_count;
					break;
				}
			}
			$scope.$apply();
		}

		function getRunningJobsForSelectedContext() {
			SparkJobsServices.getRunningJobsNbr().then(function (response) {
				let data = response.data;
				let jobsCount = {
					running_count: data.v1,
					waiting_count: data.v2,
				};
				displayRunningJobsCount(jobsCount, false);
			});
		}

		function displayRunningJobsCount(notif, refresh) {
			let total = notif.running_count + notif.waiting_count;
			let totalDisplay = total > 99 ? '+99' : total;
			let runningDisplay =
				notif.running_count > 99 ? '+99' : notif.running_count;
			$scope.jobsCount = {
				running: notif.running_count,
				waiting: notif.waiting_count,
				total: total,
				display: runningDisplay + '/' + totalDisplay,
			};
			if (refresh) {
				$scope.$apply();
			}
		}

		function handleSocketMessage(notif) {
			if (AppSelectorService.getSelectedCtxId() == notif.context_id) {
				displayRunningJobsCount(notif, true);
			}
		}
	}
})();
