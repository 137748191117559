(function () {
	'use strict';

	angular.module('dcApp').constant('ELEMENT', {
		type: {
			connector: 'CONNECTOR',
			repository: 'DEPOT',
			entity: 'ENTITETYPE',
			datablock: 'DATABLOCK',
			dashboard: 'DASHBOARD',
			map: 'MAP',
			timeline: 'TIMELINE',
			chart: 'CHART',
			grid: 'GRID',
			media: 'MEDIA',
		},
		stats: {
			connector: 'connector',
			repository: 'repository',
			entity: 'entity',
			datablock: 'datablock',
			dashboard: 'dashboard',
			map: 'map',
			timeline: 'timeline',
			chart: 'chart',
			grid: 'grid',
			media: 'media',
		},
		permissions: {
			connector: 'connector',
			repository: 'data_source',
			entity: 'entity',
			datablock: 'datablock',
			dashboard: 'dashboard',
			map: 'map',
			timeline: 'timeline',
			chart: 'chart',
			grid: 'grid',
			media: 'media',
		},
		dataType: { genericsData: 'gd', handleData: 'hd' },
		actions: {
			edit: 'EDIT',
			create: 'CREATE',
			deleteElem: 'DELETE',
			publication: 'PUBLICATION',
			createAction: {
				datablock: 'DATABLOCK_CREATE',
				connector: 'CONNECTOR_CREATE',
				repository: 'DEPOT_CREATE',
				entity: 'ENTITE_CREATE',
				dashboard: 'DASHBOARD_CREATE',
				map: 'MAP_CREATE',
				chart: 'CHART_CREATE',
				grid: 'GRID_CREATE',
				timeline: 'TIMELINE_CREATE',
				media: 'MEDIA_CREATE',
			},
			editAction: {
				datablock: 'DATABLOCK_EDIT',
				connector: 'CONNECTOR_EDIT',
				repository: 'DEPOT_EDIT',
				entity: 'ENTITE_EDIT',
				dashboard: 'DASHBOARD_EDIT',
				map: 'MAP_EDIT',
				chart: 'CHART_EDIT',
				grid: 'GRID_EDIT',
				timeline: 'TIMELINE_EDIT',
				media: 'MEDIA_EDIT',
			},
			deleteAction: {
				datablock: 'DATABLOCK_DELETE',
				connector: 'CONNECTOR_DELETE',
				repository: 'DEPOT_DELETE',
				entity: 'ENTITE_DELETE',
				dashboard: 'DASHBOARD_DELETE',
				map: 'MAP_DELETE',
				chart: 'CHART_DELETE',
				grid: 'GRID_DELETE',
				timeline: 'TIMELINE_DELETE',
				media: 'MEDIA_DELETE',
			},
			cacheAction: { datablock: 'DATABLOCK_CACHE', entity: 'ENTITE_CACHE' },
			recacheAction: {
				datablock: 'DATABLOCK_RECACHE',
				entity: 'ENTITE_RECACHE',
			},
			publicationAction: { dashboard: 'DASHBOARD_PUBLICATION' },
			uncacheAction: {
				datablock: 'DATABLOCK_UNCACHE',
				entity: 'ENTITE_UNCACHE',
			},
			exposeAction: { datablock: 'DATABLOCK_EXPOSE' },
			exportAction: { datablock: 'DATABLOCK_EXPORT', entity: 'ENTITE_EXPORT' },
			persistAction: {
				datablock: 'DATABLOCK_PERSIST',
				entity: 'ENTITE_PERSIST',
			},
			repersistAction: {
				datablock: 'DATABLOCK_REPERSIST',
				entity: 'ENTITE_REPERSIST',
			},
			unpersistAction: {
				datablock: 'DATABLOCK_UNPERSIST',
				entity: 'ENTITE_UNPERSIST',
			},
			extractAction: { repository: 'DEPOT_EXTRACT_CREATE' },
		},
	});

	angular.module('dcApp').constant('filterValues', {
		persist: 'persist',
		cache: 'cache',
		expose: 'expose',
		export: 'export',
		extract: 'extract',
		publication: 'publication',
		repersist: 'repersist',
		recache: 'recache',
		uncache: 'uncache',
		unpersist: 'unpersist',
	});

	angular.module('dcApp').directive('projectStats', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'ProjectService',
				'GenericService',
				'$state',
				'$timeout',
				'DataBlocksService',
				'gettextCatalog',
				'PermissionService',
				'ELEMENT',
				'filterValues',
				'UPDATE_EVENT',
				function (
					$scope,
					$rootScope,
					ProjectService,
					GenericService,
					$state,
					$timeout,
					DataBlocksService,
					gettextCatalog,
					PermissionService,
					ELEMENT,
					filterValues,
					UPDATE_EVENT
				) {
					var vm = this;
					vm.ELEMENT = ELEMENT;
					vm.MAXCOUNT = 999;
					vm.descriptionTruncateThresh = 600;

					const elementTypeProperties = {
						DATABLOCK: {
							editStateUrl: 'datablocks-edit',
							newStateUrl: 'datablocks-new',
							listStateUrl: 'datablocks',
							creationPermissionCode: 'datablock-create',
						},
						ENTITETYPE: {
							editStateUrl: 'entites-edit',
							newStateUrl: 'entites-new',
							listStateUrl: 'entites',
							creationPermissionCode: 'entity-create',
						},
						DEPOT: {
							editStateUrl: 'depots-edit',
							newStateUrl: 'depots-new',
							listStateUrl: 'depots',
							creationPermissionCode: 'data_source-create',
						},
						CONNECTOR: {
							editStateUrl: 'connectors-edit',
							newStateUrl: 'connectors-new',
							listStateUrl: 'connectors',
							creationPermissionCode: 'connector-create',
						},
						DASHBOARD: {
							editStateUrl: 'dashboards-edit',
							newStateUrl: 'dashboards-new',
							listStateUrl: 'dashboards',
							creationPermissionCode: 'dashboard-create',
						},
						MAP: {
							editStateUrl: 'maps-edit',
							newStateUrl: 'maps-new',
							listStateUrl: 'maps',
							creationPermissionCode: 'map-create',
						},
						TIMELINE: {
							editStateUrl: 'timelines-edit',
							newStateUrl: 'timelines-new',
							listStateUrl: 'timelines',
							creationPermissionCode: 'timeline-create',
						},
						CHART: {
							editStateUrl: 'charts-edit',
							newStateUrl: 'charts-select',
							listStateUrl: 'charts',
							creationPermissionCode: 'chart-create',
						},
						GRID: {
							editStateUrl: 'grids-edit',
							newStateUrl: 'grids-select',
							listStateUrl: 'grids',
							creationPermissionCode: 'grid-create',
						},
						MEDIA: {
							editStateUrl: 'medias-edit',
							newStateUrl: 'medias-select',
							listStateUrl: 'medias',
							creationPermissionCode: 'media-create',
						},
					};

					vm.init = onInit;

					$scope.type = {
						gd: [
							ELEMENT.type.connector,
							ELEMENT.type.repository,
							ELEMENT.type.entity,
							ELEMENT.type.datablock,
						],
						hd: [
							ELEMENT.type.dashboard,
							ELEMENT.type.map,
							ELEMENT.type.timeline,
							ELEMENT.type.chart,
							ELEMENT.type.grid,
							ELEMENT.type.media,
						],
					};

					$scope.elementStats = {
						gd: [
							ELEMENT.stats.connector,
							ELEMENT.stats.repository,
							ELEMENT.stats.entity,
							ELEMENT.stats.datablock,
						],
						hd: [
							ELEMENT.stats.dashboard,
							ELEMENT.stats.map,
							ELEMENT.stats.timeline,
							ELEMENT.stats.chart,
							ELEMENT.stats.grid,
							ELEMENT.stats.media,
						],
					};

					$scope.elementsName = {
						gd: [
							gettextCatalog.getString('Connecteurs'),
							gettextCatalog.getString('Dépots'),
							gettextCatalog.getString('Entités Métier'),
							gettextCatalog.getString('DataBlocks'),
						],
						hd: [
							gettextCatalog.getString('Tableaux de bord'),
							gettextCatalog.getString('Cartes'),
							gettextCatalog.getString('Timelines'),
							gettextCatalog.getString('Graphiques'),
							gettextCatalog.getString('Tableaux'),
							gettextCatalog.getString('Médias'),
						],
					};

					//reload data when change event is emitted from the root scope
					$scope.$on(UPDATE_EVENT['DEPOT'], function (event) {
						if ($scope.currentElement == 'DEPOT')
							$scope.getModifications($scope.currentElement);
					});

					$scope.$on(UPDATE_EVENT['DATABLOCK'], function (event) {
						if ($scope.currentElement == 'DATABLOCK')
							$scope.getModifications($scope.currentElement);
					});

					$scope.$on(UPDATE_EVENT['ENTITETYPE'], function (event) {
						if ($scope.currentElement == 'ENTITETYPE')
							$scope.getModifications($scope.currentElement);
					});

					$scope.currentElement = ELEMENT.type.connector;

					$scope.dataGridOptions = {
						dataSource: $scope.datasource,
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: false,
						columnAutoWidth: true,
						showBorders: true,
						noDataText: gettextCatalog.getString('project.home.no.element'),
						scrolling: {
							mode: 'infinite',
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						filterPanel: {
							visible: true,
						},
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						columns: [
							{
								caption: gettextCatalog.getString('projectStat.column.action'),
								dataField: 'action',
								cellTemplate: 'actionName',
								name: 'action',
								width: '12%',
								alignment: 'center',
								lookup: {
									dataSource: $scope.filterActionsDatasource,
									valueExpr: 'code',
									displayExpr: 'label',
								},
								calculateFilterExpression: function (filterValue) {
									if (!filterValue) return;
									return [this.dataField, 'contains', filterValue];
								},
								allowHeaderFiltering: false,
							},
							{
								caption: gettextCatalog.getString('projectStat.column.label'),
								dataField: 'elementLib',
								cellTemplate: 'iconPerType',
								width: '30%',
							},
							{
								dataField: 'date',
								caption: gettextCatalog.getString('projectStat.column.date'),
								cellTemplate: 'actionDate',
								width: '17%',
								dataType: 'datetime',
								format: 'dd/MM/yyyy HH:mm',
							},
							{
								caption: gettextCatalog.getString('projectStat.column.author'),
								dataField: 'auteur',
								cellTemplate: 'exposeUser',
								width: '10%',
								allowFiltering: false,
							},
						],
						onInitialized: function (e) {
							$scope.gridInstance = e.component;
							$scope.gridInstance.option('syncLookupFilterValues', false);
						},
						onToolbarPreparing: function (e) {
							e.toolbarOptions.items.unshift(
								{
									location: 'before',
									template: 'iconsAndTitle',
								},
								{
									location: 'after',
									widget: 'dxButton',
									options: {
										elementAttr: { id: 'addButton' },
										icon: 'fas fa-plus-circle fa-lg',
										hint: gettextCatalog.getString(
											'projectStat.createNewElement'
										),
										onClick: function () {
											$scope.navigateToSuitableRoutes(
												true,
												$scope.currentElement
											);
										},
									},
								}
							);
						},
					};

					$scope.formatDateAndFilterDeletion = function (object) {
						for (let i = 0; i < object.length; i++) {
							if (object[i].action.includes('DELETE')) {
								if ($scope.deletedElements.indexOf(object[i].elementId) < 0)
									$scope.deletedElements.push(object[i].elementId);
							}
						}
					};

					$scope.getModifications = function (name) {
						$scope.currentElement = name;
						$scope.deletedElements = [];
						$scope.haveTracesPermission = $rootScope.havePermission('traces');
						if (!$scope.haveTracesPermission && !$rootScope.userIsAdmin) {
							return;
						}
						GenericService.getTraceByProjectStats($scope.currentElement).then(
							function (data) {
								$scope.datasource = data;
								$scope.gridInstance.option('dataSource', $scope.datasource);
								$scope.formatDateAndFilterDeletion($scope.datasource);
								$scope.gridInstance.refresh();
								$scope.gridInstance.clearFilter();
							}
						);
						switch (name) {
							case vm.ELEMENT.type.datablock:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.datablock
								);
								break;
							case vm.ELEMENT.type.entity:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.entity
								);
								break;
							case vm.ELEMENT.type.repository:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.repository
								);
								break;
							case vm.ELEMENT.type.connector:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.connector
								);
								break;
							case vm.ELEMENT.type.dashboard:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.dashboard
								);
								break;
							case vm.ELEMENT.type.map:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.map
								);
								break;
							case vm.ELEMENT.type.timeline:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.timeline
								);
								break;
							case vm.ELEMENT.type.chart:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.chart
								);
								break;
							case vm.ELEMENT.type.grid:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.grid
								);
								break;
							case vm.ELEMENT.type.media:
								$scope.gridInstance.columnOption(
									'action',
									'lookup.dataSource',
									$scope.filterActionsDatasource.media
								);
								break;
							default:
								lookup.dataSource = [];
								$scope.gridInstance.columnOption('action', 'lookup', lookup);
						}
						hideDisplayAddButton();
					};

					$scope.navigate = function (route, param) {
						$timeout(function () {
							if (param) $state.go(route, param);
							else $state.go(route);
						}, 300);
					};

					$scope.navigateToSuitableRoutes = function (
						isNew,
						dcType,
						elementId,
						subType
					) {
						let editStateUrl = elementTypeProperties[dcType].editStateUrl;
						let newStateUrl = elementTypeProperties[dcType].newStateUrl;
						let listStateUrl = elementTypeProperties[dcType].listStateUrl;
						let editParams = { id: elementId };
						if (dcType == 'ENTITETYPE') {
							editParams = { entiteId: elementId };
						}
						if (subType) {
							editParams.type = subType;
						}
						if (elementId) {
							$scope.navigate(editStateUrl, editParams);
						} else if (isNew) {
							$scope.navigate(newStateUrl);
						} else {
							$scope.navigate(listStateUrl);
						}
					};

					$scope.editProject = function () {
						$state.go('projects-edit', { projectId: vm.projectId });
					};

					function hideDisplayAddButton() {
						PermissionService.haveGlobalPermission(
							elementTypeProperties[$scope.currentElement]
								.creationPermissionCode
						).then(function (response) {
							$('#addButton')
								.dxButton('instance')
								.option('visible', response.data);
						});
					}

					function onInit() {
						$scope.static_data = $rootScope.static_elements_data;
						if (vm.module === ELEMENT.dataType.genericsData) {
							$scope.currentElement = ELEMENT.type.connector;
							$scope.elementsName = $scope.elementsName.gd;
							$scope.elementStats = $scope.elementStats.gd;
							$scope.type = $scope.type.gd;
						}
						if (vm.module === ELEMENT.dataType.handleData) {
							$scope.currentElement = ELEMENT.type.dashboard;
							$scope.elementsName = $scope.elementsName.hd;
							$scope.elementStats = $scope.elementStats.hd;
							$scope.type = $scope.type.hd;
						}

						if (!vm.projectId) {
							return;
						}

						ProjectService.getProjectInfo(vm.projectId).then(function (data) {
							$scope.projectStats = data.projectStats;
							$scope.filterActionsDatasource = data.actionsFilterDataSource;
							$scope.getModifications($scope.currentElement);
							vm.module === ELEMENT.dataType.genericsData
								? ($scope.stats = data.gd)
								: ($scope.stats = data.hd);
						});
					}
				},
			];
			return {
				restrict: 'E',

				scope: {
					module: '=',
					projectId: '<',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/projectStat/projectStat.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init(attrs);
					$ctrl.metadataPopover = {};
				},
			};
		},
	]);
})();
