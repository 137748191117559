(function () {
	'use strict';

	angular.module('dcApp').constant('duplicateStatus', {
		status: {
			doNotduplicate: 'doNotduplicate',
			duplicate: 'duplicate',
		},
	});

	angular.module('dcApp').directive('datablocksExpositions', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$rootScope',
				'elem',
				'duplicateStatus',
				'DataBlocksService',
				function (
					$log,
					$scope,
					$rootScope,
					elem,
					duplicateStatus,
					DataBlocksService
				) {
					var vm = this;

					vm.elem = elem;
					vm.duplicateStatus = duplicateStatus;

					vm.init = init;

					vm.applySuffix = function (suffix) {
						let suffixConf = suffix ? suffix : '';
						for (let i = 0; i < vm.expositions.length; i++) {
							if (vm.expositions[i].checked) {
								vm.expositions[i].newAccessPoint =
									vm.expositions[i].access_point + suffixConf;
							}
						}
					};

					vm.doNotDuplicateExposures = function () {
						vm.duplicateExpose = vm.duplicateStatus.status.doNotduplicate;
					};

					vm.duplicateExposures = function () {
						vm.duplicateExpose = vm.duplicateStatus.status.duplicate;
					};

					vm.checkAllElements = function () {
						for (const exposition of vm.expositions) {
							exposition.checked = !!vm.checkAll;
						}
					};

					vm.duplicateExposition = function (exposition) {
						vm.data.push({
							new_endpoint: exposition.newAccessPoint,
							datablock_id: exposition.datablock_id,
						});
					};

					$scope.$watch(
						'vm.expositions',
						function (expositions) {
							if (expositions) {
								vm.data = vm.expositions
									.filter((p) => p.checked)
									.map((p) => {
										return {
											new_endpoint: p.newAccessPoint,
											datablock_id: p.datablock_id,
										};
									});
								let endPointsList = [];
								for (const exposition of vm.expositions) {
									endPointsList.push(exposition.newAccessPoint);
								}
								DataBlocksService.getDuplicatedEndpoints(endPointsList)
									.then((response) => {
										vm.duplicatedEndpoints = response.data;
										vm.invalid = vm.duplicatedEndpoints.length > 0;
									})
									.catch((error) => {
										$log.error(error);
									});
							}
						},
						true
					);

					vm.checkEndPointUnicity = function (endpoint, index) {
						vm.filteredEndpoints = vm.expositions
							.filter((p) => p.newAccessPoint)
							.map((p) => {
								return p.newAccessPoint;
							});
						let counts = {};
						counts[endpoint] = 0;
						for (let ep of vm.filteredEndpoints) {
							if (ep === endpoint) {
								counts[endpoint] = counts[endpoint] + 1;
							}
						}
						vm.expositions[index].isUnique = counts[endpoint] <= 1;
					};

					function init() {
						vm.duplicateExpose = vm.duplicateStatus.status.doNotduplicate;
						vm.pattern = /^[a-zA-Z0-9_]*$/;
						vm.suffix = '';
						vm.data = [];
						vm.duplicatedEndpoints = [];
						vm.invalid = vm.invalid || false;
						vm.duplicatedExposuresRootCode = vm.duplicatedExposuresRootCode || false;
						vm.exposuresRootCode = '';
					}
				},
			];
			return buildDirectiveReturnObject(
				{
					data: '=',
					duplicateExposures: '=',
					notDuplicate: '=',
					expositions: '=',
					from: '=',
					endPoints: '=',
					invalid: '=?',
				},
				controller,
				'./src/components/directives/duplicateProject/datablockExpositions.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();

					initElementForDirective(element, attrs, function () {});
					watchElementForDirective(
						element,
						scope,
						function () {},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
