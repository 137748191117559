(function () {
	'use strict';

	angular.module('dcApp').directive('selectEntiteCaracs', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'TypeEntiteService',
				'$sce',
				function ($scope, TypeEntiteService, $sce) {
					var vm = this;

					$scope.init = function () {
						$scope.caracs = [];
						TypeEntiteService.getEntiteCaracs(
							vm.widgetData.selectEntiteCaracsEntiteId
						).then(function (response) {
							$scope.caracs = _.filter(response.data, function (item) {
								return !item.complex;
							});
							for (var c in $scope.caracs) {
								$scope.caracs[c].selected = true;
							}
							$scope.selectAll = true;
						});
					};

					$scope.toogleSelectAll = function () {
						var select = $scope.selectAll;
						for (var c in $scope.caracs) {
							$scope.caracs[c].selected = select;
						}
					};

					$scope.getHtmlIconByCaracType = function (type) {
						if (type != undefined) {
							type = getTypeFromEntiteTypeCode(type);
							return $sce.trustAsHtml(getHtmlIconByType(type));
						}
					};

					$scope.save = function () {
						var selectedCaracs = _.filter($scope.caracs, function (item) {
							return item.selected;
						});
						vm.widgetData.updateaction_l3(selectedCaracs);
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showSelectEntiteCaracs = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/selectEntiteCaracs/selectEntiteCaracs.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showSelectEntiteCaracs = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showSelectEntiteCaracs;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
