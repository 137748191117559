(function () {
	'use strict';

	angular.module('dcApp').directive('piechartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;

					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'pie',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope($scope, vm, 'pie');

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					var loadGroupDataInChart = function (data) {
						let column = _.find($scope.group.columns, function (item) {
							return item.uuid == $scope.config.global.value;
						});
						initPopover();
						let series = [
							getSerie(
								$scope.config.global.argument,
								$scope.config.global.value,
								column.lib
							),
						];

						for (var s in $scope.config.global.series) {
							series.push(
								getSerie(
									$scope.config.global.argument,
									$scope.config.global.series[s].valueField,
									$scope.config.global.series[s].name
								)
							);
						}

						$scope.config.global.series = series;

						$scope.config.global.onPointClick = function (e) {
							var point = e.target;
							toggleVisibility(point);
						};

						$scope.config.global.onLegendClick = function (e) {
							var arg = e.target;
							toggleVisibility(this.getAllSeries()[0].getPointsByArg(arg)[0]);
						};
						if ($scope.config.global.clickActive) {
							$scope.config.global.onLegendClick = function (e) {
								let arg = e.target;
								toggleVisibility(this.getAllSeries()[0].getPointsByArg(arg)[0]);
							};
							delete $scope.config.global.clickActive;
						}
						$scope.config.global.tooltipColumns = _.filter(
							$scope.config.global.tooltipColumns,
							function (item) {
								return _.find($scope.group.columns, function (col) {
									return col.uuid == item;
								});
							}
						);
						if (
							$scope.config.global.tooltipColumns &&
							$scope.config.global.tooltipColumns[0]
						) {
							$scope.config.global.tooltip = {
								enabled: true,
								format: 'fixedPoint',
								customizeTooltip: function (arg) {
									return {
										html: ChartService.getTooltip(
											arg.point.data,
											$scope.config.global.tooltipColumns,
											$scope.group.columns,
											$scope.config.global.argumentAxis
										),
									};
								},
							};
						}

						delete $scope.config.global.valuesGroupingMode;
						delete $scope.config.global.connectorVisible;
						delete $scope.config.global.valuesGroupingThreshold;

						$scope.dxChartOptions = $scope.config.global;
						$scope.dxChartOptions.dataSource = data;

						$('#chart' + $scope.uuid).dxPieChart($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxPieChart('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxPieChart('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var toggleVisibility = function (item) {
						if (item.isVisible()) {
							item.hide();
						} else {
							item.show();
						}
					};

					var getSerie = function (argument, value, name) {
						var serie = { argumentField: argument, valueField: value };
						serie.label = {
							visible: $scope.config.global.labelVisible,
							connector: {
								visible: $scope.config.global.connectorVisible,
								width: 1,
							},
							customizeText: function (arg) {
								return arg.argumentText + ' (' + arg.percentText + ')';
							},
						};
						serie.smallValuesGrouping = {
							mode: $scope.config.global.valuesGroupingMode,
						};
						if ($scope.config.global.valuesGroupingMode == 'topN') {
							serie.smallValuesGrouping.topCount =
								$scope.config.global.valuesGroupingThreshold;
						} else {
							serie.smallValuesGrouping.threshold =
								$scope.config.global.valuesGroupingThreshold;
						}
						return serie;
					};

					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/pie/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
