(function () {
	'use strict';

	angular.module('dcApp').directive('mediaitem', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'toaster',
				'MediaService',
				'MEDIA_UPLOAD_PDF_MAX_SIZE',
				'MEDIA_UPLOAD_IMAGE_MAX_SIZE',
				'gettextCatalog',
				function (
					$scope,
					toaster,
					MediaService,
					MEDIA_UPLOAD_PDF_MAX_SIZE,
					MEDIA_UPLOAD_IMAGE_MAX_SIZE,
					gettextCatalog
				) {
					let vm = this;
					vm.translator = gettextCatalog;
					let acceptedImageTypes = [
						'image/jpeg',
						'image/jpg',
						'image/png',
						'image/gif',
					];
					let acceptedPdfTypes = ['application/pdf'];
					let errorTra = gettextCatalog.getString('Erreur');
					let toasterPopSuccessTitle = gettextCatalog.getString('Succès');
					let toasterPopSuccessMsg = gettextCatalog.getString(
						"Enregistrement du l'élément Média effectuée"
					);

					$scope.save = function () {
						let saveService;
						if ($scope.media.binary && $scope.media.file) {
							if (vm.data.type == 'pdf') {
								if ($scope.media.file.size > MEDIA_UPLOAD_PDF_MAX_SIZE) {
									toaster.pop(
										'error',
										errorTra,
										gettextCatalog.getString(
											'Le fichier choisi est trop volumineux. Il doit avoir une taille inférieur à 300 Mo'
										)
									);
									return;
								}
								if (acceptedPdfTypes.indexOf($scope.media.file.type) < 0) {
									toaster.pop(
										'error',
										errorTra,
										gettextCatalog.getString(
											"Ce type de fichier n'est pas compatible."
										)
									);
									return;
								}
							}

							if (vm.data.type == 'image') {
								if ($scope.media.file.size > MEDIA_UPLOAD_IMAGE_MAX_SIZE) {
									toaster.pop(
										'error',
										errorTra,
										gettextCatalog.getString(
											'Le fichier choisi est trop volumineux. Il doit avoir une taille inférieur à 100 Mo'
										)
									);
									return;
								}
								if (acceptedImageTypes.indexOf($scope.media.file.type) < 0) {
									toaster.pop(
										'error',
										errorTra,
										gettextCatalog.getString(
											"Ce type de fichier n'est pas compatible."
										)
									);
									return;
								}
							}
							saveService = $scope.media.id
								? MediaService.editUploadedMediaItem(
										$scope.media,
										vm.data.mediaId,
										$scope.media.id
								  )
								: MediaService.createUploadedMediaItem(
										$scope.media,
										vm.data.mediaId
								  );
						} else {
							saveService = $scope.media.id
								? MediaService.editMediaItem(
										$scope.media,
										vm.data.mediaId,
										$scope.media.id
								  )
								: MediaService.createMediaItem($scope.media, vm.data.mediaId);
						}

						saveService.then(function (response) {
							vm.data.save($scope.media);
							toaster.pop(
								'success',
								toasterPopSuccessTitle,
								toasterPopSuccessMsg
							);
							$scope.closeModal();
						});
					};

					$scope.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showMediaItem = false;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/mediaItem/mediaItem.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showMediaItem = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showMediaItem;
						},
						function () {
							scope.media = scope.vm.data.media;
							let mediaElmType =
								scope.vm.translator.getString("à l'élément Média");
							let addOrUpdateEl =
								scope.vm.translator.getString('Ajout/Modification');
							if (scope.vm.data.type == 'pdf') {
								scope.title =
									addOrUpdateEl +
									' ' +
									scope.vm.translator.getString("d'un PDF") +
									' ' +
									mediaElmType;
							} else {
								scope.title =
									addOrUpdateEl +
									' ' +
									scope.vm.translator.getString("d'une image") +
									' ' +
									mediaElmType;
							}
							scope.accept =
								scope.vm.data.type == 'pdf'
									? '.pdf'
									: '.jpg, .png, .jpeg, .gif, .svg, .tif';
							$('#mediaFile').val('');
						}
					);
				}
			);
		},
	]);
})();
