var initializeMedia = function (
	type,
	scope,
	MediaService,
	hdSourceService,
	checkConfig,
	loadMedia,
	ignoreCache,
	PermissionService,
	gettextCatalog
) {
	scope.dataMessage = false;
	scope.uuid = generateUuid('_');
	scope.disableDataSource = true;
	scope.mediaType = type;

	if (!scope.vm.data.full_data) {
		PermissionService.havePermission(
			scope.vm.data.mediaId,
			'edit',
			'media'
		).then(function (response) {
			scope.editIsPermitted = response.data;
		});
		MediaService.getMediaConfig(type, scope.vm.data.mediaId).then(function (
			response
		) {
			scope.config = response.data;
			MediaService.getAllMediaItems(scope.vm.data.mediaId).then(function (
				itemsResponse
			) {
				scope.config.global_config.items = itemsResponse.data;
				if (!checkConfig(scope.config)) {
					scope.dataMessage = gettextCatalog.getString(
						'Le Média ne peut pas être visualisé. Certains paramètres ne sont pas valides.'
					);
					return;
				}
				loadMedia();
			});
		});
	} else {
		scope.hideCommonMenu = true;
		switch (type) {
			case 'pdf':
				scope.config = scope.vm.data.full_data.pdf;
				break;
			case 'image':
				scope.config = scope.vm.data.full_data.image;
				break;
		}
		scope.config.global_config.items = scope.vm.data.full_data.items;
		loadMedia();
	}
};

var initializeMediaDirectiveScope = function (
	scope,
	$state,
	vm,
	$window,
	type
) {
	scope.rerun = function () {
		vm.init(false);
	};

	scope.rerunWithoutCache = function () {
		vm.init(true);
	};

	scope.switchDataVisibility = function () {
		scope.showTableData = !scope.showTableData;
	};

	scope.edit = function () {
		const url = $state.href('medias-edit', {
			type: type,
			id: scope.vm.data.mediaId,
		});
		$window.open(url, '_blank');
		return;
	};
};
