(function () {
	'use strict';

	angular.module('dcApp').controller('MonitoringController', [
		'MonitoringServices',
		'$scope',
		'$rootScope',
		'$stateParams',
		'ContextServices',
		'$window',
		'CTX_VAR_NAME',
		'permissionChecker',
		function (
			MonitoringServices,
			$scope,
			$rootScope,
			$stateParams,
			ContextServices,
			$window,
			CTX_VAR_NAME,
			permissionChecker
		) {
			$scope.context = $window.sessionStorage.getItem(CTX_VAR_NAME);

			var initMonitoring = function () {
				$scope.showBackend = true;
				$scope.showSpark = true;
				if ($stateParams.ctx !== undefined) {
					$scope.showBackend = false;
					$scope.context = $stateParams.ctx;
				}

				var conxt = _.find($scope.contextList, function (item) {
					return item.id == $scope.context;
				});

				$scope.contextName = conxt.lib;
			};

			var init = function () {
				ContextServices.getContextList(true).then(function (response) {
					$scope.contextList = response.data;
					initMonitoring();
				});
			};

			permissionChecker.checkGlobalPermission(
				'metrics',
				function () {
					init();
				},
				function () {
					$scope.metrics_unauthorised = true;
				}
			);
		},
	]);
})();
