(function () {
	angular.module('dcApp').controller('AccessDeniedController', [
		'$scope',
		'$rootScope',
		function ($scope, $rootScope) {
			$scope.logout = function () {
				$rootScope.logout();
			};
		},
	]);
})();
