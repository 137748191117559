(function () {
	'use strict';

	angular.module('dcApp').factory('HdhElementInfoService', [
		'$http',
		'API_BASE_URL_BACKEND',
		function ($http, API_BASE_URL_BACKEND) {
			var HdhElementInfoService = {};

			HdhElementInfoService.getEntiteInfo = function (id) {
				const url = API_BASE_URL_BACKEND + 'entite_info/' + id;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			HdhElementInfoService.getDataBlockInfo = function (id) {
				const url = API_BASE_URL_BACKEND + 'datablock_info/' + id;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return HdhElementInfoService;
		},
	]);
})();
