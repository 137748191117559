(function () {
	angular.module('dcApp').directive('hostValidator', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attributes, control) {
				control.$validators.hostValidator = function (modelValue) {
					/*  if (modelValue == undefined || modelValue.length === 0 || modelValue.length > 511) {
                        return  false;
                    }*/
					var regExpIp = new RegExp(
						'^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
					);
					var regResultIp = regExpIp.exec(modelValue);
					var regExpHostname = new RegExp(
						/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
					); // RFC 1123
					var regResultHostname = regExpHostname.exec(modelValue);
					if (regResultIp === null && regResultHostname === null) {
						return false;
					}
					return true;
				};
			},
		};
	});
})();
