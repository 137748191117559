var loadDataBlockFromGrammar = function (wid, grammar) {
	wid.process_data = grammar.process_data;
	wid.process_count = grammar.process_count;
	wid.from_datablock = grammar.from_datablock;
	wid.gridHeight = 400;
	wid.grammar = grammar.base_grammar;
};

var setDataBlockFromGrammar = function (wid, grammar) {
	wid.process_data = grammar.process_data;
	wid.process_count = grammar.process_count;
	wid.gridHeight = 400;
	wid.grammar = grammar.base_grammar;
	wid.grammar.take_max_row = grammar.take_max_row;
	wid.limit_max_row = grammar.limit;
	wid.from_datablock = grammar.from_datablock;
	return wid;
};

var buildDataBlock = function (dbObject, dbName, db_id) {
	return {
		type: 'TABLE',
		name: dbName,
		id: db_id,
		grammar: dbObject != undefined ? dbObject.grammar : undefined,
	};
};

var getMaxId = function (columns) {
	var columnId = 0;
	for (var c in columns) {
		if (columns[c].id > columnId) {
			columnId = columns[c].id;
		}
	}
	return columnId;
};

var deleteImpactedRules = function (column_ref, rule) {
	if (rule == undefined) {
		return;
	}
	if (rule.rules != undefined && rule.rules.length != 0) {
		for (var r in rule.rules) {
			if (rule.rules[r].column_ref == column_ref) {
				rule.rules.splice(r, 1);
			} else {
				deleteImpactedRules(column_ref, rule.rules[r]);
			}
		}
	}
};

var updateFormulaDeleteImpact = function (formula, columnIndex) {
	if (formula.type == 'function' && formula.params) {
		for (var p in formula.params) {
			updateFormulaDeleteImpact(formula.params[p], columnIndex);
		}
	} else if (formula.type == 'column' && formula.value) {
		var colIndice = formula.value.substring(4, formula.value.length);
		colIndice = Number(colIndice);
		if (colIndice == columnIndex) {
			delete formula.value;
		} else if (colIndice > columnIndex) {
			formula.value = 'COLL' + (colIndice - 1);
		}
	}
};

var updateWidgetColumnDeleteImpact = function (
	widget,
	columnId,
	columnIndex,
	tmpColumns
) {
	deleteImpactedRules(columnId, widget.grammar.rules);
	if (widget.grammar.rules) {
		for (var r in widget.grammar.rules.rules) {
			if (
				widget.grammar.rules.rules[r].condition &&
				widget.grammar.rules.rules[r].rules &&
				widget.grammar.rules.rules[r].rules.length == 0
			) {
				widget.grammar.rules.rules.splice(r, 1);
			}
		}
	}

	var rules = [];

	getFlattenRules(rules, widget.grammar.rules);

	for (let k in rules) {
		if (rules[k].condition && rules[k].rules && rules[k].rules.length == 0) {
			for (var rr in rules[k].rules) {
				if (
					rules[k].rules[rr].condition &&
					rules[k].rules[rr].rules &&
					rules[k].rules[rr].rules.length == 0
				) {
					rules[k].rules.splice(rr, 1);
				}
			}
		}
	}

	if (widget.grammar.columns_) {
		widget.grammar.columns_ = deleteImpactedElement(
			widget.grammar.columns_,
			columnIndex,
			'col'
		);
	}

	if (widget.grammar.columns_tmp) {
		widget.grammar.columns_tmp = deleteImpactedElement(
			widget.grammar.columns_tmp,
			columnIndex,
			'col'
		);
	}

	for (var c in tmpColumns) {
		if (tmpColumns[c].formula) {
			updateFormulaDeleteImpact(tmpColumns[c].formula, columnIndex);
		}
	}
};

var deleteImpactedElement = function (list, value, field) {
	var list_ = [];
	for (var i in list) {
		if ((field == 'pos' || field == 'col') && list[i][field] > value) {
			var v = angular.copy(list[i]);
			v[field] = v[field] - 1;
			list_.push(v);
		} else if (list[i][field] != value) {
			list_.push(list[i]);
		}
	}
	return list_;
};

var restoreWidgetState = function (widget) {
	if (widget && widget.state) {
		for (var i in widget.columnDefs) {
			var conf = _.find(widget.state.columns_state, function (elm) {
				return elm.field == widget.columnDefs[i].name;
			});
			if (conf != undefined && conf.width != undefined) {
				widget.columnDefs[i].width = conf.width + '%';
			}
		}
	}
};

var getFunctions = function (functions, groups) {
	for (var f in functions) {
		for (var g in groups) {
			if (groups[g].pos == functions[f].pos) {
				// functions[f].func = 'COUNT';
				break;
			}
		}
	}
	return functions;
};

var buildDataBlockGrammar = function (
	grammar,
	globalSearchText,
	hidenGlobalSearchText,
	globalSearchTextIgnoreCase,
	innerJoin,
	limit
) {
	var procGrammar = {};
	procGrammar.base_grammar = angular.copy(grammar);
	procGrammar.base_grammar.inner_join = innerJoin;
	procGrammar.global_filter = [];
	procGrammar.take_max_row =
		procGrammar.base_grammar != undefined &&
		procGrammar.base_grammar.take_max_row != undefined
			? procGrammar.base_grammar.take_max_row
			: 1500;
	procGrammar.limit = limit;

	delete procGrammar.base_grammar.take_max_row;

	if (globalSearchText) {
		procGrammar.global_filter.push(globalSearchText);
	}
	if (hidenGlobalSearchText) {
		procGrammar.global_filter.push(hidenGlobalSearchText);
	}
	procGrammar.global_filter_ignore_case = globalSearchTextIgnoreCase;

	return procGrammar;
};

var getEntiteTypesFromGrammar = function (grammar) {
	if (!grammar || !grammar.columns) {
		return [];
	}
	var entiteTypeList = [];

	for (var c in grammar.columns) {
		entiteTypeList.push(grammar.columns[c].parent_entite_type_id);
		entiteTypeList = entiteTypeList.concat(
			getEntiteTypesFromPath(grammar.columns[c].path)
		);
	}

	var rules = [];
	getFlattenRules(rules, grammar.rules);
	for (var r in rules) {
		if (rules[r].parent_entite_type_id) {
			entiteTypeList.push(rules[r].parent_entite_type_id);
		}
		entiteTypeList = entiteTypeList.concat(
			getEntiteTypesFromPath(rules[r].path)
		);
	}

	return entiteTypeList;
};

var getEntiteTypesFromPath = function (path) {
	var list = [];
	if (!path) {
		return [];
	}
	var ids = path.split('-');
	for (var id in ids) {
		if (ids[id].indexOf('c') < 0) {
			list.push(Number(ids[id]));
		}
	}
	return list;
};

var getDataPosition = function (columns, index) {
	return index;
};

var getSorts = function (wid) {
	return wid.sorts;
};

var getHtmlIconByType = function (type, isList) {
	var data = '<span></span>';
	if (type != undefined) {
		type = type.toLowerCase();
		switch (type) {
			case 'string':
				data =
					'<span class="widget-type-icon color-green cursor-default" title="Chaîne de caractères">S</span>';
				break;
			case 'file':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #6cd003;" title="Fichier">F</span>';
				break;
			case 'decimal':
				data =
					'<span class="widget-type-icon color-red cursor-default" title="Nombre décimal">De</span>';
				break;
			case 'double':
				data =
					'<span class="widget-type-icon color-red cursor-default" title="Nombre décimal">De</span>';
				break;
			case 'date':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #6781ff;" title="Date">Da</span>';
				break;
			case 'integer':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #ff3b00;" title="Nombre entier (<2 Md)">I</span>';
				break;
			case 'big_integer':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #ffb443;" title="Grand nombre entier">B.I</span>';
				break;
			case 'boolean':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #ae43ff;" title="Booléen">B</span>';
				break;
			case 'binary':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #00d068;" title="Binaire">Bn</span>';
				break;
			case 'words':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #009ed0;" title="Mots">W</span>';
				break;
			case 'geometry':
				data =
					'<span class="widget-type-icon cursor-default" style="color: #f9017f;" title="Géométrie">Ge</span>';
				break;
			default:
				break;
		}
		if (isList) {
			data =
				'<span class="widget-type-icon cursor-default" style="color: #0d0b04;" title="List">L (' +
				getHtmlIconByType(type, false) +
				')</span>';
		}
	}

	return data;
};

var getTypeLabel = function (type) {
	switch (type) {
		case 'string':
			return 'Chaîne de caractères';
		case 'file':
			return 'Fichier';
		case 'decimal':
			return 'Nombre décimal';
		case 'double':
			return 'Nombre décimal';
		case 'date':
			return 'Date';
		case 'integer':
			return 'Nombre entier (<2 Md)';
		case 'big_integer':
			return 'Grand nombre entier';
		case 'boolean':
			return 'Booléen';
		case 'binary':
			return 'Binaire';
		case 'words':
			return 'Mots';
		case 'geometry':
			return 'Géométrie';
		default:
			break;
	}
};

function prepareWidgetForFormulaEditor(
	widgetData,
	saveFormulasDataQuality,
	caracs
) {
	var widget = {};
	widget.grammar = {};
	widget.grammar.columns = [];
	for (var c in caracs) {
		var col = {
			column_alias: caracs[c].lib,
			type: 'string',
			field: caracs[c].lib,
			value: caracs[c].code,
		};
		widget.grammar.columns.push(col);
	}
	widgetData.widget = widget;
	widgetData.rootFunctionType = 'boolean';
	widgetData.widget.grammar.columnsTemp = angular.copy(
		widgetData.widget.grammar.columns
	);
	widgetData.updateaction = saveFormulasDataQuality;

	widgetData.widgetMenuOpenAction = {};
	widgetData.widgetMenuData = {};
	widgetData.hideWidgetMenu = true;
	widgetData.impact = true;
}

var setFormulaColumnIndex = function (formula, columns) {
	if (formula.type == 'function' && formula.params) {
		for (var p in formula.params) {
			setFormulaColumnIndex(formula.params[p], columns);
		}
	} else if (formula.type == 'column') {
		for (var c in columns) {
			if (columns[c].uuid.toUpperCase() == formula.value.toUpperCase()) {
				formula.value = 'COLL' + c;
				break;
			}
		}
	}
};

var setFormulaColumnUuid = function (formula, columns) {
	if (formula.type == 'function' && formula.params) {
		for (var p in formula.params) {
			setFormulaColumnUuid(formula.params[p], columns);
		}
	} else if (formula.type == 'column') {
		for (var c in columns) {
			if ('COLL' + c == formula.value) {
				if (columns[c].uuid) {
					formula.value = columns[c].uuid;
				} else if (columns[c].formula) {
					formula.value = columns[c].formula.uuid;
				}
				break;
			}
		}
	}
};

var addFilter = function (grammar, columnUuid, type, value, operator) {
	var step = {
		filter: {
			condition: 'AND',
			rules: [
				{
					column_alias: 'COL_' + columnUuid,
					type: type,
					value: value,
					operator: operator,
				},
			],
		},
	};
	if (!grammar.steps) {
		grammar.steps = [];
	}
	grammar.steps.push(step);
};

var getAllCenteringMap = function (elements, uuid) {
	let element_centerings = { columns: {}, modes: {} };
	for (var e in elements) {
		if (elements[e].centering && elements[e].centering[uuid]) {
			for (var c in elements[e].centering[uuid]) {
				element_centerings.columns[c] = elements[e].centering[uuid][c];
				element_centerings.modes[c] = elements[e].centering_modes[uuid];
			}
		}
	}
	return element_centerings;
};

var getCenteringTooltip = function (centering) {
	let operator = rules_operators[centering.operator];
	let value = '';
	if (!centering.value) {
		return centering.label + ' : ' + operator;
	}
	if (centering.type == 'string') {
		value = centering.value.string_value;
	} else if (centering.type == 'date') {
		value =
			centering.operator != 'between' && centering.operator != 'not_between'
				? centering.value.date_value
				: centering.value.between_date_value1 +
				  ' , ' +
				  centering.value.between_date_value2;
	} else if (centering.type == 'numeric') {
		value =
			centering.operator != 'between' && centering.operator != 'not_between'
				? centering.value.numeric_value
				: centering.value.between_value1 +
				  ' , ' +
				  centering.value.between_value2;
	}
	value = value ? value : '';
	return centering.label + ' : ' + operator + ' ' + value;
};

var safeDeletedColumnStepImpact = function (deletedColumns, steps, stepPos) {
	let allRelatedColumns = deletedColumns;
	let nextStepIndex = stepPos != undefined ? stepPos + 1 : 0;
	for (let s = nextStepIndex; s < steps.length; s++) {
		if (steps[s].input) {
			let relatedColumns = _.filter(steps[s].input.columns, function (item) {
				return allRelatedColumns.indexOf(item.column_uuid) > -1;
			});
			for (let c in relatedColumns) {
				allRelatedColumns.push(relatedColumns[c].uuid);
			}
		}
	}
	for (var s = stepPos != undefined ? stepPos : 0; s < steps.length; s++) {
		if (steps[s].sort && steps[s].sort.sorts) {
			steps[s].sort.sorts = _.filter(steps[s].sort.sorts, function (item) {
				return allRelatedColumns.indexOf(item.column_uuid) < 0;
			});
		}
		if (steps[s].output) {
			steps[s].output = _.filter(steps[s].output, function (item) {
				return allRelatedColumns.indexOf(item.column_uuid) < 0;
			});
		}
		if (steps[s].input && stepPos != s) {
			steps[s].input.columns = _.filter(
				steps[s].input.columns,
				function (item) {
					return allRelatedColumns.indexOf(item.column_uuid) < 0;
				}
			);
		}
	}
};

var updateColumnLibInSteps = function (startIndex, steps, uuid, lib, oldLib) {
	for (let i = startIndex; i < steps.length; i++) {
		if (steps[i].output) {
			for (var c in steps[i].output) {
				if (
					steps[i].output[c].column_uuid == uuid &&
					steps[i].output[c].lib == oldLib
				) {
					steps[i].output[c].lib = lib;
				}
			}
		}
	}
};

var getCenteringsFromConfig = function (dashboardConfig) {
	let centerings = [];
	for (let c in dashboardConfig.global.centerings) {
		let cent = dashboardConfig.global.centerings[c];
		if (cent.inactive) {
			continue;
		}
		if (
			cent.value.list_string_value &&
			cent.value.list_string_value[0] &&
			cent.value.list_string_value[0].text
		) {
			cent.value.list_string_value = _.map(
				cent.value.list_string_value,
				function (item) {
					return item.text;
				}
			);
		}
		centerings.push({
			operator: cent.operator,
			value: cent.value,
			uuid: cent.uuid,
		});
	}
	return centerings;
};
