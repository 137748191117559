(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller(
			'DisplayDataFromDepotController',
			DisplayDataFromDepotController
		);

	DisplayDataFromDepotController.$inject = [
		'$log',
		'$timeout',
		'DepotFileService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
	];
	function DisplayDataFromDepotController(
		$log,
		$timeout,
		DepotFileService,
		PAGINATIONS_SIZES,
		PAGINATIONS_SIZE
	) {
		const vm = this;

		vm.binaryType = 'BINARY';
		vm.gridOptions = {};
		vm.gridData = [];
		vm.gridOptions.pager = {
			showPageSizeSelector: true,
			allowedPageSizes: PAGINATIONS_SIZES,
			showInfo: true,
			visible: true,
		};
		vm.gridOptions.paging = {
			enabled: true,
			pageSize: PAGINATIONS_SIZE,
		};
		vm.gridOptions.filterRow = {
			visible: true,
			applyFilter: 'auto',
		};
		vm.gridOptions.rowAlternationEnabled = true;
		vm.gridOptions.headerFilter = {
			visible: true,
			applyFilter: 'auto',
		};
		vm.gridOptions.columnChooser = {
			enabled: true,
		};
		vm.gridOptions.showColumnLines = true;
		vm.gridOptions.showRowLines = true;
		vm.gridOptions.allowColumnReordering = true;
		vm.gridOptions.allowColumnResizing = true;
		vm.gridOptions.columnAutoWidth = true;
		vm.gridOptions.showBorders = true;
		vm.gridOptions.onInitialized = function (e) {
			vm.gridInstance = e.component;
		};
		init();

		function init() {
			getDataPreviewForDepot(vm.depot, vm.depotType);
		}

		vm.initGridData = function () {
			let generatedColumns = [];
			//Create Grid Columns
			let headearsWithNoSpecChar = [];
			for (let i = 0; i < vm.entiteTypeFileHeader.length; i++) {
				let columnName = vm.entiteTypeFileHeader[i];
				let columnDepName =
					vm.entiteTypeFileHeader[i] != undefined
						? vm.entiteTypeFileHeader[i]
						: '';
				let headerNoSpecChar = columnName
					.replace(/[^a-zA-Z0-9_-]/g, '_')
					.toLowerCase();
				headearsWithNoSpecChar[i] = headerNoSpecChar;
				if (i < 10) {
					if (vm.selectedColumn === columnName) {
						vm.moreThanTenColumns = false;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
							fixed: true,
							cssClass: 'bold_column',
						});
					} else {
						vm.moreThanTenColumns = false;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
						});
					}
				} else {
					if (vm.selectedColumn === columnName) {
						vm.moreThanTenColumns = true;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
							fixed: true,
							cssClass: 'bold_column',
						});
					} else {
						vm.moreThanTenColumns = true;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
							visible: false,
						});
					}
				}
			}
			//Load Data
			for (
				let count = 0;
				count < vm.entiteTypeFileDataPreview.length;
				count++
			) {
				let object = vm.gridData[count];
				let value = vm.entiteTypeFileDataPreview[count];
				for (let l = 0; l < headearsWithNoSpecChar.length; l++) {
					let colName = headearsWithNoSpecChar[l];
					if (object == undefined) {
						object = {};
					}

					object[colName] = value[l];
					vm.gridData[count] = object;
				}
			}
			vm.gridOptions.dataSource = vm.gridData;
			// Set a bigger z-index
			$(function () {
				DevExpress.ui.dxOverlay.baseZIndex(1999999998);
			});
			// Show Cell value in a popup
			vm.gridOptions.onCellClick = function (e) {
				showValuePreviewOnCellDoubleClick(e, vm.getShowValuePopup);
			};
			if (vm.gridInstance && vm.gridInstance.option) {
				vm.gridInstance.option('columns', generatedColumns);
				vm.gridInstance.option('dataSource', vm.gridData);
				vm.gridInstance.refresh();
			}
		};

		function getDataPreviewForDepot(depot, depotType) {
			if (!depot || !depotType) {
				return;
			}
			DepotFileService.getDataPreviewForDepot(depot, depotType).then(function (
				response
			) {
				if (depotType === vm.binaryType) {
					let type = response.headers('Content-Type');
					type = type !== undefined ? type : 'application/octet-stream';
					vm.dataPdfTest = 'data:' + type + ';base64,' + response.data;
					vm.dataResponseType = type;
				} else {
					vm.entiteTypeFileHeader = getHeaders(response.data.headers);
					vm.entiteTypeFileDataPreview = response.data.data_preview;
					vm.initGridData();
				}
			});
		}
	}
})();
