(function () {
	'use strict';

	angular.module('dcApp').directive('bulkMapping', [
		'$parse',
		function ($parse) {
			let controller = [
				'$rootScope',
				'$scope',
				'EntiteDepotService',
				'CaracService',
				'CaracFileMappingService',
				'toaster',
				'CommonServices',
				'$filter',
				'GEOMETRY_TYPE_CODE',
				'GEO_FORMATS',
				'DATE_TYPE_CODE',
				'gettextCatalog',
				'UPDATE_EVENT',
				function (
					$rootScope,
					$scope,
					EntiteDepotService,
					CaracService,
					CaracFileMappingService,
					toaster,
					CommonServices,
					$filter,
					GEOMETRY_TYPE_CODE,
					GEO_FORMATS,
					DATE_TYPE_CODE,
					gettextCatalog,
					UPDATE_EVENT
				) {
					const LABEL_HEADERS_REF_MODE = 'LABEL';

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopSave = gettextCatalog.getString(
						'Enregistrement effectué'
					);

					let vm = this;
					let toLowerCase = false;
					let toUpperCase = false;
					vm.dateTypeCode = DATE_TYPE_CODE;
					vm.GeometryTypeCode = GEOMETRY_TYPE_CODE;
					vm.availableGeoFormats = GEO_FORMATS;
					vm.availableTimezones = [];
					vm.availableDateLanguages = [];

					$scope.init = function (entiteId, element) {
						$scope.mapping = {};
						$scope.datePattern = {};
						$scope.timezone = {};
						$scope.dateLanguage = {};
						$scope.disabledTimezones = {};
						$scope.geoFormats = {};
						$scope.formulasMapping = {};
						$scope.formDisplayed = {};
						$scope.linkIds = [];
						vm.availableTimezones = $rootScope.availableTimezones;
						vm.availableDateLanguages = $rootScope.availableDateLanguages;

						CommonServices.getDateReadingMasks().then(function (
							responsePatterns
						) {
							$scope.datePatterns = responsePatterns.data;
							EntiteDepotService.getDepotWithHeadersAndMapping(entiteId).then(
								function (response) {
									$scope.data = response.data;
									for (let i in $scope.data) {
										$scope.linkIds.push($scope.data[i].v1.link_id);
										$scope.mapping[$scope.data[i].v1.link_id] =
											$scope.mapping[$scope.data[i].v1.link_id] != undefined &&
											$scope.mapping[$scope.data[i].v1.link_id] != {}
												? $scope.mapping[$scope.data[i].v1.link_id]
												: {};
										$scope.formulasMapping[$scope.data[i].v1.link_id] =
											$scope.formulasMapping[$scope.data[i].v1.link_id] !=
												undefined &&
											$scope.formulasMapping[$scope.data[i].v1.link_id] != {}
												? $scope.formulasMapping[$scope.data[i].v1.link_id]
												: {};
										$scope.formDisplayed[$scope.data[i].v1.link_id] =
											$scope.formDisplayed[$scope.data[i].v1.link_id] !=
												undefined &&
											$scope.formDisplayed[$scope.data[i].v1.link_id] != {}
												? $scope.formDisplayed[$scope.data[i].v1.link_id]
												: {};
										$scope.datePattern[$scope.data[i].v1.link_id] =
											$scope.datePattern[$scope.data[i].v1.link_id] !=
												undefined &&
											$scope.datePattern[$scope.data[i].v1.link_id] != {}
												? $scope.datePattern[$scope.data[i].v1.link_id]
												: {};
										$scope.timezone[$scope.data[i].v1.link_id] =
											$scope.timezone[$scope.data[i].v1.link_id] != undefined &&
											$scope.timezone[$scope.data[i].v1.link_id] != {}
												? $scope.timezone[$scope.data[i].v1.link_id]
												: {};
										$scope.dateLanguage[$scope.data[i].v1.link_id] =
											$scope.dateLanguage[$scope.data[i].v1.link_id] !=
												undefined &&
											$scope.dateLanguage[$scope.data[i].v1.link_id] != {}
												? $scope.dateLanguage[$scope.data[i].v1.link_id]
												: {};
										$scope.geoFormats[$scope.data[i].v1.link_id] =
											$scope.geoFormats[$scope.data[i].v1.link_id] !=
												undefined &&
											$scope.geoFormats[$scope.data[i].v1.link_id] != {}
												? $scope.geoFormats[$scope.data[i].v1.link_id]
												: {};
										for (let j in $scope.caracs) {
											let carac = _.find($scope.data[i].v2, function (elm) {
												return elm.uca_id == $scope.caracs[j].id;
											});
											if (carac != undefined) {
												if (carac.is_formula) {
													$scope.formulasMapping[$scope.data[i].v1.link_id][
														$scope.caracs[j].id
													] = carac;
													let allFormulas = JSON.parse(
														carac.formula_data_source_grammar
													);
													let formula = allFormulas[allFormulas.length - 1];
													$scope.formDisplayed[$scope.data[i].v1.link_id][
														$scope.caracs[j].id
													] = formula.column_alias;
												} else {
													$scope.mapping[$scope.data[i].v1.link_id][
														$scope.caracs[j].id
													] = carac.header.id;
													if (carac.pattern) {
														$scope.datePattern[$scope.data[i].v1.link_id][
															$scope.caracs[j].id
														] = carac.pattern;
													}
													if (carac.timezone) {
														$scope.timezone[$scope.data[i].v1.link_id][
															$scope.caracs[j].id
														] = carac.timezone;
													}

													if (carac.language) {
														$scope.dateLanguage[$scope.data[i].v1.link_id][
															$scope.caracs[j].id
														] = carac.language;
													}
													if (carac.geo_format) {
														$scope.geoFormats[$scope.data[i].v1.link_id][
															$scope.caracs[j].id
														] = carac.geo_format;
													}
												}
											}
										}
									}
									$(element).modal('show');
								}
							);
						});
					};

					$scope.formulaModes = {
						caracMode: true,
						qualityMode: false,
						refByLabel: false,
					};
					$scope.widgetByLink = {};
					$scope.showFormule = function (
						caracs,
						keepOldData,
						linkId,
						carac,
						sourceId,
						referencingMode
					) {
						$scope.linkId = linkId;
						$scope.caracId = carac.id;
						$scope.sourceId = sourceId;
						if (!keepOldData) {
							let widget = {};
							widget.grammar = {};
							widget.grammar.columns = [];
							for (let c in caracs) {
								let c_ =
									caracs[c].column != undefined ? caracs[c].column : caracs[c];
								let t_ =
									caracs[c].data_type != undefined
										? caracs[c].data_type
										: 'string';
								let col = {
									column_alias: c_,
									field: c_,
									type: formulasTypesMap(t_),
								};
								widget.grammar.columns.push(col);
							}
							$scope.widgetData.widget = widget;
							vm.formulaModes.caracMode = false;
							vm.formulaModes.refByLabel =
								referencingMode === LABEL_HEADERS_REF_MODE;
							$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
							$scope.widgetData.showFormule = true;
							$scope.widgetData.widgetMenuOpenAction = {};
							$scope.widgetData.widgetMenuData = {};
							$scope.widgetData.hideWidgetMenu = true;
							let typeToFilter = typesMap[carac.entite_type.code];
							$scope.widgetData.rootFunctionType =
								typeToFilter != undefined ? typeToFilter : 'string';
							$scope.widgetByLink[linkId] = $scope.widgetData.widget;
						} else {
							$scope.initWidget(
								caracs,
								$scope.formulasMapping[$scope.linkId][$scope.caracId]
							);
							vm.formulaModes.caracMode = false;
							vm.formulaModes.refByLabel =
								referencingMode === LABEL_HEADERS_REF_MODE;
							$scope.widgetData.showFormule = true;
						}
					};

					$scope.initWidget = function (headers, formulas) {
						let widget = {};
						widget.grammar = {};
						widget.grammar.columns = [];
						for (let c in headers) {
							let c_ =
								headers[c].column != undefined ? headers[c].column : headers[c];
							let t_ =
								headers[c].data_type != undefined
									? headers[c].data_type
									: 'string';
							let col = {
								column_alias: c_,
								type: formulasTypesMap(t_),
								field: c_,
							};
							widget.grammar.columns.push(col);
						}
						$scope.widgetData.widget = widget;
						$scope.widgetData.widgetMenuOpenAction = {};
						$scope.widgetData.widgetMenuData = {};
						$scope.widgetData.hideWidgetMenu = true;
						$scope.widgetData.rootFunctionType = undefined;

						$scope.widgetData.updateaction = $scope.saveFormuleEditeur;
						let formulasAsObject = JSON.parse(
							formulas.formula_data_source_grammar
						);
						for (let i in formulasAsObject) {
							$scope.widgetData.widget.grammar.columns[
								$scope.widgetData.widget.grammar.columns.length
							] = formulasAsObject[i];
						}
						$scope.widgetData.widget.grammar.columnsTemp = angular.copy(
							$scope.widgetData.widget.grammar.columns
						);
					};

					$scope.formulaGrammar = {};

					$scope.saveFormuleEditeur = function (widget) {
						if (widget) {
							let objectFormSize =
								$scope.widgetData.widget.grammar.columnsTemp.length - 1;
							$scope.widgetColumnsTemp =
								widget.grammar.columnsTemp[objectFormSize];
							let formDisplay =
								widget.grammar.columnsTemp[objectFormSize].column_alias;
							let formulas = [];
							for (
								let i = 0;
								i < $scope.widgetData.widget.grammar.columnsTemp.length;
								i++
							) {
								let column = $scope.widgetData.widget.grammar.columnsTemp[i];
								if (column.col != undefined) {
									formulas.push(column);
								}
							}
							$scope.formulasMapping[$scope.linkId][$scope.caracId] = {
								formula_data_source_grammar: JSON.stringify(formulas),
								id_data_source: $scope.sourceId,
								is_formula: true,
								link_id: $scope.linkId,
								uca_id: $scope.caracId,
							};
							$scope.formDisplayed[$scope.linkId][$scope.caracId] = formDisplay;
							$scope.widgetData.widget.grammar.columns = angular.copy(
								$scope.widgetData.widget.grammar.columnsTemp
							);
							$scope.widgetByLink[$scope.linkId] = $scope.widgetData.widget;
							$scope.widgetData.hideWidgetMenu = true;
							$scope.widgetData.showFormule = false;
							delete $scope.widgetData.widget;
							delete $scope.mapping[$scope.linkId][$scope.caracId];
						}
					};

					$scope.saveValue = function () {
						let mappingToSave = {};
						let mapping = $scope.mapping;
						let formulas = $scope.formulasMapping;
						for (let i = 0; i < $scope.linkIds.length; i++) {
							mappingToSave[$scope.linkIds[i]] = [];
							for (let j in formulas[$scope.linkIds[i]]) {
								let caracMapping = formulas[$scope.linkIds[i]][j];
								delete caracMapping.id;
								mappingToSave[$scope.linkIds[i]].push(caracMapping);
							}
							for (let k in mapping[$scope.linkIds[i]]) {
								let caracMapping = {
									link_id: $scope.linkIds[i],
									uca_id: Number(k),
									is_formula: false,
									header: {
										id: mapping[$scope.linkIds[i]][k],
									},
									pattern: $scope.datePattern[$scope.linkIds[i]][k],
									timezone: $scope.timezone[$scope.linkIds[i]][k],
									language: $scope.dateLanguage[$scope.linkIds[i]][k],
									geo_format: $scope.geoFormats[$scope.linkIds[i]][k],
								};
								mappingToSave[$scope.linkIds[i]].push(caracMapping);
							}
						}
						CaracFileMappingService.saveBulkMapping(
							mappingToSave,
							$scope.entiteTypeId
						).then(function (response) {
							let counts = response.data;
							if (counts != undefined) {
								for (let i in $scope.caracs) {
									let carac = $scope.caracs[i];
									carac.mapping_count = counts[carac.id];
									$scope.caracs[i] = carac;
								}
								if (vm.widgetData.updateaction) {
									vm.widgetData.updateaction();
								}
								$rootScope.$broadcast(UPDATE_EVENT['CARAC_MAPPING']);
							}
							toaster.pop('success', toasterPopSuccess, toasterPopSave);
							closeModal();
						});
					};

					$scope.deleteMapping = function (linkId, caracId) {
						delete $scope.mapping[linkId][caracId];
						delete $scope.formulasMapping[linkId][caracId];
						delete $scope.datePattern[linkId][caracId];
						delete $scope.timezone[linkId][caracId];
						delete $scope.dateLanguage[linkId][caracId];
					};

					$scope.smartMapping = function (linkId) {
						let linkData = _.find($scope.data, function (elm) {
							return elm.v1 && elm.v1.link_id == linkId;
						});
						if (linkData != undefined) {
							let oldPatterns = angular.copy($scope.datePattern[linkId]);
							let oldTimezones = angular.copy($scope.timezone[linkId]);
							let oldDateLanguages = angular.copy($scope.dateLanguage[linkId]);
							let oldGeoFormats = angular.copy($scope.geoFormats[linkId]);
							$scope.datePattern[linkId] = {};
							$scope.timezone[linkId] = {};
							$scope.dateLanguage[linkId] = {};
							$scope.disabledTimezones[linkId] = {};
							$scope.geoFormats[linkId] = {};
							let headers = linkData.v1.depot_headers;
							headers.map(
								(h) =>
									(h.normalizedColumn = $filter('normalize')(
										h.column,
										toLowerCase,
										toUpperCase
									))
							);
							for (let i in $scope.caracs) {
								let rightHeader = _.find(headers, function (elm) {
									elm.isDate =
										$scope.caracs[i] &&
										$scope.caracs[i].entite_type &&
										$scope.caracs[i].entite_type.code === vm.dateTypeCode;
									elm.isGeom =
										$scope.caracs[i] &&
										$scope.caracs[i].entite_type &&
										$scope.caracs[i].entite_type.code === vm.GeometryTypeCode;
									return (
										elm.normalizedColumn ===
										$filter('normalize')(
											$scope.caracs[i].lib,
											toLowerCase,
											toUpperCase
										)
									);
								});

								if (rightHeader != undefined) {
									$scope.mapping[linkId][$scope.caracs[i].id] = rightHeader.id;
									if (rightHeader.isDate) {
										$scope.datePattern[linkId][$scope.caracs[i].id] =
											oldPatterns[$scope.caracs[i].id]
												? oldPatterns[$scope.caracs[i].id]
												: undefined;
										$scope.timezone[linkId][$scope.caracs[i].id] = oldTimezones[
											$scope.caracs[i].id
										]
											? oldTimezones[$scope.caracs[i].id]
											: undefined;
										$scope.dateLanguage[linkId][$scope.caracs[i].id] =
											oldDateLanguages[$scope.caracs[i].id]
												? oldDateLanguages[$scope.caracs[i].id]
												: undefined;
									}
									if (rightHeader.isGeom) {
										$scope.geoFormats[linkId][$scope.caracs[i].id] =
											oldGeoFormats[$scope.caracs[i].id]
												? oldGeoFormats[$scope.caracs[i].id]
												: 'wkb';
									}
								}
							}
						}
					};

					$scope.save = function () {
						$scope.saveValue();
					};

					$scope.cancel = function () {
						closeModal();
					};

					$scope.visiblePopup = false;

					$scope.popupOptions = {
						width: 420,
						height: 120,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						contentTemplate: 'virtualCaracMessage',
						dragEnabled: false,
						closeOnOutsideClick: true,
						shading: false,
						bindingOptions: {
							visible: 'visiblePopup',
						},
					};

					$scope.showVirtualCaracMessage = function (data) {
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
						$scope.visiblePopup = true;
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.showBulkMapping = false;
					}

					// validate date mapping
					$scope.validateTimezoneOnPatternChange = function (
						linkId,
						caracId,
						init
					) {
						if (init) {
							$scope.disabledTimezones = $scope.disabledTimezones
								? $scope.disabledTimezones
								: {};
							$scope.disabledTimezones[linkId] = { caracId: false };
						}
						if ($scope.datePattern[linkId][caracId] === 'ISO 8601') {
							$scope.timezone[linkId][caracId] = undefined;
							$scope.disabledTimezones[linkId][caracId] = true;
						} else {
							$scope.disabledTimezones[linkId][caracId] = false;
						}
					};

					$scope.validateTimezoneOnPatternChangeSelect = function (
						item,
						model,
						label,
						event,
						linkId,
						caracId
					) {
						event.stopPropagation();
						$scope.validateTimezoneOnPatternChange(linkId, caracId);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					showBulkMapping: '=',
					entiteTypeId: '=',
					formulaModes: '=',
					caracs: '=',
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/bulkMapping/bulkMapping.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.showBulkMapping = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.showBulkMapping;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.entiteTypeId = scope.vm.entiteTypeId;
								scope.caracs = _.each(scope.vm.caracs, function (elm) {
									elm.isVirtualCarac =
										elm.hdh_format && elm.hdh_format.value == 'FORMULA';
								});
								scope.widgetData = scope.vm.widgetData;
								scope.saveAction = scope.vm.saveAction;
								scope.init(scope.vm.entiteTypeId, element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
