(function () {
	'use strict';

	angular.module('dcApp').directive('elementPermissions', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$rootScope',
				'PermissionService',
				'toaster',
				'gettextCatalog',
				'ProjectService',
				function (
					$log,
					$scope,
					$rootScope,
					PermissionService,
					toaster,
					gettextCatalog,
					ProjectService
				) {
					let translatePermissionActionLibelles = {};
					translatePermissionActionLibelles.create =
						gettextCatalog.getString('Création');
					translatePermissionActionLibelles.view =
						gettextCatalog.getString('Lecture');
					translatePermissionActionLibelles.edit =
						gettextCatalog.getString('Modification');
					translatePermissionActionLibelles.del =
						gettextCatalog.getString('Suppression');
					translatePermissionActionLibelles.duplicate =
						gettextCatalog.getString('Duplication');
					translatePermissionActionLibelles.setting =
						gettextCatalog.getString('Paramètres');
					translatePermissionActionLibelles.stop =
						gettextCatalog.getString('Arrêt');
					translatePermissionActionLibelles.start =
						gettextCatalog.getString('Démarrage');
					translatePermissionActionLibelles.load = gettextCatalog.getString(
						'Chargement des données'
					);
					translatePermissionActionLibelles.unload = gettextCatalog.getString(
						'Déchargement des données'
					);
					translatePermissionActionLibelles.requesting =
						gettextCatalog.getString('Interrogation');
					translatePermissionActionLibelles.sourcesetting =
						gettextCatalog.getString('Gestion des sources');
					translatePermissionActionLibelles.list =
						gettextCatalog.getString('Liste');
					translatePermissionActionLibelles.execute =
						gettextCatalog.getString('Exécution');
					translatePermissionActionLibelles.historic =
						gettextCatalog.getString('Historique');
					translatePermissionActionLibelles.graph =
						gettextCatalog.getString('Graphe');
					translatePermissionActionLibelles.export =
						gettextCatalog.getString('Export');
					translatePermissionActionLibelles.export_dc =
						gettextCatalog.getString('Export DC');
					translatePermissionActionLibelles.column_stat =
						gettextCatalog.getString('Statistiques sur les colonnes');
					translatePermissionActionLibelles.persist =
						gettextCatalog.getString('Persistance/Cache');
					translatePermissionActionLibelles.cache =
						gettextCatalog.getString('Cacher');
					translatePermissionActionLibelles.synchronize =
						gettextCatalog.getString('Synchroniser');
					translatePermissionActionLibelles.list_carac =
						gettextCatalog.getString('Liste des caractéristiques');
					translatePermissionActionLibelles.stat =
						gettextCatalog.getString('Statistiques');
					translatePermissionActionLibelles.permissions =
						gettextCatalog.getString('Droits');
					translatePermissionActionLibelles.link =
						gettextCatalog.getString('Lien');
					translatePermissionActionLibelles.carac =
						gettextCatalog.getString('Caractéristiques');
					translatePermissionActionLibelles.data =
						gettextCatalog.getString('Données');
					translatePermissionActionLibelles.extract =
						gettextCatalog.getString('Extraction');
					translatePermissionActionLibelles.publication =
						gettextCatalog.getString('Publication');
					translatePermissionActionLibelles.data_catalog =
						gettextCatalog.getString('DataCatalogue');

					let toasterPopSucess = gettextCatalog.getString('Succès');
					let toasterPopSave = gettextCatalog.getString(
						'Enregistrement des permissions effectué'
					);

					let isPublicProject = false;

					var vm = this;

					vm.element_types = {
						connector: gettextCatalog.getString('Connecteur'),
						data_source: gettextCatalog.getString('Dépôt'),
						entity: gettextCatalog.getString('Entité Métier'),
						datablock: gettextCatalog.getString('DataBlock'),
						dashboard: gettextCatalog.getString('Tableau de Bord'),
						timeline: gettextCatalog.getString('Timeline'),
						chart: gettextCatalog.getString('Graphique'),
						media: gettextCatalog.getString('Média'),
						grid: gettextCatalog.getString('Tableau'),
						map: gettextCatalog.getString('Carte'),
						export_template: gettextCatalog.getString('export.template'),
					};

					PermissionService.getElementPermissionRules().then(function (
						response
					) {
						$scope.hdhElementPermissions = response.data;
					});

					$scope.permissionActionLibelles =
						$rootScope.permissionsActionLabelsTrans;

					$scope.init = function () {
						$('#usersTab').tab('show');
						ProjectService.isPublicProject($rootScope.currentProjectId).then(
							function (response) {
								isPublicProject = response.data;
								$scope.initUsers();
							}
						);
					};

					let yesTra = gettextCatalog.getString('Oui');
					let noTra = gettextCatalog.getString('Non');
					var initElements = function () {
						$scope.selectedElements = [];
						var options = $scope.hdhElementPermissions[$scope.vm.data.type];
						var columns = [
							{
								cellTemplate: 'userName',
								caption: gettextCatalog.getString('Utilisateurs'),
								allowFiltering: true,
								allowSorting: true,
								width: '250px',
							},
						];
						columns[0].cellTemplate =
							'' +
							'<div>' +
							'<user-info user-id="gridElList.data.user_id" is-group="gridElList.data.is_group" > </user-info> ' +
							'<button type="button" class="btn btn-success btn-xs pull-right" ng-click="changePermissionManyActions(gridElList.data, true)"> ' +
							yesTra +
							' </button>' +
							'<button type="button" class="btn btn-default btn-xs pull-right" ng-click="changePermissionManyActions(gridElList.data, false)"> ' +
							noTra +
							' </button>' +
							'</div>';

						for (var o in options) {
							if (
								options[o] === 'create' ||
								options[o] === 'list' ||
								((options[o] == 'view' || options[o] == 'data') &&
									isPublicProject)
							) {
								continue;
							}
							let col = {
								permission: options[o],
								dataField: options[o],
								caption: '',
								allowFiltering: false,
								allowSorting: false,
								headerCellTemplate:
									'' +
									'<div> ' +
									$scope.permissionActionLibelles[options[o]] +
									'</div>',
							};

							col.cellTemplate = 'permissionActionsEl';
							col.headerCellTemplate =
								'' +
								'<div> ' +
								$scope.permissionActionLibelles[options[o]] +
								'<button ng-click="changePermissionManyUsers(gridElList.column.dataField, true)" ng-if="selectedElements[0]" type="button" class="btn btn-success btn-xs pull-right"> ' +
								yesTra +
								' </button> ';
							col.headerCellTemplate =
								col.headerCellTemplate +
								'<button ng-click="changePermissionManyUsers(gridElList.column.dataField, false)" ng-if="selectedElements[0]" type="button" class="btn btn-default btn-xs pull-right" > ' +
								noTra +
								' </button>' +
								'</div>';
							columns.push(col);
						}

						if (
							$scope.vm.data.type === 'datablock' ||
							$scope.vm.data.type === 'entity'
						) {
							let col = {
								permission: 'column',
								dataField: 'column',
								caption: '',
								allowFiltering: false,
								allowSorting: false,
								headerCellTemplate:
									'<div> ' + gettextCatalog.getString('Colonnes') + ' </div>',
							};

							col.cellTemplate =
								'' +
								'<div class="text-center">' +
								'<button type="button" id="view-btn-{{gridElList.data.user_name}}" class="btn btn-xs btn-primary" style="margin-right: 10px;" ng-click="showColumnPermissions(gridElList.data)"> ' +
								gettextCatalog.getString('Voir') +
								' </button>' +
								'</div>';
							col.headerCellTemplate =
								'' +
								'<div> Colonnes ' +
								'<button ng-click="changePermissionManyUsers(gridElList.column.dataField, true)" ng-if="selectedElements[0]" type="button" class="btn btn-success btn-xs pull-right"> ' +
								yesTra +
								'</button> ';
							col.headerCellTemplate =
								col.headerCellTemplate +
								'' +
								'<button ng-click="changePermissionManyUsers(gridElList.column.dataField, true)" ng-if="selectedElements[0]" type="button" class="btn btn-default btn-xs pull-right" > ' +
								noTra +
								' </button>' +
								'</div>';
							columns.push(col);
						}

						PermissionService.getUserElementPermissions(
							vm.data.metadataId
						).then(function (response) {
							let gridOptions = {
								headerFilter: { visible: true, applyFilter: 'auto' },
								filterRow: { visible: true, applyFilter: 'auto' },
								columnAutoWidth: true,
								showBorders: true,
								showColumnLines: true,
								showRowLines: true,
								columns: columns,
							};
							//store and key should be used to keep items selected after data change
							gridOptions.dataSource = {
								store: { type: 'array', data: response.data, key: 'user_id' },
							};
							gridOptions.paging = { enabled: true, pageSize: 10 };
							gridOptions.pager = {
								showPageSizeSelector: true,
								allowedPageSizes: [5, 10],
								showInfo: true,
								visible: true,
							};
							gridOptions.selection = {
								mode: 'multiple',
								showCheckBoxesMode: 'always',
							};

							gridOptions.onSelectionChanged = function (selectedItems) {
								$scope.selectedElements = selectedItems.selectedRowsData;
								$scope.selectedItems = selectedItems;
							};

							$scope.gridOptions = gridOptions;
						});
					};

					$scope.initUsers = function () {
						delete $scope.gridOptions;
						initElements();
					};

					$scope.changePermission = function (item, code) {
						let updatePermission;
						var permission = {
							action: code,
							metadata_id: vm.data.metadataId,
							permitted: item.permissions[code],
						};
						permission.user_id = item.user_id;
						permission.is_group = item.is_group;
						updatePermission = PermissionService.updateUserPermission(
							$rootScope.currentProjectId,
							permission
						);
						updatePermission.then(function (response) {
							toaster.pop('success', toasterPopSucess, toasterPopSave);
						});
					};

					$scope.changePermissionManyUsers = function (code, permitted) {
						let updatePermission;
						var permission = {
							action: code,
							metadata_id: vm.data.metadataId,
							permitted: permitted,
						};
						let userMap = {};
						_.each($scope.selectedElements, function (item) {
							userMap[item.user_id] = item.is_group;
						});
						permission.user_ids = userMap;
						updatePermission = PermissionService.updatePermissionManyUsers(
							$rootScope.currentProjectId,
							permission
						);

						updatePermission.then(function (response) {
							$log.debug($scope.selectedItems);
							for (var e in $scope.selectedElements) {
								$scope.selectedElements[e].permissions[code] = permitted;
							}
							toaster.pop('success', toasterPopSucess, toasterPopSave);
						});
					};

					$scope.changePermissionManyActions = function (item, permitted) {
						var actions = angular.copy(
							$scope.hdhElementPermissions[$scope.vm.data.type]
						);
						if (
							$scope.vm.data.type === 'datablock' ||
							$scope.vm.data.type === 'entity'
						) {
							actions.push('column');
						}

						let updatePermission;
						var permission = {
							actions: actions,
							metadata_id: vm.data.metadataId,
							permitted: permitted,
						};
						permission.user_id = item.user_id;
						permission.is_group = item.is_group;
						updatePermission =
							PermissionService.updateUserPermissionManyActions(
								$rootScope.currentProjectId,
								permission
							);

						updatePermission.then(function (response) {
							for (var a in actions) {
								item.permissions[actions[a]] = permitted;
							}
							toaster.pop('success', toasterPopSucess, toasterPopSave);
						});
					};

					$scope.showColumnPermissions = function (item) {
						vm.data.userId = item.user_id;
						vm.data.isGroup = item.is_group;
						vm.data.showColumnPermissions = true;
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						delete $scope.gridOptions;
						$(vm.element).modal('hide');
						vm.data.showElementPermissions = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/elementPermissions/elementPermissions.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							delete scope.gridOptions;
							scope.vm.data.showElementPermissions = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.data.showElementPermissions;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
