(function () {
	'use strict';

	angular.module('dcApp').factory('GridService', [
		'$log',
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'httpService',
		function ($log, $http, $rootScope, API_BASE_URL_BACKEND, $q, httpService) {
			var GridService = {};

			GridService.geGrids = function (type, active) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_GET_ALL';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_GET_ALL';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return getGrids(active, $rootScope.getUrl(urlCode));
			};

			GridService.getGrid = function (type, gridId) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_GET';
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_GET';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return getGrid(gridId, $rootScope.getUrl(urlCode));
			};

			GridService.createGrid = function (type, grid) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_CREATE';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_CREATE';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return createGrid(grid, $rootScope.getUrl(urlCode));
			};

			GridService.createGridFromDataBlock = function (type, datablockId) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_CREATE_FROM_DB';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_CREATE_FROM_DB';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return createGrid(datablockId, $rootScope.getUrl(urlCode));
			};

			GridService.editGrid = function (type, gridId, grid) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_EDIT';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_EDIT';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return editGrid(gridId, grid, $rootScope.getUrl(urlCode));
			};

			GridService.deleteGrid = function (type, gridId) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_DELETE';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_DELETE';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return deleteGrid(gridId, $rootScope.getUrl(urlCode));
			};

			GridService.deleteBulkGrids = function (gridIds) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GRIDS_DELETE_BULK');
				return deleteBulkGrids(gridIds, url);
			};

			GridService.editGridConfig = function (type, gridId, config) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_EDIT_CONFIG';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_EDIT_CONFIG';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return editGridConfig(gridId, config, $rootScope.getUrl(urlCode));
			};

			GridService.getGridConfig = function (type, gridId) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_GET_CONFIG';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_GET_CONFIG';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return getGridConfig(gridId, $rootScope.getUrl(urlCode));
			};

			GridService.checkGridConfig = function (type, gridId) {
				var urlCode = '';
				switch (type) {
					case 'simple':
						urlCode = 'GRIDS_SIMPLE_CHECK_CONFIG';
						break;
					case 'pivot':
						urlCode = 'GRIDS_PIVOT_CHECK_CONFIG';
						break;
					default:
						$log.debug('Undefined type ' + type + '.');
				}
				return getGridConfig(gridId, $rootScope.getUrl(urlCode));
			};

			GridService.getGridOptions = function () {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('OPTIONS_HD_GRID');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			GridService.getAllGrids = function (excludePublic, excludeInactive) {
				return getGrids(excludePublic, excludeInactive, $rootScope.getUrl('GRIDS_GET_ALL'));
			};

			GridService.duplicateGrid = function (type, gridId) {
				var urlCode = '';
				if (type === 'simple') {
					urlCode = 'GRIDS_SIMPLE_DUPLICATE';
				} else if (type === 'pivot') {
					urlCode = 'GRIDS_PIVOT_DUPLICATE';
				} else {
					$log.debug('Undefined type ' + type + '.');
				}
				return duplicateGrid(gridId, $rootScope.getUrl(urlCode));
			};

			var getGrids = function (excludePublic, excludeInactive, url) {
				url = API_BASE_URL_BACKEND + url;
				url = excludePublic ? url + '?excludePublic=true' : url + '?excludePublic=false';
				url = excludeInactive ? url + '&excludeInactive=true' : url + '&excludeInactive=false';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var getGrid = function (gridId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var createGrid = function (job, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				$http.post(url, job).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			var editGrid = function (gridId, grid, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				return httpService.putRequest(url, grid, false);
			};

			var deleteGrid = function (gridId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var deleteBulkGrids = function (gridIds, url) {
				if (Array.isArray(gridIds)) {
					url = url + '?gridsId=' + gridIds[0];
					for (let i = 1; i < gridIds.length; i++) {
						url = url + '&gridsId=' + gridIds[i];
					}
				} else {
					url = url + '?gridsId=' + gridIds;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var editGridConfig = function (gridId, config, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				$http.put(url, config).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			var getGridConfig = function (gridId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var duplicateGrid = function (gridId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', gridId);
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return GridService;
		},
	]);
})();
