(function () {
	'use strict';

	angular.module('dcApp').directive('permissionCheck', [
		'$parse',
		'$sce',
		function ($parse, $sce) {
			var controller = [
				'$scope',
				'PermissionService',
				function ($scope, PermissionService) {
					var vm = this;

					$scope.checkPermission = function () {
						if (vm.data.globalAction) {
							checkGlobalPermission();
						} else if (vm.data.elementId) {
							checkElementPermission();
						} else {
							hidePermissionCheck();
						}
					};

					let checkGlobalPermission = function () {
						PermissionService.haveGlobalPermission(vm.data.globalAction)
							.then(function (response) {
								handleResponse(response);
							})
							.catch(hidePermissionCheck);
					};

					let checkElementPermission = function () {
						PermissionService.havePermission(
							vm.data.elementId,
							vm.data.action,
							vm.data.type
						)
							.then(function (response) {
								handleResponse(response);
							})
							.catch(hidePermissionCheck);
					};

					let handleResponse = function (response) {
						if (response.data) {
							vm.data.actionIfPermitted();
							$scope.vm.data.showPermissionCheck = false;
						} else {
							if ($scope.vm.data.actionIfNotPermitted) {
								$scope.vm.data.actionIfNotPermitted();
							}
							$(vm.element).modal('show');
						}
					};

					let hidePermissionCheck = function () {
						$scope.vm.data.showPermissionCheck = false;
					};

					$scope.close = function () {
						$(vm.element).modal('hide');
						vm.data.showPermissionCheck = false;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/permissionCheck/permissionCheck.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.data.showPermissionCheck = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.data.showPermissionCheck;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.checkPermission();
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
