(function () {
	'use strict';

	angular.module('dcApp').factory('DataCatalogService', [
		'$http',
		'$q',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'DataCatalogAdapter',
		function ($http, $q, $rootScope, API_BASE_URL_BACKEND, DataCatalogAdapter) {
			const endpoint = API_BASE_URL_BACKEND + $rootScope.getUrl('DATA_CATALOG');
			const endpointOne =
				API_BASE_URL_BACKEND + $rootScope.getUrl('DATA_CATALOG_ELEMENT');
			const endpointOneMessage =
				API_BASE_URL_BACKEND +
				$rootScope.getUrl('DATA_CATALOG_ELEMENT_MESSAGE');
			const endpointOneCount =
				API_BASE_URL_BACKEND + $rootScope.getUrl('DATA_CATALOG_ELEMENT_COUNT');

			return {
				findByKeywords: findByKeywords,
				findOneElementById: findOneElementById,
				sendMailToElementAdmin: sendMailToElementAdmin,
				getElementCount: getElementCount,
			};

			/**
      request {keywords: [], take: int, skip: int, load_options: {}}
    **/
			function findByKeywords(request) {
				const deferred = $q.defer();
				$http.post(endpoint, request).then(
					function (response) {
						deferred.resolve(
							DataCatalogAdapter.generateDataCatalogList(response.data)
						);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			/**
      get element details
    **/
			function findOneElementById(elementId) {
				let url = endpointOne.replace('{elementId}', elementId);
				const deferred = $q.defer();
				$http.get(url).then(
					function (response) {
						deferred.resolve(
							DataCatalogAdapter.generateDataCatalogItem(response.data)
						);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			/**
      get element count
    **/
			function getElementCount(elementId) {
				let url = endpointOneCount.replace('{elementId}', elementId);
				const deferred = $q.defer();
				$http.get(url).then(
					function (response) {
						deferred.resolve(
							DataCatalogAdapter.generateDataCatalogItemCount(response.data)
						);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			/**
      message {subject: '', message_body:''}
    **/
			function sendMailToElementAdmin(elementId, message) {
				let url = endpointOneMessage.replace('{elementId}', elementId);
				const deferred = $q.defer();
				$http.post(url, message).then(
					function (response) {
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			}
		},
	]);
})();
