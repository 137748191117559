(function () {
	'use strict';

	angular.module('dcApp').directive('elementSelection', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'DEFAULT_CELL_TEMPLATE',
				'$q',
				'$rootScope',
				'DataBlocksService',
				'TypeEntiteService',
				'gettextCatalog',
				function (
					$scope,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					DEFAULT_CELL_TEMPLATE,
					$q,
					$rootScope,
					DataBlocksService,
					TypeEntiteService,
					gettextCatalog
				) {
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.tags = gettextCatalog.getString('Tags');
					dataGirdColumnName.type = gettextCatalog.getString('Type');
					dataGirdColumnName.description =
						gettextCatalog.getString('Description');
					dataGirdColumnName.updatedBy = gettextCatalog.getString(
						'Dernier modificateur'
					);
					dataGirdColumnName.updateDate = gettextCatalog.getString(
						'Date de mise à jour'
					);

					$scope.modalTitle = {};
					$scope.modalTitle.listDatanlock = gettextCatalog.getString(
						'Liste des DataBlocks'
					);
					$scope.modalTitle.listEntityAndDatanlock = gettextCatalog.getString(
						'Liste des Entités Métier / DataBlocks'
					);
					$scope.showCurrentProjectOnly = true;
					$scope.refreshList = refreshList;
					var vm = this;

					var getAvailableTags = function () {
						return new Promise(function (resolve) {
							setTimeout(function () {
								resolve(
									_.map(
										_.sortBy($scope.tags, function (i) {
											return i.label.toLowerCase();
										}),
										function (el) {
											return el.label;
										}
									)
								);
							}, 50);
						});
					};

					let setTagsLookup = function () {
          	$scope.lookupTags = _.map(
          		_.sortBy($scope.tags, function (tag) {
          			return tag.label.toLowerCase();
          		}),
          		function (el) {
          			return {'text': el.label, 'value': el.label};
          		}
          	);
          	if ($scope.gridInstance && $scope.gridInstance.option) {
          		let headerFilter = $scope.gridInstance.columnOption('tags', 'headerFilter');
          		headerFilter.dataSource = $scope.lookupTags;
          		setTimeout(function () {
          		headerFilter.dataSource.unshift({'text': gettextCatalog.getString('grid.header.filter.blank'), 'value': null})
          			$scope.gridInstance.columnOption('tags', 'headerFilter', headerFilter);
          		}, 400);
          	}
          };

					var lookupTagDataSource = {
						store: new DevExpress.data.CustomStore({
							loadMode: 'raw',
							load: function () {
								return getAvailableTags().then(function (response) {
									return response;
								});
							},
						}),
					};

					$scope.dataGridOptions = {
						dataSource: $scope.elements,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						scrolling: {
							rowRenderingMode: 'Standard',
							showScrollbar: 'onHover',
						},
						selection: {
							mode: 'single',
							showCheckBoxesMode: 'always',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.label,
								dataField: 'lc',
								width: '20%',
								allowReordering: false,
							},
							{
								dataField: 'tags',
								caption: dataGirdColumnName.tags,
								cellTemplate: 'tagTemplate',
								allowHeaderFiltering: true,
								allowFiltering: false,
								enableCellEdit: false,
								width: '20%',
								headerFilter: {
									dataSource: [],
								},
								calculateFilterExpression: function (filterValue) {
									if (!filterValue) {
										return ['tagAsArray', '=',  null];
									}

									return ['tagAsArray', 'contains', filterValue];
								},
							},
							{
								caption: dataGirdColumnName.description,
								dataField: 'desc',
								width: '15%',
							},
							{
								caption: dataGirdColumnName.type,
								dataField: 'type',
								width: '15%',
								visible: false,
							},
							{
								dataField: 'updateDate',
								width: '20%',
								caption: dataGirdColumnName.updateDate,
								sortOrder: 'desc',
							},
							{
								dataField: 'updatedBy',
								width: '20%',
								caption: dataGirdColumnName.updatedBy,
							},
						],
						onInitialized: function (e) {
							$scope.gridInstance = e.component;
							setTagsLookup();
						},
						onSelectionChanged: function (selectedItems) {
							let selectedItem = selectedItems.selectedRowsData
								? selectedItems.selectedRowsData[0]
								: null;
							setSelectedElement(selectedItem);
						},
						onRowDblClick: function (item) {
							setSelectedElement(item.data);
							$scope.save();
						},
						onCellPrepared: function (cell) {
							// set input filter html id for test
							if (cell.rowType == 'filter') {
								let inputId = cell.column.dataField;
								let filterInput = $(cell.cellElement).find(
									'.dx-texteditor-input-container .dx-texteditor-input'
								);
								if (filterInput[0]) {
									$(filterInput[0]).attr('id', inputId);
								}
							}
						},
					};

					var setSelectedElement = function (selectedItem) {
						if (selectedItem) {
							$scope.selectedElement = selectedItem.element;
							$scope.selectedElement.lib = selectedItem.lc;
							$scope.selectedElement.code = selectedItem.code;
							$scope.selectedType = selectedItem.type;
						} else {
							delete $scope.selectedElement;
						}
					};

					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});

					function getElements(content) {
						if (content !== undefined) {
							$scope.elements = [];

							var tags = [];
							for (var r in content) {
								if (
									vm.widgetData.elementSelectionExclude &&
									vm.widgetData.elementSelectionExclude.indexOf(content[r].id) >
										-1
								) {
									continue;
								}
								$scope.elements.push({
									id: content[r].id,
									source_id: content[r].id,
									code: content[r].metadata.code,
									lc: content[r].metadata.label,
									desc: content[r].metadata.description,
									tags: content[r].metadata.tags,
									updateDate: $rootScope.getDateTimeWithPattern(
										content[r].metadata.update_date
									),
									updatedBy: content[r].metadata.updated_by,
									tagAsArray: $rootScope.mapTags(content[r].metadata.tags),
									element: content[r],
									type: content[r].grammar ? 'DATABLOCK' : 'ENTITE',
								});
								tags = _.union(tags, content[r].metadata.tags);
							}

							for (var i in tags) {
								if (_.find($scope.tags, { id: tags[i].id }) == null) {
									$scope.tags.push({ id: tags[i].id, label: tags[i].code });
								}
							}

							$scope.dataGridOptions.dataSource = $scope.elements;
							for (var c in $scope.dataGridOptions.columns) {
								if ($scope.dataGridOptions.columns[c].dataField == 'type') {
									if (
										$scope.vm.widgetData.elementSelectionType ==
										'DATABLOCK_ENTITE'
									) {
										$scope.dataGridOptions.columns[c].visible = true;
									} else {
										$scope.dataGridOptions.columns[c].visible = false;
									}
								}
							}

							$scope.showElementsGrid = true;
						}
					}

					function getEntiteAndDataBlockElements(datablocks, entites) {
						$scope.elements = [];
						var tags = [];
						for (var r in entites) {
							if (
								vm.widgetData.elementSelectionExclude &&
								vm.widgetData.elementSelectionExclude.indexOf(entites[r].id) >
									-1
							) {
								continue;
							}
							$scope.elements.push({
								id: entites[r].id,
								source_id: entites[r].id,
								code: entites[r].code,
								lc: entites[r].label,
								desc: entites[r].description,
								tags: entites[r].tags,
								updateDate: $rootScope.getDateTimeWithPattern(
									entites[r].update_date
								),
								updatedBy: entites[r].updated_by,
								tagAsArray: $rootScope.mapTags(entites[r].tags),
								element: entites[r],
								type: 'ENTITE',
							});
							tags = _.union(tags, entites[r].tags);
						}

						for (var r in datablocks) {
							if (
								vm.widgetData.elementSelectionExclude &&
								vm.widgetData.elementSelectionExclude.indexOf(
									datablocks[r].id
								) > -1
							) {
								continue;
							}
							$scope.elements.push({
								id: datablocks[r].id,
								source_id: datablocks[r].id,
								code: datablocks[r].metadata.code,
								lc: datablocks[r].metadata.label,
								desc: datablocks[r].metadata.description,
								tags: datablocks[r].metadata.tags,
								updateDate: $rootScope.getDateTimeWithPattern(
									datablocks[r].metadata.update_date
								),
								updatedBy: datablocks[r].metadata.updated_by,
								tagAsArray: $rootScope.mapTags(datablocks[r].metadata.tags),
								element: datablocks[r],
								type: 'DATABLOCK',
							});
							tags = _.union(tags, datablocks[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}

						$scope.dataGridOptions.dataSource = $scope.elements;
						for (var c in $scope.dataGridOptions.columns) {
							if ($scope.dataGridOptions.columns[c].dataField == 'type') {
								$scope.dataGridOptions.columns[c].visible = true;
							}
						}

						$scope.showElementsGrid = true;
					}

					function getGridData() {
						$scope.tags = [];
						if ($scope.vm.widgetData.elementSelectionType == 'DATABLOCK') {
							$scope.title = $scope.modalTitle.listDatanlock;
							DataBlocksService.getDatablocks(
								$scope.showCurrentProjectOnly,
								true
							).then(function (response) {
								getElements(response.data);
							});
						} else if (
							$scope.vm.widgetData.elementSelectionType == 'DATABLOCK_ENTITE'
						) {
							$scope.title = $scope.modalTitle.listEntityAndDatanlock;
							DataBlocksService.getDatablocks(
								$scope.showCurrentProjectOnly,
								true
							).then(function (response_d) {
								TypeEntiteService.getTypes(
									undefined,
									$scope.showCurrentProjectOnly,
									true
								).then(function (response_e) {
									getEntiteAndDataBlockElements(
										response_d.data,
										response_e.data
									);
								});
							});
						}
					}

					$scope.init = function () {
						$scope.showCurrentProjectOnly = true;
						refreshList();
					};

					$scope.save = function () {
						if ($scope.vm.widgetData.elementSelectionType == 'DATABLOCK') {
							DataBlocksService.getDataBlock($scope.selectedElement.id).then(
								function (response) {
									$scope.selectedElement.grammar = response.data.grammar;
									vm.widgetData.elementSelectionSaveMethod(
										$scope.selectedElement,
										'DATABLOCK'
									);
									closeModal();
								}
							);
						} else {
							vm.widgetData.elementSelectionSaveMethod(
								$scope.selectedElement,
								$scope.selectedType
							);
							closeModal();
						}
					};

					$scope.cancel = function () {
						closeModal();
					};

					function refreshList() {
						$scope.showElementsGrid = false;
						getGridData();
					}

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showHdhElementSelection = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/hdhElementSelection/hdhElementSelection.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showHdhElementSelection = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showHdhElementSelection;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
