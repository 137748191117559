(function () {
	'use strict';

	angular.module('dcApp').directive('selectColumnValues', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'DataBlocksService',
				'gettextCatalog',
				function ($log, $scope, DataBlocksService, gettextCatalog) {
					var vm = this;
					$scope.defaultTake = 200;
					$scope.trieModes = [
						{
							label: '',
						},
						{
							code: 'ASC',
							label: gettextCatalog.getString('Ascendant'),
						},
						{
							code: 'DESC',
							label: gettextCatalog.getString('Descendant'),
						},
					];

					$scope.search = function () {
						var take = $scope.take ? $scope.take : $scope.defaultTake;
						var sort = $scope.sort != null ? $scope.sort : '';

						var result = $scope.qBuilderInstance.getRules();
						$log.debug(result.queries);

						var grammar = setFilter(
							angular.copy(vm.data.selectColumnValuesData.grammar),
							result.queries,
							vm.data.selectColumnValuesData.column_uuid
						);
						DataBlocksService.findDistinctValues(
							vm.data.selectColumnValuesData.column_uuid,
							vm.data.selectColumnValuesData.column_type,
							grammar,
							sort,
							take
						).then(function (response) {
							$scope.vm.data.saveSelectedColumnValues(response.data);
							closeModal();
						});
					};

					var setFilter = function (grammar, rules, column) {
						var step = {};
						if (rules) {
							step.filter = rules;
						}
						grammar.steps.push(step);
						return grammar;
					};

					$scope.initQueryBuilder = function () {
						$scope.qbFilters = [];
						var fil = {
							id: 2,
							label: vm.data.selectColumnValuesData.column_lib,
							type: vm.data.selectColumnValuesData.column_type,
							unique: true,
						};

						if (fil.type == 'date') {
							fil.plugin = 'datetimepicker';
							fil.plugin_config = {
								format: 'DD/MM/YYYY HH:mm:ss',
							};
							fil.validation = {
								format: 'DD/MM/YYYY HH:mm:ss',
							};
						} else if (fil.type == 'boolean') {
							fil.input = 'radio';
							fil.values = {
								1: gettextCatalog.getString('Vrai'),
								0: gettextCatalog.getString('Faux'),
							};
						}

						$scope.qbFilters.push(fil);

						$('#qbuilder-dv').queryBuilder({
							plugins: ['sortable', 'bt-tooltip-errors'],
							filters: $scope.qbFilters,
							mainInstance: true,
							selectFilterActive: true,
							mainInstanceDivId: 'selectColumnValuesModal',
							rootCondition: 'AND',
							disable_select_value: true,
							disable_select_column: true,
						});

						$('#qbuilder-dv').on(
							'afterCreateRuleInput.queryBuilder',
							function (e, rule) {
								if (rule.filter.type === 'date') {
									var $input = rule.$el.find(
										'.rule-value-container [name*=_value_]'
									);
									$input.on('dp.change', function () {
										$input.trigger('change');
									});
								}
							}
						);
					};

					$scope.setRoot = function (builder, root) {
						$scope.rulesRoot = root;
						$scope.qBuilderInstance = builder;
					};

					$scope.addGroup = function (that, mod) {
						that.addGroup(mod);
						return;
					};

					$scope.deleteRule = function (that, model) {
						$scope.nbRules--;
						that.deleteRule(model);
					};

					$scope.deleteGroup = function (that, model) {
						that.deleteGroup(model);
						return;
					};

					$scope.rule_id = 0;
					$scope.addRules = function () {
						$scope.rule_id++;
						$scope.selectedThat = $scope.qBuilderInstance;
						$scope.selectedMod = $scope.rulesRoot;
						var model = $scope.selectedThat.addRule($scope.selectedMod);
						model.filter = $scope.qbFilters[0];
						model.is_active = false;
						model.column_alias =
							'COL_' + vm.data.selectColumnValuesData.column_uuid;
						model.id_ = $scope.rule_id;
					};

					$scope.setFilters = function (filters) {
						$scope.filters = filters;
					};

					$scope.openValueList = function (rule_id) {
						$scope.selectedRule = getRuleById_(rule_id);
						$scope.listValue = angular.copy($scope.selectedRule.value_list);

						$scope.vm.widgetData.columns = [];
						$scope.vm.widgetData.listValue = $scope.listValue;
						$scope.vm.widgetData.selectedRule = $scope.selectedRule;
						$scope.vm.widgetData.updateaction_l2 = $scope.saveListValue;
						$scope.$apply();
						$scope.vm.widgetData.showListValueFilter = true;
						$scope.$apply();
					};

					$scope.saveListValue = function () {
						$scope.selectedRule.value_list = $scope.vm.widgetData.listValue;
					};
					$scope.cancel = function () {
						closeModal();
					};
					var getRuleById_ = function (id) {
						var rules = getAllShildrenRules($scope.rulesRoot.rules);

						for (var r in rules) {
							if (rules[r].id_ == id) {
								return rules[r];
							}
						}
					};
					function closeModal() {
						$(vm.element).modal('hide');
						vm.data.showSelectColumnValues = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/selectColumnValues/selectColumnValues.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.data.showSelectColumnValues = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.data.showSelectColumnValues;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.take = scope.take ? scope.take : scope.defaultTake;

								getScopeQB = function () {
									return scope;
								};
								scope.initQueryBuilder();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
