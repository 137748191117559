(function () {
	'use strict';

	angular.module('dcApp').directive('jobDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$interval',
				'gettextCatalog',
				function ($scope, $rootScope, $interval, gettextCatalog) {
					var vm = this;
					$scope.globalInterval = 5;
					$scope.maxDiffBetweenPushDates = 1000;
					$scope.interval = 5;
					$scope.color = '#000000';
					$scope.durationCategories = [
						{ min: 0, max: 60 * 1000, color: '#6FCF97' },
						{ min: 60 * 1000, max: 3600 * 1000, color: '#6FCF97' },
						{ min: 3600, max: 86400 * 1000, color: '#FF7272' },
						{ min: 86400 * 1000, color: '#FF7272' },
					];
					$scope.initiated = false;
					$scope.rerun = function () {
						vm.data.rerunMethod();
					};

					$scope.init = function () {
						stopAllTimeouts();
						$scope.initiated = true;
						$scope.inconsistentPushDates = false;
						$scope.jobs = [];
						delete $scope.endExecutionFrom;
						$scope.endExecutionDate = 0;
						$scope.minPushDate = 0;
						updateGlobalEndExecutionFrom();
					};

					$scope.setFromCache = function (fromCache) {
						$scope.fromCache = fromCache;
					};

					$scope.initAndUpdate = function (job) {
						$scope.init();
						$scope.update(job);
					};

					var stopJobsTimeouts = function () {
						for (var j in $scope.jobs) {
							clearTimeout($scope.jobs[j].timeoutId);
						}
					};

					var stopAllTimeouts = function () {
						if ($scope.timeoutId) {
							clearTimeout($scope.timeoutId);
						}
						stopJobsTimeouts();
					};

					$scope.update = function (job) {
						if (!$scope.initiated) {
							$scope.init();
						}
						let jobItem = {};
						jobItem.name = gettextCatalog.getString(job.name);
						jobItem.pushDate = $rootScope.getDateTimeWithPattern(job.pushDate);
						jobItem.startExecutionDate = $rootScope.getDateTimeWithPattern(
							job.endExecutionDate - job.duration
						);
						jobItem.endExecutionDate = $rootScope.getDateTimeWithPattern(
							job.endExecutionDate
						);
						jobItem.duration = formatDurationSeconds(job.duration);

						if (
							$scope.endExecutionDate == 0 ||
							job.endExecutionDate < $scope.endExecutionDate
						) {
							$scope.endExecutionDate = job.endExecutionDate;
						}

						if (vm.onlyLongest) {
							//display only the job having the longest end execution date
							if ($scope.endExecutionDate == job.endExecutionDate) {
								$scope.setFromCache(job.is_cached);
								stopJobsTimeouts();
								$scope.jobs = [];
								updateEndExecutionFrom(jobItem, job);
								$scope.jobs.push(jobItem);
							}
						} else {
							updateEndExecutionFrom(jobItem, job);
							$scope.jobs.push(jobItem);
						}

						if ($scope.minPushDate == 0 || job.pushDate < $scope.minPushDate) {
							$scope.minPushDate = job.pushDate;
						}

						$scope.inconsistentPushDates =
							getDateDiff(job.pushDate, $scope.minPushDate) >
							$scope.maxDiffBetweenPushDates;
						setGlobalEndExecutionFrom();
					};

					var updateEndExecutionFrom = function (jobItem, job) {
						jobItem.endExecutionFrom = formatDurationSeconds(
							getCurrentDateDiff(job.endExecutionDate)
						);
						jobItem.timeoutId = setTimeout(
							function () {
								updateEndExecutionFrom(jobItem, job);
							},
							$scope.interval * 1000,
							true
						);
					};

					var updateGlobalEndExecutionFrom = function () {
						setGlobalEndExecutionFrom();
						$scope.timeoutId = setTimeout(
							function () {
								updateGlobalEndExecutionFrom();
							},
							$scope.globalInterval * 1000,
							true
						);
					};

					var setGlobalEndExecutionFrom = function () {
						if ($scope.endExecutionDate > 0) {
							let duration = getCurrentDateDiff($scope.endExecutionDate);
							$scope.endExecutionFrom = formatDurationSeconds(duration);
							if (duration >= $scope.durationCategories[0].min) {
								$scope.durationColor = $scope.durationCategories[0].color;
							} else {
								for (var g in $scope.durationCategories) {
									if (
										duration >= $scope.durationCategories[g].min &&
										duration < $scope.durationCategories[g].max
									) {
										$scope.durationColor = $scope.durationCategories[g].color;
										break;
									}
								}
							}
						}
					};

					$scope.$on('$destroy', function () {
						stopAllTimeouts();
					});

					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					config: '=',
					onlyLongest: '=',
					disableRerun: '=',
					color: '=',
					id: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/job/jobDetails/jobDetails.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.vm.data.init = scope.init;
					scope.uuid = generateUuid('_');
					scope.vm.data.update = scope.update;
					scope.vm.data.initAndUpdate = scope.initAndUpdate;
					scope.vm.data.setFromCache = scope.setFromCache;
					if (scope.vm.color) {
						scope.color = '#' + scope.vm.color;
					}
					scope.init();
				},
			};
		},
	]);
})();
