(function () {
	'use strict';

	angular.module('dcApp').directive('elementComment', [
		'$parse',
		function ($parse) {
			let controller = [
				'$rootScope',
				'$scope',
				'$window',
				'DcElementCommentService',
				'ProjectService',
				'toaster',
				'gettextCatalog',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'PROJECT_VAR_NAME',
				function (
					$rootScope,
					$scope,
					$window,
					DcElementCommentService,
					ProjectService,
					toaster,
					gettextCatalog,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					PROJECT_VAR_NAME
				) {
					let vm = this;
					let toasterPopSuccess = gettextCatalog.getString('Succès');
					$scope.currentUserId = $rootScope.account.userId;
					$scope.currentUsername = $rootScope.account.login;

					let comments = [];
					let selectedElms;

					let lookupUserDataSource = {
						store: new DevExpress.data.CustomStore({
							loadMode: 'raw',
							load: function () {
								return ProjectService.getMembersByProjectId(
									$window.localStorage.getItem(PROJECT_VAR_NAME)
								).then(function (data) {
									return data;
								});
							},
						}),
					};
					$scope.dataGridOptions = {
						dataSource: comments,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						scrolling: {
							rowRenderingMode: 'Standard',
							showScrollbar: 'onHover',
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						selection: {
							mode: 'multiple',
							showCheckBoxesMode: 'always',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columns: [
							{
								caption: gettextCatalog.getString('Utilisateur'),
								dataField: 'sender_id',
								cellTemplate: 'sender',
								lookup: {
									dataSource: lookupUserDataSource,
									valueExpr: 'memberId',
									displayExpr: 'memberLabel',
								},
							},
							{
								caption: gettextCatalog.getString('Date'),
								dataField: 'date',
								dataType: 'datetime',
								format: 'dd/MM/yyyy HH:mm:ss',
								width: '13%',
							},
							{
								caption: gettextCatalog.getString('Commentaire'),
								dataField: 'comment_value',
								width: '35%',
							},
							{
								caption: gettextCatalog.getString('Pour Qui'),
								dataField: 'receiver_id',
								cellTemplate: 'receiver',
								lookup: {
									dataSource: lookupUserDataSource,
									valueExpr: 'memberId',
									displayExpr: 'memberLabel',
								},
							},
							{
								caption: gettextCatalog.getString('Niveau'),
								dataField: 'level',
								width: '80px',
							},
							{
								caption: gettextCatalog.getString('Actions'),
								alignment: 'center',
								cellTemplate: 'actions',
								fixed: true,
								fixedPosition: 'right',
								width: '80px',
							},
						],
						onInitialized: function (e) {
							$scope.gridInstance = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							let selectedRowsElms = _.uniq(selectedItems.selectedRowsData);
							selectedElms = _.map(selectedRowsElms, function (elm) {
								return elm.id;
							});
							$scope.atLeastOneSelected = selectedElms.length > 0;
						},
						onToolbarPreparing: function (e) {
							e.toolbarOptions.items.unshift(
								{
									location: 'after',
									widget: 'dxButton',
									options: {
										icon: 'fas fa-trash fa-lg',
										hint: gettextCatalog.getString(
											'Supprimer tous les commentaires séléctionnés'
										),
										onClick: function () {
											$scope.doShowDeleteModal(null, true);
										},
										bindingOptions: {
											visible: 'atLeastOneSelected',
										},
									},
								},
								{
									location: 'after',
									widget: 'dxButton',
									options: {
										icon: 'far fa-sync fa-lg',
										hint: gettextCatalog.getString('Rafraîchir'),
										onClick: function () {
											getGridData();
										},
									},
								}
							);
						},
						onCellClick: function (e) {
							showValuePreviewOnCellDoubleClick(e, $scope.getShowValuePopup);
						},
					};

					$scope.showGrid = false;
					let getGridData = function () {
						DcElementCommentService.findAll(vm.elementId, vm.dcType).then(
							function (response) {
								comments = response.data;
								if ($scope.gridInstance && $scope.gridInstance.option) {
									$scope.gridInstance.option('dataSource', comments);
									$scope.gridInstance.refresh();
								}
							}
						);
					};
					vm.loadComments = function () {
						$scope.showGrid = true;
						getGridData();
						$(vm.element).modal(
							{ backdrop: 'static', keyboard: false },
							'show'
						);
					};

					$scope.users = [];
					$scope.selected = { user: {} };
					$scope.showNewCommentModal = function (modalId) {
						ProjectService.getMembersByProjectId(
							$window.localStorage.getItem(PROJECT_VAR_NAME)
						).then(function (members) {
							$scope.users = _.filter(members, function (member) {
								return member.memberId != $scope.currentUserId;
							});
							$scope.comment = {};
							$('#' + modalId).modal('show');
						});
					};

					$scope.saveComment = function (modalId) {
						let commentEntry = {
							comment_value: $scope.comment.comment_value,
							level: $scope.comment.level,
							receiver_id: $scope.selected.user
								? $scope.selected.user.memberId
								: undefined,
						};
						DcElementCommentService.postComment(
							commentEntry,
							vm.elementId,
							vm.dcType
						).then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								gettextCatalog.getString('Commentaire enregistré')
							);
							getGridData();
							$('#' + modalId).modal('hide');
						});
					};

					let deleteComment = function () {
						DcElementCommentService.deleteComment(commentToDelete.id).then(
							function () {
								getGridData();
								commentToDelete = undefined;
								toaster.pop(
									'success',
									gettextCatalog.getString('Succès'),
									gettextCatalog.getString('Suppression effectuée')
								);
							}
						);
					};

					let deleteComments = function () {
						DcElementCommentService.deleteSelectedComments(selectedElms).then(
							function () {
								getGridData();
								toaster.pop(
									'success',
									gettextCatalog.getString('Succès'),
									gettextCatalog.getString('Suppression effectuée')
								);
							}
						);
					};
					$scope.deleteElementData = {};
					let commentToDelete;
					$scope.doShowDeleteModal = function (elm, bulk) {
						commentToDelete = elm;
						let elmLabel = undefined;
						if (elm) {
							elmLabel =
								elm.comment_value && elm.comment_value.length > 50
									? elm.comment_value.substring(0, 5) + '...'
									: elm.comment_value;
						}
						$scope.deleteElementData = {
							label: elmLabel,
							doDelete: bulk ? deleteComments : deleteComment,
							showConfirmDeleteElement: true,
							bulk: bulk,
							count: selectedElms ? selectedElms.length : 0,
						};
					};

					$scope.previewModal = { showValuePreview: false, value: '' };
					$scope.getShowValuePopup = function (val) {
						$scope.previewModal.value = val;
						$scope.previewModal.showValuePreview = true;
					};
					$scope.hideModal = function (modalId) {
						$scope.showGrid = false;
						$('#' + modalId).modal('hide');
					};
				},
			];

			return buildDirectiveReturnObject(
				{ elementId: '=', dcType: '=', showElementComment: '=' },
				controller,
				'./src/components/directives/dcElementComment/dcElementComment.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showElementComment = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.showElementComment;
						},
						function () {
							scope.showGrid = false;
							scope.vm.loadComments();
						},
						true
					);
				}
			);
		},
	]);
})();
