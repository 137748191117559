(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller('DeleteProjectController', DeleteProjectController);

	DeleteProjectController.$inject = [
		'$log',
		'$timeout',
		'gettextCatalog',
		'ProjectService',
	];
	function DeleteProjectController(
		$log,
		$timeout,
		gettextCatalog,
		ProjectService
	) {
		const vm = this;
		const UPDATE_TIMEOUT = 650;
		const INITIAL_STEP = 1;
		const SECOND_STEP = 2;
		const FINAL_STEP = 3;
		const PREVIOUS = 'PREVIOUS';
		const NEXT = 'NEXT';
		let originalParamsKeys = [];
		let treeDatas = [];
		let updateParamKeyCb;
		let updateParamValueCb;
		vm.initialStep = INITIAL_STEP;
		vm.secondStep = SECOND_STEP;
		vm.finalStep = FINAL_STEP;
		vm.currentStep = INITIAL_STEP;
		vm.nextStep = NEXT;
		vm.previousStep = PREVIOUS;
		vm.chooseStep = chooseStep;
		vm.steps = [
			{
				stepState: vm.initialStep,
				stepName: gettextCatalog.getString('Première étape'),
				stepMessage: gettextCatalog.getString('Etape 1:  Eléments du projet'),
			},
			{
				stepState: vm.secondStep,
				stepName: gettextCatalog.getString('Deuxième étape'),
				stepMessage: gettextCatalog.getString(
					'Etape 2: Confirmation de suppression'
				),
			},
			{
				stepState: vm.finalStep,
				stepName: gettextCatalog.getString('Troisième étaoe'),
				stepMessage: gettextCatalog.getString(
					'Etape 3:  Saisie du code de Vérification'
				),
			},
		];
		vm.searchMode = 'contains';
		vm.accepted = false;
		vm.isValidCode = false;
		init();

		function init() {
			$log.info(vm.projectId);
			ProjectService.getProjectElements(vm.projectId).then((response) => {
				vm.projectElements = response;
				vm.treeViewOptions = {
					items: vm.projectElements.formattedData,
					rootValue: ' ',
					width: 600,
				};
			});
		}

		vm.searchModeOptions = {
			bindingOptions: {
				value: 'searchMode',
			},
			items: ['contains', 'startswith', 'equals'],
		};

		vm.deleteProject = function (deleteToken) {
			ProjectService.deleteProject(vm.projectId, deleteToken).then(
				(response) => {
					vm.$ignore();
					window.location.reload();
				}
			);
		};

		function chooseStep(stepType) {
			switch (vm.currentStep) {
				case INITIAL_STEP:
					vm.currentStep = stepType === NEXT ? SECOND_STEP : INITIAL_STEP;
					break;
				case SECOND_STEP:
					if (stepType === NEXT && vm.accepted) {
						vm.currentStep = FINAL_STEP;
					} else if (stepType === PREVIOUS) {
						vm.currentStep = INITIAL_STEP;
					}
					break;
				case FINAL_STEP:
					vm.currentStep = stepType === NEXT ? FINAL_STEP : SECOND_STEP;
					break;
				default:
					vm.currentStep = INITIAL_STEP;
			}
		}
	}
})();
