(function () {
	'use strict';

	angular.module('dcApp').directive('indicatorchartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'API_BASE_URL_BACKEND',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					API_BASE_URL_BACKEND
				) {
					var vm = this;

					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

					vm.init = function (ignoreCache) {
						delete $scope.current;
						ChartViewCommonFactory.initialize(
							'indicator',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope(
						$scope,
						vm,
						'indicator'
					);

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					$scope.previousItem = function () {
						$scope.currentNumber = $scope.currentNumber - 1;
						setCurrentItem();
					};

					$scope.nextItem = function () {
						$scope.currentNumber = $scope.currentNumber + 1;
						setCurrentItem();
					};

					var setCurrentItem = function () {
						let fixed = getPrecision($scope.config.global.valuePrecision);
						let dataItem = $scope.items[$scope.currentNumber];
						$scope.current = {
							argument: dataItem[$scope.config.global.argument],
							value: dataItem[$scope.config.global.value],
						};

						$scope.config.global.positiveStyle = $scope.config.global
							.positiveStyle
							? $scope.config.global.positiveStyle
							: {};
						$scope.config.global.negativeStyle = $scope.config.global
							.negativeStyle
							? $scope.config.global.negativeStyle
							: {};

						$scope.isNumeric = isNumeric($scope.current.value);
						if ($scope.isNumeric) {
							$scope.current.value = $scope.current.value.toFixed(fixed);
						}
						$scope.current.description = $scope.config.global.descriptionColumn
							? dataItem[$scope.config.global.descriptionColumn]
							: $scope.config.global.description;
						$scope.current.icon = $scope.config.global.positiveStyle.iconColumn
							? dataItem[$scope.config.global.positiveStyle.iconColumn]
							: $scope.config.global.positiveStyle.icon;
						let positive =
							$scope.goalDefined &&
							dataItem[$scope.config.global.indicator] >=
								dataItem[$scope.config.global.goal];

						if (positive) {
							$scope.current.objective_note = $scope.config.global.positiveStyle
								.noteColumn
								? dataItem[$scope.config.global.positiveStyle.noteColumn]
								: $scope.config.global.positiveStyle.note;
							$scope.current.objective_rate_color = $scope.config.global
								.positiveStyle.colorColumn
								? dataItem[$scope.config.global.positiveStyle.colorColumn]
								: $scope.config.global.positiveStyle.color;
						} else if ($scope.goalDefined) {
							$scope.current.objective_note = $scope.config.global.negativeStyle
								.noteColumn
								? dataItem[$scope.config.global.negativeStyle.noteColumn]
								: $scope.config.global.negativeStyle.note;
							$scope.current.objective_rate_color = $scope.config.global
								.negativeStyle.colorColumn
								? dataItem[$scope.config.global.negativeStyle.colorColumn]
								: $scope.config.global.negativeStyle.color;
							$scope.current.icon = $scope.config.global.negativeStyle
								.iconColumn
								? dataItem[$scope.config.global.negativeStyle.iconColumn]
								: $scope.config.global.negativeStyle.icon;
						}

						$scope.current.objective_rate = (
							($scope.current.value * 100) /
							dataItem[$scope.config.global.goal]
						).toFixed(2);
						$scope.unit = $scope.config.global.valueUnit;
					};

					var loadGroupDataInChart = function (data) {
						initPopover();
						$scope.goalDefined =
							$scope.config.global.indicator && $scope.config.global.goal;
						$scope.items = data;
						$scope.currentNumber = 0;
						$scope.total = data.length;
						setCurrentItem();

						return true;
					};

					var checkConfig = function (config) {
						return true;
					};

					// private
					function getPrecision(precision) {
						switch (precision) {
							case 'precision1':
								return 1;
							case 'precision2':
								return 2;
							case 'noPrecision':
							default:
								return 0;
						}
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/indicator/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
