(function () {
	'use strict';

	angular.module('dcApp').directive('traceLink', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'$window',
				'PermissionService',
				function ($scope, $state, $window, PermissionService) {
					$scope.goToTrace = function () {
						let url = $state.href('traces');
						// FIXME: use URL service to generate URL
						url = `${url}?element_id=${$scope.vm.id}&type=${$scope.vm.type}`;
						$window.open(url, '_blank');
					};

					PermissionService.haveGlobalPermission('traces').then(function (
						response
					) {
						$scope.authorized = response.data;
					});
				},
			];

			return buildDirectiveReturnObject(
				{ id: '=', type: '=' },
				controller,
				'./src/components/directives/traceLink/traceLink.html'
			);
		},
	]);
})();
