(function () {
	'use strict';

	angular.module('dcApp').controller('MetadataController', [
		'API_BASE_URL_BACKEND',
		'TagService',
		'$state',
		'$scope',
		'$rootScope',
		'$q',
		'toaster',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'SemanticContextService',
		'MetadataService',
		'$window',
		'permissionChecker',
		'gettextCatalog',
		function (
			API_BASE_URL_BACKEND,
			TagService,
			$state,
			$scope,
			$rootScope,
			$q,
			toaster,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			SemanticContextService,
			MetadataService,
			$window,
			permissionChecker,
			gettextCatalog
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.shortLabel = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.type = gettextCatalog.getString('Type');
			dataGirdColumnName.description = gettextCatalog.getString('Description');
			dataGirdColumnName.code = gettextCatalog.getString('Code');

			var rootTypes = [
				'SIMPLE_TYPES_STRING_ITEM',
				'SIMPLE_TYPES_INTEGER_ITEM',
				'SIMPLE_TYPES_BIG_INTEGER_ITEM',
				'SIMPLE_TYPES_BOOLEAN_ITEM',
				'SIMPLE_TYPES_DECIMAL_ITEM',
				'SIMPLE_TYPES_DATE_ITEM',
				'TYPES_WORDS_ITEM',
				'TYPES_FILE_ITEM',
			];
			$scope.rules = [];
			$scope.tagsList = [];

			permissionChecker.checkGlobalPermission(
				'tags',
				function () {
					TagService.getTags('').then(function (response) {
						$scope.tagsList = response.data;
					});
				},
				function () {}
			);

			function getMetadataList() {
				var deferred = $q.defer();
				MetadataService.getMetadataList('').then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getMetadataList().then(function (content) {
					if (content !== undefined) {
						$scope.initMetadatList = content;
						$scope.metadataList = [];
						var tags = [];
						$scope.tags = [];
						for (let i in content) {
							if (
								rootTypes.indexOf(content[i].code) === -1 &&
								content[i].hdh_type !== 'USER_GROUP'
							) {
								$scope.metadataList.push({
									id: content[i].id,
									code: content[i].code,
									hdhType: content[i].hdh_type,
									shortLab: content[i].label,
									description: content[i].description,
									tags: content[i].tags
										? _.map(content[i].tags, function (el) {
												return el.id;
										  })
										: [],
									tagAsArray: $rootScope.mapTags(content[i].tags),
								});
								tags = _.union(tags, content[i].tags);
							}
						}
						for (let i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.loadData = $scope.metadataList;
						$scope.dataGridOptions.dataSource = $scope.loadData;
						$scope.showGrid = true;
					}
				});
			};

			var getAvailableTags = function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						resolve(
							_.map(
								_.sortBy($scope.tags, function (i) {
									return i.label.toLowerCase();
								}),
								function (el) {
									return el.label;
								}
							)
						);
					}, 50);
				});
			};

			var lookupTagDataSource = {
				store: new DevExpress.data.CustomStore({
					loadMode: 'raw',
					load: function () {
						return getAvailableTags().then(function (response) {
							return response;
						});
					},
				}),
			};

			permissionChecker.checkGlobalPermission(
				'metadatas',
				function () {
					$scope.getGridData();
				},
				function () {
					$scope.metadata_unauthorised = true;
				}
			);

			$scope.dataGridOptions = {
				dataSource: {
					store: {
						type: 'array',
						key: 'id',
						data: 'metadataList',
					},
				},
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				editing: {
					mode: 'batch',
					allowUpdating: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				allowColumnReordering: true,
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				showBorders: true,
				columns: [
					{
						dataField: 'hdhType',
						caption: dataGirdColumnName.type,
						allowEditing: false,
					},
					{
						dataField: 'code',
						caption: dataGirdColumnName.code,
						allowEditing: false,
					},
					{
						dataField: 'shortLab',
						caption: dataGirdColumnName.shortLabel,
						validationRules: [
							{
								type: 'required',
								message: gettextCatalog.getString('Le libellé est obligatoire'),
							},
							{
								type: 'pattern',
								message: gettextCatalog.getString(
									'Le Libellé ne peut pas dépasser 128 caractères'
								),
								pattern: /^.{1,128}$/,
							},
						],
					},
					{
						dataField: 'description',
						caption: dataGirdColumnName.description,
						validationRules: [
							{
								type: 'pattern',
								message: gettextCatalog.getString(
									'La description ne doit pas dépasser 200 caractères'
								),
								pattern: /^.{0,200}$/,
							},
						],
					},
					{
						caption: dataGirdColumnName.tags,
						cellTemplate: 'cellTemplate',
						editCellTemplate: 'cellTemplate',
						dataField: 'tags',
						allowHeaderFiltering: true,
						allowFiltering: false,
						enableCellEdit: false,
						lookup: {
							dataSource: lookupTagDataSource,
						},
						calculateFilterExpression: function (
							filterValue,
							selectedFilterOperation
						) {
							if (!filterValue) return;
							return function (rowData) {
								return _.indexOf(rowData.tagAsArray, filterValue) != -1;
							};
						},
					},
					{
						alignment: 'center',
						cellTemplate: 'actions',
						width: 80,
					},
				],
				onRowUpdated: function (e) {
					var key = e.key.id;
					var initRow = _.find($scope.initMetadatList, function (elm) {
						return elm.id == key;
					});
					var updatedRow = _.find($scope.metadataList, function (elm) {
						return elm.id == key;
					});
					if (
						initRow != undefined &&
						initRow != {} &&
						updatedRow != undefined &&
						updatedRow != {}
					) {
						initRow.tags = _.filter($scope.tagsList, function (elm) {
							return _.indexOf(updatedRow.tags, elm.id) != -1;
						});
						for (var l in initRow.tags) {
							if (
								initRow.tags[l].id &&
								isNaN(initRow.tags[l].id) &&
								initRow.tags[l].id.startsWith('temp_id_')
							) {
								initRow.tags[l].id = null;
								initRow.tags[l].color =
									initRow.tags[l].color !== undefined
										? initRow.tags[l].color
										: '#dbf5d1';
							}
						}
						initRow.label = updatedRow.shortLab;
						initRow.description = updatedRow.description;
						MetadataService.saveMetadata(initRow).then(function () {});
					}
				},
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
			};

			$scope.tagBox = {
				displayExpr: 'code',
				valueExpr: 'id',
				acceptCustomValue: true,
				searchEnabled: true,
				tagTemplate: 'tagTemplate',
				onCustomItemCreating: function (args) {
					var newValue = {
						id: _.uniqueId('temp_id_'),
						code: args.text,
						color: '#dbf5d1',
					};
					args.customItem = newValue;
					$scope.tagsList.unshift(newValue);
					$scope.gridInstance.refresh();
				},
				onValueChanged: function (e) {
					$scope.gridInstance.cellValue(
						e.model.metadataListWidget.row.rowIndex,
						'tags',
						angular.copy(e.value)
					);
				},
			};

			$scope.getTagBox = function (list) {
				$scope.tagBox.dataSource = $scope.tagsList;
				$scope.tagBox.value = list.data.tags;

				return $scope.tagBox;
			};

			$scope.data = {
				initFilter: $scope.init,
			};

			$scope.hideMe = function (modalId) {
				$('#' + modalId).modal('hide');
			};

			$scope.goToElement = function (metadataId, type) {
				MetadataService.getElementIdByMetadataId(metadataId, type).then(
					function (response) {
						if (response.data) {
							var url = '';
							if (
								type === 'ENTITETYPE' ||
								type === 'CARACTERISTIQUE' ||
								type === 'DOCUMENT'
							) {
								url = $state.href('entites-edit', {
									entiteId: response.data.v2,
								});
							} else if (type === 'DATABLOCK') {
								url = $state.href('datablocks-edit', { id: response.data.v2 });
							} else if (type === 'CONNECTOR') {
								var reelType = response.data.v1;
								url = $state.href('connectors-edit', {
									type: reelType,
									id: response.data.v2,
								});
							} else if (type === 'DEPOT') {
								url = $state.href('depots-edit', {
									type: response.data.v1,
									id: response.data.v2,
								});
							} else if (type === 'DATAVIEW') {
								// FIXME:  A CORRIGER
								// url = $state.href('grids-edit', {
								// 	type: 'pivot',
								// 	id: response.data.v2,
								// });
							} else if (type === 'GRID') {
								url = $state.href('grids-edit', {
									id: response.data.v2,
									type: 'simple',
								});
							} else if (type === 'CHART') {
								// FIXME:  A CORRIGER manque le type
								// url = $state.href('charts-edit', { id: response.data.v2 });
							} else if (type === 'TIMELINE') {
								url = $state.href('timelines-edit', { id: response.data.v2 });
							} else if (type === 'MAP') {
								url = $state.href('maps-edit', { id: response.data.v2 });
							} else if (type === 'RANKING') {
								url = $state.href('rankings-edit', {
									rankingId: response.data.v2,
								});
							} else if (type === 'DASHBOARD') {
								url = $state.href('dashboards-edit', {
									type: response.data.v1,
									id: response.data.v2,
								});
							}

							if (url !== '') {
								$window.open(url, '_blank');
							}
						}
					}
				);
			};
		},
	]);
})();
