(function () {
	'use strict';

	angular.module('dcApp').factory('ProjectMemberAdapter', [
		'$rootScope',
		function ($rootScope) {
			const ProjectMemberAdapter = {};

			ProjectMemberAdapter.generateMember = function (response) {
				return response.map(function (beMember) {
					return beMember.is_group
						? adaptGroupMember(beMember)
						: adaptUserMember(beMember);
				});
			};

			ProjectMemberAdapter.generateMemberExtendedBasicInfo = function (data) {
				return data.map(function (beMember) {
					return {
						memberId: beMember.member_id,
						admin: beMember.admin,
						active: beMember.active,
						isGroup: beMember.is_group,
						memberLabel: beMember.label,
						canReceiveEmails: applyEmailReceiverRules(beMember),
					};
				});
			};

			function adaptGroupMember(member) {
				return {
					description: member.description,
					enabled: member.enabled,
					has_admin_role: member.has_admin_role.own,
					has_member_role: member.has_member_role.own,
					has_project_role: member.has_project_role.own,
					id: member.id,
					is_group: true,
					label: member.label,
					name: member.name,
				};
			}

			function adaptUserMember(member) {
				return {
					email: member.email,
					enabled: member.enabled,
					first_name: member.first_name,
					has_admin_role: member.has_admin_role.own,
					has_member_role: member.has_member_role.own,
					has_project_role: member.has_project_role.own,
					id: member.id,
					is_group: false,
					last_name: member.last_name,
					name: member.name,
					username: member.username,
				};
			}

			function applyEmailReceiverRules(beMember) {
				if (beMember.member_id === $rootScope.keycloak.subject) {
					return false;
				}
				if (beMember.is_group) {
					return false;
				}
				return beMember.admin;
			}

			return ProjectMemberAdapter;
		},
	]);
})();
