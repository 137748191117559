(function () {
	'use strict';

	angular.module('dcApp').directive('duplicateProjectDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'elem',
				function ($scope, $rootScope, elem) {
					var vm = this;
					vm.elem = elem;
					vm.isDisabled = true;
					vm.data = vm.data && Object.keys(vm.data).length !== 0 ? vm.data : {};
					vm.setContext = function (attribute) {
						vm.disabled = attribute;
						vm.elementTypes[vm.elem.type.localData].checked = false;
						vm.elementTypes[vm.elem.type.extractRepository].checked = false;
					};

					vm.checkDateValidity = function () {
						if (vm.data.duplicatedProjectExpiration) {
							var end = moment(vm.data.duplicatedProjectExpiration).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
					};
				},
			];
			return buildDirectiveReturnObject(
				{
					data: '=',
					projectInfos: '=',
					disabled: '=',
					elementTypes: '=',
				},
				controller,
				'./src/components/directives/duplicateProject/duplicateProjectDetails.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data;
						},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
