'use strict';

angular
	.module('dcApp')
	.factory('TypeEntiteService', [
		'$http',
		'$rootScope',
		'$q',
		'API_BASE_URL_BACKEND',
		'httpService',
		function ($http, $rootScope, $q, API_BASE_URL_BACKEND, httpService) {
			var TypeEntiteService = {},
				typeEntiteEndpoint = API_BASE_URL_BACKEND + 'entite_types';

			TypeEntiteService.searchLinksByMappedCarac = function (caracId) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('LINKS_SEARCH_BY_MAPPED_CARAC');
				url = url + '?caracId=' + caracId;
				return httpService.getRequest(url);
			};

			TypeEntiteService.createType = function (type) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_CREATE');
				return httpService.postRequest(url, type);
			};

			TypeEntiteService.getTypeWithLibs = function (entityTypeId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_GET');
				url = url.replace('{id}', entityTypeId);
				return httpService.getRequest(url);
			};

			TypeEntiteService.getLibsForMany = function (uetIds) {
				var url = API_BASE_URL_BACKEND + 'entite_types/libs';
				return httpService.postRequest(url, uetIds);
			};

			TypeEntiteService.getCodesForMany = function (uetIds) {
				var url = API_BASE_URL_BACKEND + 'entite_types/codes';
				return httpService.postRequest(url, uetIds);
			};

			TypeEntiteService.deleteEntiteType = function (
				entityTypeId,
				deleteLinks
			) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_DELETE_ONE');
				url = url.replace('{id}', entityTypeId);
				if (deleteLinks) {
					url = url + '?deleteLinks=' + deleteLinks;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getNbrEntities = function (entityTypeId, noCache) {
				let path = typeEntiteEndpoint + '/' + entityTypeId + '/entites_nbr';
				if ($rootScope.jobCacheActive && !noCache) {
					path = path + '?useCache=' + true;
				}
				return httpService.getRequest(path);
			};

			TypeEntiteService.getNbrValuesPerCarac = function (
				entityTypeId,
				noCache
			) {
				let path = typeEntiteEndpoint + '/' + entityTypeId + '/values_nbr';
				if ($rootScope.jobCacheActive && !noCache) {
					path = path + '?useCache=' + true;
				}
				return httpService.getRequest(path);
			};

			TypeEntiteService.getEntiteTypeGrammar = function (entiteTypeId) {
				return httpService.getRequest(
					typeEntiteEndpoint + '/' + entiteTypeId + '/grammar'
				);
			};

			TypeEntiteService.getPathAliases = function (entityTypeId) {
				return httpService.getRequest(
					typeEntiteEndpoint + '/' + entityTypeId + '/aliases'
				);
			};

			TypeEntiteService.getCaracs = function (
				entityTypeId,
				isSimple,
				withTags
			) {
				isSimple = !!isSimple;
				withTags = !!withTags;
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('ENTITES_CARACS') +
					'?isSimple=' +
					isSimple +
					'&withTags=' +
					withTags;
				url = url.replace('{id}', entityTypeId);
				return httpService.getRequest(url);
			};

			TypeEntiteService.getCaracCount = function (entityTypeId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_CARAC_COUNT');
				url = url.replace('{id}', entityTypeId);
				return httpService.getRequest(url);
			};

			TypeEntiteService.getAllTypes = function () {
				const url = typeEntiteEndpoint + '/all';
				return httpService.getRequest(url);
			};

			TypeEntiteService.searchEntitesExludingOne = function (
				entiteTypeLib,
				caracEntiteTypeId
			) {
				var params = {};
				if (caracEntiteTypeId) {
					params.excludeType = caracEntiteTypeId;
				}
				params.libCourt = entiteTypeLib;
				if (entiteTypeLib) {
					params.libCourt = entiteTypeLib;
				}
				const url = typeEntiteEndpoint + '/search';
				return $http.get(url, { params: params }).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getSimpleTypes = function () {
				const url = API_BASE_URL_BACKEND + '/simple_types';
				return httpService.getRequest(url);
			};

			TypeEntiteService.editType = function (entityType) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_EDIT');
				return httpService.putRequest(url, entityType);
			};

			TypeEntiteService.getMappedCaracCount = function (caracId) {
				const url = typeEntiteEndpoint + '/caracs/count/' + caracId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getLinksCountByCarac = function (caracId) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('ENTITE_TYPE_MAPPING_COUNT_BY_CARAC');
				url = url.replace('{caracId}', caracId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getLinksCount = function (entityTypeId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITE_TYPE_LINKS_COUNT');
				url = url.replace('{entiteTypeId}', entityTypeId);
				return httpService.getRequest(url);
			};

			TypeEntiteService.getAttachedElementsCommon = function (
				ids,
				dataBlockId,
				axe
			) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + '/hdh_elements/commons';
				if (axe === undefined) {
					axe = 'DB';
				}
				url = url + '?axe=' + axe;
				if (dataBlockId !== undefined) {
					url = url + '&dataBlockId=' + dataBlockId;
				}
				$http.post(url, ids).then(
					function (reponse) {
						deferred.resolve(reponse);
					},
					function (err) {
						$q.reject(err);
					}
				);
				return deferred.promise;
			};

			TypeEntiteService.getDataBlocksUsing = function (entityTypeId) {
				const url = typeEntiteEndpoint + '/' + entityTypeId + '/datablocks';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getTypes = function (excludedId, excludePublic, excludeInactive) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_GET_ALL');
				excludedId = excludedId !== undefined ? excludedId : '';
				url = url + '?exclude=' + excludedId;
				url =excludeInactive ? url + '&excludeInactive=true' : url + '&excludeInactive=false' ;
				url =excludePublic ? url + '&excludePublic=true' : url + '&excludePublic=false' ;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.testMappingsData = function (
				mappings,
				distinct,
				linked,
				noCache
			) {
				var path =
					API_BASE_URL_BACKEND +
					'/caracsMappingsTest/data?distinct=' +
					distinct +
					'&linked=' +
					linked;
				if ($rootScope.jobCacheActive && !noCache) {
					path = path + '&useCache=' + true;
				}
				return httpService.postRequest(path, mappings);
			};

			TypeEntiteService.getEntiteTypeCountLinked = function (
				mappings,
				distinct,
				clientId,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/link_analyse?distinct=' +
					distinct +
					'&clientId=' +
					clientId;
				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}
				return httpService.postRequest(url, mappings);
			};

			TypeEntiteService.getStatFunctions = function (
				entiteTypeId,
				clientId,
				vari
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/stat?clientId=' +
					clientId;
				return httpService.postRequest(url, vari);
			};

			TypeEntiteService.getEntiteTypeValuesCountDistinct = function (
				caracs,
				entiteTypeId,
				clientId,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/values_count_distinct?clientId=' +
					clientId;
				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}
				return httpService.postRequest(url, caracs);
			};

			TypeEntiteService.getEntiteTypeValuesStat = function (
				carac,
				entiteTypeId,
				clientId,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/values_stat?clientId=' +
					clientId;
				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}
				return httpService.postRequest(url, carac);
			};

			TypeEntiteService.getEntiteTypeValuesCount = function (
				caracs,
				entiteTypeId,
				clientId,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/values_count?clientId=' +
					clientId;
				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}
				return httpService.postRequest(url, caracs);
			};

			TypeEntiteService.getEntiteTypeCountAll = function (
				entiteTypeId,
				clientId,
				noCache
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/count?clientId=' +
					clientId;

				if ($rootScope.jobCacheActive && !noCache) {
					url = url + '&useCache=' + true;
				}

				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			TypeEntiteService.getCaracTopValues = function (
				caracId,
				entiteTypeId,
				clientId,
				limit,
				order
			) {
				var url =
					API_BASE_URL_BACKEND +
					'entite_types/' +
					entiteTypeId +
					'/top_values/' +
					caracId +
					'?clientId=' +
					clientId +
					'&limit=' +
					limit +
					'&order=' +
					order;

				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			TypeEntiteService.getEntiteTypeStatResultByJobKey = function (jobKey) {
				var url =
					API_BASE_URL_BACKEND +
					'/entite_types/stat_job_result?jobKey=' +
					jobKey;
				return httpService.getRequest(url);
			};

			TypeEntiteService.deleteSelectedUets = function (selectedUets) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITES_DELETE_BULK');
				if (Array.isArray(selectedUets)) {
					url = url + '?selectedUets=' + selectedUets[0];
					for (let i = 1; i < selectedUets.length; i++) {
						url = url + '&selectedUets=' + selectedUets[i];
					}
				} else {
					url = url + '?selectedUets=' + selectedUets;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.deleteAllHdhElementsUets = function (selectedUets) {
				const url = typeEntiteEndpoint + '/deleted_hdh_elements';
				return $http.post(url, selectedUets).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getEntiteCaracs = function (entiteTypeId) {
				const url =
					typeEntiteEndpoint + '/' + entiteTypeId + '/caracs_elementary_data';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.generateMetadataForDbOrDv = function (
				entityTypeId,
				prefix
			) {
				const url =
					typeEntiteEndpoint +
					'/' +
					entityTypeId +
					'/generate_metadata?prefix=' +
					(prefix !== undefined ? prefix : '');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getPersistanceParams = function (entiteTypeId) {
				const url =
					typeEntiteEndpoint + '/' + entiteTypeId + '/persistance_params';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.updatePersistanceParams = function (
				params,
				entiteTypeId
			) {
				var url =
					typeEntiteEndpoint + '/' + entiteTypeId + '/persistance_params';
				return httpService.postRequest(url, params);
			};

			TypeEntiteService.getColumnsFromCaracs = function (
				entiteTypeId,
				excludeLinks
			) {
				excludeLinks = excludeLinks ? excludeLinks : false;
				const url =
					typeEntiteEndpoint +
					'/' +
					entiteTypeId +
					'/columns?excludeLinks=' +
					excludeLinks;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getCaracLibsByCodes = function (
				entiteTypeId,
				caracCodes
			) {
				const url =
					typeEntiteEndpoint + '/' + entiteTypeId + '/caracs_libs_from_codes';
				return $http.post(url, caracCodes).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getGrapheAlias = function (imposed, proposed, masqued) {
				var url = API_BASE_URL_BACKEND + 'graphe_alias';
				url =
					url +
					'?imposed=' +
					imposed +
					'&proposed=' +
					proposed +
					'&masqued=' +
					masqued;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getGraphe = function (queryData, excludePublic) {
				let url = API_BASE_URL_BACKEND + 'full_graphe';
				url = url + '?excludePublic=' + excludePublic;
				return $http.post(url, queryData).then(function (response) {
					return response;
				});
			};

			TypeEntiteService.getHdhGraph = function (
				axe,
				filter,
				filteredType,
				filteredId,
				withPersistenceInfo
			) {
				var url =
					API_BASE_URL_BACKEND +
					'hdh_graph?axe=' +
					axe +
					'&withPersistenceInfo=' +
					withPersistenceInfo;
				if (filteredId && filteredType) {
					url =
						url + '&filteredType=' + filteredType + '&filteredId=' + filteredId;
				}
				return httpService.postRequest(url, filter);
			};

			return TypeEntiteService;
		},
	])
	.factory('EntiteFileService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		'$q',
		'httpService',
		function ($http, API_BASE_URL_BACKEND, $rootScope, $q, httpService) {
			var EntiteFileService = {},
				entiteFilesEndPoint = API_BASE_URL_BACKEND + 'entite_file';

			EntiteFileService.getEntiteTypeFilesList = function (entiteId) {
				const url = entiteFilesEndPoint + '/list' + '/' + entiteId;
				return httpService.getRequest(url);
			};

			EntiteFileService.deleteEntiteTypeFile = function (data) {
				const url = entiteFilesEndPoint + '/delete';
				return $http.delete(url, { params: data }).then(function (response) {
					return response;
				});
			};

			EntiteFileService.getHeaders = function (entiteId) {
				const url = entiteFilesEndPoint + '/headers/' + entiteId;
				return httpService.getRequest(url);
			};

			EntiteFileService.saveCaracDepotMapping = function (params) {
				const url = entiteFilesEndPoint + '/mapping';
				return httpService.postRequest(url, params);
			};

			EntiteFileService.getEntiteFileCount = function (entiteId) {
				const url = entiteFilesEndPoint + '/count/' + entiteId;
				return httpService.getRequest(url);
			};

			EntiteFileService.updateCronDetails = function (params) {
				const url = entiteFilesEndPoint + '/update_cron';
				return httpService.putRequest(url, params, false);
			};

			EntiteFileService.getEntiteTypesByDataSource = function (
				sourceId,
				isFile
			) {
				const url = entiteFilesEndPoint + '/entite_types/get/';
				return httpService.getRequest(url + sourceId + '?isFile=' + isFile);
			};

			EntiteFileService.getEntiteTypesByDbConnexion = function (dbConnexionId) {
				const url =
					entiteFilesEndPoint +
					'/entite_types/get_by_connexion/' +
					dbConnexionId;
				return httpService.getRequest(url);
			};

			EntiteFileService.updateEtDsCsvStatus = function (
				entiteTypeDsId,
				status
			) {
				const url =
					entiteFilesEndPoint +
					'/entite_types/update_csv/' +
					'?entiteTypeDsId=' +
					entiteTypeDsId +
					'&active=' +
					status;
				return httpService.putRequest(url, {}, false);
			};

			EntiteFileService.updateEtDsDbStatus = function (entiteTypeDsId, status) {
				const url =
					entiteFilesEndPoint +
					'/entite_types/update_db/' +
					'?entiteTypeDsId=' +
					entiteTypeDsId +
					'&active=' +
					status;
				return httpService.putRequest(url, {}, false);
			};

			return EntiteFileService;
		},
	])
	.factory('CaracFileMappingService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		'$q',
		function ($http, API_BASE_URL_BACKEND, $rootScope, $q) {
			var CaracFileMappingService = {},
				caraFileMappingEndPoint = API_BASE_URL_BACKEND + 'entite_file/mapping';

			CaracFileMappingService.getHeaders = function (entiteId) {
				const url = caraFileMappingEndPoint + '/headers/' + entiteId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracFileMappingService.saveCaracDepotMapping = function (
				params,
				caracId
			) {
				var deferred = $q.defer();
				const url = caraFileMappingEndPoint + '/add/' + caracId;
				$http.post(url, params).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			CaracFileMappingService.getMappingByCaracId = function (carcaId) {
				const url = caraFileMappingEndPoint + '/' + carcaId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracFileMappingService.deleteByCaracIdAndDepotId = function (
				caracId,
				depotId,
				isDb,
				linkId
			) {
				const url =
					caraFileMappingEndPoint +
					'?caracId=' +
					caracId +
					'&depotId=' +
					depotId +
					'&isDb=' +
					isDb +
					'&linkId=' +
					linkId;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			CaracFileMappingService.saveBulkMapping = function (params, entiteId) {
				var deferred = $q.defer();
				const url = caraFileMappingEndPoint + '/bulk/' + entiteId;
				$http.post(url, params).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			return CaracFileMappingService;
		},
	])
	.factory('LinkMappingService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		'$q',
		function ($http, API_BASE_URL_BACKEND, $rootScope, $q) {
			var LinkMappingService = {};

			LinkMappingService.getLinkMapping = function (caracId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITE_LINK_GET_MAPPINGS');
				url = url.replace('{caracId}', caracId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			LinkMappingService.createCaracLinkMappings = function (
				cacacLinkMappings
			) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITE_LINK_CREATE');
				$http.post(url, cacacLinkMappings).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			LinkMappingService.saveCaracLinkMappings = function (cacacLinkMappings) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITE_LINK_EDIT');
				$http.put(url, cacacLinkMappings).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			return LinkMappingService;
		},
	])
	.factory('DataSourceService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		function ($http, API_BASE_URL_BACKEND, $rootScope) {
			var DataSourceService = {},
				dataSourcesEndpoint = API_BASE_URL_BACKEND + 'entite_file/mapping/';

			DataSourceService.getAvailableDataSources = function () {
				const url = dataSourcesEndpoint + 'data_sourses';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return DataSourceService;
		},
	])
	.factory('StorageModeService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		function ($http, API_BASE_URL_BACKEND, $rootScope) {
			var StorageModeService = {},
				url = API_BASE_URL_BACKEND + 'storage_modes';

			StorageModeService.getStorageModes = function () {
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return StorageModeService;
		},
	])
	.factory('EntiteDepotService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		function ($http, API_BASE_URL_BACKEND, $rootScope) {
			var EntiteDepotService = {},
				entiteDepotEndPoint =
					API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITE_DEPOT_LINK');

			EntiteDepotService.getEntiteTypeCsvDepotList = function (entiteId) {
				const url = entiteDepotEndPoint + '/depots/?uetId=' + entiteId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getLinks = function (entiteId) {
				const url = entiteDepotEndPoint + '?uetId=' + entiteId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getEntiteTypeDepotLinksList = function (
				entiteId,
				withMappingCount
			) {
				withMappingCount =
					withMappingCount !== undefined ? withMappingCount : false;
				const url =
					entiteDepotEndPoint +
					'/' +
					entiteId +
					'?withMappingCount=' +
					withMappingCount;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.createEntiteTypeDepotLinks = function (
				entiteId,
				depotsIds
			) {
				var url = entiteDepotEndPoint + '/depots/?uetId=' + entiteId;
				return $http.post(url, depotsIds).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.updateEntiteTypeDepotLink = function (id, status) {
				const url = entiteDepotEndPoint + '?id=' + id + '&status=' + status;
				return $http.put(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.deleteEntiteTypeDepotLink = function (id) {
				const url = entiteDepotEndPoint + '?id=' + id;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getUetByDepotId = function (depotId) {
				const url = entiteDepotEndPoint + '/entite_by_depot/' + depotId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getUetCountByDepotId = function (depotId) {
				const url =
					entiteDepotEndPoint + '/entite_by_depot/' + depotId + '/count';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.updateEntiteTypeLoadingLive = function (id, status) {
				const url =
					entiteDepotEndPoint + '/live' + '?linkId=' + id + '&status=' + status;
				return $http.put(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.updateEntiteTypeDateExtractionFilter = function (
				linkId,
				filterId
			) {
				const url =
					entiteDepotEndPoint +
					'/extraction_date_filter' +
					'?linkId=' +
					linkId +
					'&filterId=' +
					filterId;
				return $http.put(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getEntiteDepotLinksCount = function (entiteId) {
				const url = entiteDepotEndPoint + '/count/' + entiteId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			EntiteDepotService.getDepotWithHeadersAndMapping = function (entiteId) {
				const url = entiteDepotEndPoint + '/with_headers_mapping/' + entiteId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return EntiteDepotService;
		},
	]);
