'use strict';

angular
	.module('dcApp')
	.controller('UserListController', [
		'PermissionService',
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'UserService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$q',
		'permissionChecker',
		'gettextCatalog',
		function (
			PermissionService,
			$timeout,
			$scope,
			$state,
			$rootScope,
			toaster,
			UserService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$q,
			permissionChecker,
			gettextCatalog
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.lastName = gettextCatalog.getString('Nom');
			dataGirdColumnName.firstName = gettextCatalog.getString('Prénom');
			dataGirdColumnName.username = gettextCatalog.getString(
				"Nom de l'utilisateur"
			);
			dataGirdColumnName.locked = gettextCatalog.getString('Verrouillé');
			dataGirdColumnName.effectiveDate =
				gettextCatalog.getString("Date d'effet");
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.revocationDate =
				gettextCatalog.getString('Date de révocation');
			dataGirdColumnName.roles = gettextCatalog.getString('Rôles');
			dataGirdColumnName.enabled = gettextCatalog.getString('Actif');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopNoAuthorizedAction = gettextCatalog.getString(
				'Action non autorisée'
			);
			let toasterPopPermission = gettextCatalog.getString('Permission');
			let toasterPopDeleteUser = gettextCatalog.getString(
				"Suppression de l'Utilisateur effectuée"
			);

			$scope.userLogoutData = {};

			$scope.confirmUserLogoutData = {};

			$scope.hasDefaultUserRoleActive = $rootScope.hasDefaultUserRoleActive;

			$scope.userLogoutData.confirmLogout = function (userId) {
				$scope.confirmUserLogoutData.userId = userId;
				$scope.confirmUserLogoutData.showConfirmUserLogout = true;
			};

			$scope.showDeleteModal = function (modalId, obj) {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop(
							'error',
							toasterPopPermission,
							toasterPopNoAuthorizedAction
						);
						return;
					} else {
						$scope.objToDelete = obj;
						$('#' + modalId).modal('show');
					}
				});
			};

			$scope.deleteUser = function (modalId) {
				$('#' + modalId).modal('hide');
				UserService.deleteUser($scope.objToDelete.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteUser);
					initUsers();
				});
			};

			function getListUsers(refresh) {
				return UserService.getUsers($scope.active, refresh);
			}

			$scope.getGridData = function (refresh) {
				$scope.showGrid = false;
				getListUsers(refresh).then(function (users) {
					if (users !== undefined) {
						$scope.users = users;
						$scope.usersGridData = $scope.users;
						$scope.dataGridOptions.dataSource = $scope.usersGridData;
						$scope.showGrid = true;
					}
				});
			};

			$scope.dataGridOptions = {
				dataSource: $scope.usersGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.lastName,
						dataField: 'last_name',
						cellTemplate: 'clickableCell',
					},
					{
						caption: dataGirdColumnName.firstName,
						dataField: 'first_name',
						cellTemplate: 'clickableCell',
					},
					{
						caption: dataGirdColumnName.username,
						dataField: 'username',
						cellTemplate: 'clickableCell',
					},
					{
						caption: dataGirdColumnName.roles,
						alignment: 'center',
						cellTemplate: 'userRoles',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.enabled,
						dataField: 'enabled',
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actions',
						width: 220,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
			};

			function initUsers() {
				$scope.getGridData();
			}

			$scope.refreshUserList = function () {
				$scope.showGrid = false;
				$scope.getGridData(true);
			};

			permissionChecker.checkGlobalPermission(
				'users',
				function () {
					initUsers();
				},
				function () {
					$scope.users_unauthorised = true;
				}
			);
		},
	])
	.controller('EditUserController', [
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'$filter',
		'toaster',
		'UserService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'DATE_PATTERN',
		'UserGroupService',
		'gettextCatalog',
		function (
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			$filter,
			toaster,
			UserService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			DATE_PATTERN,
			UserGroupService,
			gettextCatalog
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.all = gettextCatalog.getString('Tout');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopPermission = gettextCatalog.getString('Permission');
			let toasterPopResetMailPassword = gettextCatalog.getString(
				'E-mail de réinitialisation du mot de passe envoyé'
			);
			let toasterPopCreationUser = gettextCatalog.getString(
				"Création de l'Utilisateur effectuée"
			);
			let toasterPopEditUser = gettextCatalog.getString(
				"Modification de l'Utilisateur effectuée"
			);
			let toasterPopNoAuthorizedAction = gettextCatalog.getString(
				'Action non autorisée'
			);
			let toasterPopActivationSuccess = gettextCatalog.getString(
				"Succès de l'activation"
			);
			let toasterPopDesAcSuccess = gettextCatalog.getString(
				'Succès de la déactivation'
			);
			let toasterPopUserActivatedSuccess = gettextCatalog.getString(
				"L'Utilisateur a été activé avec succès"
			);
			let toasterPopUserDesActivatedSuccess = gettextCatalog.getString(
				"L'Utilisateur a été désactivé avec succès"
			);
			let toasterPopUserAddSuccess = gettextCatalog.getString('Ajout effectué');
			let toasterPopUserDeleteSuccess = gettextCatalog.getString(
				'Suppression effectuée'
			);
			let toasterPopDeleteUser = gettextCatalog.getString(
				"Suppression de l'Utilisateur effectuée"
			);

			let userTypePerson = gettextCatalog.getString('Personne');
			let userTypeSystem = gettextCatalog.getString('Système');

			$scope.disableUser = gettextCatalog.getString("Désactiver l'utilisateur");
			$scope.enableUser = gettextCatalog.getString("Activer l'utilisateur");
			$rootScope.fields = [];
			$scope.attemptConnexion = [
				{ value: 1, label: '1' },
				{ value: 2, label: '2' },
				{ value: 3, label: '3' },
			];
			$scope.attemptPassword = [
				{ value: 1, label: '1' },
				{ value: 2, label: '2' },
				{ value: 3, label: '3' },
			];
			$scope.types = [
				{ value: 1, label: userTypePerson },
				{ value: 2, label: userTypeSystem },
			];
			$scope.selectedTryPasswordKo = 3;
			$scope.selectedTryPasswordQuestionKo = 3;
			$scope.userLogoutData = {};
			$scope.confirmUserLogoutData = {};
			$scope.permissionData = {};

			$scope.userLogoutData.confirmLogout = function (userId) {
				$scope.confirmUserLogoutData.userId = userId;
				$scope.confirmUserLogoutData.showConfirmUserLogout = true;
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('users');
				}, 300);
			};

			$scope.saveAndReturnToList = function () {
				$scope.saveUser(true);
			};

			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.saveUser = function (returnToList) {
				$('#confirmationModal').modal('hide');
				$rootScope.fields = [];

				if ($scope.isNew) {
					UserService.createUser($scope.currentUser).then(function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopCreationUser);
						if (returnToList) {
							$timeout(function () {
								$state.go('users');
							}, 300);
						} else {
							$timeout(function () {
								$state.go('users-edit', { id: response.data.id });
							}, 300);
						}
					});
				} else {
					UserService.editUser(
						$scope.currentUser.id,
						$scope.currentUser,
						false,
						handleFailedUpdateException
					).then(function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopEditUser);
						if (returnToList) {
							$timeout(function () {
								$state.go('users');
							}, 300);
						}
					});
				}
			};

			var handleFailedUpdateException = function (error) {
				if (error.code === '44') {
					$('#updateModal').modal('show');
				}
			};

			$scope.cancelUpdate = function () {
				$('#updateModal').modal('hide');
				init();
			};

			$scope.confirmUpdate = function () {
				$('#updateModal').modal('hide');
				UserService.editUser(
					$scope.currentUser.id,
					$scope.currentUser,
					true,
					handleFailedUpdateException
				).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopEditUser);
				});
			};

			// Set old state
			UserService.setOldState($state.current.name);

			init();

			function init() {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop(
							'error',
							toasterPopPermission,
							toasterPopNoAuthorizedAction
						);
						$state.go('users');
					} else {
						const isInEditMode =
							$stateParams.id !== null && $stateParams.id !== undefined;
						if (!isInEditMode) {
							$scope.isNew = true;
							$scope.currentUser = {};
							$scope.currentUser.enabled = true;
							$scope.currentUser.visible = true;
							$scope.currentUser.editable = true;
							$scope.currentUser.locked = true;
							$scope.currentUser.password = '';
							$scope.currentUser.password_confirmation = '';
						}

						// edit or duplicate
						if (isInEditMode) {
							$scope.isNew = false;
							var userId = $stateParams.id;
							//TODO KC
							/* UserService.getLastLoginDate(userId).then(function (responseLoginDate) {
                        if(responseLoginDate.data){
                            $scope.lastLoginDate = moment(responseLoginDate.data).format('LLLL');
                        }else{
                            $scope.lastLoginDate = ''
                        }
                    }) */
							UserService.getUser(userId).then(function (user) {
								$scope.currentUser = user;
							});
						}
					}
				});
			}

			$scope.sendResetPassword = function () {
				UserService.sendResetPasswordMailByUserId($scope.currentUser.id).then(
					function (response) {
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopResetMailPassword
						);
					}
				);
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showDeleteConModal = function (modalId) {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop(
							'error',
							toasterPopPermission,
							toasterPopNoAuthorizedAction
						);
						return;
					} else {
						$scope.objToDelete = $scope.currentUser;
						$('#' + modalId).modal('show');
					}
				});
			};

			$scope.changeUserStatus = function (modalId) {
				UserService.isAdmin().then(function (response) {
					if (!response.data) {
						toaster.pop(
							'error',
							toasterPopPermission,
							toasterPopNoAuthorizedAction
						);
						return;
					} else {
						changeUserStatus($scope.currentUser.enabledTmp);
					}
				});
			};

			var changeUserStatus = function (active) {
				if (active) {
					$scope.updateUserStatus(true);
				} else {
					$('#deactivateModal').modal('show');
				}
			};

			$scope.updateUserStatus = function (active) {
				//$('#' + modalId).modal('hide');
				UserService.updateStatus($scope.currentUser.id, active).then(function (
					response
				) {
					$scope.currentUser.enabledTmp = active;
					$scope.currentUser.enabled = active;
					if (active) {
						toaster.pop(
							'success',
							toasterPopActivationSuccess,
							toasterPopUserActivatedSuccess
						);
					} else {
						$('#deactivateModal').modal('hide');
						$rootScope.$broadcast('user-logout', $scope.currentUser.id);
						toaster.pop(
							'success',
							toasterPopDesAcSuccess,
							toasterPopUserDesActivatedSuccess
						);
					}
				});
			};

			$scope.cancelDeactivateUser = function () {
				$scope.currentUser.enabledTmp = true;
				$('#deactivateModal').modal('hide');
			};

			$scope.deleteUser = function (modalId) {
				$('#' + modalId).modal('hide');
				UserService.deleteUser($scope.objToDelete.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteUser);
					$timeout(function () {
						$state.go('users');
					}, 300);
				});
			};

			function createList(selector, items, isForUserGroups) {
				var list = $(selector)
					.dxList({
						items: items,
						repaintChangesOnly: true,
						keyExpr: 'id',
						displayExpr: 'label',
						itemDragging: {
							allowReordering: true,
							group: 'groups',
							onDragStart: function (e) {
								e.itemData = items[e.fromIndex];
							},
							onAdd: function (e) {
								if (isForUserGroups) {
									addGroup(e.itemData.id);
								}
								items.splice(e.toIndex, 0, e.itemData);
								list.option('items', items);
							},
							onRemove: function (e) {
								if (isForUserGroups) {
									removeGroup(e.itemData.id);
								}
								items.splice(e.fromIndex, 1);
								list.option('items', items);
							},
						},
					})
					.dxList('instance');
			}

			function addGroup(groupId) {
				UserGroupService.addUser(groupId, $scope.currentUser.id).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopUserAddSuccess);
				});
			}

			function removeGroup(groupId) {
				UserGroupService.removeUser(groupId, $scope.currentUser.id).then(
					function (response) {
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopUserDeleteSuccess
						);
					}
				);
			}

			$scope.getGlobalPermissions = function () {
				$scope.userTabHidden = true;
				$scope.showGlobalPermissions = true;
			};

			$scope.getElementPermissions = function () {
				$scope.userTabHidden = true;
				$scope.showElementPermissions = true;
			};

			$scope.getUserGroups = function () {
				$scope.userTabHidden = true;
				UserGroupService.getGroupsByUser($scope.currentUser.id).then(function (
					response
				) {
					const userGroups = response.data;
					UserGroupService.getGroups().then(function (availableGroupsResponse) {
						var availableGroups = _.filter(
							availableGroupsResponse,
							function (item) {
								return !_.filter(userGroups, function (group) {
									return group.id === item.id;
								})[0];
							}
						);
						createList('#availableGroups', availableGroups);
						createList('#userGroups', userGroups, true);
					});
				});
			};
		},
	]);
