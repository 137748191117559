(function() {
	'use strict';

	angular
		.module('dcApp')
		.factory('ExportService', ExportService);

	ExportService.$inject = [
		'httpService',
		'UrlService',
	];

	function ExportService(
		httpService,
		UrlService
	) {
		var ExportService = {};

		ExportService.exportToDb = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_TO_DB', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToEs = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_ES', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToCSV = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_CSV', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToXML = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_XML', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToExcel = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_EXCEL', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToJson = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_JSON', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToParquet = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_PARQUET', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		ExportService.exportToNeo4j = function (request, datablockId) {
			const url = UrlService.generateUrl('EXPORT_DATA_BLOCK_TO_NEO4J', {
				datablockId: datablockId
			});
			return httpService.postRequest(url, request);
		};

		return ExportService;
	}
})();
