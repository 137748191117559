(function () {
	'use strict';

	angular.module('dcApp').directive('linkStat', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'TypeEntiteService',
				'SparkJobsServices',
				'gettextCatalog',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'GenericService',
				'toaster',
				function (
					$scope,
					$rootScope,
					TypeEntiteService,
					SparkJobsServices,
					gettextCatalog,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					GenericService,
					toaster
				) {
					var vm = this;
					let toasterPopEntityError = gettextCatalog.getString(
						'Il faut choisir deux Entités Métier pour tester un lien !'
					);
					let toasterPopContextError = gettextCatalog.getString(
						'context.unavailable'
					);
					let toasterPopDataError = gettextCatalog.getString(
						'Impossible de récupérer  les données demandées'
					);
					let toasterPopError = gettextCatalog.getString('Erreur');
					let translateDataGridTitle = {};
					translateDataGridTitle.towards = gettextCatalog.getString('vers');
					translateDataGridTitle.value = gettextCatalog.getString('Valeurs');
					translateDataGridTitle.lignes = gettextCatalog.getString('Lignes');
					translateDataGridTitle.linked = gettextCatalog.getString('Liées');
					translateDataGridTitle.noLinked =
						gettextCatalog.getString('Non liées');
					translateDataGridTitle.is = gettextCatalog.getString('soit');

					var handleSocketMessageJobRunning = function (notif) {
						if (!notif.data.client_id) {
							return;
						}
						let concernedByJob = false;
						if (
							$scope.leftTotalCountLoading &&
							$scope.leftTotalCountClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.leftTotalCountLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.rightTotalCountLoading &&
							$scope.rightTotalCountClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.rightTotalCountLoading = false;
								return;
							}
							concernedByJob = true;
						}

						if (
							$scope.fromEntiteDistinctValuesLoading &&
							$scope.fromEntiteDistinctValuesClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.fromEntiteDistinctValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.toEntiteDistinctValuesLoading &&
							$scope.toEntiteDistinctValuesClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.toEntiteDistinctValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.fromEntiteLinkedValuesLoading &&
							$scope.fromEntiteLinkedValuesClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.fromEntiteLinkedValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.fromEntiteLinkedDistinctValuesLoading &&
							$scope.fromEntiteLinkedDistinctValuesClientId ==
								notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.fromEntiteLinkedDistinctValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.toEntiteLinkedValuesLoading &&
							$scope.toEntiteLinkedValuesClientId == notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.toEntiteLinkedValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						if (
							$scope.toEntiteLinkedDistinctValuesLoading &&
							$scope.toEntiteLinkedDistinctValuesClientId ==
								notif.data.client_id
						) {
							if (
								notif.data.status == 'CANCELLED' ||
								notif.data.status == 'ERROR'
							) {
								$scope.toEntiteLinkedDistinctValuesLoading = false;
								return;
							}
							concernedByJob = true;
						}
						let cara;
						let type;

						if (!$scope.linkStatData) {
							return;
						}

						for (let c in $scope.linkStatData.linkData.from) {
							if (
								notif.data.client_id ==
								$scope.linkStatData.linkData.from[c].clientId
							) {
								if (
									notif.data.status == 'CANCELLED' ||
									notif.data.status == 'ERROR'
								) {
									$scope.linkStatData.linkData.from[c].statLoading = false;
									return;
								}
								cara = $scope.linkStatData.linkData.from[c];
								type = 'From';
								concernedByJob = true;
								break;
							}
						}
						for (let c in $scope.linkStatData.linkData.to) {
							if (
								notif.data.client_id ==
								$scope.linkStatData.linkData.to[c].clientId
							) {
								if (
									notif.data.status == 'CANCELLED' ||
									notif.data.status == 'ERROR'
								) {
									$scope.linkStatData.linkData.to[c].statLoading = false;
									return;
								}
								cara = $scope.linkStatData.linkData.to[c];
								type = 'To';
								concernedByJob = true;
								break;
							}
						}

						if (!concernedByJob) {
							return;
						}

						if (
							notif.data.status == 'SUCCESS' ||
							(notif.data.status == 'CACHED' && notif.data.cached_after_waiting)
						) {
							$rootScope.disableAjaxLoading = true;
							TypeEntiteService.getEntiteTypeStatResultByJobKey(
								notif.data.job_id
							).then(function (response) {
								$rootScope.disableAjaxLoading = false;
								if (response != undefined && response.data) {
									if (cara) {
										delete cara.clientId;
										cara.statLoading = false;
										getEntiteCaracValuesStat(cara, type, response.data);
									}
									if (
										$scope.leftTotalCountLoading &&
										$scope.leftTotalCountClientId == notif.data.client_id
									) {
										$scope.leftTotalCountLoading = false;
										getEntiteTotalCount('From', response.data);
									}
									if (
										$scope.rightTotalCountLoading &&
										$scope.rightTotalCountClientId == notif.data.client_id
									) {
										$scope.rightTotalCountLoading = false;
										getEntiteTotalCount('To', response.data);
									}

									if (
										$scope.fromEntiteDistinctValuesLoading &&
										$scope.fromEntiteDistinctValuesClientId ==
											notif.data.client_id
									) {
										$scope.fromEntiteDistinctValuesLoading = false;
										delete $scope.fromEntiteDistinctValuesClientId;
										getEntiteDistinctValuesCount('From', response.data);
									}
									if (
										$scope.toEntiteDistinctValuesLoading &&
										$scope.toEntiteDistinctValuesClientId ==
											notif.data.client_id
									) {
										$scope.toEntiteDistinctValuesLoading = false;
										delete $scope.toEntiteDistinctValuesClientId;
										getEntiteDistinctValuesCount('To', response.data);
									}
									if (
										$scope.fromEntiteLinkedValuesLoading &&
										$scope.fromEntiteLinkedValuesClientId ==
											notif.data.client_id
									) {
										$scope.fromEntiteLinkedValuesLoading = false;
										delete $scope.fromEntiteLinkedValuesClientId;
										getEntiteLinkedValuesCount('From', response.data);
									}
									if (
										$scope.toEntiteLinkedValuesLoading &&
										$scope.toEntiteLinkedValuesClientId == notif.data.client_id
									) {
										$scope.toEntiteLinkedValuesLoading = false;
										delete $scope.toEntiteLinkedValuesClientId;
										getEntiteLinkedValuesCount('To', response.data);
									}
									if (
										$scope.fromEntiteLinkedDistinctValuesLoading &&
										$scope.fromEntiteLinkedDistinctValuesClientId ==
											notif.data.client_id
									) {
										$scope.fromEntiteLinkedDistinctValuesLoading = false;
										delete $scope.fromEntiteLinkedDistinctValuesClientId;
										getEntiteLinkedDistinctValuesCount('From', response.data);
									}
									if (
										$scope.toEntiteLinkedDistinctValuesLoading &&
										$scope.toEntiteLinkedDistinctValuesClientId ==
											notif.data.client_id
									) {
										$scope.toEntiteLinkedDistinctValuesLoading = false;
										delete $scope.toEntiteLinkedDistinctValuesClientId;
										getEntiteLinkedDistinctValuesCount('To', response.data);
									}
								}
							});
						}
					};

					$scope.$on('job_private', function (event, message) {
						handleSocketMessageJobRunning(message);
					});

					$scope.jobError = {};
					let catchErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.jobError.errorMessage = translateErrorMessage;
						}
						$scope.jobError.error = true;
						$rootScope.disableAjaxLoading = false;
						initStatErrors();
					};

					let execEntiteTotalCount = function (type) {
						$rootScope.disableAjaxLoading = true;
						let clientId = generateClientId($rootScope.account.login);
						let etId;
						let servicePromise;
						if (type == 'From') {
							$scope.leftTotalCountClientId = clientId;
							$scope.leftTotalCountLoading = true;
							if ($scope.vm.data.fromEntite) {
								etId = $scope.vm.data.sourceId;
								servicePromise = TypeEntiteService.getEntiteTypeCountAll(
									etId,
									clientId
								);
							} else {
								servicePromise = GenericService.getCountAll(
									$scope.vm.data.sourceGrammar,
									clientId
								);
							}
						} else {
							etId = $scope.vm.data.targetId;
							$scope.rightTotalCountLoading = true;
							$scope.rightTotalCountClientId = clientId;
							servicePromise = TypeEntiteService.getEntiteTypeCountAll(
								etId,
								clientId
							);
						}

						servicePromise
							.then(function (response) {
								$rootScope.disableAjaxLoading = false;
								if (!response.data.job_id && response.data.exec_data_result) {
									if (clientId == $scope.leftTotalCountClientId) {
										$scope.leftTotalCountLoading = false;
										delete $scope.leftTotalCountClientId;
										getEntiteTotalCount('From', response.data.exec_data_result);
									} else {
										$scope.rightTotalCountLoading = false;
										delete $scope.rightTotalCountClientId;
										getEntiteTotalCount('To', response.data.exec_data_result);
									}
								} else if (response.data.job_id) {
									if (clientId == $scope.leftTotalCountClientId) {
										$scope.leftTotalCountJobId = response.data.job_id;
									} else {
										$scope.rightTotalCountJobId = response.data.job_id;
									}
								}
							})
							.catch(catchErrors);
					};

					$scope.execValuesStat = function (type, carac) {
						$rootScope.disableAjaxLoading = true;
						let clientId = (carac.clientId = generateClientId(
							$rootScope.account.login
						));
						carac.statLoading = true;
						carac.statError = false;
						let servicePromise;
						let cara = { cara_code: carac.code };
						if (!carac.id) {
							cara.formula = carac.formula;
						}
						if (type == 'From') {
							if ($scope.vm.data.fromEntite) {
								servicePromise = TypeEntiteService.getEntiteTypeValuesStat(
									cara,
									$scope.vm.data.sourceId,
									clientId
								);
							} else {
								servicePromise = GenericService.getValuesStat(
									$scope.vm.data.sourceGrammar,
									carac.code,
									clientId
								);
							}
						} else {
							servicePromise = TypeEntiteService.getEntiteTypeValuesStat(
								cara,
								$scope.vm.data.targetId,
								clientId
							);
						}
						servicePromise
							.then(function (response) {
								$rootScope.disableAjaxLoading = false;
								if (!response.data.job_id && response.data.exec_data_result) {
									for (let c in $scope.linkStatData.linkData.from) {
										if (
											clientId == $scope.linkStatData.linkData.from[c].clientId
										) {
											delete $scope.linkStatData.linkData.from[c].clientId;
											$scope.linkStatData.linkData.from[c].statLoading = false;
											getEntiteCaracValuesStat(
												$scope.linkStatData.linkData.from[c],
												'From',
												response.data.exec_data_result
											);
											return;
										}
									}
									for (let c in $scope.linkStatData.linkData.to) {
										if (
											clientId == $scope.linkStatData.linkData.to[c].clientId
										) {
											delete $scope.linkStatData.linkData.to[c].clientId;
											$scope.linkStatData.linkData.to[c].statLoading = false;
											getEntiteCaracValuesStat(
												$scope.linkStatData.linkData.to[c],
												'To',
												response.data.exec_data_result
											);
											return;
										}
									}
								} else if (response.data.job_id) {
									for (let c in $scope.linkStatData.linkData.from) {
										if (
											clientId == $scope.linkStatData.linkData.from[c].clientId
										) {
											$scope.linkStatData.linkData.from[c].statJobId =
												response.data.job_id;
											return;
										}
									}
									for (let c in $scope.linkStatData.linkData.to) {
										if (
											clientId == $scope.linkStatData.linkData.to[c].clientId
										) {
											$scope.linkStatData.linkData.to[c].statJobId =
												response.data.job_id;
											return;
										}
									}
								}
							})
							.catch(catchErrors);
					};

					let getMappingRequestFromEntite = function (type) {
						let entiteMapping = {
							source_code: $scope.vm.data.sourceCode,
							mappings: [],
						};

						entiteMapping.target_code = $scope.vm.data.targetCode;

						for (let c in $scope.linkStatData.linkData.from) {
							if (
								$scope.linkStatData.linkData.from[c] &&
								$scope.linkStatData.linkData.to[c]
							) {
								let m = {
									source_code: $scope.linkStatData.linkData.from[c].code,
									target_code: $scope.linkStatData.linkData.to[c].code,
								};

								if (
									$scope.linkStatData.linkData.from[c].formula instanceof Object
								) {
									m.source_formule =
										$scope.linkStatData.linkData.from[c].formula;
								}
								if (
									$scope.linkStatData.linkData.to[c].formula instanceof Object
								) {
									m.target_formule = $scope.linkStatData.linkData.to[c].formula;
								}
								m.operator =
									$scope.linkStatData.caracLinkMappingList[c].operator;
								entiteMapping.mappings.push(m);
							}
						}

						if (type == 'To') {
							inverseSourceTarget(entiteMapping);
						}
						return entiteMapping;
					};

					let getMappingRequestFromDb = function (type) {
						let entiteMapping = {
							source: $scope.vm.data.sourceGrammar,
							target_id: $scope.vm.data.targetId,
							mappings: $scope.vm.data.linkMappings,
						};
						entiteMapping.inverted = type == 'To';
						return entiteMapping;
					};

					let getMappingRequest = function (type) {
						if ($scope.vm.data.fromEntite) {
							return getMappingRequestFromEntite(type);
						} else {
							return getMappingRequestFromDb(type);
						}
					};

					let inverseSourceTarget = function (entiteMapping) {
						let tmp = entiteMapping.target_code;
						entiteMapping.target_code = entiteMapping.source_code;
						entiteMapping.source_code = tmp;

						for (let m in entiteMapping.mappings) {
							tmp = entiteMapping.mappings[m].source_code;
							entiteMapping.mappings[m].source_code =
								entiteMapping.mappings[m].target_code;
							entiteMapping.mappings[m].target_code = tmp;

							tmp = entiteMapping.mappings[m].source_formule;
							entiteMapping.mappings[m].source_formule =
								entiteMapping.mappings[m].target_formule;
							entiteMapping.mappings[m].target_formule = tmp;
						}
					};

					let getEntiteTotalCount = function (type, execDataResult) {
						$scope.jobDetails.update({
							is_cached: execDataResult.is_cached,
							pushDate: execDataResult.push_date,
							endExecutionDate: execDataResult.exec_end_date,
							duration: execDataResult.time_exec,
						});
						let data = execDataResult.data;
						if (type == 'From') {
							$scope.leftTotalNumber = data[0][0];
						} else {
							$scope.rightTotalNumber = data[0][0];
						}
					};

					let getEntiteCaracValuesStat = function (
						carac,
						type,
						execDataResult
					) {
						$scope.jobDetails.update({
							is_cached: execDataResult.is_cached,
							pushDate: execDataResult.push_date,
							endExecutionDate: execDataResult.exec_end_date,
							duration: execDataResult.time_exec,
						});
						let data = execDataResult.data;
						carac.nbrNotNullValues = data && data[0] ? data[0][0] : 0;
						carac.nbrDistinctValues = data && data[0] ? data[0][1] : 0;
						let total =
							type == 'From' ? $scope.leftTotalNumber : $scope.rightTotalNumber;
						carac.nbrNullValues = total - carac.nbrNotNullValues;
						carac.prcNotNullValues = (carac.nbrNotNullValues / total) * 100;
						carac.prcNotNullValues = roundDecimal(carac.prcNotNullValues, 5);
						carac.prcNullValues = (carac.nbrNullValues / total) * 100;
						carac.prcNullValues = roundDecimal(carac.prcNullValues, 5);
					};

					let getEntiteLinkedValuesCount = function (type, execDataResult) {
						$scope.jobDetails.update({
							is_cached: execDataResult.is_cached,
							pushDate: execDataResult.push_date,
							endExecutionDate: execDataResult.exec_end_date,
							duration: execDataResult.time_exec,
						});
						let data = execDataResult.data;
						if (type == 'From') {
							$scope.leftLinkedValuesNbr = data[0][0];
							$scope.leftLinkedValuesPercent =
								($scope.leftLinkedValuesNbr / $scope.leftTotalNumber) * 100;
							$scope.leftLinkedValuesPercent = roundDecimal(
								$scope.leftLinkedValuesPercent,
								2
							);
							$scope.leftNotLinkedValuesNbr =
								$scope.leftTotalNumber - $scope.leftLinkedValuesNbr;
							$scope.leftNotLinkedValuesPercent =
								100 - $scope.leftLinkedValuesPercent;
							$scope.leftNotLinkedValuesPercent = roundDecimal(
								$scope.leftNotLinkedValuesPercent,
								2
							);
						} else {
							$scope.rightLinkedValuesNbr = data[0][0];
							$scope.rightLinkedValuesPercent =
								($scope.rightLinkedValuesNbr / $scope.rightTotalNumber) * 100;
							$scope.rightLinkedValuesPercent = roundDecimal(
								$scope.rightLinkedValuesPercent,
								2
							);
							$scope.rightNotLinkedValuesNbr =
								$scope.rightTotalNumber - $scope.rightLinkedValuesNbr;
							$scope.rightNotLinkedValuesPercent =
								100 - $scope.rightLinkedValuesPercent;
							$scope.rightNotLinkedValuesPercent = roundDecimal(
								$scope.rightNotLinkedValuesPercent,
								2
							);
						}
					};

					let getEntiteDistinctValuesCount = function (type, execDataResult) {
						$scope.jobDetails.update({
							is_cached: execDataResult.is_cached,
							pushDate: execDataResult.push_date,
							endExecutionDate: execDataResult.exec_end_date,
							duration: execDataResult.time_exec,
						});
						let data = execDataResult.data;
						if (type == 'From') {
							$scope.leftEntiteTotalDistinctValuesCount = data[0][0];
						} else {
							$scope.rightEntiteTotalDistinctValuesCount = data[0][0];
						}
					};

					let getEntiteLinkedDistinctValuesCount = function (
						type,
						execDataResult
					) {
						$scope.jobDetails.update({
							is_cached: execDataResult.is_cached,
							pushDate: execDataResult.push_date,
							endExecutionDate: execDataResult.exec_end_date,
							duration: execDataResult.time_exec,
						});
						let data = execDataResult.data;
						if (type == 'From') {
							$scope.leftLinkedDistinctValuesNbr = data[0][0];
							$scope.leftLinkedDistinctValuesPercent =
								($scope.leftLinkedDistinctValuesNbr /
									$scope.leftEntiteTotalDistinctValuesCount) *
								100;
							$scope.leftLinkedDistinctValuesPercent = roundDecimal(
								$scope.leftLinkedDistinctValuesPercent,
								2
							);
							$scope.leftNotLinkedDistinctValuesNbr =
								$scope.leftEntiteTotalDistinctValuesCount -
								$scope.leftLinkedDistinctValuesNbr;
							$scope.leftNotLinkedDistinctValuesPercent =
								100 - $scope.leftLinkedDistinctValuesPercent;
							$scope.leftNotLinkedDistinctValuesPercent = roundDecimal(
								$scope.leftNotLinkedDistinctValuesPercent,
								2
							);
						} else {
							$scope.rightLinkedDistinctValuesNbr = data[0][0];
							$scope.rightLinkedDistinctValuesPercent =
								($scope.rightLinkedDistinctValuesNbr /
									$scope.rightEntiteTotalDistinctValuesCount) *
								100;
							$scope.rightLinkedDistinctValuesPercent = roundDecimal(
								$scope.rightLinkedDistinctValuesPercent,
								2
							);
							$scope.rightNotLinkedDistinctValuesNbr =
								$scope.rightEntiteTotalDistinctValuesCount -
								$scope.rightLinkedDistinctValuesNbr;
							$scope.rightNotLinkedDistinctValuesPercent =
								100 - $scope.rightLinkedDistinctValuesPercent;
							$scope.rightNotLinkedDistinctValuesPercent = roundDecimal(
								$scope.rightNotLinkedDistinctValuesPercent,
								2
							);
						}
					};

					$scope.execEntiteLinkedValuesCount = function (type, distinct) {
						let mappingRequest = getMappingRequest(type);
						$rootScope.disableAjaxLoading = true;
						let clientId = generateClientId($rootScope.account.login);
						if (type == 'From') {
							if (!distinct) {
								$scope.fromEntiteLinkedValuesClientId = clientId;
								$scope.fromEntiteLinkedValuesLoading = true;
								$scope.fromEntiteLinkedValuesError = false;
							} else {
								$scope.fromEntiteLinkedDistinctValuesClientId = clientId;
								$scope.fromEntiteLinkedDistinctValuesLoading = true;
								$scope.fromEntiteLinkedDistinctValuesError = false;
							}
						} else {
							if (!distinct) {
								$scope.toEntiteLinkedValuesClientId = clientId;
								$scope.toEntiteLinkedValuesLoading = true;
								$scope.toEntiteLinkedValuesError = false;
							} else {
								$scope.toEntiteLinkedDistinctValuesClientId = clientId;
								$scope.toEntiteLinkedDistinctValuesLoading = true;
								$scope.toEntiteLinkedDistinctValuesError = false;
							}
						}

						let servicePromise;
						if ($scope.vm.data.fromEntite) {
							servicePromise = TypeEntiteService.getEntiteTypeCountLinked(
								mappingRequest,
								distinct,
								clientId
							);
						} else {
							servicePromise = GenericService.getCountLinked(
								mappingRequest,
								distinct,
								clientId
							);
						}

						servicePromise
							.then(function (response) {
								$rootScope.disableAjaxLoading = false;
								if (!response.data.job_id && response.data.exec_data_result) {
									if ($scope.fromEntiteLinkedValuesClientId == clientId) {
										delete $scope.fromEntiteLinkedValuesClientId;
										$scope.fromEntiteLinkedValuesLoading = false;
										getEntiteLinkedValuesCount(
											'From',
											response.data.exec_data_result
										);
									} else if ($scope.toEntiteLinkedValuesClientId == clientId) {
										delete $scope.toEntiteLinkedValuesClientId;
										$scope.toEntiteLinkedValuesLoading = false;
										getEntiteLinkedValuesCount(
											'To',
											response.data.exec_data_result
										);
									} else if (
										$scope.fromEntiteLinkedDistinctValuesClientId == clientId
									) {
										delete $scope.fromEntiteLinkedDistinctValuesClientId;
										$scope.fromEntiteLinkedDistinctValuesLoading = false;
										getEntiteLinkedDistinctValuesCount(
											'From',
											response.data.exec_data_result
										);
									} else if (
										$scope.toEntiteLinkedDistinctValuesClientId == clientId
									) {
										delete $scope.toEntiteLinkedDistinctValuesClientId;
										$scope.toEntiteLinkedDistinctValuesLoading = false;
										getEntiteLinkedDistinctValuesCount(
											'To',
											response.data.exec_data_result
										);
									}
								} else if (response.data.job_id) {
									if ($scope.fromEntiteLinkedValuesClientId == clientId) {
										$scope.fromEntiteLinkedValuesJobId = response.data.job_id;
									} else if (
										$scope.fromEntiteLinkedDistinctValuesClientId == clientId
									) {
										$scope.fromEntiteLinkedDistinctValuesJobId =
											response.data.job_id;
									} else if ($scope.toEntiteLinkedValuesClientId == clientId) {
										$scope.toEntiteLinkedValuesJobId = response.data.job_id;
									} else if (
										$scope.toEntiteLinkedDistinctValuesClientId == clientId
									) {
										$scope.toEntiteLinkedDistinctValuesJobId =
											response.data.job_id;
									}
								}
							})
							.catch(catchErrors);
					};

					let execEntiteDistinctValuesCount = function (type) {
						let etId;
						let caracs;
						$rootScope.disableAjaxLoading = true;
						let clientId = generateClientId($rootScope.account.login);
						let servicePromise;
						if (type == 'From') {
							if ($scope.vm.data.fromEntite) {
								etId = $scope.vm.data.sourceId;
								caracs = $scope.linkStatData.linkData.from;
							} else {
								let columns = [];
								for (let m in $scope.linkStatData.linkMappings) {
									columns.push(
										$scope.linkStatData.linkMappings[m].left_column_uuid
									);
								}
								servicePromise = GenericService.getValuesCountDistinct(
									{ grammar: $scope.vm.data.sourceGrammar, columns: columns },
									clientId
								);
							}

							$scope.fromEntiteDistinctValuesClientId = clientId;
							$scope.fromEntiteDistinctValuesLoading = true;
						} else {
							$scope.toEntiteDistinctValuesClientId = clientId;
							$scope.toEntiteDistinctValuesLoading = true;
							etId = $scope.vm.data.targetId;
							caracs = $scope.linkStatData.linkData.to;
						}

						if (caracs) {
							let statRequestParam = [];
							for (let c in $scope.availableLinks) {
								if (caracs[c] && caracs[c].formula instanceof Object) {
									statRequestParam.push({ formula: caracs[c].formula });
								} else if (caracs[c]) {
									statRequestParam.push({ cara_code: caracs[c].code });
								}
							}
							servicePromise =
								TypeEntiteService.getEntiteTypeValuesCountDistinct(
									statRequestParam,
									etId,
									clientId
								);
						} else {
							servicePromise = GenericService.getValuesCountDistinct(
								{ grammar: $scope.vm.data.sourceGrammar, columns: [] },
								clientId
							);
						}

						servicePromise
							.then(function (response) {
								$rootScope.disableAjaxLoading = false;
								if (!response.data.job_id && response.data.exec_data_result) {
									if ($scope.fromEntiteDistinctValuesClientId == clientId) {
										delete $scope.fromEntiteDistinctValuesClientId;
										$scope.fromEntiteDistinctValuesLoading = false;
										getEntiteDistinctValuesCount(
											'From',
											response.data.exec_data_result
										);
									} else if (
										$scope.toEntiteDistinctValuesClientId == clientId
									) {
										delete $scope.toEntiteDistinctValuesClientId;
										$scope.toEntiteDistinctValuesLoading = false;
										getEntiteDistinctValuesCount(
											'To',
											response.data.exec_data_result
										);
									}
								} else if (response.data.job_id) {
									if ($scope.fromEntiteDistinctValuesClientId == clientId) {
										$scope.fromEntiteDistinctValuesJobId = response.data.job_id;
									} else if (
										$scope.toEntiteDistinctValuesClientId == clientId
									) {
										$scope.toEntiteDistinctValuesJobId = response.data.job_id;
									}
								}
							})
							.catch(catchErrors);
					};

					$scope.execAllStats = function () {
						$scope.executeStat();
						for (let c in $scope.linkStatData.linkData.from) {
							$scope.execValuesStat(
								'From',
								$scope.linkStatData.linkData.from[c]
							);
						}
						for (let c in $scope.linkStatData.linkData.to) {
							$scope.execValuesStat('To', $scope.linkStatData.linkData.to[c]);
						}
						$scope.execEntiteLinkedValuesCount('From', false);
						$scope.execEntiteLinkedValuesCount('From', true);
						$scope.execEntiteLinkedValuesCount('To', false);
						$scope.execEntiteLinkedValuesCount('To', true);
					};

					let initStatData = function () {
						delete $scope.leftLinkedValuesNbr;
						delete $scope.leftNotLinkedValuesNbr;

						delete $scope.leftLinkedValuesPercent;
						delete $scope.leftNotLinkedValuesPercent;

						delete $scope.leftLinkedDistinctValuesNbr;
						delete $scope.leftNotLinkedDistinctValuesNbr;

						delete $scope.leftLinkedDistinctValuesPercent;
						delete $scope.leftNotLinkedDistinctValuesPercent;

						delete $scope.rightLinkedValuesNbr;
						delete $scope.rightNotLinkedValuesNbr;

						delete $scope.rightLinkedValuesPercent;
						delete $scope.rightNotLinkedValuesPercent;

						delete $scope.rightLinkedDistinctValuesNbr;
						delete $scope.rightNotLinkedDistinctValuesNbr;

						delete $scope.rightLinkedDistinctValuesPercent;
						delete $scope.rightNotLinkedDistinctValuesPercent;

						$scope.leftTotalCountLoading = false;
						$scope.leftTotalCountError = false;

						$scope.rightTotalCountLoading = false;
						$scope.rightTotalCountError = false;

						$scope.fromEntiteDistinctValuesLoading = false;
						$scope.fromEntiteDistinctValuesError = false;

						$scope.toEntiteDistinctValuesLoading = false;
						$scope.toEntiteDistinctValuesError = false;

						$scope.fromEntiteLinkedValuesLoading = false;
						$scope.fromEntiteLinkedValuesError = false;

						$scope.fromEntiteLinkedDistinctValuesLoading = false;
						$scope.fromEntiteLinkedDistinctValuesError = false;

						$scope.toEntiteLinkedValuesLoading = false;
						$scope.toEntiteLinkedValuesError = false;

						$scope.toEntiteLinkedDistinctValuesLoading = false;
						$scope.toEntiteLinkedDistinctValuesError = false;

						for (let c in $scope.linkStatData.linkData.from) {
							$scope.linkStatData.linkData.from[c].statLoading = false;
							$scope.linkStatData.linkData.from[c].statError = false;
							delete $scope.linkStatData.linkData.from[c].nbrNotNullValues;
							delete $scope.linkStatData.linkData.from[c].prcNotNullValues;
							delete $scope.linkStatData.linkData.from[c].nbrNullValues;
							delete $scope.linkStatData.linkData.from[c].prcNullValues;
							delete $scope.linkStatData.linkData.from[c].nbrDistinctValues;
						}
						for (let c in $scope.linkStatData.linkData.to) {
							$scope.linkStatData.linkData.to[c].statLoading = false;
							$scope.linkStatData.linkData.to[c].statError = false;
							delete $scope.linkStatData.linkData.to[c].nbrNotNullValues;
							delete $scope.linkStatData.linkData.to[c].prcNotNullValues;
							delete $scope.linkStatData.linkData.to[c].nbrNullValues;
							delete $scope.linkStatData.linkData.to[c].prcNullValues;
							delete $scope.linkStatData.linkData.to[c].nbrDistinctValues;
						}

						$scope.showStats = true;
					};

					let initStatErrors = function () {
						if ($scope.jobError && $scope.jobError.error) {
							if ($scope.leftTotalCountLoading) {
								$scope.leftTotalCountLoading = false;
								$scope.leftTotalCountError = true;
							}
							if ($scope.rightTotalCountLoading) {
								$scope.rightTotalCountLoading = false;
								$scope.rightTotalCountError = true;
							}
							if ($scope.fromEntiteDistinctValuesLoading) {
								$scope.fromEntiteDistinctValuesLoading = false;
								$scope.fromEntiteDistinctValuesError = true;
							}
							if ($scope.toEntiteDistinctValuesLoading) {
								$scope.toEntiteDistinctValuesLoading = false;
								$scope.toEntiteDistinctValuesError = true;
							}
							if ($scope.fromEntiteLinkedValuesLoading) {
								$scope.fromEntiteLinkedValuesLoading = false;
								$scope.fromEntiteLinkedValuesError = true;
							}
							if ($scope.fromEntiteLinkedDistinctValuesLoading) {
								$scope.fromEntiteLinkedDistinctValuesLoading = false;
								$scope.fromEntiteLinkedDistinctValuesError = true;
							}

							if ($scope.toEntiteLinkedValuesLoading) {
								$scope.toEntiteLinkedValuesLoading = false;
								$scope.toEntiteLinkedValuesError = true;
							}
							if ($scope.toEntiteLinkedDistinctValuesLoading) {
								$scope.toEntiteLinkedDistinctValuesLoading = false;
								$scope.toEntiteLinkedDistinctValuesError = true;
							}
							if ($scope.caracComplexMap && $scope.linkStatData.linkData.from) {
								for (let c in $scope.linkStatData.linkData.from) {
									if ($scope.linkStatData.linkData.from[c].statLoading) {
										$scope.linkStatData.linkData.from[c].statLoading = false;
										$scope.linkStatData.linkData.from[c].statError = true;
									}
								}
							}
							if ($scope.caracComplexMap && $scope.linkStatData.linkData.to) {
								for (let c in $scope.linkStatData.linkData.to) {
									if ($scope.linkStatData.linkData.to[c].statLoading) {
										$scope.linkStatData.linkData.to[c].statLoading = false;
										$scope.linkStatData.linkData.to[c].statError = true;
									}
								}
							}
						}
					};

					$scope.closeStatTable = function () {
						$scope.showStats = true;
					};

					$scope.stopStat = function (jobId) {
						if (jobId) {
							SparkJobsServices.killJob(jobId).then().catch(catchErrors);
						}
					};

					$scope.executeStat = function (noCache) {
						initStatData();
						$scope.statExecuted = true;
						$scope.showStats = true;
						$scope.linkAnalyseIsLoading = true;
						execEntiteTotalCount('From');
						execEntiteTotalCount('To');
						execEntiteDistinctValuesCount('From');
						execEntiteDistinctValuesCount('To');
					};

					$scope.showMappingData = function (type, distinct, linked) {
						delete $scope.gridOptions;
						$scope.dataGridTitle =
							type == 'From'
								? $scope.vm.data.sourceLib
								: $scope.vm.data.targetLib;
						$scope.dataGridTitle =
							$scope.dataGridTitle + ' ' + translateDataGridTitle.towards + ' ';
						$scope.dataGridTitle =
							$scope.dataGridTitle +
							(type == 'To'
								? $scope.vm.data.sourceLib
								: $scope.vm.data.targetLib);
						$scope.dataGridTitle =
							$scope.dataGridTitle +
							'  :  ' +
							(distinct
								? ' ' + translateDataGridTitle.value + ' '
								: ' ' + translateDataGridTitle.lignes + ' ');
						$scope.dataGridTitle =
							$scope.dataGridTitle +
							(linked
								? ' ' + translateDataGridTitle.linked + ' '
								: ' ' + translateDataGridTitle.noLinked + ' ');

						let mappingRequest = getMappingRequest(type);

						if (type == 'From') {
							if (distinct && linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.leftLinkedDistinctValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.leftLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (distinct && !linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.leftNotLinkedDistinctValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.leftNotLinkedDistinctValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (!distinct && linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.leftLinkedValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.leftLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (!distinct && !linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.leftNotLinkedValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.leftNotLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							}
						} else {
							if (distinct && linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.rightLinkedDistinctValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.rightLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (distinct && !linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.rightNotLinkedDistinctValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.rightNotLinkedDistinctValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (!distinct && linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.rightLinkedValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.rightLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							} else if (!distinct && !linked) {
								$scope.dataGridTitle =
									$scope.dataGridTitle +
									' ' +
									$scope.rightNotLinkedValuesPercent +
									'% ' +
									translateDataGridTitle.is +
									' ( ' +
									$scope.rightNotLinkedValuesNbr +
									' ' +
									translateDataGridTitle.lignes +
									')';
							}
						}

						let servicePromise;
						if ($scope.vm.data.fromEntite) {
							if (!mappingRequest.target_code) {
								toaster.pop('error', toasterPopError, toasterPopEntityError);
								return;
							}
							servicePromise = TypeEntiteService.testMappingsData(
								mappingRequest,
								distinct,
								linked
							);
						} else {
							servicePromise = GenericService.getLinkedData(
								mappingRequest,
								distinct,
								linked
							);
						}

						servicePromise
							.then(function (response) {
								$scope.showStats = false;
								initGridData(type, response.data);
							})
							.catch(function (error) {
								if (
									error &&
									(error.code == '37' ||
										error.data.code == '38' ||
										error.data.code == '39')
								) {
									toaster.pop('error', toasterPopError, toasterPopContextError);
								} else if (error && error.message && error.message.length > 0) {
									toaster.pop('error', toasterPopError, error.message);
								} else {
									toaster.pop('error', toasterPopError, toasterPopDataError);
								}
							});
					};

					let initGridData = function (type, jobResult) {
						let data = jobResult.exec_data_result.data;
						$scope.gridOptions = getDefaultDxGridConfig(
							PAGINATIONS_SIZES,
							PAGINATIONS_SIZE
						);
						$scope.gridOptions.columns = new Array();
						//Create Grid Columns
						let caracs;
						let rightCaracs;
						if (type == 'From') {
							caracs = $scope.linkStatData.linkData.from;
							rightCaracs = $scope.linkStatData.linkData.to;
						} else {
							caracs = $scope.linkStatData.linkData.to;
							rightCaracs = $scope.linkStatData.linkData.from;
						}

						let colIndex = 0;
						for (let c in $scope.availableLinks) {
							let columnName = '';
							// Let COL
							if (caracs[c] && caracs[c].formula instanceof Object) {
								columnName = caracs[c].column_alias;
							} else if (caracs[c]) {
								columnName = caracs[c].lib;
							}
							$scope.gridOptions.columns.push({
								dataField: 'COL_' + colIndex,
								caption: columnName,
								cellTemplate: 'cellTemplate',
								allowFiltering: false,
							});
							colIndex = colIndex + 1;
							// Right COL
							if (rightCaracs[c] && rightCaracs[c].formula instanceof Object) {
								columnName = rightCaracs[c].column_alias;
							} else if (rightCaracs[c]) {
								columnName = rightCaracs[c].lib;
							}
							$scope.gridOptions.columns.push({
								dataField: 'COL_' + colIndex,
								cellTemplate: 'cellTemplate',
								caption: columnName,
								allowFiltering: false,
							});
							colIndex = colIndex + 1;
						}
						$scope.gridOptions.dataSource = [];

						for (let d in data) {
							let item = {};
							for (let c in $scope.gridOptions.columns) {
								item[$scope.gridOptions.columns[c].dataField] = data[d][c];
							}
							$scope.gridOptions.dataSource.push(item);
						}
					};

					$scope.close = function () {
						$(vm.element).modal('hide');
						delete $scope.jobDetails;
						$scope.vm.widgetData.showLinkStat = false;
					};

					$scope.init = function () {
						$scope.linkStatData = $scope.vm.data;
						$scope.availableLinks = $scope.vm.data.availableLinks;
						$scope.executeStat();
					};
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=', data: '=' },
				controller,
				'./src/components/directives/linkStat/linkStat.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.widgetData.showLinkStat = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showLinkStat;
						},
						function () {
							scope.jobDetails = { rerunMethod: scope.execWithoutCache };
							scope.jobDetailsId = generateUuid('_');
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
