(function () {
	'use strict';

	angular.module('dcApp').directive('imagemediaview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'MediaService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'API_BASE_URL_BACKEND',
				'$rootScope',
				'$timeout',
				'$q',
				'UrlService',
				function (
					$scope,
					$state,
					MediaService,
					hdSourceService,
					$window,
					PermissionService,
					API_BASE_URL_BACKEND,
					$rootScope,
					$timeout,
					$q,
					UrlService
				) {
					var vm = this;
					var accessToken =
						window._keycloak && window._keycloak.token
							? window._keycloak.token
							: '';
					var withAuth =
						API_BASE_URL_BACKEND +
						$rootScope.getUrl('MEDIAS_DOWNLOAD_FILE') +
						'?accessToken=' +
						accessToken;

					vm.init = function (ignoreCache) {
						initializeMedia(
							'image',
							$scope,
							MediaService,
							hdSourceService,
							checkConfig,
							loadMedia,
							ignoreCache,
							PermissionService
						);
					};

					// FIXME: replace with service factory
					initializeMediaDirectiveScope($scope, $state, vm, $window, 'image');

					var checkConfig = function (config) {
						return true;
					};

					$scope.zoom = function () {
						$scope.galleryOptionsZoom = getGalleryOptions(
							angular.copy($scope.images),
							700
						);
						$scope.visibleZoomPopup = true;
					};

					$scope.zoomPopupOptions = {
						width: 1200,
						height: 700,
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'visibleZoomPopup',
						},
						title: '',
					};
					var getGalleryOptions = function (images, height) {
						return {
							dataSource: images,
							height: height,
							loop: true,
							showIndicator: true,
							showNavButtons: true,
							itemTemplate: function (item, index) {
								var result = $('<div>').attr('id', `container-${index}`);
								const imgId = item.mediaId ?? item.mediaItemId;
								$('<img>').attr('id', `media-${imgId}`).appendTo(result);
								return result;
							},
							onSelectionChanged: function (e) {
								const item = e.addedItems[0];
								$scope.currentObject = item;
								computeMediaUrl(item).then(function (val) {
									const imgId = item.mediaId ?? item.mediaItemId;
									const newImg = $('<img>')
										.attr('id', `media-${imgId}`)
										.attr('src', val);
									$(`img#media-${imgId}`).replaceWith(newImg);
								});
							},
						};
					};

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					function computeMediaUrl(config) {
						if (config.mode === 'ExternalLink') {
							return $timeout(() => $q.resolve(config.link), 64);
						}
						if (config.mode === 'Publication') {
							const url = UrlService.generateUrl(
								'DASHBOARDS_PUBLICATION_MEDIA',
								{},
								{
									mediaItemId: config.mediaItemId,
									link: config.link,
								}
							);
							return $timeout(() => $q.resolve(url), 64);
						}
						if (config.mode === 'Visualisation') {
							const deferred = $q.defer();
							MediaService.getDownLoadToken(
								config.mediaItemId,
								config.mediasId
							).then(function (url) {
								deferred.resolve(url);
							});
							return deferred.promise;
						}
					}

					var loadMedia = function () {
						if (!Array.isArray($scope.config.global_config.items)) {
							$scope.images = [];
						} else {
							$scope.images = $scope.config.global_config.items.map(function (
								item
							) {
								initPopover();
								if (!isEmpty(item.link)) {
									return {
										link: item.link,
										mode: 'ExternalLink',
										mediaItemId: item.id,
										description: item.description,
										label: item.label,
									};
								}
								if (isEmpty(item.link) && $scope.vm.data.full_data) {
									return {
										mediaItemId: item.id,
										mode: 'Publication',
										link: $scope.vm.data.full_data.link,
										description: item.description,
										label: item.label,
									};
								}
								return {
									mode: 'Visualisation',
									mediasId: $scope.vm.data.mediaId,
									mediaItemId: item.id,
									description: item.description,
									label: item.label,
								};
							});
						}
						$timeout(function () {
							$scope.galleryOptions = getGalleryOptions(
								angular.copy($scope.images),
								450
							);
						}, 50);
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/mediaView/image/mediaView.html',
				function postLink(scope, element, attrs) {
					scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
					scope.jobDetailsId = generateUuid('_');
					scope.vm.init(false);
				}
			);
		},
	]);
})();
