(function () {
	'use strict';

	angular.module('dcApp').directive('expressIntegration', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'$window',
				'ExpressIntegration',
				'CommonServices',
				'TypeEntiteService',
				'ParametersServices',
				'MetadataService',
				'toaster',
				'DATE_PATTERN',
				'CTX_VAR_NAME',
				'API_BASE_URL_BACKEND',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'$timeout',
				'gettextCatalog',
				'AllDepotService',
				'UPDATE_EVENT',
				function (
					$scope,
					$rootScope,
					$window,
					ExpressIntegration,
					CommonServices,
					TypeEntiteService,
					ParametersServices,
					MetadataService,
					toaster,
					DATE_PATTERN,
					CTX_VAR_NAME,
					API_BASE_URL_BACKEND,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					$timeout,
					gettextCatalog,
					AllDepotService,
					UPDATE_EVENT
				) {
					// init EntiteType
					$scope.selectedLang = $rootScope.account.lang;
					$scope.selectedLangCarac = $rootScope.account.lang;
					$scope.metadataLoaded = false;
					$scope.forms = {};
					$scope.metadata = {};
					$scope.hide_preview = true;
					$scope.depots = {};
					$scope.showFileStatus = true;
					$scope.showList = {};
					$scope.getDateWithPattern = $rootScope.getDateWithPattern;
					$scope.charsets = [];
					$scope.defaultCharset = 'UTF-8';
					$scope.charsetsFetched = false;
					$scope.caracIsList = {};
					$scope.entiteTypeCaracs = [];
					$scope.selectedFileHeader = {};
					$scope.selectedEntiteTypeCarac = {};
					$scope.createRepDetails = false;
					$scope.fileUploaded = false;
					$scope.saveInProgress = false;

					$scope.helpTra = gettextCatalog.getString('Aide Intégration Rapide');
					let headerDetailsLib = gettextCatalog.getString('Détails Dépôt');
					let defaultDataType = 'StringType';
					let translateErrorMessage = gettextCatalog.getString(
						'context.unavailable'
					);

					$scope.parsingModes = ['PERMISSIVE', 'DROPMALFORMED', 'FAILFAST'];

					$scope.prepareDataSource = function () {
						// set metadata
						for (let t in $scope.metadata.tags) {
							$scope.metadata.tags[t].color =
								$scope.metadata.tags[t].color !== undefined
									? $scope.metadata.tags[t].color
									: '#dbf5d1';
						}
						$scope.dataSource = $scope.depot;
						delete $scope.dataSource.flow;
						delete $scope.dataSource.connector;
						$scope.dataSource.metadata = $scope.metadata;
					};

					$scope.setSelectedEntiteTypeCarac = function (carac, index) {
						setSelectedEntiteTypeCarac(
							carac,
							index,
							$scope.caracIsList,
							$scope.selectedFileHeader,
							$scope.mappings,
							$scope.selectedEntiteTypeCarac
						);
					};

					$scope.saveAll = function () {
						let entiteTypeCaracsCopy = angular.copy($scope.entiteTypeCaracs);
						$scope.prepareDataSource();
						let data = {};
						let caracs = [];
						let mapping = [];
						angular.forEach(
							$scope.selectedEntiteTypeCarac,
							function (carac, key) {
								if (!carac.depotDetails) {
									updateGeneratedCarac(carac);
									let caracData = carac;
									let caracCode =
										caracData.tempCode != undefined
											? caracData.tempCode
											: caracData.code;
									let headerCarac = $scope.selectedFileHeader[caracCode];
									if (
										headerCarac != undefined &&
										headerCarac != '' &&
										headerCarac != null
									) {
										let caracMapping = {};
										caracMapping.uca_code = caracCode;
										caracMapping.is_formula = false;
										caracMapping.header = {};
										caracMapping.header.column =
											$scope.selectedFileHeader[caracCode];
										caracMapping.header.data_type =
											$scope.entiteTypeFileHeaderTypeMap[caracData.oldCode];
										caracMapping.header.depot_details =
											caracMapping.header.column == headerDetailsLib;
										caracMapping.pattern =
											caracData.date_pattern != undefined &&
											caracData.entite_type.code == 'SIMPLE_TYPES_DATE_ITEM'
												? caracData.date_pattern
												: undefined;
										caracMapping.uca_code = caracData.origin_column_id;
										caracMapping.linkId = $scope.linkId;
										mapping.push(caracMapping);
										caracs.push(caracData);
									}
								}
							}
						);
						data.csv_depot = $scope.dataSource;
						data.caracs = caracs;
						data.mappings = mapping;
						let selectedFileHeaderCopy = angular.copy(
							$scope.selectedFileHeader
						);
						let entiteTypeFileHeaderCopy = angular.copy(
							$scope.entiteTypeFileHeader
						);
						$scope.saveInProgress = true;
						ExpressIntegration.saveExpressIntegration(
							data,
							$scope.createRepDetails
						)
							.then(function (response) {
								$scope.selectedFileHeader = selectedFileHeaderCopy;
								$scope.entiteTypeCaracs = entiteTypeCaracsCopy;
								$scope.entiteTypeFileHeader = entiteTypeFileHeaderCopy;
								angular.forEach($scope.entiteTypeCaracs, function (element) {
									$scope.setSelectedEntiteTypeCarac(element, element.code);
								});
								actionsAfterEntityExpress(response.data, vm);
								$rootScope.$broadcast(UPDATE_EVENT['ENTITETYPE']);
								$rootScope.$broadcast(UPDATE_EVENT['DEPOT']);
								toaster.pop(
									'success',
									gettextCatalog.getString('Succès'),
									gettextCatalog.getString('Création des éléments effectuée')
								);
								$scope.saveInProgress = false;
								closeModal(true);
							})
							.catch(function () {
								// used to keep mapping filled even if an error occurred
								$scope.selectedFileHeader = selectedFileHeaderCopy;
								$scope.entiteTypeCaracs = entiteTypeCaracsCopy;
								$scope.entiteTypeFileHeader = entiteTypeFileHeaderCopy;
								angular.forEach($scope.entiteTypeCaracs, function (element) {
									$scope.setSelectedEntiteTypeCarac(element, element.code);
									$scope.setSelectedType(element);
								});
								$scope.saveInProgress = false;
							});
					};

					function createCarac(carac) {
						let caracData = {};
						let caracCode = carac.metaCodeValidator();

						caracData.code = '_' + caracCode;
						caracData.code = caracData.code.toUpperCase();
						caracData.tempCode = caracData.code;
						caracData.oldCode = carac;
						caracData.entite_type = {};
						caracData.entite_type.id =
							$scope.typeMapper[$scope.entiteTypeFileHeaderTypeMap[carac]];
						caracData.entite_type =
							$scope.simpleTypes[caracData.entite_type.id];
						caracData.poids = 100;
						caracData.label = carac;
						caracData.complex = false;
						if (caracData.date_pattern == undefined) {
							for (let t in $scope.datePatterns) {
								if ($scope.datePatterns[t].value == 'ISO 8601') {
									caracData.date_pattern = $scope.datePatterns[t].value;
									break;
								}
							}
						}

						return caracData;
					}

					let refreshExpressIntegrationFile = function () {
						$scope.showLoadingSpinner = true;
						$scope.refreshingHeaders = true;
						ExpressIntegration.refreshExpressIntegrationFile(
							$scope.dataSource,
							true,
							false
						)
							.then(function (response) {
								$scope.refreshingHeaders = false;
								$scope.showLoadingSpinner = false;
								$scope.entiteTypeFileDataPreview = response.data.data_preview;
								$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
								$scope.metatdataDepotDetailsLib = '';
								$scope.headers = response.data.headers;
								let caracCodeCounter = {};
								angular.forEach($scope.headers, function (element) {
									if (element.depot_details) {
										$scope.metatdataDepotDetailsLib = element.column;
									}
									let realType = getListRealType(
										element[0],
										element[1],
										$scope.caracIsList
									);
									$scope.entiteTypeFileHeaderTypeMap[element[0]] =
										getRootType(realType);
									let carac = createCarac(element[0]);
									//add incremental number to the carac code if duplicated
									if (caracCodeCounter[carac.code] == undefined) {
										caracCodeCounter[carac.code] = 0;
									} else {
										caracCodeCounter[carac.code] =
											caracCodeCounter[carac.code] + 1;
										carac.code = carac.code.concat(
											caracCodeCounter[carac.code]
										);
									}
									$scope.caracIsList[carac.code] =
										$scope.caracIsList[element[0]];
									carac.list =
										$scope.caracIsList[carac.code] != undefined
											? $scope.caracIsList[carac.code]
											: false;
									delete $scope.caracIsList[element[0]];
									$scope.entiteTypeCaracs.push(carac);
									$scope.selectedFileHeader[carac.code] = element[0];
									$scope.hide_preview = false;
								});
								generateDetailsDepotCarac();
								$scope.showDataPreviewGrid = false;
								$scope.initGridData();
							})
							.catch(function () {
								$scope.refreshingHeaders = false;
								$scope.showLoadingSpinner = false;
							});
					};

					$scope.depots = {};
					$scope.refreshingHeaders = false;
					$scope.showLoadingSpinner = false;
					$scope.refreshHeader = function () {
						if (
							$scope.depots.flow.files.length > 0 &&
							$scope.depot != null &&
							$scope.allowRefresh
						) {
							$scope.dataSource = $scope.depot;
							delete $scope.dataSource.flow;
							$scope.dataSource.metadata = $scope.metadata;
							$scope.entiteTypeCaracs = [];
							$scope.selectedFileHeader = {};
							$scope.selectedEntiteTypeCarac = {};
							$scope.selectedFileHeaderFormula = {};
							$scope.entiteTypeFileHeaderTypeMap = {};
							$scope.metatdataDepotDetailsLib = '';
							$scope.timeoutOn = false;
							$scope.gridData = [];
							$scope.entiteTypeFileDataPreview = [];
							$scope.showDataPreviewGrid = false;
							if (
								!$scope.dataSource.delimiter_csv ||
								$scope.dataSource.delimiter_csv == ''
							) {
								$scope.timeoutOn = true;
								$timeout(function () {
									if ($scope.timeoutOn) {
										refreshExpressIntegrationFile();
									}
								}, 1500);
							} else {
								refreshExpressIntegrationFile();
							}
						}
					};

					$scope.disableMetaDataCode = false;
					$scope.submitFlow = function (files, event, flow) {
						if (
							$scope.metadata.code === undefined ||
							$scope.metadata.code === ''
						) {
							MetadataService.getMatadataUniqueCode().then(function (response) {
								$scope.metadata.code = response.data;
								$scope.disableMetaDataCode = true;
								$scope.uploadFile(flow);
							});
						} else {
							$scope.uploadFile(flow);
						}
					};

					$scope.uploadedFileProcessingDone = {};
					let errorTra = gettextCatalog.getString('Erreur');
					let fileTra = gettextCatalog.getString('Le fichier');
					$scope.uploadFile = function (flow) {
						$scope.showLoadingSpinner = true;
						let charset =
							$scope.depot.charset != null
								? $scope.depot.charset
								: $scope.defaultCharset;
						flow.opts.query = {
							code: $scope.metadata.code,
							charset: charset,
						};

						flow.opts.headers = {
							Authorization: 'Bearer ' + window._keycloak.token,
						};
						//Define URL
						flow.opts.target =
							API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('EXPRESS_INTEGRATION_UPLOAD') +
							'?ctx=' +
							$window.sessionStorage.getItem(CTX_VAR_NAME) +
							'&project=' +
							$rootScope.currentProjectId;
						for (let i in flow.files) {
							CommonServices.validateFileByReadingMaskGlob(
								flow.files[i].name,
								$scope.depot.current_version_mask
							).then(function (response) {
								if (!response.data) {
									toaster.error(
										errorTra,
										fileTra +
											" '" +
											flow.files[i].name +
											"' " +
											gettextCatalog.getString(
												'ne correspond pas au masque de lecture'
											)
									);
									flow.files[i].cancel();
									delete flow.files[i];
									i--;
									$scope.showLoadingSpinner = false;
								} else {
									if (
										$scope.fileMaxSize &&
										flow.files[i].size > $scope.fileMaxSize
									) {
										toaster.error(
											errorTra,
											fileTra +
												" '" +
												flow.files[i].name +
												"' " +
												gettextCatalog.getString('est trop grand')
										);
										flow.files[i].cancel();
										delete flow.files[i];
										i--;
										$scope.showLoadingSpinner = false;
									} else {
										flow.files[i].uniqueIdentifier = i + 1;
									}
								}
								if (i == flow.files.length - 1) {
									if (flow.files[i] && flow.files[i].uniqueIdentifier) {
										$scope.uploadedFileProcessingDone[
											flow.files[i].uniqueIdentifier
										] = false;
									}
									flow.upload();
								}
							});
						}
					};

					$scope.generatedMetadata = false;
					$scope.loadMetadata = function (fileName) {
						if (!$scope.generatedMetadata) {
							$scope.metadata.actif = true;
							$scope.metadata.label = fileName.removeFileExtension();
							$scope.generatedMetadata = true;
						}
					};

					$scope.cancelFilesUpload = function () {
						if ($scope.depots.flow && $scope.depots.flow.files != undefined) {
							angular.forEach($scope.depots.flow.files, function (file) {
								file.cancel();
							});
						}
						$scope.showLoadingSpinner = false;
						$scope.allowRefresh = false;
					};

					$scope.depotNamesMapper = {};
					$scope.allowRefresh = false;
					$scope.succeedUploadHandle = function (
						flow,
						file,
						response,
						saveButtonDisabled
					) {
						$scope.showLoadingSpinner = false;
						$scope.uploadedFileProcessingDone[file.uniqueIdentifier] = true;
						$scope.saveButtonDisabled = saveButtonDisabled;
						$scope.depot =
							$scope.depot != undefined ? $scope.depot : initDepot();
						// $scope.metadata.code = data.code;
						$scope.disableMetaDataCode = true;
						// // Auto generate metadata
						$scope.loadMetadata(file.name);
						$scope.hide_preview = true;
						$scope.newFileUploaded = true;
						$scope.allowRefresh = true;
						$scope.refreshHeader();
						$scope.fileUploaded = true;
					};

					$scope.uploadError = function (file, response, flow) {
						$scope.showLoadingSpinner = false;
						let data = JSON.parse(response);
						let error =
							data.field_errors != undefined && data.field_errors.length > 0
								? data.field_errors[0]
								: data;
						let msgHeader = 'Erreur';
						if (error.field != undefined) {
							angular.forEach($scope.depots.flow.files, function (fl) {
								fl.cancel();
							});
						}
						toaster.pop('error', msgHeader, error.message, 20000);
						if (error.element != undefined) {
							$scope.depotNamesMapper[file.name] = error.element;
						}
						$scope.allowRefresh = false;
					};

					$scope.gridOptions = {};
					$scope.gridOptions.dataSource = [];
					$scope.moreThanTenColumns = false;
					$scope.showDataPreviewGrid = false;
					$scope.initGridData = function () {
						$scope.gridData = [];
						$scope.gridOptions = {};
						$scope.showDataPreviewGrid = false;
						ExpressIntegration.refreshExpressIntegrationFile(
							$scope.dataSource,
							false,
							true
						).then(function (response) {
							$scope.refreshingHeaders = false;
							$scope.showLoadingSpinner = false;
							$scope.entiteTypeFileDataPreview = response.data.data_preview;
							let dataHeaders = getHeaders(response.data.headers);
							//Create Grid Columns
							let headearsWithNoSpecChar = [];
							let generatedColumns = [];
							for (let i = 0; i < dataHeaders.length; i++) {
								let columnName = dataHeaders[i];
								$scope.headers =
									$scope.headers != undefined ? $scope.headers : [];
								let columnDepName =
									$scope.headers[i] != undefined
										? $scope.headers[i].column
										: '';
								columnDepName =
									columnDepName != undefined &&
									columnDepName != '' &&
									columnDepName != null
										? columnDepName
										: columnName;
								columnName =
									columnName != undefined &&
									columnName != '' &&
									columnName != null
										? columnName
										: columnDepName;
								let headerNoSpecChar = '';
								if (columnName != undefined) {
									headerNoSpecChar = columnName
										.replace(/[^a-zA-Z0-9_-]/g, '_')
										.toLowerCase();
								}
								headearsWithNoSpecChar[i] = headerNoSpecChar;
								if (i < 10) {
									$scope.moreThanTenColumns = false;
									generatedColumns.push({
										dataField: headerNoSpecChar,
										caption: '*' + columnDepName + ' / ' + columnName,
									});
								} else {
									$scope.moreThanTenColumns = true;
									generatedColumns.push({
										dataField: headerNoSpecChar,
										caption: '*' + columnDepName + ' / ' + columnName,
										visible: false,
									});
								}
							}
							//Load Data
							if ($scope.entiteTypeFileDataPreview) {
								for (
									let count = 0;
									count < $scope.entiteTypeFileDataPreview.length;
									count++
								) {
									let object = $scope.gridData[count];
									let value = $scope.entiteTypeFileDataPreview[count];
									for (let j = 0; j < headearsWithNoSpecChar.length; j++) {
										let colName = headearsWithNoSpecChar[j];
										if (object == undefined) {
											object = {};
										}

										object[colName] = value[j];
										$scope.gridData[count] = object;
									}
								}

								$scope.gridOptions.pager = {
									showPageSizeSelector: true,
									allowedPageSizes: PAGINATIONS_SIZES,
									showInfo: true,
									visible: true,
								};
								$scope.gridOptions.paging = {
									enabled: true,
									pageSize: PAGINATIONS_SIZE,
								};
								$scope.gridOptions.filterRow = {
									visible: true,
									applyFilter: 'auto',
								};
								$scope.gridOptions.rowAlternationEnabled = true;
								$scope.gridOptions.headerFilter = {
									visible: true,
									applyFilter: 'auto',
								};
								$scope.gridOptions.columnChooser = {
									enabled: true,
								};
								$scope.gridOptions.showColumnLines = true;
								$scope.gridOptions.showRowLines = true;
								$scope.gridOptions.allowColumnReordering = true;
								$scope.gridOptions.allowColumnResizing = true;
								$scope.gridOptions.columnAutoWidth = true;
								$scope.gridOptions.showBorders = true;
								$scope.gridOptions.onInitialized = function (e) {
									$scope.gridInstance = e.component;
								};
								// Set a bigger z-index
								$(function () {
									DevExpress.ui.dxOverlay.baseZIndex(1999999998);
								});
								$scope.gridOptions.columns = generatedColumns;
								$scope.gridOptions.dataSource = $scope.gridData;
								$scope.showDataPreviewGrid = true;
								if ($scope.gridInstance && $scope.gridInstance.option) {
									$scope.gridInstance.option('columns', generatedColumns);
									$scope.gridInstance.option('dataSource', $scope.gridData);
									$scope.gridInstance.refresh();
								}
							} else {
								$scope.gridOptions.dataSource = [];
							}
						});
					};

					$scope.updateCaracLib = function (carac, lib) {
						carac.label = lib;
					};

					$scope.setSelectedType = function (carac) {
						setSelectedType(carac, $scope.defaultTypes);
					};

					$scope.setSelectedConnector = function (connector) {
						setSelectedConnector(connector, $scope.connexions, $scope.depot);
					};

					$scope.selectedHeaderType = {};
					$scope.getHeaderReadableType = function (type, code) {
						if ($scope.caracIsList[code]) {
							return 'Liste(' + getHeaderRealReadableType(type) + ')';
						} else {
							return getHeaderRealReadableType(type);
						}
					};

					function generateDetailsDepotCarac() {
						let metadataHeaderCarac = _.find(
							$scope.entiteTypeCaracs,
							function (elm) {
								return elm.depotDetails == true;
							}
						);
						if (
							$scope.metatdataDepotDetailsLib == '' ||
							metadataHeaderCarac == undefined
						) {
							$scope.entiteTypeFileHeader.push(headerDetailsLib);
							let realType = getListRealType(
								headerDetailsLib,
								defaultDataType,
								$scope.caracIsList
							);
							$scope.entiteTypeFileHeaderTypeMap[headerDetailsLib] = realType;
							let carac = createCarac(headerDetailsLib);
							carac.depotDetails = true;
							$scope.caracIsList[carac.code] =
								$scope.caracIsList[headerDetailsLib];
							carac.list =
								$scope.caracIsList[carac.code] != undefined
									? $scope.caracIsList[carac.code]
									: false;
							delete $scope.caracIsList[headerDetailsLib];
							$scope.entiteTypeCaracs.push(carac);
							$scope.selectedFileHeader[carac.code] = headerDetailsLib;
						}
					}

					$scope.itemTracker = function (item) {
						return item.code + '-' + _.uniqueId();
					};

					function initDepot() {
						var charset = $scope.depot ? $scope.depot.charset : undefined;
						$scope.depot = {};
						$scope.depot.delimiter_csv = ';';
						$scope.depot.quote_csv = '';
						$scope.depot.current_version_mask =
							'*.{csv,CSV,cSv,cSV,Csv,CSv,csV,CsV}';
						$scope.depot.parsing_mode = 'PERMISSIVE';
						$scope.depot.has_header = true;
						$scope.depot.multiline = false;
						if (charset != undefined) {
							$scope.depot.charset = charset;
						} else {
							if ($scope.charsetsFetched) {
								for (var i in $scope.charsets) {
									if ($scope.charsets[i].value == 'UTF_8') {
										$scope.defaultCharset = $scope.charsets[i].value;
										break;
									}
								}
							}
						}
						if ($scope.depot.charset == undefined) {
							$scope.depot.charset = $scope.defaultCharset;
						}
						$scope.headersReferencingModes = [];
						AllDepotService.findAllDepotsHeadersReferencingModes().then(
							function (modesRes) {
								$scope.headersReferencingModes = modesRes.data;
								$scope.depot.headers_referencing_mode = 'LABEL';
							}
						);
					}

					let vm = this;

					$scope.reset = function () {
						// init File
						$scope.metadataLoaded = false;
						$scope.generatedMetadata = false;
						$scope.metadata = {};
						$scope.metadata.code = '';
						$scope.metadata.actif = true;
						$scope.metadata.tags = [];
						$scope.disableMetaDataCode = false;
						$scope.metadataLoaded = true;
						$scope.entiteTypeFileHeader = [];
						$scope.selectedFileHeader = {};
						$scope.gridData = [];
						$scope.gridOptions = {};
						$scope.showDataPreviewGrid = false;
						$scope.entiteTypeFileDataPreview = [];
						$scope.entiteTypeFileHeader = [];
						$scope.entiteTypeCaracs = [];
						initDepot();
						$scope.cancelFilesUpload();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal(reload) {
						vm.showExpressIntegration = false;
						vm.entiteTypeId = null;
						vm.sourceId = null;
						vm.disableEntiteType = false;
						vm.disableDataSource = false;
						$scope.diasableDataSourceSelectList = false;
						$scope.diasableEntiteTypeSelectList = false;
						$scope.showDataPreview = false;
						$scope.sourceId = vm.sourceId;
						$scope.entiteTypeId = vm.entiteTypeId;
						$scope.selectedFileHeader = {};
						$scope.selectedEntiteTypeCarac = {};
						$scope.selectedFileHeaderFormula = {};
						$scope.showList = {};
						$scope.depot = {};
						$scope.initObjectFlow = false;
						$scope.depotFlow = {};
						$scope.cancelFilesUpload();
						delete $scope.gridData;
						$scope.entiteTypeFileHeader = [];
						$scope.disableMetaDataCode = false;
						$scope.reset();
						$scope.allowRefresh = false;
						$scope.createRepDetails = false;
					}

					// Get necessary data and show the modal
					vm.initData = function (element) {
						// Get simpleTypes
						TypeEntiteService.getSimpleTypes().then(function (responseTypes) {
							let simpletypes = $rootScope.translateSimpleTypes(
								responseTypes.data
							);
							$scope.defaultTypes = simpletypes;
							$scope.simpleTypes = {};
							$scope.typeMapper = {};
							mapSimpleTypes(
								simpletypes,
								$scope.typeMapper,
								$scope.simpleTypes
							);
							CommonServices.getCharsets().then(function (responseCharsets) {
								$scope.charsets = responseCharsets.data;
								$scope.charsetsFetched = true;
								// Get file max size
								$scope.fileMaxSize = undefined;
								ParametersServices.getFileMaxSize().then(function (
									responseMaxFileSize
								) {
									$scope.fileMaxSize = responseMaxFileSize.data;
									$scope.depot = {};
									// Get date âtterns
									CommonServices.getDateReadingMasks().then(function (
										response
									) {
										$scope.datePatterns = response.data;
										initDepot();
										// show the modal
										$(element).modal(
											{ backdrop: 'static', keyboard: false },
											'show'
										);
									});
								});
							});
						});
					};

					$scope.metadataConfig = {
						htmlId: 'express-integration',
					};

					let catchErrors = function (error) {
						if (
							(error &&
								error.data &&
								(error.data.code == '37' ||
									error.data.code == '38' ||
									error.data.code == '39')) ||
							(error &&
								(error.code == '37' ||
									error.code == '38' ||
									error.code == '39'))
						) {
							$scope.jobError.errorMessage = translateErrorMessage;
						}
						$scope.jobError.error = true;
						$scope.savingInProgress = false;
					};

					$scope.detectCharset = function () {
						if ($scope.fileUploaded) {
							$scope.jobError = {};
							$scope.jobError.mainPosition = true;
							ExpressIntegration.detectCharset(
								$scope.depot.metadata.code,
								$scope.depot.current_version_mask
							)
								.then(function (response) {
									let charset = response.data;
									if (charset !== undefined && charset !== '') {
										if (!Array.isArray($scope.charsets)) {
											$scope.charsets = [];
										}
										let charsetExist = false;
										for (let i in $scope.charsets) {
											if ($scope.charsets[i].value === charset) {
												charsetExist = true;
												break;
											}
										}
										if (!charsetExist) {
											let charsetObj = {
												label: charset,
												value: charset,
											};
											$scope.charsets.unshift(charsetObj);
										}
										$scope.depot.charset = charset;
										$scope.refreshHeader();
									}
								})
								.catch(catchErrors);
						}
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					showExpressIntegration: '=',
					reloadDataConfirmation: '=',
					refreshGraphAction: '=',
					updateAction: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				transclude: true,
				replace: true,
				templateUrl:
					'./src/components/directives/expressIntegration/expressIntegration.html',
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					element.bind('keydown keypress', function (event) {
						if (event.which == 27) {
							if (event.target.id == element[0].id) {
								event.stopPropagation();
								event.stopImmediatePropagation();
							} else {
								$(event.target).modal('hide');
							}
						}
					});

					scope.$watch(
						function () {
							return scope.vm.showExpressIntegration;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.depotFlow = {};
								scope.initObjectFlow = true;
								scope.vm.initData(element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
