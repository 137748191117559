(function () {
	'use strict';

	angular.module('dcApp').directive('datablockbuilder', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'DataBlocksService',
				'gettextCatalog',
				'$sce',
				function ($scope, DataBlocksService, gettextCatalog, $sce) {
					var vm = this;
					vm.MAXSIZE = 35;
					var defaultSummaryConfig = {
						summary: true,
						data: true,
						total_count: true,
						count_columns: [],
						summary_columns: [],
					};
					let stepTra = gettextCatalog.getString('Etape');
					let filtersTra = gettextCatalog.getString('Filtres');

					$scope.selectStep = function (step) {
						$scope.selectedStep = step;
						for (var s in $scope.steps) {
							delete $scope.steps[s].selected;
						}
						step.selected = true;
						$scope.lastStepNotSelected =
							$scope.steps.indexOf(step) !== $scope.steps.length - 1;
					};

					$scope.showColumns = function () {
						$scope.oldSteps = angular.copy($scope.steps);
						vm.datablock.checkColumnsBeforeSave = $scope.checkColumnsBeforeSave;
						$scope.oldPartielStep = {};
						delete $scope.selectedStep;
						vm.datablock.showAddColumn(vm.datablock);
					};

					$scope.checkColumnsBeforeSave = function (columns, saveMethod) {
						checkDeletedColumns(
							undefined,
							{ in_columns: true },
							function () {
								saveMethod();
							},
							columns
						);
					};

					$scope.intercaleStep = function (position) {
						for (var si in $scope.steps) {
							delete $scope.steps[si].summaryConfigData;
						}
						var selectedIndex = $scope.steps.indexOf($scope.selectedStep);
						var steps = [];
						let index = position == 'RIGHT' ? selectedIndex + 1 : selectedIndex;
						for (let s = 0; s < index; s++) {
							steps.push($scope.steps[s]);
						}
						var step = {
							input: { columns: [] },
							formulas: [],
							data_operation: {},
							agregation: {},
							sort: {},
							alias: stepTra + ' ' + ($scope.steps.length + 1),
							output: [],
							summary_config: angular.copy(defaultSummaryConfig),
							updateOutput: true,
						};

						getColumns(selectedIndex, steps, function () {
							setStepInputOutput(step, vm.data.columns);
							steps.push(step);
							for (let s = index; s < $scope.steps.length; s++) {
								steps.push($scope.steps[s]);
							}
							$scope.steps = steps;
							vm.datablock.steps = $scope.steps;
							for (var si in $scope.steps) {
								$scope.setSummaryConfigData($scope.steps[si]);
							}
						});
					};

					$scope.addStep = function () {
						addStep();
					};
					var addStep = function (fixedInputCols) {
						var step = {
							input: { columns: [] },
							formulas: [],
							data_operation: {},
							agregation: {},
							sort: {},
							alias: stepTra + ' ' + ($scope.steps.length + 1),
							output: [],
							summary_config: angular.copy(defaultSummaryConfig),
						};
						$scope.setSummaryConfigData(step);
						setStepInputOutput(step);
						let grammar = {
							columns: vm.datablock.grammar.columns,
							steps: $scope.steps,
						};
						//TODO set date pattern from last step
						DataBlocksService.getDBBuilderColumns(grammar).then(function (
							response
						) {
							setStepInputOutput(
								step,
								response.data,
								fixedInputCols ? fixedInputCols : []
							);
							$scope.steps.push(step);
						});
					};

					var setStepInputOutput = function (step, columns, fixedInputCols) {
						for (let c in columns) {
							let col = columns[c];
							let inputCol = {
								uuid: col.uuid,
								column_uuid: col.uuid,
								label: col.lib,
								type: col.type,
								is_list: col.is_list,
							};
							step.input.columns.push(inputCol);
							step.output.push({
								column_uuid: inputCol.uuid,
								lib: col.lib,
								active: true,
							});
						}
						for (let c in fixedInputCols) {
							step.input.columns.push(fixedInputCols[c]);
						}
					};

					var setStepOutput = function (step, columns) {
						step.output = [];
						for (let c in columns) {
							const col = columns[c];
							step.output.push({
								column_uuid: col.uuid,
								lib: col.lib,
								active: true,
							});
						}
					};

					$scope.showSummaryConfig = function (i) {
						var steps = angular.copy($scope.steps.slice(0, i + 1));

						getColumns(i, steps, function (columns) {
							$scope.steps[i].output_columns = columns;
							$scope.steps[i].summaryConfigData.initConfig(columns);
						});
					};

					$scope.containsFilter = function (i) {
						return (
							($scope.steps[i].filter &&
								$scope.steps[i].filter.rules &&
								$scope.steps[i].filter.rules[0]) ||
							$scope.steps[i].limit
						);
					};

					$scope.filterIsInactive = function (i) {
						return $scope.steps[i].filter && $scope.steps[i].filter.inactive;
					};

					$scope.sortIsInactive = function (i) {
						return $scope.steps[i].sort && $scope.steps[i].sort.inactive;
					};

					$scope.containsFormula = function (i) {
						return $scope.steps[i].formulas && $scope.steps[i].formulas[0];
					};
					$scope.containsDatablockLink = function (i) {
						return $scope.steps[i].links && $scope.steps[i].links[0];
					};
					$scope.containsDatablockUnion = function (i) {
						return $scope.steps[i].unions && $scope.steps[i].unions[0];
					};

					$scope.containsOperation = function (i) {
						return (
							$scope.steps[i].data_operation &&
							(($scope.steps[i].data_operation.explode &&
								$scope.steps[i].data_operation.explode.columns &&
								$scope.steps[i].data_operation.explode.columns[0]) ||
								($scope.steps[i].data_operation.stack &&
									$scope.steps[i].data_operation.stack.columns &&
									$scope.steps[i].data_operation.stack.columns[0]) ||
								($scope.steps[i].data_operation.pivot &&
									$scope.steps[i].data_operation.pivot
										.number_of_columns_per_group))
						);
					};

					$scope.containsAgregation = function (i) {
						return stepContainsAgregation($scope.steps[i]);
					};

					$scope.containsSort = function (i) {
						return stepContainsSort($scope.steps[i]);
					};

					$scope.removeStep = function () {
						checkDeletedColumns(undefined, { step_delete: true }, function () {
							$scope.steps.splice($scope.stepToDeleteIndex, 1);
							delete $scope.selectedStep;
							vm.referencedElements = getReferencedEntities();
						});
					};

					$scope.deleteElementData = {};
					$scope.doShowDeleteModal = function (index) {
						$scope.stepToDeleteIndex = index;
						let label = $scope.steps[index].alias;
						$scope.deleteElementData = {
							label: label,
							doDelete: $scope.removeStep,
							showConfirmDeleteElement: true,
							bulk: false,
						};
					};

					$scope.execInit = function (i) {
						for (var s in $scope.steps) {
							$scope.steps[s].executed = false;
						}
						vm.datablock.initExecuted = true;
						vm.datablock.reloadWidget([]);
					};

					$scope.execStep = function (i) {
						for (var s in $scope.steps) {
							$scope.steps[s].executed = false;
						}
						vm.datablock.initExecuted = false;
						var steps = $scope.steps.slice(0, i + 1);
						vm.datablock.reloadWidget(steps);
					};

					$scope.filterStep = function (i) {
						$scope.selectedStep = $scope.steps[i];

						vm.data.step = $scope.selectedStep;
						if (!$scope.selectedStep.filter) {
							$scope.selectedStep.filter = { rules: [], condition: 'AND' };
						}
						vm.data.datablock = vm.datablock;
						vm.data.saveFilterMethod = $scope.saveFilter;
						vm.data.filterRules = $scope.selectedStep.filter;
						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.data_operation = {};
						lastStep.filter = {};
						lastStep.output = [];
						steps.push(lastStep);
						vm.data.currentSteps = steps;
						getColumns(i, steps, $scope.showFilter);
					};

					$scope.linkStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						if (!$scope.selectedStep.links) {
							$scope.selectedStep.links = [];
						}
						vm.data.datablock = vm.datablock;
						vm.data.saveLinksMethod = $scope.saveLinks;

						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.links = [];
						lastStep.unions = [];
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.formulas = [];
						lastStep.output = [];
						delete lastStep.filter;
						lastStep.data_operation = {};
						steps.push(lastStep);
						getColumns(i, steps, function (columns) {
							$scope.showLinks(steps, columns);
						});
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.links = angular.copy(
							$scope.selectedStep.links
						);
					};

					$scope.unionStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						if (!$scope.selectedStep.unions) {
							$scope.selectedStep.unions = [];
						}
						vm.data.datablock = vm.datablock;
						vm.data.saveUnionsMethod = $scope.saveUnions;

						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.unions = [];
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.formulas = [];
						lastStep.output = [];

						delete lastStep.filter;
						lastStep.data_operation = {};
						steps.push(lastStep);

						getColumns(i, steps, $scope.showUnions);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.unions = angular.copy(
							$scope.selectedStep.unions
						);
					};

					$scope.setMax25Length = function (str) {
						return setMaxStringLength(str, 25);
					};

					$scope.updateGrammar = function () {};

					$scope.showLinks = function (steps, columns) {
						vm.data.linkSourceGrammar = {
							columns: vm.datablock.grammar.columns,
							steps: steps,
						};
						vm.data.linkSourceColumns = columns;
						vm.data.linkSourceLabel = vm.datablock.metadata
							? vm.datablock.metadata.label
							: '';
						vm.data.showDbLinks = true;
					};

					$scope.showUnions = function () {
						vm.data.showDbUnions = true;
					};

					function getReferencedEntities() {
						let elements = [];
						for (let i = 0; i < vm.datablock.grammar.columns.length; i++) {
							if (
								vm.datablock.grammar.columns[i].parent_entite_type_id &&
								elements.indexOf(
									vm.datablock.grammar.columns[i].parent_entite_type_id
								) < 0
							) {
								elements.push(
									vm.datablock.grammar.columns[i].parent_entite_type_id
								);
							}
						}
						for (let i in $scope.steps) {
							if ($scope.steps[i].links) {
								for (let j in $scope.steps[i].links) {
									if (
										$scope.steps[i].links[j].db_id &&
										elements.indexOf($scope.steps[i].links[j].db_id) < 0
									) {
										elements.push($scope.steps[i].links[j].db_id);
									}
								}
							}
							if ($scope.steps[i].unions) {
								for (let j in $scope.steps[i].unions) {
									if (
										$scope.steps[i].unions[j].db_id &&
										elements.indexOf($scope.steps[i].unions[j].db_id) < 0
									) {
										elements.push($scope.steps[i].unions[j].db_id);
									}
								}
							}
						}
						return elements;
					}

					$scope.saveLinks = function (links, saveAndCloseMethod) {
						var s = angular.copy($scope.oldPartielStep);
						s.links = links;
						const keysToRemove = ['$$hashKey', 'allSelected', 'eligibileColumns'];
						let updateOutput = $scope.selectedStep.updateOutput ||
							!_.isEqual(
								cleanStepObject(angular.copy($scope.oldPartielStep.links), keysToRemove),
								cleanStepObject(links, keysToRemove)
							);
						checkDeletedColumns(s, { in_link: true }, function () {
							let i = $scope.steps.indexOf($scope.selectedStep);
							$scope.selectedStep.links = links;
							if (updateOutput) {
								const steps = angular.copy($scope.steps.slice(0, i));
								steps.push(angular.copy($scope.selectedStep));
								// clear steps output
								steps.forEach((step) => step.output = []);
								getColumns(null, steps, function (columns) {
									setStepOutput($scope.selectedStep, columns);
									delete $scope.selectedStep.updateOutput;
									saveAndCloseMethod();
									vm.referencedElements = getReferencedEntities();
								});
							} else {
								saveAndCloseMethod();
								vm.referencedElements = getReferencedEntities();
							}
						});
					};

					$scope.saveUnions = function (unions, saveAndCloseMethod) {
						var s = angular.copy($scope.oldPartielStep);
						s.unions = unions;
						checkDeletedColumns(s, { in_union: true }, function () {
							$scope.selectedStep.unions = unions;
							saveAndCloseMethod();
							vm.referencedElements = getReferencedEntities();
						});
					};

					$scope.showFilter = function () {
						vm.data.filterTitle = filtersTra;
						vm.data.disableLimit = false;
						vm.data.disableActivation = false;
						vm.data.showFilter = true;
					};

					$scope.saveFilter = function (rules, limit) {
						$scope.selectedStep.filter = rules;
						// date to iso
						DataBlocksService.filterDatesToIsoDefaultTz($scope.selectedStep.filter.rules);
						$scope.selectedStep.limit = limit;
					};

					$scope.formulaStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						if (!$scope.selectedStep.formulas) {
							$scope.selectedStep.formulas = [];
						}

						vm.widgetData.updateaction = $scope.saveFormulas;

						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.formulas = [];
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.data_operation = {};
						lastStep.output = [];
						steps.push(lastStep);

						getColumns(i, steps, $scope.showFormulas);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.formulas = angular.copy(
							$scope.selectedStep.formulas
						);
					};

					$scope.showFormulas = function () {
						var widget = { grammar: {} };
						widget.grammar.columns = [];
						var j = 1;
						for (let c in vm.data.columns) {
							var col = {
								id: j,
								column_alias: vm.data.columns[c].lib,
								field: vm.data.columns[c].lib,
								type: vm.data.columns[c].type,
								uuid: vm.data.columns[c].uuid,
								list: vm.data.columns[c].is_list,
							};
							widget.grammar.columns.push(col);
							j++;
						}
						for (let f in $scope.selectedStep.formulas) {
							widget.grammar.columns.push({
								id: j,
								formula: angular.copy($scope.selectedStep.formulas[f]),
								column_alias: $scope.selectedStep.formulas[f].lib,
								field: $scope.selectedStep.formulas[f].lib,
								type: $scope.selectedStep.formulas[f].data_type,
								uuid: $scope.selectedStep.formulas[f].uuid,
								list: $scope.selectedStep.formulas[f].is_list,
								description: $scope.selectedStep.formulas[f].description,
							});
							j++;
						}

						for (let k in widget.grammar.columns) {
							if (widget.grammar.columns[k].formula) {
								setFormulaColumnIndex(
									widget.grammar.columns[k].formula,
									widget.grammar.columns
								);
							}
						}

						vm.widgetData.widget = widget;
						vm.widgetData.widgetMenuData = {};
						vm.widgetData.checkMethodBeforeSaveFormulas = true;
						vm.widgetData.showFormule = true;
						vm.widgetData.hideWidgetMenu = true;

						vm.widgetData.widget = widget;
					};

					$scope.saveFormulas = function (
						widget,
						saveAndCloseMethod,
						onDeleteFailure
					) {
						var formulas = [];
						for (var c in vm.widgetData.widget.grammar.columns) {
							if (vm.widgetData.widget.grammar.columns[c].formula) {
								setFormulaColumnUuid(
									vm.widgetData.widget.grammar.columns[c].formula,
									vm.widgetData.widget.grammar.columns
								);
								vm.widgetData.widget.grammar.columns[c].formula.lib =
									vm.widgetData.widget.grammar.columns[c].column_alias;
								vm.widgetData.widget.grammar.columns[c].formula.description =
									vm.widgetData.widget.grammar.columns[c].description;
								vm.widgetData.widget.grammar.columns[c].formula.is_list =
									vm.widgetData.widget.grammar.columns[c].list;
								vm.widgetData.widget.grammar.columns[c].formula.uuid = vm
									.widgetData.widget.grammar.columns[c].uuid
									? vm.widgetData.widget.grammar.columns[c].uuid
									: generateUuid('_');
								formulas.push(vm.widgetData.widget.grammar.columns[c].formula);
							}
						}
						var s = angular.copy($scope.oldPartielStep);
						s.formulas = formulas;

						checkDeletedColumns(
							s,
							{ in_formulas: true },
							function () {
								$scope.selectedStep.formulas = formulas;
								for (let c in formulas) {
									updateColumnLib(formulas[c].uuid, formulas[c].lib);
								}
								if (saveAndCloseMethod) {
									saveAndCloseMethod();
								}
							},
							undefined,
							onDeleteFailure
						);
					};

					$scope.inputStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						$scope.selectedStepIndex = i;
						vm.data.step = $scope.selectedStep;
						vm.data.saveInputMethod = $scope.saveInput;
						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = { input: { columns: [] } };
						steps.push(lastStep);
						getColumns(i, steps, $scope.showInput);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.input = angular.copy(
							$scope.selectedStep.input
						);
					};

					$scope.showInput = function () {
						vm.data.showStepInputMapping = true;
					};

					$scope.saveInput = function (input, saveAndCloseMethod) {
						var s = angular.copy($scope.oldPartielStep);
						s.input = input;
						checkDeletedColumns(s, { in_input: true }, function () {
							$scope.selectedStep.input = input;
							saveAndCloseMethod();
						});
					};
					$scope.sortStep = function (i) {
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						vm.data.saveSortsMethod = $scope.saveSort;
						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);

						lastStep.sort = {};
						delete lastStep.output;
						steps.push(lastStep);
						getColumns(i, steps, $scope.showSort);
					};

					$scope.showSort = function () {
						if (!$scope.selectedStep.sort || !$scope.selectedStep.sort.sorts) {
							$scope.selectedStep.sort = { sorts: [] };
						}
						vm.data.showBDTris = true;
					};

					$scope.saveSort = function (sorts, inactive) {
						for (var s in sorts) {
							sorts[s].column_uuid = sorts[s].uuid;
						}
						$scope.selectedStep.sort = { sorts: sorts, inactive: inactive };
					};

					$scope.outputStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						vm.data.saveOutputMethod = $scope.saveOutput;

						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);

						delete lastStep.output;
						steps.push(lastStep);

						getColumns(i, steps, $scope.showOutput);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.output = angular.copy(
							$scope.selectedStep.output
						);
					};

					$scope.showOutput = function () {
						vm.data.showOutput = true;
					};

					$scope.saveOutput = function (columns, saveAndCloseMethod) {
						var s = angular.copy($scope.oldPartielStep);
						s.output = columns;
						checkDeletedColumns(s, { in_output: true }, function () {
							for (var c in columns) {
								//if label is updated ==> update the next steps output
								let oldCol = _.find(
									$scope.selectedStep.output,
									function (item) {
										return item.column_uuid == columns[c].column_uuid;
									}
								);
								if (oldCol && oldCol.lib != columns[c].lib) {
									updateColumnLib(
										columns[c].column_uuid,
										columns[c].lib,
										oldCol.lib
									);
								}
								if (columns[c].date_output_pattern) {
									updateDatePattern(
										columns[c].column_uuid,
										columns[c].date_output_pattern
									);
								}
							}
							$scope.selectedStep.output = columns;
							saveAndCloseMethod();
						});
					};

					var updateColumnLib = function (uuid, lib, oldLib) {
						updateColumnLibInSteps(
							$scope.steps.indexOf($scope.selectedStep) + 1,
							$scope.steps,
							uuid,
							lib,
							oldLib
						);
					};

					var updateDatePattern = function (uuid, pattern) {
						for (var s in $scope.steps) {
							if ($scope.steps[s].output) {
								let outputCol = _.find($scope.steps[s].output, function (item) {
									return item.column_uuid == uuid;
								});
								if (outputCol) {
									outputCol.date_output_pattern = pattern;
								}
							}
						}
					};

					$scope.operateStep = function (i) {
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						vm.data.saveDataOperationMethod = $scope.saveDataOperation;
						var steps = angular.copy($scope.steps.slice(0, i));
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.data_operation = {};
						lastStep.output = [];
						steps.push(lastStep);
						getColumns(i, steps, $scope.showDataOperation);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.data_operation = angular.copy(
							$scope.selectedStep.data_operation
						);
					};

					$scope.showDataOperation = function () {
						vm.data.showBDDataOperation = true;
					};

					$scope.saveDataOperation = function (
						columns,
						operationType,
						numberOfRows,
						numberOfColumnsPerGroup,
						startColumnIndex,
						groupFieldsColumnUuid,
						endColumnIndex,
						groupColumnLabel,
						valueColumnLabel,
						valueColumnUuid,
						groupColumnUuid,
						stackedColumnNames,
						saveAndCloseMethod
					) {
						var columns_ = [];
						var dataOperation = {};
						for (var c in columns) {
							var col = {
								keep_in: columns[c].keep_in,
								column_uuid: columns[c].column_uuid,
							};
							col.uuid = columns[c].uuid ? columns[c].uuid : generateUuid('_');
							columns_.push(col);
						}
						if (operationType == 0) {
							dataOperation.explode = { columns: columns_ };
						} else if (operationType == 1) {
							dataOperation.stack = {
								columns: columns_,
								number_of_rows: numberOfRows,
							};
						} else if (operationType == 2) {
							dataOperation.pivot = {
								columns: columns_,
								number_of_columns_per_group: numberOfColumnsPerGroup,
								start_column_index: startColumnIndex,
								group_fields_column_uuid: groupFieldsColumnUuid,
								end_column_index: endColumnIndex,
								group_column_label: groupColumnLabel,
								value_column_label: valueColumnLabel,
								value_column_uuid: valueColumnUuid,
								group_column_uuid: groupColumnUuid,
								stacked_column_names: stackedColumnNames,
							};
						}
						var s = angular.copy($scope.oldPartielStep);
						s.data_operation = dataOperation;
						const keysToRemove = ['$$hashKey'];
						let updateOutput = $scope.selectedStep.updateOutput ||
							!_.isEqual(
								cleanStepObject(angular.copy($scope.oldPartielStep.data_operation), keysToRemove),
								cleanStepObject(dataOperation, keysToRemove)
							);
						checkDeletedColumns(s, { in_data_operation: true }, function () {
							let i = $scope.steps.indexOf($scope.selectedStep);
							$scope.selectedStep.data_operation = dataOperation;
							if (updateOutput) {
								const steps = angular.copy($scope.steps.slice(0, i));
								steps.push(angular.copy($scope.selectedStep));
								// clear steps output
								steps.forEach((step) => step.output = []);
								getColumns(null, steps, function (columns) {
									setStepOutput($scope.selectedStep, columns);
									delete $scope.selectedStep.updateOutput;
									saveAndCloseMethod();
								});
							} else {
								saveAndCloseMethod();
							}
						});
					};

					$scope.agregateStep = function (i) {
						vm.data.datablock = vm.datablock;
						$scope.oldSteps = angular.copy($scope.steps);
						$scope.selectedStep = $scope.steps[i];
						vm.data.step = $scope.selectedStep;
						vm.data.saveAgregationMethod = $scope.saveAgregation;
						var steps = $scope.steps.slice(0, i);
						var lastStep = angular.copy($scope.selectedStep);
						lastStep.agregation = {};
						lastStep.sort = {};
						lastStep.output = [];
						steps.push(lastStep);
						vm.data.currentSteps = steps;
						getColumns(i, steps, $scope.showAgregation);
						$scope.oldPartielStep = angular.copy(lastStep);
						$scope.oldPartielStep.agregation = angular.copy(
							$scope.selectedStep.agregation
						);
					};

					$scope.showAgregation = function () {
						vm.data.showBDAgregation = true;
					};

					$scope.setSummaryConfigData = function (step) {
						if (!step.summary_config) {
							step.summary_config = {
								summary: true,
								data: true,
								total_count: true,
								count_columns: [],
								summary_columns: [],
							};
						}
						step.summaryConfigData = { config: step.summary_config };
					};

					$scope.setInitSummaryConfigData = function () {
						if (!vm.datablock.grammar.summary_config) {
							vm.datablock.grammar.summary_config = {
								summary: true,
								data: true,
								total_count: true,
								count_columns: [],
								summary_columns: [],
							};
						}
						$scope.summaryConfigData = {
							config: vm.datablock.grammar.summary_config,
						};
					};

					$scope.showInitSummaryConfig = function () {
						DataBlocksService.getDBBuilderColumns({
							columns: vm.datablock.grammar.columns,
						}).then(function (response) {
							$scope.summaryConfigData.initConfig(response.data);
						});
					};

					$scope.saveAgregation = function (agregation, saveAndCloseMethod) {
						let s = angular.copy($scope.oldPartielStep);
						s.agregation = agregation;
						const keysToRemove = ['$$hashKey', 'eligibleFunctions'];
						let updateOutput = $scope.selectedStep.updateOutput ||
							!_.isEqual(
								cleanStepObject(angular.copy($scope.oldPartielStep.agregation), keysToRemove),
								cleanStepObject(agregation, keysToRemove)
							);
						checkDeletedColumns(s, { in_agregation: true }, function () {
							let i = $scope.steps.indexOf($scope.selectedStep);
							$scope.selectedStep.agregation = agregation;
							if (updateOutput) {
								const steps = angular.copy($scope.steps.slice(0, i));
								steps.push(angular.copy($scope.selectedStep));
								// clear steps output
								steps.forEach((step) => step.output = []);
								getColumns(null, steps, function (columns) {
									setStepOutput($scope.selectedStep, columns);
									delete $scope.selectedStep.updateOutput;
									saveAndCloseMethod();
								});
							} else {
								saveAndCloseMethod();
							}
						});
					};

					var getColumns = function (i, steps, method) {
						var grammar = {
							columns: vm.datablock.grammar.columns,
							steps: steps,
						};
						DataBlocksService.getDBBuilderColumns(grammar).then(function (
							response
						) {
							vm.data.columns = response.data;
							method(response.data);
						});
					};

					var safeDeletedColumnImpact = function (deletedColumns, stepPos) {
						return safeDeletedColumnStepImpact(
							deletedColumns,
							$scope.steps,
							stepPos
						);
					};

					var checkDeletedColumns = function (
						newPartialStep,
						type,
						saveAndClose,
						newColumns,
						onDeleteFailure
					) {
						let stepPos = $scope.selectedStep
							? $scope.steps.indexOf($scope.selectedStep)
							: 0;

						let partialSteps;
						let oldPartialSteps;
						if (stepPos > 0 && newPartialStep) {
							partialSteps = angular.copy($scope.steps.slice(0, stepPos));
							oldPartialSteps = angular.copy($scope.steps.slice(0, stepPos));
							partialSteps.push(newPartialStep);
							oldPartialSteps.push($scope.oldPartielStep);
						} else if (newPartialStep) {
							partialSteps = [newPartialStep];
							oldPartialSteps = [$scope.oldPartielStep];
						}

						let oldSteps = $scope.oldSteps;
						type.db_id = vm.datablock.source_id;
						type.all_steps = oldSteps;
						type.new_steps = partialSteps;
						type.old_steps = oldPartialSteps;
						type.step_index = stepPos;
						type.columns = vm.datablock.grammar.columns;
						if (type.in_columns) {
							type.new_columns = newColumns;
						}

						if (type.step_delete) {
							type.step_index = $scope.stepToDeleteIndex;
							type.all_steps = $scope.steps;
							type.new_steps = angular.copy(
								$scope.steps.slice(0, $scope.stepToDeleteIndex)
							);
							type.old_steps = angular.copy(
								$scope.steps.slice(0, $scope.stepToDeleteIndex + 1)
							);
						}
						DataBlocksService.getImpact(type).then(function (response) {
							let safeDeletedColumns = [];
							if (response.data[0]) {
								for (var i in response.data) {
									if (
										response.data[i].impacts[0] ||
										!_.isEmpty(response.data[i].linked_elements)
									) {
										response.data[i].impact = true;
										vm.widgetData.dbStepImpactAllSteps = $scope.steps;
										vm.widgetData.dbStepImpactCurrentStep = $scope.selectedStep
											? stepPos
											: undefined;
										vm.widgetData.dbStepImpactValidateAction = saveAndClose;
										vm.widgetData.dbStepImpactAddNewStep = function (
											inputCols
										) {
											addStep(inputCols);
										};
										vm.widgetData.dbImpacts = response.data;
										vm.widgetData.showDbStepImpact = true;
										if (onDeleteFailure) {
											onDeleteFailure();
										}
										return;
									} else {
										safeDeletedColumns.push(response.data[i].column_uuid);
									}
								}
							}
							safeDeletedColumnImpact(
								safeDeletedColumns,
								$scope.selectedStep
									? $scope.steps.indexOf($scope.selectedStep)
									: undefined
							);
							saveAndClose();
						});
					};

				 $scope.chunkAlias = function(alias) {
				  if(alias == null) {
				  	return;
				  }
					const numChunks = Math.ceil(alias.length / vm.MAXSIZE);
					$scope.chunkedAlias = new Array(numChunks);

					for (let i = 0, o = 0; i < numChunks; ++i, o += vm.MAXSIZE) {
						$scope.chunkedAlias[i] = alias.substr(o, vm.MAXSIZE);
					}
				}

					/**
					 * Remove keys with undefined null, empty values or present in keysToRemove
					 * @param obj
					 * @param keysToRemove
					 * @returns {unknown[]}
					 */
					function cleanStepObject(obj, keysToRemove) {
						return _.transform(obj, function (result, value, key) {
							if (_.includes(keysToRemove, key)) {
								return;
							}
							if (_.isObject(value)) {
								result[key] = cleanStepObject(value, keysToRemove); // Recurse into nested objects
							} else if (!(_.isNull(value) || _.isUndefined(value) || value === '')) {
								result[key] = value; // Keep non-null, non-undefined, non-empty
							}
						});
					}
				},
		];

		return buildDirectiveReturnObject(
			{
				data: '=',
				datablock: '=',
				widgetData: '=',
				enableAddSteps: '=',
				referencedElements: '=',
			},
			controller,
			'./src/components/directives/dataOperationTools/datablockBuilder/datablockBuilder.html',
			function postLink(scope, element, attrs) {
				$(document).on(
					'click',
					'#step-tag-container .dropdown-menu',
					function (e) {
						e.stopPropagation();
					}
				);
				if (!scope.vm.datablock.steps) {
					scope.vm.datablock.steps = [];
				}
				scope.steps = scope.vm.datablock.steps;
				for (var s in scope.steps) {
					if (scope.containsDatablockLink(s)) {
						scope.steps[s].showLink = true;
					}
					if (scope.containsDatablockUnion(s)) {
						scope.steps[s].showUnion = true;
					}
					if (scope.containsFormula(s)) {
						scope.steps[s].showFormula = true;
					}
					if (scope.containsFilter(s)) {
						scope.steps[s].showFilter = true;
					}
					if (scope.containsOperation(s)) {
						scope.steps[s].showOperation = true;
					}
					if (scope.containsAgregation(s)) {
						scope.steps[s].showAgregation = true;
					}
					if (scope.containsSort(s)) {
						scope.steps[s].showSort = true;
					}
					if (scope.steps[s].output && scope.steps[s].output[0]) {
						scope.steps[s].showOutput = true;
					}
					scope.setSummaryConfigData(scope.steps[s]);
				}
				scope.setInitSummaryConfigData();
			}
		);
	},
	]);
})();
