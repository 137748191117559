(function () {
	'use strict';

	angular.module('dcApp').directive('hdhElementGraph', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$timeout',
				function ($log, $scope, $timeout) {
					var vm = this;
					$scope.graphData = {};

					$scope.init = function () {
						$log.debug('init in hdh element graph');
						$scope.graphData.centeredElementLabel =
							vm.widgetData.centeredElementLabel;
						$scope.graphData.centeredElementType =
							vm.widgetData.centeredElementType;
						$scope.graphData.centeredElementId =
							vm.widgetData.centeredElementId;
						$scope.graphData.axe = vm.widgetData.axe;
						$scope.graphData.disableGlobalGraph = true;
						$scope.graphData.showAxes = false;
						$timeout(function () {
							$scope.showGraph = true;
						}, 200);
					};

					$scope.closeMe = function () {
						vm.widgetData.showHdhElementGraph = false;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/hdhElementGraph/hdhElementGraph.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});

					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showHdhElementGraph = false;
						});
						scope.showGraph = false;
					});

					scope.$watch(
						function () {
							return scope.vm.widgetData.showHdhElementGraph;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
								scope.init();
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
