(function () {
	'use strict';

	angular.module('dcApp').factory('UserGroupService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'GroupAdapter',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, GroupAdapter) {
			var UserGroupService = {};

			UserGroupService.getGroups = function () {
				const deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_GET_ALL');
				$http.get(url).then(function (response) {
					deferred.resolve(GroupAdapter.generateGroupList(response.data));
				});
				return deferred.promise;
			};

			UserGroupService.getCurrentUserGroups = function () {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('USER_GROUP');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserGroupService.getGroupsByUser = function (userId) {
				const deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_BY_USER');
				url = url.replace('{userId}', userId);
				$http.get(url).then(function (response) {
					deferred.resolve(response);
				});
				return deferred.promise;
			};

			UserGroupService.getGroup = function (groupId) {
				const deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_GET');
				url = url.replace('{id}', groupId);
				$http.get(url).then(function (response) {
					deferred.resolve(GroupAdapter.generateGroup(response.data));
				});
				return deferred.promise;
			};

			UserGroupService.createGroup = function (group) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_CREATE');
				$http.post(url, group).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserGroupService.editGroup = function (groupId, group) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_EDIT');
				url = url.replace('{id}', groupId);
				$http.put(url, group).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserGroupService.addUser = function (groupId, userId) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_MEMBERS_ADD');
				url = url.replace('{id}', groupId);
				$http.put(url, userId).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject($q.reject(err));
					}
				);
				return deferred.promise;
			};

			UserGroupService.removeUser = function (groupId, userId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_MEMBERS_DELETE');
				url = url.replace('{id}', groupId);
				url = url.replace('{userId}', userId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						$q.reject(err);
					}
				);
			};

			UserGroupService.getMembers = function (groupId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_MEMBERS_GET');
				url = url.replace('{id}', groupId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserGroupService.getAvailableUsers = function (groupId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_AVAILABLE_USERS');
				if (groupId !== null && groupId !== undefined) {
					url = url + '?groupId=' + groupId;
				}
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserGroupService.deleteGroup = function (groupId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_DELETE');
				url = url.replace('{id}', groupId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			UserGroupService.deleteBulkGroups = function (groupIds) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('GROUPS_DELETE_BULK');
				if (Array.isArray(groupIds)) {
					url = url + '?groupsId=' + groupIds[0];
					for (let i = 1; i < groupIds.length; i++) {
						url = url + '&groupsId=' + groupIds[i];
					}
				} else {
					url = url + '?groupsId=' + groupIds;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			return UserGroupService;
		},
	]);
})();
