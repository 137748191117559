// Return column name as COLL'index' for a given label
var getCorrespendingCol = function (label, cols) {
	var column = null;
	for (var c = 0; c < cols.length; c++) {
		if (cols[c].column_alias == label) {
			column = 'COLL' + c;
		}
	}

	return column != null ? column : label;
};

var isFunctionBraketsAreClosed = function (functionStr) {
	return countChar('(', functionStr) / countChar(')', functionStr) == 1;
};

var countChar = function (char, string) {
	var newString = string.replace(/(')(?:(?=(\\?))\2.)*?\1/g, '');
	return newString.split(char).length;
};

// Split string in function format
var splitStatementByExternalCommas = function (statement) {
	statement += ','; // so I don't have to handle the "last, leftover
	// parameter"
	var results = [];
	var chars = statement.split('');
	var level = 0; // levels of parenthesis, used to track how deep I am in ().
	var index = 0; // determines which parameter am I currently on.
	var temp = '';

	// this is somewhat like your implementation in the edits, I walk through
	// the characters one by one, and do something extra if it's a special
	// character.r
	chars.forEach(function (char) {
		switch (char) {
			case '(':
				temp += char;
				level++;
				break;
			case ')':
				temp += char;
				level--;
				break;
			case ',':
				// if the comma is between a set of parenthesis, ignore.
				if (level !== 0) {
					temp += char;
				}
				// if the comma is external, split the string.
				else {
					results[index] = temp;
					temp = '';
					index++;
				}
				break;
			default:
				temp += char;
				break;
		}
	});
	return results;
};

// Return param value without spec chars
var getParamTypeAndremoveSpecCarc = function (param, columns, scope) {
	var newParam = {
		value: param,
		type: '',
	};
	if (param.startsWith("'")) {
		newParam.type = 'value';
		newParam.value = param.replace(/'/g, '');
	} else {
		if (/^COLL\d{1,}$/.test(newParam.value)) {
			newParam.type = 'column';
		} else {
			newParam.type = 'value';
			if (scope) {
				scope.reGenerateDisplayedformula = true;
			}
		}
	}

	return newParam;
};

// FIXME: no longer used
var getfunctionStructure = function (func, columns, scope) {
	var functionStr = func.toString();
	if (!isFunctionBraketsAreClosed(functionStr)) {
		throw new UserException('Check if all opend brakets are closed correctly');
	}
	var formmatedFunction = {
		function_name: functionStr.substr(0, functionStr.indexOf('(')),
		params: [],
		value: null,
		type: 'function',
	};

	var args = functionStr.match(/\b[^()]+\((.*)\)\s{0,}$/)[1];
	var params = [];
	var isFunctionPattern = /\b[^()]+\(/;
	params = splitStatementByExternalCommas(args);
	for (var i = 0; i < params.length; i++) {
		var elem = params[i].trim();
		elem = elem.replace(/^\s{0,},\s{0,}/g, '');
		if (isFunctionPattern.test(elem)) {
			var extractedFunction = getfunctionStructure(elem, columns, scope);
			formmatedFunction.params.push(extractedFunction);
		} else if (!elem.startsWith('(')) {
			var paraTypeAndReplaced = getParamTypeAndremoveSpecCarc(
				elem,
				columns,
				scope
			);
			var extractedParam = {
				function_name: null,
				params: [],
				value: paraTypeAndReplaced.value,
				type: paraTypeAndReplaced.type,
			};
			formmatedFunction.params.push(extractedParam);
		} else {
			throw new UserException('Function invalide: ' + elem);
		}
	}

	return formmatedFunction;
};

function prepareCatalogFormula(formula, defaultValue) {
	for (let i in formula.params) {
		if (formula.params[i].type == 'function') {
			prepareCatalogFormula(formula.params[i], defaultValue);
		} else if (
			formula.params[i].type == 'column' ||
			formula.params[i].type == 'carac'
		) {
			formula.params[i].value = defaultValue;
			formula.params[i].type = 'value_template';
		} else if (formula.params[i].type == 'value_template') {
			formula.params[i].value = defaultValue;
			formula.params[i].type = 'value';
		}
	}
}

let formulaCatalogListCols = function (translateMap, lookupTagDataSource) {
	return [
		{
			caption: translateMap['Libellé'],
			dataField: 'label',
			width: '25%',
			allowReordering: false,
			alignment: 'center',
		},
		{
			dataField: 'tags',
			caption: translateMap['Tag'],
			cellTemplate: 'tagTemplate',
			allowHeaderFiltering: true,
			allowFiltering: false,
			enableCellEdit: false,
			width: '15%',
			alignment: 'center',
			lookup: {
				dataSource: lookupTagDataSource,
			},
			calculateFilterExpression: function (
				filterValue,
				selectedFilterOperation
			) {
				if (!filterValue) return;
				return function (rowData) {
					return _.indexOf(rowData.tagAsArray, filterValue) != -1;
				};
			},
		},
		{
			caption: translateMap['Description'],
			dataField: 'description',
			width: '18%',
			alignment: 'center',
		},
		{ dataField: 'type', caption: translateMap['Type'], cellTemplate: 'type' },
		{
			dataField: 'created',
			caption: translateMap['Crée le'],
			dataType: 'date',
		},
		{
			dataField: 'created_by',
			caption: translateMap['Utilisateur'],
			cellTemplate: 'createdByUser',
		},
		{
			dataField: 'shared',
			caption: translateMap['Partagé'],
			width: '13%',
			cellTemplate: 'sharedSwitch',
		},
		{
			caption: translateMap['Actions'],
			alignment: 'center',
			cellTemplate: 'actions',
			fixed: true,
			fixedPosition: 'right',
			width: '180px',
		},
	];
};
