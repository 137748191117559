var usedColumnsDeleted = function (newColumns, oldColumns, steps) {
	for (var c in newColumns) {
		var cols = _.filter(oldColumns, function (col) {
			return col.uuid == newColumns[c].uuid;
		});
		if (!vals || !vals[0]) {
		}
	}
};

var columnUsedInStep = function (uuid, step) {
	var columnUsing = { links: [], unions: [], formulas: [], partitions: [] };
	//link
	if (step.links) {
		for (var l in step.links) {
			for (var m in step.links[l].mappings) {
				if (step.links[l].mappings[m].left_column_uuid == uuid) {
					columnUsing.links.push(step.links[l].lib);
					break;
				}
			}
		}
	}

	//union
	if (step.links && step.unions[0]) {
		for (var l in step.unions) {
			for (var m in step.unions[l].mappings) {
				if (step.unions[l].mappings[m].left_column_uuid == uuid) {
					columnUsing.unions.push(step.unions[l].lib);
					break;
				}
			}
		}
	}

	//formulas

	//filter
	if (step.filter && filterContainsColumn(uuid, step.filter)) {
		columnUsing.filter = true;
	}

	//stack / explode
	if (
		step.data_operation &&
		step.data_operation.explode &&
		step.data_operation.explode &&
		step.data_operation.explode.columns
	) {
		for (var c in step.data_operation.explode.columns) {
			if (step.data_operation.explode.columns[c].column_uuid == uuid) {
				columnUsing.explode = true;
				break;
			}
		}
	}
	if (
		step.data_operation &&
		step.data_operation.explode &&
		step.data_operation.stack &&
		step.data_operation.stack.columns
	) {
		for (var c in step.data_operation.explode.columns) {
			if (step.data_operation.stack.columns[c].column_uuid == uuid) {
				columnUsing.stack = true;
				break;
			}
		}
	}

	//agregations
	if (step.agregation && step.agregation.groups && step.agregation.groups[0]) {
		for (var c in step.agregation.groups) {
			if (step.agregation.groups[c].column_uuid == uuid) {
				columnUsing.agregation = true;
				break;
			}
		}
	}

	if (
		step.agregation &&
		step.agregation.functions &&
		step.agregation.functions[0]
	) {
		for (var c in step.agregation.functions) {
			if (step.agregation.functions[c].column_uuid == uuid) {
				columnUsing.agregation = true;
				break;
			}
		}
	}

	if (
		step.agregation &&
		step.agregation.partitions &&
		step.agregation.partitions[0]
	) {
		for (var c in step.agregation.partitions) {
			if (step.agregation.partitions[c].groups) {
				var exists = false;
				for (var g in step.agregation.partitions[c].groups) {
					if (step.agregation.partitions[c].groups[g].column_uuid == uuid) {
						columnUsing.partitions.push(step.agregation.partitions[c].lib);
						exists = true;
						break;
					}
				}
				if (exists) {
					break;
				}
			}
			if (step.agregation.partitions[c].functions) {
				var exists = false;
				for (var g in step.agregation.partitions[c].functions) {
					if (step.agregation.partitions[c].functions[g].column_uuid == uuid) {
						columnUsing.partitions.push(step.agregation.partitions[c].lib);
						exists = true;
						break;
					}
				}
				if (exists) {
					break;
				}
			}
			if (step.agregation.partitions[c].sorts) {
				var exists = false;
				for (var g in step.agregation.partitions[c].sorts) {
					if (step.agregation.partitions[c].sorts[g].column_uuid == uuid) {
						columnUsing.partitions.push(step.agregation.partitions[c].lib);
						exists = true;
						break;
					}
				}
				if (exists) {
					break;
				}
			}
		}
	}
};

var columnUsedInStep = function (uuid, step) {
	//link
	//formulas
	//filter
	//stack / explode
	//agregations
	//sorts
	//output
};

var filterContainsColumn = function (uuid, rule) {
	if (rule.rules == undefined || rule.rules.length == 0) {
		if (rule.column_alias == 'COL' + uuid) {
			return true;
		}
	} else {
		for (var r in rule.rules) {
			var exists = filterContainsColumn(uuid, rule.rules[r]);
			if (exists) {
				return true;
			}
		}
		return false;
	}
};

var stepContainsAgregation = function (step) {
	return (
		step.agregation &&
		((step.agregation.groups && step.agregation.groups[0]) ||
			(step.agregation.functions && step.agregation.functions[0]) ||
			(step.agregation.partitions && step.agregation.partitions[0]))
	);
};

var stepContainsSort = function (step) {
	return step.sort && step.sort.sorts && step.sort.sorts[0];
};
