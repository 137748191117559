(function () {
	angular.module('dcApp').factory('CommonServices', [
		'$rootScope',
		'$http',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, API_BASE_URL_BACKEND) {
			var CommonServices = {},
				endPoint = API_BASE_URL_BACKEND;

			CommonServices.getDateMasks = function () {
				return $http.get(endPoint + 'date_masks').then(function (response) {
					return response;
				});
			};

			CommonServices.getDateReadingMasks = function () {
				return $http
					.get(endPoint + 'date_reading_masks')
					.then(function (response) {
						return response;
					});
			};

			CommonServices.getServerTimezone = function () {
				return $http.get(	API_BASE_URL_BACKEND + $rootScope.getUrl('TIMEZONE_SERVER')).then(function (response) {
					return response;
				});
			};

			CommonServices.getTimezones = function () {
				return $http.get(	API_BASE_URL_BACKEND + $rootScope.getUrl('TIMEZONE')).then(function (response) {
					return response;
				});
			};

			CommonServices.getCharsets = function () {
				return $http
					.get(API_BASE_URL_BACKEND + 'charsets')
					.then(function (response) {
						return response;
					});
			};

			CommonServices.validateFileByReadingMaskGlob = function (
				fileName,
				readingMask
			) {
				return $http
					.get(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('EXPRESS_INTEGRATION_VALIDATE_FILE') +
							'?fileName=' +
							fileName +
							'&readingMask=' +
							readingMask
					)
					.then(function (response) {
						return response;
					});
			};

			CommonServices.isEmpty = function (value) {
				return value === undefined || value === null || value === '';
			};

			return CommonServices;
		},
	]);
})();
