(function () {
	'use strict';

	angular.module('dcApp').constant('validateLength', {
		maxLength: 50,
		minLength: 0,
	});

	angular.module('dcApp').directive('exportModal', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$timeout',
				'ProjectService',
				'DataBlocksService',
				'$rootScope',
				'$stateParams',
				'validateLength',
				'elem',
				'PROJECT_PROGRESS_OPERATION',
				'PROGRESS_STATUS',
				'PROJECT_OPERATION_DONE',
				'gettextCatalog',
				function (
					$log,
					$scope,
					$timeout,
					ProjectService,
					DataBlocksService,
					$rootScope,
					$stateParams,
					validateLength,
					elem,
					PROJECT_PROGRESS_OPERATION,
					PROGRESS_STATUS,
					PROJECT_OPERATION_DONE,
					gettextCatalog
				) {
					var vm = this;
					let execId;
					vm.MAXLENGTH = validateLength.maxLength;
					vm.MINLENGTH = validateLength.minLength;
					vm.elem = elem;
					vm.closeModal = closeModal;
					vm.init = init;
					vm.projectInfos = {};
					vm.exportProjectInfos = {};
					vm.duplicatedProjectInfos = {};
					vm.confirmationData = {};
					vm.exportedProjectInfos = {};
					vm.terms = {};
					vm.progress = {};
					vm.from = 'export';
					vm.exportProject = exportProject;
					vm.exportOperation = PROJECT_PROGRESS_OPERATION.export;
					vm.exportAnalysisOperation =
						PROJECT_PROGRESS_OPERATION.export_analysis;
					vm.downloadLink = {};
					vm.downloadOn = false;
					vm.downloadFile = downloadFile;
					vm.cancelExport = cancelExport;

					function init() {
						vm.progress = {
							countOps: 1,
							projectLabel: vm.pLabel,
							operation: vm.exportAnalysisOperation,
							progressBarInitValue: null,
							running: true,
						};
						vm.exportInProgress = true;
						$rootScope.$broadcast('bulk_status_progress', vm.progress);
						if (!vm.projectId) {
							return;
						}
						ProjectService.usesPublicElements(vm.projectId).then(function (
							responseConsumed
						) {
							if (!responseConsumed.data) {
								ProjectService.getStats(vm.projectId).then(function (data) {
									vm.projectStats = data.stats;
									vm.projectInfos = {
										currentUser: data.currentUser,
										projectLabel: data.projectLabel,
										projectDescription: data.projectDescription,
										projectVersion: data.projectVersion,
									};
									DataBlocksService.getExposedDataBlocks(
										$stateParams.projectId
									).then(
										function (responseExpo) {
											vm.expositions = responseExpo;
											vm.isExposed = checkExpositionStatus(vm.expositions);
											ProjectService.getExportUserAgreement().then(function (
												responseTerms
											) {
												let dataTerms = responseTerms.data;
												let code = _.keys(dataTerms)[0];
												vm.terms = {
													type: code,
													text: dataTerms[code],
													accepted: false,
												};
												// keep analyse screen shown for at least 2s
												$timeout(function () {
													vm.exportInProgress = false;
												}, 2000);
											});
										},
										function (e) {
											$log.error(e);
										}
									);
								});
							} else {
								// keep analyse screen shown for at least 2s
								$timeout(function () {
									$rootScope.$broadcast('bulk_status', {
										status: PROGRESS_STATUS.failed,
									});
								}, 2000);
							}
						});
					}

					function exportProject() {
						let options = {
							file_name: vm.exportedProjectInfos.exportProjectFileName,
							elements: {
								connector: vm.elementTypes.connector.checked,
								export_template: vm.elementTypes.exportTemplate.checked,
								depot: vm.elementTypes.repository.checked,
								entity: vm.elementTypes.entity.checked,
								datablock: vm.elementTypes.datablock.checked,
								hd_element: vm.elementTypes.hdElements.checked,
								dashboard: vm.elementTypes.dashboard.checked,
								extraction: vm.elementTypes.extractRepository.checked,
								local_data: vm.elementTypes.localData.checked,
							},
							metadata: {
								with_members: vm.exportedProjectInfos.membersAndGroups,
								with_template_permissions:
									vm.exportedProjectInfos.templateRightValue,
							},
							data_exposition: {
								datablocks: vm.duplicatedExpositions
									? vm.duplicatedExpositions
									: [],
							},
							user_agreements: [vm.terms],
						};

						let elementsGroupedByCheck = _.countBy(
							options.elements,
							function (e) {
								return e ? 'checked' : 'notChecked';
							}
						);
						let countElements = elementsGroupedByCheck.checked;

						if (vm.elementTypes.entity.checked) {
							countElements = countElements + 1;
						}

						vm.progress = {
							countOps: countElements + 6,
							projectLabel: vm.projectInfos.projectLabel,
							operation: vm.exportOperation,
							progressBarInitValue: null,
							running: true,
						};
						$rootScope.$broadcast('bulk_status_progress', vm.progress);
						vm.exportInProgress = true;

						// Post order and get exec id
						ProjectService.exportProject(vm.projectId, options)
							.then(function (responseExp) {
								execId = responseExp.data;
								vm.progress.progressBarInitValue = 0;
								vm.progress.execId = execId;
								$rootScope.$broadcast('bulk_status_progress', vm.progress);
							})
							.catch(function (error) {
								vm.exportInProgress = false;
							});
					}

					function closeModal() {
						vm.exportInProgress = false;
						vm.downloadOn = false;
						vm.projectInfos = {};
						vm.exportProjectInfos = {};
						vm.duplicatedProjectInfos = {};
						vm.exportedProjectInfos = {};
						vm.elementTypes = {};
						vm.from = 'export';
						vm.progress = {
							countOps: 1,
							projectLabel: vm.pLabel,
							operation: vm.exportAnalysisOperation,
							progressBarInitValue: null,
						};
						$rootScope.$broadcast('bulk_status_progress', vm.progress);
						$(vm.element).modal('hide');
						vm.data = false;
					}

					vm.doNotDuplicateExposures = function () {
						vm.duplicateExpose = vm.duplicateStatus.status.doNotduplicate;
					};

					vm.duplicateExposures = function () {
						vm.duplicateExpose = vm.duplicateStatus.status.duplicate;
					};

					function downloadFile() {
						if (vm.downloadLink && vm.downloadOn) {
							let downloadUrl = ProjectService.downloadExportArchive(
								vm.downloadLink.execId,
								vm.downloadLink.link
							);
							window.open(downloadUrl, '_blank');
							closeModal();
						}
					}

					function cancelExport() {
						if (!vm.downloadOn && execId && vm.projectId) {
							ProjectService.cancelExportProject(vm.projectId, execId).then(
								function () {
									closeModal();
								}
							);
						} else {
							closeModal();
						}
					}

					vm.showConfirmationExport = function () {
						vm.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString('launch.export');
						vm.confirmationData.confirmationDialogMessage =
							gettextCatalog.getString('confirm.export.message');
						vm.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('confirm.export.action');
						vm.confirmationData.confirmationGreenAction = true;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.showConfirmationDialog = true;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.confirmDialogAction = vm.exportProject;
						vm.confirmationData.showConfirmationDialog = true;
					};

					vm.showCancelConfirmation = function () {
						vm.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString('cancel.export');
						vm.confirmationData.confirmationDialogMessage =
							gettextCatalog.getString('confirm.cancel.message');
						vm.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('yes.cancel.action');
						vm.confirmationData.cancelDialogActionName =
							gettextCatalog.getString('no.cancel.action');
						vm.confirmationData.confirmationGreenAction = false;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.showConfirmationDialog = true;
						vm.confirmationData.enableConfirmDialogAction = true;
						vm.confirmationData.confirmDialogAction = vm.cancelExport;
						vm.confirmationData.showConfirmationDialog = true;
					};

					/**
					 *Private functions
					 */
					function checkExpositionStatus(expositions) {
						return expositions.length > 0;
					}

					$scope.$on(PROJECT_OPERATION_DONE.export, function (event, data) {
						vm.downloadLink = data;
						vm.downloadOn = true;
					});
				},
			];
			return buildDirectiveReturnObject(
				{ projectId: '=', data: '=', pLabel: '=' },
				controller,
				'./src/components/directives/exportProject/exportProjectModal.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data;
						},
						function () {
							scope.vm.init();
						},
						false
					);
				}
			);
		},
	]);
})();
