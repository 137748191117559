(function () {
	'use strict';

	angular.module('dcApp').factory('frameService', [
		'$rootScope',
		'$http',
		'$window',
		'$q',
		'API_BASE_URL_BACKEND',
		'CTX_VAR_NAME',
		'FRAME_OPERATION_EVENT',
		function (
			$rootScope,
			$http,
			$window,
			$q,
			API_BASE_URL_BACKEND,
			CTX_VAR_NAME,
			FRAME_OPERATION_EVENT
		) {
			var FrameService = {};
			var dataFrameEndPoint = API_BASE_URL_BACKEND + 'dataframes/';

			FrameService.getStatus = function (code) {
				const url = dataFrameEndPoint + code + '/status';
				return $http.get(url).then(
					function (response) {
						const data = response.data;
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.persist = function (code) {
				const url = dataFrameEndPoint + code + '/persist';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.repersist = function (code) {
				const url = dataFrameEndPoint + code + '/repersist';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.cache = function (code) {
				const url = dataFrameEndPoint + code + '/cache';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.recache = function (code) {
				const url = dataFrameEndPoint + code + '/recache';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.unpersist = function (code) {
				const url = dataFrameEndPoint + code + '/unpersist';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.unpersistMany = function (codes) {
				var deferred = $q.defer();
				const url = dataFrameEndPoint + 'unpersist_many';
				$http.post(url, codes).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			FrameService.uncache = function (code) {
				const url = dataFrameEndPoint + code + '/uncache';
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.getPersistenceSize = function (code) {
				const url = dataFrameEndPoint + code + '/persist/size';
				return $http.get(url).then(
					function (response) {
						const data = response.data;
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			FrameService.broadcastOperationResponse = function (code, message) {
				$rootScope.$broadcast(FRAME_OPERATION_EVENT, {
					code: code,
					data: message,
				});
			};

			return FrameService;
		},
	]);
})();
