(function () {
	'use strict';

	angular.module('dcApp').directive('help', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$window',
				'toaster',
				'gettextCatalog',
				function ($scope, $rootScope, $window, toaster, gettextCatalog) {
					let docUrls = [
						'AGREGATION',
						'CHART_BAR_CREATE',
						'CHART_BAR_EDIT',
						'CHART_PIE_CREATE',
						'CHART_PIE_EDIT',
						'CHART_GAUGE_CREATE',
						'CHART_GAUGE_EDIT',
						'CHART_FUNNEL_CREATE',
						'CHART_FUNNEL_EDIT',
						'CHART_SANKEY_CREATE',
						'CHART_SANKEY_EDIT',
						'CHART_TREEMAP_CREATE',
						'CHART_TREEMAP_EDIT',
						'CONNECTOR_CREATE',
						'CONNECTOR_EDIT',
						'DASHBOARD_CREATE',
						'DASHBOARD_EDIT',
						'DATABLOCK_CREATE',
						'DATABLOCK_EDIT',
						'DATABLOCK_EXPORT',
						'DATABLOCK_REST',
						'DEPOT_CREATE',
						'DEPOT_EDIT',
						'ENTITY_CREATE',
						'ENTITY_EDIT',
						'EXPRESS_INTEGRATION',
						'FORMULAS',
						'GRID_CREATE',
						'GRID_EDIT',
						'JOBS',
						'MAP_CREATE',
						'MAP_EDIT',
						'MEDIA_IMAGE_CREATE',
						'MEDIA_IMAGE_EDIT',
						'MEDIA_PDF_CREATE',
						'MEDIA_PDF_EDIT',
						'PICTOGRAMME',
						'SEARCH',
						'TAG',
						'TIMELINE_CREATE',
						'TIMELINE_EDIT',
					];

					$scope.goToHelp = function () {
						let url = docUrls.includes($scope.vm.helpCode);
						if (url) {
							$window.open('/doc/dc_funct/' + $scope.vm.helpCode, '_blank');
						} else {
							toaster.pop(
								'error',
								gettextCatalog.getString('Erreur'),
								gettextCatalog.getString('Documentation indisponible')
							);
						}
					};

					$scope.getStyle = function () {
						return {
							color: $scope.vm.helpColor
								? $scope.vm.helpColor
								: 'var(--ag-grey2)',
						};
					};
				},
			];
			return buildDirectiveReturnObject(
				{ helpCode: '=', helpTitle: '=', helpText: '=', helpColor: '=' , textOnly: '=' },
				controller,
				'./src/components/directives/help/help.html'
			);
		},
	]);
})();
