(function () {
	'use strict';

	angular.module('dcApp').directive('valuePreview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$timeout',
				function ($scope, $timeout) {
					$scope.getShowValuePopup = function (val, element) {
						$scope.cellValue = val;
						$scope.cellBrutValue = val;
						$scope.isCellValueJson = false;
						let parsedValue;
						try {
							parsedValue = JSON.parse(val);
							$scope.isCellValueJson = true;
						} catch (e) {
							$scope.isCellValueJson = false;
						}
						$scope.cellValue = $scope.isCellValueJson
							? parsedValue
							: $scope.cellValue;
						$scope.copiedToClipboard = false;
						$(element).modal({ backdrop: 'static', keyboard: false }, 'show');
					};

					$scope.copyToClipboard = function () {
						let $temp_input = $('<input>');
						$('body').append($temp_input);
						$temp_input.val($scope.cellBrutValue).select();
						document.execCommand('copy');
						$temp_input.remove();
						$scope.copiedToClipboard = true;
						$timeout(function () {
							$scope.copiedToClipboard = false;
						}, 600);
					};
				},
			];
			return buildDirectiveReturnObject(
				{ showValuePreview: '=', value: '=' },
				controller,
				'./src/components/directives/valuePreview/valuePreview.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showValuePreview = false;
						$(element).on('hidden.bs.modal', function () {
							scope.$apply(function () {
								scope.vm.showValuePreview = false;
							});
						});
					});
					scope.$watch(
						function () {
							return scope.vm.showValuePreview;
						},
						function (value) {
							if (value == true) {
								scope.getShowValuePopup(scope.vm.value, element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				}
			);
		},
	]);
})();
