(function () {
	'use strict';

	angular.module('dcApp').directive('datablockExpose', [
		'$parse',
		function ($parse) {
			let controller = [
				'$rootScope',
				'$scope',
				'$sce',
				'DataBlocksService',
				'GenericService',
				'UserService',
				'$filter',
				'toaster',
				'gettextCatalog',
				'PermissionService',
				'PAGINATIONS_SIZES',
				function (
					$rootScope,
					$scope,
					$sce,
					DataBlocksService,
					GenericService,
					UserService,
					$filter,
					toaster,
					gettextCatalog,
					PermissionService,
					PAGINATIONS_SIZES
				) {
					let vm = this;

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let permissionFilterGridInstance;
					let toLowerCase = false;
					let toUpperCase = false;
					let permissionFilterToDelete;
					let permissionFilterGridOptions = getDefaultDxGridConfig(
						10,
						PAGINATIONS_SIZES
					);
					permissionFilterGridOptions.columns = [
						{
							caption: gettextCatalog.getString('Libellé'),
							dataField: 'label',
						},
						{
							caption: gettextCatalog.getString('Description'),
							dataField: 'description',
						},
						{
							caption: gettextCatalog.getString('Actif'),
							dataField: 'active',
							width: '100px',
						},
						{
							caption: gettextCatalog.getString('Actions'),
							cellTemplate: 'actions',
							width: '110px',
						},
					];
					permissionFilterGridOptions.onInitialized = function (e) {
						permissionFilterGridInstance = e.component;
					};

					vm.permissionFilterGridOptions = permissionFilterGridOptions;

					vm.clearSearch = clearSearch;
					vm.getPermissionFilters = getPermissionFilters;
					vm.openAddEditFilter = openAddEditFilter;
					vm.savePermissionFilter = savePermissionFilter;
					vm.openApplyFilter = openApplyFilter;
					vm.applyFilterToUsers = applyFilter;
					vm.addSelectedUserToApplied = addSelectedUserToApplied;
					vm.removeUser = removeUser;
					vm.closeMe = closeMe;
					vm.doShowDeleteFilter = doShowDeleteFilter;
					vm.searchExposedAvailableColumns = searchExposedAvailableColumns;
					vm.permissionFilterDef = {};
					vm.permissionFilterDefUsers = {};
					vm.newFilter = true;
					vm.projectUsers = [];
					vm.filteredProjectUsers = [];
					vm.permissionFilterToApply = {};
					vm.usersNames = {};
					vm.filteredExposedAvailableColumns = [];
					vm.filterInformation = [
						{ name: 'Select', link: gettextCatalog.getString('select.link') },
						{ name: 'Filter', link: gettextCatalog.getString('filter.link') },
					];
					const SERVICE_DATA_PATH = "/service/data";
					vm.dataPath = SERVICE_DATA_PATH;

					function clearSearch() {
						vm.searchText = '';
					}

					function getPermissionFilters() {
						let data = $scope.dataExposeParams.permission_filters
							? $scope.dataExposeParams.permission_filters
							: [];
						if (
							permissionFilterGridInstance &&
							permissionFilterGridInstance.option
						) {
							permissionFilterGridInstance.option('dataSource', data);
							permissionFilterGridInstance.refresh();
						}
						clearSearch();
					}

					function searchExposedAvailableColumns() {
						vm.filteredExposedAvailableColumns = _.filter(
							$scope.availableColumns,
							function (item) {
								return (
									item.lib
										.toLowerCase()
										.indexOf(vm.searchText.toLowerCase()) !== -1 && item.active
								);
							}
						);
					}

					function openAddEditFilter(modalId, data) {
						if (data !== undefined) {
							vm.permissionFilterDef = angular.copy(data);
							vm.newFilter = false;
						} else {
							vm.permissionFilterDef = { active: true };
							vm.newFilter = true;
						}
						vm.searchExposedAvailableColumns();
						$('#' + modalId).modal('show');
					}

					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});

					vm.helpPopoverSyntax = {
						target: '#help-example-syntax',
						showEvent: 'dxclick',
						position: 'bottom',
						width: 600,
						left: -285,
						top: 40,
						animation: {
							show: {
								type: 'pop',
								from: { scale: 0 },
								to: { scale: 1 },
							},
							hide: {
								type: 'fade',
								from: 1,
								to: 0,
							},
						},
						bindingOptions: {
							visible: 'visibleMetaData',
						},
					};

					function savePermissionFilter(modalId) {
						if (vm.permissionFilterDef.id) {
							DataBlocksService.editExposePermissionFilter(
								vm.datablockId,
								vm.permissionFilterDef.id,
								vm.permissionFilterDef
							).then(function (responseEdit) {
								for (let i in $scope.dataExposeParams.permission_filters) {
									if (
										$scope.dataExposeParams.permission_filters[i].id ===
										vm.permissionFilterDef.id
									) {
										$scope.dataExposeParams.permission_filters[i] =
											angular.copy(responseEdit.data);
										break;
									}
								}
								if (
									permissionFilterGridInstance &&
									permissionFilterGridInstance.option
								) {
									permissionFilterGridInstance.option(
										'dataSource',
										$scope.dataExposeParams.permission_filters
									);
									permissionFilterGridInstance.refresh();
								}
								toaster.pop(
									'success',
									toasterPopSuccess,
									gettextCatalog.getString(
										'expose.modal.successfully.saved'
									)
								);
								$('#' + modalId).modal('hide');
							});
						} else {
							DataBlocksService.addExposePermissionFilter(
								vm.datablockId,
								vm.permissionFilterDef
							).then(function (responseAdd) {
								$scope.dataExposeParams.permission_filters = $scope
									.dataExposeParams.permission_filters
									? $scope.dataExposeParams.permission_filters
									: [];
								$scope.dataExposeParams.permission_filters.unshift(
									responseAdd.data
								);
								if (
									permissionFilterGridInstance &&
									permissionFilterGridInstance.option
								) {
									permissionFilterGridInstance.option(
										'dataSource',
										$scope.dataExposeParams.permission_filters
									);
									permissionFilterGridInstance.refresh();
								}
								toaster.pop(
									'success',
									toasterPopSuccess,
									gettextCatalog.getString('Enregistrement du filtre effectué')
								);
								$('#' + modalId).modal('hide');
							});
						}
					}

					function openApplyFilter(modalId, data) {
						vm.permissionFilterToApply = data;
						vm.permissionFilterDefUsers = vm.permissionFilterToApply.applied_to
							? vm.permissionFilterToApply.applied_to
							: [];
						UserService.getProjectMembers().then(function (members) {
							vm.projectUsers = _.filter(members, function (member) {
								return member.has_member_role || member.has_project_role;
							});
							vm.filteredProjectUsers = _.filter(
								vm.projectUsers,
								function (elm) {
									return vm.permissionFilterDefUsers.indexOf(elm.id) < 0;
								}
							);
							vm.usersNames = _.object(
								_.map(vm.projectUsers, function (item) {
									return [item.id, item.name];
								})
							);
							$('#' + modalId).modal('show');
						});
					}

					function addSelectedUserToApplied() {
						vm.permissionFilterDefUsers = vm.permissionFilterDefUsers
							? vm.permissionFilterDefUsers
							: [];
						vm.permissionFilterDefUsers.unshift(vm.selectedUser);
						delete vm.selectedUser;
						vm.filteredProjectUsers = _.filter(vm.projectUsers, function (elm) {
							return vm.permissionFilterDefUsers.indexOf(elm.id) < 0;
						});
					}

					function removeUser(index) {
						vm.permissionFilterDefUsers.splice(index, 1);
						vm.filteredProjectUsers = _.filter(vm.projectUsers, function (elm) {
							return vm.permissionFilterDefUsers.indexOf(elm.id) < 0;
						});
					}

					function applyFilter(modalId) {
						DataBlocksService.exposeAttachPermissionToUsers(
							vm.datablockId,
							vm.permissionFilterToApply.id,
							vm.permissionFilterDefUsers
						).then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								gettextCatalog.getString(
									'expose.modal.successfully.saved'
								)
							);
							for (let i in $scope.dataExposeParams.permission_filters) {
								if (
									$scope.dataExposeParams.permission_filters[i].id ===
									vm.permissionFilterToApply.id
								) {
									$scope.dataExposeParams.permission_filters[i] = angular.copy(
										response.data
									);
									break;
								}
							}
							if (
								permissionFilterGridInstance &&
								permissionFilterGridInstance.option
							) {
								permissionFilterGridInstance.option(
									'dataSource',
									$scope.dataExposeParams.permission_filters
								);
								permissionFilterGridInstance.refresh();
							}
							$('#' + modalId).modal('hide');
						});
					}

					function closeMe(modalId) {
						$('#' + modalId).modal('hide');
					}

					function doShowDeleteFilter(data) {
						permissionFilterToDelete = data;
						$scope.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString('Confirmation de la suppression');
						$scope.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('Supprimer');
						$scope.confirmationData.enableConfirmDialogAction = true;
						$scope.confirmationData.confirmationGreenAction = false;
						$scope.confirmationData.confirmDialogAction = deleteFilterParam;
						$scope.confirmationData.confirmationDialogMessage =
							gettextCatalog.getString('expose.filter.delete.confirmation', {label: data.label});
						$scope.confirmationData.showConfirmationDialog = true;
					}

					function deleteFilterParam() {
						DataBlocksService.deleteExposePermissionFilter(
							vm.datablockId,
							permissionFilterToDelete.id
						).then(function (response) {
							$scope.dataExposeParams.permission_filters = _.filter(
								$scope.dataExposeParams.permission_filters,
								function (elm) {
									return elm.id !== permissionFilterToDelete.id;
								}
							);
							if (
								permissionFilterGridInstance &&
								permissionFilterGridInstance.option
							) {
								permissionFilterGridInstance.option(
									'dataSource',
									$scope.dataExposeParams.permission_filters
								);
								permissionFilterGridInstance.refresh();
							}
							toaster.pop(
								'success',
								toasterPopSuccess,
								gettextCatalog.getString('Suppression du filtre effectuée')
							);
							permissionFilterToDelete = undefined;
						});
					}

					let checkExposeParams = function () {
						$scope.exposeNameIsEmpty = false;
						if (
							!$scope.dataExposeParams.api_name ||
							!$scope.dataExposeParams.api_name[0]
						) {
							$scope.exposeNameIsEmpty = true;
							return false;
						}
						if (
							!_.filter($scope.availableColumns, function (item) {
								return item.active && !item.hidden;
							})[0]
						) {
							toaster.pop(
								'error',
								gettextCatalog.getString(
									'expose.modal.one.mandatory.column'
								)
							);
							return false;
						}
						let columnsAreValid = true;
						_.each($scope.availableColumns, function (column) {
							if (column.active && (!column.name || !column.name[0])) {
								column.aliasIsEmpty = true;
								columnsAreValid = false;
							} else {
								column.aliasIsEmpty = false;
							}
						});
						if (!columnsAreValid) {
							return false;
						}
						return true;
					};


					function updateUrls() {
						GenericService.getWebUiUrl().then(function (response) {
							$scope.webUiUrl = response.data;
						});
					}

					function showExposeHistory() {
						delete $scope.exposeHistoryGridOptions;
						DataBlocksService.getExposeHistory(vm.datablockId).then(function (
							response
						) {
							$scope.exposeHistoryGridOptions = getDefaultDxGridConfig(
								10,
								PAGINATIONS_SIZES
							);
							$scope.exposeHistoryGridOptions.columns = [
								{
									caption: gettextCatalog.getString('Date/ Heure Exposition'),
									dataField: 'date',
								},
								{
									caption: gettextCatalog.getString('Utilisateur Exposition'),
									cellTemplate: 'exposeUser',
								},
								{
									caption: gettextCatalog.getString('Durée (s)'),
									dataField: 'duration',
								},
								{
									caption: gettextCatalog.getString('Nb lignes'),
									dataField: 'count',
								},
								{
									caption: gettextCatalog.getString('Nb colonnes'),
									dataField: 'nbr_of_cols',
								},
								{
									caption: gettextCatalog.getString('Statut'),
									dataField: 'status',
								},
							];
							_.each(response.data, function (item) {
								if (item.status == 'DELETED')
									item.status = gettextCatalog.getString('expose.status.deleted');
								if (item.status == 'CURRENT')
									item.status = gettextCatalog.getString('expose.status.current');
								if (item.status == 'DRAFT')
									item.status = gettextCatalog.getString('expose.status.draft');
								if (item.status == 'ERROR')
									item.status = gettextCatalog.getString('expose.status.error');
							});
							//duration to seconds
							$scope.exposeHistoryGridOptions.dataSource = _.each(
								response.data,
								function (item) {
									item.duration = item.duration
										? moment.duration(item.duration, 'milliseconds').asSeconds()
										: item.duration;
									item.date = item.date
										? moment.unix(item.date).format('DD/MM/Y HH:mm:ss')
										: item.date;
								}
							);
						});
						DataBlocksService.getCurrentExpose(vm.datablockId).then(function (
							response
						) {
							updateCurrentExposeData(response);
						});
					}

					function updateCurrentExposeData(response)
					{
						$scope.currentExpose = response.data;
						if ($scope.currentExpose.date)
						{
							// mise à jour de la date
							$scope.currentExpose.date = moment.unix($scope.currentExpose.date).format('DD/MM/Y HH:mm:ss');
						}
						updateDisplayURLs();
					}

					$scope.confirmationData = {};
					function showExposeConfirmationDialog(
						confirmDialogAction,
						confirmationDialogMessage,
						greenAction
					) {
						$scope.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString('Confirmation');
						$scope.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						$scope.confirmationData.enableConfirmDialogAction = true;
						$scope.confirmationData.confirmationGreenAction = greenAction;
						$scope.confirmationData.confirmDialogAction = confirmDialogAction;
						$scope.confirmationData.confirmationDialogMessage =
							confirmationDialogMessage;
						$scope.confirmationData.showConfirmationDialog = true;
					}

					$scope.selectIdColumn = function (column) {
						if (column.id_column) {
							_.each($scope.availableColumns, function (item) {
								item.id_column = item.uuid === column.uuid;
							});
						}
					};

					$scope.exposeDb = function () {
						let confirmMsg;
						const codeExpose = "expose.modal.confirmation.exposition.{{datablockLabel}}.{{date}}";
						const codeUpdate = "expose.modal.confirmation.exposition.update.{{datablockLabel}}";
						if ($scope.currentExpose)
						{
							confirmMsg =
								gettextCatalog.getString(codeUpdate, {datablockLabel : vm.datablockLabel});
						} else {
							confirmMsg =
								gettextCatalog.getString(codeExpose, {
									datablockLabel : vm.datablockLabel,
									date : $rootScope.getFormattedDateWithPattern(new Date(), 'dd/MM/yyyy hh:mm:ss')
								}
							);
						}

						if ($scope.currentExpose) {
						}

						showExposeConfirmationDialog(exposeDb, confirmMsg, true);
					};

					function exposeDb() {
						$scope.exposeClientId = generateClientId($rootScope.account.login);
						$scope.exposeLoading = true;
						DataBlocksService.exposeDatablock(
							vm.datablockId,
							$scope.exposeClientId
						)
							.then(function (response) {
								if (vm.updateAction) {
									vm.updateAction();
								}
							})
							.catch(function () {
								$scope.exposeLoading = false;
							});
					}

					$scope.deleteExpose = function () {
						showExposeConfirmationDialog(
							deleteExpose,
							gettextCatalog.getString("expose.modal.confirmation.raz.{{datablockLabel}}",
								{datablockLabel : vm.datablockLabel}
							),
							false
						);
					};


					function deleteExpose() {
						DataBlocksService.deleteCurrentExpose(vm.datablockId).then(
							function (response) {
								showExposeHistory();
								if (vm.updateAction) {
									vm.updateAction();
								}
							}
						);
					}

					$scope.enableExposition = function () {
						DataBlocksService.enableExpose(
							vm.datablockId,
							$scope.exposeClientId
						)
						.then(function (response) {
							updateCurrentExposeData(response);
							showExposeHistory();
							if (vm.updateAction) {
								vm.updateAction();
							}
						});
					};

					$scope.disableExposition = function () {
						DataBlocksService.disableExpose(
							vm.datablockId,
							$scope.exposeClientId
						)
						.then(function (response) {
							updateCurrentExposeData(response);
							showExposeHistory();
							if (vm.updateAction) {
								vm.updateAction();
							}
						});
					};

					vm.showDataExposeParams = function () {
						PermissionService.havePermission(
							vm.datablockId,
							'edit',
							'datablock'
						).then(function (response) {
							vm.canEditDB = response.data;
							getExposeData();
						});
					};

					$scope.getHtmlIconByType = function (type, isList) {
						return $sce.trustAsHtml(
							getHtmlIconByType(type.toLowerCase(), isList)
						);
					};

					$scope.saveDataExposeParams = function () {
						if (!checkExposeParams()) {
							return;
						}
						$scope.dataExposeParams.columns = [];
						for (let c in $scope.availableColumns) {
							if (
								!$scope.availableColumns[c].active ||
								!$scope.availableColumns[c].name
								) {
								continue;
							}
							$scope.dataExposeParams.columns.push({
								column_uuid: $scope.availableColumns[c].uuid,
								name: $scope.availableColumns[c].name,
								description: $scope.availableColumns[c].description,
								active: $scope.availableColumns[c].active,
								hidden: $scope.availableColumns[c].hidden,
								cis: $scope.availableColumns[c].cis,
								id_column: $scope.availableColumns[c].id_column,
							});
						}
						DataBlocksService.editExposeParams(
							vm.datablockId,
							$scope.dataExposeParams
						).then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								gettextCatalog.getString(
									'expose.modal.successfully.saved'
								)
							);
							$scope.exposeIsAlreadyActive = $scope.dataExposeParams.active;
							$scope.exposeNotConfigured =
								$scope.dataExposeParams.api_name === undefined;
							if (vm.updateAction) {
								vm.updateAction();
							}
						});
					};

					// expose params popup
					$scope.aliasPattern = '^[a-zA-Z0-9_]*$';
					$scope.searchAvailableColumns = function () {
						$scope.filteredAvailableColumns = _.filter(
							$scope.availableColumns,
							function (item) {
								return (
									item.lib
										.toLowerCase()
										.indexOf(vm.searchText.toLowerCase()) !== -1
								);
							}
						);
					};

					$scope.availableColsSortingOrder = 'DEFAULT';
					$scope.sortAvailableCols = function () {
						if ($scope.availableColsSortingOrder == 'DEFAULT') {
							$scope.filteredAvailableColumns = _.sortBy(
								$scope.filteredAvailableColumns,
								function (e) {
									return e.lib.toLowerCase();
								}
							);
							$scope.availableColsSortingOrder = 'ASC';
						} else if ($scope.availableColsSortingOrder == 'ASC') {
							$scope.filteredAvailableColumns = _.sortBy(
								$scope.filteredAvailableColumns,
								function (e) {
									return e.lib.toLowerCase();
								}
							).reverse();
							$scope.availableColsSortingOrder = 'DESC';
						} else if ($scope.availableColsSortingOrder == 'DESC') {
							$scope.filteredAvailableColumns = _.sortBy(
								$scope.filteredAvailableColumns,
								function (e) {
									return e.orderN;
								}
							);
							$scope.availableColsSortingOrder = 'DEFAULT';
						}
					};

					$scope.updateColumnAlias = function (column) {
						column.aliasIsEmpty = false;
						if (
							_.filter($scope.availableColumns, function (item) {
								return (
									item.uuid != column.uuid &&
									item.name &&
									item.name[0] &&
									column.name &&
									item.name.toLowerCase() == column.name.toLowerCase()
								);
							})[0]
						) {
							column.aliasExists = true;
						} else {
							column.aliasExists = false;
						}
					};

					vm.normalizeColumn = function (column) {
						if (column.active) {
							column.name = $filter('normalize')(
								column.name ? column.name : column.lib,
									toLowerCase, toUpperCase
							);
						}
					};

					$scope.switchColumnActiveAll = function () {
						_.each($scope.availableColumns, function (column) {
							column.active = $scope.columnActiveAll;
							vm.normalizeColumn(column);
						});
					};

					vm.switchActiveColumn = function (column) {
						vm.normalizeColumn(column);
						vm.updateColumnActiveAll();
					};

					vm.updateColumnActiveAll = function () {
						$scope.columnActiveAll = !_.filter(
							$scope.availableColumns,
							function (column) {
								return !column.active;
							}
						)[0];
					};

					$scope.copyToClipboard = function (value) {
						copyToClipboard(value);
						toaster.pop(
							'success',
							gettextCatalog.getString('Copie effectuée !')
						);
					};

					$scope.$on('exposeJobFinished', function (event, notif) {
						if (
							notif &&
							notif.data &&
							notif.data.client_id == $scope.exposeClientId
						) {
							if (notif.data.status === 'SUCCESS') {
								toaster.pop(
									'success',
									gettextCatalog.getString('expose.modal.expose')
								);
								showExposeHistory();
								$scope.exposeLoading = false;
							} else if (notif.data.status === 'ERROR') {
								toaster.pop(
									'error',
									gettextCatalog.getString(
										"expose.modal.expose.error"
									)
								);
								$scope.exposeLoading = false;
							}
						}
					});

					let deleteExposeAndParam = function () {
						DataBlocksService.deleteExposeParams(vm.datablockId).then(function (
							response
						) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								gettextCatalog.getString('Suppression effectuée')
							);
							vm.showDataExposeParams();
							if (vm.updateAction) {
								vm.updateAction();
							}
						});
					};

					$scope.doShowDeleteModal = function () {
						let msg = gettextCatalog.getString(
							"expose.modal.current.expose.delete.text"
						);
						$scope.confirmationData.confirmationDialogTitle =
							gettextCatalog.getString(
								"expose.modal.current.expose.delete.title"
							);
						$scope.confirmationData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						$scope.confirmationData.enableConfirmDialogAction = true;
						$scope.confirmationData.confirmationGreenAction = false;
						$scope.confirmationData.confirmDialogAction = deleteExposeAndParam;
						$scope.confirmationData.confirmationDialogMessage = msg;
						$scope.confirmationData.showConfirmationDialog = true;
					};

					// get data
					function getExposeData() {
						vm.searchText = '';
						$scope.exposeNameIsEmpty = false;
						$scope.exposeIsAlreadyActive = false;
						$scope.exposeLoading = false;
						$scope.exposeNotConfigured = true;
						updateUrls();
						showExposeHistory();
						DataBlocksService.getExposeParams(vm.datablockId).then(function (
							response
						) {
							$scope.dataExposeParams = response.data;
							const rootPathC = $scope.dataExposeParams.exposures_root_code ? "/" + $scope.dataExposeParams.exposures_root_code : ""
							vm.dataPath = SERVICE_DATA_PATH + rootPathC + "/";
							$scope.exposeNotConfigured =
								$scope.dataExposeParams.api_name === undefined;
							$scope.exposeIsAlreadyActive = response.data.active;
							if (!$scope.dataExposeParams.api_name) {
								$scope.dataExposeParams.active = true;
							}
							if (!$scope.dataExposeParams.columns) {
								$scope.dataExposeParams.columns = [];
							}
							updateDisplayURLs();
							DataBlocksService.getDataBlockColumns(vm.datablockId).then(
								function (columnsResponse) {
									let currentOrder = 0;
									$scope.availableColumns = _.each(
										columnsResponse.data,
										function (elm) {
											elm.orderN = currentOrder;
											currentOrder = currentOrder + 1;
										}
									);
									$scope.filteredAvailableColumns = $scope.availableColumns;
									$scope.availableColsSortingOrder = 'DEFAULT';
									for (let c in $scope.availableColumns) {
										let col = _.filter(
											$scope.dataExposeParams.columns,
											function (item) {
												return (
													item.column_uuid == $scope.availableColumns[c].uuid
												);
											}
										)[0];
										if (col) {
											$scope.availableColumns[c].name = col.name;
											$scope.availableColumns[c].description = col.description;
											$scope.availableColumns[c].active = col.active;
											if (col.active)
											{
												$scope.availableColumns[c].id_column = col.id_column;
												$scope.availableColumns[c].cis = col.cis;
												$scope.availableColumns[c].hidden = col.hidden;
											}
										}
									}
									if (
										!_.filter($scope.availableColumns, function (item) {
											return item.name && item.name[0];
										})[0]
									) {
										_.each($scope.availableColumns, function (item) {
											if (!item.name) {
												item.name = item.lib;
											}
										});
									}
									$scope.columnActiveAll = !_.filter(
										$scope.availableColumns,
										function (column) {
											return !column.active;
										}
									)[0];
									$scope.columnHiddenAll = !_.filter(
										$scope.availableColumns,
										function (column) {
											return ! (column.active && column.hidden);
										}
									)[0];
								}
							);
							$('#exposeSettingTab').tab('show');
							$(vm.element).modal('show');
						});
					}

					function updateDisplayURLs() {
						$scope.apiUrl =
							$scope.webUiUrl +
							vm.dataPath +
							$scope.currentExpose.api_name;
						$scope.apiCountUrl =
							$scope.webUiUrl +
							vm.dataPath +
							$scope.currentExpose.api_name +
							'/count';
						$scope.apiInfoUrl =
							$scope.webUiUrl +
							'/#/data/' +
							$scope.currentExpose.api_name +
							'/info';
					}
				},
			];

			return buildDirectiveReturnObject(
				{
					datablockId: '=',
					showExposeDatablock: '=',
					datablockLabel: '=',
					updateAction: '=',
				},
				controller,
				'./src/components/directives/dataBlockExpose/dataBlockExpose.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showExposeDatablock = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.showExposeDatablock;
						},
						function () {
							scope.vm.showDataExposeParams();
						},
						true
					);
				}
			);
		},
	]);
})();
