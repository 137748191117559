(function () {
	'use strict';

	angular.module('dcApp').factory('ChartService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'$filter',
		'UrlService',
		function (
			$http,
			$rootScope,
			API_BASE_URL_BACKEND,
			$q,
			$filter,
			UrlService
		) {
			var ChartService = {};

			var getUrls = {
				indicator: 'CHARTS_INDICATOR_GET',
				barline: 'CHARTS_BAR_GET',
				pie: 'CHARTS_PIE_GET',
				treemap: 'CHARTS_TREEMAP_GET',
				range: 'CHARTS_RANGE_GET',
				scatter: 'CHARTS_SCATTER_GET',
				sankey: 'CHARTS_SANKEY_GET',
				gauge_bar: 'CHARTS_GAUGE_BAR_GET',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_GET',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_GET',
				funnel: 'CHARTS_FUNNEL_GET',
			};
			var createUrls = {
				indicator: 'CHARTS_INDICATOR_CREATE',
				barline: 'CHARTS_BAR_CREATE',
				pie: 'CHARTS_PIE_CREATE',
				treemap: 'CHARTS_TREEMAP_CREATE',
				range: 'CHARTS_RANGE_CREATE',
				scatter: 'CHARTS_SCATTER_CREATE',
				sankey: 'CHARTS_SANKEY_CREATE',
				gauge_bar: 'CHARTS_GAUGE_BAR_CREATE',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_CREATE',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_CREATE',
				funnel: 'CHARTS_FUNNEL_CREATE',
			};
			var createFromDbUrls = {
				indicator: 'CHARTS_INDICATOR_CREATE_FROM_DB',
				barline: 'CHARTS_BAR_CREATE_FROM_DB',
				pie: 'CHARTS_PIE_CREATE_FROM_DB',
				treemap: 'CHARTS_TREEMAP_CREATE_FROM_DB',
				range: 'CHARTS_RANGE_CREATE_FROM_DB',
				scatter: 'CHARTS_SCATTER_CREATE_FROM_DB',
				sankey: 'CHARTS_SANKEY_CREATE_FROM_DB',
				gauge_bar: 'CHARTS_GAUGE_BAR_CREATE_FROM_DB',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_CREATE_FROM_DB',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_CREATE_FROM_DB',
				funnel: 'CHARTS_FUNNEL_CREATE_FROM_DB',
			};
			var editUrls = {
				indicator: 'CHARTS_INDICATOR_EDIT',
				barline: 'CHARTS_BAR_EDIT',
				pie: 'CHARTS_PIE_EDIT',
				treemap: 'CHARTS_TREEMAP_EDIT',
				range: 'CHARTS_RANGE_EDIT',
				scatter: 'CHARTS_SCATTER_EDIT',
				sankey: 'CHARTS_SANKEY_EDIT',
				gauge_bar: 'CHARTS_GAUGE_BAR_EDIT',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_EDIT',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_EDIT',
				funnel: 'CHARTS_FUNNEL_EDIT',
			};
			var deleteUrls = {
				indicator: 'CHARTS_INDICATOR_DELETE',
				barline: 'CHARTS_BAR_DELETE',
				pie: 'CHARTS_PIE_DELETE',
				treemap: 'CHARTS_TREEMAP_DELETE',
				range: 'CHARTS_RANGE_DELETE',
				scatter: 'CHARTS_SCATTER_DELETE',
				sankey: 'CHARTS_SANKEY_DELETE',
				gauge_bar: 'CHARTS_GAUGE_BAR_DELETE',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_DELETE',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_DELETE',
				funnel: 'CHARTS_FUNNEL_DELETE',
			};
			var getConfigUrls = {
				indicator: 'CHARTS_INDICATOR_GET_CONFIG',
				barline: 'CHARTS_BAR_GET_CONFIG',
				pie: 'CHARTS_PIE_GET_CONFIG',
				treemap: 'CHARTS_TREEMAP_GET_CONFIG',
				range: 'CHARTS_RANGE_GET_CONFIG',
				scatter: 'CHARTS_SCATTER_GET_CONFIG',
				sankey: 'CHARTS_SANKEY_GET_CONFIG',
				gauge_bar: 'CHARTS_GAUGE_BAR_GET_CONFIG',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_GET_CONFIG',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_GET_CONFIG',
				funnel: 'CHARTS_FUNNEL_GET_CONFIG',
			};
			var editConfigUrls = {
				indicator: 'CHARTS_INDICATOR_EDIT_CONFIG',
				barline: 'CHARTS_BAR_EDIT_CONFIG',
				pie: 'CHARTS_PIE_EDIT_CONFIG',
				treemap: 'CHARTS_TREEMAP_EDIT_CONFIG',
				range: 'CHARTS_RANGE_EDIT_CONFIG',
				scatter: 'CHARTS_SCATTER_EDIT_CONFIG',
				sankey: 'CHARTS_SANKEY_EDIT_CONFIG',
				gauge_bar: 'CHARTS_GAUGE_BAR_EDIT_CONFIG',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_EDIT_CONFIG',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_EDIT_CONFIG',
				funnel: 'CHARTS_FUNNEL_EDIT_CONFIG',
			};
			var checkConfigUrls = {
				indicator: 'CHARTS_INDICATOR_CHECK_CONFIG',
				barline: 'CHARTS_BAR_CHECK_CONFIG',
				pie: 'CHARTS_PIE_CHECK_CONFIG',
				treemap: 'CHARTS_TREEMAP_CHECK_CONFIG',
				range: 'CHARTS_RANGE_CHECK_CONFIG',
				scatter: 'CHARTS_SCATTER_CHECK_CONFIG',
				sankey: 'CHARTS_SANKEY_CHECK_CONFIG',
				gauge_bar: 'CHARTS_GAUGE_BAR_CHECK_CONFIG',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_CHECK_CONFIG',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_CHECK_CONFIG',
				funnel: 'CHARTS_FUNNEL_CHECK_CONFIG',
			};
			var duplicateUrls = {
				indicator: 'CHARTS_INDICATOR_DUPLICATE',
				barline: 'CHARTS_BAR_DUPLICATE',
				pie: 'CHARTS_PIE_DUPLICATE',
				treemap: 'CHARTS_TREEMAP_DUPLICATE',
				range: 'CHARTS_RANGE_DUPLICATE',
				scatter: 'CHARTS_SCATTER_DUPLICATE',
				sankey: 'CHARTS_SANKEY_DUPLICATE',
				gauge_bar: 'CHARTS_GAUGE_BAR_DUPLICATE',
				gauge_circular: 'CHARTS_GAUGE_CIRCULAR_DUPLICATE',
				gauge_linear: 'CHARTS_GAUGE_LINEAR_DUPLICATE',
				funnel: 'CHARTS_FUNNEL_DUPLICATE',
			};

			ChartService.getChart = function (type, chartId) {
				var urlCode = getUrls[type];
				return getChart(chartId, $rootScope.getUrl(urlCode));
			};

			ChartService.createChart = function (type, chart) {
				var urlCode = createUrls[type];
				return createChart(chart, $rootScope.getUrl(urlCode));
			};

			ChartService.createChartFromDataBlock = function (type, chart) {
				var urlCode = createFromDbUrls[type];
				return createChart(chart, $rootScope.getUrl(urlCode));
			};

			ChartService.editChart = function (type, chartId, chart) {
				var urlCode = editUrls[type];
				return editChart(chartId, chart, $rootScope.getUrl(urlCode));
			};

			ChartService.deleteChart = function (type, chartId) {
				var urlCode = deleteUrls[type];
				return deleteChart(chartId, $rootScope.getUrl(urlCode));
			};

			ChartService.deleteCharts = function (chartsId) {
				return deleteCharts(chartsId, $rootScope.getUrl('CHARTS_DELETE_BULK'));
			};

			ChartService.editChartConfig = function (type, chartId, config) {
				var urlCode = editConfigUrls[type];
				return editChartConfig(chartId, config, $rootScope.getUrl(urlCode));
			};

			ChartService.getChartConfig = function (type, chartId) {
				var urlCode = getConfigUrls[type];
				return getChartConfig(chartId, $rootScope.getUrl(urlCode));
			};

			ChartService.checkChartConfig = function (type, chartId) {
				var urlCode = checkConfigUrls[type];
				return checkChartConfig(chartId, $rootScope.getUrl(urlCode));
			};

			ChartService.duplicateChart = function (type, chartId) {
				var urlCode = duplicateUrls[type];
				return duplicateChart(chartId, $rootScope.getUrl(urlCode));
			};

			ChartService.getChartOptions = function () {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('OPTIONS_HD_CHART');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ChartService.getAllCharts = function (excludePublic, excludeInactive) {
				return getCharts(excludePublic, excludeInactive, 'CHARTS_GET_ALL');
			};

			ChartService.getTooltip = function (
				data,
				tooltipColumns,
				columns,
				axisConf
			) {
				let rows = '';

				for (let c in tooltipColumns) {
					let col = _.find(columns, function (item) {
						return item.uuid == tooltipColumns[c];
					});
					if (col) {
						let value = data[col.uuid];
						if (col.type === 'date') {
							let pattern =
								axisConf && axisConf.label && axisConf && axisConf.label.format
									? axisConf.label.format
									: '';
							const rawDate = new Date(data[col.uuid]);
							const date =
								$rootScope.getDefaultTimezone() !== 'UTC'
									? rawDate
									: Date.UTC(
											rawDate.getFullYear(),
											rawDate.getMonth(),
											rawDate.getDate(),
											rawDate.getHours(),
											rawDate.getMinutes(),
											rawDate.getSeconds(),
											rawDate.getMilliseconds()
									  );
							value = ChartService.getFormattedDxChartDateToTZ(
								date,
								pattern,
								$rootScope.getDefaultTimezone(),
								false,
								$filter
							);
							const valueUTC = ChartService.getFormattedDxChartDateToTZ(
								date,
								pattern,
								'UTC',
								false,
								$filter
							);
							rows = `${rows}
								<tr>
									<td class="width-50"><b>${col.lib}</b> : </td>
									<td class="width-50">${value}</td>
								</tr>
								<tr>
									<td class="width-50"><b>${col.lib} (UTC)</b> : </td>
									<td class="width-50">${valueUTC}</td>
								</tr>
							`;
						} else {
							rows = `${rows}
								<tr>
									<td class="width-50"><b>${col.lib}</b> : </td>
									<td class="width-50">${value}</td>
								</tr>
							`;
						}
					}
				}

				const view = `
					<table style="width : 400px">
						${rows}
					</table>
				`;
				return view;
			};

			// format axis date in default tz
			ChartService.getFormattedDxChartDateToTZ = function (
				date,
				pattern,
				defaultTZ,
				callToStringIfNoPattern,
				$filter
			) {
				let momentDate = moment.utc(date);
				if (!momentDate.isValid()) {
					return date;
				}

				if (!pattern && callToStringIfNoPattern) {
					return momentDate.tz(defaultTZ).toString();
				}

				let patternToUse = pattern ? pattern : 'yyyy-MM-dd HH:mm:ss.sss';

				let utcDate = momentDate.toDate();

				patternToUse = patternToUse.replace('SSS', 'sss');

				return $filter('date')(utcDate, patternToUse, defaultTZ);
			};

			var getCharts = function (excludePublic, excludeInactive, urlCode) {
				const url = UrlService.generateUrl(
					urlCode,
					{},
					{
						excludePublic: excludePublic ? excludePublic : false,
						excludeInactive: excludeInactive ? excludeInactive : false,
					}
				);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var getChart = function (chartId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var checkChartConfig = function (chartId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var createChart = function (job, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				$http.post(url, job).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			var editChart = function (chartId, chart, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				$http.put(url, chart).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			};

			var deleteChart = function (chartId, url) {
				var url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var deleteCharts = function (chartsId, url) {
				url = API_BASE_URL_BACKEND + url;
				if (Array.isArray(chartsId)) {
					url = url + '?chartsId=' + chartsId[0];
					for (let i = 1; i < chartsId.length; i++) {
						url = url + '&chartsId=' + chartsId[i];
					}
				} else {
					url = url + '?chartsId=' + chartsId;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			var editChartConfig = function (chartId, config, url) {
				var deferred = $q.defer();
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				$http.put(url, config).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			var getChartConfig = function (chartId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			var duplicateChart = function (chartId, url) {
				url = API_BASE_URL_BACKEND + url;
				url = url.replace('{id}', chartId);
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			return ChartService;
		},
	]);
})();
