(function () {
	'use strict';

	angular.module('dcApp').directive('selectPath', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				'$rootScope',
				function ($scope, GenericService, $rootScope) {
					var vm = this;

					$scope.hidePathActionCol = true;

					$scope.save = function () {
						vm.widgetData.updateaction_l2($scope.selectedPath);
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showSelectPath = false;
					}

					$scope.searchPathesByLoop = function () {
						$scope.predicate = undefined;
						$scope.isLoading = true;

						$scope.searchPathNotContains = false;
						$scope.searchPathText = '';

						if ($scope.loopPath === undefined) {
							$scope.loopPath = 1;
						}

						if ($scope.pathesCurrentPage === undefined) {
							$scope.pathesCurrentPage = 1;
						}

						GenericService.getAllPathFormattedAliases(
							vm.widgetData.oldPathes,
							vm.widgetData.source,
							vm.widgetData.target,
							$scope.loopPath,
							vm.widgetData.sourceType,
							vm.widgetData.targetType,
							$scope.pathesCurrentPage,
							100
						).then(function (response) {
							$scope.totalPatheCount = response.data.total_count;
							$scope.isLoading = false;
							var data = response.data.pathes;
							$scope.libs = {};
							for (var d in data) {
								var dataItem = data[d].nodes;
								for (var di in dataItem) {
									if (dataItem[di].type == 'c') {
										$scope.libs['c' + dataItem[di].id] = dataItem[di];
									} else {
										$scope.libs[dataItem[di].id] = dataItem[di];
									}
								}
							}

							$scope.pathes = [];
							for (var p in data) {
								$scope.pathes.push(setPathFieldsAlias(data[p]));
							}
							var title;
							var titleObj;
							var tous = false;
							var formattedPathes = [];
							var nbrYellow = 0;
							for (var p in $scope.pathes) {
								$scope.pathes[p].fNodes = $scope.pathes[p].nodes;
								if (
									$scope.pathes[p].isTitle &&
									$scope.pathes[p].path.indexOf('Tous') < 0
								) {
									title = $scope.pathes[p].path;
									titleObj = $scope.pathes[p];
									nbrYellow++;
								} else if ($scope.pathes[p].path.indexOf('Tous') == 0) {
									title = undefined;
									tous = true;
								} else if (title !== undefined) {
									if (!tous) {
										$scope.pathes[p].fNodes = [];
										for (var n in $scope.pathes[p].nodes) {
											if (
												!containsNode(titleObj.nodes, $scope.pathes[p].nodes[n])
											) {
												$scope.pathes[p].fNodes.push($scope.pathes[p].nodes[n]);
											}
										}
									}

									$scope.pathes[p].path = $scope.pathes[p].path.replace(
										title + '--',
										''
									);
								}
								formattedPathes.push($scope.pathes[p]);
							}

							$scope.shared_pathes = nbrYellow !== 0;
							$scope.pathes = formattedPathes;
							$scope.pathesTable = $scope.pathes;

							$scope.pathesTable = sortPathes(
								$scope.pathesTable,
								'nbEdges',
								true,
								$scope.shared_pathes
							);
							if (
								$scope.sourceType == 'c' &&
								$scope.targetType == 'c' &&
								$scope.targetParentId == $scope.sourceParentId
							) {
								$scope.selectPath(
									$scope.pathesTable[0].object,
									$scope.pathesTable[0].isTitle
								);
								$scope.save();
								return;
							}

							if (
								$scope.sourceType == 'c' &&
								$scope.targetType == 't' &&
								$scope.target == $scope.sourceParentId
							) {
								$scope.selectPath(
									$scope.pathesTable[0].object,
									$scope.pathesTable[0].isTitle
								);
								$scope.save();
								return;
							}

							if (
								$scope.sourceType == 't' &&
								$scope.targetType == 'c' &&
								$scope.targetParentId == $scope.source
							) {
								$scope.selectPath(
									$scope.pathesTable[0].object,
									$scope.pathesTable[0].isTitle
								);
								$scope.save();
								return;
							}
						});
						return;
					};

					$scope.selectPath = function (path, isTitle) {
						if (isTitle) {
							return;
						}
						if ($scope.selectedPath !== path) {
							$scope.selectedPath = path;
						} else {
							$scope.selectedPath = undefined;
						}
					};

					$scope.sortPathes = function (
						pathesTable,
						field,
						reverse,
						shared_pathes
					) {
						return sortPathes(pathesTable, field, reverse, shared_pathes);
					};

					$scope.selectPath = function (path, isTitle) {
						if (isTitle) {
							return;
						}
						if ($scope.selectedPath !== path) {
							$scope.selectedPath = path;
						} else {
							$scope.selectedPath = undefined;
						}
					};

					$scope.searchPathesByText = function () {
						$scope.pathesTable = [];
						if ($scope.searchPathNotContains === undefined) {
							$scope.searchPathNotContains = false;
						}

						for (var p in $scope.pathes) {
							if (
								$scope.pathes[p].isTitle ||
								(!$scope.searchPathNotContains &&
									$scope.pathes[p].full_path
										.toLowerCase()
										.indexOf($scope.searchPathText) != -1) ||
								isEmpty($scope.searchPathText)
							) {
								$scope.pathesTable.push($scope.pathes[p]);
							} else if (
								$scope.pathes[p].isTitle ||
								($scope.searchPathNotContains &&
									$scope.pathes[p].full_path
										.toLowerCase()
										.indexOf($scope.searchPathText) == -1)
							) {
								$scope.pathesTable.push($scope.pathes[p]);
							}
						}
					};

					$scope.getFiltredNodes = function (nodes) {
						var newNodes = [];
						for (var n in nodes) {
							if (!$scope.notShowNodeInPath(nodes[n])) {
								newNodes.push(nodes[n]);
							}
						}
						return newNodes;
					};

					$scope.notShowNodeInPath = function (node) {
						if (node.is_alias) {
							return false;
						}

						if (
							node.node.id == $scope.source &&
							$scope.sourceType == 'c' &&
							node.node.type == 'c'
						) {
							return true;
						}

						if (
							node.node.parent_entite_type_id == $scope.source &&
							$scope.sourceType == 't' &&
							node.node.type == 'c'
						) {
							return true;
						}

						if (
							node.node.id == $scope.sourceParentId &&
							$scope.sourceType == 'c' &&
							node.node.type == 't'
						) {
							return true;
						}

						if (
							node.node.id == $scope.selectedCaracId &&
							$scope.selectedCaracType == 'c' &&
							node.node.type == 'c'
						) {
							return true;
						}

						if (
							node.parent_entite_type_id == $scope.selectedCaracId &&
							$scope.selectedCaracType == 't' &&
							node.node.type == 'c'
						) {
							return true;
						}

						if (
							node.node.id == $scope.targetParentId &&
							$scope.selectedCaracType == 'c' &&
							node.node.type == 't'
						) {
							return true;
						}

						return false;
					};

					$scope.editPathAlias = function (path) {
						$scope.vm.widgetData.editPathAliasAllPathes = $scope.pathes;
						$scope.vm.widgetData.afterUpdateAction = $scope.searchPathesByLoop;
						$scope.vm.widgetData.showEditPathAlias = true;
						$scope.vm.widgetData.pathToEditAlias = path;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/selectPath/selectPath.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showSelectPath = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showSelectPath;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.pathesTable = [];
								scope.selectedPath = undefined;
								scope.source = scope.vm.widgetData.source;
								scope.sourceLib = scope.vm.widgetData.sourceLib;
								scope.sourceType = scope.vm.widgetData.sourceType;
								scope.sourceParentLib = scope.vm.widgetData.sourceParentLib;
								scope.sourceParentId = scope.vm.widgetData.sourceParentId;
								scope.target = scope.vm.widgetData.target;
								scope.targetLib = scope.vm.widgetData.targetLib;
								scope.targetType = scope.vm.widgetData.targetType;
								scope.targetParentLib = scope.vm.widgetData.targetParentLib;
								scope.targetParentId = scope.vm.widgetData.targetParentId;
								scope.searchPathesByLoop();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
