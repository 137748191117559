var getScope = function (widgetId) {
	return angular.element($('#' + widgetId + '_widget')).scope();
};

angular
	.module('dcApp')
	.directive('datablock', [
		'$parse',
		'$compile',
		'dataBlockControlerGenerator',
		function ($parse, $compile, dataBlockControlerGenerator) {
			var controller = dataBlockControlerGenerator.getController();

			return {
				restrict: 'E',

				scope: {
					widget: '=',
					autoExecute: '=',
					preExec: '=',
					enableFilter: '=',
					enableDuplicate: '=',
					disableEntiteStatus: '=',
					enableMove: '=',
					enableFormules: '=',
					enableAddColumn: '=',
					enableHistoric: '=',
					enableEntiteRebound: '=',
					enableGeolocalisation: '=',
					disableAgregation: '=',
					enableDataBlockLinks: '=',
					widgetData: '=',
					disableMenu: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/dataBlock/dataBlock.html',
				link: function (scope, element, attrs) {
					scope.jobDetails = { rerunMethod: scope.execWithoutCache };
					scope.jobDetailsId = generateUuid('_');
					scope.uuid = generateUuid('_');
					scope.vm.init(attrs);

					if (scope.vm.widget.globalSearchTextIgnoreCase == undefined) {
						scope.vm.widget.globalSearchTextIgnoreCase = true;
					}

					if (!scope.vm.widget.process_data && !scope.vm.widget.process_count) {
						scope.vm.widget.process_data = scope.vm.widget.process_count = true;
					}
					scope.vm.widget.process_summary = true;
					scope.vm.widget.reloadWidget = scope.reloadDataBlock;
					scope.vm.widget.showAddColumn = scope.showAddColumn;
					scope.vm.widgetData.reload = scope.reload;
					scope.vm.widgetData.preExec = scope.preExec;

					scope.currentExecutionWidget = scope.vm.widget;
					if (scope.vm.autoExecute) {
						scope.reloadDataBlock();
					}
					$(document).on('click', '.dropdown-menu-summary', function (e) {
						e.stopPropagation();
					});
				},
			};
		},
	])
	.filter('splice', function () {
		// Show dots after long libs
		return function (value, max) {
			if (value == undefined) {
				return '';
			}
			max = parseInt(max, 10);
			if (max == undefined || max < 0) {
				return value;
			}
			max = max / 8;
			if (value.length <= max) {
				return value;
			}
			value = value + '';
			value = value.substr(0, max * 0.85);

			return value + '...';
		};
	});
