(function () {
	'use strict';

	angular.module('dcApp').directive('tris', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'toaster',
				'$sce',
				'gettextCatalog',
				function ($scope, toaster, $sce, gettextCatalog) {
					var vm = this;
					$scope.errorDialogData = {};
					$scope.getHtmlIconByType = function (type) {
						if (type !== undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type));
						}
					};

					let movedIndex;
					$scope.moveElement = function (start, index) {
						movedIndex = start === 'start' ? index : undefined;
					};

					$scope.trieModes = [
						{
							code: 'ASC',
							label: gettextCatalog.getString('Ascendant'),
						},
						{
							code: 'DESC',
							label: gettextCatalog.getString('Descendant'),
						},
					];

					$scope.data = {};

					$scope.attributs = [];

					$scope.init = function () {
						$scope.sorts = [];
						$scope.vm.data.sortActive = !vm.data.step.sort.inactive;
						for (var g in vm.data.step.sort.sorts) {
							var s = angular.copy(
								findElement(
									vm.data.columns,
									'uuid',
									vm.data.step.sort.sorts[g].column_uuid
								)
							);
							if (s) {
								s.order = vm.data.step.sort.sorts[g].order;
								s.name = vm.data.step.sort.sorts[g].name ? vm.data.step.sort.sorts[g].name : s.lib;
								$scope.sorts.push(s);
							}
						}
					};

					$scope.dropCallbackSorts = function (event, index, item) {
						// Handle move element
						if (movedIndex !== undefined) {
							$scope.sorts.splice(movedIndex, 1);
							movedIndex = undefined;
						} else {
							if (
								_.find($scope.sorts, function (sortItem) {
									return item.uuid == sortItem.uuid;
								})
							) {
								$scope.errorDialogData.visiblePopup = true;
								$scope.errorDialogData.popupTitle =
									gettextCatalog.getString('Ajout impossible');
								$scope.errorDialogData.popupMsg = gettextCatalog.getString(
									"Il n'est pas possible de trier une colonne plus d'une fois."
								);
								return;
							}
						}

						item.order = $scope.trieModes[0].code;

						return item;
					};

					$scope.moveCallbackSorts = function (index) {
						$scope.attributs.unshift($scope.sorts[index]);
						$scope.sorts.splice(index, 1);
					};

					$scope.setAttributes = function () {
						$scope.attributs = [];
						for (var i = 0; i < vm.data.columns.length; i++) {
							var obj = {};
							obj.id = i;
							//obj.pos = i;
							obj.name = vm.data.columns[i].lib;
							obj.uuid = vm.data.columns[i].uuid;
							obj.type = vm.data.columns[i].type;
							obj.order = $scope.trieModes[0].code;
							$scope.attributs.push(obj);
						}
						for (var g in vm.data.step.sort.sorts) {
							$scope.attributs = $scope.attributs.filter(
								(p) => p.uuid !== vm.data.step.sort.sorts[g].column_uuid
							);
						}
						$scope.searchCaracByText();
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showBDTris = false;
					};

					$scope.deleteSorts = function () {
						vm.data.widgetData.showConfirmationDialog = true;
						vm.data.widgetData.confirmationDialogTitle =
							gettextCatalog.getString('Confirmer la suppression');
						vm.data.widgetData.confirmationDialogMessage =
							gettextCatalog.getString(
								'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
							);
						vm.data.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						vm.data.widgetData.enableConfirmDialogAction = true;
						vm.data.widgetData.confirmDialogAction = function () {
							confirmDeleteSorts();
						};
					};

					$scope.clearSorts = function () {
						$scope.sorts = [];
					};

					let successTra = gettextCatalog.getString('Succès');
					let successSaveTra = gettextCatalog.getString(
						'Enregistrement effectué'
					);
					var confirmDeleteSorts = function () {
						vm.data.saveSortsMethod([], !$scope.vm.data.sortActive);
						toaster.pop('success', successTra, successSaveTra);
						vm.closeModal();
					};

					$scope.save = function () {
						if (!$scope.sorts || !$scope.sorts[0]) {
							toaster.pop(
								'error',
								gettextCatalog.getString('Configuration invalide'),
								gettextCatalog.getString(
									'Configurer un tri avant de poursuivre'
								)
							);
							return;
						}
						vm.data.saveSortsMethod($scope.sorts, !$scope.vm.data.sortActive);
						toaster.pop('success', successTra, successSaveTra);
						vm.closeModal();
					};

					$scope.cancel = function () {
						vm.closeModal();
					};

					$scope.searchCaracByText = function () {
						if (
							$scope.caracSearchText === undefined ||
							$scope.caracSearchText.length === 0
						) {
							$scope.attributs_ = $scope.attributs;
						} else {
							$scope.attributs_ = _.filter($scope.attributs, function (item) {
								return (
									item.name
										.toLowerCase()
										.indexOf($scope.caracSearchText.toLowerCase()) !== -1
								);
							});
						}
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dataOperationTools/tris/tris.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showBDTris;
						},
						function () {
							scope.setAttributes();
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
