(function () {
	'use strict';

	angular.module('DatablockModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('datablock-parent', {
			url: '/datablocks',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('datablocks', {
			url: '/',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'DataBlock',
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablocks.html',
					controller: 'DataBlockListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('datablocks-new', {
			url: '/new',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Nouveau DataBlock',
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablockForm.html',
					controller: 'DataBlockEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('datablocks-edit', {
			url: '/edit/:id',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Edition du DataBlock',
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablockForm.html',
					controller: 'DataBlockEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('datablocks-duplicate', {
			url: '/duplicate/:duplicatedId',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Dupliquer le DataBlock',
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablockForm.html',
					controller: 'DataBlockEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('from-datablock-list', {
			url: ':datablockId/export',
			parent: 'datablocks',
			data: {
				admin: true,
				access: 'member',
				title: "Export à partir d'un Datablock",
			},
			params: { datablockLabel: '' },
			views: {
				'@datablocks': {
					controller: [
						'$state',
						'$stateParams',
						'$timeout',
						'NgExportTemplate',
						function ($state, $stateParams, $timeout, NgExportTemplate) {
							const $ctrl = this;
							$timeout(function () {
								NgExportTemplate.configureSelectedDatablockExport(
									$stateParams.datablockId,
									$stateParams.datablockLabel
								);
							}, 16);
						},
					],
				},
			},
		});

		$stateProvider.state('from-datablock-edit', {
			url: '/export',
			parent: 'datablocks-edit',
			data: {
				admin: true,
				access: 'member',
				title: "Export à partir d'un Datablock",
			},
			params: { datablockLabel: '' },
			views: {
				'@datablocks-edit': {
					controller: [
						'$state',
						'$stateParams',
						'$timeout',
						'NgExportTemplate',
						function ($state, $stateParams, $timeout, NgExportTemplate) {
							const $ctrl = this;
							$timeout(function () {
								NgExportTemplate.configureSelectedDatablockExport(
									$stateParams.id,
									$stateParams.datablockLabel
								);
							}, 16);
						},
					],
				},
			},
		});

		$stateProvider.state('datablocks-fromEntite', {
			url: '/fromEntite/:entiteId',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: "DataBlock à partir d'une Entité métier",
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablockForm.html',
					controller: 'DataBlockEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('datablocks-fromDatablock', {
			url: '/datablocks/fromDatablock/:datablockId',
			parent: 'datablock-parent',
			data: {
				admin: true,
				access: 'member',
				title: "DataBlock à partir d'un DataBlock",
			},
			views: {
				'@datablock-parent': {
					templateUrl: './src/components/modules/datablocks/datablockForm.html',
					controller: 'DataBlockEditController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
