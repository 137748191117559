(function () {
	'use strict';

	angular.module('dcApp').filter('defaultCharset', [
		'gettextCatalog',
		function (gettextCatalog) {
			return function (val) {
				let defaultCharset = gettextCatalog.getString('Par défault');
				if (val === 'DEFAULT') {
					return defaultCharset;
				}
				return val;
			};
		},
	]);
})();
