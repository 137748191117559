(function () {
	'use strict';

	angular.module('dcApp').controller('WebServiceController', [
		'API_BASE_URL_BACKEND',
		'$scope',
		'$sce',
		function (API_BASE_URL_BACKEND, $scope, $sce) {
			$scope.trustSrc = function () {
				return $sce.trustAsResourceUrl(
					API_BASE_URL_BACKEND + '/swagger-ui.html'
				);
			};
		},
	]);
})();
