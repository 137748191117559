(function () {
	'use strict';

	angular.module('dcApp').directive('columnorigin', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'DataBlocksService',
				'hdSourceService',
				'$timeout',
				'gettextCatalog',
				function (
					$rootScope,
					$scope,
					DataBlocksService,
					hdSourceService,
					$timeout,
					gettextCatalog
				) {
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.colAndOperation = gettextCatalog.getString(
						'Colonne / Opération'
					);
					dataGirdColumnName.type = gettextCatalog.getString('Type');
					dataGirdColumnName.element = gettextCatalog.getString('Elément');
					dataGirdColumnName.Operation = gettextCatalog.getString('Opération');
					dataGirdColumnName.step = gettextCatalog.getString('Etape');
					dataGirdColumnName.description =
						gettextCatalog.getString('Description');

					$scope.treeListOptions = {
						dataStructure: 'plain',
						rootValue: -1,
						keyExpr: 'ID',
						parentIdExpr: 'Head_ID',
						onInitialized: function (e) {
							$scope.treeInstance = e.component;
						},
						columns: [
							{
								dataField: 'text',
								caption: dataGirdColumnName.colAndOperation,
								minWidth: 300,
								allowSorting: false,
								cellTemplate: function (container, options) {
									if (options.data.isColumn) {
										container.append(
											$('<div> <b>' + options.data.text + '</b> </div>')
										);
									} else {
										container.append($('<div>' + options.data.text + '</div>'));
									}
								},
							},
							{
								dataField: 'parent_type',
								caption: dataGirdColumnName.element,
								allowSorting: false,
							},
							{
								dataField: 'parent_lib',
								caption: dataGirdColumnName.label,
								allowSorting: false,
							},
							{
								dataField: 'operation_type',
								caption: dataGirdColumnName.Operation,
								allowSorting: false,
							},
							{
								dataField: 'step',
								caption: dataGirdColumnName.step,
								allowSorting: false,
							},
							{
								dataField: 'description',
								caption: dataGirdColumnName.description,
								allowSorting: false,
								cellTemplate: function (container, options) {
									if (options.data.description) {
										container.append(
											$(
												"<div title='" +
													options.data.description +
													"'>" +
													options.data.description +
													'</div>'
											)
										);
									}
								},
							},
							{
								dataField: 'type',
								caption: dataGirdColumnName.type,
								allowSorting: false,
							},
						],
						showRowLines: true,
						showBorders: true,
						columnAutoWidth: true,
						allowColumnResizing: true,
					};

					var vm = this;

					var getTreeObject = function (data_, plainData, headId) {
						$scope.headId = $scope.headId + 1;
						var data = {
							ID: $scope.headId,
							text: data_.lib,
							isColumn: true,
							Head_ID: headId,
						};
						plainData.push(data);

						if (data_.parent_lib) {
							data.parent_lib = data_.parent_lib;
							data.parent_type = getElementTypeText(data_.parent_type);
						}
						if (data_.type) {
							data.type = data_.type;
						}

						$scope.headId = $scope.headId + 1;
						var item = {
							ID: $scope.headId,
							text: gettextCatalog.getString('Origine / Opération'),
							Head_ID: data.ID,
						};
						if (data_.operation) {
							item.isColumn = false;
							item.operation_type = getOperationText(
								data_.operation.operation_type
							);
							item.step = data_.operation.step;
							item.partition = data_.operation.partition;
							item.formula = data_.operation.formula;
							item.formula_function = data_.operation.formula_function;
							item.function = data_.operation.function;
							item.link = data_.operation.link;
							item.union = data_.operation.union;
							item.connector_type = data_.operation.connector_type;
							item.description = data_.operation.description;
							plainData.push(item);
							for (var a in data_.operation.attributes) {
								getTreeObject(
									data_.operation.attributes[a],
									plainData,
									data.ID
								);
							}
						}
						return data;
					};

					$scope.getColumnOperations = function (
						uuid,
						parentId,
						parentType,
						hdType,
						hdId
					) {
						delete $scope.treeListOptions.dataSource;
						let plainData = [];
						$scope.headId = 1;
						let getDataBlockColumnOrigin =
							parentType == 'DATAVIEW'
								? hdSourceService.getHdSourceColumnOrigin(
										parentId,
										uuid,
										hdType,
										hdId
								  )
								: DataBlocksService.getDataBlockColumnOrigin(parentId, uuid);
						getDataBlockColumnOrigin.then(function (response) {
							getTreeObject(response.data, plainData, -1);
							$scope.treeListOptions.dataSource = plainData;
							$timeout(function () {
								for (var d in plainData) {
									$scope.treeInstance.expandRow(plainData[d].ID);
								}
							}, 300);
						});
					};

					var getOperationText = function (operation) {
						switch (operation) {
							case 'MAPPING_DATABLOCK':
								return 'Ajout initial de colonne';
							case 'MAPPING_ENTITE':
								return 'Ajout initial de colonne';
							case 'MAPPING_DEPOT':
								return 'Mapping Dépôt';
							case 'MAPPING_CONNECTOR':
								return 'Mapping Connecteur';
							case 'FILTER':
								return 'Filtre';
							case 'LINK':
								return 'Lien';
							case 'UNION':
								return 'Union';
							case 'AGREGATION_GROUP':
								return 'Agrégation';
							case 'AGREGATION_FUNCTION':
								return 'Fonction';
							case 'FORMULA':
								return 'Formule';
							case 'STACK':
								return 'Stack';
							case 'EXPLODE':
								return 'Explode';
							case 'LIB_UPDATED':
								return 'Changement de libellé';
							default:
								return operation;
						}
					};

					var getElementTypeText = function (element) {
						switch (element) {
							case 'DATAVIEW':
								return 'HandleData Source';
							case 'DATABLOCK':
								return 'DataBlock';
							case 'ENTITETYPE':
								return 'Entité métier';
							case 'DEPOT':
								return 'Dépôt';
							case 'CONNECTOR':
								return 'Connecteur';
							default:
								return element;
						}
					};

					$scope.getHtmlIconByType = function (type) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type));
						}
					};

					$scope.closeModal = function () {
						$(vm.element).modal('hide');
						$scope.vm.data.showColumnOperations = false;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/columnOperations/columnOperations.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.uuid = generateUuid('_');
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showColumnOperations;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.getColumnOperations(
									scope.vm.data.columnOperationsColumnId,
									scope.vm.data.columnOperationsParentId,
									scope.vm.data.columnOperationsParentType,
									scope.vm.data.columnOperationsHdType,
									scope.vm.data.columnOperationsHdId
								);
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
