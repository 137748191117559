(function () {
	'use strict';

	angular.module('dcApp').constant('META_DATA_CATALOGUE_REF_STATUS', {
		active: 'active',
		inactive: 'inactive',
	});

	angular.module('dcApp').directive('metaData', [
		'$parse',
		function ($parse) {
			var controller = [
				'$http',
				'API_BASE_URL_BACKEND',
				'$scope',
				'$rootScope',
				'TagService',
				'$stateParams',
				'META_DATA_CATALOGUE_REF_STATUS',
				'PictogrammeService',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'$timeout',
				'MetadataService',
				'toaster',
				'permissionChecker',
				'gettextCatalog',
				'UserService',
				function (
					$http,
					API_BASE_URL_BACKEND,
					$scope,
					$rootScope,
					TagService,
					$stateParams,
					META_DATA_CATALOGUE_REF_STATUS,
					PictogrammeService,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					$timeout,
					MetadataService,
					toaster,
					permissionChecker,
					gettextCatalog,
					UserService
				) {
					var vm = this;
					vm.metadataCatalogueRefStatus = META_DATA_CATALOGUE_REF_STATUS;
					vm.havePermission = $rootScope.havePermission;
					$scope.confirmationData = {};
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.picture = gettextCatalog.getString('Image');
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.accessToken = window._keycloak.token;

					$scope.meta = {
						imageURL: API_BASE_URL_BACKEND + 'pictogramme_image?id=',
					};

					vm.searchTags = function ($query) {
						return $http
							.get(API_BASE_URL_BACKEND + 'tags/?tag' + $query)
							.then(function (response) {
								return response;
							});
					};

					vm.loadTags = function ($query) {
						return $http.get(API_BASE_URL_BACKEND + '/tags?text=' + $query);
					};

					$scope.cancelAdvanced = function (id) {
						$('#advancedDataModal-' + id).modal('hide');
					};

					$scope.saveAdvanced = function (id) {
						$('#advancedDataModal-' + id).modal('hide');
					};

					vm.checkError = function (fieldName) {
						return $rootScope.checkErrorBoolean(fieldName);
					};

					$scope.buildIconUrl = function () {
						vm.data.icon_id =
							vm.data.icon_id != undefined ? vm.data.icon_id : -1;
						$scope.imageURL = $scope.meta.imageURL + vm.data.icon_id;
					};

					function getMetadataIcon() {
						if (vm.data.id != undefined || vm.data.metadata_id) {
							var id =
								vm.data.metadata_id != undefined
									? vm.data.metadata_id
									: vm.data.id;
							PictogrammeService.getPictogrammeByMetatDataId(id).then(function (
								response
							) {
								vm.data.icon_id = response.data;
								$scope.buildIconUrl();
							});
						}
					}
					$scope.intiated = false;
					$scope.$watch(
						angular.bind(this, function () {
							return this.data;
						}),
						function (newVal, oldValue) {
							if (newVal != undefined) {
								getMetadataIcon();
								$scope.buildIconUrl();
								$scope.metadataPopover = {};
								vm.init();
								$scope.intiated = true;
							} else {
								if (!$scope.intiated) {
									$scope.imageURL = $scope.meta.imageURL + '-1';
									$scope.intiated = false;
								}
							}
						}
					);
					vm.init = function () {
						$scope.htmlId =
							vm.config && vm.config.htmlId ? vm.config.htmlId : '';
						if (!vm.isNew) {
							// edit or duplicate
							vm.hide_advanced = true;
						}
						vm.data.tags = _.sortBy(vm.data.tags, function (elm) {
							return elm.position;
						});
						vm.metadataInfo = {
							id: vm.elementId,
							code: vm.data.code,
							updateDate: vm.data.update_date,
							creationDate: vm.data.creation_date,
							createdBy: vm.data.created_by,
							updatedBy: vm.data.updated_by,
							metadataId:
								vm.data.metadata_id != undefined
									? vm.data.metadata_id
									: vm.data.id,
						};
					};

					vm.sortTags = function () {
						vm.data.tags = _.sortBy(vm.data.tags, function (elm) {
							return elm.position;
						});
					};

					$scope.getDateTimeWithPattern = function (date) {
						return $rootScope.getDateTimeWithPattern(date);
					};

					$scope.saveAttachedPictogrammes = function (iconId) {
						if (iconId != -1) {
							vm.data.icon_id = iconId;
						} else {
							vm.data.icon_id = -1;
						}

						$scope.buildIconUrl();
					};

					$scope.iconsSelector = {};
					$scope.selectIcon = function () {
						$scope.iconsSelector.saveSelection =
							$scope.saveAttachedPictogrammes;
						$scope.iconsSelector.showIconsSelector = true;
					};

					function updateTagPosition() {
						for (let i = 0; i < vm.data.tags.length; i++) {
							vm.data.tags[i].position = i;
						}
						// Refresh selected
						_.each($scope.availableTags, function (elm) {
							elm.selected = elementAlreadySelected(elm);
						});
					}

					$scope.sortableOptions = {
						'ui-floating': true,
						stop: function () {
							updateTagPosition();
						},
					};

					$scope.deleteTag = function (index) {
						vm.data.tags.splice(index, 1);
						updateTagPosition();
					};

					$scope.tagToEditIndex = undefined;
					$scope.tag = {};
					$scope.updateTag = function (index, modalId) {
						$scope.tagToEditIndex = index;
						$scope.tag = angular.copy(vm.data.tags[index]);
						if ($scope.tag) {
							$scope.tag.color = $scope.tag.color
								? $scope.tag.color
								: '#dbf5d1';
							$('#' + modalId + $scope.htmlId).modal('show');
						}
					};

					$scope.saveTag = function (modalId) {
						TagService.saveTag($scope.tag).then(function (response) {
							if ($scope.tagToEditIndex !== undefined) {
								vm.data.tags[$scope.tagToEditIndex] = response.data;
							} else {
								vm.data.tags.push(response.data);
							}
							let message =
								$scope.tagToEditIndex !== undefined
									? gettextCatalog.getString('Modification du Tag effectuée')
									: gettextCatalog.getString('Création du Tag effectuée');
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								message
							);
							$('#' + modalId + $scope.htmlId).modal('hide');
							updateTagPosition();
							// update available tags
							$scope.getAvailableTags();
						});
					};

					$scope.availableTags = [];
					$scope.getAvailableTags = function (search) {
						search = search != undefined ? search : '';
						$scope.selectTag = {};
						vm.loadTags(search).then(function (response) {
							//sort and check if selected
							$scope.availableTags = _.sortBy(response.data, function (tag) {
								tag.selected = elementAlreadySelected(tag);
								return tag.code && tag.code.toLowerCase();
							});
							// Add "new tag option"
							$scope.availableTags.unshift({
								code: gettextCatalog.getString('Nouveau Tag'),
								color: 'white',
								create: true,
							});
						});
					};

					$scope.selectTag = {};
					$scope.tagSelected = function (tag, modalId) {
						if (tag.selected) {
							vm.data.tags = _.without(
								vm.data.tags,
								_.findWhere(vm.data.tags, { code: tag.code })
							);
							tag.selected = false;
							return;
						}
						if (vm.data.tags.length >= 10) {
							toaster.pop(
								'error',
								gettextCatalog.getString('Erreur'),
								gettextCatalog.getString(
									'Vous ne pouvez pas attribuer plus de 10 Tags'
								)
							);
							return;
						}
						if (tag.searchValue) {
							let tagSearch = _.find($scope.availableTags, function (t) {
								return t.code == tag.searchValue;
							});
							if (!tagSearch) {
								tag = { create: true, searchValue: tag.searchValue };
							} else {
								tag = tagSearch;
							}
						}
						if (tag.create) {
							$scope.tagToEditIndex = undefined;
							$scope.tag = { color: '#dbf5d1', code: tag.searchValue };
							$('#' + modalId + $scope.htmlId).modal('show');
						} else if (tag && tag.code && !tag.selected) {
							vm.data.tags.push(tag);
						}
						// Refresh selected
						_.each($scope.availableTags, function (elm) {
							elm.selected = elementAlreadySelected(elm);
						});
						$scope.selectTag = {};
					};

					$scope.tagTransform = function (newTag) {
						let tag = {
							code: newTag + ' (' + gettextCatalog.getString('Nouveau') + ')',
							searchValue: newTag,
							color: 'white',
						};

						return tag;
					};

					permissionChecker.checkGlobalPermission(
						'tags',
						function () {
							$scope.getAvailableTags();
						},
						function () {}
					);
					let referencedUpdated = false;
					$scope.addDataCatalogue = function () {
						referencedUpdated = true;
					};

					$scope.closeDialogAction = function () {
						if (referencedUpdated) {
							referencedUpdated = false;
							return;
						}
						vm.data.referenced = !vm.data.referenced;
						referencedUpdated = false;
					};

					$scope.showAddDataCatalogue = function () {
						if (vm.datacatalogueDisabled) {
							return;
						}
						if (vm.data.referenced) {
							$scope.confirmationData.confirmationDialogTitle =
								gettextCatalog.getString('Ajouter au DataCatalogue');
							$scope.confirmationData.confirmationDialogMessage =
								gettextCatalog.getString(
									'metadata.fields.dataCatalogue.confirmation'
								);
							$scope.confirmationData.confirmationDialogActionName =
								gettextCatalog.getString('Ajouter');
							$scope.confirmationData.confirmationGreenAction = true;
						} else {
							$scope.confirmationData.confirmationDialogTitle =
								gettextCatalog.getString('Retirer du DataCatalogue');
							$scope.confirmationData.confirmationDialogMessage =
								gettextCatalog.getString(
									"Êtes-vous sûr de vouloir desactiver l'option DataCatalogue ? "
								);
							$scope.confirmationData.confirmationDialogActionName =
								gettextCatalog.getString('Désactiver');
							$scope.confirmationData.confirmationGreenAction = false;
						}
						$scope.confirmationData.enableConfirmDialogAction = true;
						$scope.confirmationData.confirmDialogAction =
							$scope.addDataCatalogue;
						$scope.confirmationData.closeDialogAction =
							$scope.closeDialogAction;
						$scope.confirmationData.showConfirmationDialog = true;
					};

					function elementAlreadySelected(tag) {
						return (
							_.find(vm.data.tags, function (t) {
								return t.code == tag.code;
							}) != undefined
						);
					}

					$scope.closeMe = function (modalId) {
						$('#' + modalId + $scope.htmlId).modal('hide');
					};
					$scope.setActiveIndex = function ($select) {
						$timeout(() => {
							if ($select.selected === undefined) {
								$select.activeIndex = -1;
							}
						}, 25);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					hasCatalogue: '=',
					datacatalogueDisabled: '=',
					elementId: '=',
					callback: '&',
					expression: '@',
					disabled: '=',
					isNew: '=',
					disableCode: '=',
					enableCode: '=',
					config: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				//required in 1.3+ with controllerAs
				bindToController: true,
				templateUrl: './src/components/directives/metadata/metadata.html',
				link: function (scope, element, attrs, $ctrl) {
					$ctrl.isReferenced =
						$ctrl.hasCatalogue === $ctrl.metadataCatalogueRefStatus.active;
					scope.$watch(
						function () {
							return scope.vm.data;
						},
						function (newValue, OldValue) {
							if (newValue && newValue !== OldValue) {
								scope.vm.sortTags();
							}
						}
					);

					scope.$watch(
						function () {
							return scope.vm.isNew;
						},
						function (newValue, OldValue) {
							if (newValue !== OldValue) {
								scope.vm.hide_advanced = !newValue;
							}
						}
					);

					scope.$watch(
						function () {
							return scope.vm.disableCode;
						},
						function (newValue, OldValue) {
							if (newValue !== OldValue) {
								scope.vm.disableCode = newValue;
							}
						}
					);
				},
			};
		},
	]);
})();
