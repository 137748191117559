'use strict';

angular
	.module('dcApp')
	.factory('ConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'HTTPConnectorService',
		'DBConnectorService',
		'ESConnectorService',
		'S3ConnectorService',
		'SFTPConnectorService',
		'HDFSConnectorService',
		'UrlService',
		function (
			$http,
			$rootScope,
			API_BASE_URL_BACKEND,
			HTTPConnectorService,
			DBConnectorService,
			ESConnectorService,
			S3ConnectorService,
			SFTPConnectorService,
			HDFSConnectorService,
			UrlService
		) {
			var ConnectorService = {};

			/**
			 * Get connector list
			 * @param excludedTypes
			 *      Connector type to be excluded. This can be a string or an array of string
			 * @param includedTypes
			 *      Connector type to be included. This can be a string or an array of string
			 * @param details
			 *      The service should return a lib and the code or a metadata object. True to return metadata object. Default value true
			 * @returns {*}
			 */
			ConnectorService.getConnectorsList = function (
				excludedTypes,
				includedTypes,
				details,
				excludePublic,
				excludeInactive
			) {
				const url = UrlService.generateUrl('CONNECTORS', {},
					{
						excludePublic: excludePublic ? excludePublic : false,
						excludeInactive: excludeInactive ? excludeInactive : false,
						details: typeof details === 'boolean' ? details : true,
						excludedTypes: excludedTypes,
						includedTypes: includedTypes
					}
				);

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ConnectorService.getConnectorById = function (connectorId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ConnectorService.deleteConnectors = function (connectorsIds) {
				var url = API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('CONNECTORS');
				if (Array.isArray(connectorsIds)) {
					url = url + '?connectorIds=' + connectorsIds[0];
					for (let i = 1; i < connectorsIds.length; i++) {
						url = url + '&connectorIds=' + connectorsIds[i];
					}
				} else {
					url = url + '?connectorIds=' + connectorsIds;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			ConnectorService.listRemoteFilesById = function (
				connectorId,
				path,
				type
			) {
				let uri = getSuitableUrlByType(type);
				if (!uri) {
					return new Promise((resolve, reject) => {
						throw new Error('type.non.supported');
					});
				}

				let url = API_BASE_URL_BACKEND + uri;
				url =
					url.replace('{connectorId}', connectorId) +
					'?remotePath=' +
					encodeURIComponent(path);

				return $http.get(url).then(function (response) {
					return response;
				});

				function getSuitableUrlByType(type) {
					type = type ? type.toLowerCase() : type;
					switch (type) {
						case 'sftp':
							return $rootScope.getUrl('SFTP_LIST_CONNECTOR_FILES_BY_ID');
						case 'hdfs':
							return $rootScope.getUrl('HDFS_LIST_CONNECTOR_FILES_BY_ID');
						case 's3':
							return $rootScope.getUrl('S3_LIST_CONNECTOR_FILES_BY_ID');
						default:
							return null;
					}
				}
			};

			ConnectorService.listRemoteFiles = function (connector, path, type) {
				let uri = getSuitableUrlByType(type);
				if (!uri) {
					return new Promise((resolve, reject) => {
						throw new Error('type.non.supported');
					});
				}

				let url =
					API_BASE_URL_BACKEND +
					uri +
					'?remotePath=' +
					encodeURIComponent(path);

				return $http.post(url, connector).then(function (response) {
					return response;
				});

				function getSuitableUrlByType(type) {
					type = type ? type.toLowerCase() : type;
					switch (type) {
						case 'sftp':
							return $rootScope.getUrl('SFTP_LIST_CONNECTOR_FILES');
						case 'hdfs':
							return $rootScope.getUrl('HDFS_LIST_CONNECTOR_FILES');
						case 's3':
							return $rootScope.getUrl('S3_LIST_CONNECTOR_FILES');
						default:
							return null;
					}
				}
			};

			return ConnectorService;
		},
	])
	.factory('HTTPConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var HTTPConnectorService = {};

			HTTPConnectorService.getHTTPConnectors = function () {
				return $http
					.get(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('HTTP_CONNECTORS')
					)
					.then(function (response) {
						return response;
					});
			};

			HTTPConnectorService.getHTTPConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HTTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			HTTPConnectorService.createHTTPConnector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('HTTP_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			HTTPConnectorService.editHTTPConnector = function (
				connectorId,
				connector
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HTTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			HTTPConnectorService.deleteHTTPConnector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HTTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			HTTPConnectorService.checkHTTPConnection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('HTTP_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			return HTTPConnectorService;
		},
	])
	.factory('DBConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var DBConnectorService = {};

			DBConnectorService.getDBConnectors = function () {
				return $http
					.get(API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('DB_CONNECTORS'))
					.then(function (response) {
						return response;
					});
			};

			DBConnectorService.getSimilarDBConnectors = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('DB_CONNECTORS_SIMILAR');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DBConnectorService.getDBConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('DB_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DBConnectorService.createDBConnector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('DB_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			DBConnectorService.editDBConnector = function (connectorId, connector) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('DB_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			DBConnectorService.deleteDBConnector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('DB_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DBConnectorService.checkDBConnection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('DB_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			DBConnectorService.getMongodbCollections = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('MONGODB_COLLECTION_BY_CONNECTOR_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return DBConnectorService;
		},
	])
	.factory('ESConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var ESConnectorService = {};

			ESConnectorService.getESConnectors = function () {
				return $http
					.get(API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('ES_CONNECTORS'))
					.then(function (response) {
						return response;
					});
			};

			ESConnectorService.getESConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('ES_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ESConnectorService.createESConnector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('ES_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			ESConnectorService.editESConnector = function (connectorId, connector) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('ES_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			ESConnectorService.deleteESConnector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('ES_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			ESConnectorService.checkESConnection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('ES_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			return ESConnectorService;
		},
	])
	.factory('S3ConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var S3ConnectorService = {};

			S3ConnectorService.getS3Connectors = function () {
				return $http
					.get(API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('S3_CONNECTORS'))
					.then(function (response) {
						return response;
					});
			};

			S3ConnectorService.getS3ConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('S3_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			S3ConnectorService.createS3Connector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('S3_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			S3ConnectorService.editS3Connector = function (connectorId, connector) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('S3_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			S3ConnectorService.deleteS3Connector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('S3_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			S3ConnectorService.checkS3Connection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('S3_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			return S3ConnectorService;
		},
	])
	.factory('SFTPConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var SFTPConnectorService = {};

			SFTPConnectorService.getSFTPConnectors = function () {
				return $http
					.get(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('SFTP_CONNECTORS')
					)
					.then(function (response) {
						return response;
					});
			};

			SFTPConnectorService.getSFTPConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('SFTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			SFTPConnectorService.createSFTPConnector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('SFTP_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			SFTPConnectorService.editSFTPConnector = function (
				connectorId,
				connector
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('SFTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			SFTPConnectorService.deleteSFTPConnector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('SFTP_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			SFTPConnectorService.checkSFTPConnection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('SFTP_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			return SFTPConnectorService;
		},
	])
	.factory('HDFSConnectorService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var HDFSConnectorService = {};

			HDFSConnectorService.getHDFSConnectors = function () {
				return $http
					.get(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('HDFS_CONNECTORS')
					)
					.then(function (response) {
						return response;
					});
			};

			HDFSConnectorService.getHDFSConnectorById = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HDFS_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			HDFSConnectorService.createHDFSConnector = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND + '/' + $rootScope.getUrl('HDFS_CONNECTORS'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			HDFSConnectorService.editHDFSConnector = function (
				connectorId,
				connector
			) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HDFS_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.put(url, connector).then(function (response) {
					return response;
				});
			};

			HDFSConnectorService.deleteHDFSConnector = function (connectorId) {
				var url =
					API_BASE_URL_BACKEND +
					'/' +
					$rootScope.getUrl('HDFS_CONNECTORS_BY_ID');
				url = url.replace('{connectorId}', connectorId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			HDFSConnectorService.checkHDFSConnection = function (connector) {
				return $http
					.post(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('HDFS_CONNECTORS_CHECK_CONNECTION'),
						connector
					)
					.then(function (response) {
						return response;
					});
			};

			return HDFSConnectorService;
		},
	]);
