(function () {
	'use strict';

	angular.module('dcApp').factory('EntityFromDepotService', [
		'$rootScope',
		'$http',
		'$q',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, $q, API_BASE_URL_BACKEND) {
			let EntityFromDepotService = {};

			EntityFromDepotService.createEntityFromDepot = function (
				depotId,
				createRepDetails,
				data
			) {
				createRepDetails = createRepDetails ? createRepDetails : false;
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('ENTITY_FROM_DEPOT');
				url =
					url.replace('{depotId}', depotId) +
					'?createRepDetails=' +
					createRepDetails;
				return $http.post(url, data).then(function (response) {
					return response;
				});
			};

			return EntityFromDepotService;
		},
	]);
})();
