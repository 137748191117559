(function () {
	'use strict';

	angular.module('HdModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		// Maps
		$stateProvider.state('map-parent', {
			url: '/map',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('maps', {
			url: '/',
			parent: 'map-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Cartes',
			},
			views: {
				'@map-parent': {
					templateUrl: './src/components/modules/hd/map/maps.html',
					controller: 'MapListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('maps-new', {
			url: '/new',
			parent: 'map-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouvelle Carte',
			},
			views: {
				'@map-parent': {
					templateUrl: './src/components/modules/hd/map/mapForm.html',
					controller: 'MapEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('maps-edit', {
			url: '/edit/:id',
			parent: 'map-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition de la Carte',
			},
			views: {
				'@map-parent': {
					templateUrl: './src/components/modules/hd/map/mapForm.html',
					controller: 'MapEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('maps-view', {
			url: '/edit/:id/:mode',
			parent: 'map-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation de la Carte',
			},
			views: {
				'@map-parent': {
					templateUrl: './src/components/modules/hd/map/mapForm.html',
					controller: 'MapEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: pas mapper, click sur action duplique mene directement sur la page editon du duplicat
		$stateProvider.state('maps-duplicate', {
			url: '/duplicate/:duplicatedId',
			parent: 'map-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Dupliquer la carte',
			},
			views: {
				'@map-parent': {
					templateUrl: './src/components/modules/hd/map/mapForm.html',
					controller: 'MapEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// Timelines

		$stateProvider.state('timeline-parent', {
			url: '/timeline',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('timelines', {
			url: '/',
			parent: 'timeline-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'TimeLine',
			},
			views: {
				'@timeline-parent': {
					templateUrl: './src/components/modules/hd/timeline/timelines.html',
					controller: 'TimeLineListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('timelines-new', {
			url: '/new',
			parent: 'timeline-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouvelle TimeLine',
			},
			views: {
				'@timeline-parent': {
					templateUrl: './src/components/modules/hd/timeline/timelineForm.html',
					controller: 'TimeLineEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('timelines-edit', {
			url: '/edit/:id',
			parent: 'timeline-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition de la TimeLine',
			},
			views: {
				'@timeline-parent': {
					templateUrl: './src/components/modules/hd/timeline/timelineForm.html',
					controller: 'TimeLineEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: pas mapper, click sur action duplique mene directement sur la page editon du duplicat
		$stateProvider.state('timelines-view', {
			url: '/edit/:id/:mod',
			parent: 'timeline-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation de la TimeLine',
			},
			views: {
				'@timeline-parent': {
					templateUrl: './src/components/modules/hd/timeline/timelineForm.html',
					controller: 'TimeLineEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('timelines-duplicate', {
			url: '/duplicate/:duplicatedId',
			parent: 'timeline-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Dupliquer la TimeLine',
			},
			views: {
				'@timeline-parent': {
					templateUrl: './src/components/modules/hd/timeline/timelineForm.html',
					controller: 'TimeLineEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// Charts

		$stateProvider.state('chart-parent', {
			url: '/chart',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('charts', {
			url: '/',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Graphiques',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/charts.html',
					controller: 'ChartListController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: rename state
		$stateProvider.state('charts-select', {
			url: '/select',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Sélectionner le Graphique',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/newChart.html',
					controller: 'NewChartController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('charts-new', {
			url: '/new/:type/:barType',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouveau Graphique',
			},
			params: {
				type: '',
				barType: '',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/chartForm.html',
					controller: 'ChartEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('charts-edit', {
			url: '/:type/edit/:id',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition du Graphique',
			},
			params: {
				type: '',
				barType: '',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/chartForm.html',
					controller: 'ChartEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('charts-view', {
			url: '/:type/edit/:id/:mode',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation du Graphique',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/chartForm.html',
					controller: 'ChartEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: pas mapper, click sur action duplique mene directement sur la page editon du duplicat
		$stateProvider.state('charts-duplicate', {
			url: '/:type/duplicate/:duplicatedId',
			parent: 'chart-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Dupliquer le Graphique',
			},
			views: {
				'@chart-parent': {
					templateUrl: './src/components/modules/hd/chart/chartForm.html',
					controller: 'ChartEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// Grids

		$stateProvider.state('grid-parent', {
			url: '/grid',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('grids', {
			url: '/',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Tableaux',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/grids.html',
					controller: 'GridListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('grids-select', {
			url: '/select',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Sélectionner le Tableau',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/newGrid.html',
					controller: 'NewGridController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('grids-new', {
			url: '/new/:type',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouveau Tableau',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/gridForm.html',
					controller: 'GridEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('grids-edit', {
			url: '/:type/edit/:id',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition du Tableau',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/gridForm.html',
					controller: 'GridEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('grids-view', {
			url: '/:type/edit/:id/:mode',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation du Tableau',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/gridForm.html',
					controller: 'GridEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: pas mapper, click sur action duplique mene directement sur la page editon du duplicat
		$stateProvider.state('grids-duplicate', {
			url: '/:type/duplicate/:duplicatedId',
			parent: 'grid-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Dupliquer le Tableau',
			},
			views: {
				'@grid-parent': {
					templateUrl: './src/components/modules/hd/grid/gridForm.html',
					controller: 'GridEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// Media

		$stateProvider.state('media-parent', {
			url: '/media',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('medias', {
			url: '/',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Médias',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/medias.html',
					controller: 'MediaListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('medias-select', {
			url: '/select',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Sélectionner le Média',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/newMedia.html',
					controller: 'NewMediaController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('medias-new', {
			url: '/new/:type',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouveau Média',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/mediaForm.html',
					controller: 'MediaEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('medias-edit', {
			url: '/:type/edit/:id',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition du Média',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/mediaForm.html',
					controller: 'MediaEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('medias-view', {
			url: '/:type/edit/:id/:mode',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation du Média',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/mediaForm.html',
					controller: 'MediaEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// FIXME: pas mapper, click sur action duplique mene directement sur la page editon du duplicat
		$stateProvider.state('medias-duplicate', {
			url: '/:type/duplicate/:duplicatedId',
			parent: 'media-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Dupliquer le Média',
			},
			views: {
				'@media-parent': {
					templateUrl: './src/components/modules/hd/media/mediaForm.html',
					controller: 'MediaEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		// Dashboards

		$stateProvider.state('dashboard-parent', {
			url: '/dashboard',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('dashboards', {
			url: '/',
			parent: 'dashboard-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Tableaux de Bord',
			},
			views: {
				'@dashboard-parent': {
					templateUrl: './src/components/modules/hd/dashboard/dashboards.html',
					controller: 'DashboardListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('dashboards-new', {
			url: '/new',
			parent: 'dashboard-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Nouveau Tableau de bord',
			},
			views: {
				'@dashboard-parent': {
					templateUrl:
						'./src/components/modules/hd/dashboard/dashboardForm.html',
					controller: 'DashboardEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('dashboards-edit', {
			url: '/edit/:id',
			parent: 'dashboard-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Edition du Tableau de bord',
			},
			views: {
				'@dashboard-parent': {
					templateUrl:
						'./src/components/modules/hd/dashboard/dashboardForm.html',
					controller: 'DashboardEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('dashboards-view', {
			url: '/edit/:id/:mode',
			parent: 'dashboard-parent',
			data: {
				admin: false,
				access: 'member',
				title: 'Visualisation du Tableau de bord',
			},
			views: {
				'@dashboard-parent': {
					templateUrl:
						'./src/components/modules/hd/dashboard/dashboardForm.html',
					controller: 'DashboardEditController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
