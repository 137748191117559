(function () {
	'use strict';

	angular.module('dcApp').factory('ProjectMessageService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'httpService',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, httpService, $q) {
			var ProjectMessageService = {};

			ProjectMessageService.sendMessage = function (projectId, message) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_MESSAGE');
				url = url.replace('{id}', projectId);
				return httpService.postRequest(url, message);
			};

			return ProjectMessageService;
		},
	]);
})();
