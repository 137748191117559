(function () {
	'use strict';

	angular.module('dcApp').directive('consumedElementError', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'gettextCatalog',
				function ($scope, $state, gettextCatalog) {
					let deleteReportTra = gettextCatalog.getString(
						'Rapport de suppression'
					);

					$scope.visiblePopup = false;
					$scope.popupOptions = {
						width: 600,
						height: 'auto',
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						title: deleteReportTra,
						dragEnabled: false,
						shading: false,
						closeOnOutsideClick: true,
						bindingOptions: {
							visible: 'visiblePopup',
						},
						onHidden: function () {
							$scope.visiblePopup = false;
							$scope.vm.showErr = false;
						},
					};

					$scope.getTypeLabelFromHdhType = function (type) {
						return getTypeLabelFromHdhType(type);
					};

					$scope.showInfo = function () {
						$scope.elements = [];
						$scope.totalRequestElement = $scope.vm.data.total_request_element;
						$scope.totalDeletedElement = $scope.vm.data.total_deleted_element;
						$scope.showDetails = {};
						if ($scope.totalRequestElement > 0) {
							$scope.reports = $scope.vm.data.element_dependencies;
						} else {
							for (let i in $scope.vm.data.element_dependencies) {
								$scope.elementLib = $scope.vm.data.element_dependencies[i].v1;
								$scope.is_exposed = $scope.vm.data.element_dependencies[i].v3;
								for (let j in $scope.vm.data.element_dependencies[i].v2) {
									$scope.elements.push(
										$scope.vm.data.element_dependencies[i].v2[j]
									);
								}
							}
						}
						$scope.visiblePopup = true;
					};

					$scope.getLink = function (el) {
						let url = '#';
						if (el.type === 'DEPOT') {
							url = goToDepot(el);
						} else if (el.type === 'ENTITETYPE') {
							url = goToEntity(el);
						} else if (el.type === 'DATABLOCK') {
							url = goToDataBlock(el);
						} else if (el.type === 'GRID') {
							url = goToGrid(el);
						} else if (el.type === 'DASHBOARD') {
							url = goToDashboard(el);
						} else if (el.type === 'MAP') {
							url = goToMap(el);
						} else if (el.type === 'TIMELINE') {
							url = goToTimeLine(el);
						} else if (el.type === 'CHART') {
							url = goToChart(el);
						} else if (el.type === 'DATABLOCK_EXPORT_TEMPLATE') {
							// TODO Add template URL
							url = "/";
						}
						window.open(url, '_blank');
					};

					function goToDepot(depot) {
						let url = '#';
						if (depot.sub_type) {
							let subType = depot.sub_type.toUpperCase();
							if (subType.indexOf('CSV') > -1) {
								url = $state.href('depots-edit', { id: depot.id, type: 'CSV' });
							} else if (subType.indexOf('JSON') > -1) {
								url = $state.href('depots-edit', {
									id: depot.id,
									type: 'JSON',
								});
							} else if (subType.indexOf('PARQUET') > -1) {
								url = $state.href('depots-edit', {
									id: depot.id,
									type: 'PARQUET',
								});
							} else if (subType.indexOf('BINARY') > -1) {
								url = $state.href('depots-edit', {
									id: depot.id,
									type: 'BINARY',
								});
							} else if (subType.indexOf('EXCEL') > -1) {
								url = $state.href('depots-edit', {
									id: depot.id,
									type: 'EXCEL',
								});
							} else if (subType.indexOf('SQL') > -1) {
								url = $state.href('depots-edit', { id: depot.id, type: 'SQL' });
							}
						}

						return url;
					}

					function goToEntity(entity) {
						return $state.href('entites-edit', { entiteId: entity.id });
					}

					function goToDataBlock(db) {
						return $state.href('datablocks-edit', { id: db.id });
					}

					function goToGrid(grid) {
						return $state.href('grids-edit', {
							id: grid.id,
							type: grid.sub_type,
						});
					}

					function goToDashboard(dashboard) {
						return $state.href('dashboards-edit', { id: dashboard.id });
					}

					function goToMap(map) {
						return $state.href('maps-edit', { id: map.id });
					}

					function goToTimeLine(timeLine) {
						return $state.href('timelines-edit', { id: timeLine.id });
					}

					function goToChart(chart) {
						return $state.href('charts-edit', {
							id: chart.id,
							type: chart.sub_type,
						});
					}
					// bug wheel scroll inside dx popup fix
					$('#popupConsumedTemplate').on('dxmousewheel', function (e) {
						e.stopPropagation();
					});
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=', showErr: '=' },
				controller,
				'./src/components/directives/consumedElementError/consumedElementError.html',
				function postLink(scope, element, attrs) {
					scope.$watch(
						function () {
							return scope.vm.showErr;
						},
						function (value) {
							if (value) {
								scope.showInfo();
							}
						}
					);
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target.id == element[0].id) {
							scope.vm.showErr = false;
						}
					});
				}
			);
		},
	]);
})();
