(function () {
	'use strict';

	angular.module('dcApp').directive('datablockcreator', [
		'$parse',
		'DataBlocksService',
		'toaster',
		'gettextCatalog',
		function ($parse, DataBlocksService, toaster, gettextCatalog) {
			var controller = [
				'$scope',
				'$rootScope',
				'UPDATE_EVENT',
				function ($scope, $rootScope, UPDATE_EVENT) {
					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopCreationDatablock = gettextCatalog.getString(
						'Création du Datablock effectuée'
					);

					var vm = this;

					$scope.save = function (reloadData) {
						if (reloadData) {
							var entiteTypeList = [];
							if (vm.data.entiteTypeList) {
								for (var et in vm.data.entiteTypeList) {
									entiteTypeList.push(vm.data.entiteTypeList[et].id);
								}
							} else {
								entiteTypeList.push(vm.data.entiteTypeId);
							}
							DataBlocksService.createDataBlockByUets(entiteTypeList).then(
								function (response) {
									toaster.pop(
										'success',
										toasterPopSuccess,
										toasterPopCreationDatablock
									);
									$rootScope.$broadcast(UPDATE_EVENT['DATABLOCK']);
									if (vm.data.showReloadConfirmattionOnOK) {
										vm.data.showReloadConfirmattionOnOK(response.data[0]);
									}
								}
							);
						} else {
							if (vm.data.showReloadConfirmattionOnNotOK) {
								vm.data.showReloadConfirmattionOnNotOK();
							}
						}

						$scope.closeModal();
					};

					$scope.cancel = function () {
						$scope.closeModal();
					};

					$scope.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showReloadConfirmattion = false;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dataBlockCreator/dataBlockCreator.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showReloadConfirmattion = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data
								? scope.vm.data.showReloadConfirmattion
								: false;
						},
						function () {}
					);
				}
			);
		},
	]);
})();
