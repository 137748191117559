(function () {
	'use strict';

	angular.module('dcApp').filter('normalize', function () {
		const normalizationForm = 'NFD';
		const nfdRegex = /[\u0300-\u036f]/g;

		return function (attribute, toLowerCase, toUpperCase) {
			if (!attribute) {
				return;
			}
			var normalizedAttribute = attribute
				.normalize(normalizationForm)
				.replace(nfdRegex, ''); // Remove accents
			normalizedAttribute = normalizedAttribute.replaceAll(' ', '_'); // replace whitespaces by underscore
			normalizedAttribute = normalizedAttribute.replace(
				/[`~!@#$%^&*()°|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
				''
			); // Remove Spacial Characs
			if (toLowerCase) {
				return normalizedAttribute.replace(nfdRegex, (m) => '').toLowerCase();
			} else if (toUpperCase) {
				return normalizedAttribute.replace(nfdRegex, (m) => '').toUpperCase();
			} else {
				return normalizedAttribute.replace(nfdRegex, (m) => '');
			}
		};
	});
})();
