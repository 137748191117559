(function () {
	'use strict';

	angular.module('dcApp').factory('ProjectService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'httpService',
		'$q',
		'ProjectMemberAdapter',
		'ProjectStatAdapter',
		'ProjectDuplicateAdapter',
		'DeleteProjectAdapter',
		'UrlService',
		function (
			$http,
			$rootScope,
			API_BASE_URL_BACKEND,
			httpService,
			$q,
			ProjectMemberAdapter,
			ProjectStatAdapter,
			ProjectDuplicateAdapter,
			DeleteProjectAdapter,
			UrlService
		) {
			var ProjectService = {};

			ProjectService.getProjects = function () {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_GET_ALL');
				return httpService.getRequest(url);
			};

			ProjectService.getProject = getProjectById;

			ProjectService.getProjectMetada = getProjectMetadataById;

			ProjectService.createProject = function (project) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_CREATE');
				return httpService.postRequest(url, project);
			};

			ProjectService.editProject = function (projectId, projectData) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_EDIT');
				url = url.replace('{id}', projectId);
				return httpService.putRequest(url, projectData, false);
			};

			ProjectService.deleteProject = function (projectId, token) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DELETE');
				url = url.replace('{id}', projectId);
				url = url + '?token=' + token;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			ProjectService.duplicateProject = function (projectId, options) {
				let deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DUPLICATE');
				url = url.replace('{id}', projectId);
				return httpService.postRequest(url, options);
			};

			ProjectService.cancelDuplicateProject = function (
				projectId,
				executionId
			) {
				let deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DUPLICATE_CANCEL');
				url = url.replace('{id}', projectId);
				url = url.replace('{executionId}', executionId);
				return httpService.postRequest(url, {});
			};

			ProjectService.exportProject = function (projectId, options) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_EXPORT');
				url = url.replace('{id}', projectId);
				return httpService.postRequest(url, options);
			};

			ProjectService.usedByOtherProjectElements = function (projectId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_USED_IN_OTHER_PROJECT');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			};

			ProjectService.cancelExportProject = function (projectId, executionId) {
				let deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_EXPORT_CANCEL');
				url = url.replace('{id}', projectId);
				url = url.replace('{executionId}', executionId);
				return httpService.postRequest(url, {});
			};

			ProjectService.importProject = function (options) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_IMPORT');
				return httpService.postRequest(url, options);
			};

			ProjectService.cancelImportProject = function (projectId, executionId) {
				let deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_IMPORT_CANCEL');
				url = url.replace('{id}', projectId);
				url = url.replace('{executionId}', executionId);
				return httpService.postRequest(url, {});
			};

			ProjectService.getProjectMembersCount = function () {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_MEMBERS_COUNT');
				return httpService.getRequest(url);
			};

			ProjectService.getProjectContextsCount = function () {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_CONTEXTS_COUNT');
				return httpService.getRequest(url);
			};

			ProjectService.getProjectLastAccessDates = function (projectId) {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_LAST_ACCESS_DATES');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			};

			ProjectService.getProjectContextAvailabilities = function () {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_CONTEXTS_AVAILABILITIES');
				return httpService.getRequest(url);
			};

			ProjectService.getStats = function (projectId) {
				return $q
					.all([
						getGenericDataStata(),
						getHandleDataStats(),
						getProjectMetadataById(projectId),
					])
					.then(function (res) {
						const gdStatsRes = res[0].data;
						const hdStatsRes = res[1].data;
						const projectDataRes = res[2].data;
						return ProjectDuplicateAdapter.getStats(
							gdStatsRes,
							hdStatsRes,
							projectDataRes
						);
					});
			};

			ProjectService.getProjectLastAccess = function () {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_LAST_ACCESS');
				return httpService.getRequest(url);
			};

			ProjectService.setDefaultProject = function (projectId) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DEFAULT');
				return httpService.putRequest(url, projectId, false);
			};

			ProjectService.getDefaultProject = function () {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DEFAULT');
				return httpService.getRequest(url);
			};

			ProjectService.setFavoriteProject = function (projectId, favorite) {
				let deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_FAVORITE');
				url = url.replace('{id}', projectId);
				return httpService.putRequest(url, favorite, false);
			};

			ProjectService.getHandleDataStats = getHandleDataStats;
			ProjectService.getGenericDataStats = getGenericDataStata;

			ProjectService.getProjectInfo = function (projectId) {
				return $q
					.all([
						getGenericDataStata(),
						getHandleDataStats(),
						getProjectMetadataById(projectId),
					])
					.then(function (res) {
						const gdStatsRes = res[0].data;
						const hdStatsRes = res[1].data;
						const projectDataRes = res[2].data;
						return ProjectStatAdapter.getAll(
							gdStatsRes,
							hdStatsRes,
							projectDataRes
						);
					});
			};

			ProjectService.getMembersByProjectId = function (projectId) {
				const deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_MEMBERS');
				url = url.replace('{id}', projectId);
				httpService.getRequest(url).then(function (response) {
					deferred.resolve(
						ProjectMemberAdapter.generateMemberExtendedBasicInfo(response.data)
					);
				});
				return deferred.promise;
			};

			ProjectService.getOrphanProjects = function () {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_ORPHAN');
				return httpService.getRequest(url);
			};

			ProjectService.setOrphanProjectAdmins = function (
				orphanProjectId,
				admins
			) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_ADMIN_MEMBERS');
				url = url.replace('{id}', orphanProjectId);
				return httpService.postRequest(url, admins);
			};

			ProjectService.getProjectElements = function (projectId) {
				const deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_ELEMENTS');
				url = url.replace('{id}', projectId);
				httpService.getRequest(url).then(function (response) {
					deferred.resolve(
						DeleteProjectAdapter.getProjectElements(response.data)
					);
				});
				return deferred.promise;
			};

			ProjectService.getDeleteProjectToken = function (projectId) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_DELETE_VERIFICATION');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			};

			ProjectService.isPublicProject = function (projectId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_IS_PUBLIC');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			};

			ProjectService.usesPublicElements = function (projectId) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_USES_PUBLIC_ELEMENTS');
				url = url.replace('{id}', projectId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ProjectService.downloadExportArchive = function (executionId, link) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_EXPORT_DOWNLOAD');
				url = url.replace('{executionId}', executionId);
				url = url.replace('{link}', link);
				return url;
			};

			ProjectService.getExportUserAgreement = function () {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('USER_AGREEMENTS_PROJECT_EXPORT');
				return httpService.getRequest(url);
			};

			ProjectService.getDuplicationUserAgreement = function () {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('USER_AGREEMENTS_PROJECT_DUPLICATION');
				return httpService.getRequest(url);
			};

			ProjectService.isAnyExecutionRunning = function () {
				let url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('PROJECTS_IS_BULK_EXECUTION_RUNNING');
				return httpService.getRequest(url);
			};

			ProjectService.isExposuresRootCodeUnique = function (rootCode) {
				const url = UrlService.generateUrl('PROJECTS_DATABLOCKS_EXPOSES_ROOT_UNIQUE', {}, {rootCode: rootCode});
				return httpService.getRequest(url);
			};

			function getGenericDataStata() {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_STAT_GD');
				return httpService.getRequest(url);
			}

			function getHandleDataStats() {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_STAT_HD');
				return httpService.getRequest(url);
			}

			function getProjectById(projectId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_DATA_GET');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			}

			function getProjectMetadataById(projectId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_GET');
				url = url.replace('{id}', projectId);
				return httpService.getRequest(url);
			}

			return ProjectService;
		},
	]);
})();
