(function () {
	'use strict';

	angular.module('dcApp').directive('dcHtmlviewer', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$sce',
				'GRIDSTER_OPTIONS',
				function ($scope, $sce, GRIDSTER_OPTIONS) {
					var vm = this;
					vm.init = function (attrs, elem) {
						vm.trusted_html = $sce.trustAsHtml(vm.data.content);
						vm.attrs = attrs;
						vm.el = elem;
					};

					$scope.$watch('vm.data.configuredSize', function (newValue) {
						const heightVal =
							newValue && newValue.height
								? `${Math.max(
										newValue.height - 80,
										GRIDSTER_OPTIONS.minSizeY
								  )}px`
								: '100%;';
						vm.attrs.$set('style', `height: ${heightVal}`);
					});
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/htmlViewer/htmlViewer.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init(attrs, element);
				}
			);
		},
	]);
})();
