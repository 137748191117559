(function () {
	'use strict';

	angular.module('dcApp').directive('metadataInfo', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$timeout',
				'MetadataService',
				'UserService',
				function ($scope, $rootScope, $timeout, MetadataService, UserService) {
					$scope.currentDate = moment(new Date()).format('LLLL');

					var vm = this;

					vm.init = function () {
						$scope.htmlId =
							vm.config && vm.config.htmlId ? vm.config.htmlId : '';
						if (!vm.isNew) {
							// edit or duplicate
							vm.hide_advanced = true;
						}
					};

					$scope.getDateTimeWithPattern = function (date) {
						return $rootScope.getDateTimeWithPattern(date);
					};

					$scope.copyInfo = function (value) {
						copyToClipboard(value);
						$scope.isCopied = true;
						$timeout(function () {
							$scope.isCopied = false;
						}, 600);
					};

					$scope.visibleMetaData = false;

					$scope.showPopover = function ($event) {
						$scope.metadataPopover = {
							id: vm.data.id,
							code: vm.data.code,
							updateDate: vm.data.updateDate,
							creationDate: vm.data.creationDate,
							createdBy: vm.data.createdBy,
							updatedBy: vm.data.updatedBy,
						};
						if (
							vm.data.metadataId !== undefined &&
							vm.data.metadataId !== '' &&
							!isNaN(vm.data.metadataId)
						) {
							initPopover();
							MetadataService.getMetadataTrace(vm.data.metadataId).then(
								function (response) {
									let data = response.data;
									$scope.metadataPopover.createdBy = data.created_by;
									$scope.metadataPopover.creationDate = data.creation_date;
									$scope.metadataPopover.updateDate = data.update_date;
									$scope.metadataPopover.updatedBy = data.updated_by;
									if ($scope.metadataPopover.createdBy) {
										UserService.getUserById(
											$scope.metadataPopover.createdBy
										).then(function (res1) {
											$scope.metadataPopover.createdBy = res1.data.name;
										});
									}
									if ($scope.metadataPopover.updatedBy) {
										UserService.getUserById(
											$scope.metadataPopover.updatedBy
										).then(function (res1) {
											$scope.metadataPopover.updatedBy = res1.data.name;
										});
									}
								}
							);
							$scope.visibleMetaData = true;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
						$event.stopPropagation();
					};
					function initPopover() {
						$scope.metadataDetailsPopover = {
							target: '#metadataInfoPopover' + vm.data.metadataId,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					isNew: '=',
					config: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/metadataInfo/metadataInfo.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.vm.init(attrs);
					scope.metadataPopover = {};
				},
			};
		},
	]);
})();
