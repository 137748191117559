(function () {
	'use strict';

	angular.module('dcApp').controller('DcGraphController', [
		'$scope',
		function ($scope) {
			$scope.graphData = {};
			$scope.graphData.axe = 'dash';
			$scope.graphData.showAxes = true;
		},
	]);
})();
