(function () {
	'use strict';

	angular.module('dcApp').directive('datablockViews', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'DEFAULT_CELL_TEMPLATE',
				'$q',
				'$rootScope',
				'DataBlocksService',
				'TypeEntiteService',
				'gettextCatalog',
				function (
					$scope,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					DEFAULT_CELL_TEMPLATE,
					$q,
					$rootScope,
					DataBlocksService,
					TypeEntiteService,
					gettextCatalog
				) {
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.tags = gettextCatalog.getString('Tags');
					dataGirdColumnName.description =
						gettextCatalog.getString('Description');

					var vm = this;

					function isSourceUsed(id, type) {
						if (type == 'DATAVIEW') {
							return _.find(
								$scope.vm.widgetData.selectedDataViews,
								function (elm) {
									return elm == id;
								}
							);
						}
					}

					var getAvailableTags = function () {
						return new Promise(function (resolve) {
							setTimeout(function () {
								resolve(
									_.map($scope.tags, function (el) {
										return el.label;
									})
								);
							}, 50);
						});
					};

					var lookupTagDataSource = {
						store: new DevExpress.data.CustomStore({
							loadMode: 'raw',
							load: function () {
								return getAvailableTags().then(function (response) {
									return response;
								});
							},
						}),
					};

					$scope.tagBox = {
						displayExpr: 'code',
						valueExpr: 'id',
						searchEnabled: false,
						editEnabled: false,
						tagTemplate: 'tagTemplate',
					};

					$scope.getTagBox = function (list) {
						$scope.tagBox.value = list.data.tags;

						return $scope.tagBox;
					};

					$scope.dataBlockDataGridOptions = {
						dataSource: $scope.datablocks,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						selection: {
							mode: 'multiple',
							showCheckBoxesMode: 'always',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.label,
								dataField: 'lc',
								width: '35.8%',
								allowReordering: false,
							},
							{
								dataField: 'tags',
								caption: dataGirdColumnName.tags,
								cellTemplate: 'tagTemplate',
								allowHeaderFiltering: true,
								allowFiltering: false,
								enableCellEdit: false,
								width: '32%',
								lookup: {
									dataSource: lookupTagDataSource,
								},
								calculateFilterExpression: function (
									filterValue,
									selectedFilterOperation
								) {
									if (!filterValue) return;
									return function (rowData) {
										return _.indexOf(rowData.tagAsArray, filterValue) != -1;
									};
								},
							},
							{
								caption: dataGirdColumnName.description,
								dataField: 'desc',
								width: '29%',
							},
						],
						onInitialized: function (e) {
							$scope.gridDataBlockInstance = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							$scope.selectedDataBlocks = selectedItems.selectedRowsData;
							$scope.changedDataBlockSelection = true;
							if (
								selectedItems &&
								selectedItems.selectedRowsData &&
								selectedItems.selectedRowsData[0]
							) {
								$scope.lib = selectedItems.selectedRowsData[0].lc;
								if (vm.widgetData.disableMultiSelect) {
									$scope.selectedDataViews = [];
								}
							}
						},
						onContentReady: function (e) {
							if (
								$scope.gridDataBlockInstance &&
								$scope.gridDataBlockInstance.selectRowsByIndexes &&
								!$scope.changedDataBlockSelection
							) {
								var selectedIndexes = [];
								for (var d in $scope.selectedDataBlocks) {
									for (var d1 in $scope.dataBlockDataGridOptions.dataSource) {
										if (
											$scope.dataBlockDataGridOptions.dataSource[d1].id ==
											$scope.selectedDataBlocks[d].id
										) {
											selectedIndexes.push(d1);
										}
									}
								}
								if (selectedIndexes.length > 0) {
									$scope.gridDataBlockInstance.selectRowsByIndexes(
										selectedIndexes
									);
								}
							}
							$scope.changedDataBlockSelection =
								$scope.changedDataBlockSelection
									? false
									: $scope.changedDataBlockSelection;
						},
						onCellPrepared: function (cellInfo) {
							if (
								cellInfo.rowType == 'data' &&
								(cellInfo.column.dataField === 'lc' ||
									cellInfo.column.dataField === 'desc' ||
									cellInfo.column.dataField === 'tags') &&
								isSourceUsed(cellInfo.data.id, cellInfo.data.type)
							) {
								cellInfo.cellElement.addClass('background-row');
							}
						},
					};

					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});

					function getElements() {
						var deferred = $q.defer();
						DataBlocksService.getDatablocks(false, true).then(
							function (response) {
								deferred.resolve(response);
							},
							function (errorMessage) {
								deferred.reject();
							}
						);

						return deferred.promise;
					}

					$scope.getGridData = function () {
						getElements().then(function (content) {
							if (content !== undefined) {
								var elements = [];
								$scope.tags = [];
								var tags = [];
								for (var r in content) {
									elements.push({
										id: content[r].id,
										code: content[r].metadata.code,
										lc: content[r].metadata.label,
										desc: content[r].metadata.description,
										element: content[r],
										tags: content[r].metadata.tags,
										tagAsArray:  $rootScope.mapTags(content[r].metadata.tags),
									});

									tags = _.union(tags, content[r].metadata.tags);
								}

								for (var i in tags) {
									if (_.find($scope.tags, { id: tags[i].id }) == null) {
										$scope.tags.push({ id: tags[i].id, label: tags[i].code });
									}
								}

								$scope.datablocks = elements;
								$scope.dataBlockDataGridOptions.dataSource = $scope.datablocks;
							}
						});
					};

					$scope.saveSelectedSources = function () {
						$scope.selectedSources = [];
						getDataBlockGrammar(0);
					};

					var getDataBlockGrammar = function (i) {
						if (!$scope.selectedDataBlocks[i]) {
							$scope.vm.widgetData.saveSelectedDataBlockViews(
								$scope.selectedSources
							);
							closeModal();
							return;
						}

						TypeEntiteService.getEntiteTypeGrammar(
							$scope.selectedDataBlocks[i].id
						).then(function (response) {
							$scope.selectedSources.push({
								grammar: { columns: response.data.columns, steps: [] },
								lc: $scope.selectedDataBlocks[i].lc,
								data_block_id: $scope.selectedDataBlocks[i].id,
							});
							getDataBlockGrammar(i + 1);
						});
					};

					$scope.init = function () {
						if ($scope.vm.widgetData.disableMultiSelect) {
							$scope.dataBlockDataGridOptions.selection.mode = 'single';
						}
						$scope.selectedDataBlocks = [];
						$scope.getGridData();
					};

					$scope.save = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showDataBlockViews = false;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataBlockViews/dataBlockViews.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showDataBlockViews = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showDataBlockViews;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
