(function () {
	'use strict';

	angular.module('dcApp').directive('exportProjectDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'elem',
				'APP_VERSION',
				function ($scope, $rootScope, elem, APP_VERSION) {
					var vm = this;
					vm.elem = elem;
					vm.APP_VERSION = APP_VERSION;
					vm.labelTruncateThresh = 100;
					vm.descriptionTruncateThresh = 200;

					vm.checkDateValidity = function () {
						if (vm.data.duplicatedProjectExpiration) {
							var end = moment(vm.data.duplicatedProjectExpiration).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
					};
				},
			];
			return buildDirectiveReturnObject(
				{
					data: '=',
					projectInfos: '=',
				},
				controller,
				'./src/components/directives/exportProject/exportProjectDetails.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data ? scope.vm.data.showExportDatablock : false;
						},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
