(function () {
	angular.module('dcApp').factory('ExportDcService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		function ($http, API_BASE_URL_BACKEND, $rootScope) {
			var ExportDcService = {},
				endPoint = API_BASE_URL_BACKEND + 'export_hdh';

			ExportDcService.export = function (exportData) {
				return $http.post(endPoint, exportData).then(function (response) {
					return response;
				});
			};

			ExportDcService.exportSelectedUets = function (
				includeSources,
				selectedUets
			) {
				return $http
					.post(
						endPoint + '/list?includeSources=' + includeSources,
						selectedUets
					)
					.then(function (response) {
						return response;
					});
			};

			return ExportDcService;
		},
	]);
})();
