// Check if an array 'a' contains object 'obj'
function contains(a, obj) {
	let i = a.length;
	while (i--) {
		if (a[i] === obj) {
			return true;
		}
	}
	return false;
}

// Group elements with similar properties in the arrayparseRules
function groupBy(array, f) {
	let groups = {};
	array.forEach(function (o) {
		let group = JSON.stringify(f(o));
		groups[group] = groups[group] || [];
		groups[group].push(o);
	});
	return Object.keys(groups).map(function (group) {
		return groups[group];
	});
}

// copy specific properties from one object to another
function copy(srcObj, destObj) {
	for (let key in srcObj) {
		destObj[key] = srcObj[key];
	}
}

function joinWithSlash(array) {
	return array.join('/');
}

// return length of object (= property number)
function count(obj) {
	let counter = 0;
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			++counter;
		}
	}
	return counter;
}

function isEmpty(obj) {
	if (obj == undefined || obj == null || obj == '') {
		return true;
	}
	return false;
}

function sort_by(field, reverse, primer) {
	let key = function (x) {
		return primer ? primer(x[field]) : x[field];
	};

	return function (a, b) {
		let A = key(a),
			B = key(b);
		return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!reverse];
	};
}

let sortDataList = function (data, field, reverse) {
	data.sort(sort_by(field, reverse, parseInt));
	return data;
};

function getTags(tagsList) {
	let tags = '';
	for (let t in tagsList) {
		if (t == 0) {
			tags = tagsList[t].code;
		} else tags = tags + ' , ' + tagsList[t].code;
	}
	return tags;
}

function getCurrentDate() {
	return new Date();
}

function dbFilterDateParser(dateStr, defaultTz) {
	if (!dateStr) {
		return dateStr;
	}
	let momentDate;
	if (dateStr.includes("T")) {
		momentDate = moment(dateStr);
	} else {
		momentDate = moment.utc(dateStr, "DD/MM/YYYY HH:mm:ss")
	}
	if (!momentDate.isValid()) {
		// Try with pattern
		momentDate = moment.utc(dateStr, "DD-MM-YYYY HH:mm:ss");
	}
	// check if is valid*
	if (!momentDate.isValid()) {
		// Try with pattern
		return dateStr;
	}

	return moment.tz(momentDate, defaultTz).format("DD/MM/YYYY HH:mm:ss");
}

function parseValue(rule, tz) {
	if (rule != undefined) {
		if (isEmpty(rule.value)) {
			if (
				rule.type == 'decimal' ||
				rule.type == 'double' ||
				rule.type == 'integer' ||
				rule.type == 'big_integer' ||
				rule.type == 'number'
			) {
				return 0;
			}
			return undefined;
		} else {
			if (
				(rule.type == 'decimal' ||
					rule.type == 'double' ||
					rule.type == 'integer' ||
					rule.type == 'big_integer' ||
					rule.type == 'number') &&
				rule.operator !== 'between' &&
				rule.operator !== 'not_between'
			) {
				if (typeof rule.value == 'string') {
					rule.value = rule.value.replace(/\"/g, '');
				}
				return Number(rule.value);
			}
		}
		let value = rule.value;
		if (typeof value == 'string') {
			if (rule.value[0] == '"' && rule.value[rule.value.length - 1] == '"') {
				value = rule.value.substring(1, rule.value.length - 1);
			}
			if (rule.type == 'string' || rule.type == 'file') {
				if (isNumeric(value)) {
					value = '' + rule.value + '';
				}
			} else {
				if (rule.value[0] == '"' && rule.value[rule.value.length - 1] == '"') {
					value = rule.value.substring(1, rule.value.length - 1);
				}
			}
		}
		if (
			rule.type == 'decimal' ||
			rule.type == 'double' ||
			rule.type == 'integer' ||
			rule.type == 'big_integer' ||
			rule.type == 'number'
		) {
			if (rule.operator == 'between' || rule.operator == 'not_between') {
				if (typeof rule.value == 'string') {
					value = value.replace(/\"/g, '');
					value = value.replace('[', '');
					value = value.replace(']', '');
					let valsNum = value.split(',');
					value = [Number(valsNum[0]), Number(valsNum[1])];
				}
			} else {
				value = Number(value);
			}
		} else if (rule.type == 'date') {
			if (rule.operator == 'between' || rule.operator == 'not_between') {
				if (!isEmpty(value) && !(value instanceof Array) && value.startsWith("[")) {
					const valueAsArray = JSON.parse(value);
					if (valueAsArray) {
						return valueAsArray.map(elm => dbFilterDateParser(elm, tz));

					}
				}
			} else {
				if (!isEmpty(value)) {
					return dbFilterDateParser(value, tz);
				}
			}
		}
		return value;
	}
	return null;
}

function getFilterFromRule(rule, translateMap) {
	let filter = {
		id: rule.uca_id ? rule.uca_id : rule.id,
		label: rule.field,
		type: rule.type,
		unique: true,
		list: rule.list,
		description: rule.field,
	};

	if (filter.type == 'date') {
		filter.plugin = 'datetimepicker';
		filter.plugin_config = {
			format: 'DD/MM/YYYY HH:mm:ss',
		};
		filter.validation = {
			format: 'DD/MM/YYYY HH:mm:ss',
		};
	} else if (filter.type == 'boolean') {
		filter.input = 'radio';
		filter.values = {
			1: translateMap['true'],
			0: translateMap['false'],
		};
	}
	return filter;
}

function getFilterFromColumn(column, translateMap) {
	let filter = {
		id: column.uca_id ? column.uca_id : column.id,
		uca_id: column.uca_id ? column.uca_id : column.id,
		label: column.lib ? column.lib : column.column_alias,
		column_alias: column.alias,
		type: column.type,
		list: column.list,
		unique: true,
		//column_ref: column.uca_id ? undefined : column.id,
		description: column.field,
		is_unstructed_content: column.media,
	};
	if (filter.type == 'date' && !filter.list) {
		filter.plugin = 'datetimepicker';
		filter.plugin_config = {
			format: 'DD/MM/YYYY HH:mm:ss',
		};
		filter.validation = {
			format: 'DD/MM/YYYY HH:mm:ss',
		};
	} else if (filter.type == 'boolean') {
		filter.input = 'radio';
		filter.values = {
			1: translateMap['true'],
			0: translateMap['false'],
		};
	}
	return filter;
}

function parseRules(allRules, rule) {
	if (rule.rules == undefined || rule.rules.length == 0) {
		if (rule.id == undefined) {
			if (rule.uca_id) {
				rule.id = rule.uca_id;
			} else {
				rule.id = rule.column_ref;
			}
		}

		allRules.push(rule);
	} else {
		for (let r in rule.rules) {
			parseRules(allRules, rule.rules[r]);
		}
	}
}

function getFlattenRules(allRules, rule) {
	if (rule == undefined) {
		return;
	}
	if (rule.rules == undefined || rule.rules.length == 0) {
		allRules.push(rule);
	} else {
		for (let r in rule.rules) {
			getFlattenRules(allRules, rule.rules[r]);
		}
	}
}

function resetBetweenOperator(rule) {
	if (rule != undefined) {
		if (rule.rules == undefined || rule.rules.length == 0) {
			if (
				rule.type == 'decimal' ||
				rule.type == 'double' ||
				rule.type == 'integer' ||
				rule.type == 'big_integer' ||
				rule.type == 'number' ||
				rule.type == 'date'
			) {
				if (rule.operator == 'between' || rule.operator == 'not_between') {
					if (rule.value instanceof Array) {
						rule.value = '["' + rule.value[0] + '","' + rule.value[1] + '"]';
					}
				} else {
					if (rule.value instanceof Array) {
						rule.value = '';
					}
				}
			}
		} else {
			for (let r in rule.rules) {
				resetBetweenOperator(rule.rules[r]);
			}
		}
	}
}

function getAllShildrenRules(rules) {
	let allRules = [];
	for (let r in rules) {
		parseShildrenRules(allRules, rules[r]);
	}
	return allRules;
}

function parseShildrenRules(allRules, rule) {
	if (rule.operator) {
		allRules.push(rule);
	} else if (rule.rules && rule.rules[0] && rule.condition) {
		for (let r in rule.rules) {
			parseShildrenRules(allRules, rule.rules[r]);
		}
	}
}

function setEntityRows(entity, columns) {
	let newE = {};
	let v = 0;
	for (let c in columns) {
		if (columns[c].uca_id) {
			newE[c] = entity[v];
		}
		if (columns[c].uca_id) {
			v++;
		}

		v++;
	}
	return newE;
}

function containsNode(nodes, node) {
	let id1 = node.node !== undefined ? node.node.id : node.alias.id;
	let id2;
	for (let n in nodes) {
		id2 = nodes[n].node !== undefined ? nodes[n].node.id : nodes[n].alias.id;
		if (id1 == id2) {
			return true;
		}
	}
	return false;
}

function setPathFieldsAlias(path) {
	if (path.nodes.length == 0 && path.is_title) {
		return {
			isTitle: true,
			path: 'Tous',
			nodes: [],
			object: ' ',
		};
	}

	let pth = {};

	if (path.is_title) {
		pth.isTitle = true;
	}

	pth.path = '';
	pth.full_path = '';
	let edges = 0;
	let caracs = [];
	let poids = 0;

	let nodes = path.nodes;
	let path_ids = '';
	for (let n in nodes) {
		if (!nodes[n].is_alias) {
			let id_n = '';
			if (nodes[n].node.type == 'c') {
				id_n = 'c' + nodes[n].node.id;
				edges++;
				poids = poids + nodes[n].node.poids;
			} else {
				id_n = nodes[n].node.id;
			}

			if (pth.path == '') {
				path_ids = id_n;
				pth.path = nodes[n].node.lib_court;
				pth.full_path = nodes[n].node.lib_court;
			} else {
				path_ids = path_ids + '-' + id_n;
				pth.path = pth.path + '--' + nodes[n].node.lib_court;
				pth.full_path = pth.full_path + '--' + nodes[n].node.lib_court;
			}

			caracs.push(nodes[n].node);
		} else {
			if (pth.path == '') {
				path_ids = nodes[n].alias.path;
				pth.path = nodes[n].alias.metadata.label;
			} else {
				let pNodes1 = path_ids.split('-');
				let lastPNode1 = pNodes1[pNodes1.length - 1];

				let pNodes2 = nodes[n].alias.path.split('-');
				let firstPNode2 = pNodes2[0];

				if (lastPNode1 == firstPNode2) {
					path_ids =
						path_ids + '-' + nodes[n].alias.path.replace(firstPNode2 + '-', '');
				} else {
					path_ids = path_ids + '-' + nodes[n].alias.path;
				}

				pth.path = pth.path + '--' + nodes[n].alias.metadata.label;
			}
			nodes[n].alias.lib = nodes[n].alias.metadata.label;

			let nodes_a = nodes[n].alias.nodes;
			for (let na in nodes_a) {
				if (pth.full_path == '') {
					pth.full_path = nodes_a[na].lib_court;
				} else {
					pth.full_path = pth.full_path + '--' + nodes_a[na].lib_court;
				}
				if (nodes_a[na].type == 'c') {
					edges++;
					poids = poids + nodes_a[na].poids;
				}
				let item = _.find(caracs, {
					id: nodes_a[na].id,
					type: nodes_a[na].type,
				});

				if (!item) {
					caracs.push(nodes_a[na]);
				}
			}
		}
	}

	pth.nodes = nodes;
	pth.poids_moy = Math.round(poids / edges);
	pth.nbEdges = edges;
	pth.object = path_ids;
	pth.poids = poids;
	pth.caracs = caracs;

	return pth;
}

function sortPathes(pathesTable, field, reverse, shared_pathes) {
	if (shared_pathes) {
		// trier par groupe
		let allPathes = [];
		let pathes = [];

		for (let p in pathesTable) {
			if (pathesTable[p].isTitle) {
				allPathes = allPathes.concat(sortDataList(pathes, field, reverse));
				allPathes.push(pathesTable[p]);

				pathes = [];
			} else {
				pathes.push(pathesTable[p]);
			}
		}
		allPathes = allPathes.concat(sortDataList(pathes, field, reverse));
		pathesTable = allPathes;
	} else {
		pathesTable = sortDataList(pathesTable, field, reverse);
	}
	return pathesTable;
}

function getColIndex(grammar, id) {
	for (let c in grammar.columns) {
		if (grammar.columns[c].id == id) {
			return c;
		}
	}
}

function resetRuleOperator(rule) {
	if (rule != undefined) {
		if (rule.rules == undefined || rule.rules.length == 0) {
			if (rule.operator != undefined && rule.operator.code != undefined) {
				rule.operator = rule.operator.code;
			}
		} else {
			for (let r in rule.rules) {
				resetRuleOperator(rule.rules[r]);
			}
		}
	}
}

function isDoc(val) {
	if (val != undefined) {
		if (
			val.toString().indexOf('.PDF') >= 0 ||
			val.toString().indexOf('.HTML') >= 0 ||
			val.toString().indexOf('.pdf') >= 0 ||
			val.toString().indexOf('.html') >= 0 ||
			val.toString().indexOf('.doc') >= 0 ||
			val.toString().indexOf('.DOC') >= 0
		) {
			return true;
		}
	}
	return false;
}

function getMatchingColumns(columns, type) {
	let cols = [];
	for (let c in columns) {
		if (columns[c].type == type) {
			cols.push(columns[c]);
		}
	}
	return cols;
}

String.prototype.capitalize = function () {
	return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
		return p1 + p2.toUpperCase();
	});
};

function getTypeFromEntiteTypeCode(code) {
	switch (code) {
		case 'SIMPLE_TYPES_STRING_ITEM':
			return 'string';
		case 'TYPES_FILE_ITEM':
			return 'file';
		case 'SIMPLE_TYPES_DECIMAL_ITEM':
			return 'decimal';
		case 'SIMPLE_TYPES_INTEGER_ITEM':
			return 'integer';
		case 'SIMPLE_TYPES_BIG_INTEGER_ITEM':
			return 'big_integer';
		case 'SIMPLE_TYPES_DATE_ITEM':
			return 'date';
		case 'SIMPLE_TYPES_BOOLEAN_ITEM':
			return 'boolean';
		case 'SIMPLE_TYPES_BINARY_ITEM':
			return 'string';
		case 'TYPES_WORDS_ITEM':
			return 'words';
		case 'TYPES_BINARY_ITEM':
			return 'binary';
		case 'TYPES_GEOMETRY_ITEM':
			return 'geometry';
	}
}

function getColumnFromCarac(carac) {
	let col = {
		type: getTypeFromEntiteTypeCode(carac.code_type),
		field: carac.lib_court,
	};
	if (carac.type == 'c') {
		col.uca_id = carac.id;
	} else {
		col.uet_id = carac.id;
	}
	if (col.media != undefined) {
		if (!carac.structured) {
			col.media = true;
		} else {
			col.media = false;
		}
	}

	col.parent_entite_type_id = carac.parent_entite_type_id;
	col.parent_entite_type_lib = carac.parent_entite_type_lib;
	col.list = carac.list;
	return col;
}

function setElementaryDataMap(map, path) {
	path = path.replace('***', '');
	path = path.replace('***', '');
	let nodes = path.split('-');
	let i = 0;
	for (let n in nodes) {
		if (nodes[n].indexOf('c') !== -1) {
			let idC = nodes[n].slice(1);
			map.push({ v1: idC, v2: 'c' });
		} else {
			map.push({ v1: nodes[n], v2: 't' });
		}
		i++;
	}
}

String.prototype.replaceAll = function (search, replacement) {
	let target = this;
	return target.split(search).join(replacement);
};

// Used to get headers( DataSource)
function getHeaders(results) {
	return results.map(function (elem) {
		return elem[0];
	});
}

// Only alpha num in a string
String.prototype.metaCodeValidator = function () {
	let code = this.replace(/[^a-zA-Z0-9_]+/g, '_');

	return code.replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '');
};

//Remove extension from a file name
String.prototype.removeFileExtension = function () {
	return this.substr(0, this.lastIndexOf('.'));
};

//For ui grid filters
function fakeI18n(title) {
	return title;
}

function getTextFromRule(text, rule, columns) {
	if ((rule.rules == undefined || rule.rules.length == 0) && rule.operator) {
		let value = rule.value;
		if (rule.value_column_id) {
			value = _.find(columns, function (item) {
				return item.uuid == rule.value_column_id;
			})['lib'];
		}
		if (rule.value_column_id2) {
			value =
				value +
				' , ' +
				_.find(columns, function (item) {
					return item.uuid == rule.value_column_id2;
				})['lib'];
		}
		if (
			rule.operator == 'in' ||
			rule.operator == 'not_in' ||
			rule.operator == 'contains_at_least_one' ||
			rule.operator == 'contains_all' ||
			rule.operator == 'not_contains_any'
		) {
			if (rule.value_list) {
				value = '(' + rule.value_list.join(' , ') + ')';
			}
		} else if (
			rule.operator == 'is_empty' ||
			rule.operator == 'is_not_empty' ||
			rule.operator == 'is_null' ||
			rule.operator == 'is_not_null'
		) {
			value = '';
		} else if (rule.operator == 'semantic_context') {
			value =
				rule &&
				rule.semantic_context &&
				rule.semantic_context.label != undefined
					? '"' + rule.semantic_context.label + '"'
					: '"-"';
		}
		text.append(
			' ' + rule.field + ' ' + rules_operators[rule.operator] + ' ' + value
		);
	} else {
		let rules = [];
		for (let r in rule.rules) {
			if (containsRules(rule.rules[r])) {
				rules.push(rule.rules[r]);
			}
		}
		for (let r in rules) {
			if (containsRules(rules[r])) {
				if (r != 0 && rules[1]) {
					text.append(' ' + (rule.condition == 'AND' ? and : or) + ' ');
				}
				text.append('(');
				getTextFromRule(text, rules[r], columns);
				text.append(')');
			}
		}
	}
}

function containsRules(rule) {
	if (rule.operator) {
		return true;
	}
	if (!rule.rules) {
		return false;
	}
	for (let r in rule.rules) {
		if (rule.rules[r].operator) {
			return true;
		}
	}

	for (let r in rule.rules) {
		if (
			rule.rules[r].condition &&
			rule.rules[r].rules &&
			rule.rules[r].rules[0]
		) {
			let containsRule = containsRules(rule.rules[r]);
			if (containsRule) {
				return true;
			}
		}
	}
}

function StringBuffer() {
	this.buffer = [];
	this.index = 0;
}

StringBuffer.prototype = {
	append: function (s) {
		this.buffer[this.index] = s;
		this.index += 1;
		return this;
	},

	toString: function () {
		return this.buffer.join('');
	},
};

function cloneWidget(widget) {
	let resultGridApi = widget.resultGridApi;
	let resultGroupingGridApi = widget.resultGroupingGridApi;
	let resultDetailGridApi = widget.resultDetailGridApi;
	let resultForageDetailGridApi = widget.resultForageDetailGridApi;
	delete widget.resultGridApi;
	delete widget.resultGroupingGridApi;
	delete widget.resultDetailGridApi;
	delete widget.resultForageDetailGridApi;

	let newWidget = angular.copy(widget);
	widget.resultGridApi = resultGridApi;
	widget.resultGroupingGridApi = resultGroupingGridApi;
	widget.resultDetailGridApi = resultDetailGridApi;
	widget.resultForageDetailGridApi = resultForageDetailGridApi;
	return newWidget;
}

function addSpinner(el, static_pos) {
	let spinner = el.children('.spinner-wid');
	if (spinner.length && !spinner.hasClass('spinner-wid-remove')) return null;
	!spinner.length &&
		(spinner = $(
			'<div class="spinner-wid' +
				(static_pos ? '' : ' spinner-wid-absolute') +
				'"/>'
		).appendTo(el));
	animateSpinner(spinner, 'add');
}

function removeSpinner(el, complete) {
	let spinner = el.children('.spinner-wid');
	spinner.length && animateSpinner(spinner, 'remove', complete);
}

function animateSpinner(el, animation, complete) {
	if (el.data('animating')) {
		el.removeClass(el.data('animating')).data('animating', null);
		el.data('animationTimeout') && clearTimeout(el.data('animationTimeout'));
	}
	el.addClass('spinner-wid-' + animation).data(
		'animating',
		'spinner-wid-' + animation
	);
	el.data(
		'animationTimeout',
		setTimeout(function () {
			animation == 'remove' && el.remove();
			complete && complete();
		}, parseFloat(el.css('animation-duration')) * 1000)
	);
}

function formuleToString(formule) {
	return JSON.stringify(formule);
}

function formuleToJson(stringFormule) {
	return JSON.parse(stringFormule);
}

function parseBigInt(str) {
	return new Number(str);
}

function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

function formatNumber(string) {
	if (string == undefined) {
		return '';
	}
	if (string == 0) {
		return 0;
	}
	let output = [];

	//convert to string
	let numStr = string + '';

	//remove - and ,XXX ==> convert to integer
	let containsMinus = numStr.indexOf('-') > -1;
	if (containsMinus) {
		numStr = numStr.replace('-', '');
	}
	let decSplit = numStr.split('.');
	string = Number(decSplit[0]);

	//handle integer
	for (; string > 0; string = Math.floor(string / 1000)) {
		let dec = string % 1000;
		if (dec == 0) {
			dec = '000';
		} else if (dec < 10) {
			dec = '00' + dec;
		} else if (dec < 100) {
			dec = '0' + dec;
		}
		output.unshift(dec);
	}
	let result = output.join(' ');
	if (result.startsWith('00')) {
		result = result.substr(2, result.length - 1);
	} else if (result.startsWith('0')) {
		result = result.substr(1, result.length - 1);
	}

	//add - and ,XXX
	if (result == '') result = 0;
	let res = decSplit[1] ? result + '.' + decSplit[1] : result;
	if (containsMinus) {
		res = '-' + res;
	}
	return res;
}

function updateRulesWithFunction(rule, updateFunction) {
	if (rule.rules == undefined || rule.rules.length == 0) {
		updateFunction(rule);
	} else {
		for (let r in rule.rules) {
			updateRulesWithFunction(rule.rules[r], updateFunction);
		}
	}
}

function generateClientId(prefix) {
	let randomUuid = uuidv4();
	return prefix + '_' + randomUuid;
}

function generateUuid(separator) {
	let randomUuid = uuidv4();
	randomUuid = randomUuid.toUpperCase();
	randomUuid = randomUuid.replace(/-/g, separator);
	return randomUuid;
}

function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}

_.mixin({
	nestedOmit: function (obj, iteratee, context) {
		let r = _.omit(obj, iteratee, context);

		_.each(r, function (val, key) {
			if (typeof val === 'object')
				r[key] = _.nestedOmit(val, iteratee, context);
		});

		return r;
	},
});

function getDatesForOptions(fromdate, todate) {
	let fromDate = fromdate.split(' ')[0].split('/');
	let fromHoure = fromdate.split(' ')[1].split(':');
	let toDate = todate.split(' ')[0].split('/');
	let toHoure = todate.split(' ')[1].split(':');

	return {
		min: new Date(
			parseInt(fromDate[2], 10),
			parseInt(fromDate[1] - 1, 10),
			parseInt(fromDate[0], 10),
			parseInt(fromHoure[0], 10),
			parseInt(fromHoure[1], 10),
			parseInt(fromHoure[2], 10)
		),
		max: new Date(
			parseInt(toDate[2], 10),
			parseInt(toDate[1] - 1, 10),
			parseInt(toDate[0], 10),
			parseInt(toHoure[0], 10),
			parseInt(toHoure[1], 10),
			parseInt(toHoure[2], 10)
		),
		start: new Date(
			parseInt(fromDate[2], 10),
			parseInt(fromDate[1] - 1, 10),
			parseInt(fromDate[0], 10),
			parseInt(fromHoure[0], 10),
			parseInt(fromHoure[1], 10),
			parseInt(fromHoure[2], 10)
		),
		end: new Date(
			parseInt(toDate[2]),
			parseInt(toDate[1] - 1, 10),
			parseInt(toDate[0], 10),
			parseInt(toHoure[0], 10),
			parseInt(toHoure[1], 10),
			parseInt(toHoure[2], 10)
		),
	};
}

function roundDecimal(nombre, precision) {
	precision = precision || 2;
	let tmp = Math.pow(10, precision);
	return Math.round(nombre * tmp) / tmp;
}

function closeTooltipById(id) {
	let element = document.getElementById(id);
	if (element) {
		element.remove();
	}
}

function getTypeLabelFromHdhType(type) {
	switch (type) {
		case 'DASHBOARD':
			return 'Tableau de bord';
		case 'MAP':
			return 'Carte';
		case 'CHART':
			return 'Graphique';
		case 'GRID':
			return 'Tableau';
		case 'TIMELINE':
			return 'TimeLine';
		case 'DATAVIEW':
			return 'Source';
		case 'DATABLOCK':
			return 'DataBlock';
		case 'DEPOT':
			return 'Dépôt';
		case 'CONNECTOR':
			return 'Connecteur';
		case 'ENTITETYPE':
			return 'Entité métier';
		case 'CARACTERISTIQUE':
			return 'Caractéristique';
		case 'DATABLOCK_EXPORT_TEMPLATE':
			return 'Modèle d’export';
		default:
			return type;
	}
}

function findElement(list, field, fieldValue) {
	for (let e in list) {
		if (list[e][field] == fieldValue) {
			return list[e];
		}
	}
	return {};
}

function toGridFilterObject(arr, columns) {
	let rv = {};
	let j = 0;
	for (let i in arr) {
		if (arr[i] instanceof Array) {
			rv[i] = toGridFilterObject(arr[i], columns);
		} else {
			if (i == 'columnIndex') {
				rv[j] = getDataFiles(columns, arr[i]);
			} else {
				rv[j] = arr[i];
			}
		}
		j = j + 1;
	}

	return rv;
}

function getDataFiles(columns, index) {
	let dataField = index;
	let col = _.find(columns, function (elm) {
		return elm.index == index;
	});
	if (col) {
		dataField = col.dataField;
	}

	return dataField;
}

function formulasTypesMap(type) {
	switch (type) {
		case 'StringType':
		case 'NullType':
			return 'string';
		case 'BooleanType':
			return 'boolean';
		case 'DateType':
		case 'TimestampType':
			return 'date';
		case 'DecimalType':
		case 'DoubleType':
			return 'decimal';
		case 'LongType':
			return 'big_integer';
		case 'IntegerType':
			return 'integer';
		case 'BinaryType':
			return 'binary';
		default:
			return 'string';
	}
}

let typesMap = {
	SIMPLE_TYPES_STRING_ITEM: 'string',
	SIMPLE_TYPES_DECIMAL_ITEM: 'decimal',
	SIMPLE_TYPES_DATE_ITEM: 'date',
	SIMPLE_TYPES_INTEGER_ITEM: 'integer',
	SIMPLE_TYPES_BIG_INTEGER_ITEM: 'big_integer',
	SIMPLE_TYPES_BOOLEAN_ITEM: 'boolean',
	TYPES_WORDS_ITEM: 'words',
	TYPES_FILE_ITEM: 'file',
	TYPES_BINARY_ITEM: 'binary',
	TYPES_GEOMETRY_ITEM: 'geometry',
};

function isUrl(str) {
	if (str && typeof str === 'string') {
		str = str.toLowerCase();
		return str.startsWith('http://') || str.startsWith('https://');
	} else {
		return false;
	}
}

var toCamel = (s) => {
	return s.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace('-', '').replace('_', '');
	});
};

function keysToCamel(o) {
	if (isObject(o)) {
		const n = {};

		Object.keys(o).forEach((k) => {
			n[toCamel(k)] = keysToCamel(o[k]);
		});

		return n;
	} else if (isArray(o)) {
		return o.map((i) => {
			return keysToCamel(i);
		});
	}

	return o;
}

function isObject(o) {
	return o === Object(o) && !isArray(o) && typeof o !== 'function';
}

function isArray(a) {
	return Array.isArray(a);
}

function copyToClipboard(text_to_share) {
	// create temp element
	let copyElement = document.createElement('span');
	copyElement.appendChild(document.createTextNode(text_to_share));
	copyElement.id = 'tempCopyToClipboard';
	angular.element(document.body.append(copyElement));

	// select the text
	let range = document.createRange();
	range.selectNode(copyElement);
	window.getSelection().removeAllRanges();
	window.getSelection().addRange(range);

	// copy & cleanup
	document.execCommand('copy');
	window.getSelection().removeAllRanges();
	copyElement.remove();
}

function getFormattedMediaType(type) {
	switch (type) {
		case 'image':
			return 'Image';
		case 'pdf':
			return 'Pdf';
	}
}

function getFormattedGridType(type) {
	switch (type) {
		case 'simple':
			return 'Tableau Simple / Tableau Hiérarchique';
		case 'pivot':
			return 'Pivot';
	}
}

function compareTwoDatesForDxGrid(value1, value2, sortOrder) {
	if (
		(value1 == null || value1 == undefined) &&
		(value2 != null || value2 != undefined)
	) {
		return sortOrder == 'asc' ? 1 : -1;
	}
	if (value1 == undefined && value2 == null) {
		return 0;
	}
	if (value1 != undefined && value2 == null) {
		return sortOrder == 'asc' ? -1 : 1;
	}
	return value1 != undefined && value1.localeCompare(value2);
}

function updateGeneratedCarac(caracData) {
	caracData.origin_column_id = caracData.code;
	delete caracData.code;
}

var getTimeMicroSecondsValue = function (value, unit) {
	switch (unit) {
		case 'microseconds':
			return value * 1;
		case 'milliseconds':
			return value * 1000;
		case 'seconds':
			return value * 1000 * 1000;
		case 'minutes':
			return value * 1000 * 1000 * 60;
		case 'hours':
			return value * 1000 * 1000 * 60 * 60;
		case 'days':
			return value * 1000 * 1000 * 60 * 60 * 24;
		case 'weeks':
			return value * 1000 * 1000 * 60 * 60 * 24 * 7;
	}
	return 0;
};

var formatDurationSeconds = function (durationMS) {
	if (durationMS <= 1000) {
		return '0s (' + durationMS + 'ms)';
	}

	let duration = moment.duration(durationMS);
	let asDays = Math.trunc(duration.asDays());
	let hours = duration.hours();
	let minutes = duration.minutes();
	let seconds = duration.seconds();
	let formattedDuration = '';

	if (asDays >= 1) {
		formattedDuration = formattedDuration + asDays + 'j';
	}
	if (hours >= 1) {
		formattedDuration = formattedDuration + ' ' + hours + 'h';
	}
	if (minutes >= 1) {
		formattedDuration = formattedDuration + ' ' + minutes + 'mn';
	}
	if (seconds >= 1) {
		formattedDuration = formattedDuration + ' ' + seconds + 's';
	}

	return formattedDuration;
};

var formatDurationMinutes = function (durationMS) {
	if (durationMS <= 1000 * 60) {
		return '0 mn (' + durationMS + 'ms)';
	}
	let duration = moment.duration(durationMS);
	let asDays = Math.trunc(duration.asDays());
	let hours = duration.hours();
	let minutes = duration.minutes();
	let formattedDuration = '';

	if (asDays >= 1) {
		formattedDuration = formattedDuration + asDays + 'j';
	}
	if (hours >= 1) {
		formattedDuration = formattedDuration + ' ' + hours + 'h';
	}
	if (minutes >= 1) {
		formattedDuration = formattedDuration + ' ' + minutes + 'mn';
	}
	return formattedDuration;
};

//Get difference between the current date and another date
var getCurrentDateDiff = function (timeMS) {
	let current = new moment();
	return moment.duration(current.diff(moment(timeMS))).asMilliseconds();
};

var getDateDiff = function (timeMS1, timeMS2) {
	return moment
		.duration(moment(timeMS1).diff(moment(timeMS2)))
		.asMilliseconds();
};

function getDepotReaderParentType(depotType) {
	depotType = depotType ? depotType.toUpperCase() : depotType;
	switch (depotType) {
		case 'SQL':
		case 'DB':
			return 'SQL';
		case 'MONGODB':
			return 'MONGODB';
		case 'CSV':
		case 'S3CSV':
		case 'SFTPCSV':
		case 'HDFS_CSV':
		case 'HTTP_CSV':
			return 'CSV';
		case 'PARQUET':
		case 'S3_PARQUET':
		case 'SFTP_PARQUET':
		case 'HDFS_PARQUET':
		case 'HTTP_PARQUET':
			return 'PARQUET';
		case 'JSON':
		case 'SFTP_JSON':
		case 'S3_JSON':
		case 'HDFS_JSON':
		case 'HTTP_JSON':
			return 'JSON';
		case 'EXCEL':
		case 'SFTP_EXCEL':
		case 'S3_EXCEL':
		case 'HDFS_EXCEL':
		case 'HTTP_EXCEL':
			return 'EXCEL';
		case 'S3_BINARY':
		case 'SFTP_BINARY':
		case 'HDFS_BINARY':
			return 'BINARY';
		case 'XML':
		case 'S3_XML':
		case 'SFTP_XML':
		case 'HDFS_XML':
		case 'HTTP_XML':
			return 'XML';
		default:
			return depotType;
	}
}

var formatDate = function (date) {
	let momentDate = moment(date);
	return momentDate.format('L') + ' à ' + momentDate.format('LT');
};

var formatStatValue = function (value) {
	if (!isNumeric(value)) {
		return value;
	}
	if (value == 0) {
		return value;
	}
	if (
		value > 1000000000 ||
		(value < 0.0001 && value > -0.0001) ||
		value < -1000000000
	) {
		return value.toExponential();
	} else {
		return value.toFixed(4);
	}
};

var setMaxStringLength = function (str, length) {
	if (!str) {
		return '';
	}
	if (str.length > length) {
		return str.substring(0, length) + '...';
	} else {
		return str;
	}
};

// Double click show Value
function initialClick(e) {
	let component = e.component;
	component.clickCount = 1;
	component.clickKey = e.key;
	component.clickDate = new Date();
}

function doubleClick(e, functionToExecute) {
	let component = e.component;
	component.clickCount = 0;
	component.clickKey = 0;
	component.clickDate = null;
	functionToExecute(e.text);
}

var showValuePreviewOnCellDoubleClick = function (e, functionToExecute) {
	if (e && e.row && e.row.rowType != 'data') {
		return;
	}
	let component = e.component;
	if (
		!component.clickCount ||
		component.clickCount != 1 ||
		component.clickKey != e.key
	) {
		initialClick(e);
	} else if (component.clickKey == e.key) {
		if (new Date() - component.clickDate <= 300) {
			doubleClick(e, functionToExecute);
		} else {
			initialClick(e);
		}
	}
};

// fix large column width
var limitColumnWithWhenLargerThan400 = function (e) {
	let cols = e.component.getVisibleColumns();
	for (var i = 0; i < cols.length; i++) {
		let width = cols[i].visibleWidth;
		let field = cols[i].dataField;
		if (width > 400) {
			e.component.columnOption(field, 'visibleWidth', 250);
			e.component.columnOption(field, 'width', 250);
		}
	}
};

// Warning filter sort begin
var getFilteredOrSorted = function (grid, scope) {
	let cols = grid.option('columns');
	let sorted = false;
	for (let i = 0; i < cols.length; i++) {
		if (grid.columnOption(i, 'sortOrder') != undefined) {
			sorted = true;
			break;
		}
	}
	scope.filteredOrSorted = sorted || grid.getCombinedFilter() != undefined;
};

var popupWarningFilterOptions = {
	width: 300,
	maxHeight: 110,
	contentTemplate: 'warningFilterSort',
	showTitle: false,
	dragEnabled: false,
	shading: false,
	closeOnOutsideClick: true,
	bindingOptions: {
		visible: 'visibleWarningSortPopup',
	},
};

var warningSortFilterGenerator = function (eT) {
	eT.toolbarOptions.items.unshift({
		location: 'after',
		template: 'warningSortFilter',
	});
};
// Warning filter sort end

//update field value in a list of objects by adding incremental number when the value is duplicated
var incrementAndUpdateDuplicatedValues = function (objects, fieldName) {
	if (!objects || !fieldName) {
		return;
	}
	let valuesCounter = {};
	angular.forEach(objects, function (element) {
		let fieldValue = element[fieldName];
		if (valuesCounter[fieldValue] == undefined) {
			valuesCounter[fieldValue] = 0;
		} else {
			valuesCounter[fieldValue] = valuesCounter[fieldValue] + 1;
			element[fieldName] = fieldValue.concat(valuesCounter[fieldValue]);
		}
	});
};

var timelinesDirectiveScopes = {};

var getTLScope = function (uuid) {
	return timelinesDirectiveScopes[uuid];
};

var getGlobalData = function (uuid, groupId) {
	getTLScope(uuid).loadGroupGlobalDataGrid(groupId);
};

var getDetailsData = function (uuid, groupId) {
	getTLScope(uuid).loadGroupDetailsDataGrid(groupId);
};

var doReboundMany = function (uuid, groupId, ids_) {
	hideInfo();
	getTLScope(uuid).doReboundMany(groupId, ids_);
};

var setSubTimeLineInterval = function (uuid, groupId, ids_) {
	getTLScope(uuid).setSubTimeLineInterval(groupId, ids_);
};

var showItemsInWidget = function (uuid, groupId, ids_) {
	getTLScope(uuid).showItemsInWidget(groupId, ids_);
};

var base = document.createElement('tooltip');

var showInfo = function (uuid, event, groupId, ids_, showCenter) {
	getTLScope(uuid).showInfo(event, groupId, ids_, showCenter);
};

var hideInfo = function (groupId, ids_) {
	if (document.getElementsByTagName('tooltip')[0]) {
		document.getElementsByTagName('tooltip')[0].remove(); // Remove last tooltip
	}
};
