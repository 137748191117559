(function () {
	'use strict';

	angular.module('dcApp').directive('dcHtmlEditor', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					vm.init = init;

					function init() {
						$scope.value = $scope.htmlEditorOptions = {
							autoResizeEnabled: true,
							height: 550,
							toolbar: {
								multiline: true,
								items: [
									'undo',
									'redo',
									'separator',
									{
										name: 'size',
										acceptedValues: [
											'8pt',
											'10pt',
											'12pt',
											'14pt',
											'18pt',
											'24pt',
											'36pt',
										],
									},
									{
										name: 'font',
										acceptedValues: [
											'Arial',
											'Courier New',
											'Georgia',
											'Impact',
											'Lucida Console',
											'Tahoma',
											'Times New Roman',
											'Verdana',
										],
									},
									'separator',
									'bold',
									'italic',
									'strike',
									'underline',
									'separator',
									'alignLeft',
									'alignCenter',
									'alignRight',
									'alignJustify',
									'separator',
									'orderedList',
									'bulletList',
									'separator',
									{
										name: 'header',
										acceptedValues: [false, 1, 2, 3, 4, 5],
									},
									'separator',
									'color',
									'background',
									'separator',
									'link',
									'image',
									'separator',
									'clear',
									'codeBlock',
									'blockquote',
								],
							},
							mediaResizing: {
								enabled: true,
							},
							value: vm.data.html_content,
							onValueChanged: function (htmlContent) {
								vm.data.html_content = htmlContent.value;
							},
						};
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/htmlEditor/htmlEditor.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();
				}
			);
		},
	]);
})();
