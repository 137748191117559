(function () {
	'use strict';
	angular.module('dcApp').directive('elementDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'$state',
				'DataCatalogService',
				'PermissionService',
				'gettextCatalog',
				'API_BASE_URL_BACKEND',
				function (
					$rootScope,
					$scope,
					$state,
					DataCatalogService,
					PermissionService,
					gettextCatalog,
					API_BASE_URL_BACKEND
				) {
					const vm = this;

					vm.details = {};
					vm.message = { sendMessageDialog: false };
					vm.elementGraphData = {};
					vm.dataPreviewConf = {};
					vm.exportDataBlockConf = {};
					vm.count = {};
					vm.permissions = {};
					vm.countLoading = false;
					vm.privateElement = gettextCatalog.getString('Elément privé');
					vm.showGraph = showGraph;
					vm.showData = showData;
					vm.showExportDataBlock = showExportDataBlock;
					vm.closeModal = closeModal;
					vm.init = init;
					vm.openSendMessage = sendMessage;
					vm.goToCurrentElement = goToCurrentElement;

					function init(elementId) {
						DataCatalogService.findOneElementById(elementId)
							.then(function (response) {
								vm.details = response;
								vm.details.image_url =
									API_BASE_URL_BACKEND +
									'pictogramme_image?id=' +
									vm.details.icon_id;
								$(vm.element).modal('show');
								vm.countLoading = true;
								DataCatalogService.getElementCount(elementId)
									.then(function (responseCount) {
										vm.countLoading = false;
										vm.count = responseCount;
									})
									.catch(function (e) {
										vm.countLoading = false;
									});
								PermissionService.havePermissionForManyActions(
									elementId,
									['edit', 'export', 'view'],
									'datablock'
								).then(function (permissionResponse) {
									vm.permissions = permissionResponse.data;
								});
							})
							.catch(function (e) {
								vm.showElementDetails = false;
							});
					}

					function closeModal() {
						$(vm.element).modal('hide');
						vm.showElementDetails = false;
					}

					function sendMessage() {
						vm.message.sendMessageDialog = true;
						vm.message.elementId = vm.elementId;
					}

					function showGraph() {
						vm.elementGraphData.centeredElementLabel = vm.details.label;
						vm.elementGraphData.centeredElementType = vm.details.type;
						vm.elementGraphData.centeredElementId = vm.details.id;
						vm.elementGraphData.axe = 'db';
						vm.elementGraphData.showHdhElementGraph = true;
					}

					function showData() {
						vm.dataPreviewConf.elementId = vm.details.id;
						vm.dataPreviewConf.elementLabel = vm.details.label;
						vm.dataPreviewConf.show = true;
					}

					function showExportDataBlock() {
						vm.exportDataBlockConf.id = vm.details.id;
						vm.exportDataBlockConf.lc = vm.details.label;
						vm.exportDataBlockConf.showExportDatablock = true;
					}

					function goToCurrentElement() {
						vm.showElementDetails = false;
						$state.go('datablocks-edit', { id: vm.details.id });
					}
				},
			];
			return buildDirectiveReturnObject(
				{ elementId: '=', showElementDetails: '=' },
				controller,
				'./src/components/directives/dataCatalog/catalogElementDetails/catalogElementDetails.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showElementDetails = false;
						scope.vm.details = {};
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.showElementDetails;
						},
						function () {
							scope.vm.init(scope.vm.elementId);
						},
						true
					);
				}
			);
		},
	]);
})();
