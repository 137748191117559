(function () {
	'use strict';

	angular.module('dcApp').factory('ParametersServices', [
		'$log',
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($log, $http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var ParametersServices = {};

			ParametersServices.getParameters = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_GENERALS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getAdvancedParameters = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_ADVANCED');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getAllParameters = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.updateParameters = function (parameters) {
				var deferred = $q.defer();
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_GENERALS');
				$http.post(url, parameters).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			ParametersServices.saveLogo = function (avatar) {
				var fd = new FormData();
				fd.append('file', avatar);
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_GENERALS_LOGO');
				return $http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			ParametersServices.saveHandledataLogo = function (avatar) {
				var fd = new FormData();
				fd.append('file', avatar);
				const url = API_BASE_URL_BACKEND + '/parameters/hd/logo';
				return $http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			ParametersServices.getHandledataDefalutLogo = function () {
				const url = API_BASE_URL_BACKEND + '/parameters/hd/defaultLogo';
				return $http
					.post(url, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			ParametersServices.getGenericsdataDefalutLogo = function () {
				const url = API_BASE_URL_BACKEND + '/parameters/gd/defaultLogo';
				return $http
					.post(url, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			ParametersServices.saveGenericsdataLogo = function (avatar) {
				var fd = new FormData();
				fd.append('file', avatar);
				const url = API_BASE_URL_BACKEND + '/parameters/gd/logo';
				return $http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			ParametersServices.deleteLogo = function () {
				const url = API_BASE_URL_BACKEND + 'parameters/general/logo';
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						$log.error(err);
					}
				);
			};

			ParametersServices.deleteHandledataLogo = function () {
				return $http.delete(API_BASE_URL_BACKEND + 'parameters/hd/logo').then(
					function (response) {
						return response;
					},
					function (err) {
						$log.error(err);
					}
				);
			};

			ParametersServices.deleteGenericdataLogo = function () {
				const url = API_BASE_URL_BACKEND + 'parameters/gd/logo';
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						$log.error(err);
					}
				);
			};

			ParametersServices.getEstablishmentData = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_GENERALS_LOGO');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getHandleDataLogo = function () {
				const url = API_BASE_URL_BACKEND + '/parameters/hd/logo';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getGenericsDataLogo = function () {
				const url = API_BASE_URL_BACKEND + '/parameters/gd/logo';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getFileMaxSize = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_MAX_FILE_SIZE');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getTakeMaxRow = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_TAKE_MAX_ROW');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParametersServices.getCacheStatus = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_CACHE_STATUS');
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						$log.error(err);
						return false;
					}
				);
			};

			ParametersServices.updateCacheStatus = function (isActive) {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARAMETERS_CACHE_STATUS');
				return $http.put(url, isActive).then(function (response) {
					return response;
				});
			};

			return ParametersServices;
		},
	]);
})();
