'use strict';

angular
	.module('dcApp')
	.controller('TimeLineListController', [
		'$location',
		'$scope',
		'$http',
		'$state',
		'$stateParams',
		'$rootScope',
		'TimeLineService',
		'toaster',
		'$q',
		'API_BASE_URL_BACKEND',
		'DEFAULT_CELL_TEMPLATE',
		'$timeout',
		'PermissionService',
		'gettextCatalog',
		'DcElementListService',
		function (
			$location,
			$scope,
			$http,
			$state,
			$stateParams,
			$rootScope,
			TimeLineService,
			toaster,
			$q,
			API_BASE_URL_BACKEND,
			DEFAULT_CELL_TEMPLATE,
			$timeout,
			PermissionService,
			gettextCatalog,
			DcElementListService
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDuplicateSuccess = gettextCatalog.getString(
				'Duplication TimeLine effectuée'
			);
			let toasterPopDeleteSuccess = gettextCatalog.getString(
				'Suppression de la TimeLine effectuée'
			);
			let toasterPopDeleteTlsSuccess = gettextCatalog.getString(
				'Suppression des TimeLines effectuée'
			);
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			$scope.checkPermissionData = {};
			PermissionService.haveGlobalPermission('timeline-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

			$scope.filters = {};
			$scope.sortings = {};
			var paginationOptions = {
				pageNumber: 1,
				pageSize: 25,
				sort: null,
			};
			var filtersParam = $location.search();
			$scope.paginationParams = {
				max_results: paginationOptions.pageSize,
				page_number: paginationOptions.pageNumber,
				filters: $scope.filters,
				sortings: $scope.sortings,
			};

			$scope.paginationParams.max_results =
				filtersParam.max_results != undefined
					? parseInt(filtersParam.max_results)
					: $scope.paginationParams.max_results;
			$scope.paginationParams.page_number =
				filtersParam.page_number != undefined
					? parseInt(filtersParam.page_number)
					: $scope.paginationParams.page_number;
			$scope.paginationParams.filters =
				filtersParam.filters != undefined
					? JSON.parse(filtersParam.filters)
					: $scope.paginationParams.filters;
			$scope.paginationParams.sortings =
				filtersParam.sortings != undefined
					? JSON.parse(filtersParam.sortings)
					: $scope.paginationParams.sortings;

			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'timeline';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.editTimeline = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'timeline';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.duplicateTimeline = function (obj) {
				TimeLineService.duplicateTimeline(obj.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDuplicateSuccess);
					$state.go('timelines-edit', { id: response.data });
				});
			};

			$scope.createTimeline = function () {
				$state.go('timelines-new', {});
			};

			$scope.goToEdit = function () {
				$state.go('timelines-edit', { id: $scope.objToEdit.id });
			};

			$scope.viewTimeline = function (obj) {
				$state.go('timelines-view', { id: obj.id, mode: 'visualisation' });
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkTimeLines
						: $scope.deleteTimeLine,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.deleteTimeLine = function () {
				TimeLineService.deleteTimeLine($scope.objToDelete.id).then(function (
					response
				) {
					$('#delModal').modal('hide');
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteSuccess);
					initTimeLines();
				});
			};

			$scope.deleteBulkTimeLinesModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkTimeLines = function () {
				let timeLiesIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				TimeLineService.deleteBulkTimeLines(timeLiesIds)
					.then(function () {
						if ($scope.timeline_authorised) {
							initTimeLines();
						}
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteTlsSuccess
						);
					})
					.catch(function (e) {
						if (
							e &&
							e.data &&
							e.data.code == '40' &&
							$scope.timeline_authorised
						) {
							initTimeLines();
						}
					});
			};

			function getListTimeLines() {
				var deferred = $q.defer();
				TimeLineService.getTimeLines($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);

				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListTimeLines().then(function (content) {
					if (content !== undefined) {
						$scope.timelines = [];
						$scope.tags = [];
						var tags = [];
						for (var r in content) {
							$scope.timelines.push(
								DcElementListService.buildHdListElement(
									content[r],
									$rootScope,
									false
								)
							);
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.showGrid = true;
						$scope.gridData = angular.copy($scope.timelines);
						$scope.dataGridOptions.dataSource = $scope.gridData;
					}
				});
			};

			function initTimeLines() {
				PermissionService.haveGlobalPermission('timeline').then(function (
					response
				) {
					$scope.timeline_authorised = response.data;
					if ($scope.timeline_authorised) {
						$scope.getGridData();
						$scope.disableAllSelectedElems = true;
					}
				});
			}

			$scope.moveOrCopyDataTL = {};
			$scope.moveOrCopyTLsToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyDataTL = {
					elements: connectorIds,
					type: 'timeline',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneTLToProject = function (elementId) {
				$scope.moveOrCopyDataTL = {
					elements: [elementId],
					type: 'timeline',
					showMoveCopy: true,
				};
			};

			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'timeline', false);
			initTimeLines();
		},
	])
	.controller('TimeLineEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'TimeLineService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'hdSourceService',
		'gettextCatalog',
		'PermissionService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			TimeLineService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			hdSourceService,
			gettextCatalog,
			PermissionService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Timelines');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopInvalidParam = gettextCatalog.getString(
				'Paramètres invalides'
			);
			let toasterSaveParamsSuccess = gettextCatalog.getString(
				'Enregistrement des paramètres effectué'
			);
			let toasterPopDeleteSuccess = gettextCatalog.getString(
				'Suppression de la TimeLine effectuée'
			);
			let toasterRefDateMandatory = gettextCatalog.getString(
				'La date de référence est obligatoire'
			);
			let toasterCreateTlSuccess = gettextCatalog.getString(
				'Création de la TimeLine effectuée'
			);
			let toasterUpdateTlSuccess = gettextCatalog.getString(
				'Modification de la TimeLine effectuée'
			);

			$scope.accessToken = window._keycloak.token;
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};

			$scope.tlView = {};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('timelines');
				}, 300);
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.timeline && $scope.timeline.metadata
							? $scope.timeline.metadata.label
							: undefined,
					doDelete: $scope.deleteTimeline,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.timeline.id;
				$scope.checkPermissionData.type = 'timeline';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteTimeline = function () {
				TimeLineService.deleteTimeLine($scope.timeline.id).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteSuccess);
					$timeout(function () {
						$state.go('timelines');
					}, 300);
				});
			};

			$scope.toggleShowTabs = function () {
				$scope.hideTabs = !$scope.hideTabs;
			};

			//handle data source directive
			$scope.onLoadSource = function (noAvailableSources) {
				$scope.sourceExists = !noAvailableSources;
				$scope.disabledConfig = noAvailableSources;
				if ($scope.disabledConfig) {
					$scope.disabledVisualisation = true;
				}
			};

			$scope.dataSource = {
				onOpenSource: $scope.toggleShowTabs,
				onLoadSource: $scope.onLoadSource,
			};
			//handle data source directive

			$scope.showDataBlockViews = function (saveSelectedDataBlockViews) {
				$scope.widgetData.saveSelectedDataBlockViews =
					saveSelectedDataBlockViews;
				$scope.widgetData.showDataBlockViews = true;
			};

			$scope.dataSource.showDataBlockViews = $scope.showDataBlockViews;

			$scope.showSources = function () {
				if (!$scope.configSaved && $scope.tlConfig) {
					$scope.saveConfig(showSources);
				}
				showSources();
			};

			var showSources = function () {
				delete $scope.tlView.timelineId;
				$scope.dataSource.reinit();
			};
			//end

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.timeline.metadata.label;
				$scope.elementGraphData.centeredElementType = 'TIMELINE';
				$scope.elementGraphData.centeredElementId = $scope.timeline.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.timeline.id;
				$scope.checkPermissionData.type = 'timeline';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.timeline.metadata.id;
				$scope.permissionData.type = 'timeline';
				$scope.permissionData.elementId = $scope.timeline.id;
				$scope.permissionData.lib = $scope.timeline.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showConfig = function () {
				delete $scope.tlView.timelineId;
				$scope.configSaved = false;
				TimeLineService.getTimeLineConfig($stateParams.id).then(function (
					response
				) {
					$scope.tlConfig = response.data;
					if (!$scope.tlConfig.global) {
						$scope.tlConfig.global = {};
					}
					$scope.tlConfig.groups = _.sortBy(
						$scope.tlConfig.groups,
						function (item) {
							return item.order;
						}
					);
					setGroupColumns(0);
				});
			};

			var setGroupColumns = function (i) {
				if (!$scope.tlConfig.groups[i]) {
					return;
				}
				hdSourceService
					.getSourceColumns(
						$scope.tlConfig.groups[i].source_id,
						'TIMELINE',
						$stateParams.id
					)
					.then(function (response) {
						$scope.tlConfig.groups[i].columns = response.data;
						setGroupColumns(i + 1);
					});
			};

			$scope.saveConfig = function (afterSave) {
				if (!checkConfig()) {
					return;
				}
				var config = angular.copy($scope.tlConfig);
				var groups = [];
				for (var g in config.groups) {
					groups.push({
						id: config.groups[g].id,
						config: config.groups[g].config,
						order: Number(g),
					});
				}

				config.groups = groups;

				TimeLineService.editTimelineConfig($stateParams.id, config).then(
					function (response) {
						//$scope.configSaved = true;
						toaster.pop('success', toasterPopSuccess, toasterSaveParamsSuccess);
						$scope.disabledVisualisation = false;
						$scope.configIsValid = true;
						if (afterSave) {
							afterSave();
						}
					}
				);
			};

			function checkConfig() {
				for (var g in $scope.tlConfig.groups) {
					if (!$scope.tlConfig.groups[g].config.start_date_column) {
						toaster.pop(
							'error',
							toasterPopInvalidParam,
							toasterRefDateMandatory
						);
						return false;
					}
				}
				return true;
			}

			$scope.openGroupTooltip = function (group) {
				$scope.selectedGroup = group;
				updateSortedColumns(group);
				$('#listTooltipColumnsModal').modal('show');
			};

			var updateSortedColumns = function (group) {
				if (!group.tooltip_columns) {
					group.tooltip_columns = [];
				}
				var sortedColumns = [];
				for (let c in group.config.tooltip_columns) {
					var col = _.find(group.columns, function (item) {
						return item.uuid == group.config.tooltip_columns[c];
					});
					col.selected = true;
					sortedColumns.push(col);
				}

				for (let c in group.columns) {
					if (sortedColumns.indexOf(group.columns[c]) < 0) {
						group.columns[c].selected = false;
						sortedColumns.push(group.columns[c]);
					}
				}
				$scope.sortedColumns = sortedColumns;
			};

			$scope.saveTooltip = function () {
				$scope.selectedGroup.config.tooltip_columns = [];
				for (let c in $scope.sortedColumns) {
					if ($scope.sortedColumns[c].selected) {
						$scope.selectedGroup.config.tooltip_columns.push(
							$scope.sortedColumns[c].uuid
						);
					}
				}
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.closeTooltip = function () {
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.tlConfig) {
					PermissionService.havePermission(
						$stateParams.id,
						'edit',
						'timeline'
					).then(function (response) {
						if (response.data) {
							$scope.saveConfig(showVisualization);
						} else {
							showVisualization();
						}
					});
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				$scope.configSaved = true;
				if ($scope.sourceExists && $scope.configIsValid) {
					if (!$scope.tlView.timelineId) {
						$scope.tlView.timelineId = $stateParams.id;
					} else if ($scope.tlView.reinit) {
						$scope.tlView.reinit();
					}
				}
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.savingInProgress = false;

				if ($stateParams.id) {
					$scope.isNew = false;
					$scope.metadata.isNew = false;
					//if no source is available => deactivate config and visualisation tab. Else activate config tab
					hdSourceService
						.getNbrSourcesByElement('TIMELINE', $stateParams.id)
						.then(function (response) {
							$scope.sourceExists = response.data;
							if ($scope.sourceExists) {
								TimeLineService.checkTimeLineConfig($stateParams.id).then(
									function (responseCheck) {
										$scope.configIsValid = responseCheck.data;
										if ($stateParams.mode == 'visualisation') {
											$('.nav-tabs a[data-target="#visualization"]').tab(
												'show'
											);
											$scope.showVisualization();
										} else {
											$('.nav-tabs a[data-target="#config"]').tab('show');
											$scope.disabledVisualisation = !$scope.configIsValid;
											$scope.showConfig();
										}
									}
								);
							} else if ($stateParams.mode == 'visualisation') {
								$scope.disabledConfig = true;
								$('.nav-tabs a[data-target="#visualization"]').tab('show');
								$scope.showVisualization();
							} else {
								$scope.disabledConfig = true;
								$scope.disabledVisualisation = true;
							}
						});

					var tlId = $stateParams.id;
					$scope.dataSource.hdId = tlId;
					$scope.dataSource.hdType = 'TIMELINE';
					TimeLineService.getTimeLine(tlId).then(function (response) {
						$scope.timeline = response.data;
						$scope.metadata = $scope.timeline.metadata;
						$scope.metadataLoaded = true;
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveTimeline(true);
			};
			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchTimelineSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveTimeline = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var timeline = {};

				timeline.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					timeline.metadata.code = $scope.metadata.code;
					TimeLineService.createTimeLine(timeline)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterCreateTlSuccess);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('timelines');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('timelines-edit', { id: response.data });
								}, 300);
							}
						})
						.catch(catchTimelineSaveErrors);
				} else {
					TimeLineService.editTimeLine($scope.timeline.id, timeline)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterUpdateTlSuccess);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('timelines');
								}, 300);
							}
						})
						.catch(catchTimelineSaveErrors);
				}
			};
		},
	]);
