(function () {
	angular.module('dcApp').factory('ImportDbService', [
		'$http',
		'$q',
		'API_BASE_URL_BACKEND',
		function ($http, $q, API_BASE_URL_BACKEND) {
			var ImportDbService = {},
				endPoint = API_BASE_URL_BACKEND + '/import_db/';

			ImportDbService.getDbSchemas = function (request) {
				const url = endPoint + '/request';
				return $http.post(url, request).then(function (response) {
					return response;
				});
			};

			ImportDbService.sendDbSchema = function (
				schema,
				connectorId,
				createRepDetails
			) {
				const details = createRepDetails ? createRepDetails : false;
				const url =
					endPoint +
					'?connectorId=' +
					connectorId +
					'&createRepDetails=' +
					details;
				return $http.post(url, schema).then(function (response) {
					return response;
				});
			};

			ImportDbService.getTablePreview = function (
				tableName,
				schemaName,
				connectorId
			) {
				let url =
					endPoint +
					'preview?tableName=' +
					tableName +
					'&schemaName=' +
					schemaName +
					'&connectorId=' +
					connectorId;
				return $http.get(url).then(
					function (response) {
						const data = response.data;
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			ImportDbService.getTypesMaps = function () {
				const url = endPoint + 'type_mapper';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ImportDbService.stopOperation = function (clientId) {
				let url = endPoint + 'stop?clientId=' + clientId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return ImportDbService;
		},
	]);
})();
