(function () {
	angular.module('dcApp').factory('RankingService', [
		'$http',
		'UrlService',
		function ($http, UrlService) {
			var RankingService = {};
			const endPoint = UrlService.generateUrl('RANKING', {}, {});

			RankingService.createRanking = function (ranking) {
				return $http.post(endPoint, ranking).then(function (response) {
					return response;
				});
			};

			RankingService.editRanking = function (ranking) {
				return $http.put(endPoint, ranking).then(function (response) {
					return response;
				});
			};

			RankingService.findAll = function (excludePublic, excludeInactive) {
				const url = UrlService.generateUrl('RANKING', {},
				{excludePublic: excludePublic ? excludePublic : false, excludeInactive: excludeInactive ? excludeInactive : false});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			RankingService.deleteRanking = function (id) {
			const url = UrlService.generateUrl('RANKING_BY_ID', {rankingId: id}, {});
				return $http
					.delete(url)
					.then(function (response) {
						return response;
					});
			};

			RankingService.getRankingById = function (id) {
				const url = UrlService.generateUrl('RANKING_BY_ID', {rankingId: id}, {});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			RankingService.duplicateRanking = function (id) {
			const url = UrlService.generateUrl('DUPLICATE_RANKING', {rankingId: id}, {});
				return $http
					.get(url)
					.then(function (response) {
						return response;
					});
			};

			RankingService.disableRanking = function (id) {
			const url = UrlService.generateUrl('RANKING_DISABLE', {rankingId: id}, {});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			RankingService.enableRanking = function (id) {
				const url = UrlService.generateUrl('RANKING_ENABLE', {rankingId: id}, {});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return RankingService;
		},
	]);
})();
