(function () {
	'use strict';

	angular.module('DcDepotModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('depot-parent', {
			url: '/depot',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('depots', {
			url: '/',
			parent: 'depot-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Dépôts',
			},
			views: {
				'@depot-parent': {
					templateUrl: './src/components/modules/depot/depots.html',
					controller: 'DepotListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('depots-new', {
			url: '/new',
			parent: 'depot-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Nouveau Dépôt',
			},
			views: {
				'@depot-parent': {
					templateUrl: './src/components/modules/depot/depot-form.html',
					controller: 'DepotEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('depots-edit', {
			url: '/edit/:type/:id',
			parent: 'depot-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Edition du Dépôt',
			},
			views: {
				'@depot-parent': {
					templateUrl: './src/components/modules/depot/depot-form.html',
					controller: 'DepotEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('depots-fromConnector', {
			url: '/new/fromConnector/:connectorId',
			parent: 'depot-parent',
			data: {
				admin: true,
				access: 'member',
				title: "Dépôt à partir d'un Connecteur",
			},
			views: {
				'@depot-parent': {
					templateUrl: './src/components/modules/depot/depot-form.html',
					controller: 'DepotEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('depots-duplicate', {
			url: '/:type/duplicate/:duplicatedId',
			parent: 'depot-parent',
			data: {
				admin: true,
				access: 'member',
				title: 'Dupliquer le Dépôt',
			},
			views: {
				'@depot-parent': {
					templateUrl: './src/components/modules/depot/depot-form.html',
					controller: 'DepotEditController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
