(function () {
	'use strict';

	angular.module('MiscellaneousSettingsModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('miscellaneous-settings', {
			url: '/miscellaneous',
			parent: 'target-project',
			abstract: true,
		});

		$stateProvider.state('export-template-list', {
			url: '/export/list',
			parent: 'miscellaneous-settings',
			data: {
				admin: true,
				access: '*',
				title: 'Liste Template Export',
			},
			resolve: {
				isList: () => true,
			},
			views: {
				'@miscellaneous-settings': {
					component: 'exportTemplateListCmp',
				},
			},
		});

		$stateProvider.state('export-template-edit', {
			url: '/export/:exportConfigId',
			parent: 'miscellaneous-settings',
			params: {
				type: '',
			},
			data: {
				admin: true,
				access: '*',
				title: 'Template Export',
			},
			views: {
				'@miscellaneous-settings': {
					template: `<app-export-template
						ng-prop-export_config_id="$ctrl.exportConfigId">
						</app-export-template>`,
					controller: [
						'$stateParams',
						function ($stateParams) {
							const $ctrl = this;
							init();
							function init() {
								const exportId = parseInt($stateParams['exportConfigId'], 10);
								$ctrl.exportConfigId = !Number.isNaN(exportId)
									? exportId
									: null;
							}
						},
					],
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('metadata', {
			url: '/metadata',
			parent: 'miscellaneous-settings',
			data: {
				admin: true,
				access: '*',
				title: 'Metadata',
			},
			views: {
				'@miscellaneous-settings': {
					templateUrl: './src/components/modules/metadata/metadatas.html',
					controller: 'MetadataController',
				},
			},
		});

		$stateProvider.state('formulas-usage', {
			url: '/formulas/usage',
			parent: 'miscellaneous-settings',
			data: {
				admin: true,
				access: '*',
				title: 'Usage Formule',
			},
			views: {
				'@miscellaneous-settings': {
					template: `<app-formulas-usage-list></app-formulas-usage-list>`,
				},
			},
		});

		$stateProvider.state('formulas-catalog', {
			url: '/formulas/catalog',
			parent: 'target-project',
			data: {
				admin: true,
				access: '*',
				title: 'Catalogues des Formules',
			},
			views: {
				'@target-project': {
					templateUrl:
						'./src/components/modules/formulas-catalog/formulas-catalog.html',
					controller: 'FormulasCatalogController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('rankings', {
			url: '/rankings',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Classements',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/ranking/rankings.html',
					controller: 'RankingListController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('rankings-new', {
			url: '/rankings/new',
			parent: 'target-project',
			data: {
				admin: true,
				access: '*',
				title: 'Nouveau Classement',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/ranking/ranking-form.html',
					controller: 'RankingEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('rankings-edit', {
			url: '/rankings/edit/:rankingId',
			parent: 'target-project',
			data: {
				admin: true,
				access: '*',
				title: 'Edition du Classement',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/ranking/ranking-form.html',
					controller: 'RankingEditController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('dataBlockExportHistory', {
			url: '/datablocks-export-history',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Historique des exports',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/export-history/history.html',
					controller: 'ExportHistoryController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('dc-graph', {
			url: '/dc-graph',
			parent: 'target-project',
			data: {
				admin: false,
				access: 'member',
				title: 'HD-Graphique',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/graph/dcGraph.html',
					controller: 'DcGraphController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('traces', {
			url: '/traces',
			parent: 'target-project',
			data: {
				admin: false,
				access: '*',
				title: 'Traces',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/trace/traces.html',
					controller: 'TracesController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('importDb', {
			url: '/import-db',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Import base de données',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/import-db/import-db.html',
					controller: 'ImportDbController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('importDC', {
			url: '/import-dc',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Import HD',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/import-dc/import-dc.html',
					controller: 'ImportDCController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('link-graph', {
			url: '/link-graph?node=:nodeId',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Graphe complet',
			},
			params: { nodeId: { dynamic: true } },
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/graph/linkGraph.html',
					controller: 'LinkGraphController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('path-graph', {
			url: '/path-graph',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Graphe Fonctionnel',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/graph/pathGraph.html',
					controller: 'PathGraphController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('value-search', {
			url: '/value-search',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'Recherche de valeurs',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/value-search/search.html',
					controller: 'valueSearchController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
