angular
	.module('dcApp')
	.controller('deleteProjectValidation', [
		'$scope',
		function ($scope, ProjectService, $timeout) {},
	])
	.directive('deleteProjectValidation', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'ProjectService',
				'$timeout',
				function ($scope, ProjectService, $timeout) {
					const MIN_SIZE = 0;
					$scope.init = function () {
						$scope.isValidCode = $scope.isValidCode
							? $scope.isValidCode
							: false;

						ProjectService.getDeleteProjectToken($scope.projectId).then(
							(response) => {
								if (response) {
									$scope.showResendCode = true;
								}
							}
						);
					};
					$scope.checkDeleteToken = function () {
						$scope.isValidCode = $scope.deleteToken.length > MIN_SIZE;
					};

					$scope.resendCode = function () {
						$scope.showResendCode = false;
						ProjectService.getDeleteProjectToken($scope.projectId).then(
							(response) => {
								if (response) {
									$scope.showResendCode = true;
									$scope.showMessage = true;
									$scope.deleteToken = '';
									$timeout(function () {
										$scope.showMessage = false;
									}, 5000);
								}
							}
						);
					};
				},
			];
			return {
				restrict: 'AECM',
				templateUrl:
					'./src/components/modules/project/deleteProject/deleteProjectValidation.html',
				replace: true,
				controller: controller,
				scope: {
					projectElements: '=',
					isValidCode: '=',
					projectId: '=',
					deleteToken: '=',
					projectName: '=',
				},
				link: function (scope) {
					scope.init();
				},
			};
		},
	]);
