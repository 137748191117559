(function () {
	'use strict';

	angular.module('dcApp').factory('SparkJobsServices', [
		'$http',
		'UrlService',
		function ($http, UrlService) {
			var SparkJobsServices = {};
			SparkJobsServices.getJobs = function (onlyRunning) {
				const url = UrlService.generateUrl(
					'SPARK_JOBS',
					{},
					{ onlyRunning: onlyRunning }
				);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			SparkJobsServices.killJob = function (jobId) {
				const url = UrlService.generateUrl('SPARK_JOB', { jobKey: jobId }, {});
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			SparkJobsServices.killAllJobs = function () {
				const url = UrlService.generateUrl('SPARK_JOBS_KILL_ALL', {}, {});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			SparkJobsServices.clearJobs = function () {
				const url = UrlService.generateUrl('SPARK_JOBS_CLEAR_JOBS', {}, {});

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			SparkJobsServices.getRunningJobsNbr = function () {
				const url = UrlService.generateUrl('SPARK_JOBS_RUNNING_NBR', {}, {});

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			SparkJobsServices.getRunningJob = function () {
				const url = UrlService.generateUrl('SPARK_JOBS_RUNNING_JOB', {}, {});

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return SparkJobsServices;
		},
	]);
})();
