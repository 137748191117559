(function () {
	angular.module('dcApp').filter('highlightdb', [
		'$sce',
		function ($sce) {
			return function (text, phrase, highlight) {
				if (highlight && phrase) {
					text = text.replace(
						new RegExp('(' + phrase + ')', 'gi'),
						'<span class="highlighted">$1</span>'
					);
				}

				return $sce.trustAsHtml(text);
			};
		},
	]);
})();
