(function () {
	'use strict';

	angular.module('dcApp').directive('confirmUserlogout', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'UserService',
				'toaster',
				'gettextCatalog',
				function ($scope, $rootScope, UserService, toaster, gettextCatalog) {
					var vm = this;

					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopSaveSuccess = gettextCatalog.getString(
						"L'Utilisateur a été déconnecté avec succès"
					);

					$scope.logout = function () {
						UserService.logoutUser(vm.data.userId, $scope.deleteApiTokens).then(
							function (response) {
								toaster.pop(
									'success',
									toasterPopSuccess,
									toasterPopSaveSuccess
								);
								$rootScope.$broadcast('user-logout', vm.data.userId);
								closeModal();
							}
						);
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.data.showConfirmUserLogout = false;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/confirmUserLogout/confirmUserLogout.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showConfirmUserLogout = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data
								? scope.vm.data.showConfirmUserLogout
								: false;
						},
						function () {
							scope.deleteApiTokens = 'false';
						}
					);
				}
			);
		},
	]);
})();
