(function () {
	'use strict';

	angular.module('dcApp').constant('NEW_COLUMN_MODIFICATION_MODE', 'newColumn');
	angular
		.module('dcApp')
		.constant('EDIT_COLUMN_MODIFICATION_MODE', 'editColumn');

	angular.module('dcApp').directive('columnsMapping', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				'DataBlocksService',
				'toaster',
				'$sce',
				'gettextCatalog',
				'CaracService',
				'DATA_TYPES',
				'TypeEntiteService',
				'NEW_COLUMN_MODIFICATION_MODE',
				'EDIT_COLUMN_MODIFICATION_MODE',
				function (
					$scope,
					GenericService,
					DataBlocksService,
					toaster,
					$sce,
					gettextCatalog,
					CaracService,
					DATA_TYPES,
					TypeEntiteService,
					NEW_COLUMN_MODIFICATION_MODE,
					EDIT_COLUMN_MODIFICATION_MODE
				) {
					var ModificationMode = {
						AddNewColumn: NEW_COLUMN_MODIFICATION_MODE,
						EditColumn: EDIT_COLUMN_MODIFICATION_MODE,
					};
					var vm = this;
					vm.editColumn = editColumn;
					$scope.types = _.each(DATA_TYPES, function (e) {
						e.label = gettextCatalog.getString(e.lib);
					});
					$scope.showDataBlockList = function () {
						vm.widgetData.hdhElementRestrictedList = [];
						vm.widgetData.elementSelectionType = vm.entitiesDisabled
							? 'DATABLOCK'
							: 'DATABLOCK_ENTITE';
						vm.widgetData.elementSelectionExclude = [
							$scope.mappingSourceId,
							$scope.vm.widgetData.widget.id,
						];
						vm.widgetData.showHdhElementSelection = true;
						vm.widgetData.elementSelectionSaveMethod =
							$scope.selectDataBlockOrEntite;
					};

					$scope.selectDataBlockOrEntite = function (element, type) {
						$scope.fromDataBlock = type == 'DATABLOCK' ? true : false;
						$scope.mappingSourceLib = element.lib;
						delete $scope.pivotCaracId;
						$scope.pivotParentId = element.id;
						$scope.mappingSourceId = element.id;
						_.each($scope.tmpColumns, function (column) {
							delete column.path;
							delete column.uca_id;
							delete column.uca_code;
							delete column.parent_entite_type_id;
						});
						loadColumns(element.id, true);
					};

					$scope.getHtmlIconByType = function (type, isList) {
						return type != undefined
							? $sce.trustAsHtml(getHtmlIconByType(type.toLowerCase(), isList))
							: '';
					};

					$scope.init = function () {
						$scope.columnFilterText = '';
						$scope.sourceColumnFilterText = '';
						$scope.columnsSortMode = 1;
						$scope.sourceColumnsSortMode = 1;
						// TO  DO use list or is_list (stop using both)
						$scope.tmpColumns = $scope.vm.widgetData.mappingColumns
							? angular.copy($scope.vm.widgetData.mappingColumns).map((c) => {
									c.is_list = c['list'];
									return c;
							  })
							: $scope.vm.widgetData.mappingColumns;
						$scope.filteredColumns = $scope.tmpColumns;
						$scope.fromDataBlock =
							$scope.vm.widgetData.widget.grammar.from_datablock;

						$scope.datablockLib = $scope.vm.widgetData.widget.name;

						let pivotCol = _.filter($scope.tmpColumns, function (item) {
							return item.pivot;
						})[0];
						$scope.pivotCaracId = pivotCol.uca_id;
						$scope.pivotType = pivotCol.type;
						$scope.pivotParentId = pivotCol.parent_entite_type_id;
						$scope.mappingSourceId = $scope.pivotParentId;

						TypeEntiteService.getLibsForMany([$scope.mappingSourceId]).then(
							function (response) {
								$scope.mappingSourceLib = response.data[$scope.mappingSourceId];
							}
						);

						loadColumns($scope.mappingSourceId, false);
					};

					$scope.getColumns = function (column) {
						column.is_list = column.is_list ? column.is_list : false;
						return _.filter($scope.sourceColumns, function (item) {
							return item.type == column.type && item.is_list == column.is_list;
						});
					};

					$scope.filterColumns = function () {
						$scope.sourceColumnFilterText = '';
						$scope.filteredColumns = _.filter($scope.tmpColumns, function (el) {
							return (
								el.column_alias
									.toLowerCase()
									.indexOf($scope.columnFilterText.toLowerCase()) > -1
							);
						});
					};

					$scope.filterSourceColumns = function () {
						$scope.columnFilterText = '';
						$scope.filteredColumns = _.filter($scope.tmpColumns, function (el) {
							return (
								el.field
									.toLowerCase()
									.indexOf($scope.sourceColumnFilterText.toLowerCase()) > -1
							);
						});
					};

					$scope.sortColumns = function () {
						$scope.filteredColumns = _.sortBy(
							$scope.filteredColumns,
							function (item) {
								return item.column_alias.trim().toUpperCase();
							}
						);
						if ($scope.columnsSortMode == 1) {
							$scope.filteredColumns = $scope.filteredColumns.reverse();
						}
						$scope.columnsSortMode = -$scope.columnsSortMode;
					};

					$scope.sortSourceColumns = function () {
						$scope.filteredColumns = _.sortBy(
							$scope.filteredColumns,
							function (item) {
								return item.field ? item.field.trim().toUpperCase() : '';
							}
						);
						if ($scope.sourceColumnsSortMode == 1) {
							$scope.filteredColumns = $scope.filteredColumns.reverse();
						}
						$scope.sourceColumnsSortMode = -$scope.sourceColumnsSortMode;
					};

					var loadColumns = function (id, autoMapping) {
						$scope.sourceColumns = [];
						CaracService.getSimpleCaracsByEntiteId(id).then(function (
							response
						) {
							//if pivot is not affected ==> set it with the first carac matching old pivot type
							let pivotCaracCode;
							if (!$scope.pivotCaracId) {
								let pivotCarac = _.filter(response.data, function (item) {
									return (
										getTypeFromEntiteTypeCode(item.entite_type.code) ==
										$scope.pivotType
									);
								})[0];
								$scope.pivotCaracId = pivotCarac ? pivotCarac.id : undefined;
								pivotCaracCode = pivotCarac ? pivotCarac.code : undefined;
							}
							//fill source columns
							for (let c in response.data) {
								let path =
									$scope.pivotCaracId == response.data[c].id
										? 'c' + $scope.pivotCaracId
										: 'c' +
										  $scope.pivotCaracId +
										  '-' +
										  $scope.pivotParentId +
										  '-c' +
										  response.data[c].id;
								$scope.sourceColumns.push({
									path: path,
									parent_entite_type_id: $scope.pivotParentId,
									alias: response.data[c].lib,
									carac_id: response.data[c].id,
									carac_code: response.data[c].code,
									type: getTypeFromEntiteTypeCode(
										response.data[c].entite_type.code
									),
									is_list: response.data[c].list,
								});
							}
							if ($scope.pivotCaracId) {
								_.each($scope.tmpColumns, function (column) {
									if (column.pivot) {
										column.path = 'c' + $scope.pivotCaracId;
										column.uca_id = $scope.pivotCaracId;
										column.uca_code = pivotCaracCode;
										column.parent_entite_type_id = $scope.pivotParentId;
									}
								});
							}
							updateSourceColumns();
							if (autoMapping) {
								updateUnmappedColumns();
							}
						});
					};

					$scope.changeColumnMapping = function (column, selected) {
						// if changing pivot, update paths
						if (column.pivot && selected) {
							updatePaths(column, selected);
						}
						if (column.path) {
							let sourceColumn = _.find($scope.sourceColumns, function (item) {
								return item.path == column.path;
							});
							column.uca_id = sourceColumn.carac_id;
							column.uca_code = sourceColumn.carac_code;
							column.parent_entite_type_id = sourceColumn.parent_entite_type_id;
							column.parent_entite_type_lib =
								sourceColumn.parent_entite_type_lib;
						} else {
							clearCaracReferences(column);
						}
					};

					$scope.addNewColumn = function () {
						$scope.columnModificationMode = ModificationMode.AddNewColumn;
						$scope.currentColumn = {
							uuid: generateUuid('_'),
							active: true,
							type: 'string',
						};
						$('#mappingColumnModal').modal('show');
					};

					$scope.saveColumn = function () {
						if ($scope.columnModificationMode === ModificationMode.EditColumn) {
							saveModifiedColumn();
							return;
						}
						saveNewColumn();
					};

					let saveNewColumn = function () {
						$scope.currentColumn.column_alias = $scope.currentColumn.alias;
						$scope.tmpColumns.push($scope.currentColumn);
						$scope.filteredColumns = $scope.tmpColumns;
						closeMappingColumnModal();
					};

					let saveModifiedColumn = function () {
						if ($scope.columnToEdit.type != $scope.currentColumn.type) {
							clearCaracReferences($scope.columnToEdit);
						}
						$scope.columnToEdit.column_alias = $scope.currentColumn.alias;
						$scope.columnToEdit.alias = $scope.currentColumn.alias;
						$scope.columnToEdit.type = $scope.currentColumn.type;
						$scope.columnToEdit.is_list = $scope.currentColumn.is_list;
						delete $scope.columnToEdit;
						closeMappingColumnModal();
					};

					let clearCaracReferences = function (column) {
						column.uca_code = undefined;
						column.uca_id = undefined;
						column.parent_entite_type_id = undefined;
						column.parent_entite_type_lib = undefined;
						column.path = undefined;
					};

					$scope.cancelColumnModification = function () {
						delete $scope.currentColumn;
						delete $scope.columnToEdit;
						closeMappingColumnModal();
					};

					let closeMappingColumnModal = function () {
						$('#mappingColumnModal').modal('hide');
					};

					$scope.selectOtherColumn = function (column) {
						$scope.selectedColumn = column;
						$scope.vm.widgetData.selectConnectedColumnPivotId =
							$scope.pivotCaracId;
						$scope.vm.widgetData.selectConnectedColumnParentId =
							$scope.pivotParentId;
						$scope.vm.widgetData.selectConnectedColumnType = column.type;
						$scope.vm.widgetData.selectConnectedColumnIsList = column.is_list;
						$scope.vm.widgetData.selectConnectedColumnPaths = _.map(
							_.filter($scope.tmpColumns, function (item) {
								return !item.pivot && item.path;
							}),
							function (item) {
								return item.path;
							}
						);
						$scope.vm.widgetData.saveSelectConnectedColumn =
							$scope.saveSelectConnectedColumn;
						vm.widgetData.showSelectConnectedColumn = true;
					};

					$scope.saveSelectConnectedColumn = function (path, carac) {
						$scope.selectedColumn.path = path;
						$scope.selectedColumn.pivot = false;
						$scope.selectedColumn.uca_id = carac.id;
						$scope.selectedColumn.uca_code = carac.code;
						$scope.selectedColumn.field = carac.lib_court;
						$scope.selectedColumn.parent_entite_type_id =
							carac.parent_entite_type_id;
						updateSourceColumns();
					};

					function editColumn(column) {
						$scope.columnModificationMode = ModificationMode.EditColumn;
						$scope.columnToEdit = column;
						$scope.currentColumn = {
							uuid: column.uuid,
							active: true,
							type: column.type,
							alias: column.column_alias,
							is_list: column.is_list,
						};
						$('#mappingColumnModal').modal('show');
					}

					var updateUnmappedColumns = function () {
						_.each($scope.tmpColumns, function (column) {
							if (!column.path) {
								//search by label
								let matchedSourceCol = _.filter(
									$scope.sourceColumns,
									function (item) {
										return (
											item.alias == column.alias || item.alias == column.field
										);
									}
								)[0];
								if (matchedSourceCol) {
									column.path = matchedSourceCol.path;
									column.uca_id = matchedSourceCol.carac_id;
									column.uca_code = matchedSourceCol.carac_code;
									column.parent_entite_type_id =
										matchedSourceCol.parent_entite_type_id;
								}
							}
						});
					};

					var updateSourceColumns = function () {
						//add linked caracs
						_.each($scope.tmpColumns, function (column) {
							if (
								column.parent_entite_type_id &&
								column.parent_entite_type_id != $scope.pivotParentId
							) {
								$scope.sourceColumns.push({
									path: column.path,
									parent_entite_type_id: column.parent_entite_type_id,
									alias: column.field,
									carac_id: column.uca_id,
									carac_code: column.uca_code,
									type: column.type,
									is_list: column.is_list,
								});
							}
						});
					};

					$scope.save = function () {
						vm.widgetData.saveColumnsMapping(
							$scope.fromDataBlock,
							$scope.tmpColumns
						);
						vm.closeModal();
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.widgetData.showColumnsMapping = false;
					};

					function updatePaths(column, selected) {
						let oldPivotPath = 'c' + $scope.pivotCaracId;
						$scope.pivotCaracId = selected.carac_id;
						$scope.pivotCaracCode = selected.carac_code;
						$scope.pivotParentId = selected.parent_entite_type_id;
						let pivotPath = 'c' + $scope.pivotCaracId;
						// update source paths and pivot
						let mapById = {};
						_.each($scope.sourceColumns, function (elm) {
							if (elm.carac_id == selected.carac_id) {
								elm.path = pivotPath;
								elm.pivot = true;
							} else {
								elm.path =
									'c' +
									$scope.pivotCaracId +
									'-' +
									$scope.pivotParentId +
									'-c' +
									elm.carac_id;
								elm.pivot = false;
							}
							mapById[elm.carac_id] = elm;
						});
						// sort make pivot first
						$scope.sourceColumns = _.sortBy(
							$scope.sourceColumns,
							function (elm) {
								return elm.pivot ? -1 : 1;
							}
						);

						_.each($scope.tmpColumns, function (column) {
							delete column.path;
							delete column.uca_id;
							delete column.uca_code;
							delete column.parent_entite_type_id;
						});

						$scope.tmpColumns[0].path = 'c' + $scope.pivotCaracId;
						$scope.tmpColumns[0].uca_id = $scope.pivotCaracId;
						$scope.tmpColumns[0].uca_code = $scope.pivotCaracCode;
						$scope.tmpColumns[0].parent_entite_type_id = $scope.pivotParentId;
						updateSourceColumns();
						updateUnmappedColumns();
						// change col path
						column.path = pivotPath;
						// filter elements
						if ($scope.sourceColumnFilterText && !$scope.columnFilterText) {
							$scope.filterSourceColumns();
						} else if (
							!$scope.sourceColumnFilterText &&
							$scope.columnFilterText
						) {
							$scope.filterColumns();
						} else {
							$scope.filterSourceColumns();
							$scope.filterColumns();
						}
					}
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=', entitiesDisabled: '=', saveAction: '&' },
				controller,
				'./src/components/directives/dataOperationTools/columnsMapping/columnsMapping.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData.showColumnsMapping;
						},
						function () {
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
