(function () {
	'use strict';

	angular.module('dcApp').directive('returnbutton', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					$scope.showConfirmationModal = function () {
						$('#confirmationModal').modal('show');
					};

					var vm = this;

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					dataModel: '=',
					saveAction: '&',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/returnButton/returnButton.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
				},
			};
		},
	]);
})();
