(function () {
	'use strict';

	angular.module('dcApp').directive('iconsSelector', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'PictogrammeService',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'API_BASE_URL_BACKEND',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					PictogrammeService,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					API_BASE_URL_BACKEND,
					gettextCatalog
				) {
					let dataGirdColumnName = {};
					dataGirdColumnName.label = gettextCatalog.getString('Libellé');
					dataGirdColumnName.picture = gettextCatalog.getString('Image');

					var vm = this;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.accessToken = window._keycloak.token;
					$scope.saveSelection = function () {
						for (
							var i = 0;
							i < $scope.pictoPopupDataGridOptions.dataSource.length;
							i++
						) {
							if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
								vm.data.saveSelection($scope.pictogrammes[i].id);
								vm.closeModal();
							}
						}
					};

					$scope.searchIcons = function () {
						$scope.showPictoGridPopUp = false;
						PictogrammeService.getPictogrammes(0).then(function (response) {
							const content = response.data;
							if (content !== undefined) {
								$scope.pictogrammes = [];
								for (var r in content) {
									$scope.pictogrammes.push({
										id: content[r].id,
										lib: content[r].lib,
										selected: content[r].selected,
									});
								}
								$scope.pictosPopupGridData = $scope.pictogrammes;
								$scope.pictoPopupDataGridOptions.dataSource =
									$scope.pictosPopupGridData;
								$scope.showPictoGridPopUp = true;
							}
						});
					};

					$scope.pictoPopupDataGridOptions = {
						dataSource: $scope.pictosPopupGridData,
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						selection: {
							mode: 'single',
							showCheckBoxesMode: 'always',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.label,
								dataField: 'lib',
								width: '70%',
								allowReordering: false,
							},
							{
								caption: dataGirdColumnName.picture,
								alignment: 'center',
								cellTemplate: 'imageAndLibPopUp',
								width: '30%',
							},
						],
						onInitialized: function (e) {
							$scope.gridPictoPopupInstance = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							$scope.selectedIconPopu = selectedItems.selectedRowsData;
							if ($scope.selectedIconPopu && $scope.selectedIconPopu[0]) {
								for (var i in $scope.pictoPopupDataGridOptions.dataSource) {
									if (
										$scope.pictoPopupDataGridOptions.dataSource[i].id ===
										$scope.selectedIconPopu[0].id
									) {
										$scope.pictoPopupDataGridOptions.dataSource[
											i
										].selected = true;
									} else {
										$scope.pictoPopupDataGridOptions.dataSource[
											i
										].selected = false;
									}
								}
							} else {
								for (var i in $scope.pictoPopupDataGridOptions.dataSource) {
									$scope.pictoPopupDataGridOptions.dataSource[
										i
									].selected = false;
								}
							}
						},
						onContentReady: function (e) {
							if (
								$scope.gridPictoPopupInstance &&
								$scope.gridPictoPopupInstance.selectRowsByIndexes
							) {
								var selectedItems = [];
								for (
									var i = 0;
									i < $scope.pictoPopupDataGridOptions.dataSource.length;
									i++
								) {
									if ($scope.pictoPopupDataGridOptions.dataSource[i].selected) {
										selectedItems.push(i);
									}
								}
								$scope.gridPictoPopupInstance.selectRowsByIndexes(
									selectedItems
								);
							}
						},
					};

					$scope.cancel = function () {
						vm.closeModal();
					};

					$scope.openPictoModal = function () {
						vm.data.showPictoModal = true;
						vm.data.updateAction = $scope.searchIcons;
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showIconsSelector = false;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/iconsSelector/iconsSelector.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.vm.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showIconsSelector;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.searchIcons();
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
