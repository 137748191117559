(function () {
	'use strict';

	angular
		.module('ProjectModule')
		.controller('ProjectListController', ProjectListController);

	ProjectListController.$inject = [
		'$log',
		'$scope',
		'$rootScope',
		'ProjectService',
		'$state',
		'PermissionService',
		'gettextCatalog',
		'toaster',
	];

	function ProjectListController(
		$log,
		$scope,
		$rootScope,
		ProjectService,
		$state,
		PermissionService,
		gettextCatalog,
		toaster
	) {
		$scope.deletePopupOptions = {
			width: 700,
			height: 270,
			contentTemplate: 'deletePopup',
			showTitle: true,
			position: { my: 'top', at: 'top', of: window, offset: '0 30' },
			dragEnabled: false,
			closeOnOutsideClick: false,
			bindingOptions: {
				visible: 'visibleDeletePopup',
			},
			title: 'Confirmation de la suppression',
		};

		$scope.hasMemberRole = $rootScope.hasMemberRole;
		$scope.hasProjectRole = $rootScope.hasProjectRole;

		$scope.sendMailData = {
			projectId: '',
			member: undefined,
		};

		var loadProjects = function () {
			$log.debug('querying projects');
			ProjectService.getProjects().then(function (response) {
				const data = response.data;
				_.each(data, function (item) {
					item.inactive =
						item.expired || !item.metadata.actif || item.premature;
				});
				$scope.projects = _.sortBy(data, function (item) {
					return !item.favorite_project;
				});
				$scope.projects = _.sortBy($scope.projects, function (item) {
					return item.inactive;
				});
				$scope.projects = _.sortBy($scope.projects, function (item) {
					return item.expired;
				});
				for (let p in $scope.projects) {
					$scope.projects[p].expiration_date = $scope.projects[p]
						.expiration_date
						? moment($scope.projects[p].expiration_date)
						: '';
					$scope.projects[p].effectiveness_date = $scope.projects[p]
						.effectiveness_date
						? moment($scope.projects[p].effectiveness_date)
						: '';
				}
				$scope.filteredProjects = $scope.projects;
				ProjectService.getProjectMembersCount().then(function (response) {
					const data = response.data;
					for (let p in $scope.projects) {
						$scope.projects[p].member_count = data[$scope.projects[p].id]
							? data[$scope.projects[p].id]
							: 0;
					}
				});
				ProjectService.getProjectContextsCount().then(function (response) {
					const data = response.data;
					for (let p in $scope.projects) {
						$scope.projects[p].context_count = data[$scope.projects[p].id]
							? data[$scope.projects[p].id]
							: 0;
					}
				});
				ProjectService.getProjectContextAvailabilities().then(function (
					response
				) {
					const data = response.data;
					for (let p in $scope.projects) {
						$scope.projects[p].unavailable_contexts =
							data[$scope.projects[p].id] == false;
					}
				});
				ProjectService.getProjectLastAccess().then(function (response) {
					const data = response.data;
					for (let p in $scope.projects) {
						$scope.projects[p].last_access_by = data[$scope.projects[p].id]
							? data[$scope.projects[p].id].v2
							: '';
						$scope.projects[p].last_access_date = data[$scope.projects[p].id]
							? ($rootScope.lastLoginDate = moment(
									data[$scope.projects[p].id].v1
							  ).format('LLLL'))
							: '';
					}
				});
			});
		};
		loadProjects();

		$scope.filterProjects = function () {
			$scope.filteredProjects = _.filter($scope.projects, function (item) {
				return (
					(item.metadata.label &&
						item.metadata.label
							.toLowerCase()
							.indexOf($scope.searchText.toLowerCase()) > -1) ||
					(item.metadata.description &&
						item.metadata.description
							.toLowerCase()
							.indexOf($scope.searchText.toLowerCase()) > -1) ||
					(item.metadata.tags &&
						_.filter(item.metadata.tags, function (item) {
							return (
								item.code
									.toLowerCase()
									.indexOf($scope.searchText.toLowerCase()) > -1
							);
						})[0])
				);
			});
		};

		$scope.newProject = function () {
			$state.go('projects-new', {});
		};

		$scope.importProject = function () {
			ProjectService.isAnyExecutionRunning().then(function (response) {
				if (!response.data) {
					$scope.import = true;
				} else {
					toaster.pop(
						'error',
						gettextCatalog.getString('project.bulk.execution.in.progress')
					);
				}
			});
		};

		$scope.deleteProject = function (project) {
			$scope.projectToDelete = project;
			$scope.confirmationText = 'SUPPRIMER ' + project.metadata.label;
			$scope.visibleDeletePopup = true;
		};

		$scope.confirmDelete = function () {
			if ($scope.confirmationText == $scope.userConfirmationText) {
				ProjectService.deleteProject($scope.projectToDelete.id).then(function (
					data
				) {
					$scope.visibleDeletePopup = false;
					toaster.pop(
						'success',
						gettextCatalog.getString('Succès'),
						gettextCatalog.getString('Suppression du Projet effectuée')
					);
					delete $scope.projectToDelete;
					loadProjects();
				});
			} else {
				toaster.pop(
					'error',
					gettextCatalog.getString(
						'Le message ne correspond pas au message attendu'
					)
				);
			}
		};

		$scope.cancelDelete = function () {
			$scope.visibleDeletePopup = false;
		};

		$scope.afterSetDefault = function (projectId) {
			for (let p in $scope.projects) {
				$scope.projects[p].default_project = $scope.projects[p].id == projectId;
			}
		};

		$scope.$on('refresh_projects_list', function (event, data) {
			loadProjects();
		});
	}
})();
