(function () {
	'use strict';

	angular.module('dcApp').directive('elementInfo', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'API_BASE_URL_BACKEND',
				'DashboardService',
				'ChartService',
				'GridService',
				'MediaService',
				'MapService',
				'TimeLineService',
				'DataBlocksService',
				'TypeEntiteService',
				'ConnectorService',
				'AllDepotService',
				'frameService',
				'EntiteDepotService',
				function (
					$scope,
					$rootScope,
					$state,
					API_BASE_URL_BACKEND,
					DashboardService,
					ChartService,
					GridService,
					MediaService,
					MapService,
					TimeLineService,
					DataBlocksService,
					TypeEntiteService,
					ConnectorService,
					AllDepotService,
					frameService,
					EntiteDepotService
				) {
					var vm = this;
					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

					$scope.openElement = function () {
						if (vm.infoMode == 'FULL_PAGE') {
							openElementPage();
						} else if (vm.infoMode == 'POPUP') {
							$scope.showElementMetadata = true;
						}
					};

					var openElementPage = function () {
						let stateName;
						let params;
						switch (vm.type) {
							case 'dashboard':
								stateName = 'dashboards-edit';
								params = { id: vm.id };
								break;
							case 'chart':
								stateName = 'charts-edit';
								params = { id: vm.id, type: vm.subType };
								break;
							case 'grid':
								stateName = 'grids-edit';
								params = { id: vm.id, type: vm.subType };
								break;
							case 'media':
								stateName = 'medias-edit';
								params = { id: vm.id, type: vm.subType };
								break;
							case 'timeline':
								stateName = 'timelines-edit';
								params = { id: vm.id };
								break;
							case 'connector':
								stateName = 'connectors-edit';
								params = { id: vm.id, type: vm.subType };
								break;
							case 'depot':
								stateName = 'depots-edit';
								params = { id: vm.id, type: vm.subType };
								break;
							case 'map':
								stateName = 'maps-edit';
								params = { id: vm.id };
								break;
							case 'datablock':
								stateName = 'datablocks-edit';
								params = { id: vm.id };
								break;
							case 'entity':
								stateName = 'entites-edit';
								params = { entiteId: vm.id };
								break;
							case 'exportTemplate':
								stateName = 'export-template-edit';
								params = { exportConfigId: vm.id, type: vm.subType };
								break;
							default:
								break;
						}
						$state.go(stateName, params);
					};
				},
			];
			return {
				restrict: 'E',

				scope: {
					infoMode: '=', //POPUP or FULL_PAGE
					type: '=',
					subType: '=',
					label: '=',
					id: '=',
					iconId: '=',
					dataframeConfig: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/elementInfo/elementInfo.html',
				transclude: true,
				replace: true,
				link: function (scope, elem, attrs) {},
			};
		},
	]);
})();
