(function () {
	'use strict';

	angular.module('dcApp').directive('editPathalias', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'GenericService',
				'$rootScope',
				function ($log, $scope, GenericService, $rootScope) {
					var vm = this;

					$scope.save = function () {
						vm.widgetData.afterUpdateAction();
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showEditPathAlias = false;
					}

					$scope.editPathAlias = function (path) {
						$scope.editAliasMode = 'new';
						$scope.pathToEditAlias = $scope.getPathFromPathes(path);
						$scope.pathToEditAlias.status = 1;
						$scope.pathToEditAlias.lib = '';
						for (var c in $scope.pathToEditAlias.caracs) {
							$scope.pathToEditAlias.caracs[c].selected = true;
						}
						$scope.updatePathAliasNodes();
						$scope.pathToEditAlias.aliases = [];
						for (var n in $scope.pathToEditAlias.nodes) {
							if ($scope.pathToEditAlias.nodes[n].is_alias == true) {
								$scope.pathToEditAlias.aliases.push(
									$scope.pathToEditAlias.nodes[n].alias
								);
							}
						}
					};

					$scope.getPathFromPathes = function (path) {
						for (var p in $scope.pathes) {
							if ($scope.pathes[p].object == path) {
								return $scope.pathes[p];
							}
						}
					};

					$scope.updatePathAliasNodes = function () {
						$scope.isPathAliasAlreadyExist = false;
						$scope.isPathAliasLibAlreadyExist = false;
						var path = '';
						var nbr = 0;
						$scope.notValidPathAlias = false;
						var startNode = undefined;
						var endNode = undefined;
						$log.debug($scope.pathToEditAlias);
						for (var c in $scope.pathToEditAlias.caracs) {
							if ($scope.pathToEditAlias.caracs[c].selected) {
								if (!startNode) {
									startNode = $scope.pathToEditAlias.caracs[c];
								}
								endNode = $scope.pathToEditAlias.caracs[c];
								nbr++;
								var id = '';
								if ($scope.pathToEditAlias.caracs[c].type == 'c') {
									id = 'c' + $scope.pathToEditAlias.caracs[c].id;
								} else {
									id = $scope.pathToEditAlias.caracs[c].id;
								}
								if (isEmpty(path)) {
									path = id;
								} else {
									path = path + '-' + id;
								}
							}
						}
						if (
							(startNode && startNode.type == 'c') ||
							(endNode && endNode.type == 'c')
						) {
							$scope.notValidPathAlias = true;
							return;
						}
						if ($scope.pathToEditAlias.object.indexOf(path) < 0) {
							$scope.notValidPathAlias = true;
							return;
						} else if (nbr < 2 && $scope.pathToEditAlias.status !== '2') {
							$scope.notValidPathAlias = true;
							return;
						}
					};

					$scope.savePathAlias = function (path) {
						$rootScope.fields = [];
						if (isEmpty($scope.pathToEditAlias.lib)) {
							$rootScope.fields.push('lib');
							return;
						}
						var pathAlias = {};
						pathAlias.lib = $scope.pathToEditAlias.lib;
						pathAlias.status = $scope.pathToEditAlias.status;
						pathAlias.path = '';
						for (var c in $scope.pathToEditAlias.caracs) {
							if ($scope.pathToEditAlias.caracs[c].selected) {
								var id = '';

								if ($scope.pathToEditAlias.caracs[c].type == 'c') {
									id = 'c' + $scope.pathToEditAlias.caracs[c].id;
								} else {
									id = $scope.pathToEditAlias.caracs[c].id;
								}
								if (isEmpty(pathAlias.path)) {
									pathAlias.path = id;
								} else {
									pathAlias.path = pathAlias.path + '-' + id;
								}
							}
						}

						GenericService.createPathAlias(pathAlias).then(function (response) {
							if (response.data == 0) {
								$scope.isPathAliasLibAlreadyExist = true;
							} else {
								$scope.save();
							}
						});
					};

					$scope.updatePathAliasEditMode = function () {
						if ($scope.editAliasMode == 'new') {
							for (var c in $scope.pathToEditAlias.caracs) {
								$scope.pathToEditAlias.caracs[c].selected = true;
							}
							$scope.updatePathAliasNodes();
						}
					};

					$scope.deletePathAlias = function () {
						var aliasesToDelete = [];
						var aliases = [];
						for (var a in $scope.pathToEditAlias.aliases) {
							if ($scope.pathToEditAlias.aliases[a].selected) {
								aliasesToDelete.push($scope.pathToEditAlias.aliases[a].id);
							} else {
								aliases.push($scope.pathToEditAlias.aliases[a].id);
							}
						}

						$scope.pathToEditAlias.aliases = aliases;
						GenericService.deletePathAlias(aliasesToDelete).then(function (
							response
						) {
							$scope.save();
						});
					};

					$scope.updateSelectedNodes = function (path) {
						for (var c in $scope.pathToEditAlias.caracs) {
							$scope.pathToEditAlias.caracs[c].selected = false;
						}

						for (var n in path.nodes) {
							for (var c in $scope.pathToEditAlias.caracs) {
								if ($scope.pathToEditAlias.caracs[c].id == path.nodes[n].id) {
									$scope.pathToEditAlias.caracs[c].selected = true;
									break;
								}
							}
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/editPathAlias/editPathAlias.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showEditPathAlias = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.showEditPathAlias;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.pathes = scope.vm.widgetData.editPathAliasAllPathes;
								scope.editPathAlias(scope.vm.widgetData.pathToEditAlias);
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
