(function () {
	'use strict';

	angular.module('dcApp').controller('ExportHistoryController', [
		'$scope',
		'$rootScope',
		'ExportHistoryService',
		function ($scope, $rootScope, ExportHistoryService) {
			$scope.history = [];
			$scope.showElementsHistory = true;
			$scope.elementsDetails = {};
			$scope.projectsDetails = {};

			if ($rootScope.hasProjectRole) {
				ExportHistoryService.getProjectExportHistoryCount(
					$rootScope.currentProjectId
				).then(function (response) {
					$scope.projectsDetails.count = response.data;
				});
			}

			$scope.showElements = function () {
				$scope.showElementsHistory = true;
				$scope.showProjectsHistory = false;
			};

			$scope.showProjects = function () {
				$scope.showProjectsHistory = true;
				$scope.showElementsHistory = false;
			};

			$scope.widgetData = {};
			$scope.widgetData.showDownloadFiles = false;
		},
	]);
})();
