(function () {
	'use strict';

	angular.module('dcApp').directive('dbStepImpact', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					$scope.init = function () {
						$scope.showConfirm = true;
						$scope.allImpacts = $scope.vm.widgetData.dbImpacts; //all deleted columns
						$scope.impacts = _.filter(
							$scope.vm.widgetData.dbImpacts,
							function (item) {
								return item.impacted;
							}
						);
						for (let im in $scope.impacts) {
							if (
								_.filter($scope.impacts[im].impacts, function (item) {
									return (
										item.step_index == vm.widgetData.dbStepImpactCurrentStep
									);
								})[0]
							) {
								$scope.showConfirm = false;
								break;
							}
						}
					};

					$scope.confirm = function () {
						let nextStepIndex =
							vm.widgetData.dbStepImpactCurrentStep != undefined
								? vm.widgetData.dbStepImpactCurrentStep + 1
								: 0;
						let nextStep = vm.widgetData.dbStepImpactAllSteps[nextStepIndex];

						//safe delete not used columns
						let safeDeletedColumns = _.map(
							_.filter($scope.allImpacts, function (im) {
								return !im.impacted;
							}),
							function (item) {
								return item.column_uuid;
							}
						);
						safeDeletedColumnStepImpact(
							safeDeletedColumns,
							vm.widgetData.dbStepImpactAllSteps,
							vm.widgetData.dbStepImpactCurrentStep
						);

						if (nextStep) {
							//set mapping in the next step
							for (let im in $scope.impacts) {
								clearInputMappingAndOutput($scope.impacts[im], nextStep);
							}
						}
						//execute action
						vm.widgetData.dbStepImpactValidateAction();
						if (!nextStep) {
							//add new step
							let inputCols = [];
							for (let im in $scope.impacts) {
								let columnImpact = $scope.impacts[im];
								inputCols.push({
									uuid: columnImpact.column_uuid,
									label: columnImpact.column_lib,
									type: columnImpact.column_type,
									is_list: columnImpact.column_is_list,
								});
							}
							vm.widgetData.dbStepImpactAddNewStep(inputCols);
						}
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};
					var clearInputMappingAndOutput = function (columnImpact, nextStep) {
						nextStep.output = _.filter(nextStep.output, function (item) {
							return columnImpact.column_uuid != item.column_uuid;
						});
						nextStep.input = nextStep.input ? nextStep.input : { columns: [] };
						let inputCols = _.filter(nextStep.input.columns, function (item) {
							return item.column_uuid == columnImpact.column_uuid;
						});
						if (inputCols[0]) {
							for (let c in inputCols) {
								//clear mapping : delete source column
								delete inputCols[c].column_uuid;
							}
						} else {
							//add input mapping
							nextStep.input.columns.push({
								uuid: columnImpact.column_uuid,
								label: columnImpact.column_lib,
								type: columnImpact.column_type,
								is_list: columnImpact.column_is_list,
							});
						}
					};

					function closeModal() {
						$scope.vm.widgetData.showDbStepImpact = false;
						$(vm.element).modal('hide');
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/dbStepImpact/dbStepImpact.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.widgetData.showDbStepImpact = false;
						});
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData
								? scope.vm.widgetData.showDbStepImpact
								: false;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
