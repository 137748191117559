(function () {
	'use strict';

	angular.module('dcApp').directive('mediatype', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;
					$scope.media_type = getFormattedMediaType(vm.type);
				},
			];

			return buildDirectiveReturnObject(
				{ type: '=', subType: '=' },
				controller,
				'./src/components/directives/handledata/mediaType/mediaType.html',
				function postLink(scope, element, attrs) {}
			);
		},
	]);
})();
