(function () {
	'use strict';

	angular.module('dcApp').factory('ProjectDuplicateAdapter', [
		'$rootScope',
		'ELEMENT',
		function ($rootScope, ELEMENT) {
			const ProjectDuplicateAdapter = {};
			ProjectDuplicateAdapter.getStats = getStats;
			ProjectDuplicateAdapter.getExposedDataBlocks = getExposedDataBlocks;

			function getStats(gdStatsRes, hdStatsRes, projectDataRes) {
				return {
					projectLabel: projectDataRes.metadata.label,
					projectDescription: projectDataRes.metadata.description,
					projectVersion: projectDataRes.metadata,
					currentUser: $rootScope.account.preferredUsername,
					stats: {
						connector: {
							count: gdStatsRes.connector || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.connector
							),
						},
						repository: {
							count: gdStatsRes.repository || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.repository
							),
						},
						exportTemplate: {
							count: gdStatsRes.export_template || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.export_template
							),
						},
						entity: {
							count: gdStatsRes.entity || 0,
							hasAccess: $rootScope.havePermission(ELEMENT.permissions.entity),
						},
						datablock: {
							count: gdStatsRes.datablock || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.datablock
							),
						},
						hdElements: {
							count: countHdElements(hdStatsRes) || 0,
							hasAccess:
								$rootScope.havePermission(ELEMENT.permissions.map) &&
								$rootScope.havePermission(ELEMENT.permissions.timeline) &&
								$rootScope.havePermission(ELEMENT.permissions.media) &&
								$rootScope.havePermission(ELEMENT.permissions.grid) &&
								$rootScope.havePermission(ELEMENT.permissions.chart),
						},
						dashboard: {
							count: hdStatsRes.dashboard || 0,
							hasAccess: $rootScope.havePermission(
								ELEMENT.permissions.dashboard
							),
						},
					},
				};
			}

			function getExposedDataBlocks(data) {
				let elements = [];
				let element = {};
				for (const elem of data) {
					element = {
						datablock_id: elem.datablock_id,
						label: elem.label,
						access_point: elem.access_point,
					};
					elements.push(element);
				}
				return elements;
			}

			function countHdElements(list) {
				let hdElementsSum = 0;
				for (const obj in list) {
					if (obj !== 'dashboard') {
						hdElementsSum = list[obj] + hdElementsSum;
					}
				}
				return hdElementsSum;
			}

			return ProjectDuplicateAdapter;
		},
	]);
})();
