(function () {
	'use strict';

	angular.module('dcApp').directive('dcCalendar', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'uibDateParser',
				'DateService',
				function ($scope, $rootScope, uibDateParser, DateService) {
				const DATE_TIME_PATTERN = "YYYY-MM-DD HH:mm:ss"
					var vm = this;

					$scope.setTime = function (newDate) {
						let toDate = vm.dateOnly ?  moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(),0,0,0)) : newDate;
						vm.dateValue = vm.useDefaultTz ? DateService.dcCalendarDateToString(newDate, vm.pattern)
						  : DateService.dateToStringWithPatternLocal(newDate, vm.pattern);
						$scope.updateIsoDate();
					};

					$scope.updateIsoDate = function () {
						let newDate = angular.copy(vm.dateValue);
						const usedPattern = vm.pattern ? vm.pattern : DATE_TIME_PATTERN;
						let parsedDate = DateService.getJSDateForDcCalendarWithPattern(newDate, usedPattern, vm.useDefaultTz);
						let date = vm.dateOnly && parsedDate.getTime() === parsedDate.getTime() ? moment(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(),0,0,0)) : parsedDate;
						vm.isoDateValue = DateService.dateToISOString(date);
						vm.dateValuePicker = newDate;
					};

					vm.formatDateForPicker = function () {
						if (!vm.dateValue) {
							return;
						}
						const rawDate = angular.copy(vm.dateValue);
						let pattern = vm.pattern ? vm.pattern : DATE_TIME_PATTERN;
						let dt;
						if (typeof rawDate === "number" || (typeof rawDate === "string" && rawDate.includes("T"))) {
              dt = moment(rawDate);
            } else {
							dt = moment(rawDate, pattern)
            }
						if (!dt._isValid) {
							dt = uibDateParser.parse(rawDate, pattern);
						}
						// handle iso date
						//TODO remove when migrating all the apps to iso dates
						let dtM = moment(dt);
						if (dtM._isValid) {
						   vm.dateValue = vm.useDefaultTz ? DateService.dateToStringWithPatternAndTZ(dtM, pattern, $rootScope.getDefaultTimezone())
                            : DateService.dateToStringWithPatternLocal(dtM, pattern);
						} else {
						  vm.dateValue = rawDate;
						}

			      $scope.updateIsoDate();
					};

					$scope.getUuid = function () {
						return $scope.uuid;
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					dateValue: '=',
					isoDateValue: '=?',
					pattern: '=',
					relativePosition: '=',
					dateOnly: '=',
					useDefaultTz: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/dcCalendar/dcCalendar.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.vm.formatDateForPicker();
					scope.uuid = generateUuid('_');
				},
			};
		},
	]);
})();
