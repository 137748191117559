'use strict';

angular
	.module('dcApp')
	.directive('loader', function () {
		return function ($scope, element, attrs) {
			$scope.$on('loader_show', function () {
				return element.show();
			});
			return $scope.$on('loader_hide', function () {
				return element.hide();
			});
		};
	})
	.directive('capitalize', function () {
		return {
			require: 'ngModel',
			link: function (scope, element, attrs, modelCtrl) {
				var capitalize = function (inputValue) {
					if (inputValue == undefined) inputValue = '';
					var capitalized = inputValue.toUpperCase();
					if (capitalized !== inputValue) {
						modelCtrl.$setViewValue(capitalized);
						modelCtrl.$render();
					}
					return capitalized;
				};
				modelCtrl.$parsers.push(capitalize);
				capitalize(scope[attrs.ngModel]);
			},
		};
	})
	.directive('emptyToNull', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, elem, attrs, ctrl) {
				ctrl.$parsers.push(function (viewValue) {
					if (viewValue === '') {
						return null;
					}
					return viewValue;
				});
			},
		};
	})
	.directive('btncollapse', function () {
		var link = function (scope) {
			var bIndex = 0;
			scope.toggle = function () {
				if (bIndex === 0) {
					angular.element('#main-nav').animate(
						{
							top: '-75px',
						},
						500
					);
					angular.element(' .main-content').animate(
						{
							top: '-65px',
						},
						500
					);
					angular.element('.header-group').animate(
						{
							height: '70px',
						},
						500
					);
					angular
						.element('.collapse-menu')
						.find('span')
						.removeClass('glyphicon-chevron-up');
					angular
						.element('.collapse-menu')
						.find('span')
						.addClass('glyphicon-chevron-down');
					angular.element('.main-nav li a').find('span').css('display', 'none');
					bIndex = 1;
				} else {
					angular.element('#main-nav').animate(
						{
							top: '0px',
						},
						500
					);
					angular.element('.main-content').animate(
						{
							top: '0px',
						},
						500
					);
					angular.element('.header-group').animate(
						{
							height: '140px',
						},
						500
					);
					angular
						.element('.collapse-menu')
						.find('span')
						.addClass('glyphicon-chevron-up');
					angular
						.element('.collapse-menu')
						.find('span')
						.removeClass('glyphicon-chevron-down');
					angular
						.element('.main-nav li a')
						.find('span')
						.css('display', 'inline-block');

					bIndex = 0;
				}
			};
		};

		return {
			link: link,
			template:
				'<button type="button" ng-click="toggle()" class="btn pull-right collapse-menu"><span class="glyphicon glyphicon-chevron-up"></span></button>',
		};
	})
	.directive('ngEnter', function () {
		return function (scope, element, attrs) {
			element.bind('keydown keypress', function (event) {
				if (event.which === 13) {
					scope.$apply(function () {
						scope.$eval(attrs.ngEnter, { event: event });
					});

					event.preventDefault();
				}
			});
		};
	})
	.directive('toggle', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if (attrs.toggle == 'tooltip') {
					$(element).tooltip();
				}
				if (attrs.toggle == 'popover') {
					$(element).popover();
				}
			},
		};
	})
	.directive('emptyToNull', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, elem, attrs, ctrl) {
				ctrl.$parsers.push(function (viewValue) {
					if (viewValue === '') {
						return null;
					}
					return viewValue;
				});
			},
		};
	})
	.directive('paginator', function () {
		var controller = function () {
			var vm = this;

			function init() {
				// vm.visible = true;
				vm.page = 0;
			}

			init();

			vm.next = function () {
				var current = ++vm.page;
				vm.callback({ page: current });
			};

			vm.previous = function () {
				if (vm.page > 0) {
					var current = --vm.page;
					vm.callback({ page: current });
				}
			};
		};

		var template =
			'<div class="pull-right" ng-hide="!vm.visible"><div class="btn-group bottom-space-15" role="group">' +
			'<button type="button" ng-disabled="vm.page==0" class="btn btn-primary btn-xs right-space-3" style="margin-top: -3px !important;" ng-click="vm.previous(vm.page)">' +
			'<i class="fa fa-arrow-left right-space-5 left-space-5"></i></button>' +
			'<button type="button" class="btn btn-primary btn-xs right-space-3" style="margin-top: -3px !important; opacity:1 !important;" disabled="true">' +
			'Page {{vm.page+1}}</button>' +
			'<button type="button" class="btn btn-primary btn-xs right-space-3" style="margin-top: -3px !important;" ng-click="vm.next(vm.page)">' +
			'<i class="fa fa-arrow-right right-space-5 left-space-5"></i></button>' +
			'</div></div>';

		return {
			restrict: 'E',
			scope: {
				callback: '&',
				visible: '=',
			},
			controller: controller,
			controllerAs: 'vm',
			//required in 1.3+ with controllerAs
			bindToController: true,
			template: template,
		};
	})
	.directive('fileModel', [
		'$parse',
		function ($parse) {
			return {
				restrict: 'A',
				link: function (scope, element, attrs) {
					var model = $parse(attrs.fileModel);
					var modelSetter = model.assign;

					element.bind('change', function () {
						scope.$apply(function () {
							modelSetter(scope, element[0].files[0]);
						});
					});
				},
			};
		},
	])
	.directive('elemReady', [
		'$parse',
		function ($parse) {
			return {
				restrict: 'A',
				link: function ($scope, elem, attrs) {
					elem.ready(function () {
						var func = $parse(attrs.elemReady);
						func($scope);
					});
				},
			};
		},
	])
	.filter('formatNumber', [
		function () {
			return formatNumber;
		},
	])
	.directive('scHover', [
		'$log',
		'$timeout',
		function scHoverDirective($log, $timeout) {
			return {
				link: function (scope, element, attrs, modelCtrl) {
					$log.debug('scHover.postLink called');
					var inTimeout = false;
					var hoverDelay = parseInt(attrs.scHoverDelay, 10) | 1000;

					element.on('mouseover', function () {
						inTimeout = true;
						$timeout(function () {
							if (inTimeout) {
								scope.$eval(attrs.scHover);
								inTimeout = false;
							}
						}, hoverDelay);
					});

					element.on('mouseleave', function () {
						inTimeout = false;
						scope.$apply(function () {
							scope.$eval(attrs.scHoverEnd);
						});
					});
				},
			};
		},
	])
	.directive('uiSelectDisableActive', function () {
		return {
			require: '^?uiSelect',
			link: function (scope, element, attrs, $select) {
				if ($select && $select.isActive) {
					$select.isActive = function (elem) {
						return false;
					};
				}
			},
		};
	})
	.directive('elementSize', [
		'$timeout',
		function ($timeout) {
			return {
				restrict: 'A',
				link: function (scope, element, attrs) {
					var height, width;
					function calculateResize() {
						$timeout(function () {
							height = element[0].offsetHeight;
							width = element[0].offsetWidth;
							if (attrs.key) {
								scope[attrs.key] = {
									height: height,
									width: width,
								};
								return;
							}
							scope.elementSize = {
								height: height,
								width: width,
							};
						}, 200);
					}
					element.ready(function () {
						calculateResize();
					});
					// TODO remove when dashboard html editor layout is correctly integrated (remove the directive)
					scope.$on('gridster-item-resized', function (item) {
						calculateResize();
					});
				},
			};
		},
	]);
