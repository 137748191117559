(function () {
	'use strict';

	angular.module('dcApp').factory('TagService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			var TagService = {};

			TagService.getTags = function (text) {
				return $http
					.get(API_BASE_URL_BACKEND + 'tags?text=' + text)
					.then(function (response) {
						return response;
					});
			};

			TagService.saveTag = function (tag) {
				return $http
					.post(API_BASE_URL_BACKEND + 'tags', tag)
					.then(function (response) {
						return response;
					});
			};

			TagService.deleteTag = function (tagId) {
				return $http
					.delete(API_BASE_URL_BACKEND + 'tags?tagId=' + tagId)
					.then(function (response) {
						return response;
					});
			};

			TagService.getTagsByCaracId = function (caracId) {
				return $http
					.get(API_BASE_URL_BACKEND + 'tags_carac?caracId=' + caracId)
					.then(function (response) {
						return response;
					});
			};

			TagService.getTagsByEntiteTypeId = function (entiteTypeId) {
				return $http
					.get(
						API_BASE_URL_BACKEND +
							'tags_entite_type?entiteTypeId=' +
							entiteTypeId
					)
					.then(function (response) {
						return response;
					});
			};

			TagService.getTagsByNatureId = function (natureId) {
				return $http
					.get(API_BASE_URL_BACKEND + 'tags_nature?natureId=' + natureId)
					.then(function (response) {
						return response;
					});
			};

			return TagService;
		},
	]);
})();
