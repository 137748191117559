(function () {
	'use strict';

	angular.module('dcApp').directive('cdcfelement', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				function ($scope) {
					var vm = this;

					$scope.save = function () {
						vm.data.doDelete();
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.data.showConfirmDeleteCaracFromElement = false;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dialog/confirmDeleteCaracFromElement/confirmDeleteCaracFromElement.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.data.showConfirmDeleteCaracFromElement = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data
								? scope.vm.data.showConfirmDeleteCaracFromElement
								: false;
						},
						function () {}
					);
				}
			);
		},
	]);
})();
