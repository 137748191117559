(function () {
	'use strict';

	angular.module('dcApp').controller('PathGraphController', [
		'$scope',
		'$state',
		'GenericService',
		'TypeEntiteService',
		'TagService',
		'CaracService',
		'API_BASE_URL_BACKEND',
		'$http',
		'$rootScope',
		'gettextCatalog',
		function (
			$scope,
			$state,
			GenericService,
			TypeEntiteService,
			TagService,
			CaracService,
			API_BASE_URL_BACKEND,
			$http,
			$rootScope,
			gettextCatalog
		) {
			let aliasFilterType = {};
			aliasFilterType.onlyImposedAndProposed = gettextCatalog.getString(
				'Uniquement les alias proposés et imposés'
			);
			aliasFilterType.onlyMasqued = gettextCatalog.getString(
				'Uniquement les alias masqués'
			);
			aliasFilterType.all = gettextCatalog.getString('Tous les alias');

			$scope.loadTags = function ($query) {
				return $http.get(API_BASE_URL_BACKEND + '/tags?text=' + $query);
			};

			$scope.caracIsSelected = function (caracId) {
				if ($scope.selectedCarac && caracId == $scope.selectedCarac.id) {
					return true;
				}
			};

			$scope.selectCarac = function (carac) {
				$scope.selectedCarac = carac;
				if ($scope.selectedCarac.complex) {
					TypeEntiteService.getCaracs($scope.selectedCarac.id_type).then(
						function (response) {
							var map = [];
							for (var carac in response.data) {
								map.push({ v1: response.data[carac].id, v2: 'c' });
							}
							GenericService.getElementaryData(map).then(function (response2) {
								$scope.simpleCaracs = [];
								for (var carac in response2.data) {
									if (!response2.data[carac].complex) {
										$scope.simpleCaracs.push(response2.data[carac]);
									}
								}
							});
						}
					);
				}
			};

			$scope.setSimpleAndComplexCaracs = function () {
				$scope.simpleCaracs = _.filter($scope.caracs, function (item) {
					return item.complex === false;
				});
				$scope.complexCaracs = _.filter($scope.caracs, function (item) {
					return item.complex === true;
				});
			};

			$scope.showPanel = false;
			$scope.activatePanel = function () {
				$scope.showPanel = $scope.showPanel !== true;
				if ($scope.showPanel && $scope.selectNodeP) {
					$scope.selectNode($scope.selectNodeP);
				}
			};

			$scope.selectNode = function (p) {
				if (p.nodes[0] !== undefined && !$scope.toCreateLink) {
					delete $scope.selectedEdge;
					delete $scope.selectedNode;

					$scope.selectedNode = p.nodes[0];
					var node = p.nodes[0];
					var map = [];
					map.push({ v1: node, v2: 't' });
					GenericService.getElementaryData(map).then(function (response) {
						delete $scope.simpleCaracs;
						$scope.selectedNode = response.data[0];
						TypeEntiteService.getNbrEntities($scope.selectedNode.id).then(
							function (response) {
								$scope.entitiesCount = response.data;

								TypeEntiteService.getNbrValuesPerCarac(
									$scope.selectedNode.id
								).then(function (response) {
									$scope.caracs_ids = response.data;
									var map = [];
									var caracs = [];
									for (var carac in $scope.caracs_ids) {
										map.push({ v1: carac, v2: 'c' });
									}
									GenericService.getElementaryData(map).then(function (
										response
									) {
										$scope.caracs = response.data;
										for (var carac in $scope.caracs) {
											var c = {};
											angular.copy($scope.caracs[carac], c);
											c.nbr = $scope.caracs_ids[$scope.caracs[carac].id];

											if (c.nbr == undefined) {
												c.nbr = 0;
											}
											caracs.push(c);
										}
										$scope.caracs = caracs;
										$scope.setSimpleAndComplexCaracs();
									});
								});
								$scope.showPanel = true;
								$state.go('pathGraph');
							}
						);
					});
				} else if (p.nodes[0] !== undefined && $scope.toCreateLink) {
					if ($scope.selectedNode.id == p.nodes[0]) {
						return;
					} else {
						var item = _.find($scope.nodes.nodes, {
							id: p.nodes[0],
						});

						if (item) {
							$scope.selectedDistinationNodeId = p.nodes[0];
							$scope.selectedDistinationNodeLabel = item.label;
						}
						$scope.carac = {};
						$scope.carac.tags = [];
						$scope.carac.poids = 100;
						$scope.$apply();
						$('#createLinkModal').modal('show');
					}
				}
			};
			var network;
			function redrawAllAlias(nod, edg) {
				// remove positoins
				for (var i = 0; i < nod.length; i++) {
					delete nod[i].x;
					delete nod[i].y;
				}
				// create a network
				var container = document.getElementById('entites');

				for (var ed in edg) {
					edg[ed]['shape'] = 'dot';
				}

				for (var nd in nod) {
					if (nod[nd]['group'] == 0) {
						nod[nd]['shape'] = 'square';
					}
				}

				var data = {
					nodes: nod,
					edges: edg,
				};

				var options = {
					groups: {
						0: {
							color: {
								background: '#006666',
								border: '#006666',
							},
						},
						1: {
							color: {
								background: '#99CC66',
								border: '#99CC66',
							},
						},
					},
					nodes: {
						size: 200,
						scaling: {
							min: 10,
							max: 30,
						},
						font: {
							size: 18,
							face: 'Arial',
						},
					},
					edges: {
						width: 0.25,
						color: '#006666',
						smooth: {
							type: 'continuous',
						},
					},
					physics: {
						enabled: true,
						stabilization: true,
						barnesHut: {
							avoidOverlap: 1,
							gravitationalConstant: -5000,
							springConstant: 0.001,
							springLength: 90,
						},
					},

					interaction: {
						navigationButtons: false,
						tooltipDelay: 400,
						hideEdgesOnDrag: true,
						keyboard: {
							enabled: false,
							speed: {
								zoom: 0.002,
							},
							bindToWindow: true,
						},
					},
				};

				network = new vis.Network(container, data, options);

				network.on('select', function (p) {
					var item = _.find($scope.nodes.nodes, {
						id: p.nodes[0],
					});

					if (!item || item.group != 0) {
						$scope.selectNodeP = p;
						if (!$scope.showPanel) {
							return;
						}
						$scope.selectNode(p);
						return;
					}
					$scope.selectedAliasId = item.real_id;
					$scope.selectedPathAliasLib = item.label;

					GenericService.getPathAlias($scope.selectedAliasId).then(function (
						response
					) {
						$scope.selectedAlias = response.data;
						$scope.selectedPathAliasLibStatus = $scope.selectedAlias.status;
						$('#pathAliasModal').modal('show');
					});
				});
			}

			$scope.deletePathAlias = function () {
				GenericService.deletePathAlias([$scope.selectedAliasId]).then(function (
					response
				) {
					$scope.searchAliases();
					$('#pathAliasModal').modal('hide');
				});
			};

			$scope.editPathAlias = function () {
				GenericService.editPathAlias(
					$scope.selectedAliasId,
					$scope.selectedPathAliasLib
				).then(function (response) {
					$scope.searchAliases();
					$('#pathAliasModal').modal('hide');
				});
			};

			$scope.getGraphe = function (imposed, proposed, masqued) {
				TypeEntiteService.getGrapheAlias(imposed, proposed, masqued).then(
					function (response) {
						$scope.nodes = response.data;
						redrawAllAlias($scope.nodes.nodes, $scope.nodes.edges);
					}
				);
			};

			$scope.aliasFilters = [
				{
					code: 'onlyImposedAndProposed',
					label: aliasFilterType.onlyImposedAndProposed,
				},
				{ code: 'onlyMasqued', label: aliasFilterType.onlyMasqued },
				{ code: 'all', label: aliasFilterType.all },
			];
			$scope.aliasFilter = 'onlyImposedAndProposed';

			$scope.searchAliases = function () {
				delete $scope.selectedEdge;
				delete $scope.selectedNode;
				delete $scope.showPanel;
				if ($scope.aliasFilter == 'onlyMasqued') {
					$scope.getGraphe(false, false, true);
				} else if ($scope.aliasFilter == 'all') {
					$scope.getGraphe(true, true, true);
				} else if ($scope.aliasFilter == 'onlyImposedAndProposed') {
					$scope.getGraphe(true, true, false);
				}
			};
			$scope.searchAliases();

			$scope.createLink = function () {
				$('#confirmLinkModal').modal('show');
			};

			$scope.parentPathesList = true;
			$scope.confirmLink = function () {
				$scope.toCreateLink = true;
				$scope.newLinkType = 'tech';
				delete $scope.selectedPath;
				delete $scope.newAliasLabel;
				delete $scope.isPathAliasAlreadyExist;
				delete $scope.isPathAliasLibAlreadyExist;
				$scope.searchPathText = '';
				$scope.pathesTable = [];

				$scope.newAliasStatus = 0;
				$('#confirmLinkModal').modal('hide');
			};

			$scope.cancelLinkConfirm = function () {
				$scope.toCreateLink = false;
				$('#confirmLinkModal').modal('hide');
			};

			$scope.cancelLinkCreate = function () {
				$scope.toCreateLink = false;
				$('#cancelLinkCreate').modal('hide');
			};

			$scope.updateNewLinkType = function () {
				$scope.hidePathActionCol = true;
				$scope.searchPathesByLoop();
			};

			$scope.saveNewLink = function () {
				if ($scope.newLinkType == 'tech') {
					saveNewLinkCarac();
				} else {
					saveNewLinkAlias();
				}
				$scope.toCreateLink = false;
			};

			var saveNewLinkCarac = function () {
				if ($scope.carac.tags != undefined) {
					for (var i = 0; i < $scope.carac.tags.length; i++) {
						$scope.carac.tags[i].color = '#DDF663';
					}
				}

				$scope.carac.entite_type = {};
				$scope.carac.entite_type.id = $scope.selectedDistinationNodeId;

				// http put service call to save changes on carac.
				CaracService.createCarac($scope.selectedNode.id, $scope.carac)
					.then(function (response) {
						$('#createLinkModal').modal('hide');
						return $http.get(API_BASE_URL_BACKEND + response.headers.location);
					})
					.then(function (response) {});
			};

			var saveNewLinkAlias = function () {
				GenericService.ispathAliasExists($scope.selectedPath).then(function (
					response
				) {
					$scope.isPathAliasAlreadyExist = response.data;
					return;
				});
				var pathAlias = {};
				pathAlias.lib = $scope.newAliasLabel;
				pathAlias.status = $scope.newAliasStatus;
				pathAlias.path = $scope.selectedPath;

				GenericService.createPathAlias(pathAlias).then(function (response) {
					if (response.data == 0) {
						$scope.isPathAliasLibAlreadyExist = true;
						return;
					}
					$('#createLinkModal').modal('hide');
					$scope.searchAliases();
				});
			};

			$scope.searchPathesByLoop = function () {
				$scope.isLoading = true;
				if ($scope.loopPath === undefined) {
					$scope.loopPath = 1;
				}

				if ($scope.pathesCurrentPage === undefined) {
					$scope.pathesCurrentPage = 1;
				}

				GenericService.getAllPathFormattedAliases(
					[],
					$scope.selectedNode.id,
					$scope.selectedDistinationNodeId,
					$scope.loopPath,
					't',
					't',
					$scope.pathesCurrentPage,
					100
				).then(function (response) {
					$scope.totalPatheCount = response.data.total_count;
					$scope.isLoading = false;
					var data = response.data.pathes;
					$scope.libs = {};
					for (var d in data) {
						var dataItem = data[d].nodes;
						for (var di in dataItem) {
							if (dataItem[di].type == 'c') {
								$scope.libs['c' + dataItem[di].id] = dataItem[di];
							} else {
								$scope.libs[dataItem[di].id] = dataItem[di];
							}
						}
					}

					$scope.pathes = [];
					for (var p in data) {
						$scope.pathes.push(setPathFieldsAlias(data[p]));
					}
					var title;
					var titleObj;
					var tous = false;
					var formattedPathes = [];
					var nbrYellow = 0;
					for (var pa in $scope.pathes) {
						$scope.pathes[pa].fNodes = $scope.pathes[pa].nodes;
						if (
							$scope.pathes[pa].isTitle &&
							$scope.pathes[pa].path.indexOf('Tous') < 0
						) {
							title = $scope.pathes[pa].path;
							titleObj = $scope.pathes[pa];
							nbrYellow++;
						} else if ($scope.pathes[pa].path.indexOf('Tous') == 0) {
							title = undefined;
							tous = true;
						} else if (title !== undefined) {
							if (!tous) {
								$scope.pathes[pa].fNodes = [];
								for (var n in $scope.pathes[pa].nodes) {
									if (
										!containsNode(titleObj.nodes, $scope.pathes[pa].nodes[n])
									) {
										$scope.pathes[pa].fNodes.push($scope.pathes[pa].nodes[n]);
									}
								}
							}

							$scope.pathes[pa].path = $scope.pathes[pa].path.replace(
								title + '--',
								''
							);
						}
						formattedPathes.push($scope.pathes[pa]);
					}

					$scope.shared_pathes = nbrYellow !== 0;
					$scope.pathes = formattedPathes;
					$scope.pathesTable = $scope.pathes;

					$scope.pathesTable = sortPathes(
						$scope.pathesTable,
						'poids_moy',
						true,
						$scope.shared_pathes
					);
				});

				return;
			};

			$scope.sortPathes = function (
				pathesTable,
				field,
				reverse,
				shared_pathes
			) {
				return sortPathes(pathesTable, field, reverse, shared_pathes);
			};

			$scope.selectPath = function (path, isTitle) {
				if (isTitle) {
					return;
				}
				if ($scope.selectedPath !== path) {
					$scope.selectedPath = path;
				} else {
					$scope.selectedPath = undefined;
				}
			};

			$scope.searchPathesByText = function () {
				$scope.pathesTable = [];
				if ($scope.searchPathNotContains === undefined) {
					$scope.searchPathNotContains = false;
				}

				for (var p in $scope.pathes) {
					if (
						$scope.pathes[p].isTitle ||
						(!$scope.searchPathNotContains &&
							$scope.pathes[p].full_path
								.toLowerCase()
								.indexOf($scope.searchPathText) != -1) ||
						isEmpty($scope.searchPathText)
					) {
						$scope.pathesTable.push($scope.pathes[p]);
					} else if (
						$scope.pathes[p].isTitle ||
						($scope.searchPathNotContains &&
							$scope.pathes[p].full_path
								.toLowerCase()
								.indexOf($scope.searchPathText) == -1)
					) {
						$scope.pathesTable.push($scope.pathes[p]);
					}
				}
			};

			$scope.getFiltredNodes = function (nodes) {
				return nodes;
			};
		},
	]);
})();
