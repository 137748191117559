(function () {
	'use strict';

	angular.module('dcApp').directive('depotLinkedEntities', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'EntiteDepotService',
				function ($scope, $rootScope, EntiteDepotService) {
					$scope.getDepotCount = function (depotId) {
						if (!isNaN(depotId)) {
							EntiteDepotService.getUetCountByDepotId(depotId).then(function (
								response
							) {
								$scope.uetCount = response.data;
							});
						} else {
							$scope.uetCount = 0;
						}
					};
				},
			];

			return buildDirectiveReturnObject(
				{ depotId: '=' },
				controller,
				'./src/components/directives/depotLinkedEntities/depotLinkedEntities.html',
				function postLink(scope, element, attrs) {
					scope.getDepotCount(scope.vm.depotId);
				}
			);
		},
	]);
})();
