(function () {
	'use strict';
	angular.module('dcApp').factory('SemanticContextService', [
		'$http',
		'$q',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		function ($http, $q, $rootScope, API_BASE_URL_BACKEND) {
			var SemanticContextService = {},
				endpoint = API_BASE_URL_BACKEND + 'semantic_context';

			SemanticContextService.getSemantics = function () {
				return $http.get(endpoint).then(function (response) {
					return response;
				});
			};

			SemanticContextService.getSemanticRuleById = function (id) {
				return $http
					.get(endpoint + '/by_id?ruleId=' + id)
					.then(function (response) {
						return response;
					});
			};

			SemanticContextService.createSemantic = function (semantic) {
				return $http.post(endpoint, semantic).then(function (response) {
					return response;
				});
			};

			SemanticContextService.updateSemantic = function (semantic) {
				return $http.put(endpoint, semantic).then(function (response) {
					return response;
				});
			};

			SemanticContextService.deleteSemantic = function (id) {
				return $http
					.delete(endpoint + '?semanticId=' + id)
					.then(function (response) {
						return response;
					});
			};

			return SemanticContextService;
		},
	]);
})();
