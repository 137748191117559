(function () {
	'use strict';

	angular.module('dcApp').controller('PublicationController', [
		'$scope',
		'$stateParams',
		'PublicationService',
		'DashboardService',
		'$rootScope',
		'LANGUAGES',
		function ($scope, $stateParams, PublicationService, DashboardService, $rootScope, LANGUAGES) {
			var loadDashboard = function (
				centerings,
				callbackFunction,
				applyInitialCenterings
			) {
				if (/^fr\b/.test(navigator.language)) {
					$rootScope.setDefaultLanguage(LANGUAGES.FRENCH);
				} else {
					$rootScope.setDefaultLanguage(LANGUAGES.ENGLISH);
				}
				$scope.isLoading = true;
				let formattedCentering = angular.copy(centerings);
				formattedCentering.forEach(centering => DashboardService.centeringDateToISO(centering));
				PublicationService.getDashboardData(
					$stateParams.link,
					formattedCentering,
					applyInitialCenterings
				).then(function (response) {
					$scope.publication_date = $rootScope.getDateTimeWithPatternMn(
						response.data.publication_date
					);
					$scope.isLoading = false;

					if (callbackFunction) {
						callbackFunction(response.data);
						return;
					}
					$scope.label = response.data.publication_label;
					$scope.headerVisible = response.data.header_visible;
					let fullData = response.data;
					fullData.link = $stateParams.link;
					$scope.dashboardView = {
						full_data: fullData,
						reloadFullData: loadDashboardWithCentering,
					};
				});
			};

			loadDashboard([], undefined, true);

			var loadDashboardWithCentering = function (centerings, callbackFunction) {
				loadDashboard(centerings, callbackFunction, false);
			};
		},
	]);
})();
