(function () {
	'use strict';

	angular.module('dcApp').factory('UserAdapter', [
		'gettextCatalog',
		function (gettextCatalog) {
			const UserAdapter = {};

			UserAdapter.generateUsersList = function (data) {
				if (data === null || data === undefined) {
					return [];
				}
				return data.map(function (userData) {
					return generateUser(userData);
				});
			};

			UserAdapter.generateUser = function (data) {
				return generateUser(data);
			};

			function generateUser(data) {
				const yesTra = gettextCatalog.getString('Oui');
				const noTra = gettextCatalog.getString('Non');
				return {
					id: data.id,
					first_name: data.first_name,
					last_name: data.last_name,
					username: data.username,
					enabled: data.enabled ? yesTra : noTra,
					enabledTmp: data.enabled,
					stacked_member_role_labels: computeMemberRoleSource(data),
					has_member_role:
						data.has_member_role.own || data.has_member_role.inherited,
					has_inherited_memberRole: data.has_member_role.inherited,
					has_project_role:
						data.has_project_role.own || data.has_project_role.inherited,
					has_inherited_projectRole: data.has_project_role.inherited,
					stacked_project_role_labels: computeProjectRoleSource(data),
					has_admin_role: data.has_admin_role.own,
					is_locked: data.locked,
					email: data.email,
					is_group_member: data.is_group_member,
				};
			}

			function computeMemberRoleSource(userData) {
				if (
					!userData.has_member_role.own &&
					!userData.has_member_role.inherited
				) {
					return null;
				}
				const ownRole = gettextCatalog.getString('isMember.badgeLabel.owner');
				const inheritedRole = gettextCatalog.getString(
					'isMember.badgeLabel.inherited'
				);
				return {
					badgeLabel:
						userData.has_member_role.own && userData.has_member_role.inherited
							? 'm'
							: userData.has_member_role.own
							? ownRole
							: inheritedRole,
					iconTitle:
						gettextCatalog.getString('project.member') +
						' (' +
						generateMemberRoleDescription(userData) +
						')',
				};
			}

			function computeProjectRoleSource(userData) {
				if (
					!userData.has_project_role.own &&
					!userData.has_project_role.inherited
				) {
					return null;
				}
				const ownRole = gettextCatalog.getString('isProject.badgeLabel.owner');
				const inheritedRole = gettextCatalog.getString(
					'isProject.badgeLabel.inherited'
				);
				return {
					badgeLabel:
						userData.has_project_role.own && userData.has_project_role.inherited
							? 'm'
							: userData.has_project_role.own
							? ownRole
							: inheritedRole,
					iconTitle:
						gettextCatalog.getString('project.handler') +
						' (' +
						generateProjectRoleDescription(userData) +
						')',
				};
			}

			function generateMemberRoleDescription(userData) {
				const ownRoleTitle = gettextCatalog.getString('isMember.title.owner');
				const inheritedRoleTitle = gettextCatalog.getString(
					'isMember.title.inherited'
				);
				return userData.has_member_role.own &&
					userData.has_member_role.inherited
					? ownRoleTitle + '/' + inheritedRoleTitle
					: userData.has_member_role.own
					? ownRoleTitle
					: inheritedRoleTitle;
			}

			function generateProjectRoleDescription(userData) {
				const ownRoleTitle = gettextCatalog.getString('isProject.title.owner');
				const inheritedRoleTitle = gettextCatalog.getString(
					'isProject.title.inherited'
				);
				return userData.has_project_role.own &&
					userData.has_project_role.inherited
					? ownRoleTitle + '/' + inheritedRoleTitle
					: userData.has_project_role.own
					? ownRoleTitle
					: inheritedRoleTitle;
			}

			return UserAdapter;
		},
	]);
})();
