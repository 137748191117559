(function () {
	angular.module('dcApp').factory('ImportDCService', [
		'$http',
		'API_BASE_URL_BACKEND',
		'$rootScope',
		'$httpParamSerializer',
		function ($http, API_BASE_URL_BACKEND, $rootScope, $httpParamSerializer) {
			var ImportDCService = {},
				endPoint = API_BASE_URL_BACKEND + '/import_dc/';

			ImportDCService.import = function (hdhImport) {
				return $http.post(endPoint, hdhImport).then(function (response) {
					return response;
				});
			};

			return ImportDCService;
		},
	]);
})();
