(function () {
	'use strict';

	angular.module('demosModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('demos', {
			url: '/demos',
			views: {
				content: {
					template: '<ui-view/>',
				},
			},
			parent: 'logged',
			abstract: true,
		});

		$stateProvider.state('ajs18', {
			url: '/ajs18',
			parent: 'demos',
			data: {
				admin: true,
				access: '*',
			},
			views: {
				'@demos': {
					template: '<first-cmp></first-cmp>',
				},
			},
		});

		$stateProvider.state('angular-test-cmp', {
			url: '/angular-test-cmp',
			parent: 'demos',
			data: {
				admin: true,
				access: '*',
			},
			views: {
				'@demos': {
					template: '<app-test-cmp></app-test-cmp>',
				},
			},
		});
	}
})();
