'use strict';

angular
	.module('dcApp')
	.controller('RankingListController', [
		'$scope',
		'$rootScope',
		'toaster',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$filter',
		'RankingService',
		'PermissionService',
		'gettextCatalog',
		function (
			$scope,
			$rootScope,
			toaster,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$filter,
			RankingService,
			PermissionService,
			gettextCatalog
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.type = gettextCatalog.getString('Type');
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.actif = gettextCatalog.getString('Actif');
			dataGirdColumnName.code = gettextCatalog.getString('Code');
			dataGirdColumnName.description = gettextCatalog.getString('Description');
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteRank = gettextCatalog.getString(
				'Suppression du Classement effectuée'
			);
			let toasterPopCopyRank = gettextCatalog.getString(
				'Duplication du Classement effectuée'
			);
			let toasterPopDesactiveRank = gettextCatalog.getString(
				'Désactivation du Classement effectuée'
			);
			let toasterPopActiveRank = gettextCatalog.getString(
				'Activation du Classement effectuée'
			);

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;

			PermissionService.haveGlobalPermission('classement-create').then(
				function (response) {
					$scope.create_authorised = response.data;
				}
			);

			$scope.showDeleteModal = function (modalId, obj) {
				$scope.obectToBeDeletedId = obj.id;
				$('#' + modalId).modal('show');
			};

			$scope.deleteRanking = function (modalId) {
				RankingService.deleteRanking($scope.obectToBeDeletedId).then(function (
					response
				) {
					for (var i = 0; i < $scope.rankings.length; i++) {
						if ($scope.obectToBeDeletedId == $scope.rankings[i].id) {
							$scope.rankings.splice(i, 1);
						}
					}
					$scope.rankingsGridData = $scope.rankings;
					if ($scope.gridInstance && $scope.gridInstance.option) {
						$scope.gridInstance.option('dataSource', $scope.rankingsGridData);
						$scope.gridInstance.refresh();
					}
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteRank);
				});
				$('#' + modalId).modal('hide');
			};

			let yesTra = gettextCatalog.getString('Oui');
			let noTra = gettextCatalog.getString('Non');
			function getRankingsList() {
				var deferred = $q.defer();
				RankingService.findAll($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getRankingsList().then(function (data) {
					const content = data;
					if (content !== undefined) {
						$scope.rankings = [];
						$scope.tags = [];
						var tags = [];
						var dsResults = content;
						for (let i in dsResults) {
							$scope.rankings.push({
								id: dsResults[i].id,
								code: dsResults[i].metadata.code,
								lc: dsResults[i].metadata.label,
								desc: dsResults[i].metadata.description,
								is_active: dsResults[i].metadata.actif ? yesTra : noTra,
								actif: dsResults[i].metadata.actif,
								tags: content[i].metadata.tags,
								updateDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.update_date
								),
								creationDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.creation_date
								),
								updatedBy: content[i].metadata.updated_by,
								createdBy: content[i].metadata.created_by,
								icon_id: content[i].metadata.id,
								tagAsArray: $rootScope.mapTags(content[i].metadata.tags),
							});
							tags = _.union(tags, content[i].metadata.tags);
						}

						for (let i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}

						$scope.rankingsGridData = angular.copy($scope.rankings);
						$scope.dataGridOptions.dataSource = $scope.rankingsGridData;
						$scope.showGrid = true;
					}
				});
			};

			var getAvailableTags = function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						resolve(
							_.map(
								_.sortBy($scope.tags, function (i) {
									return i.label.toLowerCase();
								}),
								function (el) {
									return el.label;
								}
							)
						);
					}, 50);
				});
			};

			var lookupTagDataSource = {
				store: new DevExpress.data.CustomStore({
					loadMode: 'raw',
					load: function () {
						return getAvailableTags().then(function (response) {
							return response;
						});
					},
				}),
			};

			$scope.dataGridOptions = {
				dataSource: $scope.rankingsGridData,
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					infoText: $rootScope.dxGridTextInfo,
					showInfo: true,
					visible: true,
				},
				scrolling: {
					rowRenderingMode: 'Standard',
					showScrollbar: 'onHover',
				},
				searchPanel: {
					visible: true,
					width: 240,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnChooser: {
					enabled: true,
				},
				columnFixing: {
					enabled: false,
				},
				columns: [
					{
						dataField: 'code',
						caption: dataGirdColumnName.code,
						visible: false,
						alignment: 'center',
					},
					{
						caption: dataGirdColumnName.label,
						cellTemplate: 'imageAndLib',
						dataField: 'lc',
						width: '30.8%',
						allowReordering: false,
					},
					{
						dataField: 'tags',
						caption: dataGirdColumnName.tags,
						cellTemplate: 'tagTemplate',
						allowHeaderFiltering: true,
						allowFiltering: false,
						enableCellEdit: false,
						width: '32%',
						lookup: {
							dataSource: lookupTagDataSource,
						},
						calculateFilterExpression: function (
							filterValue,
							selectedFilterOperation
						) {
							if (!filterValue) return;
							return function (rowData) {
								return _.indexOf(rowData.tagAsArray, filterValue) != -1;
							};
						},
					},
					{
						caption: dataGirdColumnName.description,
						dataField: 'desc',
						width: '29%',
					},
					{
						caption: dataGirdColumnName.actif,
						cellTemplate: 'activeTemplate',
						dataField: 'actif',
						width: 120,
					},
					{
						dataField: 'updateDate',
						width: '20%',
						caption: gettextCatalog.getString('Date de mise à jour'),
						sortOrder: 'desc',
					},
					{
						cellTemplate: 'updatedByUser',
						width: '20%',
						caption: gettextCatalog.getString('Dernier modificateur'),
					},
					{
						dataField: 'creationDate',
						width: '20%',
						caption: gettextCatalog.getString('Date de création'),
						sortOrder: 'desc',
						visible: false,
					},
					{
						cellTemplate: 'createdByUser',
						width: '20%',
						caption: gettextCatalog.getString('Créateur'),
						visible: false,
					},
					{
						caption: dataGirdColumnName.actions,
						alignment: 'center',
						cellTemplate: 'actions',
						width: 150,
						allowReordering: false,
					},
				],
				onInitialized: function (e) {
					$scope.gridInstance = e.component;
				},
			};

			$scope.tagBox = {
				displayExpr: 'code',
				valueExpr: 'id',
				searchEnabled: false,
				editEnabled: false,
				tagTemplate: 'tagTemplate',
			};

			$scope.getTagBox = function (list) {
				$scope.tagBox.value = list.data.tags;
				return $scope.tagBox;
			};

			function init() {
				$scope.active = true;
				PermissionService.haveGlobalPermission('classement').then(function (
					response
				) {
					$scope.classement_authorised = response.data;
					if ($scope.classement_authorised) {
						$scope.getGridData();
					}
				});
			}

			$scope.showDuplicateModal = function (modalId, row) {
				$scope.rankingToBeDuplicatedId = row.id;
				$('#' + modalId).modal('show');
			};

			$scope.duplicateRanking = function (modalId) {
				RankingService.duplicateRanking($scope.rankingToBeDuplicatedId).then(
					function (response) {
						$('#' + modalId).modal('hide');
						toaster.pop('success', toasterPopSuccess, toasterPopCopyRank);
						init();
					}
				);
				$('#' + modalId).modal('hide');
			};

			init();

			$scope.disableRanking = function (id) {
				RankingService.disableRanking(id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDesactiveRank);
				});
			};

			$scope.enableRanking = function (id) {
				RankingService.enableRanking(id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopActiveRank);
				});
			};

			$scope.toggleChanged = function (ranking) {
				if (ranking.actif) {
					$scope.enableRanking(ranking.id);
				} else {
					$scope.disableRanking(ranking.id);
				}
			};
		},
	])
	.controller('RankingEditController', [
		'$scope',
		'$rootScope',
		'$stateParams',
		'toaster',
		'RankingService',
		'$state',
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'$filter',
		'DATE_TIME_PATTERN',
		'$timeout',
		'SemanticContextService',
		'DATA_TYPES',
		'gettextCatalog',
		function (
			$scope,
			$rootScope,
			$stateParams,
			toaster,
			RankingService,
			$state,
			$q,
			$http,
			API_BASE_URL_BACKEND,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			$filter,
			DATE_TIME_PATTERN,
			$timeout,
			SemanticContextService,
			DATA_TYPES,
			gettextCatalog
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopTypeConstraint = gettextCatalog.getString(
				'Le type est obligatoire'
			);
			let toasterPopCreationRank = gettextCatalog.getString(
				'Création du Classement effectuée'
			);
			let toasterPopEditRank = gettextCatalog.getString(
				'Modification du Classement effectuée'
			);

			let toasterPopError = gettextCatalog.getString('Erreur');

			let tankType = {};
			tankType.words = gettextCatalog.getString('Mots (Contexte Sémantique)');
			tankType.string = gettextCatalog.getString('Chaîne de caractères');
			tankType.integer = gettextCatalog.getString('Nombre entier (<2 Md)');
			tankType.beigInteger = gettextCatalog.getString('Grand nombre entier');
			tankType.demical = gettextCatalog.getString('Nombre décimal');
			tankType.date = gettextCatalog.getString('Date');

			let rankMode = {};
			rankMode.value = gettextCatalog.getString(
				'Exécuter la totalité des classes'
			);
			rankMode.stopIfTrue = gettextCatalog.getString(
				'S’arrêter dès que la réponse est positive'
			);
			rankMode.stopIfFalse = gettextCatalog.getString(
				'S’arrêter dès que la réponse est négative'
			);
			rankMode.runAllTrue = gettextCatalog.getString(
				'Exécuter toutes les classes avec une réponse positive'
			);
			rankMode.runAllFlase = gettextCatalog.getString(
				'Exécuter toutes les classes avec une réponse négative'
			);

			let rankOperators = {};
			rankOperators.equal = gettextCatalog.getString('Egale');
			rankOperators.notEqual = gettextCatalog.getString('Différent');
			rankOperators.less = gettextCatalog.getString('Inférieur');
			rankOperators.lessOrEqual =
				gettextCatalog.getString('Inférieur ou égale');
			rankOperators.greater = gettextCatalog.getString('Supérieur');
			rankOperators.greaterOrEqual =
				gettextCatalog.getString('Supérieur ou égale');
			rankOperators.between = gettextCatalog.getString("Dans l'intervalle");
			rankOperators.notBetween = gettextCatalog.getString(
				"N'est pas dans l'intervalle"
			);
			rankOperators.beginsWith = gettextCatalog.getString('Commence par');
			rankOperators.notBeginsWith = gettextCatalog.getString(
				'Ne commence pas par'
			);
			rankOperators.contains = gettextCatalog.getString('Contient');
			rankOperators.notContains = gettextCatalog.getString('Ne contient pas');
			rankOperators.endsWith = gettextCatalog.getString('Termine par');
			rankOperators.notEndsWith = gettextCatalog.getString(
				'Ne se termine pas par'
			);
			rankOperators.isNull = gettextCatalog.getString('Est nulle');
			rankOperators.isNotNull = gettextCatalog.getString("N'est pas nulle");
			rankOperators.containsAtLeastOne = gettextCatalog.getString(
				'Contient au moins une valeur de la liste'
			);
			rankOperators.containsAllValues = gettextCatalog.getString(
				"Contient toutes les valeurs d'une liste"
			);
			rankOperators.notContainsAny = gettextCatalog.getString(
				'Ne contient aucune valeur de la liste'
			);

			let rankBtnSaveName = gettextCatalog.getString('Enregistrer');
			let rankBtnAddName = gettextCatalog.getString('Ajouter');

			$scope.metadata = {};
			$scope.metadata.code = '';
			$scope.metadata.actif = true;
			$scope.metadata.date_effet = $rootScope.getDateWithPattern(new Date());
			$scope.metadata.date_revocation = ' 2099-12-31';
			$scope.metadata.tags = [];

			$scope.rankings = {};
			$scope.isNewRanking = true;
			$scope.ranks = {};
			$scope.ranks.ranks = [];

			$scope.types = _.each(DATA_TYPES, function (e) {
				e.lib = gettextCatalog.getString(e.lib);
			});
			// filter unused types
			$scope.types = _.filter($scope.types, function (e) {
				return (
					e.value != 'geometry' &&
					e.value != 'file' &&
					e.value != 'boolean' &&
					e.value != 'words'
				);
			});

			$scope.modes = [
				{ lib: rankMode.value, value: 'RUN_ALL_CLASSES', sep: true },
				{ lib: rankMode.stopIfTrue, value: 'STOP_IF_TRUE' },
				{
					lib: rankMode.stopIfFalse,
					value: 'STOP_IF_FALSE',
				},
				{ lib: rankMode.runAllTrue, value: 'RUN_ALL_TRUE', sep: true },
				{
					lib: rankMode.runAllFlase,
					value: 'RUN_ALL_FALSE',
					sep: true,
				},
			];
			$scope.operators = [
				{
					value: 'equal',
					nb_inputs: 1,
					lib: rankOperators.equal,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'not_equal',
					nb_inputs: 1,
					lib: rankOperators.notEqual,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'less',
					nb_inputs: 1,
					lib: rankOperators.less,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'less_or_equal',
					nb_inputs: 1,
					lib: rankOperators.lessOrEqual,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'greater',
					nb_inputs: 1,
					lib: rankOperators.greater,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'greater_or_equal',
					nb_inputs: 1,
					lib: rankOperators.greaterOrEqual,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'between',
					nb_inputs: 2,
					lib: rankOperators.between,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'not_between',
					nb_inputs: 2,
					lib: rankOperators.notBetween,
					apply_to: ['integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'begins_with',
					nb_inputs: 1,
					lib: rankOperators.beginsWith,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'not_begins_with',
					nb_inputs: 1,
					lib: rankOperators.notBeginsWith,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'contains',
					nb_inputs: 1,
					lib: rankOperators.contains,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'not_contains',
					nb_inputs: 1,
					lib: rankOperators.notContains,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'ends_with',
					nb_inputs: 1,
					lib: rankOperators.endsWith,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'not_ends_with',
					nb_inputs: 1,
					lib: rankOperators.notEndsWith,
					apply_to: ['string'],
					list: false,
				},
				{
					value: 'is_null',
					nb_inputs: 0,
					lib: rankOperators.isNull,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'is_not_null',
					nb_inputs: 0,
					lib: rankOperators.isNotNull,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: false,
				},
				{
					value: 'contains_at_least_one',
					nb_inputs: 1,
					lib: rankOperators.containsAtLeastOne,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: true,
				},
				{
					value: 'contains_all_values',
					nb_inputs: 1,
					lib: rankOperators.containsAllValues,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: true,
				},
				{
					value: 'not_contains_any',
					nb_inputs: 1,
					lib: rankOperators.notContainsAny,
					apply_to: ['string', 'integer', 'date', 'big_integer', 'decimal'],
					list: true,
				},
			];

			$scope.filterOperatorsByType = function (elem) {
				return elem.apply_to.indexOf($scope.selectedType) != -1;
			};

			$scope.editingMode = false;
			$scope.rankingType = '';
			$scope.addOrEditRankRow = function (modalId, index) {
				$scope.ranking = {};
				$scope.ranking.values_list = [''];
				if (index != undefined) {
					$scope.ranking = angular.copy($scope.ranks.ranks[index]);
					$scope.ranking.values_list =
						$scope.ranking.values_list != undefined
							? $scope.ranking.values_list
							: [''];
					$scope.setSelectedOperator($scope.ranking.operator);
					$scope.editingMode = true;
					$scope.editedRankIndex = index;
					$scope.btnName = rankBtnSaveName;
				} else {
					$scope.editingMode = false;
					$scope.btnName = rankBtnAddName;
				}
				$('#' + modalId).modal('show');
			};

			$scope.deleteElement = function (index) {
				$scope.ranking.values_list.splice(index, 1);
			};

			$scope.newListElement = function () {
				$scope.ranking.values_list.push('');
			};

			$scope.setRankingType = function (type) {
				$scope.ranks.type = type.value;
				$scope.selectedType = type.value;
			};

			$scope.setRankingProcessingMode = function (mode) {
				for (var i in $scope.modes) {
					if ($scope.modes[i].value == mode) {
						$scope.ranks.processing_mode = $scope.modes[i];
					}
				}
			};

			let mandatoryLibTra = gettextCatalog.getString(
				'Un libellé classement est obligatoire'
			);
			let mandatoryOpTra = gettextCatalog.getString(
				'Un opérateur est obligatoire'
			);
			$scope.addEditRanking = function (modalId) {
				if (!$scope.ranking.lib && $scope.ranks.type != 'words') {
					toaster.pop('error', toasterPopError, mandatoryLibTra);
					return;
				}
				if (!$scope.ranking.operator && $scope.ranks.type != 'words') {
					toaster.pop('error', toasterPopError, mandatoryOpTra);
					return;
				}
				if ($scope.editingMode) {
					$scope.ranks.ranks[$scope.editedRankIndex] = $scope.ranking;
				} else {
					var ranks = $scope.ranks.ranks;
					ranks.push($scope.ranking);
					$scope.ranks.ranks = ranks;
				}

				$('#' + modalId).modal('hide');
			};

			$scope.setSaveButtonDisabled = function (value) {
				$scope.saveButtonDisabled = value;
				$scope.newFileUploaded = false;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				backToList();
			};

			let backToList = function () {
				$timeout(function () {
					$state.go('rankings');
				}, 300);
			};

			$scope.saveAndReturnToList = function () {
				$scope.saveRanking(true);
			};

			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			function convertToSelectedType(value) {
				if ($scope.ranks.type != 'string' && $scope.ranks.type != 'date') {
					return Number(value);
				} else {
					return value;
				}
			}

			function getOpeartorValue(rows) {
				var forrmatedRows = rows;

				for (var w in forrmatedRows) {
					forrmatedRows[w].operator = forrmatedRows[w].operator
						? forrmatedRows[w].operator.value
						: null;
				}

				return forrmatedRows;
			}

			$scope.saveRanking = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				// original object
				let ranksData = angular.copy($scope.ranks);
				ranksData.processing_mode = $scope.ranks.processing_mode;
				ranksData.separator = $scope.ranks.separator;

				// Parse string to int
				for (var v in ranksData.ranks) {
					ranksData.ranks[v].low = convertToSelectedType(
						$scope.ranks.ranks[v].low
					);
					ranksData.ranks[v].high = convertToSelectedType(
						$scope.ranks.ranks[v].high
					);
				}

				var rankingGrammar = {};
				rankingGrammar.type = $scope.ranks.type;
				rankingGrammar.processing_mode =
					ranksData &&
					ranksData.processing_mode &&
					ranksData.processing_mode.value &&
					ranksData.processing_mode.value != ''
						? ranksData.processing_mode.value
						: 'STOP_IF_TRUE';
				rankingGrammar.separator = $scope.ranks.separator;
				if (rankingGrammar.type == 'string') {
					rankingGrammar.rankings_string = getOpeartorValue(ranksData.ranks);
					rankingGrammar.rankings_string = prepareRankGrammar(
						rankingGrammar.rankings_string
					);
				} else if (rankingGrammar.type == 'integer') {
					rankingGrammar.rankings_integer = getOpeartorValue(ranksData.ranks);
					rankingGrammar.rankings_integer = prepareRankGrammar(
						rankingGrammar.rankings_integer
					);
				} else if (rankingGrammar.type == 'big_integer') {
					rankingGrammar.rankings_big_int = getOpeartorValue(ranksData.ranks);
					rankingGrammar.rankings_big_int = prepareRankGrammar(
						rankingGrammar.rankings_big_int
					);
				} else if (rankingGrammar.type == 'decimal') {
					rankingGrammar.rankings_double = getOpeartorValue(ranksData.ranks);
					rankingGrammar.rankings_double = prepareRankGrammar(
						rankingGrammar.rankings_double
					);
				} else if (rankingGrammar.type == 'date') {
					rankingGrammar.rankings_date = getOpeartorValue(ranksData.ranks);
					rankingGrammar.rankings_date = prepareRankGrammar(
						rankingGrammar.rankings_date
					);
				} else if (rankingGrammar.type == 'words') {
					rankingGrammar.rankings_semantic_rule = getOpeartorValue(
						ranksData.ranks
					);
					rankingGrammar.rankings_semantic_rule = prepareRankGrammar(
						rankingGrammar.rankings_semantic_rule
					);
				} else {
					toaster.pop('error', toasterPopError, toasterPopTypeConstraint);
					return;
				}

				var ranking = {
					metadata: $scope.metadata,
					grammar: rankingGrammar,
				};
				ranking.id = $scope.isNewRanking ? null : $scope.rankingId;

				if ($scope.isNewRanking) {
					RankingService.createRanking(ranking).then(function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopCreationRank);
						if (returnToList) {
							backToList();
						} else {
							$timeout(function () {
								$state.go('rankings-edit', { rankingId: response.data });
							}, 300);
						}
					});
				} else {
					RankingService.editRanking(ranking).then(function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopEditRank);
						if (returnToList) {
							backToList();
						}
					});
				}
			};

			// Prepare DTO
			function prepareRankGrammar(ranks) {
				for (var i in ranks) {
					if (
						_.contains(
							[
								'contains_at_least_one',
								'contains_all_values',
								'not_contains_any',
							],
							ranks[i].operator
						) ||
						ranks[i].semantic_rule
					) {
						delete ranks[i].low;
						delete ranks[i].high;
						if (ranks[i].semantic_rule) {
							delete ranks[i].values_list;
						}
					} else {
						delete ranks[i].values_list;
						delete ranks[i].semantic_rule;
					}
				}

				return ranks;
			}

			$scope.showDeleteRowModal = function (modalId, index) {
				$scope.rowIndexTobeDeleted = index;
				$('#' + modalId).modal('show');
			};

			$scope.deleteRow = function (modalId) {
				$scope.ranks.ranks.splice($scope.rowIndexTobeDeleted, 1);
				$('#' + modalId).modal('hide');
			};

			function init() {
				$scope.noString = ' ';
				$scope.forms = {};

				if ($stateParams.rankingId) {
					// edit
					$scope.isNewRanking = false;
					$scope.ranking = {};
					$scope.ranks = {};
					$scope.ranking.values_list = [''];
					RankingService.getRankingById($stateParams.rankingId).then(function (
						response
					) {
						$scope.ranking = response.data;
						$scope.rankingId = $scope.ranking.id;
						$scope.metadata = $scope.ranking.metadata;
						$scope.metadataLoaded = true;
						$scope.ranks = $scope.ranking.grammar;

						if ($scope.ranks.type == 'string') {
							$scope.ranks.ranks = $scope.ranks.rankings_string;
							$scope.ranks.type = 'string';
						} else if ($scope.ranks.type == 'integer') {
							$scope.ranks.ranks = $scope.ranks.rankings_integer;
							$scope.ranks.type = 'integer';
						} else if ($scope.ranks.type == 'big_integer') {
							$scope.ranks.ranks = $scope.ranks.rankings_big_int;
							$scope.ranks.type = 'big_integer';
						} else if ($scope.ranks.type == 'decimal') {
							$scope.ranks.ranks = $scope.ranks.rankings_double;
							$scope.ranks.type = 'decimal';
						} else if ($scope.ranks.type == 'date') {
							$scope.ranks.ranks = $scope.ranks.rankings_date;
							$scope.ranks.type = 'date';
							for (var t in $scope.ranks.ranks) {
								$scope.ranks.ranks[t].low = $filter('date')(
									$scope.ranks.ranks[t].low,
									DATE_TIME_PATTERN
								);
								$scope.ranks.ranks[t].high = $filter('date')(
									$scope.ranks.ranks[t].high,
									DATE_TIME_PATTERN
								);
								for (var o in $scope.ranks.ranks[t].values_list) {
									$scope.ranks.ranks[t].values_list[o] = $filter('date')(
										$scope.ranks.ranks[t].values_list[o],
										DATE_TIME_PATTERN
									);
									$scope.ranks.ranks[t].values_list[o] = $filter('date')(
										$scope.ranks.ranks[t].values_list[o],
										DATE_TIME_PATTERN
									);
								}
							}
						} else if ($scope.ranks.type == 'words') {
							$scope.ranks.ranks = $scope.ranks.rankings_semantic_rule;
							$scope.ranks.type = 'words';
						} else {
							$scope.ranks = {};
							$scope.ranks.ranks = {};
							$scope.ranks.type = 'string';
							$scope.ranking.values_list = [''];
						}

						for (var l in $scope.types) {
							if ($scope.types[l].value == $scope.ranks.type) {
								$scope.rankingType = $scope.types[l];
								$scope.setRankingType($scope.rankingType);
								break;
							}
						}

						for (var x in $scope.ranks.ranks) {
							for (var z in $scope.operators) {
								if (
									$scope.operators[z].value == $scope.ranks.ranks[x].operator
								) {
									$scope.ranks.ranks[x].operator = $scope.operators[z];
									break;
								}
							}
						}

						SemanticContextService.getSemantics().then(function (semResponse) {
							$scope.semanticRulesList = semResponse.data;
							for (var i in $scope.ranks.ranks) {
								if ($scope.ranks.ranks[i].semantic_rule) {
									$scope.ranks.ranks[i].semantic_rule = _.find(
										$scope.semanticRulesList,
										function (elm) {
											return elm.id == $scope.ranks.ranks[i].semantic_rule.id;
										}
									);

									$scope.ranks.ranks[i].lib = $scope.ranks.ranks[i]
										.semantic_rule
										? $scope.ranks.ranks[i].semantic_rule.label
										: gettextCatalog.getString(
												'Règle non définie. Vérifiez si la règle existe'
										  );
								}
							}
						});
					});
				} else {
					$scope.isNewRanking = true;
					$scope.metadataLoaded = true;
					SemanticContextService.getSemantics().then(function (response) {
						$scope.semanticRulesList = response.data;
					});
				}
			}

			$scope.onSetDateLow = function (index) {
				if (index != undefined) {
					$scope.ranking.values_list[index] = $filter('date')(
						$scope.ranking.values_list[index],
						DATE_TIME_PATTERN
					);
				} else {
					$scope.ranking.low = $filter('date')(
						$scope.ranking.low,
						DATE_TIME_PATTERN
					);
				}
			};

			$scope.onSetDateHigh = function () {
				$scope.ranking.high = $filter('date')(
					$scope.ranking.high,
					DATE_TIME_PATTERN
				);
			};

			$scope.showSemanticRulesList = function (modalId) {
				$scope.semanticRulesListFiltred = $scope.semanticRulesList;
				$('#' + modalId).modal('show');
			};

			$scope.searchRuleByLabel = function () {
				if (
					$scope.ruleSearchText == undefined ||
					$scope.ruleSearchText.length == 0
				) {
					$scope.semanticRulesListFiltred = $scope.semanticRulesList;
				} else {
					$scope.semanticRulesListFiltred = _.filter(
						$scope.semanticRulesList,
						function (item) {
							return (
								item.label
									.toLowerCase()
									.indexOf($scope.ruleSearchText.toLowerCase()) !== -1
							);
						}
					);
				}
			};

			$scope.useRuleItem = function (modalId, rule) {
				$scope.ranking.semantic_rule = rule;
				$scope.ranking.lib = $scope.ranking.semantic_rule.label;
				$('#' + modalId).modal('hide');
			};

			// Validate inputs by chosen operator (once the operator is chosen the fields must be filled)
			$scope.isInputsFilled = function () {
				if ($scope.ranks && $scope.ranks.type != undefined) {
					if ($scope.ranks.type === 'words') {
						return (
							$scope.ranking &&
							$scope.ranking.semantic_rule &&
							$scope.ranking.semantic_rule.id !== undefined
						);
					} else if (
						$scope.ranking &&
						$scope.ranking.operator &&
						$scope.ranking.operator.nb_inputs === 2 &&
						!$scope.ranking.operator.list
					) {
						return (
							$scope.ranking.low !== undefined &&
							$scope.ranking.low !== '' &&
							$scope.ranking.high !== undefined &&
							$scope.ranking.high !== ''
						);
					} else if (
						$scope.ranking &&
						$scope.ranking.operator &&
						$scope.ranking.operator.nb_inputs === 1 &&
						!$scope.ranking.operator.list
					) {
						return (
							$scope.ranking.low !== undefined && $scope.ranking.low !== ''
						);
					} else if (
						$scope.ranking &&
						$scope.ranking.operator &&
						$scope.ranking.operator.list
					) {
						return isAllListValuesSetted();
					} else {
						return true;
					}
				} else {
					return false;
				}
			};

			function isAllListValuesSetted() {
				for (var i = 0; i < $scope.ranking.values_list.length; i++) {
					if (
						$scope.ranking.values_list[i] == undefined ||
						$scope.ranking.values_list[i] == ''
					) {
						return false;
					}
				}
				return true;
			}

			// Set selected operator
			$scope.setSelectedOperator = function (op) {
				if (
					$scope.ranking.operator != undefined &&
					!$scope.ranking.semantic_rule
				) {
					for (var l = 0; l < $scope.operators.length; l++) {
						if ($scope.operators[l].value == op.value) {
							$scope.ranking.operator = $scope.operators[l];
						}
					}
				}
			};

			$scope.hideMe = function (modalId) {
				$('#' + modalId).modal('hide');
			};

			init();
		},
	]);
