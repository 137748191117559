'use strict';

angular
	.module('dcApp')
	.controller('ConnectorListController', [
		'$log',
		'$scope',
		'$rootScope',
		'$state',
		'toaster',
		'ConnectorService',
		'$q',
		'DBConnectorService',
		'HTTPConnectorService',
		'ESConnectorService',
		'S3ConnectorService',
		'SFTPConnectorService',
		'HDFSConnectorService',
		'PermissionService',
		'API_BASE_URL_BACKEND',
		'gettext',
		'gettextCatalog',
		'DcElementListService',
		function (
			$log,
			$scope,
			$rootScope,
			$state,
			toaster,
			ConnectorService,
			$q,
			DBConnectorService,
			HTTPConnectorService,
			ESConnectorService,
			S3ConnectorService,
			SFTPConnectorService,
			HDFSConnectorService,
			PermissionService,
			API_BASE_URL_BACKEND,
			gettext,
			gettextCatalog,
			DcElementListService
		) {
			const HTTP_CONNECTOR = 'HTTP';
			const SQL_CONNECTOR = 'SQL';
			const SFTP_CONNECTOR = 'SFTP';
			const S3_CONNECTOR = 'S3';
			const HDFS_CONNECTOR = 'HDFS';
			const MONGODB_CONNECTOR = 'MONGODB';
			const NEO4J_CONNECTOR = 'NEO4J';

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteConnectors = gettextCatalog.getString(
				'Suppression des Connecteurs effectuée'
			);
			let toasterPopDeleteConnector = gettextCatalog.getString(
				'Suppression du Connecteur'
			);
			let toasterPopDeleteConnectorSuccess =
				gettextCatalog.getString('effectuée');
			let toasterPopDeleteConnectorDB =
				gettextCatalog.getString('Base de données');

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			$scope.checkPermissionData = {};
			PermissionService.haveGlobalPermission('connector-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'connector';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.editConnector = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'connector';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.goToEdit = function () {
				$state.go('connectors-edit', {
					id: $scope.objToEdit.id,
					type: $scope.objToEdit.connector_type,
				});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkConnectors
						: $scope.deleteConnector,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.deleteBulkConnectorsModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkConnectors = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				ConnectorService.deleteConnectors(connectorIds)
					.then(function () {
						if ($scope.connector_authorised) {
							$scope.getGridData();
							$scope.gridInstance.clearSelection();
						}
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteConnectors
						);
					})
					.catch(function (e) {
						if (
							e &&
							e.data &&
							e.data.code == '40' &&
							$scope.connector_authorised
						) {
							$scope.getGridData();
							$scope.gridInstance.clearSelection();
						}
					});
			};

			function deleteConnectorSuccess(lib) {
				toaster.pop(
					'success',
					toasterPopSuccess,
					toasterPopDeleteConnector +
						' ' +
						lib +
						' ' +
						toasterPopDeleteConnectorSuccess
				);
				for (let j in $scope.dataGridOptions.dataSource) {
					if (
						$scope.dataGridOptions.dataSource[j].id == $scope.objToDelete.id
					) {
						$scope.dataGridOptions.dataSource.splice(j, 1);
						$scope.gridInstance.refresh();
						break;
					}
				}
			}

			$scope.deleteConnector = function () {
				if (
					$scope.objToDelete.connector_type == SQL_CONNECTOR ||
					$scope.objToDelete.connector_type == MONGODB_CONNECTOR ||
					$scope.objToDelete.connector_type == NEO4J_CONNECTOR
				) {
					DBConnectorService.deleteDBConnector($scope.objToDelete.id)
						.then(function (response) {
							deleteConnectorSuccess(toasterPopDeleteConnectorDB);
						})
						.catch(function (e) {
							if (e && e.data && e.data.code == '40') {
								$scope.gridInstance.clearSelection();
							}
						});
				} else if ($scope.objToDelete.connector_type == HTTP_CONNECTOR) {
					HTTPConnectorService.deleteHTTPConnector($scope.objToDelete.id).then(
						function (response) {
							deleteConnectorSuccess(HTTP_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == S3_CONNECTOR) {
					S3ConnectorService.deleteS3Connector($scope.objToDelete.id).then(
						function (response) {
							deleteConnectorSuccess(S3_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == SFTP_CONNECTOR) {
					SFTPConnectorService.deleteSFTPConnector($scope.objToDelete.id).then(
						function (response) {
							deleteConnectorSuccess(SFTP_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == HDFS_CONNECTOR) {
					HDFSConnectorService.deleteHDFSConnector($scope.objToDelete.id).then(
						function (response) {
							deleteConnectorSuccess(HDFS_CONNECTOR);
						}
					);
				}
			};

			$scope.moveOrCopyData = {};
			$scope.moveOrCopyElementToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyData = {
					elements: connectorIds,
					type: 'connector',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneElementToProject = function (elementId) {
				$scope.moveOrCopyData = {
					elements: [elementId],
					type: 'connector',
					showMoveCopy: true,
				};
			};

			function getListConnectors() {
				let deferred = $q.defer();
				ConnectorService.getConnectorsList(
					undefined,
					undefined,
					undefined,
					$scope.showCurrentProjectOnly,
					$scope.activeElementsOnly
				).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						$log.error(err);
						deferred.reject(err);
					}
				);
				return deferred.promise;
			}

			$scope.showGrid = false;
			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListConnectors().then(function (data) {
					if (data != undefined) {
						let content = data;
						$scope.connectors = [];
						$scope.tags = [];
						let tags = [];
						for (let i in content) {
							$scope.connectors.push({
								id: content[i].id,
								code: content[i].metadata.code,
								lc: content[i].metadata.label,
								desc: content[i].metadata.description,
								connector_type: content[i].connector_type,
								tags: content[i].metadata.tags,
								icon_id: content[i].metadata.icon_id,
								imported: content[i].metadata.imported,
								tagAsArray: $rootScope.mapTags(content[i].metadata.tags),
								creationDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.creation_date
								),
								updateDate: $rootScope.getDateTimeWithPattern(
									content[i].metadata.update_date
								),
								createdBy: content[i].metadata.created_by,
								updatedBy: content[i].metadata.updated_by,
								project: content[i].metadata.project,
							});
							tags = _.union(tags, content[i].metadata.tags);
						}

						for (let i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.gridData = angular.copy($scope.connectors);
						$scope.dataGridOptions.dataSource = $scope.gridData;
						$scope.showGrid = true;
					}
				});
			};

			// Filter on imported property
			// Binded to imported checkbox
			$scope.filterImported = function () {
				if ($scope.importedOnly) {
					$scope.gridInstance.filter(['imported', '=', true]);
				} else {
					$scope.gridInstance.clearFilter('dataSource');
				}
			};

			function init() {
				$scope.active = true;
				PermissionService.haveGlobalPermission('connector').then(function (
					response
				) {
					$scope.connector_authorised = response.data;
					if ($scope.connector_authorised) {
						$scope.getGridData();
					}
				});
			}

			// Get DxGrid dataGridOptions
			$scope.disableAllSelectedElems = true;
			DcElementListService.getDcElementDxList($scope, 'connector', false);
			init();
		},
	])
	.controller('ConnectorEditController', [
		'$scope',
		'$rootScope',
		'$stateParams',
		'toaster',
		'$state',
		'EntiteFileService',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'$timeout',
		'HTTPConnectorService',
		'DBConnectorService',
		'ESConnectorService',
		'S3ConnectorService',
		'SFTPConnectorService',
		'HDFSConnectorService',
		'ConnectorService',
		'DepotService',
		'$filter',
		'$window',
		'gettextCatalog',
		'ModalService',
		function (
			$scope,
			$rootScope,
			$stateParams,
			toaster,
			$state,
			EntiteFileService,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			$timeout,
			HTTPConnectorService,
			DBConnectorService,
			ESConnectorService,
			S3ConnectorService,
			SFTPConnectorService,
			HDFSConnectorService,
			ConnectorService,
			DepotService,
			$filter,
			$window,
			gettextCatalog,
			ModalService
		) {
			const HTTP_CONNECTOR = 'HTTP';
			const SQL_CONNECTOR = 'SQL';
			const SFTP_CONNECTOR = 'SFTP';
			const S3_CONNECTOR = 'S3';
			const HDFS_CONNECTOR = 'HDFS';
			const MONGODB_CONNECTOR = 'MONGODB';
			const NEO4J_CONNECTOR = 'NEO4J';

			$scope.helpTitleTra = gettextCatalog.getString('Aide Connecteurs');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let createConnectorTra = gettextCatalog.getString(
				'Création du Connecteur'
			);
			let updateConnectorTra = gettextCatalog.getString(
				'Modification du Connecteur'
			);
			let deleteConnectorTra = gettextCatalog.getString(
				'Suppression du Connecteur'
			);
			let updateOrCreateConnectorDone = gettextCatalog.getString('effectuée');

			let toasterPopError = gettextCatalog.getString('Erreur');
			let toasterPopConnectorErrorMessage = gettextCatalog.getString(
				'Le type de Connecteur est obligatoire !'
			);
			let toasterPopValidConnection =
				gettextCatalog.getString('Connexion Valide');
			let toasterPopErrorConnection = gettextCatalog.getString(
				'La connexion a échoué'
			);

			let toasterConnectorHost = gettextCatalog.getString('hôte');
			let toasterConnectorPort = gettextCatalog.getString('port');
			let toasterConnectorBaseName = gettextCatalog.getString('nom_de_la_base');

			$scope.accessToken = window._keycloak.token;
			$scope.metadata = {};
			$scope.metadata.code = '';
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];

			let connectorDataBaseType = gettextCatalog.getString('Base de données');

			$scope.connectorsTypes = [
				{ value: SQL_CONNECTOR, label: connectorDataBaseType },
				{ value: HTTP_CONNECTOR, label: HTTP_CONNECTOR },
				{ value: S3_CONNECTOR, label: 'S3 / MinIO' },
				{
					value: SFTP_CONNECTOR,
					label: SFTP_CONNECTOR,
				},
				{ value: HDFS_CONNECTOR, label: HDFS_CONNECTOR },
			];

			$scope.httpAuthTypes = [
				{ value: 'NONE', label: gettextCatalog.getString('Aucune') },
				{ value: 'BASIC', label: gettextCatalog.getString('Basique') },
			];

			$scope.bdTypes = [
				{ value: 'mariaDb', label: 'MariaDB' },
				{ value: 'mongoDb', label: 'MongoDB' },
				{
					value: 'mySql',
					label: 'MySQL',
				},
				{
					value: 'neo4j',
					label: ' Neo4j',
				},
				{ value: 'oracle', label: 'Oracle' },
				{ value: 'pgSql', label: 'PostgreSQL' },
				{
					value: 'sqlServer',
					label: ' SQL Server',
				},
			];

			$scope.isNewConnector = true;

			$scope.saveButtonDisabled = true;
			$scope.permissionData = {};

			$scope.checkPermissionData = {};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.connector.id;
				$scope.checkPermissionData.type = 'connector';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.connector.metadata.id;
				$scope.permissionData.type = 'connector';
				$scope.permissionData.elementId = $scope.connector.id;
				$scope.permissionData.lib = $scope.connector.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.resetBdData = function () {
				$scope.metadataDb = {};
				$scope.metadataDb.code = '';
				$scope.metadataDb.actif = true;
				$scope.metadataDb.tags = [];
				$scope.extraction = {};
			};

			let catchErrors = function (error) {
				$scope.savingInProgress = false;
			};

			function connectorTypeLib(type) {
				switch (type) {
					case SFTP_CONNECTOR:
						return SFTP_CONNECTOR;
					case SQL_CONNECTOR:
						return connectorDataBaseType;
					case HTTP_CONNECTOR:
						return HTTP_CONNECTOR;
					case HDFS_CONNECTOR:
						return HDFS_CONNECTOR;
					case S3_CONNECTOR:
						return S3_CONNECTOR;
					default:
						return '';
				}
			}

			function createDepotSuccess(returnToList, responseData, type) {
				toaster.pop(
					'success',
					toasterPopSuccess,
					createConnectorTra +
						' ' +
						connectorTypeLib(type) +
						' ' +
						updateOrCreateConnectorDone
				);
				if (returnToList) {
					$timeout(function () {
						$scope.savingInProgress = false;
						$state.go('connectors');
					}, 300);
				} else {
					$timeout(function () {
						$scope.savingInProgress = false;
						$state.go('connectors-edit', { id: responseData.id, type: type });
					}, 300);
				}
			}

			function saveDepotSuccess(returnToList, responseData, type) {
				toaster.pop(
					'success',
					toasterPopSuccess,
					updateConnectorTra +
						' ' +
						connectorTypeLib(type) +
						' ' +
						updateOrCreateConnectorDone
				);
				if (returnToList) {
					$timeout(function () {
						$state.go('connectors');
					}, 300);
				}
				$scope.savingInProgress = false;
			}

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('connectors');
				}, 300);
			};
			$scope.connector = {};

			$scope.savingInProgress = false;
			$scope.saveConnector = function () {
				if (!$scope.isNewConnector) {
					$scope.checkPermissionData.elementId = $scope.connector.id;
					$scope.checkPermissionData.type = 'connector';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.save;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.save();
				}
			};

			$scope.save = function (returnToList) {
				if (
					$scope.connector.connector_type == SQL_CONNECTOR ||
					$scope.connector.connector_type == MONGODB_CONNECTOR ||
					$scope.connector.connector_type == NEO4J_CONNECTOR
				) {
					$scope.saveDbConnector(returnToList);
				} else if ($scope.connector.connector_type == HTTP_CONNECTOR) {
					$scope.saveHttpConnector(returnToList);
				} else if ($scope.connector.connector_type == S3_CONNECTOR) {
					$scope.saveS3Connector(returnToList);
				} else if ($scope.connector.connector_type == SFTP_CONNECTOR) {
					$scope.saveSFTPConnector(returnToList);
				} else if ($scope.connector.connector_type == HDFS_CONNECTOR) {
					$scope.saveHdfsConnector(returnToList);
				} else {
					toaster.pop(
						'error',
						toasterPopError,
						toasterPopConnectorErrorMessage
					);
				}
			};

			$scope.saveAndReturnToList = function () {
				$scope.save(true);
			};

			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.saveDbConnector = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let dbConnector = angular.copy($scope.connector);
				dbConnector.metadata = $scope.metadata;
				dbConnector.db_type = dbConnector.type;
				delete dbConnector.id;
				delete dbConnector.type;
				$scope.savingInProgress = true;
				let connectorType =
					dbConnector.db_type == 'mongoDb' ? MONGODB_CONNECTOR : SQL_CONNECTOR;
				connectorType =
					dbConnector.db_type == 'neo4j' ? NEO4J_CONNECTOR : connectorType;
				if ($scope.isNewConnector) {
					DBConnectorService.createDBConnector(dbConnector)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, connectorType);
						})
						.catch(catchErrors);
				} else {
					DBConnectorService.editDBConnector($scope.connector.id, dbConnector)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, connectorType);
						})
						.catch(catchErrors);
				}
			};

			$scope.saveSFTPConnector = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				$scope.sftpConnector = angular.copy($scope.connector);
				$scope.sftpConnector.metadata = $scope.metadata;
				delete $scope.sftpConnector.id;
				setSftpDtoSecurity($scope.sftpConnector);
				$scope.savingInProgress = true;

				if ($scope.isNewConnector) {
					SFTPConnectorService.createSFTPConnector($scope.sftpConnector)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, SFTP_CONNECTOR);
						})
						.catch(catchErrors);
				} else {
					SFTPConnectorService.editSFTPConnector(
						$scope.connector.id,
						$scope.sftpConnector
					)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, SFTP_CONNECTOR);
						})
						.catch(catchErrors);
				}
			};

			function setSftpDtoSecurity(connector) {
				if ($scope.sftpSSH) {
					connector.ssh_access.username = connector.username;
					connector.ssh_access.secret_phrase = connector.password;
				} else {
					connector.credentials.username = connector.username;
					connector.credentials.password = connector.password;
				}
				delete connector.username;
				delete connector.password;
				delete connector.ssh_access_key;
			}

			$scope.saveHttpConnector = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				$scope.httpConnector = angular.copy($scope.connector);
				$scope.httpConnector.metadata = $scope.metadata;
				delete $scope.httpConnector.id;
				setSelectedAuthTypeDtoSecurity($scope.httpConnector);
				$scope.savingInProgress = true;

				if ($scope.isNewConnector) {
					HTTPConnectorService.createHTTPConnector($scope.httpConnector)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, HTTP_CONNECTOR);
						})
						.catch(catchErrors);
				} else {
					HTTPConnectorService.editHTTPConnector(
						$scope.connector.id,
						$scope.httpConnector
					)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, HTTP_CONNECTOR);
						})
						.catch(catchErrors);
				}
			};

			function setSelectedAuthTypeDtoSecurity(connector) {
				if ($scope.selectedAuthType == 'BASIC') {
					connector.bac.username = connector.username;
					connector.bac.password = connector.password;
				}
				delete connector.username;
				delete connector.password;
			}

			$scope.saveS3Connector = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				$scope.esConnector = angular.copy($scope.connector);
				$scope.esConnector.metadata = $scope.metadata;
				delete $scope.esConnector.id;
				$scope.savingInProgress = true;

				if ($scope.isNewConnector) {
					S3ConnectorService.createS3Connector($scope.esConnector)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, S3_CONNECTOR);
						})
						.catch(catchErrors);
				} else {
					S3ConnectorService.editS3Connector(
						$scope.connector.id,
						$scope.esConnector
					)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, S3_CONNECTOR);
						})
						.catch(catchErrors);
				}
			};
			$scope.saveHdfsConnector = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				$scope.hdfsConnector = angular.copy($scope.connector);
				$scope.hdfsConnector.metadata = $scope.metadata;
				delete $scope.hdfsConnector.id;
				setHdfsConnectorParamsBeforeSave($scope.hdfsConnector);
				$scope.savingInProgress = true;

				if ($scope.isNewConnector) {
					HDFSConnectorService.createHDFSConnector($scope.hdfsConnector)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, HDFS_CONNECTOR);
						})
						.catch(catchErrors);
				} else {
					HDFSConnectorService.editHDFSConnector(
						$scope.connector.id,
						$scope.hdfsConnector
					)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, HDFS_CONNECTOR);
						})
						.catch(catchErrors);
				}
			};

			function initConnectorData(data, connectorType) {
				$scope.connector = data;
				$scope.connector.id = $scope.isNewConnector
					? undefined
					: $scope.connector.id;
				$scope.metadata = $scope.isNewConnector
					? $scope.metadata
					: $scope.connector.metadata;
				$scope.metadataLoaded = true;
				$scope.connector.connector_type = connectorType;
				if ($scope.connector.connector_type == SFTP_CONNECTOR) {
					if ($scope.connector.ssh_access) {
						$scope.connector.username = $scope.connector.ssh_access.username;
					} else if ($scope.connector.credentials) {
						$scope.connector.username = $scope.connector.credentials.username;
					}
				} else if (
					$scope.connector.connector_type == HTTP_CONNECTOR &&
					$scope.connector.bac
				) {
					$scope.connector.username = $scope.connector.bac.username;
				}
			}

			function init() {
				if (
					($stateParams.id && $stateParams.type) ||
					($stateParams.duplicatedId && $stateParams.type)
				) {
					// edit
					$scope.isNewConnector = $stateParams.duplicatedId != undefined;
					$scope.connector = {};
					$scope.isSSH();
					$scope.calcHttpAuth();
					let connectorId =
						$stateParams.id != undefined
							? $stateParams.id
							: $stateParams.duplicatedId;
					$scope.savingInProgress = false;

					if ($stateParams.type == HTTP_CONNECTOR) {
						HTTPConnectorService.getHTTPConnectorById(connectorId).then(
							function (response) {
								initConnectorData(
									response.data,
									HTTP_CONNECTOR
								);
								$scope.calcHttpAuth();
							}
						);
					} else if (
						$stateParams.type == SQL_CONNECTOR ||
						$stateParams.type == 'DB' ||
						$stateParams.type == MONGODB_CONNECTOR ||
						$stateParams.type == NEO4J_CONNECTOR
					) {
						DBConnectorService.getDBConnectorById(connectorId).then(function (
							response
						) {
							initConnectorData(response.data, SQL_CONNECTOR);
							$scope.connector.type = $scope.connector.db_type;
							delete $scope.connector.db_type;
							$scope.getConnectionString();
						});
					} else if ($stateParams.type == S3_CONNECTOR) {
						S3ConnectorService.getS3ConnectorById(connectorId).then(function (
							response
						) {
							initConnectorData(response.data, S3_CONNECTOR);
						});
					} else if ($stateParams.type == SFTP_CONNECTOR) {
						SFTPConnectorService.getSFTPConnectorById(connectorId).then(
							function (response) {
								initConnectorData(
									response.data,
									SFTP_CONNECTOR
								);
								$scope.isSSH();
							}
						);
					} else if ($stateParams.type == HDFS_CONNECTOR) {
						HDFSConnectorService.getHDFSConnectorById(connectorId).then(
							function (response) {
								initConnectorData(
									response.data,
									HDFS_CONNECTOR
								);
								getHdfsConnectorParams();
							}
						);
					}
				} else {
					$scope.isNewConnector = true;
					$scope.metadataLoaded = true;
					$scope.isSSH();
					$scope.calcHttpAuth();
				}
			}

			let connectionResponseHandler = function (response) {
				if (response.data) {
					toaster.pop('success', toasterPopSuccess, toasterPopValidConnection);
				} else {
					toaster.pop('error', toasterPopError, toasterPopErrorConnection);
				}
			};

			$scope.testConnection = function () {
				if (
					$scope.connector.connector_type == SQL_CONNECTOR ||
					$scope.connector.connector_type == MONGODB_CONNECTOR ||
					$scope.connector.connector_type == NEO4J_CONNECTOR
				) {
					let connector = angular.copy($scope.connector);
					connector.db_type = connector.type;
					delete connector.type;
					DBConnectorService.checkDBConnection(connector).then(function (
						response
					) {
						connectionResponseHandler(response);
					});
				} else if ($scope.connector.connector_type == SFTP_CONNECTOR) {
					let connector = angular.copy($scope.connector);
					setSftpDtoSecurity(connector);
					SFTPConnectorService.checkSFTPConnection(connector).then(function (
						response
					) {
						connectionResponseHandler(response);
					});
				} else if ($scope.connector.connector_type == HTTP_CONNECTOR) {
					let connector = angular.copy($scope.connector);
					setSelectedAuthTypeDtoSecurity(connector);
					HTTPConnectorService.checkHTTPConnection(connector).then(function (
						response
					) {
						connectionResponseHandler(response);
					});
				} else if ($scope.connector.connector_type == HDFS_CONNECTOR) {
					let hdfsConnector = angular.copy($scope.connector);
					setHdfsConnectorParamsBeforeSave(hdfsConnector);
					HDFSConnectorService.checkHDFSConnection(hdfsConnector).then(
						function (response) {
							connectionResponseHandler(response);
						}
					);
				} else if ($scope.connector.connector_type == S3_CONNECTOR) {
					S3ConnectorService.checkS3Connection($scope.connector).then(function (
						response
					) {
						connectionResponseHandler(response);
					});
				}
			};

			$scope.getConnectionString = function () {
				$scope.generatedconnectionChaine = '';
				let host =
					$scope.connector.host != undefined
						? $scope.connector.host
						: toasterConnectorHost;
				let port =
					$scope.connector.port != undefined
						? $scope.connector.port
						: toasterConnectorPort;
				let dbName =
					$scope.connector.db_name != undefined
						? $scope.connector.db_name
						: toasterConnectorBaseName;
				let username =
					$scope.connector.username != undefined
						? $scope.connector.username
						: 'username';
				let secureKey = '*******';
				switch ($scope.connector.type) {
					case 'oracle':
						$scope.generatedconnectionChaine =
							'jdbc:oracle:thin:@//' + host + ':' + port + '/' + dbName;
						break;
					case 'sqlServer':
						$scope.generatedconnectionChaine =
							'jdbc:sqlserver://' +
							host +
							':' +
							port +
							';databaseName=' +
							dbName;
						break;
					case 'pgSql':
						$scope.generatedconnectionChaine =
							'jdbc:postgresql://' + host + ':' + port + '/' + dbName;
						break;
					case 'mySql':
						$scope.connector.additional_params = $scope.isNewConnector
							? 'nullNamePatternMatchesAll=true&useUnicode=true&useJDBCCompliantTimezoneShift=true&useLegacyDatetimeCode=false&serverTimezone=UTC&zeroDateTimeBehavior=convertToNull'
							: $scope.connector.additional_params;
						$scope.generatedconnectionChaine =
							'jdbc:mysql://' + host + ':' + port + '/' + dbName;
						break;
					case 'mariaDb':
						$scope.generatedconnectionChaine =
							'jdbc:mariadb://' + host + ':' + port + '/' + dbName;
						break;
					case 'neo4j':
						$scope.generatedconnectionChaine = 'bolt://' + host + ':' + port;
						break;
					case 'mongoDb':
						$scope.generatedconnectionChaine =
							'mongodb://' +
							username +
							':' +
							secureKey +
							'@' +
							host +
							':' +
							port +
							'/' +
							dbName;
						break;
					default:
						$scope.generatedconnectionChaine = '';
				}
			};
			$scope.deleteElementData = {};
			$scope.showDeleteConModal = function () {
				$scope.objToDelete = $scope.connector;
				$scope.deleteElementData = {
					label: $scope.connector.metadata.label,
					doDelete: $scope.deleteConnector,
					showConfirmDeleteElement: true,
				};
			};

			$scope.deleteConnector = function () {
				if (
					$scope.objToDelete.connector_type == SQL_CONNECTOR ||
					$scope.objToDelete.connector_type == MONGODB_CONNECTOR ||
					$scope.objToDelete.connector_type == NEO4J_CONNECTOR
				) {
					DBConnectorService.deleteDBConnector($scope.objToDelete.id).then(
						function (response) {
							showDeleteSuccessAndReturn('Base de données');
						}
					);
				} else if ($scope.objToDelete.connector_type == HTTP_CONNECTOR) {
					HTTPConnectorService.deleteHTTPConnector($scope.objToDelete.id).then(
						function (response) {
							showDeleteSuccessAndReturn(HTTP_CONNECTOR);
							showDeleteSuccessAndReturn(HTTP_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == S3_CONNECTOR) {
					S3ConnectorService.deleteS3Connector($scope.objToDelete.id).then(
						function (response) {
							showDeleteSuccessAndReturn(S3_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == SFTP_CONNECTOR) {
					SFTPConnectorService.deleteSFTPConnector($scope.objToDelete.id).then(
						function (response) {
							showDeleteSuccessAndReturn(SFTP_CONNECTOR);
						}
					);
				} else if ($scope.objToDelete.connector_type == HDFS_CONNECTOR) {
					HDFSConnectorService.deleteHDFSConnector($scope.objToDelete.id).then(
						function (response) {
							showDeleteSuccessAndReturn(HDFS_CONNECTOR);
						}
					);
				}
			};

			function showDeleteSuccessAndReturn(type) {
				toaster.pop(
					'success',
					toasterPopSuccess,
					deleteConnectorTra + ' ' + type + ' ' + updateOrCreateConnectorDone
				);
				$timeout(function () {
					$state.go('connectors');
				}, 300);
			}

			$scope.showTable = false;
			$scope.getLinkedDepotTable = function () {
				$scope.showTable = true;
			};

			$scope.depotListConf = {};
			$scope.depotListConf.disableAllSelectedElems = true;

			$scope.elementGraphData = {};
			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.connector.metadata.label;
				$scope.elementGraphData.centeredElementType = 'CONNECTOR';
				$scope.elementGraphData.centeredElementId = $scope.connector.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.sftpSSH = false;
			$scope.isSSH = function () {
				$scope.sftpSSH =
					$scope.connector &&
					$scope.connector.ssh_access != undefined &&
					$scope.connector.credentials == undefined;
				$scope.resetSftpSecMode();
			};

			$scope.resetSftpSecMode = function () {
				if ($scope.connector) {
					if ($scope.sftpSSH) {
						$scope.connector.ssh_access = $scope.connector.ssh_access
							? $scope.connector.ssh_access
							: {};
						$scope.connector.credentials = null;
					} else {
						$scope.connector.ssh_access = null;
						$scope.connector.credentials = $scope.connector.credentials
							? $scope.connector.credentials
							: {};
					}
				}
			};

			$scope.selectedAuthType = 'NONE';
			$scope.calcHttpAuth = function () {
				if ($scope.connector && $scope.connector.bac != undefined) {
					$scope.selectedAuthType = 'BASIC';
				} else {
					$scope.selectedAuthType = 'NONE';
				}
				$scope.changeHttpAuthMode();
			};

			$scope.changeHttpAuthMode = function () {
				if ($scope.connector) {
					if ($scope.selectedAuthType == 'BASIC') {
						$scope.connector.bac = $scope.connector.bac
							? $scope.connector.bac
							: {};
					} else {
						delete $scope.connector.bac;
					}
				}
			};

			$scope.metadataConfig = {
				htmlId: 'connector',
			};
			$scope.paramsKeys = {};
			$scope.paramsValues = {};
			$scope.addHdfsParamValue = function () {
				$scope.paramsKeys =
					$scope.paramsKeys != undefined ? $scope.paramsKeys : {};
				$scope.paramsValues =
					$scope.paramsValues != undefined ? $scope.paramsValues : {};
				let key = _.uniqueId('params_');
				$scope.paramsKeys[key] = '';
				$scope.paramsValues[key] = '';
			};

			$scope.deleteHdfsParamValue = function (index) {
				delete $scope.paramsKeys[index];
				delete $scope.paramsValues[index];
			};

			let setHdfsConnectorParamsBeforeSave = function (connector) {
				connector.params = {};
				angular.forEach($scope.paramsKeys, function (value, key) {
					connector.params[value] = $scope.paramsValues[key];
				});
			};

			let getHdfsConnectorParams = function () {
				$scope.paramsKeys = {};
				$scope.paramsValues = {};
				angular.forEach($scope.connector.params, function (value, key) {
					let uniqueKey = _.uniqueId('params_');
					$scope.paramsKeys[uniqueKey] = key;
					$scope.paramsValues[uniqueKey] = value;
				});
			};

			$scope.openFileManagerModal = function () {
				if (!$scope.connector && !$scope.connector.connector_type) {
					return;
				}

				let connector = $scope.connector;
				let connectorType = $scope.connector.connector_type;
				let title = gettextCatalog.getString(
					'connector.remote.files.explorer',
					{
						connectorType: $filter('connectorTranslator')(connectorType),
						connectorLabel: $scope.metadata.label,
					}
				);
				var locals = {
					title: title,
					connector: connector,
					type: connectorType,
				};

				ModalService.open({
					controllerAs: 'vm',
					bindToController: true,
					templateUrl:
						'./src/components/modules/connector/remote-file-explorer/remote-file-explorer.html',
					controller: 'RemoteFilesExplorerController',
					disableBackdrop: true,
					locals: locals,
				}).then(function (path) {
					$scope.depot.path = path;
				});
			};

			init();
		},
	]);
