(function () {
	angular.module('dcApp').factory('httpService', [
		'$q',
		'$http',
		function ($q, $http) {
			var httpService = {};

			httpService.postRequest = function (url, body) {
				var deferred = $q.defer();
				$http.post(url, body).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (error) {
						deferred.reject(error);
					}
				);
				return deferred.promise;
			};

			httpService.putRequest = function (url, body) {
				var deferred = $q.defer();
				$http.put(url, body).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			httpService.getRequest = function (url) {
				return $http.get(url).then(
					function (response) {
						const data = response.data;
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return httpService;
		},
	]);
})();
