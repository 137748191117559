(function () {
	'use strict';

	angular.module('dcApp').directive('confirmDialog', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'gettextCatalog',
				function ($scope, gettextCatalog) {
					var vm = this;
					vm.cancelText = gettextCatalog.getString('Annuler');
					$scope.confirm = function () {
						vm.widgetData.confirmDialogAction();
						closeModal();
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal() {
						if (vm.widgetData.closeDialogAction) {
							vm.widgetData.closeDialogAction();
						}
						$scope.vm.widgetData.showConfirmationDialog = false;
						$(vm.element).modal('hide');
					}
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=' },
				controller,
				'./src/components/directives/dialog/confirmDialog/confirmDialog.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.widgetData.showConfirmationDialog = false;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.widgetData
								? scope.vm.widgetData.showConfirmationDialog
								: false;
						},
						function () {
							scope.confirmationDialogTitle =
								scope.vm.widgetData.confirmationDialogTitle;
							scope.confirmationDialogMessage =
								scope.vm.widgetData.confirmationDialogMessage;
							scope.confirmationDialogMessageDetails =
								scope.vm.widgetData.confirmationDialogMessageDetails;
							scope.confirmationDialogMessageDetailsList =
								scope.vm.widgetData.confirmationDialogMessageDetailsList;
							scope.confirmationDialogActionName =
								scope.vm.widgetData.confirmationDialogActionName;
							scope.cancelDialogActionName = scope.vm.widgetData
								.cancelDialogActionName
								? scope.vm.widgetData.cancelDialogActionName
								: scope.vm.cancelText;
							scope.enableConfirm =
								scope.vm.widgetData.enableConfirmDialogAction;
							scope.confirmationGreenAction =
								scope.vm.widgetData.confirmationGreenAction;
						}
					);
				}
			);
		},
	]);
})();
